import React, {useEffect, useState} from 'react';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ScrollView, Platform, Text, Pressable} from 'react-native';
import {Button, Icon, LinearProgress, useTheme} from 'react-native-elements';
import globalStyle from '../theme/globalStyle';
import Modal from 'react-native-modal';
import QRCode from 'qrcode.react';
import {postProxyRequest} from '../api/inCheckinRestService';
import {
    EVISITOR_GUEST_DETAILS_URL,
    EVISITOR_QR_EXAMPLE,
    QR_TOURIST_DATA_TITLE,
    TOURIST_DETAILS_DIALOG_TITLE,
    SHARE_DOCUMENT_TEXT,
    QR_NOTE,
    CLOSE,
    PRINT,
} from '../constants/stringsAndFields';
import {getUrlForQrByID, isLocalhost} from '../utils/helpers';
import {useReactToPrint} from 'react-to-print';
import useStyles from './TouristDetailsDialog.styles';
import {fromBase64} from '../utils/jwt';
import {parseAndGenerateHtml} from '../utils/htmlParser';
import WebView from 'react-native-webview';
import {generatePdf} from '../utils/pdf';
import {useCallback} from 'react';

const IS_MOBILE = Platform.OS !== 'web' ? true : false;
const MOCK_QR_CODE_URL = !IS_MOBILE && isLocalhost() ? true : false;
const FILE_NAME = 'eCheckinTouristInfo';

const TouristDetailsDialog = ({setApiErrors, useSharedDialogs}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const [htmlContent, setHtmlContent] = useState(null);
    const [touristLink, setTouristLink] = useState(null);
    const {t} = useTranslation();
    const componentRef = useRef();
    const {isOpenQRDetails, closeQRDetails, guestId, updateGuestId} = useSharedDialogs();

    const shareTitle = t(SHARE_DOCUMENT_TEXT);
    const shareText = t(QR_TOURIST_DATA_TITLE);

    const close = useCallback(() => {
        setHtmlContent(null);
        updateGuestId(null);
        closeQRDetails();
    }, []);

    const onButtonClose = _ => {
        close();
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const onButtonPrint = _ => {
        handlePrint();
    };

    const handleMobilePrint = async _ => {
        await generatePdf(htmlContent, FILE_NAME, shareTitle, shareText);
    };

    const fetchGuestData = useCallback(async guestId => {
        try {
            const tLink = `${t(EVISITOR_GUEST_DETAILS_URL)}${getUrlForQrByID(guestId ?? '')}`;
            setTouristLink(tLink);
            const proxyRes = await postProxyRequest({
                url: MOCK_QR_CODE_URL ? t(EVISITOR_QR_EXAMPLE) : tLink,
                method: 'get',
            });
            if (proxyRes) {
                const proxyResponseBody = fromBase64(proxyRes['body']);
                if (proxyResponseBody) {
                    const parsedHtml = parseAndGenerateHtml(t, proxyResponseBody, tLink, IS_MOBILE);
                    setHtmlContent(parsedHtml);
                }
            }
        } catch (e) {
            console.log(e);
            setApiErrors({signal: 'postProxyRequest', message: e?.RESTErrors?.description ?? e?.message});
        }
    }, []);

    useEffect(() => {
        if (isOpenQRDetails && guestId) {
            fetchGuestData(guestId).catch(console.error);
        }
    }, [isOpenQRDetails, guestId]); // eslint-disable-line react-hooks/exhaustive-deps

    //web app
    const webView = !IS_MOBILE && (
        <View ref={componentRef}>
            <div dangerouslySetInnerHTML={{__html: htmlContent}} />
            {touristLink && (
                <>
                    <View style={styles.qrcode}>
                        <QRCode level="M" value={touristLink} />
                    </View>
                    <Text style={styles.note}>{t(QR_NOTE)}</Text>
                </>
            )}
        </View>
    );

    //mobile apps
    const mobileWebView = (
        <WebView
            source={{
                html: htmlContent,
            }}
        />
    );

    return (
        <Modal
            isVisible={isOpenQRDetails}
            onBackdropPress={close}
            onBackButtonPress={close}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={[global.modalDialogL, {maxWidth: 930, height: '95%'}]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(TOURIST_DETAILS_DIALOG_TITLE)}</Text>
                    <Pressable onPress={close} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>
                {!htmlContent && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
                <ScrollView contentContainerStyle={{flexGrow: 1}}>
                    {htmlContent && (IS_MOBILE ? mobileWebView : webView)}
                </ScrollView>
                <View style={global.modalFooter}>
                    <Button
                        title={t(CLOSE)}
                        onPress={onButtonClose}
                        containerStyle={[global.ModalButton.Negative.containerStyle, {minWidth: 110}]}
                        buttonStyle={global.ModalButton.Negative.buttonStyle}
                        titleStyle={global.ModalButton.Negative.titleStyle}
                    />
                    <Button
                        title={t(PRINT)}
                        onPress={IS_MOBILE ? handleMobilePrint : onButtonPrint}
                        containerStyle={[global.ModalButton.Positive.containerStyle, {minWidth: 110}]}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default TouristDetailsDialog;
