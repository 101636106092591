import React, {memo, useMemo} from 'react';
import {FlatList, View, Text, useWindowDimensions} from 'react-native';
import {useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {useTranslation} from 'react-i18next';
import {NO_INVOICES} from '../../constants/stringsAndFields';
import InvoiceItem from './InvoiceItem';

const InvoicesList = ({invoices, closeInvoices, navigation}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const width = useWindowDimensions().width;

    const render = ({item}) => <InvoiceItem invoice={item} closeInvoices={closeInvoices} navigation={navigation} />;

    const memoizedContainerStyle = useMemo(() => {
        const isDesktop = width > 1500;
        const global = globalStyle(theme);
        return [global.flatList, {width: '100%', maxWidth: 1100, alignSelf: 'center'}, isDesktop && {paddingTop: 25}];
    }, [width, theme]);

    const emptyList = <MemoizedEmptyList t={t} global={global} />;

    return (
        <FlatList
            data={invoices}
            renderItem={render}
            contentContainerStyle={memoizedContainerStyle}
            ListEmptyComponent={emptyList}
        />
    );
};

export default InvoicesList;

export function EmptyList({t, global}) {
    return (
        <View style={global.listEmptyMsg}>
            <Text style={global.listEmptyMsgText}>{t(NO_INVOICES)}</Text>
        </View>
    );
}
export const MemoizedEmptyList = memo(EmptyList);
