import React, {useState} from 'react';
import {TextInput, View, Text, StyleSheet, Platform} from 'react-native';
import {Icon, useTheme} from 'react-native-elements';
import {forwardRef} from 'react';

const UITextInput = forwardRef(
    (
        {
            label,
            value,
            onChangeText,
            placeholder,
            errorMessage,
            disabled,
            readonly,
            keyboardType = 'default',
            autoComplete = 'off',
            autoCorrect = false,
            multiline = false,
            caretHidden = false,
            dropdown = false,
            style,
            containerStyle,
            labelStyle,
            iconLeft,
            iconRight,
            iconColor = '#888',
            onRightIconPress,
            onLeftIconPress,
            ...props
        },
        ref
    ) => {
        const {theme} = useTheme();
        const styles = getStyles(theme);

        const isWeb = Platform.OS === 'web';

        const [isFocused, setIsFocused] = useState(false);

        const handleFocus = () => {
            setIsFocused(true);
        };

        const handleBlur = () => {
            setIsFocused(false);
        };

        return (
            <View style={[styles.containerStyle, containerStyle]}>
                {label && <Text style={[styles.label, labelStyle]}>{label}</Text>}
                <View style={[styles.inputContainerStyle, disabled && styles.inputContainerDisabled]}>
                    {iconLeft && (
                        <Icon
                            type="ionicon"
                            name={iconLeft}
                            size={18}
                            containerStyle={[styles.iconLeft, onLeftIconPress && {pointerEvents: 'auto'}]}
                            color={isFocused ? theme.colors.primary : iconColor}
                            onPress={onLeftIconPress}
                        />
                    )}
                    <TextInput
                        value={value}
                        onChangeText={onChangeText}
                        placeholder={placeholder}
                        keyboardType={keyboardType}
                        autoComplete={autoComplete}
                        autoCorrect={autoCorrect}
                        multiline={multiline}
                        textAlignVertical="top"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        style={[
                            styles.input,
                            isFocused && styles.inputFocused,
                            errorMessage && styles.inputError,
                            iconLeft && {paddingLeft: 33},
                            iconRight && {paddingRight: 33},
                            dropdown && {paddingRight: 33},
                            multiline && styles.textArea,
                            caretHidden && !disabled && isWeb && {cursor: 'pointer'},
                            disabled && styles.inputDisabled,
                            readonly && styles.readonly,
                            style,
                        ]}
                        selectionColor={theme.colors.primaryMedium}
                        cursorColor={theme.colors.primary}
                        placeholderTextColor={theme.colors.greyMedium}
                        underlineColorAndroid="rgba(255,255,255,0)" // fix for Android underline - not working
                        disabled={disabled}
                        ref={ref}
                        {...props}
                    />
                    {iconRight && (
                        <Icon
                            type="ionicon"
                            name={iconRight}
                            size={18}
                            containerStyle={[styles.iconRight, onRightIconPress && {pointerEvents: 'auto'}]}
                            color={isFocused ? theme.colors.primary : iconColor}
                            onPress={onRightIconPress}
                        />
                    )}
                    {dropdown && <Icon name={'keyboard-arrow-down'} size={18} containerStyle={styles.arrowDownIcon} />}
                </View>

                {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
            </View>
        );
    }
);

const getStyles = theme => {
    return StyleSheet.create({
        input: {
            height: 40,
            minHeight: 40,
            width: '100%',
            paddingHorizontal: 10,
            backgroundColor: '#fff',
            borderWidth: 1,
            borderColor: theme.colors.greyLight,
            borderRadius: theme.inputRadius,
            fontSize: 14,
            fontFamily: theme.fontFamilyMedium,
            color: theme.colors.textColor,
        },

        inputFocused: {
            borderColor: theme.colors.primary,
        },

        inputError: {
            borderColor: theme.colors.error,
        },

        inputContainerDisabled: {
            opacity: 0.7,
            pointerEvents: 'none',
        },

        inputDisabled: {
            backgroundColor: theme.colors.navBg,
            color: theme.colors.greyDark,
        },

        readonly: {
            pointerEvents: 'none',
        },

        textArea: {
            height: 'auto',
            minHeight: 60,
            paddingVertical: 10,
            resize: 'vertical',
            lineHeight: 20,
        },

        label: {
            fontSize: 13,
            fontFamily: theme.fontFamilyMedium,
            color: theme.colors.textColorMedium,
            //color: theme.colors.textColor,
            marginBottom: 6,
        },

        error: {
            fontFamily: theme.fontFamilyMedium,
            fontSize: 11,
            marginTop: 5,
            color: theme.colors.error,
        },

        containerStyle: {
            width: '100%',
            marginBottom: 15,
        },

        inputContainerStyle: {
            width: '100%',
            position: 'relative',
        },

        arrowDownIcon: {
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            width: 35,
            justifyContent: 'center',
            pointerEvents: 'none',
            zIndex: 1,
        },

        iconLeft: {
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: 35,
            justifyContent: 'center',
            pointerEvents: 'none',
            zIndex: 1,
        },

        iconRight: {
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            width: 35,
            justifyContent: 'center',
            pointerEvents: 'none',
            zIndex: 1,
        },
    });
};

export default UITextInput;
