import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    editAccomodation: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        paddingHorizontal: 15,
        paddingVertical: 10,
    },

    noteWrapper: {
        flexDirection: 'row',
        paddingHorizontal: 15,
        paddingVertical: 10,
        marginTop: 5,
        borderTopWidth: 1,
        borderTopColor: theme.colors.borderLight,
    },

    selfCheckinBtn: {
        backgroundColor: theme.colors.primary,
        height: 40,
        paddingHorizontal: 0,
        width: '100%',
        maxWidth: 150,
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
    },

    selfCheckinBtnText: {
        color: theme.colors.white,
        fontFamily: theme.fontFamilyMedium,
        fontSize: 13,
    },
}));

export default useStyles;
