import AsyncStorage from '@react-native-async-storage/async-storage';
import {useIsFocused} from '@react-navigation/native';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, Pressable, SafeAreaView, Text, View, useWindowDimensions} from 'react-native';
import {LinearProgress, useTheme} from 'react-native-elements';
import {cancelGuest, checkinGuest, checkoutGuest, fetchTourists} from '../api/inCheckinRestService';
import Confirm2For1Dialog from '../components/Confirm2For1Dialog';
import CreditsDepletedDialog from '../components/CreditsDepletedDialog';
import ErrorMessageDialog from '../components/ErrorMessageDialog';
import TouristDetailsDialog from '../components/TouristDetailsDialog';
import CalendarGuestsList from '../components/calendar/CalendarGuestsList';
import CheckinActionsDialog from '../components/calendar/CheckinActionsDialog';
import CheckinsCalendar from '../components/calendar/CheckinsCalendar';
import EVChooserHeader from '../components/calendar/EVChooserHeader';
import FabButton from '../components/calendar/FabButton';
import FacilityChooserDialog from '../components/calendar/FacilityChooserDialog';
import {useSharedDialogs} from '../components/calendar/useDialogs';
import {useSharedErrors} from '../components/calendar/useErrors';
import EVChooserDialog from '../components/tourists/EVChooserDialog';
import {
    ACCOMODATION_OBJECT,
    ACCOMODATION_UNIT,
    CODE,
    CREDITS_DEPLETED_MSG,
    CREDITS_DEPLETED_TITLE,
    CREDITS_EXPIRED_MSG,
    CREDITS_EXPIRED_TITLE,
    EVISITOR_FACILITY_LABEL,
    FACILITY_CODE,
    FACILITY_ID,
    ID,
    NAME,
    NAME_CODE,
    PIN,
    PIN_ID,
    RECORDS,
    REST_ERRORS,
    STORAGE_CHOSEN_EV_ACCOUNT,
    STORAGE_CHOSEN_EV_FACILITY,
    TOURIST_NAME,
    TOURIST_SURNAME,
    UPDATED_STATUS,
    content,
    status,
} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import globalStyle from '../theme/globalStyle';
import {filterByFacilityCodeAndNameCode, findEvisitorByPin, isEmpty, isNotEmpty} from '../utils/arrayHelpers';
import {showCreditsDialog, useCreditsAfterExpireConfirm} from '../utils/credits';
import {deserializeData, serializeData} from '../utils/helpers';
import {isEVAccountFetchedFacility, saveEVAccountFetchedFacilityFlag} from '../utils/userUtils';
import {getMonthBoundaries} from '../utils/dateHelper';

const PAGE_ONE = 1;
const TOURIST_PAGE_COUNT = 40;
const IS_WEB = Platform.OS === 'web';

const CalendarScreen = ({route, navigation}) => {
    const {
        clearErrors,
        apiErrors,
        apiMessages,
        getCheckinsLocalFull,
        evaccounts,
        refetchEvisitor,
        getEvisitor,
        eVisitorStaticData,
        updateGuest,
        setApiErrors,
        onCreateIfNotExistCheckin,
    } = useContext(DataContext);
    const isFocused = useIsFocused();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const [openFacilityChooser, setOpenFacilityChooser] = useState(false);
    const [evAccount, setEvAccount] = useState(null);
    const [progressOngoing, setProgressOngoing] = useState(false);
    const [checkinsData, setCheckinsData] = useState([]);
    const [guestsData, setGuestsData] = useState([]);
    const [filteredFacilities, setFilteredFacilities] = useState([]);
    const [facility, setFacility] = useState(null);
    const [tourists, setTourists] = useState([]);
    const [selectedMonthDate, setSelectedMonthDate] = useState(new Date());
    const [openApiErrorMessage, setOpenApiErrorMessage] = useState(null);
    const [checkinActionsDialog, setCheckinActionsDialog] = useState(null);
    const [evAccountSetupInProgress, setEvAccountSetupInProgress] = useState(false);
    const {openCreditsDepleted, openCreditsAfterExpire} = useSharedDialogs();

    const width = useWindowDimensions().width;
    const isMobileSmall = width < 500;

    const updateGuestStatus = async (guest, evisitorStatus, editedStatus = false) => {
        try {
            guest[status] = evisitorStatus;
            guest[UPDATED_STATUS] = editedStatus;
            await updateGuest(guest, true);
        } catch (e) {
            console.log(e);
        }
        // refreshing tourists
        await initTourists(evAccount, facility);
        await populateCheckins(evAccount, facility);
    };

    const updateCheckoutGuest = async guest => {
        try {
            await updateGuest(guest, true);
        } catch (e) {
            console.log(e);
        }
        // refreshing tourists
        await initTourists(evAccount, facility);
        await populateCheckins(evAccount, facility);
    };

    const guestActions = {
        checkinGuest,
        checkoutGuest,
        cancelGuest,
        updateCheckoutGuest,
        updateGuestStatus,
        onCreateIfNotExistCheckin,
    };

    const refreshCalendar = async () => {
        if (evAccount && facility) {
            await initTourists(evAccount, facility);
            await populateCheckins(evAccount, facility);
        }
    };

    const openFacilityChooserDialog = _ => {
        setOpenFacilityChooser(true);
    };

    const onChoosedFacility = selectedFacility => {
        setFacility(selectedFacility);
    };

    const setupEvAccount = async () => {
        setGuestsData([]);
        let eVisitorAcc = null;
        if (isNotEmpty(evaccounts)) {
            const storedPin = await AsyncStorage.getItem(STORAGE_CHOSEN_EV_ACCOUNT);
            eVisitorAcc = storedPin ? findEvisitorByPin(evaccounts, storedPin) : null;
            if (!eVisitorAcc) {
                eVisitorAcc = evaccounts[0];
            }
            if (eVisitorAcc) {
                setEvAccount(eVisitorAcc);
                await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, eVisitorAcc[PIN]);
            }
            if (!evAccountSetupInProgress) {
                await setupFacilities(eVisitorAcc);
            }
        } else {
            setEvAccount(null);
        }
        return eVisitorAcc;
    };

    const extendFacilities = useCallback(evisitor => {
        const filteredFacilities = evisitor ? evisitor?.[ACCOMODATION_OBJECT] : [];
        const unitCodes = evisitor[ACCOMODATION_UNIT].map(unit => unit[CODE]);
        const activeFacilities = filteredFacilities.filter(facility => unitCodes.includes(facility[FACILITY_CODE]));
        if (isNotEmpty(activeFacilities)) {
            for (let facility of activeFacilities) {
                const parentObject = evisitor[ACCOMODATION_UNIT].find(unit => facility[FACILITY_CODE] === unit[CODE]);
                if (parentObject) {
                    facility[NAME] = parentObject[NAME];
                }
            }
        }
        return activeFacilities;
    }, []);

    const checkAndFetchFacilities = useCallback(async evAccount => {
        let facilities = evAccount ? evAccount?.[ACCOMODATION_OBJECT] : [];
        if (isEmpty(facilities)) {
            const isAlreadyFetched = await isEVAccountFetchedFacility(evAccount[PIN]);
            if (!isAlreadyFetched) {
                await refetchEvisitor(evAccount);
                await saveEVAccountFetchedFacilityFlag(evAccount[PIN]);
                const updatedEvisitor = await getEvisitor(evAccount[ID]);
                facilities = extendFacilities(updatedEvisitor);
            }
        } else {
            facilities = extendFacilities(evAccount);
        }
        return facilities;
    }, []);

    const setupFacilities = async evAccount => {
        setEvAccountSetupInProgress(true);
        try {
            let storedFavoriteFacility = await AsyncStorage.getItem(STORAGE_CHOSEN_EV_FACILITY);
            const facilities = await checkAndFetchFacilities(evAccount);
            if (isNotEmpty(facilities)) {
                if (storedFavoriteFacility) {
                    storedFavoriteFacility = deserializeData(storedFavoriteFacility);
                }
                let selectedFacility = storedFavoriteFacility
                    ? facilities.find(
                          fac =>
                              fac?.[FACILITY_CODE] === storedFavoriteFacility?.[FACILITY_CODE] &&
                              fac?.[NAME_CODE] === storedFavoriteFacility?.[NAME_CODE]
                      )
                    : null;

                selectedFacility = selectedFacility ? selectedFacility : facilities[0];
                await AsyncStorage.setItem(STORAGE_CHOSEN_EV_FACILITY, serializeData(selectedFacility));
                await populateCheckins(evAccount, selectedFacility);
                setFacility(selectedFacility);
                setFilteredFacilities([...facilities]);
            } else {
                setFacility(null);
                setFilteredFacilities([]);
            }
        } catch (erorr) {
            console.log(error);
        } finally {
            setEvAccountSetupInProgress(false);
        }
    };

    const populateCheckins = useCallback(async (evAccount, selectedFacility) => {
        const checkins = await getCheckinsLocalFull(evAccount);
        const filteredCheckins = filterByFacilityCodeAndNameCode(checkins, selectedFacility);
        setCheckinsData(filteredCheckins);
    }, []);

    const initTourists = async (evAccount, selectedFacility) => {
        try {
            setProgressOngoing(true);
            const monthDateTimeBoundary = getMonthBoundaries(selectedMonthDate);
            const fetchedTourists = await fetchTourists(
                evAccount?.[PIN_ID],
                TOURIST_PAGE_COUNT,
                PAGE_ONE,
                null,
                selectedFacility?.[FACILITY_ID],
                monthDateTimeBoundary
            );
            fetchedTourists && setTourists(fetchedTourists[RECORDS]);
        } catch (e) {
            console.log(e);
            setApiErrors({signal: 'initTourists', message: e?.RESTErrors?.description ?? e?.message});
        } finally {
            setProgressOngoing(false);
        }
    };

    const {closeAllErrors, setCheckinErrors, openCheckinErrorMsg} = useSharedErrors();
    const showErrors = useCallback(async (error, guest) => {
        const showedCreditsDialog = await showCreditsDialog(error, openCreditsDepleted, openCreditsAfterExpire);
        if (showedCreditsDialog) return;
        if (error?.[REST_ERRORS]) {
            const {type, description} = error?.[REST_ERRORS];
            setCheckinErrors({
                type: type,
                description: description,
                guest: `${guest[content][TOURIST_NAME]} ${guest[content][TOURIST_SURNAME]}`,
            });
            openCheckinErrorMsg();
        } else if (error?.response) {
            const {type, description} = error.response?.data;
            setCheckinErrors({
                type: type,
                description: description,
                guest: `${guest[content][TOURIST_NAME]} ${guest[content][TOURIST_SURNAME]}`,
            });
            openCheckinErrorMsg();
        } else if (error?.message) {
            setCheckinErrors({
                type: 'Guest',
                description: error.message,
                guest: `${guest[content][TOURIST_NAME]} ${guest[content][TOURIST_SURNAME]}`,
            });
            openCheckinErrorMsg();
        }
    }, []);

    const onBuyCreditsPress = async () => {
        navigation.navigate('Packages');
    };

    const updateEvAccount = async acc => {
        await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, acc[PIN]);
        if (!evAccountSetupInProgress) {
            await setupFacilities(acc);
        }
    };

    useEffect(() => {
        if (isFocused && evaccounts) {
            clearErrors();
            setupEvAccount()
                .catch(console.error)
                .then(_ => {
                    // needed for pop events from fast checkin/checkout
                    refreshCalendar().catch(console.error);
                });
        }
    }, [isFocused, evaccounts]);

    useEffect(() => {
        setGuestsData([]);
        if (evaccounts && evAccount) {
            updateEvAccount(evAccount).catch(console.error);
        } else {
            setCheckinsData([]);
        }
    }, [evAccount]);

    useEffect(() => {
        if (facility) {
            setGuestsData([]);
            const setupFacility = async () => {
                await AsyncStorage.setItem(STORAGE_CHOSEN_EV_FACILITY, serializeData(facility));
                if (evAccount) {
                    await populateCheckins(evAccount, facility);
                    await initTourists(evAccount, facility);
                }
            };
            setupFacility().catch(console.error);
        } else {
            const removeFacilitySetting = async () => {
                await AsyncStorage.removeItem(STORAGE_CHOSEN_EV_FACILITY);
            };
            setGuestsData([]);
            setCheckinsData([]);
            setTourists([]);
            removeFacilitySetting().catch(console.error);
        }
    }, [facility]);

    useEffect(() => {
        apiMessages && setOpenApiErrorMessage(true);
        apiErrors && setOpenApiErrorMessage(true);
    }, [apiMessages, apiErrors]);

    useEffect(() => {
        if (evAccount) {
            navigation.setOptions({
                headerRight: () => (
                    <EVChooserHeader evAccount={evAccount} useSharedDialogs={useSharedDialogs} global={global} />
                ),
            });
        }
    }, [evAccount, facility]);

    return (
        <SafeAreaView style={[global.containerBg]}>
            {progressOngoing && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
            <View style={global.filterNav}>
                <View
                    style={{
                        width: '100%',
                        maxWidth: 1080,
                        marginHorizontal: 'auto',
                    }}>
                    <Pressable onPress={openFacilityChooserDialog}>
                        <Text
                            style={[
                                global.link,
                                IS_WEB && {
                                    cursor: 'pointer',
                                },
                                {fontSize: isMobileSmall ? 12 : 13},
                            ]}>
                            {facility
                                ? `${facility?.[NAME]}, ${facility?.[NAME_CODE]?.toUpperCase()} `
                                : EVISITOR_FACILITY_LABEL}
                        </Text>
                    </Pressable>
                </View>
            </View>
            <CalendarGuestsList
                guests={guestsData}
                guestActions={guestActions}
                evAccount={evAccount}
                facility={facility}
                showErrors={showErrors}
                setCheckinActionsDialog={setCheckinActionsDialog}
                calendarComponent={
                    <CheckinsCalendar
                        checkins={checkinsData}
                        tourists={tourists}
                        evAccount={evAccount}
                        setGuestsData={setGuestsData}
                        eVisitorStaticData={eVisitorStaticData}
                        setTourists={setTourists}
                        facility={facility}
                        setSelectedMonthDate={setSelectedMonthDate}
                    />
                }
                navigation={navigation}
            />
            <FacilityChooserDialog
                open={openFacilityChooser}
                handleOpen={setOpenFacilityChooser}
                facilities={filteredFacilities}
                setFacility={setFacility}
                onChoosedFacility={onChoosedFacility}
            />
            {isFocused && (
                <EVChooserDialog
                    evaccounts={evaccounts}
                    setEvAccount={setEvAccount}
                    useSharedDialogs={useSharedDialogs}
                />
            )}
            <CheckinActionsDialog {...checkinActionsDialog} />
            <TouristDetailsDialog setApiErrors={setApiErrors} useSharedDialogs={useSharedDialogs} />
            <Confirm2For1Dialog
                title={t(CREDITS_EXPIRED_TITLE)}
                content={t(CREDITS_EXPIRED_MSG)}
                onConfirm={useCreditsAfterExpireConfirm}
                onBuy={onBuyCreditsPress}
                useSharedDialogs={useSharedDialogs}
            />
            <CreditsDepletedDialog
                title={t(CREDITS_DEPLETED_TITLE)}
                content={t(CREDITS_DEPLETED_MSG)}
                onBuy={onBuyCreditsPress}
                useSharedDialogs={useSharedDialogs}
            />
            <ErrorMessageDialog
                isScreenFocused={isFocused}
                useSharedErrors={useSharedErrors}
                apiErrors={apiErrors}
                apiMessages={apiMessages}
                openApiErrorMessage={openApiErrorMessage}
                closeErrorDialogs={closeAllErrors}
            />
            {isNotEmpty(evaccounts) && (
                <FabButton
                    t={t}
                    theme={theme}
                    global={global}
                    useSharedDialogs={useSharedDialogs}
                    navigation={navigation}
                    evAccount={evAccount}
                    facility={facility}
                />
            )}
        </SafeAreaView>
    );
};

export default CalendarScreen;
