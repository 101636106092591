import React, {useState, useCallback} from 'react';
import {useBetween} from 'use-between';

const useDialog = () => {
    const [isOpenEvisitorChooser, setIsOpenEvisitorChooser] = useState(false);
    const openEvisitorDialog = useCallback(() => setIsOpenEvisitorChooser(true), []);
    const closeEvisitorDialog = useCallback(() => setIsOpenEvisitorChooser(false), []);
    const [isOpenFab, setIsOpenFab] = useState(false);
    const openFab = useCallback(() => setIsOpenFab(true), []);
    const closeFab = useCallback(() => setIsOpenFab(false), []);

    return {
        isOpenEvisitorChooser,
        openEvisitorDialog,
        closeEvisitorDialog,
        isOpenFab,
        openFab,
        closeFab,
    };
};

export const useSharedDialogs = () => useBetween(useDialog);
