import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    packageWrapper: {
        width: '33%',
        padding: 15,
    },

    packageBox: {
        padding: 20,
        width: '100%',
        borderRadius: 20,
    },

    packageTitleBox: {
        borderBottomWidth: 1,
        borderBottomColor: 'rgba(0, 0, 0, 0.07)',
        marginVertical: 15,
        paddingBottom: 20,
    },

    packageTitle: {
        color: theme.colors.primary,
        fontSize: 25,
        fontFamily: theme.fontFamilyMedium,
        textAlign: 'center',
    },

    packagesText: {
        fontSize: 16,
        textAlign: 'center',
    },

    packageBtn: {
        marginBottom: 15,
        alignSelf: 'center',
        width: 130,
    },

    standOut: {
        borderWidth: 1,
        borderColor: theme.colors.primary,
    },

    checkins: {
        color: theme.colors.primary,
        marginTop: 10,
        marginBottom: 20,
        fontSize: 22,
        fontFamily: theme.fontFamilyMedium,
        textAlign: 'center',
    },

    expiration: {
        color: theme.colors.primary,
        marginVertical: 10,
        fontSize: 22,
        fontFamily: theme.fontFamilyMedium,
        textAlign: 'center',
    },

    packagePriceBox: {
        marginVertical: 15,
        paddingTop: 20,
        borderTopWidth: 1,
        borderTopColor: 'rgba(0, 0, 0, 0.07)',
    },

    price: {
        fontFamily: theme.fontFamily,
        fontSize: 20,
        marginBottom: 10,
        textAlign: 'center',
    },
}));

export default useStyles;
