import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {View, useWindowDimensions} from 'react-native';
import {Calendar} from 'react-native-calendars';
import {Icon, useTheme} from 'react-native-elements';
import {fetchTourists} from '../../api/inCheckinRestService';
import {FACILITY_ID, PERIODS, PIN_ID, RECORDS} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import {mergeGuestData} from '../../utils/arrayHelpers';
import {extractGuestsFromCheckins, getCalendarTouristsPeriods} from '../../utils/calendar';
import {getMonthBoundaries, getNowDayDate} from '../../utils/dateHelper';
import {importTouristsToGuests} from '../../utils/guestUtils';
import {useSharedDialogs} from './useDialogs';

const CheckinsCalendar = ({
    checkins,
    tourists,
    evAccount,
    setGuestsData,
    eVisitorStaticData,
    setTourists,
    facility,
    setSelectedMonthDate,
}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {updateDayPicked} = useSharedDialogs();

    const width = useWindowDimensions().width;
    const isDesktop = width >= theme.desktopBP;

    const [calendarDays, setCalendarDays] = useState({});
    const [localGuests, setLocalGuests] = useState([]);
    const [remoteGuests, setRemoteGuests] = useState([]);
    const [previousSelectedDay, setPreviousSelectedDay] = useState(null);
    const [currentSelectedDay, setCurrentSelectedDay] = useState(getNowDayDate());

    const onDaySelected = useCallback(
        day => {
            const selectedDate = day?.['dateString'] ?? day;
            const selectedDay = calendarDays?.[selectedDate];
            const selectedPeriods = selectedDay?.[PERIODS];

            if (previousSelectedDay) {
                // this is same day
                if (previousSelectedDay === selectedDay) {
                    calendarDays[selectedDate] = {...calendarDays[selectedDate], selected: true};
                } else {
                    previousSelectedDay['selected'] = false;
                    calendarDays[selectedDate] = {...calendarDays[selectedDate], selected: true};
                }
            } else {
                if (!selectedDay) {
                    // appending to calendar new day data
                    calendarDays[selectedDate] = {selected: true};
                } else {
                    calendarDays[selectedDate] = {...calendarDays[selectedDate], selected: true};
                }
            }
            calendarDays[selectedDate]['selectedColor'] = theme.colors.primary;
            setPreviousSelectedDay(calendarDays[selectedDate]);

            if (selectedPeriods) {
                const guestsData = [];
                for (let period of selectedPeriods) {
                    !period?.['empty'] && guestsData.push(period['guest']);
                }
                setGuestsData(guestsData);
            } else {
                setGuestsData([]);
            }
            updateDayPicked(selectedDate);
            setCurrentSelectedDay(day);
        },
        [calendarDays, previousSelectedDay]
    );

    const fetchMonthTourists = async monthDateTimeBoundary => {
        if (monthDateTimeBoundary) {
            try {
                const fetchedTourists = await fetchTourists(
                    evAccount?.[PIN_ID],
                    50,
                    1,
                    null,
                    facility?.[FACILITY_ID],
                    monthDateTimeBoundary
                );
                fetchedTourists && setTourists(fetchedTourists[RECORDS]);
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onMonthChange = useCallback(
        async monthDate => {
            setGuestsData([]);
            if (previousSelectedDay) {
                previousSelectedDay['selected'] = false;
            }
            if (currentSelectedDay) {
                setCurrentSelectedDay(null);
            }
            const selectedMonthDate = new Date(monthDate.timestamp);
            const monthDateTimeBoundary = getMonthBoundaries(selectedMonthDate);
            await fetchMonthTourists(monthDateTimeBoundary);
            setSelectedMonthDate(selectedMonthDate);
        },
        [previousSelectedDay, currentSelectedDay]
    );

    const calendarTheme = useMemo(() => {
        return {
            textMonthFontFamily: theme.fontFamily,
            textDayHeaderFontFamily: theme.fontFamily,
            textDayFontSize: 14,
            textMonthFontSize: 14,
            textDayHeaderFontSize: 12,
            'stylesheet.marking': {
                period: {
                    height: isDesktop ? 3 : 2,
                    marginVertical: 1,
                },
            },
            'stylesheet.day.basic': {
                base: {
                    width: 32,
                    height: 32,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 2,
                },
                text: {
                    marginTop: 0,
                    fontFamily: theme.fontFamily,
                },
                alignedText: {
                    marginTop: 0,
                },
            },
        };
    }, [theme, isDesktop]);

    useEffect(() => {
        if (checkins) {
            const extractedGuests = extractGuestsFromCheckins(checkins);
            extractedGuests && setLocalGuests(extractedGuests);
        }
    }, [checkins]);

    useEffect(() => {
        if (tourists && evAccount && eVisitorStaticData) {
            const importTourists = async () => {
                const importedTourists = await importTouristsToGuests(tourists, evAccount, eVisitorStaticData);
                importedTourists && setRemoteGuests(importedTourists);
            };
            importTourists().catch(console.error);
        }
    }, [tourists]);

    useEffect(() => {
        if (localGuests || remoteGuests) {
            const merged = mergeGuestData(localGuests, remoteGuests);
            merged && setCalendarDays({...getCalendarTouristsPeriods(merged, theme)});
        }
    }, [localGuests, remoteGuests]);

    useEffect(() => {
        if (calendarDays) {
            currentSelectedDay && onDaySelected(currentSelectedDay);
        }
    }, [calendarDays]);

    return (
        <View style={[global.boxShadow, {borderRadius: 10, marginBottom: 15}]}>
            <View style={[global.card]}>
                <Calendar
                    enableSwipeMonths={true}
                    markingType="multi-period"
                    renderArrow={direction =>
                        direction === 'left' ? (
                            <Icon type="ionicon" name="chevron-back" size={15} color={theme.colors.primaryDark} />
                        ) : (
                            <Icon type="ionicon" name="chevron-forward" size={15} color={theme.colors.primaryDark} />
                        )
                    }
                    markedDates={calendarDays}
                    onDayPress={onDaySelected}
                    onMonthChange={onMonthChange}
                    style={{paddingBottom: 10, paddingHorizontal: 20}}
                    theme={calendarTheme}
                />
            </View>
        </View>
    );

    // multiDot calendar
    return (
        <Calendar
            renderArrow={direction =>
                direction === 'left' ? (
                    <Icon type="ionicon" name="chevron-back" size={15} color={theme.colors.primaryDark} />
                ) : (
                    <Icon type="ionicon" name="chevron-forward" size={15} color={theme.colors.primaryDark} />
                )
            }
            enableSwipeMonths={true}
            markingType={'multi-dot'}
            markedDates={calendarDays}
            onDayPress={onDaySelected}
            onMonthChange={onMonthChange}
        />
    );
};

export default CheckinsCalendar;
