import {useCallback, useState} from 'react';
import {useBetween} from 'use-between';

const useCheckinActions = () => {
    const [toggleCheckinSwipeState, setToggleCheckinSwipeState] = useState(false);
    const handleCheckinSwipeToggle = value => useCallback(value => setToggleCheckinSwipeState(value), []);

    const [guestForCheckin, setGuestForCheckin] = useState(null);
    const prepareGuestForCheckin = useCallback(value => setGuestForCheckin(value), []);

    const [isSwipeReset, setIsSwipeReset] = useState(false);
    const resetSwipeButton = useCallback(() => setIsSwipeReset(true), []);

    const [swipeButtonType, setSwipeButtonType] = useState(null);
    const prepareSwipeButtonType = useCallback(value => setSwipeButtonType(value), []);

    const [fastCheckinInProgress, setFastCheckinInProgress] = useState(false);
    const startFastCheckinInProgress = useCallback(() => setFastCheckinInProgress(true), []);
    const endFastCheckinInProgress = useCallback(() => setFastCheckinInProgress(false), []);

    return {
        guestForCheckin,
        prepareGuestForCheckin,
        toggleCheckinSwipeState,
        handleCheckinSwipeToggle,
        isSwipeReset,
        resetSwipeButton,
        swipeButtonType,
        prepareSwipeButtonType,
        fastCheckinInProgress,
        startFastCheckinInProgress,
        endFastCheckinInProgress,
    };
};

export const useSharedCheckinActions = () => useBetween(useCheckinActions);
