import React from 'react';
import {Platform, View, Text, Pressable} from 'react-native';
import {Icon} from 'react-native-elements';
import {EVISITOR_LABEL, NAME} from '../../constants/stringsAndFields';

const IS_WEB = Platform.OS === 'web';

const EVChooserHeader = props => {
    const {evAccount, useSharedDialogs, onRefresh, global, theme} = props;
    const {openEvisitorDialog} = useSharedDialogs();
    return (
        <View style={[global.flexCenter, {maxWidth: '100%'}]}>
            <View style={[global.evChooserWrapper]}>
                <Pressable onPress={openEvisitorDialog} style={!IS_WEB && {alignSelf: 'flex-end'}}>
                    <Text numberOfLines={1} style={[global.evChooser, global.link]}>
                        {evAccount?.[NAME] ?? EVISITOR_LABEL}
                    </Text>
                </Pressable>
            </View>
            <Pressable onPress={onRefresh} style={{marginHorizontal: 15}}>
                <Icon type="ionicon" name="refresh" size={25} color={theme.colors.primary} />
            </Pressable>
        </View>
    );
};

export default EVChooserHeader;
