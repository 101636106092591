import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable, View} from 'react-native';
import {Button, Icon, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {ADDITIONAL_FIELDS, CHECKIN_NOTE_FIELD, DELETE, NOTE_ADD_MSG, SAVE_DATA} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import UITextInput from '../ui-components/UITextInput';

const NoteEditDialog = ({
    title,
    content,
    onConfirm,
    onReject = null,
    showButtons = true,
    checkin,
    useSharedDialogs,
    onDeleteNote,
}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const [note, setNote] = useState('');
    const {isOpenNoteEdit, closeNoteEdit} = useSharedDialogs();

    const close = async () => {
        closeNoteEdit();
        onReject && (await onReject());
    };

    const deleteNote = async () => {
        onDeleteNote();
        closeNoteEdit();
        onReject && (await onReject());
        setNote('');
    };

    const confirm = async _ => {
        closeNoteEdit();
        onConfirm && (await onConfirm(note));
    };

    const updateNote = text => {
        setNote(text);
    };

    useEffect(() => {
        if (checkin?.[ADDITIONAL_FIELDS]?.[CHECKIN_NOTE_FIELD]) {
            setNote(checkin?.[ADDITIONAL_FIELDS]?.[CHECKIN_NOTE_FIELD]);
        }
    }, [checkin?.[ADDITIONAL_FIELDS]?.[CHECKIN_NOTE_FIELD]]);

    return (
        <Modal
            isVisible={isOpenNoteEdit}
            onBackdropPress={close}
            onBackButtonPress={close}
            backdropTransitionOutTiming={0}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}
            avoidKeyboard={true}>
            <View style={global.modalDialog}>
                {title && (
                    <View style={global.modalTitle}>
                        <Text style={global.modalTitleText}>{title}</Text>
                        <Pressable onPress={close} style={global.modalCloseButton}>
                            <Icon
                                name="close"
                                containerStyle={global.modalCloseIcon}
                                color={theme.colors.white}
                                size={20}
                            />
                        </Pressable>
                    </View>
                )}

                {content && (
                    <View style={[global.modalContent, {paddingVertical: 30}]}>
                        <Text style={global.modalContentText}>{content}</Text>
                        <UITextInput
                            value={note}
                            onChangeText={updateNote}
                            multiline={true}
                            numberOfLines={3}
                            placeholder={t(NOTE_ADD_MSG)}
                            containerStyle={[{marginTop: 20, marginBottom: 0}]}
                        />
                    </View>
                )}

                {showButtons && (
                    <View style={global.modalFooter}>
                        <Button
                            onPress={deleteNote}
                            title={t(DELETE)}
                            containerStyle={global.ModalButton.Negative.containerStyle}
                            buttonStyle={global.ModalButton.Negative.buttonStyle}
                            titleStyle={global.ModalButton.Negative.titleStyle}
                            disabledStyle={global.ModalButton.disabledStyle}
                            disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                        />
                        <Button
                            onPress={confirm}
                            title={t(SAVE_DATA)}
                            containerStyle={global.ModalButton.Positive.containerStyle}
                            buttonStyle={global.ModalButton.Positive.buttonStyle}
                            titleStyle={global.ModalButton.Positive.titleStyle}
                            disabledStyle={global.ModalButton.disabledStyle}
                            disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                        />
                    </View>
                )}
            </View>
        </Modal>
    );
};

export default NoteEditDialog;
