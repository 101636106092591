import { Buffer } from 'buffer';
import utf8 from 'utf8';

export const toBase64 = (input) => Buffer.from(input, 'utf-8').toString('base64');

export const fromBase64 = (encoded) => Buffer.from(encoded, 'base64').toString('utf8');

const base64url = (source) => {
    // Encode in classical base64
    let encodedSource = toBase64(source);
    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');
    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');
    return encodedSource;
}

const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = utf8.decode(base64Url).replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(fromBase64(base64).split('').map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
    const data = JSON.parse(jsonPayload);
    return data;
};

const checkIfExpired = (expTime) => {
    const expDate = new Date(expTime * 1000);
    const now = new Date();
    const isExpired = (expDate <= now) ? true : false;
    if (isExpired) {
        console.log("JWT Token expired");
        console.log(expDate);
    }
    return isExpired;
}

export const verifyJwt = (jwt) => {
    try {
        const token = parseJwt(jwt);
        return (checkIfExpired(token?.['exp'])) ? false : true;
    } catch (e) {
        console.log("Could not verify JWT Token");
        return false;
    }
}

// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTMzNywidXNlcm5hbWUiOiJqb2huLmRvZSJ9
const generateUnsignedAppToken = (name) => {
    const header = {
        "alg": "HS256",
        "typ": "JWT"
    };
    const stringifiedHeader = utf8.encode(JSON.stringify(header));
    const encodedHeader = base64url(stringifiedHeader);
    const data = { app_token: name }
    const stringifiedData = utf8.encode(JSON.stringify(data));
    const encodedData = base64url(stringifiedData);
    const token = encodedHeader + "." + encodedData;
    return token;
}

/*
export const generateAppToken = (name, secret) => {
    const token = jwt.sign({
        app_token: name
    }, secret, {
        algorithm: 'HS256',
        expiresIn: '10y',
        notBefore: 0
    });
    return token;
}
*/