import React, {useEffect} from 'react';
import {Button, Icon} from 'react-native-elements';
import {View} from 'react-native';
import {CREATE, EDIT, PRINT, SHARE_BUTTON_TEXT} from '../../constants/stringsAndFields';
import useStyles from './FooterActionButtons.style';

const FooterActionButtons = ({
    t,
    theme,
    global,
    isMobile,
    onButtonPrint,
    onButtonShare,
    onEditClick,
    isExistingInvoice,
}) => {
    const styles = useStyles(theme);

    const bottomActionButtonsList = [];
    // print, share, edit
    bottomActionButtonsList.push(
        <Button
            title={`${t(PRINT)}`}
            key={PRINT}
            titleStyle={[global.outlineButtonTitle, styles.titleStyle, isMobile && styles.titleStyleMobile]}
            containerStyle={[
                global.Button.containerStyle,
                styles.containerStyle,
                isMobile ? styles.containerStyleMobile : styles.containerStyleDesktop,
            ]}
            buttonStyle={[global.outlineButton, styles.buttonStyle, isMobile && styles.buttonStyleMobile]}
            icon={
                <Icon
                    name="print"
                    type="ionicon"
                    size={isMobile ? 16 : 18}
                    color={theme.colors.primary}
                    containerStyle={[!isMobile && {marginRight: 10}]}
                />
            }
            onPress={onButtonPrint}
        />
    );
    bottomActionButtonsList.push(
        <Button
            title={`${t(SHARE_BUTTON_TEXT)}`}
            key={SHARE_BUTTON_TEXT}
            containerStyle={[
                global.outlineButtonContainer,
                styles.containerStyle,
                isMobile ? styles.containerStyleMobile : styles.containerStyleDesktop,
            ]}
            buttonStyle={[global.outlineButton, styles.buttonStyle, isMobile && styles.buttonStyleMobile]}
            titleStyle={[global.outlineButtonTitle, styles.titleStyle, isMobile && styles.titleStyleMobile]}
            icon={
                <Icon
                    name="share-social"
                    type="ionicon"
                    size={isMobile ? 16 : 18}
                    color={theme.colors.primary}
                    containerStyle={[!isMobile && {marginRight: 10}]}
                />
            }
            onPress={onButtonShare}
        />
    );
    const editCreateButtonLabel = isExistingInvoice ? t(EDIT) : t(CREATE);
    bottomActionButtonsList.push(
        <Button
            title={`${editCreateButtonLabel}`}
            key={EDIT}
            containerStyle={[
                global.outlineButtonContainer,
                styles.containerStyle,
                isMobile ? styles.containerStyleMobile : styles.containerStyleDesktop,
            ]}
            buttonStyle={[global.outlineButton, styles.buttonStyle, isMobile && styles.buttonStyleMobile]}
            titleStyle={[global.outlineButtonTitle, styles.titleStyle, isMobile && styles.titleStyleMobile]}
            icon={
                <Icon
                    name="pencil"
                    type="ionicon"
                    size={isMobile ? 16 : 18}
                    color={theme.colors.primary}
                    containerStyle={[!isMobile && {marginRight: 10}]}
                />
            }
            onPress={onEditClick}
        />
    );

    return <View style={styles.footerActionButtonsWrapper}>{bottomActionButtonsList}</View>;
};

export default FooterActionButtons;
