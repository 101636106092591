import {
    INVOICE_FORM_OVERNIGHT_COUNT,
    INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE,
    INVOICE_FORM_PRICE_FINAL,
    INVOICE_FORM_OVERNIGHT_PDV_AMOUNT,
    INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE,
    INVOICE_FORM_PRICE_PER_NIGHT,
    INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV,
    INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV,
    INVOICE_FORM_USER_IN_PDV_SYSTEM,
    INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED,
    INVOICE_FORM_TOURIST_TAX_ADULT_RATE,
    INVOICE_FORM_TOURIST_TAX_CHILD_RATE,
    INVOICE_FORM_TOURIST_TAX_ADULT_COUNT,
    INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL,
    INVOICE_FORM_TOURIST_TAX_CHILD_COUNT,
    INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL,
    INVOICE_FORM_TOURIST_TAX_TOTAL,
    TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM,
    INVOICE_FORM_PDV_TOTAL_SUM,
    INVOICE_FORM_OVERNIGHT_DISCOUNT_AMOUNT,
    INVOICE_FORM_DISCOUNT_TOTAL_SUM,
    OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV,
} from '../constants/stringsAndFields';
import {getBooleanTrueDefault} from './helpers';
import {getTaxRates} from './invoice/invoice.functions';
export const OVERNIGHT_PDV_PERCENTAGE = 13.0;

export const NUMERIC = '0123456789';
export const NUMERIC_WITH_ZERO = '0123456789.';

export const handleOvernightPriceChanges = (getValues, setValue) => {
    console.log('*** prices handleOvernightPriceChanges ');
    try {
        const overnightCount = Number(getValues(INVOICE_FORM_OVERNIGHT_COUNT)) || 0;
        const pricePerNight = Number(getValues(INVOICE_FORM_PRICE_PER_NIGHT)) || 0;
        const overnightDiscountPercentage = Number(getValues(INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE)) || 0;
        const userInPdvSystem = getBooleanTrueDefault(getValues(INVOICE_FORM_USER_IN_PDV_SYSTEM));
        const overnightPdvPercentage = Number(getValues(INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE)) || 0;
        let overnightPdvAmount = Number(getValues(INVOICE_FORM_OVERNIGHT_PDV_AMOUNT)) || 0;

        //OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV
        const overnightTotalWithoutDiscountBeforePdv = overnightCount * pricePerNight;
        const overnightDiscountAmount = overnightTotalWithoutDiscountBeforePdv * (overnightDiscountPercentage / 100);
        let overnightTotalBeforePdv = overnightTotalWithoutDiscountBeforePdv - overnightDiscountAmount;

        if (userInPdvSystem) {
            overnightPdvAmount = overnightTotalBeforePdv * (overnightPdvPercentage / 100);
            const overnightTotalWithPDV = overnightTotalBeforePdv + overnightPdvAmount;
            setValue(INVOICE_FORM_OVERNIGHT_PDV_AMOUNT, overnightPdvAmount.toFixed(2));
            setValue(INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV, overnightTotalWithPDV.toFixed(2));
        }
        setValue(OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV, overnightTotalWithoutDiscountBeforePdv.toFixed(2));
        setValue(INVOICE_FORM_OVERNIGHT_DISCOUNT_AMOUNT, overnightDiscountAmount.toFixed(2));
        setValue(INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV, overnightTotalBeforePdv.toFixed(2));
    } catch (e) {
        console.log(e);
    }
};

export const handleTouristTaxChanges = (getValues, setValue) => {
    console.log('*** prices handleTouristTaxChanges ');
    const touristTaxAdultRate = Number(getValues(INVOICE_FORM_TOURIST_TAX_ADULT_RATE)) || 0;
    const touristTaxAdultCount = Number(getValues(INVOICE_FORM_TOURIST_TAX_ADULT_COUNT)) || 0;
    const touristTaxChildCount = Number(getValues(INVOICE_FORM_TOURIST_TAX_CHILD_COUNT)) || 0;

    const taxRates = getTaxRates(touristTaxAdultRate);
    // automatically set child rate according to adult rate (which is only editable)
    setValue(INVOICE_FORM_TOURIST_TAX_CHILD_RATE, taxRates[INVOICE_FORM_TOURIST_TAX_CHILD_RATE].toFixed(2));

    const touristTaxAdultSum = touristTaxAdultCount * taxRates[INVOICE_FORM_TOURIST_TAX_ADULT_RATE] ?? 0;
    const touristTaxChildSum = touristTaxChildCount * taxRates[INVOICE_FORM_TOURIST_TAX_CHILD_RATE] ?? 0;
    const touristTaxTotal = touristTaxAdultSum + touristTaxChildSum ?? 0;

    setValue(INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL, touristTaxAdultSum.toFixed(2));
    setValue(INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL, touristTaxChildSum.toFixed(2));
    setValue(INVOICE_FORM_TOURIST_TAX_TOTAL, touristTaxTotal.toFixed(2));
};

export const updateFinalPrice = (getValues, setValue) => {
    console.log('*** prices updateFinalPrice ');
    try {
        const overnightTotalWithoutDiscountBeforePdv =
            Number(getValues(OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV)) || 0;
        const overnightTotalBeforePdv = Number(getValues(INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV)) || 0;
        const overnightDiscountAmount = Number(getValues(INVOICE_FORM_OVERNIGHT_DISCOUNT_AMOUNT)) || 0;
        const overnightPdvAmount = Number(getValues(INVOICE_FORM_OVERNIGHT_PDV_AMOUNT)) || 0;
        const overnightTotalWithPDV = Number(getValues(INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV)) || 0;
        const userInPdvSystem = getBooleanTrueDefault(getValues(INVOICE_FORM_USER_IN_PDV_SYSTEM));
        const touristTaxAlreadyIncluded = getBooleanTrueDefault(getValues(INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED));
        const touristTaxTotal = Number(getValues(INVOICE_FORM_TOURIST_TAX_TOTAL)) || 0;

        let totalWithoutDiscountBeforePdvSum = overnightTotalWithoutDiscountBeforePdv;
        const discountTotalSum = overnightDiscountAmount;
        const pdvTotalSum = overnightPdvAmount;
        let finalPrice = overnightTotalBeforePdv;

        if (userInPdvSystem) {
            finalPrice = overnightTotalWithPDV;
        }
        if (!touristTaxAlreadyIncluded) {
            totalWithoutDiscountBeforePdvSum = totalWithoutDiscountBeforePdvSum + touristTaxTotal;
            finalPrice = finalPrice + touristTaxTotal;
        }
        setValue(INVOICE_FORM_DISCOUNT_TOTAL_SUM, discountTotalSum.toFixed(2));
        setValue(INVOICE_FORM_PDV_TOTAL_SUM, pdvTotalSum.toFixed(2));
        setValue(TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM, totalWithoutDiscountBeforePdvSum.toFixed(2));
        setValue(INVOICE_FORM_PRICE_FINAL, finalPrice.toFixed(2));
    } catch (e) {
        console.log(e);
    }
};

//*** NOVO ***/
export const handleFinalPriceChanges = (getValues, setValue) => {
    console.log('*** prices handleFinalPriceChanges ');
    try {
        const finalPrice = Number(getValues(INVOICE_FORM_PRICE_FINAL)) || 0;
        const touristTaxTotal = Number(getValues(INVOICE_FORM_TOURIST_TAX_TOTAL)) || 0;
        const overnightCount = Number(getValues(INVOICE_FORM_OVERNIGHT_COUNT)) || 0;
        const overnightPdvPercentage = Number(getValues(INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE)) || 0;
        const userInPdvSystem = getBooleanTrueDefault(getValues(INVOICE_FORM_USER_IN_PDV_SYSTEM));
        const touristTaxAlreadyIncluded = getBooleanTrueDefault(getValues(INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED));

        let overnightTotalWithPDV = Number(getValues(INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV)) || 0;

        let overnightTotalBeforePdv = Number(getValues(INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV)) || 0;
        let overnightPdvAmount = Number(getValues(INVOICE_FORM_OVERNIGHT_PDV_AMOUNT)) || 0;
        let pdvTotalSum = Number(getValues(INVOICE_FORM_PDV_TOTAL_SUM)) || 0;
        let pricePerNight = Number(getValues(INVOICE_FORM_PRICE_PER_NIGHT)) || 0;

        let overnightTotalWithoutDiscountBeforePdv =
            Number(getValues(OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV)) || 0;

        let totalWithoutDiscountBeforePdvSum = Number(getValues(TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM)) || 0;

        if (userInPdvSystem) {
            overnightTotalWithPDV = finalPrice - touristTaxTotal;

            overnightTotalBeforePdv = overnightTotalWithPDV / (overnightPdvPercentage / 100 + 1);
            overnightPdvAmount = overnightTotalWithPDV - overnightTotalBeforePdv;
            pdvTotalSum = overnightPdvAmount;
            pricePerNight = overnightTotalBeforePdv / overnightCount;

            overnightTotalWithoutDiscountBeforePdv = overnightCount * pricePerNight;
            totalWithoutDiscountBeforePdvSum = overnightTotalWithoutDiscountBeforePdv;
        } else {
            overnightTotalBeforePdv = finalPrice - touristTaxTotal;
            overnightTotalWithoutDiscountBeforePdv = overnightTotalBeforePdv;
            pricePerNight = overnightTotalBeforePdv / overnightCount;
            totalWithoutDiscountBeforePdvSum = overnightTotalWithoutDiscountBeforePdv;
        }

        if (!touristTaxAlreadyIncluded) {
            totalWithoutDiscountBeforePdvSum = totalWithoutDiscountBeforePdvSum + touristTaxTotal;
        }

        if (userInPdvSystem) {
            setValue(INVOICE_FORM_OVERNIGHT_PDV_AMOUNT, overnightPdvAmount.toFixed(2));
            setValue(INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV, overnightTotalWithPDV.toFixed(2));
        }
        setValue(OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV, overnightTotalWithoutDiscountBeforePdv.toFixed(2));
        setValue(TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM, totalWithoutDiscountBeforePdvSum.toFixed(2));
        setValue(INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV, overnightTotalBeforePdv.toFixed(2));

        setValue(INVOICE_FORM_PDV_TOTAL_SUM, pdvTotalSum.toFixed(2));
        setValue(INVOICE_FORM_PRICE_PER_NIGHT, pricePerNight.toFixed(2));
        setValue(INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV, overnightTotalBeforePdv.toFixed(2));
    } catch (e) {
        console.log(e);
    }
};

export const toFloat = num => {
    try {
        return parseFloat(num).toFixed(2);
    } catch (e) {
        console.log(e);
    }
    return num;
};

export const fixNaNValues = (key, invoice, getValues, setValue) => {
    const checkItem = getValues(key);
    if (isNaN(checkItem) || checkItem === '') {
        setValue(key, '0');
        invoice[key] = '0';
    }
};

//TODO do we need this?
export const recalculateTotalAndPdv = (invoice, setValue, checkin = null) => {
    try {
        const pdv = parseFloat(invoice?.[INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE] ?? 0);
        const pricePerNight = parseFloat(invoice?.[INVOICE_FORM_PRICE_PER_NIGHT] ?? 0);
        const days = Number(invoice?.[INVOICE_FORM_OVERNIGHT_COUNT] ?? 0);
        const discount = parseFloat(invoice?.[INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE] ?? 0);

        const totalPrice = Number(days) * pricePerNight;

        let taxAmmount = 0;
        const includedTaxes = getBooleanTrueDefault(invoice?.[INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED]);
        if (checkin && !includedTaxes) {
            taxAmmount = parseFloat(invoice?.[INVOICE_FORM_TOURIST_TAX_TOTAL] ?? 0);
            console.log(taxAmmount);
        }

        let finalPrice = totalPrice - totalPrice * (discount / 100) + taxAmmount;

        // add pdv only if price per night is > 0
        if (pdv && pricePerNight) {
            const calculatedPdv = finalPrice * (pdv / 100);
            finalPrice = finalPrice + calculatedPdv;
            invoice[INVOICE_FORM_OVERNIGHT_PDV_AMOUNT] = String(calculatedPdv.toFixed(2));
            setValue(INVOICE_FORM_OVERNIGHT_PDV_AMOUNT, String(calculatedPdv.toFixed(2)));
        }
        if (totalPrice) {
            invoice[INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV] = String(totalPrice.toFixed(2));
            setValue(INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV, String(totalPrice.toFixed(2)));
        }
        if (finalPrice) {
            invoice[INVOICE_FORM_PRICE_FINAL] = String(finalPrice.toFixed(2));
            setValue(INVOICE_FORM_PRICE_FINAL, String(finalPrice.toFixed(2)));
        }
        return {
            [INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV]: invoice[INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV],
            [INVOICE_FORM_PRICE_FINAL]: invoice[INVOICE_FORM_PRICE_FINAL],
        };
    } catch (e) {
        console.log(e);
    }
    return null;
};
