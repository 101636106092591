export const logoQrSvg = (
    <svg width="101" height="18" viewBox="0 0 101 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M26.3785 15.4221C25.3867 15.4221 24.5175 15.2285 23.7708 14.8413C23.0354 14.443 22.4615 13.901 22.0491 13.2151C21.648 12.5292 21.4474 11.7492 21.4474 10.8753C21.4474 9.99022 21.6424 9.21029 22.0324 8.53545C22.4336 7.84955 22.9796 7.313 23.6706 6.9258C24.3726 6.5386 25.1694 6.345 26.0609 6.345C26.9301 6.345 27.7046 6.53307 28.3843 6.90921C29.0641 7.28535 29.599 7.81636 29.989 8.50226C30.3791 9.18816 30.5741 9.99575 30.5741 10.925C30.5741 11.0135 30.5685 11.1131 30.5574 11.2237C30.5574 11.3344 30.5518 11.4395 30.5406 11.539H23.1022V10.1617H29.4374L28.6184 10.5931C28.6295 10.0843 28.5236 9.63621 28.3008 9.24901C28.0779 8.86181 27.7714 8.55758 27.3814 8.33632C27.0025 8.11506 26.5623 8.00443 26.0609 8.00443C25.5483 8.00443 25.0969 8.11506 24.7069 8.33632C24.328 8.55758 24.0272 8.86734 23.8043 9.2656C23.5925 9.6528 23.4867 10.1119 23.4867 10.6429V10.9748C23.4867 11.5058 23.6093 11.976 23.8544 12.3853C24.0996 12.7947 24.445 13.11 24.8908 13.3312C25.3365 13.5525 25.8491 13.6631 26.4286 13.6631C26.9301 13.6631 27.3814 13.5857 27.7826 13.4308C28.1837 13.2759 28.5403 13.0325 28.8524 12.7006L29.9723 13.9784C29.5711 14.443 29.0641 14.8026 28.4512 15.057C27.8494 15.3004 27.1585 15.4221 26.3785 15.4221Z"
            fill="#414141"
        />
        <path
            d="M38.1975 15.4719C37.306 15.4719 36.4758 15.3281 35.7069 15.0404C34.9491 14.7417 34.2861 14.3269 33.7178 13.7959C33.1606 13.2538 32.726 12.6177 32.414 11.8875C32.1019 11.1574 31.9459 10.3608 31.9459 9.49792C31.9459 8.63502 32.1019 7.83849 32.414 7.10834C32.726 6.37819 33.1662 5.74761 33.7345 5.21659C34.3028 4.67451 34.9659 4.25965 35.7236 3.97201C36.4814 3.67331 37.3116 3.52397 38.2142 3.52397C39.1726 3.52397 40.0474 3.68991 40.8386 4.0218C41.6298 4.34262 42.2984 4.82385 42.8444 5.4655L41.4403 6.77645C41.0169 6.32288 40.5433 5.98546 40.0195 5.7642C39.4958 5.53188 38.9274 5.41572 38.3145 5.41572C37.7016 5.41572 37.1389 5.51528 36.6263 5.71442C36.1248 5.91355 35.6846 6.19565 35.3057 6.56073C34.938 6.9258 34.6483 7.35725 34.4365 7.85508C34.2359 8.35291 34.1357 8.90053 34.1357 9.49792C34.1357 10.0953 34.2359 10.6429 34.4365 11.1408C34.6483 11.6386 34.938 12.07 35.3057 12.4351C35.6846 12.8002 36.1248 13.0823 36.6263 13.2814C37.1389 13.4806 37.7016 13.5801 38.3145 13.5801C38.9274 13.5801 39.4958 13.4695 40.0195 13.2482C40.5433 13.0159 41.0169 12.6674 41.4403 12.2028L42.8444 13.5303C42.2984 14.1609 41.6298 14.6422 40.8386 14.9741C40.0474 15.3059 39.167 15.4719 38.1975 15.4719Z"
            fill="#414141"
        />
        <path
            d="M49.7908 6.345C50.504 6.345 51.1392 6.48329 51.6964 6.75986C52.2647 7.03643 52.7104 7.46235 53.0336 8.03762C53.3568 8.60183 53.5184 9.33198 53.5184 10.2281V15.3059H51.4289V10.4936C51.4289 9.70812 51.2395 9.12178 50.8606 8.73458C50.4928 8.34738 49.9747 8.15378 49.306 8.15378C48.8157 8.15378 48.3811 8.25335 48.0022 8.45248C47.6233 8.65161 47.328 8.95031 47.1163 9.34857C46.9157 9.73578 46.8154 10.2281 46.8154 10.8255V15.3059H44.726V2.99295H46.8154V8.83415L46.3641 8.104C46.6761 7.53979 47.1275 7.10834 47.7181 6.80964C48.3198 6.49988 49.0107 6.345 49.7908 6.345Z"
            fill="#414141"
        />
        <path
            d="M60.3809 15.4221C59.3891 15.4221 58.5199 15.2285 57.7732 14.8413C57.0378 14.443 56.4639 13.901 56.0515 13.2151C55.6504 12.5292 55.4498 11.7492 55.4498 10.8753C55.4498 9.99022 55.6448 9.21029 56.0348 8.53545C56.436 7.84955 56.982 7.313 57.6729 6.9258C58.375 6.5386 59.1718 6.345 60.0633 6.345C60.9325 6.345 61.707 6.53307 62.3867 6.90921C63.0665 7.28535 63.6014 7.81636 63.9914 8.50226C64.3815 9.18816 64.5765 9.99575 64.5765 10.925C64.5765 11.0135 64.5709 11.1131 64.5597 11.2237C64.5597 11.3344 64.5542 11.4395 64.543 11.539H57.1046V10.1617H63.4398L62.6207 10.5931C62.6319 10.0843 62.526 9.63621 62.3032 9.24901C62.0803 8.86181 61.7738 8.55758 61.3838 8.33632C61.0049 8.11506 60.5647 8.00443 60.0633 8.00443C59.5507 8.00443 59.0993 8.11506 58.7093 8.33632C58.3304 8.55758 58.0295 8.86734 57.8067 9.2656C57.5949 9.6528 57.4891 10.1119 57.4891 10.6429V10.9748C57.4891 11.5058 57.6117 11.976 57.8568 12.3853C58.102 12.7947 58.4474 13.11 58.8932 13.3312C59.3389 13.5525 59.8515 13.6631 60.431 13.6631C60.9325 13.6631 61.3838 13.5857 61.785 13.4308C62.1861 13.2759 62.5427 13.0325 62.8548 12.7006L63.9747 13.9784C63.5735 14.443 63.0665 14.8026 62.4536 15.057C61.8518 15.3004 61.1609 15.4221 60.3809 15.4221Z"
            fill="#414141"
        />
        <path
            d="M70.6788 15.4221C69.7427 15.4221 68.907 15.2285 68.1715 14.8413C67.4471 14.443 66.8788 13.901 66.4665 13.2151C66.0542 12.5292 65.848 11.7492 65.848 10.8753C65.848 9.99022 66.0542 9.21029 66.4665 8.53545C66.8788 7.84955 67.4471 7.313 68.1715 6.9258C68.907 6.5386 69.7427 6.345 70.6788 6.345C71.548 6.345 72.3114 6.52201 72.9688 6.87602C73.6375 7.21897 74.1445 7.72786 74.49 8.4027L72.8853 9.33198C72.6178 8.91159 72.2891 8.60183 71.899 8.4027C71.5202 8.20357 71.1078 8.104 70.6621 8.104C70.1495 8.104 69.687 8.21463 69.2747 8.43589C68.8624 8.65714 68.5392 8.97797 68.3052 9.39836C68.0712 9.80768 67.9542 10.3 67.9542 10.8753C67.9542 11.4505 68.0712 11.9484 68.3052 12.3687C68.5392 12.7781 68.8624 13.0934 69.2747 13.3146C69.687 13.5359 70.1495 13.6465 70.6621 13.6465C71.1078 13.6465 71.5202 13.5469 71.899 13.3478C72.2891 13.1487 72.6178 12.8389 72.8853 12.4185L74.49 13.3478C74.1445 14.0116 73.6375 14.526 72.9688 14.8911C72.3114 15.2451 71.548 15.4221 70.6788 15.4221Z"
            fill="#414141"
        />
        <path
            d="M77.9195 13.3312L77.9697 10.7259L82.6333 6.44457H85.1406L81.179 10.394L80.0758 11.3067L77.9195 13.3312ZM76.2145 15.3059V2.99295H78.304V15.3059H76.2145ZM82.9509 15.3059L79.6914 11.3067L81.0119 9.64727L85.4916 15.3059H82.9509Z"
            fill="#414141"
        />
        <path
            d="M86.8413 15.3059V6.44457H88.9307V15.3059H86.8413ZM87.8944 4.98427C87.5043 4.98427 87.1812 4.86257 86.9249 4.61919C86.6797 4.37581 86.5571 4.08264 86.5571 3.73969C86.5571 3.38568 86.6797 3.09251 86.9249 2.86019C87.1812 2.61681 87.5043 2.49512 87.8944 2.49512C88.2844 2.49512 88.602 2.61128 88.8472 2.8436C89.1035 3.06486 89.2316 3.34696 89.2316 3.68991C89.2316 4.05498 89.109 4.36474 88.8639 4.61919C88.6187 4.86257 88.2955 4.98427 87.8944 4.98427Z"
            fill="#414141"
        />
        <path
            d="M96.7379 6.345C97.4511 6.345 98.0863 6.48329 98.6435 6.75986C99.2118 7.03643 99.6576 7.46235 99.9807 8.03762C100.304 8.60183 100.465 9.33198 100.465 10.2281V15.3059H98.3761V10.4936C98.3761 9.70812 98.1866 9.12178 97.8077 8.73458C97.44 8.34738 96.9218 8.15378 96.2532 8.15378C95.7629 8.15378 95.3283 8.25335 94.9494 8.45248C94.5705 8.65161 94.2752 8.95031 94.0634 9.34857C93.8629 9.73578 93.7626 10.2281 93.7626 10.8255V15.3059H91.6731V6.44457H93.6623V8.83415L93.3112 8.104C93.6233 7.53979 94.0746 7.10834 94.6652 6.80964C95.267 6.49988 95.9579 6.345 96.7379 6.345Z"
            fill="#414141"
        />
        <path
            d="M15.8955 9.42301C15.9014 9.35563 15.9055 9.30931 15.907 9.2093V9L16.1163 8.9994H18.0008C18.0005 9.2265 17.9916 9.45504 17.9738 9.68463C17.8936 10.7492 17.6283 11.7621 17.2099 12.6912C16.673 13.8868 15.8878 14.9314 14.9285 15.7704C13.8899 16.6805 12.6401 17.3555 11.2621 17.7122C10.3366 17.953 9.36032 18.0485 8.36345 17.9766C7.26459 17.8998 6.22011 17.6257 5.26521 17.1894C4.08349 16.6512 3.05089 15.8697 2.22054 14.9174C1.32982 13.8977 0.665386 12.6753 0.305551 11.3284C0.0469529 10.3651 -0.0540354 9.34561 0.0277193 8.30437C0.106688 7.27045 0.36036 6.2854 0.759271 5.37869C1.00039 4.82886 1.29384 4.31044 1.63248 3.82864L1.63329 3.82975C3.26149 1.51359 5.95418 0 9.00038 0C10.2684 0 11.4751 0.262257 12.5693 0.735467C13.2113 1.01223 13.811 1.36015 14.3602 1.76798L12.859 3.26934C12.0424 2.71855 11.0944 2.33462 10.0565 2.174L10.0566 2.17315C9.71227 2.1203 9.35953 2.09289 9.00038 2.09289C6.17002 2.09289 3.73732 3.79556 2.67063 6.23253C2.43747 6.76693 2.26867 7.33985 2.17532 7.94315C2.14832 8.11768 2.1281 8.29169 2.11447 8.46492C2.10097 8.64131 2.09409 8.81956 2.09409 8.99942C2.09409 9.61542 2.17474 10.2126 2.32604 10.7809C2.85278 12.7501 4.23747 14.4145 6.11597 15.2766C6.68365 15.5371 7.29644 15.7244 7.94426 15.8246C8.13808 15.8546 8.33125 15.8762 8.52342 15.8897C8.68102 15.9005 8.84006 15.9059 9.00038 15.9059C9.59215 15.9059 10.1665 15.8315 10.7147 15.6915C12.7289 15.1745 14.4323 13.7617 15.2979 11.8387C15.5478 11.2837 15.7279 10.6862 15.8254 10.0555C15.8529 9.87806 15.8733 9.7012 15.887 9.52515C15.8901 9.48361 15.8929 9.45143 15.8955 9.42301Z"
            fill="#5D4D9D"
        />
        <path
            d="M17.1298 1.96206L18.6098 3.44205L8.98988 13.062L4.9199 8.99206L6.39989 7.51207L8.98986 10.102L17.1298 1.96206Z"
            fill="#5D4D9D"
        />
    </svg>
);
