import {
    ADDITIONAL_FIELDS,
    CREATED_AT,
    ID,
    NETWORK_OFFLINE_ERROR_MSG,
    PIN,
    PIN_ID,
    REMOTE_CHECKIN_EXISTS,
    SLUG,
    UPDATED_AT,
    guests as guests_field,
    id,
} from '../constants/stringsAndFields';
import {
    CHECKINS_TABLE,
    deleteCheckin as deleteLocalCheckin,
    getCheckinDb,
    getCheckinsForToday,
    getCheckinsWithoutGuestData,
    insertCheckin,
    reCreateCheckinTable,
    updateCheckin as updateLocalCheckin,
} from '../database/checkin';
import {OFFLINE_STORAGE_SUPPORTED, getTableCount} from '../database/common';
import {
    createEvisitorTable,
    deleteAllEvisitors,
    getEvisitor,
    getEvisitors,
    insertEvisitor,
    insertEvisitors,
    updateEvisitor,
} from '../database/evisitor';
import {
    createGuestTable,
    deleteGuest as deleteLocalGuest,
    getGuest,
    insertGuest,
    updateGuest as updateLocalGuest,
} from '../database/guest';
import {
    createInvoiceTable,
    deleteInvoice as deleteInvoiceDb,
    getInvoice,
    getInvoicesByEvAccount,
    insertInvoice,
    updateInvoice as updateInvoiceDb,
} from '../database/invoice';
import {exportDatabase} from '../database/sqlOperations';
import {CHECKINS_ALL_PAGES, CHECKINS_FIRST_PAGE} from '../screens/CheckinsScreen';
import {INVOICES_ALL_PAGES} from '../screens/InvoicesScreen';
import {getDateFromSql} from '../utils/dateHelper';
import {deleteContentAndPreserveNote} from '../utils/guestUtils';
import {isOnline} from '../utils/network';
import {getUserId} from '../utils/userUtils';
import {
    RECEPCIJA_FIRST_PAGE,
    createOrUpdateGuestRest,
    deleteCheckinRest,
    deleteGuestRest,
    deleteInvoice,
    deleteReservationGuest,
    fetchAllCheckinsFromRest,
    fetchAllInvoicesFromRest,
    fetchCheckinsFromRest,
    fetchEvisitorAccountFromRest,
    fetchEvisitorAccountsFromRest,
    fetchGuest,
    fetchInvoicesFromRest,
    fetchReservationsFromRest,
    postInvoice,
    postReservationGuest,
    putInvoice,
    saveCheckinRest,
    saveGuestRest,
    updateCheckinRest,
    updateGuestRest,
} from './inCheckinRestService';

export async function fetchEvisitorAccounts(db, forceRefresh = false, emitMessage, fullEvisitorDownload = false) {
    let fetchedData = [];
    if (localDbSupported(db)) {
        fetchedData = await getEvisitors(db);
    }
    const online = await isOnline();
    if (online && (fetchedData.length < 1 || forceRefresh)) {
        fetchedData = await fetchEvisitorAccountsFromRest(emitMessage, fullEvisitorDownload);
        if (localDbSupported(db)) {
            await deleteAllEvisitors(db);
            await insertEvisitors(db, fetchedData);
        }
    }
    await exportDatabase(db);
    return fetchedData;
}

export async function fetchEvisitorAccount(db, evisitorAccount, emitMessage) {
    const online = await isOnline();
    if (online) {
        const evisitorData = await fetchEvisitorAccountFromRest(evisitorAccount, evisitorAccount[PIN_ID], emitMessage);
        if (localDbSupported(db)) {
            const existingEvAccount = await getEvisitor(db, evisitorAccount[ID]);
            if (existingEvAccount) {
                await updateEvisitor(db, evisitorData);
            } else {
                await insertEvisitor(db, evisitorData);
            }
            await exportDatabase(db);
        }
    }
}

export async function fetchCheckins(db, page = CHECKINS_FIRST_PAGE, filter = null, forceRefresh = false) {
    const online = await isOnline();
    let localCheckinsCount = 0;
    let hasNext = true;
    if (localDbSupported(db)) {
        localCheckinsCount = await getTableCount(db, CHECKINS_TABLE);
    }
    if (online && (localCheckinsCount < 1 || forceRefresh)) {
        const remoteData =
            page === CHECKINS_ALL_PAGES
                ? await fetchAllCheckinsFromRest(filter)
                : await fetchCheckinsFromRest(page, filter);
        const {count, next, results} = remoteData;
        hasNext = next;
        if (localDbSupported(db) && count !== 0) {
            for (const remoteCheckin of results) {
                await mergeCheckin(db, remoteCheckin);
            }
            await exportDatabase(db);
        }
    }
    const evAccount = {[PIN]: filter['evisitor_oib']};
    const localData = await getCheckinsWithoutGuestData(db, evAccount);
    return {checkins: localData, hasNext: hasNext};
}

export async function fetchCheckin(db, checkinId, returnData = false) {
    const online = await isOnline();
    if (online) {
        try {
            const remoteCheckin = await fetchCheckinsFromRest(null, {checkinId: checkinId});
            if (remoteCheckin && localDbSupported(db)) {
                await mergeCheckin(db, remoteCheckin);
                await exportDatabase(db);
                return remoteCheckin;
            } else {
                console.log('local storage not supported');
            }
        } catch (e) {
            console.log(e);
            if (e?.RESTErrors?.type === 'CheckinNotFound' && returnData) {
                return null;
            } else {
                throw e;
            }
        }
    }
    return null;
}

export async function saveCheckin(db, data) {
    if (localDbSupported(db)) {
        await insertCheckin(db, data);
        await exportDatabase(db);
    }
    const online = await isOnline();
    if (online) {
        try {
            const createRes = await saveCheckinRest(data);
            const slug = createRes?.[SLUG];
            if (slug) {
                const additionalFields = data[ADDITIONAL_FIELDS];
                await updateLocalCheckin(db, {
                    [id]: data[id],
                    [SLUG]: slug,
                    [ADDITIONAL_FIELDS]: {
                        ...additionalFields,
                        [REMOTE_CHECKIN_EXISTS]: true,
                    },
                });
            }
        } catch (e) {
            console.log(e);
        }
    }
}

export async function updateCheckin(db, data) {
    if (localDbSupported(db)) {
        await updateLocalCheckin(db, data);
        await exportDatabase(db);
    }
    const online = await isOnline();
    if (online) {
        await updateCheckinRest(data);
    }
}

export async function updateLocalCheckinDb(db, data) {
    if (localDbSupported(db)) {
        await updateLocalCheckin(db, data);
        await exportDatabase(db);
    }
}

export async function deleteCheckin(db, data, runExport = true) {
    if (localDbSupported(db)) {
        await deleteLocalCheckin(db, data);
        await exportDatabase(db);
    }
    const online = await isOnline();
    if (online) {
        await deleteCheckinRest(data);
    }
}

export async function saveGuest(db, data, runExport = true) {
    if (localDbSupported(db)) {
        await insertGuest(db, data);
        await exportDatabase(db);
    }
    const online = await isOnline();
    if (online) {
        await saveGuestRest(data);
    }
}

export async function updateGuest(db, data, removeContent) {
    if (localDbSupported(db)) {
        await updateLocalGuest(db, data);
        await exportDatabase(db);
    }
    const online = await isOnline();
    if (online) {
        if (removeContent) {
            deleteContentAndPreserveNote(data);
        }
        await updateGuestRest(data);
    }
}

export async function createOrUpdateGuest(db, data, create, omitGuestCheck) {
    const guestId = data?.[id];
    if (guestId) {
        if (localDbSupported(db)) {
            const existingLocalGuest = await getGuest(db, guestId);
            if (create || !existingLocalGuest) {
                await insertGuest(db, data);
            } else {
                await updateLocalGuest(db, data);
            }
            await exportDatabase(db);
        }
        const online = await isOnline();
        if (online) {
            if (omitGuestCheck) {
                await createOrUpdateGuestRest(data, create);
            } else {
                let remoteGuestExists = true;
                try {
                    await fetchGuest(guestId);
                } catch (e) {
                    console.log(e);
                    remoteGuestExists = false;
                }
                if (remoteGuestExists) {
                    await createOrUpdateGuestRest(data, create);
                } else {
                    await createOrUpdateGuestRest(data, true);
                }
            }
        }
    }
}

export async function deleteGuest(db, data) {
    if (localDbSupported(db)) {
        await deleteLocalGuest(db, data);
        await exportDatabase(db);
    }
    const online = await isOnline();
    if (online) {
        try {
            await deleteGuestRest(data);
        } catch (e) {
            console.log(e);
        }
    }
}

export async function saveInvoice(db, data) {
    const online = await isOnline();
    if (!online) {
        throw new Error(NETWORK_OFFLINE_ERROR_MSG);
    }
    let remoteGeneratedId = null;
    try {
        const remoteInvoice = await postInvoice(data);
        if (remoteInvoice) {
            remoteGeneratedId = remoteInvoice?.[id];
        }
    } catch (e) {
        throw e;
    } finally {
        if (localDbSupported(db)) {
            if (remoteGeneratedId) {
                data[id] = remoteGeneratedId;
            }
            await insertInvoice(db, data);
            await exportDatabase(db);
        }
    }
}

export async function updateInvoice(db, data) {
    if (localDbSupported(db)) {
        await updateInvoiceDb(db, data);
        await exportDatabase(db);
    }
    const online = await isOnline();
    if (online) {
        try {
            await putInvoice(data);
        } catch (e) {
            console.log(e);
        }
    }
}

export async function removeInvoice(db, data) {
    if (localDbSupported(db)) {
        await deleteInvoiceDb(db, data);
        await exportDatabase(db);
    }
    const online = await isOnline();
    if (online) {
        try {
            await deleteInvoice(data);
        } catch (e) {
            console.log(e);
        }
    }
}

export async function fetchInvoices(
    db,
    evAccount,
    evaccounts,
    page = INVOICE_FIRST_PAGE,
    forceRefresh = false,
    fromYear = null
) {
    const online = await isOnline();
    let localInvoicesCount = 0;
    if (localDbSupported(db)) {
        localInvoicesCount = await getInvoicesByEvAccount(db, evAccount, evaccounts, true);
    }
    if (online && (localInvoicesCount < 1 || forceRefresh)) {
        try {
            const remoteData =
                page === INVOICES_ALL_PAGES
                    ? await fetchAllInvoicesFromRest(evAccount, fromYear)
                    : await fetchInvoicesFromRest(evAccount, evaccounts, page, fromYear);
            const {count, results} = remoteData;
            if (localDbSupported(db) && count !== 0) {
                for (const remoteInvoice of results) {
                    await mergeInvoice(db, remoteInvoice);
                }
                await exportDatabase(db);
            }
        } catch (e) {
            console.log(e);
        }
    }
}

// not used
export async function saveRecepcijaGuest(db, data) {
    const online = await isOnline();
    if (!online) {
        throw new Error(NETWORK_OFFLINE_ERROR_MSG);
    }
    try {
        await postReservationGuest(data);
        if (localDbSupported(db)) {
            await insertGuest(db, data);
            await exportDatabase(db);
        }
    } catch (e) {
        throw e;
    }
}

export async function removeRecepcijaGuest(db, data) {
    if (localDbSupported(db)) {
        await deleteLocalGuest(db, data);
        await exportDatabase(db);
    }
    const online = await isOnline();
    if (online) {
        try {
            await deleteReservationGuest(data);
        } catch (e) {
            console.log(e);
        }
    }
}

export async function fetchRecepcijaReservationsLocal(db, evAccount, page = RECEPCIJA_FIRST_PAGE) {
    const reservations = await getCheckinsForToday(db, evAccount);
    return reservations;
}

export async function fetchRecepcijaReservationsRemote(db, evAccount, page = RECEPCIJA_FIRST_PAGE) {
    const online = await isOnline();
    const userId = await getUserId();
    let reservations = [];
    if (online) {
        try {
            const remoteData = await fetchReservationsFromRest(userId, evAccount, page);
            const {count, results} = remoteData;
            reservations = results;
            if (localDbSupported(db) && count !== 0) {
                for (const remoteCheckin of results) {
                    await mergeCheckin(db, remoteCheckin);
                }
                await exportDatabase(db);
            }
        } catch (e) {
            console.log(e);
        }
    }
    return reservations;
}

export async function deleteAllData(db) {
    await reCreateCheckinTable(db);
    await createGuestTable(db);
    await createEvisitorTable(db);
    await createInvoiceTable(db);
    await exportDatabase(db);
}

const mergeCheckin = async (db, remoteCheckin) => {
    const localCheckin = await getCheckinDb(db, remoteCheckin[id]);
    // if checkin is already stored
    if (localCheckin) {
        const localCheckinDate = getDateFromSql(localCheckin[UPDATED_AT]);
        const remoteCheckinTimestamp = remoteCheckin?.[UPDATED_AT] ?? remoteCheckin?.[CREATED_AT];
        const remoteCheckinDate = getDateFromSql(remoteCheckinTimestamp);

        if (localCheckinDate < remoteCheckinDate) {
            //console.log('Remote checkin newer, inserting!');
            await insertCheckin(db, remoteCheckin);
        }

        if (remoteCheckin?.hasOwnProperty(guests_field)) {
            for (let remoteGuest of remoteCheckin[guests_field]) {
                const localGuest = localCheckin[guests_field]?.find(guestFilter => guestFilter[id] === remoteGuest[id]);
                if (localGuest) {
                    const localGuestDate = getDateFromSql(localGuest?.[UPDATED_AT]);
                    const remoteGuestTimestamp = remoteGuest?.[UPDATED_AT] ?? remoteGuest?.[CREATED_AT];
                    const remoteGuestDate = getDateFromSql(remoteGuestTimestamp);

                    if (localGuestDate && remoteGuestDate) {
                        if (localGuestDate < remoteGuestDate) {
                            //console.log('Remote guest newer, inserting!');
                            await insertGuest(db, remoteGuest);
                        }
                    }
                } else {
                    // guest is not on local device
                    await insertGuest(db, remoteGuest);
                }
            }
        }
    } else {
        // checkin is not stored
        await insertCheckin(db, remoteCheckin);
        if (remoteCheckin?.hasOwnProperty(guests_field)) {
            for (let remoteGuest of remoteCheckin[guests_field]) {
                await insertGuest(db, remoteGuest);
            }
        }
    }
};

const mergeInvoice = async (db, remoteInvoice) => {
    const localInvoice = await getInvoice(db, remoteInvoice[id]);
    // if invoice is already stored
    if (localInvoice) {
        const localInvoiceDate = getDateFromSql(localInvoice[UPDATED_AT]);
        const remoteInvoiceTimestamp = remoteInvoice?.[UPDATED_AT] ?? remoteInvoice?.[CREATED_AT];
        const remoteInvoiceDate = getDateFromSql(remoteInvoiceTimestamp);
        if (localInvoiceDate < remoteInvoiceDate) {
            //console.log('Remote invoice newer, inserting!');
            await insertInvoice(db, remoteInvoice);
        }
    } else {
        // invoice is not stored
        await insertInvoice(db, remoteInvoice);
    }
};

const localDbSupported = db => {
    return OFFLINE_STORAGE_SUPPORTED && db;
};
