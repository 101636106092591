import {useIsFocused} from '@react-navigation/native';
import React, {useContext, useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {Path, Svg} from 'react-native-svg';
import DataContext from '../context/dataContext';
import {sleep} from '../utils/promiseHelpers';

const SplashScreen = ({navigation}) => {
    const {loggedIn, startupCompleted} = useContext(DataContext);
    const isFocused = useIsFocused();

    useEffect(() => {
        if (isFocused && startupCompleted) {
            if (loggedIn) {
                sleep(200).then(() => {
                    navigation.navigate('Root');
                });
            } else {
                navigation.navigate('Login');
            }
        }
    }, [loggedIn, isFocused, startupCompleted]);

    return (
        <View style={styles.container}>
            <Svg width="100" height="97" viewBox="0 0 100 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                <Path
                    d="M92.0473 10.6556L100 18.6083L48.3071 70.3012L40.3544 62.3484L92.0473 10.6556Z"
                    fill="#5D4D9D"
                />
                <Path
                    d="M56.2599 62.3484L48.3071 70.3012L26.4371 48.4311L34.3898 40.4783L56.2599 62.3484Z"
                    fill="#5D4D9D"
                />
                <Path
                    d="M85.4763 49.5986C85.4652 50.3627 85.4253 50.5436 85.3687 51.2958C85.2954 52.2418 85.1856 53.1922 85.0381 54.1454C84.5137 57.5347 83.5462 60.7454 82.2036 63.7277C77.5521 74.0607 68.3989 81.6526 57.5753 84.4308C54.6299 85.1831 51.5434 85.5831 48.3636 85.5831C47.5021 85.5831 46.6475 85.5537 45.8007 85.496C44.768 85.4233 43.73 85.3072 42.6885 85.146C39.2074 84.6073 35.9146 83.6011 32.8642 82.2012C22.7701 77.5688 15.3294 68.6253 12.499 58.0438C11.686 54.9897 11.2526 51.7809 11.2526 48.4708C11.2526 47.5043 11.2896 46.5465 11.3621 45.5986C11.4353 44.6678 11.544 43.7327 11.6891 42.7949C12.1907 39.553 13.0978 36.4745 14.3506 33.6029C20.0825 20.5078 33.1546 11.3585 48.3636 11.3585C50.2935 11.3585 52.1889 11.5058 54.0394 11.7898L54.0387 11.7943C59.6159 12.6574 64.7097 14.7205 69.0981 17.6801L77.1649 9.61254C74.2137 7.42107 70.991 5.55154 67.5411 4.06435C61.6614 1.52154 55.1771 0.112305 48.3636 0.112305C31.9948 0.112305 17.5256 8.24559 8.77651 20.6915L8.77211 20.6855C6.95245 23.2745 5.37561 26.0603 4.07995 29.0148C1.93639 33.887 0.573291 39.1802 0.14895 44.7359C-0.29036 50.3311 0.252302 55.8092 1.64188 60.9858C3.57546 68.2232 7.14582 74.7919 11.9321 80.2711C16.394 85.3883 21.9426 89.5879 28.2927 92.48C33.4238 94.8241 39.0363 96.2971 44.941 96.71C50.2977 97.0961 55.5439 96.5828 60.5171 95.2892C67.9215 93.3724 74.6376 89.7454 80.2186 84.8548C85.373 80.3464 89.5926 74.7334 92.4773 68.3088C94.7257 63.3163 96.1516 57.8734 96.5823 52.1528C96.6778 50.9191 96.7256 49.691 96.7272 48.4707H86.0207H86.601L85.4763 48.4739V49.5986Z"
                    fill="#5D4D9D"
                />
            </Svg>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default SplashScreen;
