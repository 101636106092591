// Navigation
export const CHECKIN_LABEL = 'CHECKIN_LABEL';
export const CHECKINS = 'CHECKINS';
export const RESERVATIONS = 'RESERVATIONS';
export const RESERVATION_LABEL = 'RESERVATION_LABEL';
export const INVOICES = 'INVOICES';
export const GUESTS = 'GUESTS';
export const CALENDAR = 'CALENDAR';
export const MORE = 'MORE';
export const ACCOUNT = 'ACCOUNT';
export const LEGAL = 'LEGAL';
export const INVOICE = 'INVOICE';
export const PROFORMA_INVOICE = 'PROFORMA_INVOICE';
export const PACKAGES = 'PACKAGES';
export const SCANNER_LABEL = 'SCANNER_LABEL';

// Dummy
export const LOREM_IPSUM = 'LOREM_IPSUM';

// Gender
export const MALE = 'MALE';
export const FEMALE = 'FEMALE';
export const GENDERS = {[MALE]: 'Muški', [FEMALE]: 'Ženski'};

// Static data fields
export const COUNTRY_DATA = 'CountryLookup';
export const RECORDS = 'Records';
export const COUNTRY_NAME = 'AlternativeName';
export const COUNTRY_CODE = 'CodeThreeLetters';
export const CITY_NAME = 'CityName';
export const NAME_NATIONAL = 'NameNational';
export const NAME_NATIONAL_SHORT = 'NameNationalShort';
export const NAME_CITIZENSHIP = 'NameCitizenships';
export const ADDRESS = 'Address';
export const AMOUNT = 'Amount';
export const CROATIA_COUNTRY_CODE = 'HRV';
export const SETTLEMENTS_DATA = 'SettlementLookup';
export const NAME = 'Name';
export const DOCUMENT_TYPE_DATA = 'DocumentTtypeLookup';
export const CODE = 'Code';
export const CODE_MI = 'CodeMI';
export const SERVICE_TYPE_DATA = 'OfferedServiceTypeLookup';
export const PAYMENT_CATEGORY_DATA = 'TTPaymentCategoryLookup';
export const ARRIVAL_ORGANIZATION_DATA = 'ArrivalOrganisationLookup';
export const PIN_ID = 'pin_id';
export const PIN = 'Pin';
export const STREET_NUMBER = 'StreetNumber';
export const ACCOMODATION_OBJECT = 'ACCOMODATION_OBJECT';
export const ACCOMODATION_UNIT = 'ACCOMODATION_UNIT';
export const ACCOMODATION_PAYMENT_CATEGORIES = 'ACCOMODATION_PAYMENT_CATEGORIES';
export const OBJECT = 'OBJECT';
export const guests = 'guests';
export const guest = 'guest';
export const content = 'content';
export const status = 'status';
export const EV_STATIC_DATA = 'EV_STATIC_DATA';
export const ACTIVE = 'Active';
export const SEQUENCE = 'Sequence';
export const CREDITS = 'CREDITS';
export const CREDITS_NOTE = 'CREDITS_NOTE';
export const CREDITS_USAGE = 'CREDITS_USAGE';
export const BEST_BEFORE = 'BEST_BEFORE';
export const UNLIMITED = 'UNLIMITED';
export const DURATION = 'DURATION';
export const code = 'code';
export const name = 'name';
export const settings = 'settings';
export const CREDITS_EXPIRATION_OVERRIDE = 'credits_expiration_override';
export const SORT = 'SORT';
export const DATA = 'DATA';
export const AMOUNT_FIELD = 'AmountString';
export const MODEL_FIELD = 'Model';
export const REFNO_FIELD = 'RefNo';
export const EXECUTION_DATE_FIELD = 'ExecutionDate';

// Checkin in status
export const STATUS = 'status';
// GUEST evStatusFields
export const NOT_CHECKED_IN_STATUS = 'Not Checked-In';
export const CHECKED_IN_STATUS = 'Checked-In';
export const CHECKED_OUT_STATUS = 'Checkout-Out';
export const CANCELED_STATUS = 'Canceled';
// GUEST editedStatus
export const UPDATED_STATUS = 'updated_status';
// GUEST position
export const POSITION = 'position';
export const PERIODS = 'periods';

// Swipe button status
export const SWIPE_VISIBLE = 'SWIPE_VISIBLE';
export const SWIPE_CHECKIN_ACTION = 'SWIPE_CHECKIN_ACTION';
export const SWIPE_CHECKOUT_ACTION = 'SWIPE_CHECKOUT_ACTION';

// Tourist evStatusFields
export const CHECKOUT_OUT = 'CheckedOutTourist';

// Rest api error message types
export const INSUFFICIENT_CREDITS = 'InsufficientCredits';
export const data_credits = 'credits';
export const data_credits_best_before = 'credits_best_before';
export const REST_ERRORS = 'RESTErrors';
export const error_message = 'message';
export const error_data = 'data';
export const error_type = 'type';

// Guest status Labels
export const NOT_CHECKED_IN_STATUS_LABEL = 'NOT_CHECKED_IN_STATUS_LABEL';
export const CHECKED_IN_STATUS_LABEL = 'CHECKED_IN_STATUS_LABEL';
export const CHECKED_OUT_STATUS_LABEL = 'CHECKED_OUT_STATUS_LABEL';
export const CANCELED_STATUS_LABEL = 'CANCELED_STATUS_LABEL';
export const UPDATED_STATUS_LABEL = 'UPDATED_STATUS_LABEL';

// Actions
export const CHECKIN_ACTION = 'checkin';
export const CHECKOUT_ACTION = 'checkout';
export const CANCEL_ACTION = 'cancel';

// Fields
export const GENDER_FIELD = 'Gender';
export const TIME_STAY_FROM_FIELD = 'TimeStayFrom';
export const STAY_FROM_FIELD = 'StayFrom';
export const FORESEEN_STAY_UNTIL_FIELD = 'ForeseenStayUntil';
export const TIME_ESTIMATED_STAY_UNTIL_FIELD = 'TimeEstimatedStayUntil';
export const DATE_OF_BIRTH_FIELD = 'DateOfBirth';
export const COUNTRY_OF_BIRTH = 'CountryOfBirth';
export const COUNTRY_OF_RESIDENCE = 'CountryOfResidence';
export const CITIZENSHIP = 'Citizenship';
export const KONTAKT = 'Kontakt';
export const DATE_PLACE_OF_BIRTH = 'DatePlaceOfBirth';
export const CITY_OF_RESIDENCE = 'CityOfResidence';
export const DOCUMENT_TYPE = 'DocumentType';
export const PAYMENT_CATEGORY = 'TTPaymentCategory';
export const OFFERED_SERVICE_TYPE = 'OfferedServiceType';
export const ARRIVAL_ORGANIZATION = 'ArrivalOrganisation';
export const FACILITY = 'Facility';

//GUEST FORM
export const FIRST_NAME = 'FirstName';
export const SURNAME = 'Surname';
export const TOURIST_NAME = 'TouristName';
export const TOURIST_SURNAME = 'TouristSurname';
export const TOURIST_MIDDLE_NAME = 'TouristMiddleName';
export const TOURIST_SURNAME_NAME = 'SurnameAndName';
export const TRAVEL_DOCUMENT_TYPE = 'TravelDocumentTypeNumber';
export const DOCUMENT_NUMBER = 'DocumentNumber';
export const ROOM_NUMBER = 'RoomNumber';
export const DATE_TIME_OF_ARRIVAL = 'DateTimeOfArrival';
export const DATE_TIME_OF_DEPARTURE = 'DateTimeOfDeparture';
export const FACILITY_NAME = 'FacilityName';
export const FACILITY_ID = 'FacilityID';
export const CITY = 'City';
export const ID = 'ID';
export const id = 'id';
export const ADDITIONAL_FIELDS = 'additional_info';
export const SLUG = 'slug';
export const EV_IMPORTED = 'evisitor_imported';
export const CURRENT_GUESTS_COUNT = 'current_guests_count';
export const CHECKIN_ID = 'checkin_id';
export const NEW_CHECKIN_ID = 'new_checkin_id';
export const DATE_CREATED = 'dateCreated';
export const EVISITOR = 'evisitor';
export const FACILITY_FIELD = 'facility';
export const ACCOMODATION = 'accommodation';
export const NAME_CODE = 'NameCode';
export const CODE_NAMES = 'CodeNames';
export const EV_ACCOUNT = 'evAccount';
export const EV_ACCOUNT_ID = 'ev_account_id';
export const FACILITY_CODE = 'FacilityCode';
export const CHECKOUT_DATE = 'CheckOutDate';
export const CHECKOUT_TIME = 'CheckOutTime';
export const SETTLEMENT_NAME = 'SettlementName';
export const CREATED_AT = 'created_at';
export const UPDATED_AT = 'updated_at';
export const USER_ID = 'user_id';
export const COMMENT = 'comment';
export const DEFAULT_SERVICE_TYPE = 'Noćenje';
export const DEFAULT_ARRIVAL_ORGANIZATION = 'I';
export const NEPOZNATO = 'Nepoznato';
export const MAX_GUEST_NUMBER = 'max_guests';
export const SINGLE_GUEST_CHECKIN = 'single_guest_checkin';
export const REMOTE_CHECKIN_EXISTS = 'remote_checkin_exists';
export const IS_CHECKED_OUT = 'isCheckedOut';
export const NAME_FIELD = 'name';
export const TOURIST_CHECKOUT_OUT = 'CheckedOutTourist';
export const TOURIST_CHECKOUT_OUT_STRING = 'TouristCheckedOutString';
export const NOTE = 'NOTE';
export const NOTE2 = 'NOTE2';
export const AUTO_TOURIST_CHECKOUT = 'AutomaticTouristCheckout';
export const CHECKIN_CHECKOUT = 'CheckInOut';
export const EMAIL_FIELD = 'Email';
export const CHECKIN_FIELD = 'CheckIn';
export const CHECKOUT_FIELD = 'CheckOut';
export const ORG_ARRIVAL_FIELD = 'Org dolaska:';
export const TYPE_OF_SVC_FIELD = 'Vrsta usluge:';
export const USER_ID_IDX = 'user_id_idx';
export const THEME = 'theme';
export const DEFAULT_CHECKIN_TIME = 'default_checkin_time';
export const DEFAULT_CHECKOUT_TIME = 'default_checkout_time';
export const DEFAULT_NUMBER_OF_NIGHTS = 'default_number_of_nights';
export const USER_INFO = 'user_info';
export const EVISITOR_OIB = 'evisitor_oib';
export const INVOICE_NUMBER = 'invoice_number';
export const INVOICE_NUMBER_IDX = 'invoice_number_idx';
export const SELF_CHECKIN_FIELD = 'self_checkin';
export const MANUAL_GUEST_DATES = 'manual_guest_dates';
export const MANUAL_GUEST_TIME = 'manual_guest_time';
export const CHECKIN_NOTE_FIELD = 'checkin_note';
export const GUEST_NOTE_FIELD = 'guest_note';
export const BIRTHDAY_NOTIFICATION_TIME_FIELD = 'BIRTHDAY_NOTIFICATION_TIME_FIELD';
export const INITIAL_GUEST_FIELD = 'initial_guest_field';
export const INVOICE_EXISTS = 'invoice_exists';
export const RESERVATION = 'reservation';
export const RESERVATION_ID = 'reservation_id';
export const RESERVATION_HOLDER = 'reservation_holder';
export const RESERVATION_UNIT_NAME = 'unit_name';
export const RESERVATION_STATUS = 'reservation_status';

// Buttons
export const ADD_GUEST = 'ADD_GUEST';
export const ADD_CHECKIN = 'ADD_CHECKIN';
export const ADD_EVISITOR = 'ADD_EVISITOR';
export const SHARE_BUTTON_TEXT = 'SHARE_BUTTON_TEXT';
export const SHARE_BUTTON_COPY_TEXT = 'SHARE_BUTTON_COPY_TEXT';
export const SHARE_TEST_LINK = 'SHARE_TEST_LINK';
export const CHECKIN_BUTTON = 'CHECKIN_BUTTON';
export const CHECKOUT_BUTTON = 'CHECKOUT_BUTTON';
export const CANCEL_BUTTON = 'CANCEL_BUTTON';
export const EVISITOR_CONNECT = 'EVISITOR_CONNECT';
export const ACCEPT = 'ACCEPT';
export const DECLINE = 'DECLINE';
export const DELETE = 'DELETE';
export const BUY_CREDITS = 'BUY_CREDITS';
export const USE_2_FOR_1 = 'USE_2_FOR_1';
export const BUY = 'BUY';
export const SELF_CHECKIN_BUTTON_LABEL = 'SELF_CHECKIN_BUTTON_LABEL';
export const CHOOSE_TIME = 'CHOOSE_TIME';
export const CHOOSE_DATE = 'CHOOSE_DATE';
export const GO_TO_PAYMENT = 'GO_TO_PAYMENT';

// Messages
export const ALERT_DIALOG_TITLE = 'ALERT_DIALOG_TITLE';
export const ERROR_ENTER_NUMBER_OF_GUESTS = 'ERROR_ENTER_NUMBER_OF_GUESTS';
export const EMAIL_BODY_SELF_CHECKIN = 'Here is the link for Self Check-in: \n';
export const EMAIL_TITLE_REFERRAL = 'eCheckin - brza prijava gostiju na eVisitor';
export const EMAIL_BODY_REFERRAL =
    'Koristi eCheckin za brzu prijavu gostiju na eVisitor i iskoristi 40 besplatnih prijava: \n';
export const STATUS_GET_EVISITOR = 'Syncing eVisitor accounts...';
export const STATUS_GET_CHECKINS = 'Syncing checkins...';
export const STATUS_GET_STATIC = 'Getting static data...';
export const STATUS_UPDATE_GUEST = 'Updating guest...';
export const STATUS_DELETE_GUEST = 'Deleting guest...';
export const STATUS_SAVE_CHECKIN = 'Saving checkin...';
export const STATUS_UPDATE_CHECKIN = 'Updating checkin...';
export const STATUS_UPDATE_CHECKIN_GUESTS = 'Updating checkin guests...';
export const STATUS_RECREATE_CHECKIN_GUESTS = 'Recreating checkin guests...';
export const STATUS_EDIT_CHECKIN = 'Editing checkin...';
export const STATUS_DELETE_CHECKIN = 'Deleting checkin...';
export const CONFIRM_GUEST_CANCEL_DIALOG = 'CONFIRM_GUEST_CANCEL_DIALOG';
export const CONFIRM_GUEST_CANCEL_RESERVATION_DIALOG = 'CONFIRM_GUEST_CANCEL_RESERVATION_DIALOG';
export const CONFIRM_DELETE_EV_DIALOG = 'CONFIRM_DELETE_EV_DIALOG';
export const CHECKIN_UNKNOWN = 'CHECKIN_UNKNOWN';
//export const GUEST_EDIT_WARNING = "After saving guest data you will not be able to edit it anymore! For eventual changes please contact the renter."
export const GUEST_EDIT_WARNING = 'GUEST_EDIT_WARNING';
export const INVOICE_DELETE_WARNING = 'INVOICE_DELETE_WARNING';
export const TERMS_CHECKBOX = 'TERMS_CHECKBOX';
export const PRIVOLA_PRIKUPLJANJE = 'PRIVOLA_PRIKUPLJANJE';
export const PRIVOLA_USTUPANJE = 'PRIVOLA_USTUPANJE';
export const PRIVOLA_SPREMANJE = 'PRIVOLA_SPREMANJE';
export const PRIVOLA_2 = 'PRIVOLA_2';
export const PRIVOLA_3 = 'PRIVOLA_3';
export const PRIVOLA_4 = 'PRIVOLA_4';
export const PRIVOLA_5 = 'PRIVOLA_5';
export const PRIVOLA_6 = 'PRIVOLA_6';
export const PRIVOLA_7 = 'PRIVOLA_7';
export const PRIVOLA_8 = 'PRIVOLA_8';
export const PRIVOLA_9 = 'PRIVOLA_9';
export const CANCEL_TEXT = 'CANCEL_TEXT';
//Self Check-in page
export const SELF_CHECKIN_TITLE = 'SELF_CHECKIN_TITLE';
export const SELF_CHECKIN_TEXT_INTRO_1 = 'SELF_CHECKIN_TEXT_INTRO_1';
export const CURRENT_GUEST_COUNT = 'CURRENT_GUEST_COUNT';
export const SELF_CHECKIN_GDPR_LINK = 'SELF_CHECKIN_GDPR_LINK';
export const SELF_CHECKIN_TEXT = 'SELF_CHECKIN_TEXT';
export const SELF_CHECKIN_TEXT_2 = 'SELF_CHECKIN_TEXT_2';
export const SELF_CHECKIN_TEXT_3 = 'SELF_CHECKIN_TEXT_3';
export const SELF_CHECKIN_LANDLORD_TITLE = 'SELF_CHECKIN_LANDLORD_TITLE';
export const SELF_CHECKIN_NOTE = 'SELF_CHECKIN_NOTE';
export const SELF_CHECKIN_NOTE_GDPR = 'SELF_CHECKIN_NOTE_GDPR';
export const SELF_CHECKIN_APP_DESCRIPTION = 'SELF_CHECKIN_APP_DESCRIPTION';
export const GUEST_SAVED = 'GUEST_SAVED';
export const PASSWORDS = 'PASSWORDS';
export const PASSWORD = 'PASSWORD';
export const PASSWORD_NOT_MATCHED = 'PASSWORD_NOT_MATCHED';
export const CONFIRM_TERMS_CONTENT = LOREM_IPSUM;
export const ERROR = 'ERROR';
export const USER_CREDS_INVALID = 'USER_CREDS_INVALID';
export const USER_EMAIL_INVALID = 'USER_EMAIL_INVALID';
export const USER_PASSWORD_INVALID = 'USER_PASSWORD_INVALID';
export const USER_PASSWORD_TOO_SHORT = 'USER_PASSWORD_TOO_SHORT';
export const USER_PASSWORD_REPEAT_INVALID = 'USER_PASSWORD_REPEAT_INVALID';
export const PRINT_CONSENT = 'PRINT_CONSENT';
export const DELETE_ACCOUNT_INTRO = 'DELETE_ACCOUNT_INTRO';
export const DELETE_ACCOUNT_DIALOG_CONTENT_1 = 'DELETE_ACCOUNT_DIALOG_CONTENT_1';
export const DELETE_ACCOUNT_DIALOG_CONTENT_2 = 'DELETE_ACCOUNT_DIALOG_CONTENT_2';
export const NETWORK_OFFLINE_ERROR_MSG = 'Network error: please check internet connection!';
export const SHARE_DOCUMENT_TEXT = 'SHARE_DOCUMENT_TEXT';
export const PLEASE_WAIT = 'PLEASE_WAIT';
export const RESET_PASS_ERROR_MESSAGES = ['Error', 'Greška'];
export const NO_CHECKINS = 'NO_CHECKINS';
export const NO_RESERVATIONS = 'NO_RESERVATIONS';
export const NO_EVACCOUNT = 'NO_EVACCOUNT';
export const NO_FACILITY = 'NO_FACILITY';
export const NO_EVACCOUNT_MODAL = 'NO_EVACCOUNT_MODAL';
export const NO_EVACCOUNT_ADD_ACCOUNT = 'NO_EVACCOUNT_ADD_ACCOUNT';
export const NO_FACILITY_MODAL = 'NO_FACILITY_MODAL';
export const NO_INVOICES = 'NO_INVOICES';
export const NO_TOURISTS = 'NO_TOURISTS';
export const IAP_ALERT_PURCHASE_UNSUCCESSFUL = 'IAP_ALERT_PURCHASE_UNSUCCESSFUL';
export const IAP_ALERT_PURCHASE_SUCCESSFUL = 'IAP_ALERT_PURCHASE_SUCCESSFUL';
export const REGISTER_USER_DIALOG_TITLE = 'REGISTER_USER_DIALOG_TITLE';
export const REGISTER_USER_DIALOG_SUCCESS = 'REGISTER_USER_DIALOG_SUCCESS';
export const REGISTER_USER_DIALOG_ONGOING = 'REGISTER_USER_DIALOG_ONGOING';
export const LOGIN_REFRESH_DIALOG_TEXT = 'LOGIN_REFRESH_DIALOG_TEXT';
export const ONLINE_CONNECTED_STATUS = 'ONLINE_CONNECTED_STATUS';
export const DELETE_ACCOUNT_EMAIL_INCORRECT = 'DELETE_ACCOUNT_EMAIL_INCORRECT';
export const SELF_CHECKIN_SLOTS_FULL = 'SELF_CHECKIN_SLOTS_FULL';
export const LOAD_MORE = 'LOAD_MORE';
export const DATE_OF_BIRTH_LABEL_ERROR = 'DATE_OF_BIRTH_LABEL_ERROR';
export const PROCESSING_GALLERY = 'PROCESSING_GALLERY';
export const YOUR_DEBT = 'YOUR_DEBT';
export const FINAL_DEBT_AMOUNT = 'FINAL_DEBT_AMOUNT';
export const DEBT_OBJECT = 'DEBT_OBJECT';
export const DEBT_ARRIVAL = 'DEBT_ARRIVAL';
export const DEBT_FOR_PAY = 'DEBT_FOR_PAY';
export const DEBT_ENTER = 'DEBT_ENTER';
export const BARCODE_ERROR = 'BARCODE_ERROR';

// Titles
export const CHOOSE_FACILITY_TITLE = 'CHOOSE_FACILITY_TITLE';
export const FACILITY_PAYMENT_INFO = 'FACILITY_PAYMENT_INFO';
export const FACILITY_PAYMENT_NOTE = 'FACILITY_PAYMENT_NOTE';
export const CHOOSE_EVACCOUNT_TITLE = 'CHOOSE_EVACCOUNT_TITLE';
export const TITLE_NUMBER_OF_GUESTS = 'TITLE_NUMBER_OF_GUESTS';
export const TITLE_GUEST_ARRIVAL = 'TITLE_GUEST_ARRIVAL';
export const TOOLBAR_CHECKIN_TITLE = 'TOOLBAR_CHECKIN_TITLE';
export const TOOLBAR_SIGNUP_TITLE = 'Sign Up';
export const TOOLBAR_EVISITOR_TITLE = 'TOOLBAR_EVISITOR_TITLE';
export const TOOLBAR_GUEST_TITLE = 'TOOLBAR_GUEST_TITLE';
export const DOWNLOAD_IN_PROGRESS = 'Downloading data';
export const SHARE_SELF_CHECKIN_TITLE = 'eCheckin Self-Checkin';
export const SHARE_SELF_CHECKIN_DIALOG_TITLE = 'SHARE_SELF_CHECKIN_DIALOG_TITLE';
export const ECHECKIN_TITLE = 'eCheckin';
export const REMEMBER_LABEL = 'Remember me';
export const SIGN_IN = 'SIGN_IN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const LOGIN_TITLE = 'LOGIN_TITLE';
export const SIGNUP_TITLE = 'SIGNUP_TITLE';
export const SIGN_UP_BUTTON_TITLE = 'SIGN_UP_BUTTON_TITLE';
export const SIGN_IN_BUTTON_TITLE = 'SIGN_IN_BUTTON_TITLE';
export const WELCOME = 'WELCOME';
export const SIGN_UP_INTRO = 'SIGN_UP_INTRO';
export const REFERRAL_INTRO = 'REFERRAL_INTRO';
export const LOGIN_INTRO = 'LOGIN_INTRO';
export const LOGIN_NOTE = 'LOGIN_NOTE';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CONFIRM_GUEST_CANCEL_TITLE = 'CONFIRM_GUEST_CANCEL_TITLE';
export const CONFIRM_GUEST_TITLE = 'CONFIRM_GUEST_TITLE';
export const CONFIRM_DELETE_EV_TITLE = 'CONFIRM_DELETE_EV_TITLE';
export const CONFIRM_GUEST_DELETE_TITLE = 'CONFIRM_GUEST_DELETE_TITLE';
export const CONFIRM_GUEST_DELETE_DIALOG = 'CONFIRM_GUEST_DELETE_DIALOG';
export const CONFIRM_CHECKIN_DELETE_TITLE = 'CONFIRM_CHECKIN_DELETE_TITLE';
export const CONFIRM_CHECKIN_DELETE_DIALOG = 'CONFIRM_CHECKIN_DELETE_DIALOG';
export const CONFIRM_CHECKIN_EMPTY_DELETE_DIALOG = 'CONFIRM_CHECKIN_EMPTY_DELETE_DIALOG';
export const CONFIRM_CHECKIN_WITH_NO_EV_GUEST_DELETE_DIALOG = 'CONFIRM_CHECKIN_WITH_NO_EV_GUEST_DELETE_DIALOG';
export const CONFIRM_DELETE_INVOICE_TITLE = 'CONFIRM_DELETE_INVOICE_TITLE';
export const PRIVOLA_TITLE = 'PRIVOLA_TITLE';
export const PRIVOLA_CONTENT_TITLE = 'PRIVOLA_CONTENT_TITLE';
export const AGREE = 'AGREE';
export const AND = 'AND';
export const TERMS = 'TERMS';
export const PRIVACY_POLICY = 'PRIVACY_POLICY';
export const TOURIST_DETAILS_TITLE = 'TOURIST_DETAILS_TITLE';
export const ADD_EVISITOR_DIALOG_TITLE = 'ADD_EVISITOR_DIALOG_TITLE';
export const CONFIRM_TERMS_TITLE = 'CONFIRM_TERMS_TITLE';
export const QR_TOURIST_DATA_TITLE = 'QR_TOURIST_DATA_TITLE';
export const QR_TOURIST_DATA_SUBTITLE = 'QR_TOURIST_DATA_SUBTITLE';
export const QR_TOURIST = 'QR_TOURIST';
export const QR_GENDER = 'QR_GENDER';
export const QR_NATIONATLITY = 'QR_NATIONATLITY';
export const QR_CONTACT = 'QR_CONTACT';
export const QR_ADDRESS = 'QR_ADDRESS';
export const QR_DOCUMENT = 'QR_DOCUMENT';
export const QR_ACCOMODATION_INFO = 'QR_ACCOMODATION_INFO';
export const QR_OBJECT = 'QR_OBJECT';
export const QR_STAY_FROM = 'QR_STAY_FROM';
export const QR_FORSEEN_STAY_TO = 'QR_FORSEEN_STAY_TO';
export const QR_STAY_TO = 'QR_STAY_TO';
export const QR_CATEGORY = 'QR_CATEGORY';
export const QR_ARRIVAL_ORG = 'QR_ARRIVAL_ORG';
export const QR_TYPE_OF_SERVICE = 'QR_TYPE_OF_SERVICE';
export const QR_NOTE = 'QR_NOTE';
export const TOURIST_DETAILS_DIALOG_TITLE = 'TOURIST_DETAILS_DIALOG_TITLE';
export const ALERT = 'ALERT';
export const GDPR = 'GDPR';
export const TERMS_TITLE = 'TERMS_TITLE';
export const PRIVACY_TITLE = 'PRIVACY_TITLE';
export const OWNER_LIST_TITLE = 'OWNER_LIST_TITLE';
export const CLIENT_LIST_TITLE = 'CLIENT_LIST_TITLE';
export const IAP_SCREEN_TITLE = 'IAP_SCREEN_TITLE';
export const IAP_INVOICE_DIALOG_TITLE = 'IAP_INVOICE_DIALOG_TITLE';
export const DOWNLOAD_APP = 'DOWNLOAD_APP';
export const PARTNERS = 'PARTNERS';
export const INVALID_FORM_TITLE = 'INVALID_FORM_TITLE';
export const INVALID_FORM_DETAILS = 'INVALID_FORM_DETAILS';
export const FACILITY_ERROR_DIALOG_WAIT_REFRESH = 'FACILITY_ERROR_DIALOG_WAIT_REFRESH';
export const FACILITY_ERROR_DIALOG_REFRESH_QUESTION = 'FACILITY_ERROR_DIALOG_REFRESH_QUESTION';
export const FACILITY_ERROR_NO_ACTIVE_FACILITY = 'FACILITY_ERROR_NO_ACTIVE_FACILITY';
export const GUEST_CHECKIN_ACTION_ERROR = 'GUEST_CHECKIN_ACTION_ERROR'; //'Could not checkin guest, check if'
export const GUEST_CHECKOUT_ACTION_ERROR = 'GUEST_CHECKOUT_ACTION_ERROR'; //'Could not checkin guest, check if'

export const TWO_FOR_ONE_OPTION = 'TWO_FOR_ONE_OPTION';
export const TWO_FOR_ONE_DIALOG_TITLE = 'TWO_FOR_ONE_DIALOG_TITLE';
export const TWO_FOR_ONE_DIALOG_MSG = 'TWO_FOR_ONE_DIALOG_MSG';
export const CREDITS_DEPLETED_TITLE = 'CREDITS_DEPLETED_TITLE';
export const CREDITS_DEPLETED_MSG = 'CREDITS_DEPLETED_MSG';
export const CREDITS_EXPIRED_TITLE = 'CREDITS_EXPIRED_TITLE';
export const CREDITS_EXPIRED_MSG = 'CREDITS_EXPIRED_MSG';
export const NOTE_EDIT_TITLE = 'NOTE_EDIT_TITLE';
export const NOTE_EDIT_MSG = 'NOTE_EDIT_MSG';
export const NOTE_ADD_MSG = 'NOTE_ADD_MSG';

// Labels
export const GENDER_LABEL = 'GENDER_LABEL';
export const CHECKIN_LINK = 'CHECKIN_LINK';
export const ARRIVAL_LABEL = 'ARRIVAL_LABEL';
export const ARRIVAL_DATE_LABEL = 'ARRIVAL_DATE_LABEL';
export const ARRIVAL_DATE_LABEL_ERROR = 'ARRIVAL_DATE_LABEL_ERROR';
export const ARRIVAL_TIME_LABEL = 'ARRIVAL_TIME_LABEL';
export const DEPARTURE_LABEL = 'DEPARTURE_LABEL';
export const DEPARTURE_DATE_LABEL = 'DEPARTURE_DATE_LABEL';
export const DEPARTURE_DATE_LABEL_ERROR = 'DEPARTURE_DATE_LABEL_ERROR';
export const DEPARTURE_TIME_LABEL = 'DEPARTURE_TIME_LABEL';
export const FIRST_NAME_LABEL = 'FIRST_NAME_LABEL';
export const LAST_NAME_LABEL = 'LAST_NAME_LABEL';
export const DATE_OF_BIRTH_LABEL = 'DATE_OF_BIRTH_LABEL';
export const COUNTRY_OF_BIRTH_LABEL = 'COUNTRY_OF_BIRTH_LABEL';
export const CITIZENSHIP_LABEL = 'CITIZENSHIP_LABEL';
export const RESIDENCE_COUNTRY_LABEL = 'RESIDENCE_COUNTRY_LABEL';
export const RESIDENCE_CITY_LABEL = 'RESIDENCE_CITY_LABEL';
export const DOCUMENT_TYPE_LABEL = 'DOCUMENT_TYPE_LABEL';
export const DOCUMENT_CODE_LABEL = 'DOCUMENT_CODE_LABEL';
export const PAYMENT_CATEGORY_LABEL = 'PAYMENT_CATEGORY_LABEL';
export const SERVICE_TYPE_LABEL = 'SERVICE_TYPE_LABEL';
export const TRAVEL_ARRANGEMENTS_LABEL = 'TRAVEL_ARRANGEMENTS_LABEL';
export const ROOM_NUMBER_LABEL = 'ROOM_NUMBER_LABEL';
export const YES = 'YES';
export const NO = 'NO';
export const SEARCH_TOURISTS_LABEL = 'SEARCH_TOURISTS_LABEL';
export const OK = 'OK';
export const NEW_GUEST = 'NEW_GUEST';
export const SCAN_DOCUMENT = 'SCAN_DOCUMENT';
export const IMPORT_DOCUMENT = 'IMPORT_DOCUMENT';
export const EXISTING_GUEST = 'EXISTING_GUEST';
export const MANUAL_GUEST = 'MANUAL_GUEST';
export const EVISITOR_LABEL = 'eVisitor';
export const EVISITOR_FACILITY_LABEL = 'Facility';
export const CHECKINS_LABEL = 'Checkins';
export const CHECKIN = 'Checkin';
export const CHECKOUT = 'Checkout';
export const CANCEL = 'Cancel';
export const CHECKIN_ACTIONS = 'CHECKIN_ACTIONS';
export const CHECKOUT_CONFIRM = 'CHECKOUT_CONFIRM';
export const CHECKOUT_WITH_PREDEFINED_DATE = 'CHECKOUT_WITH_PREDEFINED_DATE';
export const CHECKOUT_WITH_TODAY_DATE = 'CHECKOUT_WITH_TODAY_DATE';
export const CHECKOUT_WITH_CUSTOM_DATE = 'CHECKOUT_WITH_CUSTOM_DATE';
export const CHECKIN_ALL = 'CHECKIN_ALL';
export const CHECKOUT_GUEST = 'CHECKOUT_GUEST';
export const CHECKOUT_GUESTS = 'CHECKOUT_GUESTS';
export const CHECKIN_TOURIST = 'CHECKIN_TOURIST';
export const SWIPE_CHECKIN_TOURIST = 'SWIPE_CHECKIN_TOURIST';
export const SWIPE_CHECKOUT_TOURIST = 'SWIPE_CHECKOUT_TOURIST';
export const CHECKOUT_TOURIST = 'CHECKOUT_TOURIST';
export const CANCEL_TOURIST = 'CANCEL_TOURIST';
export const CANCEL_ALL = 'CANCEL_ALL';
export const ENTER_DATA = 'ENTER_DATA';
export const SAVE_DATA = 'SAVE_DATA';
export const EVISITOR_NOTE = 'EVISITOR_NOTE';
export const EVISITOR_USERNAME = 'EVISITOR_USERNAME';
export const EVISITOR_PASS = 'EVISITOR_PASS';
export const EVISITOR_401_TITLE = 'EVISITOR_401_TITLE';
export const EVISITOR_401_TXT = 'EVISITOR_401_TXT';
export const EVISITOR_401_TXT_URL = 'www.evisitor.hr';
export const EVISITOR_401_URL = 'EVISITOR_401_URL';
export const EVISITOR_401_POSITIVE = 'EVISITOR_401_POSITIVE';
export const EVISITOR_401_NEGATIVE = 'EVISITOR_401_NEGATIVE';
export const FORWARD_DATA = 'FORWARD_DATA';
export const SELF_CHECKIN = 'Self check-in';
export const SELF_CHECKIN_QR_CODE = 'SELF_CHECKIN_QR_CODE';
export const SELF_CHECKIN_DIALOG_TITLE = 'SELF_CHECKIN_DIALOG_TITLE';
export const QR_SELF_CHECKIN_DIALOG_NOTE = 'QR_SELF_CHECKIN_DIALOG_NOTE';
export const QR_CODE = 'QR_CODE';
export const SELF_CHECKIN_QR_CODE_DIALOG_TXT = 'SELF_CHECKIN_QR_CODE_DIALOG_TXT';
export const SELF_CHECKIN_DIALOG_BOTTOM_NOTE_TXT = 'SELF_CHECKIN_DIALOG_BOTTOM_NOTE_TXT';
export const QR_CODE_TITLE = 'QR_CODE_TITLE';
export const POWERED_BY = 'Powered by:';
export const ALL = 'All';
export const LANGUAGE = 'LANGUAGE';
export const LANGUAGE_TITLE = 'LANGUAGE_TITLE';
export const LANGUAGE_BG_CODE = 'bg';
export const LANGUAGE_ZH_CODE = 'zh';
export const LANGUAGE_CS_CODE = 'cs';
export const LANGUAGE_DA_CODE = 'da';
export const LANGUAGE_DE_CODE = 'de';
export const LANGUAGE_ET_CODE = 'et';
export const LANGUAGE_EN_CODE = 'en';
export const LANGUAGE_ES_CODE = 'es';
export const LANGUAGE_FI_CODE = 'fi';
export const LANGUAGE_FR_CODE = 'fr';
export const LANGUAGE_HR_CODE = 'hr';
export const LANGUAGE_HU_CODE = 'hu';
export const LANGUAGE_HI_CODE = 'hi';
export const LANGUAGE_IT_CODE = 'it';
export const LANGUAGE_JA_CODE = 'ja';
export const LANGUAGE_KO_CODE = 'ko';
export const LANGUAGE_LT_CODE = 'lt';
export const LANGUAGE_LV_CODE = 'lv';
export const LANGUAGE_NB_CODE = 'nb';
export const LANGUAGE_NL_CODE = 'nl';
export const LANGUAGE_PL_CODE = 'pl';
export const LANGUAGE_PT_CODE = 'pt';
export const LANGUAGE_RO_CODE = 'ro';
export const LANGUAGE_RU_CODE = 'ru';
export const LANGUAGE_SI_CODE = 'si';
export const LANGUAGE_SK_CODE = 'sk';
export const LANGUAGE_SV_CODE = 'sv';
export const LANGUAGE_TR_CODE = 'tr';
export const LANGUAGE_UA_CODE = 'ua';
export const LANGUAGE_BG = 'Български';
export const LANGUAGE_ZH = '中文';
export const LANGUAGE_CS = 'Čeština';
export const LANGUAGE_DA = 'Dansk';
export const LANGUAGE_DE = 'Deutsch';
export const LANGUAGE_ET = 'Eesti';
export const LANGUAGE_EN = 'English';
export const LANGUAGE_ES = 'Español';
export const LANGUAGE_FI = 'Suomi';
export const LANGUAGE_FR = 'Français';
export const LANGUAGE_HR = 'Hrvatski';
export const LANGUAGE_HU = 'Magyar';
export const LANGUAGE_HI = 'भारतीय';
export const LANGUAGE_IT = 'Italiano';
export const LANGUAGE_JA = '日本語';
export const LANGUAGE_KO = '한국어';
export const LANGUAGE_LT = 'Lietuvių';
export const LANGUAGE_LV = 'Latviešu';
export const LANGUAGE_NB = 'Norsk bokmål';
export const LANGUAGE_NL = 'Nederlands';
export const LANGUAGE_PL = 'Polski';
export const LANGUAGE_PT = 'Português';
export const LANGUAGE_RO = 'Română';
export const LANGUAGE_RU = 'Русский';
export const LANGUAGE_SI = 'Slovenščina';
export const LANGUAGE_SK = 'Slovenčina';
export const LANGUAGE_SV = 'Svenska';
export const LANGUAGE_TR = 'Türkçe';
export const LANGUAGE_UA = 'Українська';
export const LANGUAGE_HR_BG = 'Bugarski';
export const LANGUAGE_HR_ZH = 'Kineski';
export const LANGUAGE_HR_CS = 'Češki';
export const LANGUAGE_HR_DA = 'Danski';
export const LANGUAGE_HR_DE = 'Njemački';
export const LANGUAGE_HR_ET = 'Estonski';
export const LANGUAGE_HR_EN = 'Engleski';
export const LANGUAGE_HR_ES = 'Španjolski';
export const LANGUAGE_HR_FI = 'Finski';
export const LANGUAGE_HR_FR = 'Francuski';
export const LANGUAGE_HR_HR = 'Hrvatski';
export const LANGUAGE_HR_HU = 'Mađarski';
export const LANGUAGE_HR_HI = 'Indijski';
export const LANGUAGE_HR_IT = 'Talijanski';
export const LANGUAGE_HR_JA = 'Japanski';
export const LANGUAGE_HR_KO = 'Korejski';
export const LANGUAGE_HR_LT = 'Litavski';
export const LANGUAGE_HR_LV = 'Latvijski';
export const LANGUAGE_HR_NB = 'Norveški';
export const LANGUAGE_HR_NL = 'Nizozemski';
export const LANGUAGE_HR_PL = 'Poljski';
export const LANGUAGE_HR_PT = 'Portugalski';
export const LANGUAGE_HR_RO = 'Rumunjski';
export const LANGUAGE_HR_RU = 'Ruski';
export const LANGUAGE_HR_SI = 'Slovenski';
export const LANGUAGE_HR_SK = 'Slovački';
export const LANGUAGE_HR_SV = 'Švedski';
export const LANGUAGE_HR_TR = 'Turski';
export const LANGUAGE_HR_UA = 'Ukrajinski';
export const LANGUAGE_EN_BG = 'Bulgarian';
export const LANGUAGE_EN_ZH = 'Chinese';
export const LANGUAGE_EN_HR = 'Croatian';
export const LANGUAGE_EN_CS = 'Czech';
export const LANGUAGE_EN_DA = 'Danish';
export const LANGUAGE_EN_NL = 'Dutch';
export const LANGUAGE_EN_EN = 'English';
export const LANGUAGE_EN_ET = 'Estonian';
export const LANGUAGE_EN_FI = 'Finnish';
export const LANGUAGE_EN_FR = 'French';
export const LANGUAGE_EN_DE = 'German';
export const LANGUAGE_EN_HU = 'Hungarian';
export const LANGUAGE_EN_HI = 'Indian';
export const LANGUAGE_EN_IT = 'Italian';
export const LANGUAGE_EN_JA = 'Japanese';
export const LANGUAGE_EN_KO = 'Korean';
export const LANGUAGE_EN_LV = 'Latvian';
export const LANGUAGE_EN_LT = 'Lithuanian';
export const LANGUAGE_EN_NB = 'Norwegian';
export const LANGUAGE_EN_PL = 'Polish';
export const LANGUAGE_EN_PT = 'Portuguese';
export const LANGUAGE_EN_RO = 'Romanian';
export const LANGUAGE_EN_RU = 'Russian';
export const LANGUAGE_EN_ES = 'Spanish';
export const LANGUAGE_EN_SK = 'Slovak';
export const LANGUAGE_EN_SI = 'Slovenian';
export const LANGUAGE_EN_SV = 'Swedish';
export const LANGUAGE_EN_TR = 'Turkish';
export const LANGUAGE_EN_UA = 'Ukrainian';
export const SETTINGS = 'SETTINGS';
export const TOURISTS = 'TOURISTS';
export const EVACCOUNTS = 'EVACCOUNTS';
export const FINANCES = 'FINANCES';
export const FINANCE_PAYMENT = 'FINANCE_PAYMENT';
export const HELP = 'HELP';
export const LOGOUT = 'LOGOUT';
export const EMAIL = 'EMAIL';
export const AUTO_CHECKOUT = 'AUTO_CHECKOUT';
export const AUTO_CHECKOUT_NOTE = 'AUTO_CHECKOUT_NOTE';
export const AUTO_CHECKOUT_DIALOG_NOTE_INTRO = 'AUTO_CHECKOUT_DIALOG_NOTE_INTRO';
export const AUTO_CHECKOUT_DIALOG_SECTION_1_TITLE = 'AUTO_CHECKOUT_DIALOG_SECTION_1_TITLE';
export const AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_1 = 'AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_1';
export const AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_2 = 'AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_2';
export const AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_3 = 'AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_3';
export const AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_4 = 'AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_4';
export const AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_5 = 'AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_5';
export const AUTO_CHECKOUT_DIALOG_SECTION_2_TITLE = 'AUTO_CHECKOUT_DIALOG_SECTION_2_TITLE';
export const AUTO_CHECKOUT_DIALOG_SECTION_2_CONTENT_1 = 'AUTO_CHECKOUT_DIALOG_SECTION_2_CONTENT_1';
export const AUTO_CHECKOUT_DIALOG_SECTION_2_CONTENT_2 = 'AUTO_CHECKOUT_DIALOG_SECTION_2_CONTENT_2';
export const EXAMPLE = 'EXAMPLE';
export const LEARN_MORE = 'LEARN_MORE';
export const PRINT = 'PRINT';
export const ZOOM = 'ZOOM';
export const CHANGE_PASS = 'CHANGE_PASS';
export const OLD_PASSWORD = 'OLD_PASSWORD';
export const NEW_PASSWORD = 'NEW_PASSWORD';
export const CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';
export const CONFIRM = 'CONFIRM';
export const RESET_PASSWORD_TITLE = 'RESET_PASSWORD_TITLE';
export const RESET_PASSWORD_LABEL = 'RESET_PASSWORD_LABEL';
export const RESET_PASSWORD_CONTENT_1 = 'RESET_PASSWORD_CONTENT_1';
export const RESET_PASSWORD_CONTENT_2 = 'RESET_PASSWORD_CONTENT_2';
export const RESET_PASSWORD_CONTENT_3 = 'RESET_PASSWORD_CONTENT_3';
export const RESET_PASSWORD_MESSAGE = 'RESET_PASSWORD_MESSAGE';
export const SEND = 'SEND';
export const GUIDELINES = 'GUIDELINES';
export const DOCUMENT_AND_FORMS = 'DOCUMENT_AND_FORMS';
export const TERMS_AND_PRIVACY_POLICY_STATUS = 'TERMS_AND_PRIVACY_POLICY_STATUS';
export const CONTACT = 'CONTACT';
export const GENERATE_BARCODE = 'GENERATE_BARCODE';
export const ABOUT = 'ABOUT';
export const EDIT = 'EDIT';
export const CREATE = 'CREATE';
export const EDIT_USER_DATA = 'EDIT_USER_DATA';
export const EDIT_OPERATOR_DATA = 'EDIT_OPERATOR_DATA';
export const EDIT_FOOTER_DATA = 'EDIT_FOOTER_DATA';
export const EXPORT_ACCOUNT_INFO = 'EXPORT_ACCOUNT_INFO';
export const EXPORT_ACCOUNT_INFO_TEXT = 'EXPORT_ACCOUNT_INFO_TEXT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_TEXT_1 = 'DELETE_ACCOUNT_TEXT_1';
export const DELETE_ACCOUNT_TEXT_2 = 'DELETE_ACCOUNT_TEXT_2';
export const DELETE_ACCOUNT_TEXT_3 = 'DELETE_ACCOUNT_TEXT_3';
export const WHATS_APP = 'WhatsApp';
export const CONTACT_VIA = 'CONTACT_VIA';
export const SEARCH = 'SEARCH';
export const SELECT_ALL = 'SELECT_ALL';
export const DESELECT_ALL = 'DESELECT_ALL';
export const USER_DATA_INVOICE = 'USER_DATA_INVOICE';
export const USER_DATA_FORM_ENTITY_FLAG = 'USER_DATA_FORM_ENTITY_FLAG';
export const USER_DATA_LEGAL_ENTITY = 'USER_DATA_LEGAL_ENTITY';
export const USER_DATA_LEGAL_NAME = 'USER_DATA_LEGAL_NAME';
export const USER_DATA_PERSON = 'USER_DATA_PERSON';
export const USER_DATA_NAME_AND_SURNAME = 'USER_DATA_NAME_AND_SURNAME';
export const USER_DATA_ADDRESS = 'USER_DATA_ADDRESS';
export const USER_DATA_CITY_POST = 'USER_DATA_CITY_POST';
export const USER_DATA_COUNTRY = 'USER_DATA_COUNTRY';
export const USER_DATA_CONTACT = 'USER_DATA_CONTACT';
export const USER_DATA_EMAIL = 'USER_DATA_EMAIL';
export const USER_DATA_IN_PDV_SYSTEM = 'USER_DATA_IN_PDV_SYSTEM';
export const USER_DATA_SOJOURN_TAX = 'USER_DATA_SOJOURN_TAX';
export const USER_DATA_ELECTRONIC_INVOICE_OPT = 'USER_DATA_ELECTRONIC_INVOICE_OPT';
export const USER_DATA_ELECTRONIC_INVOICE = 'USER_DATA_ELECTRONIC_INVOICE';
export const USER_DATA_INV_OPERATOR = 'INV_OPERATOR';
export const USER_DATA_INV_OPERATOR_ID = 'INV_OPERATOR_ID';
export const USER_DATA_INV_FOOTER_DATA = 'INV_FOOTER_DATA';
export const BANK_INFO = 'BANK_INFO';
export const IBAN = 'IBAN';
export const Iban = 'Iban';
export const SWIFT = 'SWIFT';
export const BANK = 'BANK';
export const BANK_ADDRESS = 'BANK_ADDRESS';
export const OIB = 'OIB';
export const USE_USER_DATA_FOR_INVOICE = 'USE_USER_DATA_FOR_INVOICE';
export const UPLOAD_LOGO_TITLE = 'UPLOAD_LOGO_TITLE';
export const UPLOAD_LOGO = 'UPLOAD_LOGO';
export const RESET_LOGO = 'RESET_LOGO';
export const PROFILE_INVOICE_TEMPLATE_TITLE = 'PROFILE_INVOICE_TEMPLATE_TITLE';
export const PROFILE_INVOICE_DATA_TITLE = 'PROFILE_INVOICE_DATA_TITLE';
export const PROFILE_INVOICE_OPERATOR_TITLE = 'PROFILE_INVOICE_OPERATOR_TITLE';
export const PROFILE_INVOICE_OPERATOR_REF = 'PROFILE_INVOICE_OPERATOR_REF';
export const PROFILE_INVOICE_OPERATOR_NAME = 'PROFILE_INVOICE_OPERATOR_NAME';
export const PROFILE_INVOICE_FOOTER_TITLE = 'PROFILE_INVOICE_FOOTER_TITLE';
export const PROFILE_INVOICE_FOOTER_TEXT = 'PROFILE_INVOICE_FOOTER_TEXT';
export const INVOICES_DOWNLOAD_LABEL = 'INVOICES_DOWNLOAD_LABEL';
export const SEND_REFERRAL = 'SEND_REFERRAL';
export const REFERRAL_DIALOG_TXT_1 = 'REFERRAL_DIALOG_TXT_1';
export const REFERRAL_DIALOG_TXT_2 = 'REFERRAL_DIALOG_TXT_2';
export const REFERRAL_DIALOG_TXT_3 = 'REFERRAL_DIALOG_TXT_3';
export const EDIT_NOTIFICATIONS = 'EDIT_NOTIFICATIONS';
export const NOTIFICATIONS_MAIL_FIELD_TITLE = 'NOTIFICATIONS_MAIL_FIELD_TITLE';
export const NOTIFICATIONS_REMINDER_FIELD_TITLE = 'NOTIFICATIONS_REMINDER_FIELD_TITLE';
export const NOTIFICATIONS_NEWSLETTER_FIELD_TITLE = 'NOTIFICATIONS_NEWSLETTER_FIELD_TITLE';
export const GUEST_MANUAL_ENTER_DATES_TITLE = 'GUEST_MANUAL_ENTER_DATES_TITLE';
export const GUEST_MANUAL_ENTER_TIME_TITLE = 'GUEST_MANUAL_ENTER_TIME_TITLE';
export const MESSAGE = 'MESSAGE';
export const OPTIONAL = 'OPTIONAL';
export const REQUIRED_FIELD = 'REQUIRED_FIELD';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';
export const NEW_INVOICE = 'NEW_INVOICE';
export const BACK = 'BACK';

//InvoiceHtml
export const INVOICE_OIB = 'OIB';
export const DATUM = 'Datum';
export const VRIJEME = 'Vrijeme';
export const GOST = 'Gost';
export const AGENCIJA = 'Agencija';
export const INVOICE_ADRESA = 'Adresa';
export const NACIN_PLACANJA = 'Način plaćanja';
export const USLUGA = 'Usluga';
export const JEDINICA = 'Jedinica';
export const KOLICINA = 'Količina';
export const CIJENA = 'Cijena';
export const UKUPNO = 'Ukupno';
export const UKUPNA_CIJENA = 'Ukupna cijena';
export const POPUST = 'Popust';
export const ZA_NAPLATU = 'ZA NAPLATU';
export const PDV = 'PDV';
export const PDV_PERCENTAGE = 'PDV %';
export const VAT = 'VAT';
export const VAT_PERCENTAGE = 'VAT %';
export const BALANCE = 'BALANCE';

export const INVOICE_PIN = 'PIN';
export const DATE = 'Date';
export const TIME = 'Time';
export const GUEST = 'Guest';
export const AGENCY = 'Agency';
export const INVOICE_ADDRESS = 'Address';
export const INVOICE_CHECKIN = 'Check-in';
export const INVOICE_CHECKOUT = 'Check-out';
export const PAYMENT_TYPE = 'Payment type';
export const SERVICE = 'Service';
export const UNIT = 'Unit';
export const QUANTITY = 'Quantity';
export const OVERNIGHT = 'Noćenje/Night';
export const INVOICE_BANK_INFO = 'Podaci o banci/Bank info';
export const PRICE = 'Price';
export const TOTAL = 'Total';
export const TOTAL_PRICE = 'Total price';
export const DISCOUNT = 'Discount';
export const FOR_BILLING = 'FOR BILLING';

// Placeholders
export const DATE_PLACEHOLDER = 'DATE_PLACEHOLDER';
export const TIME_PLACEHOLDER = 'TIME_PLACEHOLDER';
export const GENDER_PLACEHOLDER = 'GENDER_PLACEHOLDER';
export const COUNTRY_PLACEHOLDER = 'COUNTRY_PLACEHOLDER';
export const CITIZENSHIP_PLACEHOLDER = 'CITIZENSHIP_PLACEHOLDER';
export const CITY_PLACEHOLDER = 'CITY_PLACEHOLDER';
export const DOCUMENT_TYPE_PLACEHOLDER = 'DOCUMENT_TYPE_PLACEHOLDER';
export const PAYMENT_CATEGORY_PLACEHOLDER = 'PAYMENT_CATEGORY_PLACEHOLDER';
export const SERVICE_TYPE_PLACEHOLDER = 'SERVICE_TYPE_PLACEHOLDER';
export const TRAVEL_ARRANGEMENTS_PLACEHOLDER = 'TRAVEL_ARRANGEMENTS_PLACEHOLDER';
export const MIN_LENGTH_6 = 'MIN_LENGTH_6';

// Api urls
export const EV_ACCOUNTS_DATA_URL = '/evaccounts';
export const CHECKINS_DATA_URL = '/checkins';
export const GUESTS_DATA_URL = '/guests';
export const FACILITIES_DATA_URL = '/facilities';
export const ACCOMODATIONS_DATA_URL = '/accomodations';
export const STATIC_DATA_URL = '/evisitor_static_data';
export const LOGIN_URL = '/login';
export const MAIL_URL = '/emails';

// Query keys
export const CHECKINS_DATA = 'CHECKINS_DATA';
export const EV_ACCOUNTS_DATA = 'EV_ACCOUNTS_DATA';
export const GUESTS_DATA = 'GUESTS_DATA';
export const FACILITIES_DATA = 'FACILITIES_DATA';
export const ACCOMODATIONS_DATA = 'ACCOMODATIONS_DATA';
export const STATIC_DATA = 'STATIC_DATA';

// Rest fields
export const STATIC_DATA_FIELD = 'evisitor_static_data_type';

// Local storage
export const STORAGE_APP_ACCESS_TOKEN = 'app_access_token';
export const STORAGE_USER_ACCESS_TOKEN = 'user_access_token';
export const STORAGE_REFRESH_TOKEN = 'refresh_token';
export const STORAGE_COMM_KEY = 'comm_key';
export const STORAGE_USER_EMAIL = 'user';
export const STORAGE_USER_ID = 'user_id';
export const STORAGE_USER_ORDINAL = 'user_ordinal';
export const STORAGE_USER_DATA = 'user_data';
export const STORAGE_PASSWORD = 'pass';
export const STORAGE_CHOSEN_EV_ACCOUNT = 'chosen_ev_account';
export const STORAGE_CHOSEN_EV_FACILITY = 'chosen_ev_facility';
export const STORAGE_CREDITS = 'credits';
export const STORAGE_LANGUAGE = 'lang';
export const STORAGE_SETTINGS_DELETE_GUESTS_AUTO = 'settings_delete_guests';
export const STORAGE_USE_USER_DATA_FOR_INVOICE = 'use_user_data_invoice';
export const STORAGE_LOGO_INVOICE = 'logo_invoice';
export const STORAGE_CHOSEN_INVOICE_OWNER = 'chosen_invoice_owner';
export const STORAGE_DEBUG = 'debug';
export const STORAGE_BIRTHDAY_NOTIF = 'birthday_notifications';
export const STORAGE_BIRTHDAY_NOTIF_TIME = 'birthday_notifications_time';
export const STORAGE_EVACCOUNT_FACILITY_FETCHED = 'evaccount_facility_fetched';
export const STORAGE_INVOICE_OPERATOR = 'invoice_operator';
export const STORAGE_INVOICE_FOOTER = 'invoice_footer';
export const STORAGE_CURRENT_CHECKIN_TIME_ENABLED = 'current_checkin_time_enabled';
export const STORAGE_CURRENT_CHECKIN_TIME = 'current_checkin_time';
export const STORAGE_INVOICE_LAST_SETTINGS = 'invoice_last_checkbox_settings';
export const STORAGE_SORTING_SETTINGS = 'sorting_settings';
export const STORAGE_INVOICES_YEAR = 'invoices_year';
export const STORAGE_CHECKINS_YEAR = 'checkins_year';
export const STORAGE_RECEPCIJA_GROUP = 'recepcija';

// Urls
export const EVISITOR_GUEST_DETAILS_URL = 'EVISITOR_GUEST_DETAILS_URL';
export const ECHECKIN_GDPR_WEB_URL = 'ECHECKIN_GDPR_WEB_URL';

export const DEV_BACKEND = 'https://listria.duckdns.org';
export const PRODUCTION_BACKEND = 'https://app.echeckin.hr';

export const TERMS_URL = 'TERMS_URL';
export const PRIVACY_URL = 'PRIVACY_URL';

// Icons
export const LOGOUT_ICON = 'logout';
export const EVACCOUNTS_ICON = 'account-box';
export const CHANGE_PASSWORD_ICON = 'lock';
export const GUIDELINES_ICON = 'description';
export const DOCUMENTS_ICON = 'content-copy';
export const TERMS_ICON = 'done';
export const CONTACT_ICON = 'contact-support';
export const ABOUT_ICON = 'info';
export const SETTINGS_ICON = 'settings';
export const EXPORT_ICON = 'file-download';
export const DELETE_ICON = 'delete-forever';
export const CALL_ICON = 'call';
export const EMAIL_ICON = 'email';
export const USER_DATA_ICON = 'account';

// Contact
export const EMAIL_CONTACT = 'office@echeckin.hr';
export const EMAIL_CONTACT_SUBJECT = 'eCheckin Inquiry';
export const EMAIL_CONTACT_BODY = '';
export const PHONE_CONTACT_NUMBER = '+385914400779';

// About
export const ABOUT_OWNER = 'ABOUT_OWNER';
export const ABOUT_ADDRESS = 'ABOUT_ADDRESS';
export const ABOUT_OIB = 'ABOUT_OIB';
export const ABOUT_SWIFT = 'ABOUT_SWIFT';
export const ABOUT_IBAN = 'ABOUT_IBAN';
export const ABOUT_WEB = 'ABOUT_WEB';
export const ABOUT_EMAIL_OFFICE = 'ABOUT_EMAIL_OFFICE';

// Settings
export const SETTINGS_TITLE_GENERAL = 'SETTINGS_TITLE_GENERAL';
export const SETTINGS_TITLE_DEFAULT_CHECKIN_TIME = 'SETTINGS_TITLE_DEFAULT_CHECKIN_TIME';
export const SETTINGS_TITLE_DEFAULT_CHECKOUT_TIME = 'SETTINGS_TITLE_DEFAULT_CHECKOUT_TIME';
export const SETTINGS_TITLE_CURRENT_TIME = 'SETTINGS_TITLE_CURRENT_TIME';
export const SETTINGS_TITLE_DEFAULT_NUMBER_OF_NIGHTS = 'SETTINGS_TITLE_DEFAULT_NUMBER_OF_NIGHTS';

// Notifications
export const NOTIFICATIONS_MAIL_FIELD = 'mail_me';
export const NOTIFICATIONS_REMINDER_FIELD = 'reminder';
export const NOTIFICATIONS_NEWSLETTER_FIELD = 'newsletter';
export const NOTIFICATIONS_EMAIL_SUBFIELD = 'email';

// Birthday notifications
export const BIRTHDAY_NOTIFICATION_TITLE = 'BIRTHDAY_NOTIFICATION_TITLE';
export const SETTINGS_BIRTHDAY_NOTIFICATION_TITLE = 'SETTINGS_BIRTHDAY_NOTIFICATION_TITLE';
export const SETTINGS_BIRTHDAY_NOTIFICATION_DESCRIPTION = 'SETTINGS_BIRTHDAY_NOTIFICATION_DESCRIPTION';
export const SETTINGS_BIRTHDAY_NOTIFICATION_TIME = 'SETTINGS_BIRTHDAY_NOTIFICATION_TIME';
export const SETTINGS_BIRTHDAY_NOTIFICATION_ACTIVE = 'SETTINGS_BIRTHDAY_NOTIFICATION_ACTIVE';

// User data form fields
export const USER_DATA_FORM_ENTITY = 'USER_DATA_FORM_ENTITY';
export const USER_DATA_FORM_OIB = 'USER_DATA_FORM_OIB';
export const USER_DATA_FORM_LEGAL_NAME = 'USER_DATA_FORM_LEGAL_NAME';
export const USER_DATA_FORM_PERSONAL_NAME = 'USER_DATA_FORM_PERSONAL_NAME';
export const USER_DATA_FORM_ADDRESS = 'USER_DATA_FORM_ADDRESS';
export const USER_DATA_FORM_CITY = 'USER_DATA_FORM_CITY';
export const USER_DATA_FORM_COUNTRY = 'USER_DATA_FORM_COUNTRY';
export const USER_DATA_FORM_TEL = 'USER_DATA_FORM_TEL';
export const USER_DATA_FORM_EMAIL = 'USER_DATA_FORM_EMAIL';
export const USER_DATA_FORM_BANK = 'USER_DATA_FORM_BANK';
export const USER_DATA_FORM_BANK_IBAN = 'USER_DATA_FORM_BANK_IBAN';
export const USER_DATA_FORM_BANK_SWIFT = 'USER_DATA_FORM_BANK_SWIFT';
export const USER_DATA_FORM_BANK_ADDRESS = 'USER_DATA_FORM_BANK_ADDRESS';
export const USER_DATA_FORM_HUMAN_ENTITY = 'Fizička osoba';
export const USER_DATA_FORM_LEGAL_ENTITY = 'Pravna osoba';
export const USER_DATA_FORM_IN_PDV_SYSTEM = 'USER_DATA_FORM_IN_PDV_SYSTEM';

// Paper picker
export const SELECT_DATE = 'SELECT_DATE';
export const SELECT_DATES = 'SELECT_DATES';
export const SELECT_ARRIVAL_DEPARTURE = 'SELECT_ARRIVAL_DEPARTURE';
export const SELECT_PERIOD = 'SELECT_PERIOD';
export const DATE_FORMAT_MUST_BE = 'DATE_FORMAT_MUST_BE';
export const MUST_BE_LATER_THEN = 'MUST_BE_LATER_THEN';
export const MUST_BE_EARLIER_THEN = 'MUST_BE_EARLIER_THEN';
export const MUST_BE_BETWEEN = 'MUST_BE_BETWEEN';
export const DAY_IS_NOT_ALLOWED = 'DAY_IS_NOT_ALLOWED';
export const PREVIOUS = 'PREVIOUS';
export const NEXT = 'NEXT';
export const TYPE_IN_DATE = 'TYPE_IN_DATE';
export const PICK_DATE_FROM_CALENDAR = 'PICK_DATE_FROM_CALENDAR';
export const CLOSE = 'CLOSE';
export const LABEL_FROM = 'LABEL_FROM';
export const LABEL_TO = 'LABEL_TO';

// Camera fields UNIVERSAL ID
export const CAM_RESULT = 'result';
export const CAM_IDENTIFIER = 'identifier';
export const CAM_IDENTIFIER_JSON = 'resultJson';
export const CAM_TEXT = 'text';
export const CAM_DATE_BIRTH = 'dateOfBirth'; //01.01.1980
export const CAM_FORMAT_DATE_BIRTH = 'formattedDateOfBirth';
export const CAM_DATE_EXPIRY = 'dateOfExpiry';
export const CAM_FORMAT_DATE_EXPIRY = 'formattedDateOfExpiry';
export const CAM_DOCUMENT = 'documentNumber'; // 113966373
export const CAM_FNAME = 'firstName';
export const CAM_LNAME = 'lastName';
export const CAM_NATIONALITY = 'nationality'; // HRV
export const CAM_SEX = 'sex'; // M/M
export const CAM_FORMAT_DATEOBJECT_BIRTH = 'dateOfBirthObject';

// Camera fields MRZ
export const CAM_SNAME = 'surname';
export const CAM_GNAME = 'givenNames';
export const CAM_MRZ_SEX = 'sex'; // M
export const CAM_MRZ_NATIONALITY = 'nationalityCountryCode'; // HRV
export const CAM_MRZ_DOC_TYPE = 'documentType'; // IO
export const CAM_MRY_ISSUING_COUNTRY = 'issuingCountryCode'; // HRK

// Links
export const EVISITOR_QR_EXAMPLE = 'EVISITOR_QR_EXAMPLE';

// Langs settings
export const APP_LANGS_SETTINGS = [
    {code: LANGUAGE_EN_CODE, name: LANGUAGE_EN, name_en: LANGUAGE_EN_EN, name_hr: LANGUAGE_HR_EN},
    {code: LANGUAGE_HR_CODE, name: LANGUAGE_HR, name_en: LANGUAGE_EN_HR, name_hr: LANGUAGE_HR_HR},
];

// Langs
export const APP_LANGS = [
    {code: LANGUAGE_EN_CODE, name: LANGUAGE_EN, name_en: LANGUAGE_EN_EN, name_hr: LANGUAGE_HR_EN},
    {code: LANGUAGE_HR_CODE, name: LANGUAGE_HR, name_en: LANGUAGE_EN_HR, name_hr: LANGUAGE_HR_HR},
    {code: LANGUAGE_DE_CODE, name: LANGUAGE_DE, name_en: LANGUAGE_EN_DE, name_hr: LANGUAGE_HR_DE},
    {code: LANGUAGE_FR_CODE, name: LANGUAGE_FR, name_en: LANGUAGE_EN_FR, name_hr: LANGUAGE_HR_FR},
    {code: LANGUAGE_IT_CODE, name: LANGUAGE_IT, name_en: LANGUAGE_EN_IT, name_hr: LANGUAGE_HR_IT},
    {code: LANGUAGE_ES_CODE, name: LANGUAGE_ES, name_en: LANGUAGE_EN_ES, name_hr: LANGUAGE_HR_ES},
    {code: LANGUAGE_PL_CODE, name: LANGUAGE_PL, name_en: LANGUAGE_EN_PL, name_hr: LANGUAGE_HR_PL},
    {code: LANGUAGE_CS_CODE, name: LANGUAGE_CS, name_en: LANGUAGE_EN_CS, name_hr: LANGUAGE_HR_CS},
    {code: LANGUAGE_HU_CODE, name: LANGUAGE_HU, name_en: LANGUAGE_EN_HU, name_hr: LANGUAGE_HR_HU},
    {code: LANGUAGE_BG_CODE, name: LANGUAGE_BG, name_en: LANGUAGE_EN_BG, name_hr: LANGUAGE_HR_BG},
    {code: LANGUAGE_ZH_CODE, name: LANGUAGE_ZH, name_en: LANGUAGE_EN_ZH, name_hr: LANGUAGE_HR_ZH},
    {code: LANGUAGE_DA_CODE, name: LANGUAGE_DA, name_en: LANGUAGE_EN_DA, name_hr: LANGUAGE_HR_DA},
    {code: LANGUAGE_ET_CODE, name: LANGUAGE_ET, name_en: LANGUAGE_EN_ET, name_hr: LANGUAGE_HR_ET},
    {code: LANGUAGE_FI_CODE, name: LANGUAGE_FI, name_en: LANGUAGE_EN_FI, name_hr: LANGUAGE_HR_FI},
    {code: LANGUAGE_HI_CODE, name: LANGUAGE_HI, name_en: LANGUAGE_EN_HI, name_hr: LANGUAGE_HR_HI},
    {code: LANGUAGE_JA_CODE, name: LANGUAGE_JA, name_en: LANGUAGE_EN_JA, name_hr: LANGUAGE_HR_JA},
    {code: LANGUAGE_KO_CODE, name: LANGUAGE_KO, name_en: LANGUAGE_EN_KO, name_hr: LANGUAGE_HR_KO},
    {code: LANGUAGE_LT_CODE, name: LANGUAGE_LT, name_en: LANGUAGE_EN_LT, name_hr: LANGUAGE_HR_LT},
    {code: LANGUAGE_LV_CODE, name: LANGUAGE_LV, name_en: LANGUAGE_EN_LV, name_hr: LANGUAGE_HR_LV},
    {code: LANGUAGE_NB_CODE, name: LANGUAGE_NB, name_en: LANGUAGE_EN_NB, name_hr: LANGUAGE_HR_NB},
    {code: LANGUAGE_NL_CODE, name: LANGUAGE_NL, name_en: LANGUAGE_EN_NL, name_hr: LANGUAGE_HR_NL},
    {code: LANGUAGE_PT_CODE, name: LANGUAGE_PT, name_en: LANGUAGE_EN_PT, name_hr: LANGUAGE_HR_PT},
    {code: LANGUAGE_RO_CODE, name: LANGUAGE_RO, name_en: LANGUAGE_EN_RO, name_hr: LANGUAGE_HR_RO},
    {code: LANGUAGE_RU_CODE, name: LANGUAGE_RU, name_en: LANGUAGE_EN_RU, name_hr: LANGUAGE_HR_RU},
    {code: LANGUAGE_SI_CODE, name: LANGUAGE_SI, name_en: LANGUAGE_EN_SI, name_hr: LANGUAGE_HR_SI},
    {code: LANGUAGE_SK_CODE, name: LANGUAGE_SK, name_en: LANGUAGE_EN_SK, name_hr: LANGUAGE_HR_SK},
    {code: LANGUAGE_SV_CODE, name: LANGUAGE_SV, name_en: LANGUAGE_EN_SV, name_hr: LANGUAGE_HR_SV},
    {code: LANGUAGE_TR_CODE, name: LANGUAGE_TR, name_en: LANGUAGE_EN_TR, name_hr: LANGUAGE_HR_TR},
    {code: LANGUAGE_UA_CODE, name: LANGUAGE_UA, name_en: LANGUAGE_EN_UA, name_hr: LANGUAGE_HR_UA},
];

export const HR_LOCALE = 'hr';
export const HR_LOCALE_ALT = 'cro';

// Headers
export const HEADER_ANDROID = 'e-android';
export const HEADER_IOS = 'e-ios';
export const HEADER_WEB = 'e-web';
export const HEADER_SELF_CHECKIN = 'e-self-check-in';

// Invoice Payment Types
export const CASH = 'CASH';
export const TRANSACTION_ACCOUNT = 'TRANSACTION_ACCOUNT';
export const CREDIT_CARD = 'CREDIT_CARD';
export const PORTAL_PAYMENT = 'PORTAL_PAYMENT';
export const OTHER_PAYMENT = 'OTHER_PAYMENT';

// Invoice Customer Types
export const GUEST_TYPE = 'GUEST_TYPE';
export const COMPANY_TYPE = 'COMPANY_TYPE';
//export const AGENCY_TYPE = 'AGENCY_TYPE';

// Invoice Labels
export const INVOICE_NUMBER_LABEL = 'INVOICE_NUMBER_LABEL'; // Invoice number
export const INVOICE_PROFORMA_NUMBER_LABEL = 'INVOICE_PROFORMA_NUMBER_LABEL'; // Invoice number
export const INVOICE_OWNER_LABEL = 'INVOICE_OWNER_LABEL'; // Owner
export const INVOICE_OWNER_ADDR_LABEL = 'INVOICE_OWNER_ADDR_LABEL'; // Owner address
export const INVOICE_DATE_LABEL = 'INVOICE_DATE_LABEL'; // Invoice date
export const INVOICE_PROFORMA_DATE_LABEL = 'INVOICE_PROFORMA_DATE_LABEL'; // Invoice proforma date
export const INVOICE_TIME_LABEL = 'INVOICE_TIME_LABEL'; // Invoice time
export const INVOICE_PROFORMA_TIME_LABEL = 'INVOICE_PROFORMA_TIME_LABEL'; // Invoice proforma time
export const INVOICE_CUSTOMER_TYPE_GUEST_LABEL = 'INVOICE_CUSTOMER_TYPE_GUEST_LABEL'; // Guest
export const INVOICE_CUSTOMER_TYPE_COMPANY_LABEL = 'INVOICE_CUSTOMER_TYPE_COMPANY_LABEL'; // Company
export const INVOICE_PRICE_COUNT_LABEL = 'INVOICE_PRICE_COUNT_LABEL'; // Price count label
export const INVOICE_PRICE_TYPE_PER_NIGHT_LABEL = 'INVOICE_PRICE_TYPE_PER_NIGHT_LABEL'; // Price per night
export const INVOICE_PRICE_TYPE_PRICE_TOTAL_LABEL = 'INVOICE_PRICE_TYPE_PRICE_TOTAL_LABEL'; // Price total
export const INVOICE_CUSTOMER_TYPE_GUEST_ADDR_LABEL = 'INVOICE_CUSTOMER_TYPE_GUEST_ADDR_LABEL'; // Guest address
export const INVOICE_CUSTOMER_TYPE_COMPANY_ADDR_LABEL = 'INVOICE_CUSTOMER_TYPE_COMPANY_ADDR_LABEL'; // Company address
export const INVOICE_SERVICE_TYPE_LABEL = 'INVOICE_SERVICE_TYPE_LABEL'; // Service type
export const INVOICE_PAYMENT_TYPE_LABEL = 'INVOICE_PAYMENT_TYPE_LABEL'; // Payment type
export const INVOICE_ACC_UNIT_LABEL = 'INVOICE_ACC_UNIT_LABEL'; // Accomodation unit
export const INVOICE_CURRENCY_LABEL = 'INVOICE_CURRENCY_LABEL'; // Currency
export const INVOICE_PRICE_PER_NIGHT_LABEL = 'INVOICE_PRICE_PER_NIGHT_LABEL'; // Price per night
export const INVOICE_PRICE_DISCOUNT_LABEL = 'INVOICE_PRICE_DISCOUNT_LABEL'; // Discount
export const INVOICE_FORM_PRICE_TOTAL_LABEL = 'INVOICE_FORM_PRICE_TOTAL_LABEL'; // Total price
export const INVOICE_PRICE_FINAL_LABEL = 'INVOICE_PRICE_FINAL_LABEL'; // Final price
export const INVOICE_SOJOURN_TAX_LABEL =
    'Turistička pristojba uključena u cijenu. / Sojourn tax included in the price of service.';
export const INVOICE_SOJOURN_TAX_NOT_ICLUDED =
    'Turisticka pristojba je prema clanku 33. st.3. Zakona o PDV-u prolazna stavka. / Tourist tax is exempt from VAT in accordance with Article 33, paragraph 3 of the VAT Act.';
export const INVOICE_ELECTRONIC_INVOICE =
    'Ovaj račun izdan je u elektroničkom obliku sukladno članku 80. Zakona o porezu na dodatnu vrijednost i odredbama članka 161 pravilnika o porezu na dodatnu vrijednost i vrijedi bez potpisa i žiga. / This is an electronic invoice in accordance with Article 80 of the VAT Act and the provisions of the Article 161 of the VAT Ordinance and it is valid withouth signature and stamp.';
export const INVOICE_OWNER_IN_PDV_SYSTEM =
    'Obveznik nije u sustavu PDV-a, PDV nije obračunat temeljem čl. 90 st. 1 i st. 2 Zakona o PDV-u';
export const INVOICE_NR_FIELD = 'INVOICE_NR_FIELD';
export const INVOICE_P_UNIT_FIELD = 'INVOICE_P_UNIT_FIELD';
export const INVOICE_PAY_DESK_FIELD = 'INVOICE_PAY_DESK_FIELD';
export const INVOICE_QUANTITY = 'INVOICE_QUANTITY';
export const INVOICE_CLIENT = 'INVOICE_CLIENT';
export const INVOICE_IN_NAME = 'INVOICE_IN_NAME';
export const INVOICE_IN_NAME_LABEL = 'INVOICE_IN_NAME_LABEL';
export const INVOICE_FINAL_NOTES_LABEL = 'INVOICE_FINAL_NOTES_LABEL';
export const INVOICE_SHOW_OPERATER_LABEL = 'INVOICE_SHOW_OPERATER_LABEL';
export const INVOICE_SHOW_FOOTER_LABEL = 'INVOICE_SHOW_FOOTER_LABEL';
export const INVOICE_SOJOURN_TAX_TOTAL_LABEL = 'INVOICE_SOJOURN_TAX_TOTAL_LABEL';

// Invoice fields
export const GUEST_INFO = 'GUEST_INFO';
export const INVOICE_OTHER_OWER = 'INVOICE_OTHER_OWER';
export const INVOICE_CUSTOM_OWNER_FIELD = 'INVOICE_CUSTOM_OWNER_FIELD';

// Invoice form fields
export const INVOICE_TYPE = 'INVOICE_TYPE';
export const INVOICE_TYPE_INVOICE = '0';
export const INVOICE_TYPE_PROFORMA = '1';
export const INVOICE_FORM_STAY_FROM = 'INVOICE_FORM_STAY_FROM';
export const INVOICE_FORM_STAY_TO = 'INVOICE_FORM_STAY_TO';
export const INVOICE_FORM_NUMBER = 'INVOICE_FORM_NUMBER';
export const INVOICE_FORM_LANG = 'INVOICE_FORM_LANG';
export const INVOICE_FORM_OWNER = 'INVOICE_FORM_OWNER';
export const INVOICE_FORM_OWNER_ADDRESS = 'INVOICE_FORM_OWNER_ADDRESS';
export const INVOICE_FORM_CUSTOMER_TYPE = 'INVOICE_FORM_CUSTOMER_TYPE';
export const INVOICE_FORM_GUEST = 'INVOICE_FORM_GUEST';
export const INVOICE_FORM_GUEST_ADDRESS = 'INVOICE_FORM_GUEST_ADDRESS';
export const INVOICE_FORM_DATE = 'INVOICE_FORM_DATE';
export const INVOICE_FORM_TIME = 'INVOICE_FORM_TIME';
export const INVOICE_FORM_SERVICE_TYPE = 'INVOICE_FORM_SERVICE_TYPE';
export const INVOICE_FORM_ACC_UNIT = 'INVOICE_FORM_ACC_UNIT';
export const INVOICE_FORM_PAYMENT_TYPE = 'INVOICE_FORM_PAYMENT_TYPE';
export const INVOICE_FORM_CURRENCY = 'INVOICE_FORM_CURRENCY';
export const INVOICE_FORM_PRICE_PER_NIGHT = 'INVOICE_FORM_PRICE_PER_NIGHT';
export const INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE = 'INVOICE_FORM_OVERNIGHT_DISCOUNT_PERCENTAGE';
export const INVOICE_FORM_OVERNIGHT_DISCOUNT_AMOUNT = 'INVOICE_FORM_OVERNIGHT_DISCOUNT_AMOUNT';
export const INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV = 'INVOICE_FORM_OVERNIGHT_TOTAL_BEFORE_PDV';
export const INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE = 'INVOICE_FORM_OVERNIGHT_PDV_PERCENTAGE';
export const INVOICE_FORM_OVERNIGHT_PDV_AMOUNT = 'INVOICE_FORM_OVERNIGHT_PDV_AMOUNT';
export const INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV = 'INVOICE_FORM_OVERNIGHT_TOTAL_WITH_PDV';
export const INVOICE_FORM_PDV_TOTAL_SUM = 'INVOICE_FORM_PDV_TOTAL_SUM';
export const INVOICE_FORM_DISCOUNT_TOTAL_SUM = 'INVOICE_FORM_DISCOUNT_TOTAL_SUM';
export const TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM = 'TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV_SUM';
export const OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV = 'OVERNIGHT_TOTAL_WITHOUT_DISCOUNT_BEFORE_PDV';
export const INVOICE_FORM_PRICE_FINAL = 'INVOICE_FORM_PRICE_FINAL';
export const INVOICE_FORM_GUEST_STAY_RANGE = 'INVOICE_FORM_GUEST_STAY_RANGE';
export const INVOICE_FORM_OVERNIGHT_COUNT = 'INVOICE_FORM_OVERNIGHT_COUNT';
export const INVOICE_FORM_OWNER_OIB = 'INVOICE_FORM_OWNER_OIB';
export const INVOICE_FORM_GUEST_TYPE = '1';
export const INVOICE_FORM_COMPANY_TYPE = '0';
export const INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED = 'INVOICE_FORM_TOURIST_TAX_ALREADY_INCLUDED';
export const INVOICE_FORM_TOURIST_TAX_RATE_LABEL = 'INVOICE_FORM_TOURIST_TAX_RATE_LABEL';
export const INVOICE_FORM_TOURIST_TAX_ADULT_COUNT = 'INVOICE_FORM_TOURIST_TAX_ADULT_COUNT';
export const INVOICE_FORM_TOURIST_TAX_CHILD_COUNT = 'INVOICE_FORM_TOURIST_TAX_CHILD_COUNT';
export const INVOICE_FORM_TOURIST_TAX_ADULT_COUNT_LABEL = 'INVOICE_FORM_TOURIST_TAX_ADULT_COUNT_LABEL';
export const INVOICE_FORM_TOURIST_TAX_CHILD_COUNT_LABEL = 'INVOICE_FORM_TOURIST_TAX_CHILD_COUNT_LABEL';
export const INVOICE_FORM_TOURIST_TAX_ADULT_RATE = 'INVOICE_FORM_TOURIST_TAX_ADULT_RATE';
export const INVOICE_FORM_TOURIST_TAX_CHILD_RATE = 'INVOICE_FORM_TOURIST_TAX_CHILD_RATE';
export const INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL = 'INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL';
export const INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL = 'INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL';
export const INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL_LABEL = 'INVOICE_FORM_TOURIST_TAX_ADULT_TOTAL_LABEL';
export const INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL_LABEL = 'INVOICE_FORM_TOURIST_TAX_CHILD_TOTAL_LABEL';
export const INVOICE_FORM_TOURIST_TAX_TOTAL = 'INVOICE_FORM_TOURIST_TAX_TOTAL';
export const INVOICE_FORM_CLIENT = 'INVOICE_FORM_CLIENT';
export const INVOICE_FORM_OPERATOR_NAME = 'INVOICE_FORM_OPERATOR_NAME';
export const INVOICE_FORM_OPERATOR_REF = 'INVOICE_FORM_OPERATOR_REF';
export const INVOICE_FORM_FOOTER_TEXT = 'INVOICE_FORM_FOOTER_TEXT';
export const INVOICE_FORM_COMPANY_OIB = 'INVOICE_FORM_COMPANY_OIB';
export const INVOICE_FORM_IN_NAME = 'INVOICE_FORM_IN_NAME';
export const INVOICE_FORM_IN_NAME_OIB = 'INVOICE_FORM_IN_NAME_OIB';
export const INVOICE_FORM_IN_NAME_ADDRESS = 'INVOICE_FORM_IN_NAME_ADDRESS';
export const INVOICE_FORM_USER_IN_PDV_SYSTEM = 'INVOICE_FORM_USER_IN_PDV_SYSTEM';
export const CALCULATION_PRICE_TYPE = 'CALCULATION_PRICE_TYPE';
export const CALCULATION_PRICE_TYPE_PER_NIGHT = '0';
export const CALCULATION_PRICE_TYPE_PER_FINAL_PRICE = '1';
export const INVOICE_FORM_ELECTRONIC_INVOICE = 'INVOICE_FORM_ELECTRONIC_INVOICE';
export const INVOICE_FORM_SHOW_OPERATER = 'INVOICE_FORM_SHOW_OPERATER';
export const INVOICE_FORM_SHOW_FOOTER = 'INVOICE_FORM_SHOW_FOOTER';
export const INVOICE_FORM_VER = 'INVOICE_FORM_VER';

// Export
export const DOWNLOAD_TOURISTS_LABEL = 'DOWNLOAD_TOURISTS_LABEL';
export const TOURISTS_DATASET = 'TOURISTS_DATASET';
export const INVOICES_DATASET = 'INVOICES_DATASET';
export const EXPORT_INVOICE_ORDINAL = 'EXPORT_INVOICE_ORDINAL';
export const EXPORT_INVOICE_NUMBER = 'EXPORT_INVOICE_NUMBER';
export const EXPORT_INVOICE_DATE = 'EXPORT_INVOICE_DATE';
export const EXPORT_INVOICE_NAME = 'EXPORT_INVOICE_NAME';
export const EXPORT_INVOICE_PRICE = 'EXPORT_INVOICE_PRICE';
export const EXPORT_INVOICE_PAYMENT_TYPE = 'EXPORT_INVOICE_PAYMENT_TYPE';

// Export reports
export const EXPORT_HEADER_INTRO = 'EXPORT_HEADER_INTRO';
export const EXPORT_HEADER_NAME = 'EXPORT_HEADER_NAME';
export const EXPORT_HEADER_OIB = 'EXPORT_HEADER_OIB';
export const EXPORT_HEADER_PLACE = 'EXPORT_HEADER_PLACE';
export const EXPORT_HEADER_ADDRESS = 'EXPORT_HEADER_ADDRESS';
export const EXPORT_HEADER_REPORT_NAME = 'EXPORT_HEADER_REPORT_NAME';
export const EXPORT_INVOICE_HEADER_REPORT_BOOK_NAME = 'EXPORT_INVOICE_HEADER_REPORT_BOOK_NAME';
export const EXPORT_TOURISTS_HEADER_REPORT_BOOK_NAME = 'EXPORT_TOURISTS_HEADER_REPORT_BOOK_NAME';
export const EXPORT_HEADER_REPORT_TIME = 'EXPORT_HEADER_REPORT_TIME';
export const EXPORT_HEADER_REPORT_OBJECT = 'EXPORT_HEADER_REPORT_OBJECT';
export const EXPORT_HEADER_REPORT_OBJECT_ALL = 'EXPORT_HEADER_REPORT_OBJECT_ALL';
export const EXPORT_HEADER_REPORT_PERIOD = 'EXPORT_HEADER_REPORT_PERIOD';
export const EXPORT_HEADER_NUMBER_OF_NIGHTS = 'EXPORT_HEADER_NUMBER_OF_NIGHTS';
export const EXPORT_HEADER_CITIZENSHIP_REPORT_NAME = 'EXPORT_HEADER_CITIZENSHIP_REPORT_NAME';
export const EXPORT_HEADER_GENDERS_REPORT_NAME = 'EXPORT_HEADER_GENDERS_REPORT_NAME';
export const EXPORT_HEADER_AGE_REPORT_NAME = 'EXPORT_HEADER_AGE_REPORT_NAME';

// Sorting fields
export const CHECKIN_SORT_OPTIONS = 'CHECKIN_SORT_OPTIONS';
export const GUEST_SORT_OPTIONS = 'GUEST_SORT_OPTIONS';
export const LAST_ADDED = 'LAST_ADDED';
export const FIRST_ADDED = 'FIRST_ADDED';
export const DATE_OF_ARRIVAL = 'DATE_OF_ARRIVAL';
export const DATE_OF_DEPARTURE = 'DATE_OF_DEPARTURE';

// App versions
export const APP_VERSION = '1.8.46';
