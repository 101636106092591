import React from 'react';
import {Pressable, View} from 'react-native';
import {Icon, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import globalStyle from '../../theme/globalStyle';
import useStyles from './GdprDialog.styles';

const GdprDialog = ({setVisible, visible, title, children}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const toggleOverlay = () => {
        setVisible(!visible);
    };

    return (
        <Modal
            isVisible={visible}
            onBackdropPress={toggleOverlay}
            style={styles.modal}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={[global.boxShadow, styles.modalDialog]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>eVisitor GDPR</Text>
                    <Pressable onPress={toggleOverlay} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <View style={{flex: 1, overflow: 'auto'}}>
                    <View>{children}</View>
                </View>
            </View>
        </Modal>
    );
};

export default GdprDialog;
