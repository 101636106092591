const CSS_COLOR_NAMES_TEMPLATE = [
    'Blue',
    'Green',
    'Yellow',
    'Black',
    'Red',
    'Brown',
    'Cyan',
    'Gray',
    'Green',
    'Magenta',
    'Pink',
    'Purple',
];

const generateColors = () => {
    let o = CSS_COLOR_NAMES_TEMPLATE;
    for (let i = 0; i < 4; i++) {
        o = o.concat(o);
    }
    return o;
};

export const CSS_COLOR_NAMES = generateColors();
