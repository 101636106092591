import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, Pressable, View, useWindowDimensions} from 'react-native';
import {Icon, Input, Text, useTheme} from 'react-native-elements';
import {
    ACCOMODATION_OBJECT,
    ACCOMODATION_UNIT,
    ADDITIONAL_FIELDS,
    ARRIVAL_LABEL,
    CHECKIN_NOTE_FIELD,
    CODE,
    DATE_OF_ARRIVAL,
    DATE_OF_DEPARTURE,
    DEPARTURE_LABEL,
    EVISITOR,
    FACILITY_CODE,
    FACILITY_FIELD,
    FIRST_ADDED,
    FORESEEN_STAY_UNTIL_FIELD,
    GUESTS,
    GUEST_SORT_OPTIONS,
    ID,
    INVOICE,
    LAST_ADDED,
    MAX_GUEST_NUMBER,
    NAME,
    NAME_CODE,
    NOTE2,
    PIN,
    PIN_ID,
    RESERVATION,
    RESERVATION_HOLDER,
    RESERVATION_UNIT_NAME,
    SELF_CHECKIN_BUTTON_LABEL,
    STAY_FROM_FIELD,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    TIME_STAY_FROM_FIELD,
} from '../../constants/stringsAndFields';
import DataContext from '../../context/dataContext';
import globalStyle from '../../theme/globalStyle';
import {
    getDateFromDateString,
    getDateFromTime,
    getFormattedDateFromDateString,
    getStringDate,
    getStringTime,
} from '../../utils/dateHelper';
import {isManualGuestDate, isManualGuestTime} from '../../utils/helpers';
import {
    getSortingLastSettings,
    isEVAccountFetchedFacility,
    saveEVAccountFetchedFacilityFlag,
    setSortingLastSettings,
} from '../../utils/userUtils';
import DateTimePicker from '../datetime/DateTimePicker';
import Dropdown from '../ui-components/Dropdown/Dropdown';
import useStyles from './CheckinDetailsHeader.styles';
import SelectAccomodationDialog from './SelectAccomodationDialog';
import SelectOwnerDialog from './SelectOwnerDialog';

const HIDE_UPDATE_CHECKIN_MESSAGES = true;
const IS_WEB = Platform.OS === 'web';

const CheckinDetailsHeader = ({
    checkin,
    setCheckin,
    disableArrivalForCheckedIn,
    onUpdateCheckin,
    onClickInvoice,
    onUpdateAccomodation,
    useSharedCheckinActions,
    useSharedDialogs,
    isReservation = false,
}) => {
    const {evaccounts, getEvisitor, refetchEvisitor} = useContext(DataContext);
    const {openShare} = useSharedCheckinActions();
    const {openNoteEdit, updateSortGuestsBy} = useSharedDialogs();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const [datePickerData, setDatePickerData] = useState(null);
    const [closeDateTimePicker, setCloseDateTimePicker] = useState(true);
    const [showSelectOwner, setShowSelectOwner] = useState(false);
    const [showSelectAccomodation, setShowSelectAccomodation] = useState(false);
    const [accomodations, setAccomodations] = useState([]);
    const [sortGuestsBy, setSortGuestsBy] = useState(null);

    const showCheckinNote =
        checkin?.[ADDITIONAL_FIELDS]?.[CHECKIN_NOTE_FIELD] && checkin?.[ADDITIONAL_FIELDS]?.[CHECKIN_NOTE_FIELD] !== ''
            ? true
            : false;

    const {t} = useTranslation();
    const styles = useStyles(theme);

    const width = useWindowDimensions().width;
    const isMobileSmall = width < 500;

    const fromDayRef = useRef(null);
    const toDayRef = useRef(null);
    const fromTimeRef = useRef(null);
    const toTimeRef = useRef(null);

    const showDatePickers = isManualGuestDate(checkin) ? false : true;
    const showTimePickers = isManualGuestTime(checkin) ? false : true;

    const numberOfGuests = checkin?.['guests']?.length ?? 0;
    const maxGuestSize = checkin?.[ADDITIONAL_FIELDS]?.[MAX_GUEST_NUMBER] ?? '';

    const dropdownSortOptions = [
        {text: t(LAST_ADDED), sortBy: LAST_ADDED},
        {text: t(FIRST_ADDED), sortBy: FIRST_ADDED},
        {text: t(DATE_OF_ARRIVAL), sortBy: DATE_OF_ARRIVAL},
        {text: t(DATE_OF_DEPARTURE), sortBy: DATE_OF_DEPARTURE},
    ];

    const onSelectedSortOption = async option => {
        const sortOption = option.sortBy;
        updateSortGuestsBy(sortOption);
        const settings = await getSortingLastSettings();
        setSortingLastSettings({...settings, [GUEST_SORT_OPTIONS]: sortOption});
        setSortGuestsBy(sortOption);
    };

    const onShareGuestClick = _ => openShare();

    const updateCheckin = async (field, value) => {
        checkin[field] = value;
        try {
            await onUpdateCheckin(checkin, HIDE_UPDATE_CHECKIN_MESSAGES);
            setCheckin(checkin);
        } catch (e) {
            console.log(e);
        }
    };

    const onChangeDate = async (date, field) => {
        await updateCheckin(field, getStringDate(date));
    };

    const onChangeTime = async (date, field) => {
        await updateCheckin(field, getStringTime(date));
    };

    const openDateTimePicker = (mode, field, value) => {
        setDatePickerData({
            datePickerMode: mode,
            datePickerDate: mode === 'date' ? getDateFromDateString(value) : getDateFromTime(value),
            currentPickerField: field,
            checkinData: checkin,
        });
        setCloseDateTimePicker(false);
    };

    const editOwner = () => {
        setShowSelectOwner(true);
    };

    const editAccomodation = () => {
        setShowSelectAccomodation(true);
    };

    const checkAndFetchFacilities = async evAccount => {
        let facilities = evAccount ? evAccount?.[ACCOMODATION_OBJECT] : [];
        if (facilities && facilities.length === 0) {
            const isAlreadyFetched = await isEVAccountFetchedFacility(evAccount[PIN]);
            if (!isAlreadyFetched) {
                await refetchEvisitor(evAccount);
                await saveEVAccountFetchedFacilityFlag(evAccount[PIN]);
                const updatedEvisitor = await getEvisitor(evAccount[ID]);
                facilities = extendFacilities(evAccount[PIN_ID], updatedEvisitor);
            }
        } else {
            facilities = extendFacilities(evAccount[PIN_ID], evAccount);
        }

        return facilities;
    };

    const setDefaultFacility = async (facilities, selectedEvAccount) => {
        if (facilities && facilities.length > 0) {
            const initFacility = facilities[0];
            if (initFacility) {
                const refreshedCheckin = await onUpdateAccomodation(initFacility, checkin);
                refreshedCheckin[ADDITIONAL_FIELDS][EVISITOR] = {
                    [NAME]: selectedEvAccount?.[NAME],
                    [PIN_ID]: selectedEvAccount?.[PIN_ID],
                    [PIN]: selectedEvAccount?.[PIN],
                };
                refreshedCheckin[ADDITIONAL_FIELDS][FACILITY_FIELD] = {
                    ...initFacility,
                };
                await onUpdateCheckin(refreshedCheckin, HIDE_UPDATE_CHECKIN_MESSAGES);
                setCheckin(refreshedCheckin);
                facilities && setAccomodations(facilities);
            }
        }
    };

    const onSelectedOwner = async selectedEvAccount => {
        try {
            const updatedFacilities = await checkAndFetchFacilities(selectedEvAccount);
            await setDefaultFacility(updatedFacilities, selectedEvAccount);
        } catch (e) {
            console.log(e);
        }
    };

    const onSelectAccomodation = async accomodation => {
        try {
            const refreshedCheckin = await onUpdateAccomodation(accomodation, checkin);
            refreshedCheckin[ADDITIONAL_FIELDS][FACILITY_FIELD] = {
                ...accomodation,
            };
            await onUpdateCheckin(refreshedCheckin, HIDE_UPDATE_CHECKIN_MESSAGES);
            setCheckin(refreshedCheckin);
        } catch (e) {
            console.log(e);
        }
    };

    const extendFacilities = (evisitorPinId, selectedEvisitor = null) => {
        if (evisitorPinId && evaccounts) {
            const evisitor = selectedEvisitor
                ? selectedEvisitor
                : evaccounts?.find(acc => acc[PIN_ID] === evisitorPinId);
            if (evisitor) {
                const filteredFacilities = evisitor ? evisitor?.[ACCOMODATION_OBJECT] : [];
                const unitCodes = evisitor[ACCOMODATION_UNIT].map(unit => unit[CODE]);
                const activeFacilities = filteredFacilities.filter(facility =>
                    unitCodes.includes(facility[FACILITY_CODE])
                );
                if (activeFacilities && activeFacilities.length > 0) {
                    for (let facility of activeFacilities) {
                        const parentObject = evisitor[ACCOMODATION_UNIT].find(
                            unit => facility[FACILITY_CODE] === unit[CODE]
                        );
                        if (parentObject) {
                            facility[NAME] = parentObject[NAME];
                        }
                    }
                }
                return activeFacilities;
            }
        }
    };

    useEffect(() => {
        if (evaccounts) {
            const facilities = extendFacilities(checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID], null);
            facilities && setAccomodations(facilities);
        }
    }, [evaccounts, checkin]);

    useEffect(() => {
        getSortingLastSettings().then(sortingSettings => {
            sortingSettings && setSortGuestsBy(sortingSettings[GUEST_SORT_OPTIONS]);
        });
    }, []);

    const ownerName = isReservation
        ? checkin?.[ADDITIONAL_FIELDS]?.[RESERVATION]?.[RESERVATION_HOLDER]
        : checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[NAME];

    const ownerFacilityTitle = isReservation
        ? checkin?.[ADDITIONAL_FIELDS]?.[RESERVATION]?.[RESERVATION_UNIT_NAME] ?? ''
        : checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME];

    const ownerFacilitySubtitle = isReservation ? '' : checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME_CODE];

    return (
        <>
            <View style={[global.boxShadow, {marginBottom: 20, borderRadius: 10}]}>
                <View style={global.card}>
                    <Pressable onPress={editOwner} style={[global.cardHeader]}>
                        <Text style={[global.cardHeaderText]}>{ownerName}</Text>
                        {!isReservation && (
                            <Icon name="edit" size={16} style={{marginLeft: 20}} color={theme.colors.primary} />
                        )}
                    </Pressable>

                    <Pressable style={[styles.editAccomodation]} onPress={editAccomodation}>
                        <View style={{flex: 1}}>
                            <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 3}}>
                                <Icon
                                    type="ionicon"
                                    name="home-outline"
                                    size={16}
                                    style={{marginRight: 8}}
                                    color={theme.colors.textColorMedium}
                                />
                                <Text style={[global.textCaps, {lineHeight: 16}]}>{ownerFacilityTitle}</Text>
                            </View>

                            <Text
                                style={[
                                    global.textCaps,
                                    {fontSize: 10, color: theme.colors.textColorLight, marginLeft: 24},
                                ]}>
                                {ownerFacilitySubtitle}
                            </Text>
                        </View>

                        {!isReservation && (
                            <Icon name="edit" size={16} style={{marginLeft: 20}} color={theme.colors.primary} />
                        )}
                    </Pressable>

                    {showDatePickers && (
                        <View style={[global.flexCenter, {padding: 15, marginTop: 5}]}>
                            <View style={[global.timeBox, {marginRight: 10}]}>
                                <View style={global.timeBoxTitle}>
                                    <Icon
                                        type="ionicon"
                                        name="enter-outline"
                                        size={19}
                                        style={{marginRight: 5}}
                                        color={theme.colors.greyMedium}
                                    />
                                    <Text style={[global.textCaps, {fontSize: 10}]}>{t(ARRIVAL_LABEL)}</Text>
                                </View>
                                <View style={global.timeInputWrapper}>
                                    <Input
                                        leftIcon={
                                            <Pressable
                                                onPress={_ => {
                                                    fromDayRef.current.blur();
                                                    if (!disableArrivalForCheckedIn) {
                                                        openDateTimePicker(
                                                            'date',
                                                            STAY_FROM_FIELD,
                                                            checkin?.[STAY_FROM_FIELD]
                                                        );
                                                    }
                                                }}
                                                disabled={disableArrivalForCheckedIn}
                                                style={({pressed}) => ({
                                                    opacity: pressed ? 0.5 : 1,
                                                })}>
                                                <Icon
                                                    type="ionicon"
                                                    name={'calendar-outline'}
                                                    size={16}
                                                    color={
                                                        disableArrivalForCheckedIn
                                                            ? theme.colors.greyLight
                                                            : theme.colors.iconColor
                                                    }
                                                />
                                            </Pressable>
                                        }
                                        leftIconContainerStyle={global.inputIconContainer}
                                        ref={fromDayRef}
                                        disabled={disableArrivalForCheckedIn}
                                        onFocus={_ => {
                                            fromDayRef.current.blur();
                                            if (!disableArrivalForCheckedIn) {
                                                openDateTimePicker('date', STAY_FROM_FIELD, checkin?.[STAY_FROM_FIELD]);
                                            }
                                        }}
                                        showSoftInputOnFocus={false}
                                        selectTextOnFocus={false}
                                        value={getFormattedDateFromDateString(checkin?.[STAY_FROM_FIELD] ?? '')}
                                        inputContainerStyle={global.timeInputContainer}
                                        inputStyle={[
                                            global.timeInput,
                                            IS_WEB && !disableArrivalForCheckedIn && {cursor: 'pointer'},
                                        ]}
                                    />
                                </View>
                                <View style={global.timeInputWrapper}>
                                    <Input
                                        leftIcon={
                                            <Pressable
                                                onPress={_ => {
                                                    toDayRef.current.blur();
                                                    if (!disableArrivalForCheckedIn) {
                                                        openDateTimePicker(
                                                            'time',
                                                            TIME_STAY_FROM_FIELD,
                                                            checkin?.[TIME_STAY_FROM_FIELD]
                                                        );
                                                    }
                                                }}
                                                disabled={disableArrivalForCheckedIn}
                                                style={({pressed}) => ({
                                                    opacity: pressed ? 0.5 : 1,
                                                })}>
                                                <Icon
                                                    type="ionicon"
                                                    name={'time-outline'}
                                                    size={16}
                                                    color={
                                                        disableArrivalForCheckedIn
                                                            ? theme.colors.greyLight
                                                            : theme.colors.iconColor
                                                    }
                                                />
                                            </Pressable>
                                        }
                                        leftIconContainerStyle={global.inputIconContainer}
                                        ref={toDayRef}
                                        disabled={disableArrivalForCheckedIn}
                                        onFocus={_ => {
                                            toDayRef.current.blur();
                                            if (!disableArrivalForCheckedIn) {
                                                openDateTimePicker(
                                                    'time',
                                                    TIME_STAY_FROM_FIELD,
                                                    checkin?.[TIME_STAY_FROM_FIELD]
                                                );
                                            }
                                        }}
                                        showSoftInputOnFocus={false}
                                        selectTextOnFocus={false}
                                        value={checkin?.[TIME_STAY_FROM_FIELD] ?? ''}
                                        inputContainerStyle={global.timeInputContainer}
                                        inputStyle={[
                                            global.timeInput,
                                            IS_WEB && !disableArrivalForCheckedIn && {cursor: 'pointer'},
                                        ]}
                                    />
                                </View>
                            </View>
                            <View style={[global.timeBox]}>
                                <View style={global.timeBoxTitle}>
                                    <Icon
                                        type="ionicon"
                                        name="exit-outline"
                                        size={18}
                                        style={{marginRight: 5}}
                                        color={theme.colors.greyMedium}
                                    />
                                    <Text style={[global.textCaps, {fontSize: 10}]}>{t(DEPARTURE_LABEL)}</Text>
                                </View>

                                <View style={global.timeInputWrapper}>
                                    <Input
                                        leftIcon={
                                            <Pressable
                                                onPress={_ => {
                                                    fromTimeRef.current.blur();
                                                    openDateTimePicker(
                                                        'date',
                                                        FORESEEN_STAY_UNTIL_FIELD,
                                                        checkin?.[FORESEEN_STAY_UNTIL_FIELD]
                                                    );
                                                }}
                                                style={({pressed}) => ({
                                                    opacity: pressed ? 0.5 : 1,
                                                })}>
                                                <Icon type="ionicon" name={'calendar-outline'} size={16} />
                                            </Pressable>
                                        }
                                        leftIconContainerStyle={global.inputIconContainer}
                                        ref={fromTimeRef}
                                        onFocus={_ => {
                                            fromTimeRef.current.blur();
                                            openDateTimePicker(
                                                'date',
                                                FORESEEN_STAY_UNTIL_FIELD,
                                                checkin?.[FORESEEN_STAY_UNTIL_FIELD]
                                            );
                                        }}
                                        showSoftInputOnFocus={false}
                                        selectTextOnFocus={false}
                                        value={getFormattedDateFromDateString(
                                            checkin?.[FORESEEN_STAY_UNTIL_FIELD] ?? ''
                                        )}
                                        inputContainerStyle={global.timeInputContainer}
                                        inputStyle={[global.timeInput, IS_WEB && {cursor: 'pointer'}]}
                                    />
                                </View>

                                <View style={global.timeInputWrapper}>
                                    <Input
                                        leftIcon={
                                            <Pressable
                                                onPress={_ => {
                                                    toTimeRef.current.blur();
                                                    openDateTimePicker(
                                                        'time',
                                                        TIME_ESTIMATED_STAY_UNTIL_FIELD,
                                                        checkin?.[TIME_ESTIMATED_STAY_UNTIL_FIELD]
                                                    );
                                                }}
                                                disabled={disableArrivalForCheckedIn}
                                                style={({pressed}) => ({
                                                    opacity: pressed ? 0.5 : 1,
                                                })}>
                                                <Icon type="ionicon" name={'time-outline'} size={16} />
                                            </Pressable>
                                        }
                                        leftIconContainerStyle={global.inputIconContainer}
                                        ref={toTimeRef}
                                        onFocus={_ => {
                                            toTimeRef.current.blur();
                                            openDateTimePicker(
                                                'time',
                                                TIME_ESTIMATED_STAY_UNTIL_FIELD,
                                                checkin?.[TIME_ESTIMATED_STAY_UNTIL_FIELD]
                                            );
                                        }}
                                        showSoftInputOnFocus={false}
                                        selectTextOnFocus={false}
                                        value={checkin?.[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? ''}
                                        inputContainerStyle={global.timeInputContainer}
                                        inputStyle={[global.timeInput, IS_WEB && {cursor: 'pointer'}]}
                                    />
                                </View>
                            </View>
                        </View>
                    )}

                    {showCheckinNote && (
                        <Pressable style={styles.noteWrapper} onPress={openNoteEdit}>
                            <View style={{flex: 1, flexDirection: 'row', alignItems: 'flex-start'}}>
                                <Icon
                                    type="ionicon"
                                    name="create-outline"
                                    size={16}
                                    style={{marginRight: 8, top: 1}}
                                    color={theme.colors.textColorMedium}
                                />
                                <Text style={global.textMain}>
                                    {checkin?.[ADDITIONAL_FIELDS]?.[CHECKIN_NOTE_FIELD]}
                                </Text>
                            </View>
                            <Icon name="edit" size={16} style={{marginLeft: 20}} color={theme.colors.primary} />
                        </Pressable>
                    )}

                    {!isReservation && (
                        <View style={[global.cardFooter, {paddingLeft: 0}]}>
                            <Pressable
                                onPress={onShareGuestClick}
                                style={[styles.selfCheckinBtn, isMobileSmall && {maxWidth: 130}]}>
                                <Icon
                                    type="ionicon"
                                    name="share-social-outline"
                                    size={18}
                                    color={theme.colors.white}
                                    containerStyle={{marginRight: 10}}
                                />
                                <Text style={styles.selfCheckinBtnText}>{t(SELF_CHECKIN_BUTTON_LABEL)}</Text>
                            </Pressable>
                            {!isReservation && !showCheckinNote && (
                                <Pressable onPress={openNoteEdit} style={global.flexCenter}>
                                    <Icon
                                        type="ionicon"
                                        name="create-outline"
                                        size={18}
                                        color={theme.colors.primary}
                                        containerStyle={{marginRight: 5}}
                                    />
                                    <Text style={[global.link, {fontSize: 13}]}>{t(NOTE2)}</Text>
                                </Pressable>
                            )}
                            <Pressable onPress={onClickInvoice} style={global.flexCenter}>
                                <Icon
                                    type="ionicon"
                                    name="receipt-outline"
                                    size={16}
                                    color={theme.colors.primary}
                                    containerStyle={{marginRight: 6}}
                                />
                                <Text style={[global.link, {fontSize: 13}]}>{t(INVOICE)}</Text>
                            </Pressable>
                        </View>
                    )}
                </View>
            </View>
            <View style={[global.flexCenter, {marginBottom: 10, justifyContent: 'space-between'}]}>
                <Text style={[global.textCaps, {color: global.textColor, lineHeight: 26}]}>
                    {t(GUESTS)} ({numberOfGuests}/{maxGuestSize}):
                </Text>
                {numberOfGuests > 1 && (
                    <Dropdown
                        currentSortOption={sortGuestsBy}
                        onSelected={onSelectedSortOption}
                        dropdownOptions={dropdownSortOptions}
                    />
                )}
            </View>

            <DateTimePicker
                closed={closeDateTimePicker}
                setClosed={setCloseDateTimePicker}
                onChangeDate={onChangeDate}
                onChangeTime={onChangeTime}
                datePickerData={datePickerData}
            />
            {showSelectOwner && (
                <SelectOwnerDialog
                    isOpen={showSelectOwner}
                    setOpen={setShowSelectOwner}
                    onSelected={onSelectedOwner}
                    evaccounts={evaccounts}
                />
            )}
            {showSelectAccomodation && (
                <SelectAccomodationDialog
                    isOpen={showSelectAccomodation}
                    setOpen={setShowSelectAccomodation}
                    onSelected={onSelectAccomodation}
                    accomodations={accomodations}
                />
            )}
        </>
    );
};

export default CheckinDetailsHeader;
