import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ScrollView, Pressable, Platform} from 'react-native';
import {Text, Icon, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {PRIVACY_TITLE, TERMS_TITLE} from '../../constants/stringsAndFields';
import Modal from 'react-native-modal';

import {fetchPrivacyData, fetchTermsData} from '../../api/inCheckinRestService';
import {parseAndGenerateHtmlContent} from '../../utils/htmlParser';
import WebView from 'react-native-webview';
import useStyles from './HtmlDialog.styles';

// supported views
export const PRIVACY_VIEW = 'PRIVACY_VIEW';
export const TERMS_VIEW = 'TERMS_VIEW';

const isNativeWebView = Platform.OS !== 'web' ? true : false;

const HtmlDialog = ({isOpen, setOpen, view = TERMS_VIEW}) => {
    const {t} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const [content, setContent] = useState(null);

    let dialogTitle = null;
    let fetchMethod = null;
    if (view === TERMS_VIEW) {
        dialogTitle = t(TERMS_TITLE);
        fetchMethod = fetchTermsData;
    } else if (view === PRIVACY_VIEW) {
        dialogTitle = t(PRIVACY_TITLE);
        fetchMethod = fetchPrivacyData;
    }

    const close = () => {
        setOpen(false);
    };

    const initContent = async () => {
        try {
            const res = await fetchMethod(t);
            if (res) {
                const html = parseAndGenerateHtmlContent(t, res);
                return html;
            }
        } catch (e) {
            console.log(e);
        }
        return null;
    };

    useEffect(() => {
        if (isOpen) {
            initContent().then(html => setContent(html));
        }
    }, [isOpen]);

    const getContentView = htmlContent =>
        isNativeWebView ? (
            <WebView source={{html: htmlContent}} />
        ) : (
            <div dangerouslySetInnerHTML={{__html: htmlContent}} />
        );

    return (
        <Modal
            isVisible={isOpen}
            onBackdropPress={close}
            onBackButtonPress={close}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialogL}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{dialogTitle}</Text>
                    <Pressable onPress={close} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                {isNativeWebView ? (
                    <View style={[global.modalDialog, styles.modalDialog, styles.webView]}>
                        {content && getContentView(content)}
                    </View>
                ) : (
                    <ScrollView contentContainerStyle={global.modalContent}>
                        {content && getContentView(content)}
                    </ScrollView>
                )}
            </View>
        </Modal>
    );
};

export default HtmlDialog;
