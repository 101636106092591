import React, {useState, useRef} from 'react';
import {View, Text, TouchableOpacity, FlatList, LayoutAnimation, UIManager, Animated} from 'react-native';
import {Icon, useTheme} from 'react-native-elements';
import globalStyle from '../../../theme/globalStyle';
import useStyles from './Accordion.styles';

UIManager.setLayoutAnimationEnabledExperimental && UIManager.setLayoutAnimationEnabledExperimental(true);

const Accordion = ({data}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);

    const [expandedId, setExpandedId] = useState(null);
    const rotateValue = useRef(new Animated.Value(0)).current;

    const toggleAccordion = itemId => {
        LayoutAnimation.configureNext({
            ...LayoutAnimation.Presets.easeInEaseOut,
            duration: 500,
        });
        setExpandedId(itemId === expandedId ? null : itemId);

        const toValue = expandedId === itemId ? 0 : 1;
        Animated.timing(rotateValue, {
            toValue,
            duration: 100,
            useNativeDriver: true,
        }).start();
    };

    const renderAccordionItem = ({item}) => (
        <View style={styles.accordionItem}>
            <TouchableOpacity onPress={() => toggleAccordion(item.id)} style={styles.accordionHeader}>
                <Text style={styles.accordionHeaderText}>{item.title}</Text>
                <Animated.View
                    style={[
                        {
                            transform: [
                                {
                                    rotate: rotateValue.interpolate({
                                        inputRange: [0, 1],
                                        outputRange: ['0deg', '180deg'],
                                    }),
                                },
                            ],
                        },
                    ]}>
                    <Icon name={'keyboard-arrow-down'} size={20} color={theme.colors.primary} />
                </Animated.View>
            </TouchableOpacity>
            {expandedId === item.id && (
                <View style={[styles.accordionContent, {height: expandedId === item.id ? 'auto' : 0}]}>
                    <View>{item.content}</View>
                </View>
            )}
        </View>
    );

    return <FlatList data={data} renderItem={renderAccordionItem} keyExtractor={item => item.id.toString()} />;
};

export default Accordion;
