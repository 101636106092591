import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    modalDialog: {
        width: '100%',
        maxWidth: 800,
        maxHeight: '100%',
    },

    webView: {
        height: 400,
    },
}));

export default useStyles;
