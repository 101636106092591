// COMMIT THIS FILE ALWAYS WITH IS_PRODUCTION_MODE value set to true!!!
const IS_PRODUCTION_MODE = true;

// this are being used only by webpack dev proxy environment
const DEV_BACKEND = 'https://listria.duckdns.org';
const PRODUCTION_BACKEND = 'https://app.echeckin.hr';

exports.IS_PRODUCTION_MODE = IS_PRODUCTION_MODE;
exports.DEV_BACKEND = DEV_BACKEND;
exports.PRODUCTION_BACKEND = PRODUCTION_BACKEND;
