import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    formWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },

    inputContainer: {
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

export default useStyles;
