import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    selfCheckinBtn: {
        backgroundColor: theme.colors.primaryExtraLight,
        height: 26,
        paddingHorizontal: 10,
        minWidth: 140,
        borderRadius: 6,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: 'rgba(92, 78, 157, 0.2)',
    },

    selfCheckinBtnText: {
        color: theme.colors.primary,
        fontFamily: theme.fontFamilyMedium,
        fontSize: 13,
    },
}));

export default useStyles;
