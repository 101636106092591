import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    logoWrapper: {
        marginTop: 15,
        marginBottom: 30,
        alignItems: 'center',
    },

    logoWrapperMobile: {
        marginBottom: 20,
        alignItems: 'center',
    },

    logoPlaceholder: {
        backgroundColor: theme.colors.lightBg,
        borderRadius: 10,
        borderColor: theme.colors.greyExtraLight,
        borderWidth: 1,
        width: 100,
        height: 100,
        alignItems: 'center',
        justifyContent: 'center',
    },

    logoPlaceholderMobile: {
        width: 80,
        height: 80,
    },

    itemTitle: {
        fontFamily: theme.fontFamily,
        fontSize: 14,
        lineHeight: 30,
    },

    itemValue: {
        fontFamily: theme.fontFamilySemibold,
        fontSize: 14,
        lineHeight: 30,
    },

    invoiceDataWrapper: {
        flexDirection: 'row',
    },

    invoiceData: {
        alignItems: 'center',
    },
}));

export default useStyles;
