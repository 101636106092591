import isEqual from 'lodash/isEqual';
import React, {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable, Text, View, useWindowDimensions} from 'react-native';
import {Button, Icon, LinearProgress, ListItem, useTheme} from 'react-native-elements';
import {
    ADDITIONAL_FIELDS,
    CANCELED_STATUS,
    CHECKED_IN_STATUS,
    CHECKED_OUT_STATUS,
    CHECKIN_BUTTON,
    CHECKIN_ID,
    CHECKIN_TOURIST,
    CHECKOUT_BUTTON,
    CHECKOUT_TOURIST,
    FORESEEN_STAY_UNTIL_FIELD,
    GUEST_NOTE_FIELD,
    ID,
    PIN_ID,
    STAY_FROM_FIELD,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    UPDATED_STATUS,
    content,
    status,
    id,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import {getShortFormattedDateFromDateString} from '../../utils/dateHelper';
import {
    createGuestTemplate,
    getForCheckin,
    getForCheckout,
    getGuestStatusBorderColor,
    getGuestStatusLabel,
    getGuestStatusLabelColor,
    getGuestTitleWithAge,
    prepareGuestData,
    prepareGuestForEdit,
    showQRCode,
    syncTouristCheckoutStatus,
} from '../../utils/guestUtils';

const CalendarGuestItem = ({
    guest,
    guestActions,
    evAccount,
    facility,
    showErrors,
    setCheckinActionsDialog,
    openQRDetails,
    updateGuestId,
    navigation,
}) => {
    const [progressOngoing, setProgressOngoing] = useState(false);
    const {theme} = useTheme();
    const {t} = useTranslation();
    const global = globalStyle(theme);
    const {checkinGuest, checkoutGuest, cancelGuest, updateCheckoutGuest, updateGuestStatus} = guestActions;

    const width = useWindowDimensions().width;
    const isDesktop = width >= theme.desktopBP;

    const touristName = getGuestTitleWithAge(t, guest);
    const showGuestNote = guest?.[ADDITIONAL_FIELDS]?.[GUEST_NOTE_FIELD] ? true : false;

    const handleQRClick = useCallback(
        _ => {
            updateGuestId(guest[ID]);
            openQRDetails();
        },
        [guest[ID]]
    );

    const openDetailsScreen = useCallback(async (guest, facility, evAccount) => {
        if (guest) {
            const checkinId = guest?.[ADDITIONAL_FIELDS]?.[CHECKIN_ID];
            if (checkinId) {
                // Existing guest
                const guestData = prepareGuestForEdit(guest, facility, evAccount);
                if (guestData) {
                    navigation.navigate('Guest', {
                        guestId: guest[ID],
                        checkinId: checkinId,
                        isNewGuest: false,
                        guestData: guestData,
                        calendarInput: true,
                    });
                }
            } else {
                // New guest
                const guestTemplate = await createGuestTemplate(facility, evAccount, null);
                if (guestTemplate) {
                    navigation.navigate('Guest', {
                        checkinId: 0,
                        isNewGuest: true,
                        guestData: guestTemplate,
                        calendarInput: true,
                    });
                }
            }
        }
    }, []);

    const guestCheckinActions = useCallback(
        async (isCheckinAction = false, isCheckoutAction = false, isCancelAction = false, checkoutData = null) => {
            if (guest && evAccount) {
                setProgressOngoing(true);
                const guestData = prepareGuestData(guest);
                try {
                    // const checkinGuestData = prepareGuestCheckinData(guest, facility, evAccount);
                    // const checkin = await getZeroCheckin(checkinGuestData, false, null);
                    // await onCreateIfNotExistCheckin(checkin);
                    const pinId = evAccount[PIN_ID];
                    const guestContent = guestData?.[content];
                    let actionStatus = null;
                    if (guestContent && guestContent?.[ID]) {
                        if (isCheckinAction) {
                            await checkinGuest(pinId, guestContent);
                            actionStatus = CHECKED_IN_STATUS;
                        } else if (isCheckoutAction) {
                            const isAlreadyCheckedOut = await syncTouristCheckoutStatus(
                                pinId,
                                guestData,
                                updateGuestStatus,
                                true,
                                null
                            );
                            if (!isAlreadyCheckedOut) {
                                if (checkoutData) {
                                    const {forseenStayUntil, timeEstimatedUntil} = checkoutData;
                                    guestContent[FORESEEN_STAY_UNTIL_FIELD] = forseenStayUntil;
                                    guestContent[TIME_ESTIMATED_STAY_UNTIL_FIELD] = timeEstimatedUntil;
                                }
                                await checkoutGuest(pinId, guestContent);
                                const guestForUpdate = {
                                    [id]: guestContent?.[ID],
                                    [CHECKIN_ID]: guestContent[CHECKIN_ID],
                                    [status]: CHECKED_OUT_STATUS,
                                    [UPDATED_STATUS]: false,
                                    [content]: guestContent,
                                };
                                await updateCheckoutGuest(guestForUpdate);
                                actionStatus = null;
                            }
                        } else if (isCancelAction) {
                            await cancelGuest(pinId, guestContent);
                            actionStatus = CANCELED_STATUS;
                        }
                        if (actionStatus) {
                            try {
                                await updateGuestStatus(guestData, actionStatus, false);
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                } catch (error) {
                    await showErrors(error, guestData);
                }
            }
            setProgressOngoing(false);
        },
        [guest, evAccount, facility]
    );

    const checkinGuestAction = async () => {
        await guestCheckinActions(true, false, false, null);
    };

    const checkoutGuestAction = async checkoutData => {
        await guestCheckinActions(false, true, false, checkoutData);
    };

    const onCloseDialog = () => {
        setCheckinActionsDialog({
            open: false,
        });
    };

    const onPressCheckinButton = async _ => {
        setCheckinActionsDialog({
            selectedCheckinActions: checkinGuestAction,
            actionName: CHECKIN_TOURIST,
            open: true,
            guestName: touristName,
            onClose: onCloseDialog,
        });
    };

    const onPressCheckoutButton = async _ => {
        setCheckinActionsDialog({
            selectedCheckinActions: checkoutGuestAction,
            actionName: CHECKOUT_TOURIST,
            open: true,
            guestName: touristName,
            onClose: onCloseDialog,
            guest: guest,
        });
    };

    const onPressGuest = async _ => await openDetailsScreen(guest, facility, evAccount);

    return (
        <ListItem
            key={guest?.[ID]}
            onPress={onPressGuest}
            containerStyle={[global.listItem, {borderColor: getGuestStatusBorderColor(guest, theme)}]}
            style={[global.boxShadow, {marginBottom: 15, borderRadius: 4}]}
            underlayColor="white">
            <View style={{flex: 1}}>
                <View style={[isDesktop && {flexDirection: 'row', alignItems: 'center', minHeight: 33}, {flex: 1}]}>
                    {progressOngoing && (
                        <LinearProgress
                            color={theme.colors.primary}
                            variant="indeterminate"
                            style={{position: 'absolute', left: 0, bottom: -10}}
                        />
                    )}
                    <View
                        style={[
                            !isDesktop && {marginBottom: 15, paddingRight: 160},
                            isDesktop && {
                                width: '35%',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingRight: 30,
                            },
                        ]}>
                        <View style={[global.flexCenter, isDesktop && {maxWidth: '50%'}]}>
                            <Icon
                                type="ionicon"
                                name="person"
                                size={16}
                                style={{marginRight: 8}}
                                color={getGuestStatusBorderColor(guest, theme)}
                            />
                            <Text style={[global.textMain, {fontFamily: theme.fontFamilySemibold, fontSize: 13}]}>
                                {touristName}
                            </Text>
                        </View>
                        <View style={[global.flexCenter, !isDesktop && {position: 'absolute', top: 0, right: 0}]}>
                            <Text style={[global.checkinLabel, getGuestStatusLabelColor(guest, theme)]}>
                                {getGuestStatusLabel(guest, t)}
                            </Text>
                        </View>
                    </View>

                    <View
                        style={[
                            isDesktop && {
                                width: '20%',
                                alignItems: 'center',
                                paddingHorizontal: 20,
                            },
                            !isDesktop && {marginLeft: 0, alignSelf: 'flex-start'},
                        ]}>
                        <View style={[global.dateLabel]}>
                            <Icon
                                type="ionicon"
                                name="calendar-outline"
                                color={theme.colors.textColorMedium}
                                size={16}
                                style={{marginRight: 8}}
                            />
                            <Text style={[global.textMain]}>
                                {getShortFormattedDateFromDateString(guest?.[STAY_FROM_FIELD])}
                            </Text>
                            <Text
                                style={{
                                    color: theme.colors.greyLight,
                                    marginHorizontal: 6,
                                }}>
                                /
                            </Text>
                            <Text style={[global.textMain]}>
                                {getShortFormattedDateFromDateString(guest?.[FORESEEN_STAY_UNTIL_FIELD])}
                            </Text>
                        </View>
                    </View>

                    <View
                        style={[
                            isDesktop && {
                                width: '20%',
                                alignItems: 'center',
                                paddingHorizontal: 20,
                            },
                            !isDesktop && {position: 'absolute', bottom: 0, right: 0, zIndex: 1},
                        ]}>
                        {showQRCode(guest[status]) && (
                            <Pressable
                                onPress={handleQRClick}
                                style={({pressed}) => ({
                                    opacity: pressed ? 0.5 : 1,
                                })}>
                                <Icon type="ionicon" name={'qr-code-outline'} size={20} />
                            </Pressable>
                        )}
                    </View>

                    <View
                        style={[
                            {flexDirection: 'row', alignItems: 'center'},
                            isDesktop && {
                                width: '25%',
                                paddingHorizontal: 20,
                                justifyContent: 'center',
                            },
                        ]}>
                        {/* CHECKIN BTN*/}
                        {getForCheckin(guest[status], guest[UPDATED_STATUS]) && (
                            <View style={global.flexCenter}>
                                <Button
                                    title={`${t(CHECKIN_BUTTON)}`}
                                    key={CHECKIN_BUTTON}
                                    containerStyle={[
                                        global.outlineButtonContainer,
                                        {width: 100},
                                        isDesktop && {marginHorizontal: 5},
                                        !isDesktop && {marginRight: 10, marginTop: 15},
                                    ]}
                                    titleStyle={[global.outlineButtonTitle, {textTransform: 'none', fontSize: 12}]}
                                    buttonStyle={[global.outlineButton, {paddingHorizontal: 0, paddingVertical: 8}]}
                                    onPress={onPressCheckinButton}
                                />
                            </View>
                        )}

                        {/* CHECKOUT BTN*/}
                        {getForCheckout(guest[status]) && (
                            <View style={global.flexCenter}>
                                <Button
                                    title={`${t(CHECKOUT_BUTTON)}`}
                                    key={CHECKOUT_BUTTON}
                                    containerStyle={[
                                        global.Button.containerStyle,
                                        {width: 100},
                                        isDesktop && {marginHorizontal: 5},
                                        !isDesktop && {marginRight: 10, marginTop: 15},
                                    ]}
                                    titleStyle={[global.outlineButtonTitle, {textTransform: 'none', fontSize: 12}]}
                                    buttonStyle={[
                                        global.outlineButton,
                                        {backgroundColor: theme.colors.white, paddingHorizontal: 0, paddingVertical: 8},
                                    ]}
                                    onPress={onPressCheckoutButton}
                                />
                            </View>
                        )}
                    </View>
                </View>
                {showGuestNote && (
                    <View style={global.listNote}>
                        <Icon
                            name="edit"
                            size={12}
                            color={theme.colors.textColorLight}
                            containerStyle={{marginRight: 8, top: 1}}
                        />
                        <Text style={global.listNoteText}>{guest?.[ADDITIONAL_FIELDS]?.[GUEST_NOTE_FIELD] ?? ''}</Text>
                    </View>
                )}
            </View>
        </ListItem>
    );
};

function arePropsEqual(prevProps, nextProps) {
    return isEqual(prevProps.guest, nextProps.guest);
}

export default memo(CalendarGuestItem, arePropsEqual);
