import * as yup from 'yup';
import {
    FORESEEN_STAY_UNTIL_FIELD,
    STAY_FROM_FIELD,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    TIME_STAY_FROM_FIELD,
    ARRIVAL_DATE_LABEL_ERROR,
    DEPARTURE_DATE_LABEL_ERROR,
} from '../../constants/stringsAndFields';
import {getDateFromDateString} from '../../utils/dateHelper';

export const schema = t =>
    yup.object().shape(
        {
            [TIME_ESTIMATED_STAY_UNTIL_FIELD]: yup.string().required(),
            [TIME_STAY_FROM_FIELD]: yup.string().required(),
            [STAY_FROM_FIELD]: yup.string().test({
                name: 'dateBefore',
                exclusive: false,
                params: {},
                /* eslint-disable no-template-curly-in-string */
                message: t(ARRIVAL_DATE_LABEL_ERROR),
                test: function (value) {
                    const fromDate = getDateFromDateString(value);
                    const toDate = getDateFromDateString(this.parent[FORESEEN_STAY_UNTIL_FIELD]);
                    return fromDate <= toDate ? true : false;
                },
            }),
            [FORESEEN_STAY_UNTIL_FIELD]: yup.string().test({
                name: 'dateAfter',
                exclusive: false,
                params: {},
                message: t(DEPARTURE_DATE_LABEL_ERROR),
                test: function (value) {
                    const fromDate = getDateFromDateString(this.parent[STAY_FROM_FIELD]);
                    const toDate = getDateFromDateString(value);
                    return toDate >= fromDate ? true : false;
                },
            }),
        },
        [STAY_FROM_FIELD, FORESEEN_STAY_UNTIL_FIELD]
    );

export default schema;
