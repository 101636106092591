import {useCallback, useState} from 'react';
import {useBetween} from 'use-between';

const useDialog = () => {
    const [creditsDepletedOpen, setCreditsDepletedOpen] = useState(false);
    const openCreditsDepleted = useCallback(_ => setCreditsDepletedOpen(true), []);
    const closeCreditsDepleted = useCallback(_ => setCreditsDepletedOpen(false), []);

    const [useCreditsAfterExpireOpen, setUseCreditsAfterExpireOpen] = useState(false);
    const openCreditsAfterExpire = useCallback(_ => setUseCreditsAfterExpireOpen(true), []);
    const closeCreditsAfterExpire = useCallback(_ => setUseCreditsAfterExpireOpen(false), []);

    return {
        creditsDepletedOpen,
        openCreditsDepleted,
        closeCreditsDepleted,
        useCreditsAfterExpireOpen,
        openCreditsAfterExpire,
        closeCreditsAfterExpire,
    };
};

export const useSharedDialogs = () => useBetween(useDialog);
