import React, {useContext, useState, useEffect} from 'react';
import {ActivityIndicator, View} from 'react-native';
import {Button, LinearProgress, Text, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import Modal from 'react-native-modal';
import {
    ACCOMODATION_OBJECT,
    ACCOMODATION_UNIT,
    ADDITIONAL_FIELDS,
    CODE,
    EVISITOR,
    FACILITY_CODE,
    FACILITY_ERROR_DIALOG_REFRESH_QUESTION,
    FACILITY_ERROR_DIALOG_WAIT_REFRESH,
    FACILITY_FIELD,
    NAME,
    NO,
    PIN,
    PIN_ID,
    YES,
} from '../../constants/stringsAndFields';
import {useTranslation} from 'react-i18next';
import DataContext from '../../context/dataContext';
import SelectAccomodationDialog from './SelectAccomodationDialog';
import {sleep} from '../../utils/promiseHelpers';
import {deepCopy} from '../../utils/helpers';

const FacilityErrorMessageDialog = ({
    checkin,
    onUpdateCheckin,
    setCheckin,
    onUpdateAccomodation,
    isScreenFocused,
    useSharedErrors,
    closeErrorDialogs,
}) => {
    if (!isScreenFocused) return null;
    const {getEvisitorByPin, refetchEvisitor, evaccounts} = useContext(DataContext);
    const [openProgress, setOpenProgress] = useState(false);
    const [showSelectAccomodation, setShowSelectAccomodation] = useState(false);
    const [accomodations, setAccomodations] = useState([]);
    const {theme} = useTheme();
    const {t} = useTranslation();
    const global = globalStyle(theme);
    const {checkinErrors, isOpenFacilityErrorMessage} = useSharedErrors();

    const msg = checkinErrors ? `${checkinErrors?.type} ${checkinErrors?.guest} - ${checkinErrors?.description}` : '';
    const isOpen = isOpenFacilityErrorMessage ? true : false;

    const close = async () => {
        closeErrorDialogs();
    };

    const confirmRefreshEvisitor = async () => {
        setOpenProgress(true);
        const pin = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN];
        if (pin) {
            const evAccount = await getEvisitorByPin(pin);
            if (evAccount) {
                await refetchEvisitor(evAccount);
                setOpenProgress(false);
                await close();
                await sleep(200);
                setShowSelectAccomodation(true);
            }
            setOpenProgress(false);
            await close();
        } else {
            setOpenProgress(false);
            await close();
        }
    };

    const onSelectAccomodation = accomodation => {
        checkin[ADDITIONAL_FIELDS][FACILITY_FIELD] = {
            ...accomodation,
        };
        const updatedCheckin = deepCopy(checkin);
        onUpdateCheckin(updatedCheckin, true).then(_ => {
            setCheckin(updatedCheckin);
            onUpdateAccomodation(accomodation).catch(console.error);
        });
    };

    const extendFacilities = (evisitorPinId, selectedEvisitor = null) => {
        if (evisitorPinId && evaccounts) {
            const evisitor = selectedEvisitor
                ? selectedEvisitor
                : evaccounts?.find(acc => acc[PIN_ID] === evisitorPinId);
            if (evisitor) {
                const filteredFacilities = evisitor ? evisitor?.[ACCOMODATION_OBJECT] : [];
                const unitCodes = evisitor[ACCOMODATION_UNIT].map(unit => unit[CODE]);
                const activeFacilities = filteredFacilities.filter(facility =>
                    unitCodes.includes(facility[FACILITY_CODE])
                );
                if (activeFacilities && activeFacilities.length > 0) {
                    for (let facility of activeFacilities) {
                        const parentObject = evisitor[ACCOMODATION_UNIT].find(
                            unit => facility[FACILITY_CODE] === unit[CODE]
                        );
                        if (parentObject) {
                            facility[NAME] = parentObject[NAME];
                        }
                    }
                }
                return activeFacilities;
            }
        }
    };

    useEffect(() => {
        if (evaccounts && checkin) {
            const facilities = extendFacilities(checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID], null);
            facilities && setAccomodations(facilities);
        }
    }, [evaccounts, checkin]);

    if (showSelectAccomodation) {
        return (
            <SelectAccomodationDialog
                isOpen={showSelectAccomodation}
                setOpen={setShowSelectAccomodation}
                onSelected={onSelectAccomodation}
                accomodations={accomodations}
            />
        );
    }

    return (
        <Modal
            isVisible={isOpen}
            onBackdropPress={closeErrorDialogs}
            onBackButtonPress={closeErrorDialogs}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalContent}>
                    {openProgress && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
                    <Text style={[global.modalContentText, {paddingBottom: 16}]}>{msg}</Text>
                    {openProgress ? (
                        <>
                            <ActivityIndicator
                                color={theme.colors.primary}
                                style={[{marginTop: 8, marginBottom: 8}, {maxHeight: 8}]}
                            />
                            <Text style={global.modalContentText}>{t(FACILITY_ERROR_DIALOG_WAIT_REFRESH)}</Text>
                        </>
                    ) : (
                        <Text style={global.modalContentText}>{t(FACILITY_ERROR_DIALOG_REFRESH_QUESTION)}</Text>
                    )}
                </View>
                <View style={global.modalFooter}>
                    <Button
                        onPress={close}
                        title={t(NO)}
                        containerStyle={global.ModalButton.Negative.containerStyle}
                        buttonStyle={global.ModalButton.Negative.buttonStyle}
                        titleStyle={global.ModalButton.Negative.titleStyle}
                        disabledStyle={global.ModalButton.disabledStyle}
                        disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                    />
                    <Button
                        onPress={confirmRefreshEvisitor}
                        title={t(YES)}
                        containerStyle={global.ModalButton.Positive.containerStyle}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                        disabledStyle={global.ModalButton.disabledStyle}
                        disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default FacilityErrorMessageDialog;
