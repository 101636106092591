import {Svg, Path} from 'react-native-svg';

export const logoSvg = (
    <Svg width="134" height="24" viewBox="0 0 134 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M35.1713 20.5631C33.8489 20.5631 32.69 20.305 31.6945 19.7887C30.7139 19.2577 29.9487 18.5349 29.3989 17.6204C28.864 16.7059 28.5966 15.666 28.5966 14.5007C28.5966 13.3206 28.8566 12.2807 29.3766 11.3809C29.9115 10.4664 30.6396 9.751 31.5608 9.23473C32.4968 8.71846 33.5592 8.46033 34.7479 8.46033C35.9068 8.46033 36.9395 8.71109 37.8458 9.2126C38.7522 9.71412 39.4654 10.4221 39.9854 11.3367C40.5054 12.2512 40.7655 13.328 40.7655 14.567C40.7655 14.685 40.758 14.8178 40.7432 14.9653C40.7432 15.1128 40.7357 15.2529 40.7209 15.3857H30.803V13.5493H39.2499L38.1578 14.1245C38.1727 13.446 38.0315 12.8486 37.7344 12.3323C37.4372 11.8161 37.0286 11.4104 36.5086 11.1154C36.0034 10.8204 35.4165 10.6729 34.7479 10.6729C34.0644 10.6729 33.4626 10.8204 32.9426 11.1154C32.4374 11.4104 32.0362 11.8234 31.7391 12.3545C31.4568 12.8707 31.3156 13.4829 31.3156 14.1909V14.6334C31.3156 15.3414 31.4791 15.9683 31.8059 16.5141C32.1328 17.0599 32.5934 17.4803 33.1878 17.7753C33.7821 18.0703 34.4656 18.2178 35.2382 18.2178C35.9068 18.2178 36.5086 18.1145 37.0435 17.908C37.5784 17.7015 38.0538 17.377 38.4699 16.9345L39.9631 18.6382C39.4282 19.2577 38.7522 19.7371 37.935 20.0764C37.1326 20.4009 36.2114 20.5631 35.1713 20.5631Z"
            fill="#414141"
        />
        <Path
            d="M50.9301 20.6295C49.7414 20.6295 48.6345 20.4377 47.6093 20.0542C46.5989 19.656 45.7148 19.1028 44.9571 18.3948C44.2141 17.672 43.6347 16.8239 43.2186 15.8503C42.8026 14.8768 42.5946 13.8148 42.5946 12.6642C42.5946 11.5137 42.8026 10.4516 43.2186 9.47811C43.6347 8.50458 44.2216 7.6638 44.9793 6.95577C45.7371 6.233 46.6212 5.67986 47.6315 5.29634C48.6419 4.89808 49.7488 4.69895 50.9524 4.69895C52.2302 4.69895 53.3965 4.9202 54.4515 5.36272C55.5064 5.79048 56.3979 6.43213 57.126 7.28766L55.2538 9.0356C54.6892 8.43083 54.0577 7.98094 53.3594 7.68592C52.6611 7.37616 51.9033 7.22128 51.0861 7.22128C50.2689 7.22128 49.5185 7.35404 48.8351 7.61955C48.1664 7.88506 47.5795 8.2612 47.0744 8.74796C46.584 9.23473 46.1977 9.81 45.9154 10.4738C45.648 11.1375 45.5142 11.8677 45.5142 12.6642C45.5142 13.4608 45.648 14.1909 45.9154 14.8547C46.1977 15.5184 46.584 16.0937 47.0744 16.5805C47.5795 17.0673 48.1664 17.4434 48.8351 17.7089C49.5185 17.9744 50.2689 18.1072 51.0861 18.1072C51.9033 18.1072 52.6611 17.9597 53.3594 17.6646C54.0577 17.3549 54.6892 16.8902 55.2538 16.2707L57.126 18.0408C56.3979 18.8816 55.5064 19.5232 54.4515 19.9657C53.3965 20.4082 52.2227 20.6295 50.9301 20.6295Z"
            fill="#414141"
        />
        <Path
            d="M66.3878 8.46033C67.3387 8.46033 68.1856 8.64471 68.9285 9.01347C69.6863 9.38223 70.2806 9.95013 70.7115 10.7172C71.1424 11.4694 71.3578 12.443 71.3578 13.6378V20.4082H68.5719V13.9918C68.5719 12.9445 68.3193 12.1627 67.8142 11.6464C67.3238 11.1302 66.6329 10.872 65.7414 10.872C65.0877 10.872 64.5082 11.0048 64.003 11.2703C63.4978 11.5358 63.1041 11.9341 62.8218 12.4651C62.5543 12.9814 62.4206 13.6378 62.4206 14.4343V20.4082H59.6347V3.99092H62.4206V11.7792L61.8189 10.8057C62.2349 10.0534 62.8366 9.47811 63.6241 9.07985C64.4265 8.66683 65.3477 8.46033 66.3878 8.46033Z"
            fill="#414141"
        />
        <Path
            d="M80.5079 20.5631C79.1855 20.5631 78.0265 20.305 77.031 19.7887C76.0504 19.2577 75.2852 18.5349 74.7354 17.6204C74.2005 16.7059 73.9331 15.666 73.9331 14.5007C73.9331 13.3206 74.1931 12.2807 74.7131 11.3809C75.248 10.4664 75.9761 9.751 76.8973 9.23473C77.8334 8.71846 78.8957 8.46033 80.0844 8.46033C81.2433 8.46033 82.276 8.71109 83.1823 9.2126C84.0887 9.71412 84.8019 10.4221 85.3219 11.3367C85.842 12.2512 86.102 13.328 86.102 14.567C86.102 14.685 86.0946 14.8178 86.0797 14.9653C86.0797 15.1128 86.0723 15.2529 86.0574 15.3857H76.1395V13.5493H84.5864L83.4944 14.1245C83.5092 13.446 83.3681 12.8486 83.0709 12.3323C82.7737 11.8161 82.3651 11.4104 81.8451 11.1154C81.3399 10.8204 80.753 10.6729 80.0844 10.6729C79.4009 10.6729 78.7992 10.8204 78.2791 11.1154C77.7739 11.4104 77.3728 11.8234 77.0756 12.3545C76.7933 12.8707 76.6521 13.4829 76.6521 14.1909V14.6334C76.6521 15.3414 76.8156 15.9683 77.1425 16.5141C77.4693 17.0599 77.93 17.4803 78.5243 17.7753C79.1186 18.0703 79.8021 18.2178 80.5747 18.2178C81.2433 18.2178 81.8451 18.1145 82.38 17.908C82.9149 17.7015 83.3904 17.377 83.8064 16.9345L85.2996 18.6382C84.7647 19.2577 84.0887 19.7371 83.2715 20.0764C82.4691 20.4009 81.5479 20.5631 80.5079 20.5631Z"
            fill="#414141"
        />
        <Path
            d="M94.2384 20.5631C92.9904 20.5631 91.876 20.305 90.8953 19.7887C89.9296 19.2577 89.1718 18.5349 88.622 17.6204C88.0723 16.7059 87.7974 15.666 87.7974 14.5007C87.7974 13.3206 88.0723 12.2807 88.622 11.3809C89.1718 10.4664 89.9296 9.751 90.8953 9.23473C91.876 8.71846 92.9904 8.46033 94.2384 8.46033C95.3974 8.46033 96.4152 8.69634 97.2918 9.16835C98.1833 9.62562 98.8594 10.3041 99.32 11.2039L97.1804 12.443C96.8238 11.8824 96.3855 11.4694 95.8654 11.2039C95.3602 10.9384 94.8105 10.8057 94.2162 10.8057C93.5327 10.8057 92.9161 10.9532 92.3663 11.2482C91.8166 11.5432 91.3857 11.9709 91.0736 12.5315C90.7616 13.0772 90.6056 13.7336 90.6056 14.5007C90.6056 15.2677 90.7616 15.9315 91.0736 16.492C91.3857 17.0378 91.8166 17.4581 92.3663 17.7532C92.9161 18.0482 93.5327 18.1957 94.2162 18.1957C94.8105 18.1957 95.3602 18.0629 95.8654 17.7974C96.3855 17.5319 96.8238 17.1189 97.1804 16.5584L99.32 17.7974C98.8594 18.6824 98.1833 19.3683 97.2918 19.8551C96.4152 20.3271 95.3974 20.5631 94.2384 20.5631Z"
            fill="#414141"
        />
        <Path
            d="M103.893 17.7753L103.96 14.3015L110.178 8.59308H113.521L108.239 13.859L106.768 15.0759L103.893 17.7753ZM101.619 20.4082V3.99092H104.405V20.4082H101.619ZM110.601 20.4082L106.255 15.0759L108.016 12.8634L113.989 20.4082H110.601Z"
            fill="#414141"
        />
        <Path
            d="M115.788 20.4082V8.59308H118.574V20.4082H115.788ZM117.193 6.64601C116.672 6.64601 116.242 6.48376 115.9 6.15925C115.573 5.83474 115.41 5.44385 115.41 4.98658C115.41 4.51456 115.573 4.12368 115.9 3.81391C116.242 3.4894 116.672 3.32715 117.193 3.32715C117.713 3.32715 118.136 3.48203 118.463 3.79179C118.805 4.0868 118.976 4.46294 118.976 4.9202C118.976 5.40697 118.812 5.81998 118.485 6.15925C118.158 6.48376 117.727 6.64601 117.193 6.64601Z"
            fill="#414141"
        />
        <Path
            d="M128.984 8.46033C129.935 8.46033 130.782 8.64471 131.525 9.01347C132.282 9.38223 132.877 9.95013 133.308 10.7172C133.739 11.4694 133.954 12.443 133.954 13.6378V20.4082H131.168V13.9918C131.168 12.9445 130.916 12.1627 130.41 11.6464C129.92 11.1302 129.229 10.872 128.338 10.872C127.684 10.872 127.104 11.0048 126.599 11.2703C126.094 11.5358 125.7 11.9341 125.418 12.4651C125.151 12.9814 125.017 13.6378 125.017 14.4343V20.4082H122.231V8.59308H124.883V11.7792L124.415 10.8057C124.831 10.0534 125.433 9.47811 126.22 9.07985C127.023 8.66683 127.944 8.46033 128.984 8.46033Z"
            fill="#414141"
        />
        <Path
            d="M21.194 12.564C21.2019 12.4742 21.2074 12.4124 21.2093 12.2791V12L21.4884 11.9992H24.001C24.0006 12.302 23.9888 12.6067 23.9651 12.9128C23.8582 14.3323 23.5044 15.6828 22.9465 16.9216C22.2307 18.5158 21.1837 19.9085 19.9047 21.0272C18.5199 22.2407 16.8534 23.1407 15.0162 23.6163C13.7822 23.9373 12.4804 24.0647 11.1513 23.9689C9.68612 23.8664 8.29348 23.5009 7.02029 22.9193C5.44465 22.2016 4.06785 21.1596 2.96073 19.8899C1.7731 18.5303 0.887182 16.9004 0.407402 15.1046C0.0626038 13.8201 -0.0720473 12.4608 0.036959 11.0725C0.142251 9.69394 0.48048 8.38054 1.01236 7.17159C1.33386 6.43848 1.72512 5.74725 2.17663 5.10485L2.17772 5.10634C4.34865 2.01812 7.93891 0 12.0005 0C13.6911 0 15.3001 0.349676 16.759 0.980623C17.6151 1.34964 18.4147 1.81353 19.147 2.3573L17.1454 4.35912C16.0565 3.62474 14.7926 3.11282 13.4087 2.89867L13.4088 2.89754C12.9497 2.82707 12.4794 2.79052 12.0005 2.79052C8.22669 2.79052 4.9831 5.06075 3.56084 8.31004C3.24996 9.02258 3.0249 9.78646 2.90043 10.5909C2.86442 10.8236 2.83746 11.0556 2.81929 11.2866C2.80129 11.5218 2.79213 11.7594 2.79213 11.9992C2.79213 12.8206 2.89965 13.6168 3.10139 14.3746C3.80371 17.0002 5.64996 19.2193 8.15463 20.3688C8.91154 20.7161 9.72858 20.9658 10.5924 21.0995C10.8508 21.1395 11.1083 21.1683 11.3646 21.1863C11.5747 21.2006 11.7868 21.2079 12.0005 21.2079C12.7895 21.2079 13.5554 21.1087 14.2862 20.922C16.9719 20.2327 19.2431 18.3489 20.3973 15.7849C20.7304 15.0449 20.9705 14.2483 21.1006 13.4073C21.1372 13.1708 21.1644 12.9349 21.1826 12.7002C21.1868 12.6448 21.1906 12.6019 21.194 12.564Z"
            fill="#5D4D9D"
        />
        <Path
            d="M22.8398 2.61607L24.8131 4.5894L11.9865 17.416L6.55987 11.9894L8.53319 10.0161L11.9865 13.4694L22.8398 2.61607Z"
            fill="#5D4D9D"
        />
    </Svg>
);
