import { useState, useEffect } from "react";

export const useWindowDimensions = () => {
    const isMobileSized = (window.innerWidth < 700) ? true : false;
    const [dimensions, setDimensions] = useState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight, isMobileSized: isMobileSized });

    useEffect(() => {
        const handleDimensionsChange = () => {
            const isMobileSized = (window.innerWidth < 700) ? true : false;
            setDimensions({ windowWidth: window.innerWidth, windowHeight: window.innerHeight, isMobileSized: isMobileSized });
        }

        window.addEventListener("resize", handleDimensionsChange);
        return () => {
            window.removeEventListener("resize", handleDimensionsChange);
        };
    });

    return dimensions;
}
export default useWindowDimensions;