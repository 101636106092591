import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {Button, Text, useTheme} from 'react-native-elements';
import {TITLE_NUMBER_OF_GUESTS} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';

const MIN_GUEST_NUMBER = 1;
const MAX_GUEST_NUMBER = 250;

const NumberOfGuestsIncrementor = ({isOpenShare, checkinMaxGuestNumber, guestNumber, setGuestNumber}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const steps = [1, 10, 50];

    const decrementGuestNumber = _ => {
        if (guestNumber !== MIN_GUEST_NUMBER) {
            if (guestNumber <= 20) {
                setGuestNumber(guestNumber - steps[0]);
            } else if (guestNumber <= 50) {
                setGuestNumber(guestNumber - steps[1]);
            } else if (guestNumber <= 250) {
                setGuestNumber(guestNumber - steps[2]);
            }
        }
    };

    const incrementGuestNumber = _ => {
        if (guestNumber < 20) {
            setGuestNumber(guestNumber + steps[0]);
        } else if (guestNumber < 50) {
            setGuestNumber(guestNumber + steps[1]);
        } else {
            const bigStep = steps[2];
            if (guestNumber + bigStep >= MAX_GUEST_NUMBER) {
                setGuestNumber(MAX_GUEST_NUMBER);
            } else {
                setGuestNumber(guestNumber + bigStep);
            }
        }
    };

    useEffect(() => {
        setGuestNumber(checkinMaxGuestNumber);
    }, [checkinMaxGuestNumber, isOpenShare]);

    return (
        <>
            <View style={[global.flexCenter, {marginBottom: 15}]}>
                <Text style={[global.textCaps, {marginRight: 10}]}>{t(TITLE_NUMBER_OF_GUESTS)}:</Text>
                <Button
                    title={'-'}
                    buttonStyle={[
                        global.outlineButton,
                        {
                            width: 35,
                            minWidth: 35,
                            height: 35,
                            paddingHorizontal: 0,
                            paddingVertical: 0,
                            borderRadius: 10,
                        },
                    ]}
                    titleStyle={[global.outlineButtonTitle, {fontSize: 16}]}
                    containerStyle={[global.Button.containerStyle, {width: 35, borderRadius: 10}]}
                    disabledStyle={[global.Button.disabledStyle, {backgroundColor: theme.colors.lightBg}]}
                    disabledTitleStyle={global.Button.disabledTitleStyle}
                    onPress={decrementGuestNumber}
                    disabled={guestNumber < 2}
                />
                <Text style={[global.textMain, {fontSize: 16, marginHorizontal: 15}]}>{guestNumber}</Text>
                <Button
                    title={'+'}
                    buttonStyle={[
                        global.outlineButton,
                        {
                            width: 35,
                            minWidth: 35,
                            height: 35,
                            paddingHorizontal: 0,
                            paddingVertical: 0,
                            borderRadius: 10,
                        },
                    ]}
                    titleStyle={[global.outlineButtonTitle, {fontSize: 16}]}
                    containerStyle={[global.Button.containerStyle, {width: 35, borderRadius: 10}]}
                    onPress={incrementGuestNumber}
                />
            </View>
        </>
    );
};

export default NumberOfGuestsIncrementor;
