// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/montserrat/Montserrat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/montserrat/Montserrat-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/fonts/montserrat/Montserrat-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    src: local('Montserrat-Regular'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}
@font-face {
    font-family: 'Montserrat-Medium';
    font-weight: 500;
    src: local('Montserrat-Medium'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}
@font-face {
    font-family: 'Montserrat-SemiBold';
    font-weight: 600;
    src: local('Montserrat-SemiBold'), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}

html,
body {
    margin: 0;
    height: 100%;
    overflow: hidden;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    height: 100%;
}

input:focus,
textarea:focus {
    outline: none;
}

.MuiFormControl-root.MuiTextField-root {
    visibility: hidden;
    height: 0;
}
`, "",{"version":3,"sources":["webpack://./src/web/index.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,gBAAgB;IAChB,4FAA8G;AAClH;AACA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,2FAA4G;AAChH;AACA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,6FAAgH;AACpH;;AAEA;;IAEI,SAAS;IACT,YAAY;IACZ,gBAAgB;IAChB,oGAAoG;IACpG,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,SAAS;AACb","sourcesContent":["@font-face {\n    font-family: 'Montserrat-Regular';\n    font-weight: 400;\n    src: local('Montserrat-Regular'), url(../../assets/fonts/montserrat/Montserrat-Regular.ttf) format('truetype');\n}\n@font-face {\n    font-family: 'Montserrat-Medium';\n    font-weight: 500;\n    src: local('Montserrat-Medium'), url(../../assets/fonts/montserrat/Montserrat-Medium.ttf) format('truetype');\n}\n@font-face {\n    font-family: 'Montserrat-SemiBold';\n    font-weight: 600;\n    src: local('Montserrat-SemiBold'), url(../../assets/fonts/montserrat/Montserrat-SemiBold.ttf) format('truetype');\n}\n\nhtml,\nbody {\n    margin: 0;\n    height: 100%;\n    overflow: hidden;\n    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\n#root {\n    display: flex;\n    height: 100%;\n}\n\ninput:focus,\ntextarea:focus {\n    outline: none;\n}\n\n.MuiFormControl-root.MuiTextField-root {\n    visibility: hidden;\n    height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
