import {
    ADDITIONAL_FIELDS,
    CHECKIN_ID,
    CREATED_AT,
    EVISITOR_OIB,
    INVOICE_NUMBER,
    INVOICE_NUMBER_IDX,
    PIN,
    UPDATED_AT,
    USER_ID,
    USER_ID_IDX,
    content,
    id,
} from '../constants/stringsAndFields';
import {isNotEmpty} from '../utils/arrayHelpers';
import {checkExcludedOibs} from '../utils/helpers';
import {getInvoicesYear, getUserId, getUserOwnerOib} from '../utils/userUtils';
import {CHECKINS_TABLE} from './checkin';
import {
    checkRowDataExists,
    checkTableExists,
    deleteData,
    insertData,
    selectData,
    selectDataFromYear,
    selectDataMultiFilter,
    selectDataNotEqualFromYear,
    updateData,
} from './common';
import {runSqlCmd} from './sqlOperations';

export const INVOICE_TABLE = 'invoice';
const keys = [
    id,
    INVOICE_NUMBER,
    CHECKIN_ID,
    USER_ID,
    EVISITOR_OIB,
    content,
    ADDITIONAL_FIELDS,
    CREATED_AT,
    UPDATED_AT,
];

export const createInvoiceTable = async tx => {
    await runSqlCmd(tx, `DROP TABLE IF EXISTS ${INVOICE_TABLE};`);
    await runSqlCmd(tx, `DROP INDEX IF EXISTS ${USER_ID_IDX}`);
    await runSqlCmd(
        tx,
        `CREATE TABLE IF NOT EXISTS ${INVOICE_TABLE}(
      ${id} TEXT PRIMARY KEY NOT NULL,
      ${INVOICE_NUMBER} TEXT,
      ${CHECKIN_ID} TEXT,
      ${USER_ID} TEXT,
      ${EVISITOR_OIB} TEXT,
      ${content} TEXT,
      ${ADDITIONAL_FIELDS} TEXT,
      ${CREATED_AT} TEXT,
      ${UPDATED_AT} TEXT,
      FOREIGN KEY(${CHECKIN_ID}) REFERENCES ${CHECKINS_TABLE}(${id}));`
    );
    await runSqlCmd(tx, `CREATE INDEX ${USER_ID_IDX} ON ${INVOICE_TABLE} (${USER_ID});`);
    await runSqlCmd(tx, `CREATE INDEX ${INVOICE_NUMBER_IDX} ON ${INVOICE_TABLE} (${INVOICE_NUMBER});`);
};

export const insertInvoice = async (tx, invoice) => {
    const userId = await getUserId();
    await insertData(tx, INVOICE_TABLE, keys, {...invoice, [USER_ID]: userId});
};

export const deleteAllInvoices = async tx => {
    await deleteData(tx, INVOICE_TABLE);
};

export const deleteInvoice = async (tx, invoice) => {
    const invoiceId = invoice?.[id];
    if (invoiceId) {
        await deleteData(tx, INVOICE_TABLE, invoiceId);
    }
};

export const updateInvoice = async (tx, invoice) => {
    await updateData(tx, INVOICE_TABLE, invoice, id);
};

export const getInvoices = async tx => {
    const userId = await getUserId();
    const year = await getInvoicesYear();
    const invoices = await selectDataFromYear(tx, INVOICE_TABLE, keys, USER_ID, userId, CREATED_AT, year);
    if (invoices) {
        try {
            const deserializedInvoices = invoices.map(invoice => {
                invoice[content] = JSON.parse(invoice[content]);
                invoice[ADDITIONAL_FIELDS] = JSON.parse(invoice[ADDITIONAL_FIELDS]);
                return invoice;
            });
            return deserializedInvoices;
        } catch (e) {
            console.log(e);
        }
    }
    return [];
};

export const getInvoice = async (tx, invoiceId) => {
    const userId = await getUserId();
    const invoices = await selectDataMultiFilter(tx, INVOICE_TABLE, keys, USER_ID, userId, id, invoiceId);
    if (invoices && isNotEmpty(invoices)) {
        try {
            const deserializedInvoices = invoices.map(invoice => {
                invoice[content] = JSON.parse(invoice[content]);
                invoice[ADDITIONAL_FIELDS] = JSON.parse(invoice[ADDITIONAL_FIELDS]);
                return invoice;
            });
            return deserializedInvoices[0];
        } catch (e) {
            console.log(e);
        }
    }
    return null;
};

export const getInvoiceByInvoiceNumber = async (tx, invoiceNumber) => {
    const invoices = await selectData(tx, INVOICE_TABLE, keys, INVOICE_NUMBER, invoiceNumber);
    if (invoices) {
        try {
            const deserializedInvoices = invoices.map(invoice => {
                invoice[content] = JSON.parse(invoice[content]);
                invoice[ADDITIONAL_FIELDS] = JSON.parse(invoice[ADDITIONAL_FIELDS]);
                return invoice;
            });
            return deserializedInvoices[0];
        } catch (e) {
            console.log(e);
        }
    }
    return null;
};

export const getInvoiceByCheckin = async (tx, checkin) => {
    const invoices = await selectData(tx, INVOICE_TABLE, keys, CHECKIN_ID, checkin[id]);
    if (invoices) {
        try {
            const deserializedInvoices = invoices.map(invoice => {
                invoice[content] = JSON.parse(invoice[content]);
                invoice[ADDITIONAL_FIELDS] = JSON.parse(invoice[ADDITIONAL_FIELDS]);
                return invoice;
            });
            return deserializedInvoices[0];
        } catch (e) {
            console.log(e);
        }
    }
    return null;
};

export const getInvoiceExistenceByCheckin = async (tx, checkin) => {
    try {
        const filter = `${CHECKIN_ID} = '${checkin[id]}'`;
        const invoiceExistence = await checkRowDataExists(tx, INVOICE_TABLE, filter);
        return invoiceExistence ? true : false;
    } catch (e) {
        console.log(e);
    }
    return false;
};

export const getInvoicesByCheckin = async (tx, checkin) => {
    const invoices = await selectData(tx, INVOICE_TABLE, keys, CHECKIN_ID, checkin[id]);
    if (invoices) {
        try {
            const deserializedInvoices = invoices.map(invoice => {
                invoice[content] = JSON.parse(invoice[content]);
                invoice[ADDITIONAL_FIELDS] = JSON.parse(invoice[ADDITIONAL_FIELDS]);
                return invoice;
            });
            return deserializedInvoices;
        } catch (e) {
            console.log(e);
        }
    }
    return null;
};

export const getInvoicesByEvAccount = async (tx, evAccount, evaccounts, countOnly = false) => {
    let invoices = null;
    const year = await getInvoicesYear();
    // this is for other OIBs
    const excludedOibs = await checkExcludedOibs(evAccount, evaccounts);
    if (excludedOibs) {
        invoices = await selectDataNotEqualFromYear(
            tx,
            INVOICE_TABLE,
            keys,
            EVISITOR_OIB,
            excludedOibs,
            CREATED_AT,
            year
        );
    } else {
        invoices = await selectDataFromYear(tx, INVOICE_TABLE, keys, EVISITOR_OIB, evAccount[PIN], CREATED_AT, year);
    }
    if (countOnly) {
        return invoices.length;
    }
    if (invoices) {
        try {
            const deserializedInvoices = invoices.map(invoice => {
                invoice[content] = JSON.parse(invoice[content]);
                invoice[ADDITIONAL_FIELDS] = JSON.parse(invoice[ADDITIONAL_FIELDS]);
                return invoice;
            });
            return deserializedInvoices;
        } catch (e) {
            console.log(e);
        }
    }
    return [];
};

export const getLastInvoiceByEvAccount = async (tx, evAccount) => {
    const invoices = await selectData(tx, INVOICE_TABLE, keys, EVISITOR_OIB, evAccount[PIN], CREATED_AT);
    if (invoices) {
        try {
            const lastInvoice = invoices[0];
            if (lastInvoice) {
                const deserializedInvoice = {
                    ...lastInvoice,
                    [content]: JSON.parse(lastInvoice[content]),
                    [ADDITIONAL_FIELDS]: JSON.parse(lastInvoice[ADDITIONAL_FIELDS]),
                };
                return deserializedInvoice;
            }
        } catch (e) {
            console.log(e);
        }
    }
    return null;
};

export const checkInvoiceTable = async tx => {
    return await checkTableExists(tx, INVOICE_TABLE);
};

/*
id: uuid.UUID = db.Column(db.UUID, default=uuid.uuid4, primary_key=True)
# One checkin to one user tokens relationship
user_id: uuid.UUID = db.Column(db.UUID, db.ForeignKey('users.id'), nullable=False)
checkin_id: uuid.UUID = db.Column(db.UUID, db.ForeignKey('checkin.id'), nullable=False)
content: Dict = db.Column(JSONB, default={})
created_at: datetime.datetime = db.Column(db.DateTime, default=datetime.datetime.utcnow)
updated_at: datetime.datetime = db.Column(db.DateTime, onupdate=datetime.datetime.utcnow)

[CHECKIN_ID]: checkin[id],
[id]: guestId,
[content]:
*/
