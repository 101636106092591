import AsyncStorage from '@react-native-async-storage/async-storage';
import LZString from 'lz-string';
import {fetchCredits, getUser, updateUser} from '../api/inCheckinRestService';
import {JWT_APP_TOKEN, refreshAuthLogic} from '../api/restClient';
import {
    DEFAULT_CHECKOUT_TIME,
    DEFAULT_NUMBER_OF_NIGHTS,
    EV_ACCOUNTS_DATA,
    STORAGE_APP_ACCESS_TOKEN,
    STORAGE_BIRTHDAY_NOTIF,
    STORAGE_BIRTHDAY_NOTIF_TIME,
    STORAGE_CHECKINS_YEAR,
    STORAGE_CHOSEN_EV_ACCOUNT,
    STORAGE_CHOSEN_EV_FACILITY,
    STORAGE_CHOSEN_INVOICE_OWNER,
    STORAGE_COMM_KEY,
    STORAGE_CREDITS,
    STORAGE_CURRENT_CHECKIN_TIME,
    STORAGE_CURRENT_CHECKIN_TIME_ENABLED,
    STORAGE_DEBUG,
    STORAGE_EVACCOUNT_FACILITY_FETCHED,
    STORAGE_INVOICES_YEAR,
    STORAGE_INVOICE_FOOTER,
    STORAGE_INVOICE_LAST_SETTINGS,
    STORAGE_INVOICE_OPERATOR,
    STORAGE_LANGUAGE,
    STORAGE_LOGO_INVOICE,
    STORAGE_PASSWORD,
    STORAGE_RECEPCIJA_GROUP,
    STORAGE_REFRESH_TOKEN,
    STORAGE_SETTINGS_DELETE_GUESTS_AUTO,
    STORAGE_SORTING_SETTINGS,
    STORAGE_USER_ACCESS_TOKEN,
    STORAGE_USER_DATA,
    STORAGE_USER_EMAIL,
    STORAGE_USER_ID,
    STORAGE_USER_ORDINAL,
    STORAGE_USE_USER_DATA_FOR_INVOICE,
    THEME,
    USER_DATA_FORM_ENTITY_FLAG,
    USER_DATA_FORM_OIB,
    USER_DATA_INV_FOOTER_DATA,
    USER_DATA_INV_OPERATOR,
    USER_DATA_INV_OPERATOR_ID,
    USER_DATA_SOJOURN_TAX,
    USER_INFO,
    settings,
} from '../constants/stringsAndFields';
import {deserializeData, getBooleanTrueDefault, isStringNotEmpty, serializeData} from './helpers';
import {verifyJwt} from './jwt';
import {isOnline} from './network';
import {cancelAllNotifications} from './notifications';
import {invoiceFormFields} from '../components/invoices/EditUserDataDialog';
import {getCurrentYear} from './dateHelper';

export const isLoggedIn = async (autoRefresh = true, clearData = true, setLoggedIn = null, setReauthOngoing = null) => {
    const userToken = await AsyncStorage.getItem(STORAGE_USER_ACCESS_TOKEN);
    if (userToken) {
        const isTokenOk = verifyJwt(userToken);
        if (!isTokenOk && autoRefresh) {
            try {
                console.log('Token refresh');
                setReauthOngoing(true);
                await refreshAuthLogic();
            } catch (e) {
                console.log(e);
            } finally {
                setReauthOngoing(false);
            }
            const updatedUserToken = await AsyncStorage.getItem(STORAGE_USER_ACCESS_TOKEN);
            const isRefreshedTokenOk = verifyJwt(updatedUserToken);
            if (!isRefreshedTokenOk) {
                clearData && (await clearUserData());
                setLoggedIn && setLoggedIn(false);
                return false;
            } else {
                setLoggedIn && setLoggedIn(true);
                return true;
            }
        } else if (!isTokenOk) {
            clearData && (await clearUserData());
            setLoggedIn && setLoggedIn(isTokenOk);
        }
        setLoggedIn(isTokenOk);
        return isTokenOk;
    }
};

export const checkAppToken = async () => {
    const appToken = await AsyncStorage.getItem(STORAGE_APP_ACCESS_TOKEN);
    if (appToken) {
        const isTokenOk = verifyJwt(appToken);
        if (!isTokenOk) await generateAndSaveAppToken();
    } else {
        await generateAndSaveAppToken();
    }
};

export const generateAndSaveAppToken = async () => {
    //await AsyncStorage.setItem(STORAGE_APP_ACCESS_TOKEN, generateAppToken(ICI_NAME, JWT_SECRET));
    await AsyncStorage.setItem(STORAGE_APP_ACCESS_TOKEN, JWT_APP_TOKEN);
};

export const getAppAccessToken = async () => {
    return await AsyncStorage.getItem(STORAGE_APP_ACCESS_TOKEN);
};

export const getUserAccessToken = async () => {
    return await AsyncStorage.getItem(STORAGE_USER_ACCESS_TOKEN);
};

export const getRefreshToken = async () => {
    return await AsyncStorage.getItem(STORAGE_REFRESH_TOKEN);
};

export const getUserEmail = async () => {
    return await AsyncStorage.getItem(STORAGE_USER_EMAIL);
};

export const getUserId = async () => {
    return await AsyncStorage.getItem(STORAGE_USER_ID);
};

export const setUserOrdinal = async ordinal => {
    await AsyncStorage.setItem(STORAGE_USER_ORDINAL, ordinal?.toString() ?? '');
};

export const getUserOrdinal = async () => {
    return await AsyncStorage.getItem(STORAGE_USER_ORDINAL);
};

export const getOrRefreshUserOrdinal = async () => {
    try {
        const storedOrdinal = await getUserOrdinal();
        if (!storedOrdinal) {
            const userRes = await getUser(true);
            if (userRes) {
                await setUserOrdinal(userRes?.ordinal);
            }
        }
        return await getUserOrdinal();
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const getUserData = async () => {
    const data = await AsyncStorage.getItem(STORAGE_USER_DATA);
    return data ? deserializeData(data) : null;
};

export const setPassword = async password => {
    return await AsyncStorage.setItem(STORAGE_PASSWORD, password);
};

export const getPassword = async () => {
    return await AsyncStorage.getItem(STORAGE_PASSWORD);
};

export const setSettingsDeleteGuestsAuto = async deleteGuests => {
    return await AsyncStorage.setItem(STORAGE_SETTINGS_DELETE_GUESTS_AUTO, String(deleteGuests));
};

export const getSettingsDeleteGuestsAuto = async () => {
    const val = await AsyncStorage.getItem(STORAGE_SETTINGS_DELETE_GUESTS_AUTO);
    return val === 'true' ? true : false;
};

export const getCredits = async () => {
    try {
        const credits = await AsyncStorage.getItem(STORAGE_CREDITS);
        return JSON.parse(credits);
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const setCredits = async credits => {
    try {
        if (credits) {
            return await AsyncStorage.setItem(STORAGE_CREDITS, JSON.stringify(credits));
        }
    } catch (e) {
        console.log(e);
    }
};

export const getUserTheme = async () => {
    const data = await AsyncStorage.getItem(STORAGE_USER_DATA);
    if (data) {
        const userSettings = deserializeData(data);
        const userTheme = userSettings?.[STORAGE_USER_DATA]?.[THEME];
        return userTheme ? userTheme : null;
    }
    return null;
};

export const setupUserLang = async () => {
    const storedLang = await AsyncStorage.getItem(STORAGE_LANGUAGE);
    return storedLang ? storedLang : null;
};

// format: 14:30
export const getUserDefaultCheckoutTime = async () => {
    const data = await AsyncStorage.getItem(STORAGE_USER_DATA);
    if (data) {
        const userSettings = deserializeData(data);
        const checkoutTime = userSettings?.[STORAGE_USER_DATA]?.[DEFAULT_CHECKOUT_TIME];
        return checkoutTime ? checkoutTime : null;
    }
    return null;
};

export const getUserDefaultNumberOfNights = async () => {
    const data = await AsyncStorage.getItem(STORAGE_USER_DATA);
    if (data) {
        const userSettings = deserializeData(data);
        const numberOfNights = userSettings?.[STORAGE_USER_DATA]?.[DEFAULT_NUMBER_OF_NIGHTS];
        return numberOfNights ? Number(numberOfNights) : null;
    }
    return null;
};

export const getUserInfo = async () => {
    const data = await AsyncStorage.getItem(STORAGE_USER_DATA);
    if (data) {
        const userSettings = deserializeData(data);
        const userInfo = userSettings?.[STORAGE_USER_DATA]?.[USER_INFO];
        return userInfo ? userInfo : null;
    }
    return null;
};

export const getUserOwnerOib = async () => {
    const storedUserInfo = await getUserInfo();
    if (storedUserInfo) {
        const oib = storedUserInfo?.[USER_DATA_FORM_OIB];
        return isStringNotEmpty(oib) ? oib : null;
    }
    return null;
};

const createSettings = (key, data) => {
    return {
        [STORAGE_USER_DATA]: {
            [key]: data,
        },
    };
};

const mergeSettingsData = (mergeSettings, key, data) => {
    if (!mergeSettings.hasOwnProperty(STORAGE_USER_DATA)) {
        mergeSettings[STORAGE_USER_DATA] = {};
    }
    mergeSettings[STORAGE_USER_DATA][key] = data;
    return mergeSettings;
};

export const setUserSettings = async (key, data) => {
    try {
        const userData = await AsyncStorage.getItem(STORAGE_USER_DATA);
        const updatedSettings = userData
            ? mergeSettingsData(deserializeData(userData), key, data)
            : createSettings(key, data);
        await AsyncStorage.setItem(STORAGE_USER_DATA, serializeData(updatedSettings));
        const online = await isOnline();
        if (online) {
            // remote merge best effort
            await updateRemoteUser(key, data);
        }
    } catch (e) {
        console.log(e);
    }
};

export const updateRemoteUser = async (key, data) => {
    try {
        const updatedSettings = createSettings(key, data);
        await updateUser({[settings]: updatedSettings});
    } catch (e) {
        console.log(e);
    }
};

export const saveInvoiceLogo = async logoData => {
    try {
        return await AsyncStorage.setItem(STORAGE_LOGO_INVOICE, String(LZString.compressToUTF16(logoData)));
    } catch (e) {
        console.log(e);
    }
};

export const getInvoiceLogo = async _ => {
    const logo = await AsyncStorage.getItem(STORAGE_LOGO_INVOICE);
    try {
        return logo ? LZString.decompressFromUTF16(logo) : null;
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const removeInvoiceLogo = async _ => {
    await AsyncStorage.removeItem(STORAGE_LOGO_INVOICE);
};

export const getDebugLog = async () => {
    try {
        const data = await AsyncStorage.getItem(STORAGE_DEBUG);
        return data;
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const setDebugLog = async data => {
    try {
        if (data) {
            return await AsyncStorage.setItem(STORAGE_DEBUG, data);
        }
    } catch (e) {
        console.log(e);
    }
};

export const isBirthdayNotificationsOn = async () => {
    try {
        const useBirthNotifications = await AsyncStorage.getItem(STORAGE_BIRTHDAY_NOTIF);
        return getBooleanTrueDefault(useBirthNotifications);
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const setIsBirthdayNotificationsOn = async settings => {
    try {
        return await AsyncStorage.setItem(STORAGE_BIRTHDAY_NOTIF, settings ? 'true' : 'false');
    } catch (e) {
        console.log(e);
    }
};

export const setBirthdayNotificationTime = async time => {
    try {
        if (time) {
            return await AsyncStorage.setItem(STORAGE_BIRTHDAY_NOTIF_TIME, time);
        }
    } catch (e) {
        console.log(e);
    }
};

export const DEFAULT_BIRTHDAY_NOTIF_TIME = '08:00';
export const getBirthdayNotificationTime = async () => {
    const data = await AsyncStorage.getItem(STORAGE_BIRTHDAY_NOTIF_TIME);
    return data ? data : DEFAULT_BIRTHDAY_NOTIF_TIME;
};

export const setCurrentCheckinTime = async time => {
    try {
        if (time) {
            return await AsyncStorage.setItem(STORAGE_CURRENT_CHECKIN_TIME, time);
        }
    } catch (e) {
        console.log(e);
    }
};

export const DEFAULT_CHECKIN_TIME = '10:00';
export const getCurrentCheckinTime = async () => {
    const data = await AsyncStorage.getItem(STORAGE_CURRENT_CHECKIN_TIME);
    return data ? data : DEFAULT_CHECKIN_TIME;
};

export const setIsCurrentCheckinTimeEnabled = async settings => {
    try {
        return await AsyncStorage.setItem(STORAGE_CURRENT_CHECKIN_TIME_ENABLED, settings ? 'true' : 'false');
    } catch (e) {
        console.log(e);
    }
};

export const getIsCurrentCheckinTimeEnabled = async () => {
    try {
        const settings = await AsyncStorage.getItem(STORAGE_CURRENT_CHECKIN_TIME_ENABLED);
        return getBooleanTrueDefault(settings);
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const saveEVAccountFetchedFacilityFlag = async evAccountPin => {
    if (evAccountPin) {
        const data = await AsyncStorage.getItem(STORAGE_EVACCOUNT_FACILITY_FETCHED);
        if (data) {
            const listData = deserializeData(data);
            if (listData) {
                if (!listData.includes(evAccountPin)) {
                    listData.push(evAccountPin);
                }
                await AsyncStorage.setItem(STORAGE_EVACCOUNT_FACILITY_FETCHED, JSON.stringify(listData));
            } else {
                await AsyncStorage.setItem(STORAGE_EVACCOUNT_FACILITY_FETCHED, JSON.stringify([evAccountPin]));
            }
        } else {
            await AsyncStorage.setItem(STORAGE_EVACCOUNT_FACILITY_FETCHED, JSON.stringify([evAccountPin]));
        }
    }
};

export const isEVAccountFetchedFacility = async evaccountPin => {
    if (evaccountPin) {
        const data = await AsyncStorage.getItem(STORAGE_EVACCOUNT_FACILITY_FETCHED);
        try {
            if (data) {
                const evAccountList = JSON.parse(data);
                const matchedId = evAccountList.find(item => item === evaccountPin);
                return matchedId ? true : false;
            }
        } catch (e) {
            console.log(e);
        }
    }
    return false;
};

export const refreshUserCredits = async () => {
    const online = await isOnline();
    if (online) {
        const user = await getUserEmail();
        if (user) {
            const credits = await fetchCredits();
            if (credits) {
                await setCredits(credits);
                return credits;
            }
        }
    }
    return null;
};

export const updateUserInfoFields = async (keys, values) => {
    try {
        const user = await getUserData();
        let userInfo = user?.[STORAGE_USER_DATA]?.[USER_INFO];
        if (!userInfo) {
            userInfo = {};
        }
        let index = 0;
        for (let key of keys) {
            let value = values[index];
            if (key && value) {
                userInfo[key] = value;
            }
            index++;
        }
        // workaround for default values
        for (let field of invoiceFormFields) {
            if (!userInfo.hasOwnProperty(field)) {
                if (field === USER_DATA_FORM_ENTITY_FLAG) {
                    userInfo[field] = 0;
                } else if (field === USER_DATA_SOJOURN_TAX) {
                    userInfo[field] = 'false';
                } else {
                    userInfo[field] = '';
                }
            }
        }
        await setUserSettings(USER_INFO, userInfo);
    } catch (e) {
        console.log(e);
    }
};

export const getInvoiceLastSettings = async () => {
    try {
        const data = await AsyncStorage.getItem(STORAGE_INVOICE_LAST_SETTINGS);
        if (data) {
            return JSON.parse(data);
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const setInvoiceLastSettings = async data => {
    try {
        if (data) {
            const stringData = JSON.stringify(data);
            return await AsyncStorage.setItem(STORAGE_INVOICE_LAST_SETTINGS, stringData);
        }
    } catch (e) {
        console.log(e);
    }
};

export const getSortingLastSettings = async () => {
    try {
        const data = await AsyncStorage.getItem(STORAGE_SORTING_SETTINGS);
        if (data) {
            return JSON.parse(data);
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const setSortingLastSettings = async data => {
    try {
        if (data) {
            const stringData = JSON.stringify(data);
            return await AsyncStorage.setItem(STORAGE_SORTING_SETTINGS, stringData);
        }
    } catch (e) {
        console.log(e);
    }
};

export const getInvoiceUserData = async () => {
    const userInfoData = await getUserInfo();
    const operatorId = userInfoData?.[USER_DATA_INV_OPERATOR_ID] ?? '';
    const operatorName = userInfoData?.[USER_DATA_INV_OPERATOR] ?? '';
    const operatorData = {
        [USER_DATA_INV_OPERATOR_ID]: operatorId,
        [USER_DATA_INV_OPERATOR]: operatorName,
    };
    const footerData = {[USER_DATA_INV_FOOTER_DATA]: userInfoData?.[USER_DATA_INV_FOOTER_DATA] ?? ''};
    return {userInfoData, operatorData, footerData};
};

export const setInvoicesYear = async year => {
    await AsyncStorage.setItem(STORAGE_INVOICES_YEAR, String(year));
};

export const getInvoicesYear = async () => {
    const year = await AsyncStorage.getItem(STORAGE_INVOICES_YEAR);
    return year ? year : getCurrentYear();
};

export const setCheckinsYear = async year => {
    await AsyncStorage.setItem(STORAGE_CHECKINS_YEAR, String(year));
};

export const getCheckinsYear = async () => {
    const year = await AsyncStorage.getItem(STORAGE_CHECKINS_YEAR);
    return year ? year : getCurrentYear();
};

export const getIsRecepcija = async _ => {
    const flag = await AsyncStorage.getItem(STORAGE_RECEPCIJA_GROUP);
    return flag && flag === '1' ? true : false;
};

export const clearUserData = async () => {
    await AsyncStorage.removeItem(STORAGE_APP_ACCESS_TOKEN);
    await AsyncStorage.removeItem(STORAGE_USER_ACCESS_TOKEN);
    await AsyncStorage.removeItem(STORAGE_REFRESH_TOKEN);
    await AsyncStorage.removeItem(STORAGE_COMM_KEY);
    await AsyncStorage.removeItem(STORAGE_USER_EMAIL);
    await AsyncStorage.removeItem(STORAGE_USER_ID);
    await AsyncStorage.removeItem(STORAGE_USER_ORDINAL);
    await AsyncStorage.removeItem(STORAGE_USER_DATA);
    await AsyncStorage.removeItem(STORAGE_PASSWORD);
    await AsyncStorage.removeItem(EV_ACCOUNTS_DATA);
    await AsyncStorage.removeItem(STORAGE_CHOSEN_EV_ACCOUNT);
    await AsyncStorage.removeItem(STORAGE_CHOSEN_EV_FACILITY);
    await AsyncStorage.removeItem(STORAGE_CREDITS);
    await AsyncStorage.removeItem(STORAGE_USE_USER_DATA_FOR_INVOICE);
    await AsyncStorage.removeItem(STORAGE_LOGO_INVOICE);
    await AsyncStorage.removeItem(STORAGE_CHOSEN_INVOICE_OWNER);
    await AsyncStorage.removeItem(STORAGE_DEBUG);
    await AsyncStorage.removeItem(STORAGE_BIRTHDAY_NOTIF);
    await AsyncStorage.removeItem(STORAGE_BIRTHDAY_NOTIF_TIME);
    await AsyncStorage.removeItem(STORAGE_EVACCOUNT_FACILITY_FETCHED);
    await AsyncStorage.removeItem(STORAGE_INVOICE_OPERATOR);
    await AsyncStorage.removeItem(STORAGE_INVOICE_FOOTER);
    await AsyncStorage.removeItem(STORAGE_CURRENT_CHECKIN_TIME_ENABLED);
    await AsyncStorage.removeItem(STORAGE_CURRENT_CHECKIN_TIME);
    await AsyncStorage.removeItem(STORAGE_INVOICE_LAST_SETTINGS);
    await AsyncStorage.removeItem(STORAGE_SORTING_SETTINGS);
    await AsyncStorage.removeItem(STORAGE_INVOICES_YEAR);
    await AsyncStorage.removeItem(STORAGE_CHECKINS_YEAR);
    await AsyncStorage.removeItem(STORAGE_RECEPCIJA_GROUP);
    await cancelAllNotifications();
};
