import React from 'react';
import {View} from 'react-native';
import {Text, useTheme} from 'react-native-elements';
import globalStyle from '../theme/globalStyle';
import Modal from 'react-native-modal';
import {REST_COMMUNICATION} from '../api/inCheckinRestService';

const ErrorMessageDialog = ({
    isScreenFocused,
    useSharedErrors,
    apiErrors,
    apiMessages,
    openApiErrorMessage,
    closeErrorDialogs,
}) => {
    if (!isScreenFocused) return null;

    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {checkinErrors, isOpenCheckinErrorMessage} = useSharedErrors();
    const generateMessage = () => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`;
        }
        if (apiMessages) {
            if (apiMessages?.signal === REST_COMMUNICATION) return `${apiMessages?.message}`;
            return `${apiMessages?.signal}: ${apiMessages?.message}`;
        }
        if (checkinErrors) {
            return `${checkinErrors?.type} ${checkinErrors?.guest}  - ${checkinErrors?.description}`;
        }
        return null;
    };

    const msg = generateMessage();
    const isOpen = (isOpenCheckinErrorMessage || openApiErrorMessage) && msg ? true : false;
    return (
        <Modal
            isVisible={isOpen}
            onBackdropPress={closeErrorDialogs}
            onBackButtonPress={closeErrorDialogs}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalContent}>
                    <Text style={global.modalContentText}>{msg}</Text>
                </View>
            </View>
        </Modal>
    );
};

export default ErrorMessageDialog;
