import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    modal: {
        margin: 10,
    },

    modalDialog: {
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        borderRadius: 10,
        overflow: 'hidden',
    },
}));

export default useStyles;
