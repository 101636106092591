import React, {useState, useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {FlatList, Pressable, View} from 'react-native';
import {ListItem, Text, Icon, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import Modal from 'react-native-modal';
import {ID, NAME, SEARCH} from '../../constants/stringsAndFields';
import {isNotEmpty} from '../../utils/arrayHelpers';
import UITextInput from '../ui-components/UITextInput';

const ModalChooser = ({visibleModal, setVisibleModal, chooserData}) => {
    const [search, setSearch] = useState('');
    const {t} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);

    const {
        title = '',
        filterProperty = NAME,
        filterItemId = ID,
        listData = [],
        onItemSelected = _ => {},
    } = chooserData ?? {};

    const updateSearch = useCallback(text => {
        setSearch(text);
    }, []);

    const clearSearch = useCallback(text => {
        setSearch('');
    }, []);

    const toggleOverlay = useCallback(() => {
        setSearch('');
        setVisibleModal(false);
    }, []);

    const filteredList =
        (isNotEmpty(listData) &&
            listData.filter(item => item[filterProperty]?.toLowerCase().includes(search.toLowerCase()))) ??
        [];

    const render = ({item}) => (
        <ListItem
            key={item?.[filterItemId]}
            bottomDivider
            onPress={() => {
                setSearch('');
                onItemSelected(item);
            }}>
            <ListItem.Content>
                <ListItem.Title>
                    <Text style={global.textMain}>{item?.[filterProperty]}</Text>
                </ListItem.Title>
            </ListItem.Content>
        </ListItem>
    );

    const searchContainerStyle = useMemo(() => {
        return {
            marginBottom: 0,
            backgroundColor: theme.colors.lightBg,
            borderBottomWidth: 1,
            borderBottomColor: theme.colors.greyLight,
            padding: 10,
        };
    }, [theme]);

    return (
        <Modal
            propagateSwipe
            isVisible={visibleModal}
            onBackdropPress={toggleOverlay}
            onBackButtonPress={toggleOverlay}
            backdropTransitionOutTiming={0}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{title}</Text>
                    <Pressable onPress={toggleOverlay} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <UITextInput
                    value={search}
                    renderErrorMessage={false}
                    iconLeft="search"
                    iconRight={search ? 'close' : null}
                    onRightIconPress={clearSearch}
                    placeholder={t(SEARCH)}
                    onChangeText={updateSearch}
                    containerStyle={searchContainerStyle}
                />

                <FlatList data={filteredList} renderItem={render} keyExtractor={item => item?.[filterItemId]} />
            </View>
        </Modal>
    );
};

export default ModalChooser;
