import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    linkWrapper: {
        borderColor: 'rgba(93, 77, 157,  0.4)',
        borderWidth: 1,
        paddingHorizontal: 10,
        borderRadius: 10,
        height: 35,
        flexDirection: 'row',
        overflow: 'hidden',
    },

    linkTextWrapper: {
        flex: 1,
        marginRight: 10,
    },

    linkText: {
        lineHeight: 33,
    },

    linkBtns: {
        backgroundColor: theme.colors.primaryExtraLight,
        borderColor: 'rgba(93, 77, 157,  0.4)',
        borderLeftWidth: 1,
        width: 35,
        height: 33,
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default useStyles;
