import {saveInvoiceLogo} from './userUtils';

export const targetLogoDimensionsNative = {
    width: 300,
    height: 100,
};

export const targetLogoDimensionsWeb = {
    width: 250,
    height: 40,
};

export const uploadImage = async (event, imgRef) => {
    const reader = new FileReader();
    reader.onload = function () {
        const data = reader.result;
        updateImg(imgRef, data, resizedImg => {
            saveInvoiceLogo(resizedImg)
                .then(_ => {
                    updateImg(imgRef, resizedImg);
                })
                .catch(console.error);
        });
    };
    reader.readAsDataURL(event.target.files[0]);
};

export const updateImg = (imgRef, data, resizeCallback = null) => {
    const img = imgRef.current;
    if (img) {
        img.onload = function (_) {
            if (resizeCallback) {
                resizeImage(data, targetLogoDimensionsWeb.width, targetLogoDimensionsWeb.height, dataUrl => {
                    resizeCallback(dataUrl);
                });
            }
        };
        if (data) {
            img.src = data;
            img.hidden = false;
        } else {
            img.hidden = true;
        }
    }
};

const resizeImage = (base64image, width, height, callback) => {
    const img = new Image();
    img.src = base64image;
    let base64ResizedImage = null;

    img.onload = () => {
        // Check if the image require resize at all
        if (img.height <= height && img.width <= width) {
            base64ResizedImage = base64image;
        } else {
            // Make sure the width and height preserve the original aspect ratio and adjust if needed
            if (img.height > img.width) {
                width = Math.floor(height * (img.width / img.height));
            } else {
                height = Math.floor(width * (img.height / img.width));
            }

            let resizingCanvas = document.createElement('canvas');
            let resizingCanvasContext = resizingCanvas.getContext('2d');

            // Start with original image size
            resizingCanvas.width = img.width;
            resizingCanvas.height = img.height;

            // Draw the original image on the (temp) resizing canvas
            resizingCanvasContext.drawImage(img, 0, 0, resizingCanvas.width, resizingCanvas.height);

            let curImageDimensions = {
                width: Math.floor(img.width),
                height: Math.floor(img.height),
            };

            let halfImageDimensions = {
                width: null,
                height: null,
            };

            // Quickly reduce the dize by 50% each time in few iterations until the size is less then
            // 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
            // created with direct reduction of very big image to small image
            while (curImageDimensions.width * 0.5 > width) {
                // Reduce the resizing canvas by half and refresh the image
                halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
                halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);

                resizingCanvasContext.drawImage(
                    resizingCanvas,
                    0,
                    0,
                    curImageDimensions.width,
                    curImageDimensions.height,
                    0,
                    0,
                    halfImageDimensions.width,
                    halfImageDimensions.height
                );

                curImageDimensions.width = halfImageDimensions.width;
                curImageDimensions.height = halfImageDimensions.height;
            }

            // Now do final resize for the resizingCanvas to meet the dimension requirments
            // directly to the output canvas, that will output the final image
            let outputCanvas = document.createElement('canvas');
            let outputCanvasContext = outputCanvas.getContext('2d');

            outputCanvas.width = width;
            outputCanvas.height = height;

            outputCanvasContext.drawImage(
                resizingCanvas,
                0,
                0,
                curImageDimensions.width,
                curImageDimensions.height,
                0,
                0,
                width,
                height
            );

            // output the canvas pixels as an image. params: format, quality
            base64ResizedImage = outputCanvas.toDataURL('image/png', 0.95);
        }
        callback(base64ResizedImage);
    };
};
