import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    bgContainer: {
        backgroundColor: theme.colors.primaryExtraLight,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },

    contentWrapper: {
        flex: 1,
        justifyContent: 'center',
        width: '100%',
        paddingTop: 35,
    },

    container: {
        flexGrow: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
    },

    headerIcon: {
        backgroundColor: '#f5f5fa',
        borderRadius: 50,
        width: 70,
        height: 70,
        justifyContent: 'center',
        alignSelf: 'center',
        borderWidth: 1,
        borderColor: theme.colors.primary,
        position: 'absolute',
        zIndex: 1,
    },

    textHeader: {
        textAlign: 'center',
        marginBottom: 20,
        maxWidth: 680,
        alignSelf: 'center',
    },

    textHeaderMobile: {
        textAlign: 'center',
        marginBottom: 20,
        maxWidth: '95%',
        alignSelf: 'center',
    },

    headerSubtitle: {
        textAlign: 'center',
        marginBottom: 50,
        maxWidth: '90%',
        alignSelf: 'center',
    },

    guestFormWrapper: {
        width: '100%',
        paddingHorizontal: 15,
        paddingVertical: 20,
    },

    landlordWrapper: {
        flex: 1,
        padding: 15,
        borderTopWidth: 5,
        borderTopColor: theme.colors.primary,
        paddingTop: 50,
    },

    guestSavedMsg: {
        fontFamily: theme.fontFamilyMedium,
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        paddingVertical: 7,
        paddingHorizontal: 10,
        borderRadius: 10,
        textAlign: 'center',
        marginTop: 15,
    },

    guestMsg: {
        textAlign: 'center',
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 10,
        borderColor: theme.colors.borderPrimaryLight,
        borderWidth: 1,
        marginTop: 20,
    },

    savedGuestsTitle: {
        marginTop: 15,
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 10,
        borderColor: theme.colors.borderPrimaryLight,
        borderWidth: 1,
    },

    savedGuestsCountText: {
        color: theme.colors.primary,
        fontFamily: theme.fontFamilyMedium,
    },

    scanButtonContainer: {
        alignSelf: 'flex-end',
    },

    scanButtonContainerMobile: {
        alignSelf: 'center',
        marginBottom: 20,
    },

    saveBtnWrapper: {
        alignItems: 'center',
    },

    shareButton: {
        marginTop: 30,
        alignSelf: 'center',
    },

    note: {
        textAlign: 'center',
        marginVertical: 30,
        paddingHorizontal: 15,
        fontSize: 12,
    },
}));
export default useStyles;
