import React, {useState} from 'react';
import {FlatList, RefreshControl, View, Text, useWindowDimensions, Pressable} from 'react-native';
import {useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {useTranslation} from 'react-i18next';
import {AUTO_CHECKOUT_NOTE, LEARN_MORE, NOTE, NO_EVACCOUNT, PIN} from '../../constants/stringsAndFields';
import EVAccountItem from './EVAccountItem';
import useStyles from './EVAccountsList.styles';
import AutoCheckOutDialog from './AutoCheckOutDialog';

const EVAccountsList = ({evaccounts, onDelete, refreshing, setOpenProgress, onRefresh, refetchEvisitor}) => {
    const [showAutoCheckOut, setShowAutoCheckOut] = useState(false);

    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t} = useTranslation();

    const width = useWindowDimensions().width;
    const isDesktop = width > 1500;

    const showAutoCheckOutNote = () => setShowAutoCheckOut(true);

    const render = ({item}) => (
        <EVAccountItem
            evaccount={item}
            onDelete={onDelete}
            setOpenProgress={setOpenProgress}
            refetchEvisitor={refetchEvisitor}
        />
    );
    const listEmpty = () => (
        <View style={global.listEmptyMsg}>
            <Text style={global.listEmptyMsgText}>{t(NO_EVACCOUNT)}</Text>
        </View>
    );

    const listHeader = () => (
        <>
            {evaccounts && (
                <View
                    style={[
                        global.card,
                        global.boxShadow,
                        {padding: 15, backgroundColor: theme.colors.lightBg, marginBottom: 20, overflow: 'visible'},
                    ]}>
                    <Text style={[global.textCapsDark, {marginBottom: 5}]}>{t(NOTE) + ': '}</Text>

                    <Text>
                        <Text style={[global.textMain, {fontFamily: theme.fontFamilyMedium}]}>
                            {t(AUTO_CHECKOUT_NOTE) + ' '}
                        </Text>
                        <Text onPress={showAutoCheckOutNote}>
                            <Text style={global.link}>{t(LEARN_MORE)}</Text>
                        </Text>
                    </Text>
                </View>
            )}
            <AutoCheckOutDialog isOpen={showAutoCheckOut} setIsOpen={setShowAutoCheckOut} />
        </>
    );

    return (
        <FlatList
            data={evaccounts}
            renderItem={render}
            keyExtractor={item => item[PIN]}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
            contentContainerStyle={[global.flatList, global.hasFAB, global.containerInner, {flexGrow: 1}]}
            ListEmptyComponent={listEmpty}
            ListHeaderComponent={listHeader}
        />
    );
};

export default EVAccountsList;
