import {finishTransaction, purchaseErrorListener, purchaseUpdatedListener} from "react-native-iap";
import {postIapsAppleTopUp} from "../api/inCheckinRestService";
import {getFormattedDateFromSql} from "./dateHelper";

export const initIAPSubscriptions = () => {
    // subscribe to apple callbacks. when purchase is done from other screen update subscription is called
    let purchaseUpdateSubscription = purchaseUpdatedListener(async (purchase: InAppPurchase | SubscriptionPurchase | ProductPurchase,) => {
        // here we'll receive paid but unfinished purchases
        // send purchase to ici backend for verification and top-up
        // if top up is successful finish the purchase
        console.log('IAPS did recieve purchaseUpdatedListener');
        const receipt = purchase.transactionReceipt;
        if (receipt) {
            try {
                let topUpData = {purchase};
                let respData = await postIapsAppleTopUp(topUpData);
                const credits = respData.user.credits;
                const creditsBestBefore = getFormattedDateFromSql(respData.user.credits_best_before);
                console.log('IAP top-up successful', credits, creditsBestBefore);
                await finishTransaction({purchase}, true);
            } catch (e) {
                console.error('IAPS purchaseUpdateSubscription top-up failed', e);
            }
        }
    });

    let purchaseErrorSubscription = purchaseErrorListener((error: PurchaseError) => {
        alert(error.message);
        console.error('IAPS purchaseErrorListener', error);
    });

    return () => {
        // called when the app restarts on save and probably on exit
        if (purchaseUpdateSubscription) {
            purchaseUpdateSubscription.remove();
            purchaseUpdateSubscription = null;
        }

        if (purchaseErrorSubscription) {
            purchaseErrorSubscription.remove();
            purchaseErrorSubscription = null;
        }
    };
}