import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    container: {
        flex: 1,
        padding: 30,
        alignItems: 'center',
        alignContent: 'center',
    },

    card: {
        backgroundColor: theme.colors.white,
        boxShadow: 'rgb(0 0 0 / 10%) 0 0 10',
        padding: 30,
        borderRadius: 30,
        width: 500,
    },

    title: {
        fontSize: 40,
        fontFamily: theme.fontFamilySemiBold,
        color: theme.colors.greyDark,
        marginBottom: 20,
    },

    text: {
        fontSize: 16,
        color: theme.colors.greyDark,
        marginBottom: 10,
    },
}));

export default useStyles;
