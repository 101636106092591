import React, {memo, useEffect, useMemo, useState} from 'react';
import {
    FlatList,
    RefreshControl,
    View,
    Text,
    useWindowDimensions,
    TouchableOpacity,
    ActivityIndicator,
} from 'react-native';
import {useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {useTranslation} from 'react-i18next';
import TouristItem from './TouristItem';
import {useSharedDialogs} from './useDialogs';
import {LOAD_MORE, NO_TOURISTS} from '../../constants/stringsAndFields';
import {TOURIST_PAGE_COUNT} from '../../screens/TouristsScreen';

const styles = {
    footer: {
        padding: 7,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    loadMoreBtn: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 160,
        minHeight: 38,
        paddingHorizontal: 0,
    },
};

const TouristsList = ({
    tourists,
    evAccount,
    checkinId,
    navigation,
    refreshing,
    onRefresh,
    calendarInput,
    facilityData,
    eVisitorStaticData,
    day,
    importTourist,
    getNextPageTourists,
}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const {openQRDetails, updateGuestId} = useSharedDialogs();

    const width = useWindowDimensions().width;
    const isDesktop = width > 1500;

    const getData = async _ => {
        setLoading(true);
        const incrementedPage = page + 1;
        const results = await getNextPageTourists(incrementedPage);
        if (results === TOURIST_PAGE_COUNT) {
            setPage(incrementedPage);
        }
        setLoading(false);
    };

    const renderFooter = () => {
        return tourists?.length === page * TOURIST_PAGE_COUNT ? (
            <View style={styles.footer}>
                <TouchableOpacity
                    activeOpacity={0.9}
                    onPress={getData}
                    style={[global.outlineButton, styles.loadMoreBtn]}>
                    {loading ? (
                        <ActivityIndicator color={theme.colors.primary} style={[{marginLeft: 8}, {maxHeight: 8}]} />
                    ) : (
                        <Text style={[global.outlineButtonTitle, {textTransform: 'none', fontSize: 12}]}>
                            {t(LOAD_MORE)}
                        </Text>
                    )}
                </TouchableOpacity>
            </View>
        ) : null;
    };

    const render = ({item}) => (
        <TouristItem
            tourist={item}
            evAccount={evAccount}
            checkinId={checkinId}
            navigation={navigation}
            calendarInput={calendarInput}
            facilityData={facilityData}
            eVisitorStaticData={eVisitorStaticData}
            day={day}
            openQRDetails={openQRDetails}
            updateGuestId={updateGuestId}
            importTourist={importTourist}
        />
    );

    const memoContentContainerStyle = useMemo(() => {
        return [global.flatList, {width: '100%', maxWidth: 1100, alignSelf: 'center'}, isDesktop && {paddingTop: 25}];
    }, [global, isDesktop]);

    const emptyList = <MemoizedEmptyList t={t} global={global} />;

    useEffect(() => {
        if (tourists?.length === TOURIST_PAGE_COUNT) {
            setPage(1);
        }
    }, [tourists?.length]);

    return (
        <FlatList
            data={tourists}
            renderItem={render}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
            contentContainerStyle={memoContentContainerStyle}
            ListEmptyComponent={emptyList}
            ListFooterComponent={renderFooter}
        />
    );
};

export default TouristsList;

export function EmptyList({t, global}) {
    return (
        <View style={global.listEmptyMsg}>
            <Text style={global.listEmptyMsgText}>{t(NO_TOURISTS)}</Text>
        </View>
    );
}
export const MemoizedEmptyList = memo(EmptyList);
