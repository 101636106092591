import {openDB} from 'idb';
// IndexedDb is used for persisting database to browser

const IDX_DB = 'ECHECKIN_DB';
const IDX_DB_STORE = 'ECHECKIN_DB_STORE';
export const IDX_DB_SQLITE_KEY = 'SQLITE';

// singleton for holding db and store reference
const instance = (() => {
    let localDb = null;
    let initialized = false;
    return {
        setDb: db => {
            localDb = db;
            initialized = true;
        },
        getInitalized: () => initialized,
        getDb: () => localDb,
    };
})();

export const initIdxDb = async () => {
    try {
        if (!instance.getInitalized()) {
            console.log('initIdxDb initalizing');
            const indexedDb = await openDB(IDX_DB, 1, {
                upgrade(database) {
                    database.createObjectStore(IDX_DB_STORE);
                },
            });
            instance.setDb(indexedDb);
            console.log('initIdxDb initialized');
        } else {
            console.log('initIdxDb already initialized');
            console.log(db);
        }
    } catch (e) {
        console.log('Failed to initIdxDb');
        console.log(e);
    }
};

export const putToIdxDb = async (key, content) => {
    try {
        if (instance.getInitalized()) {
            //console.log("Writing to putToIdxDb")
            await instance.getDb().put(IDX_DB_STORE, content, key);
        } else {
            console.log('Could not write to putToIdxDb, no db connection');
        }
    } catch (e) {
        console.log('Could not write to putToIdxDb');
        console.log(e);
    }
};

export const getFromIdxDb = async key => {
    try {
        if (instance.getInitalized()) {
            const content = await instance.getDb().get(IDX_DB_STORE, key);
            console.log('getFromIdxDb');
            console.log(content);
            return content;
        } else {
            console.log('Could not get from getFromIdxDb, no db connection');
        }
    } catch (e) {
        console.log('Could not get from getFromIdxDb');
        console.log(e);
    }
    return null;
};
