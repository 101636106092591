import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    itemWrapper: {
        flexDirection: 'row',
        marginBottom: 10,
        flexWrap: 'wrap',
    },

    itemWrapperAmount: {
        flexDirection: 'row',
        marginBottom: 15,
        paddingTop: 35,
        marginTop: 5,
        flexWrap: 'wrap',
        borderTopWidth: 1,
        borderTopColor: theme.colors.borderLight,
        justifyContent: 'center',
    },

    itemValue: {
        color: theme.colors.primary,
        fontFamily: theme.fontFamilySemibold,
        flex: 1,
    },

    itemTitle: {
        minWidth: 130,
        fontFamily: theme.fontFamilyMedium,
    },
}));

export default useStyles;
