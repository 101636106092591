import React from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable, ScrollView, View} from 'react-native';
import {Button, Icon, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {
    AUTO_CHECKOUT,
    AUTO_CHECKOUT_DIALOG_NOTE_INTRO,
    AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT,
    AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_1,
    AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_2,
    AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_3,
    AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_4,
    AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_5,
    AUTO_CHECKOUT_DIALOG_SECTION_1_TITLE,
    AUTO_CHECKOUT_DIALOG_SECTION_2_CONTENT_1,
    AUTO_CHECKOUT_DIALOG_SECTION_2_CONTENT_2,
    AUTO_CHECKOUT_DIALOG_SECTION_2_TITLE,
    EXAMPLE,
    OK,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import useStyles from './AutoCheckOutDialog.styles';
import {getNowDayDateFormated3, getTomorrowDayDateFormated3} from '../../utils/dateHelper';

const AutoCheckOutDialog = ({isOpen, setIsOpen}) => {
    const {t} = useTranslation();
    const {theme} = useTheme();
    const styles = useStyles(theme);
    const global = globalStyle(theme);

    const handleClose = () => {
        setIsOpen(false);
    };

    const today = getNowDayDateFormated3();
    const tomorrow = getTomorrowDayDateFormated3();

    return (
        <Modal
            isVisible={isOpen}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={[global.modalDialog, {height: '100%', backgroundColor: theme.colors.white}]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(AUTO_CHECKOUT)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <ScrollView contentContainerStyle={{flexGrow: 1, padding: 15}}>
                    <View style={styles.section}>
                        <Text style={styles.content}>{t(AUTO_CHECKOUT_DIALOG_NOTE_INTRO)}</Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.title}>{t(AUTO_CHECKOUT_DIALOG_SECTION_1_TITLE)}</Text>
                        <Text style={styles.content}>{t(AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_1)}</Text>
                        <Text style={styles.content}>
                            <Text style={{fontFamily: theme.fontFamilyMedium}}>{t(EXAMPLE)}: </Text>
                            {t(AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_2)} {today}{' '}
                            {t(AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_3)} {tomorrow}
                            {t(AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_4)} {today}{' '}
                            {t(AUTO_CHECKOUT_DIALOG_SECTION_1_CONTENT_5)}
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.title}>{t(AUTO_CHECKOUT_DIALOG_SECTION_2_TITLE)}</Text>
                        <Text style={styles.content}>{t(AUTO_CHECKOUT_DIALOG_SECTION_2_CONTENT_1)}</Text>
                        <Text style={styles.content}>{t(AUTO_CHECKOUT_DIALOG_SECTION_2_CONTENT_2)}</Text>
                    </View>
                </ScrollView>
                <View style={global.modalFooter}>
                    <Button
                        title={t(OK)}
                        onPress={handleClose}
                        containerStyle={global.ModalButton.Positive.containerStyle}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                        disabledStyle={global.ModalButton.disabledStyle}
                        disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default AutoCheckOutDialog;
