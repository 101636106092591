import * as yup from 'yup';
import {
    USER_DATA_FORM_ADDRESS,
    USER_DATA_FORM_BANK,
    USER_DATA_FORM_BANK_ADDRESS,
    USER_DATA_FORM_BANK_IBAN,
    USER_DATA_FORM_BANK_SWIFT,
    USER_DATA_FORM_CITY,
    USER_DATA_FORM_COUNTRY,
    USER_DATA_FORM_EMAIL,
    USER_DATA_FORM_ENTITY,
    USER_DATA_FORM_LEGAL_NAME,
    USER_DATA_FORM_OIB,
    USER_DATA_FORM_PERSONAL_NAME,
    USER_DATA_FORM_TEL,
    REQUIRED_FIELD,
} from '../../constants/stringsAndFields';

export const schema = (selectedEntityIndex, t) => {
    let oibField = yup.string().optional();

    if (selectedEntityIndex === 1) {
        oibField = yup.string().required(t(REQUIRED_FIELD));
    }

    return yup.object().shape({
        [USER_DATA_FORM_ENTITY]: yup.string().required(),
        [USER_DATA_FORM_OIB]: oibField,
        [USER_DATA_FORM_LEGAL_NAME]: yup.string().optional(),
        [USER_DATA_FORM_PERSONAL_NAME]: yup.string().optional(),
        [USER_DATA_FORM_ADDRESS]: yup.string().optional(),
        [USER_DATA_FORM_CITY]: yup.string().optional(),
        [USER_DATA_FORM_COUNTRY]: yup.string().optional(),
        [USER_DATA_FORM_TEL]: yup.string().optional(),
        [USER_DATA_FORM_EMAIL]: yup.string().optional(),
        [USER_DATA_FORM_BANK]: yup.string().optional(),
        [USER_DATA_FORM_BANK_IBAN]: yup.string().optional(),
        [USER_DATA_FORM_BANK_SWIFT]: yup.string().optional(),
        [USER_DATA_FORM_BANK_ADDRESS]: yup.string().optional(),
    });
};

export default schema;
