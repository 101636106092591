import {v4 as uuidv4} from 'uuid';
import {
    ACCOMODATION,
    ADDITIONAL_FIELDS,
    ARRIVAL_ORGANIZATION,
    CHECKIN_ID,
    CHECKOUT_OUT,
    content,
    DATE_CREATED,
    DATE_OF_BIRTH_FIELD,
    DEFAULT_ARRIVAL_ORGANIZATION,
    DEFAULT_SERVICE_TYPE,
    EVISITOR,
    EV_ACCOUNT,
    FACILITY,
    FACILITY_CODE,
    FACILITY_FIELD,
    FORESEEN_STAY_UNTIL_FIELD,
    id,
    ID,
    MAX_GUEST_NUMBER,
    NAME,
    NAME_CODE,
    OFFERED_SERVICE_TYPE,
    PAYMENT_CATEGORY,
    PIN_ID,
    STATUS,
    STAY_FROM_FIELD,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    TIME_STAY_FROM_FIELD,
    PIN,
    GENDERS,
    CITIZENSHIP,
    CITY_OF_RESIDENCE,
    COUNTRY_OF_BIRTH,
    COUNTRY_OF_RESIDENCE,
    DOCUMENT_NUMBER,
    DOCUMENT_TYPE,
    GENDER_FIELD,
    TOURIST_MIDDLE_NAME,
    TOURIST_NAME,
    TOURIST_SURNAME,
    guests,
    CHECKIN_ACTION,
    CHECKOUT_ACTION,
    CANCEL_ACTION,
    NOT_CHECKED_IN_STATUS,
    CHECKED_IN_STATUS,
    CHECKED_OUT_STATUS,
    CANCELED_STATUS,
    UPDATED_STATUS,
    NOT_CHECKED_IN_STATUS_LABEL,
    CHECKED_IN_STATUS_LABEL,
    CHECKED_OUT_STATUS_LABEL,
    CANCELED_STATUS_LABEL,
    UPDATED_STATUS_LABEL,
    FIRST_NAME_LABEL,
    LAST_NAME_LABEL,
    status,
    ACCOMODATION_UNIT,
    FACILITY_ID,
    ACCOMODATION_OBJECT,
    DATE_TIME_OF_ARRIVAL,
    COUNTRY_DATA,
    SETTLEMENTS_DATA,
    DOCUMENT_TYPE_DATA,
    TOURIST_CHECKOUT_OUT,
    EV_IMPORTED,
    SINGLE_GUEST_CHECKIN,
    NEW_CHECKIN_ID,
    POSITION,
    ACCOMODATION_PAYMENT_CATEGORIES,
    CODE,
    INVOICE_FORM_STAY_FROM,
    INVOICE_FORM_STAY_TO,
    MANUAL_GUEST_DATES,
    CHECKIN,
    GUEST_NOTE_FIELD,
    RECORDS,
    INITIAL_GUEST_FIELD,
    SWIPE_VISIBLE,
    SWIPE_CHECKIN_ACTION,
    SWIPE_CHECKOUT_ACTION,
    DATE_TIME_OF_DEPARTURE,
    MANUAL_GUEST_TIME,
    NAME_NATIONAL_SHORT,
    COUNTRY_NAME,
    NEPOZNATO,
    ADDRESS,
} from '../constants/stringsAndFields';
import {
    getDefaultTime,
    getDateFromAspNetFormatToEvisitor,
    getDateFromAspNetFormatToFormatted,
    getDateStringFromCalDateString,
    getNowDate,
    getNowDayDate,
    getNowTime,
    getTimeFromAspNetFormatToEvisitor,
    getNightsStayUntil,
    getTimeStayUntil,
    getNightsStayUntilFromDay,
    getFormattedDateFromDateString,
    calculateAge,
    getDateFromDateString,
    isGuestDateInPast,
} from './dateHelper';
import {
    deepCopy,
    getLastGuestCountryCity,
    getPaymentCategory,
    isCroatianLang,
    isGuestEmpty,
    mapPreferredCountries,
    mapTouristToGuest,
} from './helpers';
import {fromBase64, toBase64} from './jwt';
import globalStyle from '../theme/globalStyle';
import {getTouristFacility} from './touristUtils';
import {findByFacilityCode, findById, isNotEmpty, mapCountryCodeToCountry} from './arrayHelpers';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import {fetchTourist} from '../api/inCheckinRestService';

export const DEFAULT_PAYMENT_CATEGORY = '14';

// used by CheckinScreen and Calendar
export const createGuest = async (
    guest,
    checkin,
    onUpdateGuests = null,
    returnGuest = true,
    shouldUseCheckinDates = true
) => {
    const newGuests = [];
    const facilityCode = checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[FACILITY_CODE];
    const newGuestId = uuidv4();
    const stayUntilDate = await getNightsStayUntil();
    const checkoutTime = await getTimeStayUntil();
    const forseenStayFromCheckin = checkin[FORESEEN_STAY_UNTIL_FIELD] ?? stayUntilDate;
    const timeEstimateFromCheckin = checkin[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? checkoutTime;
    const forseenStay = shouldUseCheckinDates
        ? forseenStayFromCheckin
        : guest[FORESEEN_STAY_UNTIL_FIELD] ?? forseenStayFromCheckin;
    const timeEstimate = shouldUseCheckinDates
        ? timeEstimateFromCheckin
        : guest[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? timeEstimateFromCheckin;
    const newGuest = {
        [id]: newGuestId,
        [CHECKIN_ID]: checkin[id],
        [STATUS]: NOT_CHECKED_IN_STATUS,
        [UPDATED_STATUS]: false,
        [content]: {
            ...guest,
            [FORESEEN_STAY_UNTIL_FIELD]: forseenStay,
            [ID]: newGuestId,
            [STAY_FROM_FIELD]: checkin[STAY_FROM_FIELD] ?? getNowDate(),
            [TIME_ESTIMATED_STAY_UNTIL_FIELD]: timeEstimate,
            [TIME_STAY_FROM_FIELD]: checkin[TIME_STAY_FROM_FIELD] ?? getDefaultTime(),
            [FACILITY]: facilityCode,
            [ARRIVAL_ORGANIZATION]: DEFAULT_ARRIVAL_ORGANIZATION,
            [OFFERED_SERVICE_TYPE]: DEFAULT_SERVICE_TYPE,
            [ADDITIONAL_FIELDS]: {
                [CHECKIN_ID]: checkin[id],
                [FACILITY_FIELD]: {
                    ...checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD],
                },
            },
        },
    };
    newGuests.push(newGuest);
    if (onUpdateGuests) {
        await onUpdateGuests(newGuests);
    }
    if (returnGuest) {
        return newGuest;
    }
};

export const initCheckin = async checkinParams => {
    const newCheckinId = checkinParams?.[NEW_CHECKIN_ID] ?? uuidv4();
    const numberOfGuests = checkinParams?.numberOfGuests;
    const checkinTime = checkinParams?.checkinTime;
    const stayUntilDate = await getNightsStayUntil();
    const checkoutTime = await getTimeStayUntil();
    const newCheckin = {
        [FORESEEN_STAY_UNTIL_FIELD]: checkinTime[FORESEEN_STAY_UNTIL_FIELD] ?? stayUntilDate,
        [id]: newCheckinId,
        [STAY_FROM_FIELD]: checkinTime[STAY_FROM_FIELD] ?? getNowDate(),
        [TIME_ESTIMATED_STAY_UNTIL_FIELD]: checkinTime[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? checkoutTime,
        [TIME_STAY_FROM_FIELD]: checkinTime[TIME_STAY_FROM_FIELD] ?? getNowTime(),
        [ADDITIONAL_FIELDS]: {
            [DATE_CREATED]: Date.now(),
            [EVISITOR]: {
                [NAME]: checkinParams?.[EV_ACCOUNT]?.[NAME],
                [PIN_ID]: checkinParams?.[EV_ACCOUNT]?.[PIN_ID],
                [PIN]: checkinParams?.[EV_ACCOUNT]?.[PIN],
            },
            [FACILITY_FIELD]: {
                ...checkinParams?.[FACILITY_FIELD],
            },
            [ACCOMODATION]: {
                [NAME_CODE]: '',
            },
            [MAX_GUEST_NUMBER]: numberOfGuests,
            [SINGLE_GUEST_CHECKIN]: checkinParams?.[SINGLE_GUEST_CHECKIN] ?? false,
            [MANUAL_GUEST_DATES]: checkinParams?.[MANUAL_GUEST_DATES] ?? false,
            [MANUAL_GUEST_TIME]: checkinParams?.[MANUAL_GUEST_TIME] ?? false,
        },
    };
    return newCheckin;
};

export const reCreateGuest = async (guest, checkin, getEvisitorByPin, onReCreateGuest) => {
    const status = guest[CHECKOUT_OUT] ? CHECKED_OUT_STATUS : CHECKED_IN_STATUS;
    const facilityCode = checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[FACILITY_CODE];
    const stayUntilDate = await getNightsStayUntil();
    const checkoutTime = await getTimeStayUntil();
    const forseenStay = guest?.[FORESEEN_STAY_UNTIL_FIELD] ?? checkin[FORESEEN_STAY_UNTIL_FIELD] ?? stayUntilDate;
    const timeEstimate =
        guest?.[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? checkin[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? checkoutTime;

    const paymentCategories = await getPaymentCategories(checkin, getEvisitorByPin);
    if (paymentCategories) {
        guest = extendTouristPaymentCategory(guest, paymentCategories);
    }

    const recreatedGuest = {
        [id]: guest[id],
        [CHECKIN_ID]: checkin[id],
        [STATUS]: status,
        [UPDATED_STATUS]: false,
        [content]: {
            ...guest,
            [FORESEEN_STAY_UNTIL_FIELD]: forseenStay,
            [ID]: guest[id],
            [TIME_ESTIMATED_STAY_UNTIL_FIELD]: timeEstimate,
            [FACILITY]: facilityCode,
            [ARRIVAL_ORGANIZATION]: DEFAULT_ARRIVAL_ORGANIZATION,
            [OFFERED_SERVICE_TYPE]: DEFAULT_SERVICE_TYPE,
            [ADDITIONAL_FIELDS]: {
                [CHECKIN_ID]: checkin[id],
                [FACILITY_FIELD]: {
                    ...checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD],
                },
                [GUEST_NOTE_FIELD]: guest?.[GUEST_NOTE_FIELD],
            },
        },
    };
    delete recreatedGuest?.[content]?.[GUEST_NOTE_FIELD];
    await onReCreateGuest(recreatedGuest);
};

// Muško -> Male
export const mapGender = (gender, t) => {
    const keys = Object.keys(GENDERS);
    for (const key of keys) {
        if (GENDERS[key] === gender) return t(key);
    }
    // default
    return '';
};

// used by GuestScreen
export const initNewGuest = async (guestId, checkin, guestData) => {
    const facilityCode = checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[FACILITY_CODE];
    const stayUntilDate = await getNightsStayUntil();
    const checkoutTime = await getTimeStayUntil();
    const newGuest = {
        [CHECKIN_ID]: checkin[id],
        [id]: guestId,
        [STATUS]: NOT_CHECKED_IN_STATUS,
        [UPDATED_STATUS]: false,
        [content]: {
            [ARRIVAL_ORGANIZATION]: DEFAULT_ARRIVAL_ORGANIZATION,
            [CITIZENSHIP]: '',
            [CITY_OF_RESIDENCE]: '',
            [COUNTRY_OF_BIRTH]: '',
            [COUNTRY_OF_RESIDENCE]: '',
            [DATE_OF_BIRTH_FIELD]: null,
            [DOCUMENT_NUMBER]: '',
            [DOCUMENT_TYPE]: '',
            [FACILITY]: facilityCode,
            [FORESEEN_STAY_UNTIL_FIELD]: checkin[FORESEEN_STAY_UNTIL_FIELD] || stayUntilDate,
            [GENDER_FIELD]: '',
            [ID]: guestId,
            [OFFERED_SERVICE_TYPE]: DEFAULT_SERVICE_TYPE,
            [STAY_FROM_FIELD]: checkin[STAY_FROM_FIELD] || getNowDate(),
            [TIME_ESTIMATED_STAY_UNTIL_FIELD]: checkin[TIME_ESTIMATED_STAY_UNTIL_FIELD] || checkoutTime,
            [TIME_STAY_FROM_FIELD]: checkin[TIME_STAY_FROM_FIELD] || getNowTime(),
            [TOURIST_MIDDLE_NAME]: '',
            [TOURIST_NAME]: '',
            [TOURIST_SURNAME]: '',
            [ADDITIONAL_FIELDS]: {
                [CHECKIN_ID]: checkin[id],
                [FACILITY_FIELD]: {
                    ...checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD],
                },
            },
        },
    };

    if (guestData) {
        try {
            const decodedGuestData = JSON.parse(fromBase64(guestData));
            newGuest[content] = {
                ...newGuest[content],
                ...decodedGuestData[content],
            };
            newGuest[content][ADDITIONAL_FIELDS][FACILITY_FIELD] = {
                ...decodedGuestData[FACILITY],
            };
            const decodedGuestId = decodedGuestData?.[content]?.[ID];
            if (decodedGuestId) {
                newGuest[id] = decodedGuestId;
            }
        } catch (e) {
            console.log(e);
        }
    }
    return newGuest;
};

export const generateUpdatedStatus = (checkedInStatus, previouslyEditedStatus, isNewGuest, areEqual) => {
    if (checkedInStatus === CHECKED_IN_STATUS && !isNewGuest && !areEqual) return true;
    if (checkedInStatus === CHECKED_IN_STATUS && !isNewGuest && areEqual && previouslyEditedStatus) return true;
    else return false;
};

export const getGuestsStatusesCounts = checkinGuests => {
    let checkedInCount = 0;
    let guestsForCheckinCount = 0;
    let checkoutedCount = 0;
    let guestCount = 0;
    if (checkinGuests) {
        for (let guest of checkinGuests) {
            if (guest) {
                if (guest?.[STATUS] === CHECKED_IN_STATUS) {
                    checkedInCount++;
                    if (guest?.[UPDATED_STATUS]) {
                        guestsForCheckinCount++;
                    }
                } else if (guest?.[STATUS] === NOT_CHECKED_IN_STATUS) {
                    guestsForCheckinCount++;
                } else if (guest?.[STATUS] === CHECKED_OUT_STATUS) {
                    checkoutedCount++;
                }
                guestCount++;
            }
        }
    }
    return {checkedInCount, checkoutedCount, guestsForCheckinCount, guestCount};
};

export const getGuestStatusLabel = (guest, t) => {
    if (guest?.[STATUS] === CHECKED_IN_STATUS) {
        if (guest?.[UPDATED_STATUS]) return t(UPDATED_STATUS_LABEL);
        else return t(CHECKED_IN_STATUS_LABEL);
    } else if (guest?.[STATUS] === CHECKED_OUT_STATUS) return t(CHECKED_OUT_STATUS_LABEL);
    else if (guest?.[STATUS] === NOT_CHECKED_IN_STATUS) return t(NOT_CHECKED_IN_STATUS_LABEL);
    else if (guest?.[STATUS] === CANCELED_STATUS) return t(CANCELED_STATUS_LABEL);
};

export const getGuestStatusLabelColor = (guest, theme) => {
    const global = globalStyle(theme);
    if (guest?.[STATUS] === CHECKED_IN_STATUS) {
        if (guest?.[UPDATED_STATUS]) return global.labelRed;
        else return global.labelGreen;
    } else if (guest?.[STATUS] === CHECKED_OUT_STATUS) return global.labelBlue;
    else if (guest?.[STATUS] === NOT_CHECKED_IN_STATUS) return global.labelRed;
    else if (guest?.[STATUS] === CANCELED_STATUS) return global.checkinLabel;
};

export const getGuestStatusBorderColor = (guest, theme) => {
    const global = globalStyle(theme);
    if (guest?.[STATUS] === CHECKED_IN_STATUS) {
        if (guest?.[UPDATED_STATUS]) return theme.colors.error;
        else return theme.colors.success;
    } else if (guest?.[STATUS] === CHECKED_OUT_STATUS) return theme.colors.default;
    else if (guest?.[STATUS] === NOT_CHECKED_IN_STATUS) return theme.colors.error;
    else if (guest?.[STATUS] === CANCELED_STATUS) return theme.colors.error;
};

export const getGuestTitleWithAge = (t, guest) => {
    if (guest?.content?.status === CANCELED_STATUS || guest?.status === CANCELED_STATUS) {
        return `${t(FIRST_NAME_LABEL)} ${t(LAST_NAME_LABEL)}`;
    }

    const firstName = guest?.content?.[TOURIST_NAME] ?? guest?.[TOURIST_NAME] ?? '';
    const lastName = guest?.content?.[TOURIST_SURNAME] ?? guest?.[TOURIST_SURNAME] ?? '';
    const age = calculateAge(
        getDateFromDateString(guest?.content?.[DATE_OF_BIRTH_FIELD] ?? guest?.[DATE_OF_BIRTH_FIELD])
    );

    const formattedAge = (firstName || lastName) && age !== null ? `(${Math.floor(age)})` : '';
    const title = `${firstName} ${lastName} ${formattedAge}`;

    return title;
};

export const showQRCode = status => [CHECKED_IN_STATUS, CHECKED_OUT_STATUS].includes(status);

export const setCheckinActionsCount = (checkin, setActionsCount) => {
    const filteredGuests = checkin?.[guests] ?? [];
    const updatedActionsCount = {
        [CHECKIN_ACTION]: 0,
        [CHECKOUT_ACTION]: 0,
        [CANCEL_ACTION]: 0,
    };
    for (let guest of filteredGuests) {
        const guestEvisitorStatus = guest[status];
        const guestEditedStatus = guest[UPDATED_STATUS];
        if (getForCheckout(guestEvisitorStatus)) {
            updatedActionsCount[CHECKOUT_ACTION] += 1;
        }
        if (getForCheckin(guestEvisitorStatus, guestEditedStatus)) {
            updatedActionsCount[CHECKIN_ACTION] += 1;
        }
        if (getForCancel(guestEvisitorStatus)) {
            updatedActionsCount[CANCEL_ACTION] += 1;
        }
    }
    setActionsCount(updatedActionsCount);
};

export const getForCheckin = (guestEvisitorStatus, guestEditedStatus) =>
    guestEvisitorStatus === NOT_CHECKED_IN_STATUS || guestEditedStatus ? true : false;

export const getForCheckout = guestStatus => guestStatus === CHECKED_IN_STATUS;

// Swipe button additional checks
export const getForCheckinCheckEdited = (guestEvisitorStatus, guestEditedStatus) =>
    guestEvisitorStatus === NOT_CHECKED_IN_STATUS ||
    guestEditedStatus ||
    (guestEvisitorStatus === CHECKED_IN_STATUS && guestEditedStatus);

export const getForCheckoutCheckEdited = (guestStatus, guestEditedStatus) =>
    guestStatus === CHECKED_IN_STATUS && !guestEditedStatus;

export const getForCancel = guestStatus => [CHECKED_OUT_STATUS, CHECKED_IN_STATUS].includes(guestStatus);

export const checkIfShouldDisableArrival = guestStatus => [CHECKED_IN_STATUS, CHECKED_OUT_STATUS].includes(guestStatus);

export const checkIfShouldDisableFields = guestStatus => guestStatus === CHECKED_OUT_STATUS;

export const checkArrivalDisabled = (checkinGuests, setDisableArrivalForCheckedIn) => {
    if (checkinGuests) {
        for (guest of checkinGuests) {
            if (checkIfShouldDisableArrival(guest[STATUS])) {
                setDisableArrivalForCheckedIn(true);
            }
            break;
        }
    }
};

export const getDocumentTypeFromChar = (codeFromDocument, guestIsHrv) => {
    let searchableCode = null;
    if (codeFromDocument) {
        const docCode = codeFromDocument.charAt(0);
        switch (docCode) {
            case 'I':
                if (guestIsHrv) {
                    searchableCode = '051'; // OSOBNA ISKAZNICA - 051
                } else {
                    searchableCode = '027'; // STRANA ISKAZNICA - 027
                }
                break;
            case 'P':
                if (guestIsHrv) {
                    searchableCode = '028'; // PUTOVNICA - 028
                } else {
                    searchableCode = '002'; // STRANA PUTOVNICA - 002
                }
                break;
            default:
                searchableCode = '039';
        }
        return searchableCode;
    }
};

const gbrValues = ['GBD', 'GBN', 'GBO', 'GBP', 'GBS'];
export const checkIsoCountry = countryCodeFromScan => {
    if (countryCodeFromScan === 'D') return 'DEU';
    if (countryCodeFromScan === 'SI') return 'SVN';
    if (gbrValues.includes(countryCodeFromScan)) return 'GBR';
    return countryCodeFromScan;
};

export const getAccomodationData = (evAccount, tourist) => {
    const accomodationUnitData = evAccount?.[ACCOMODATION_UNIT]?.find(a => a[ID] === tourist?.[FACILITY_ID]);
    const facilityName = accomodationUnitData?.[NAME]; //KUĆICA

    const facility = evAccount?.[ACCOMODATION_OBJECT]?.find(a => a[FACILITY_ID] === tourist?.[FACILITY_ID]);
    const accomodationUnit = facility?.[NAME_CODE]; //KREVETIĆ

    return {facilityName, accomodationUnit};
};

export const getTouristDates = tourist => {
    const touristStayFrom = getDateFromAspNetFormatToFormatted(tourist?.[DATE_TIME_OF_ARRIVAL]);
    const touristForseenStayUntil = getDateFromAspNetFormatToFormatted(tourist?.[FORESEEN_STAY_UNTIL_FIELD]);
    return {touristStayFrom, touristForseenStayUntil};
};

export const importTouristsToGuests = async (tourists, evAccount, eVisitorStaticData) => {
    const importedTourists = [];
    for (let tourist of tourists) {
        const importedTourist = await importTouristToGuest(tourist, evAccount, eVisitorStaticData, false);
        if (importedTourist) {
            importedTourists.push(importedTourist);
        }
    }
    return importedTourists;
};

export const importTouristToGuest = async (tourist, evAccount, eVisitorStaticData, isNewGuest) => {
    const countries = eVisitorStaticData?.[COUNTRY_DATA];
    const citiesSettlements = eVisitorStaticData?.[SETTLEMENTS_DATA];
    const documentTypes = eVisitorStaticData?.[DOCUMENT_TYPE_DATA];
    try {
        if (countries && citiesSettlements && documentTypes) {
            const mappedGuest = mapTouristToGuest(tourist, countries, citiesSettlements, documentTypes);
            const checkin = constructFakeCheckin(tourist);
            const importedGuest = await createGuest(mappedGuest, checkin, null, true, false);
            const outGuest = extendGuestMetaData(tourist, importedGuest, evAccount, isNewGuest);
            return outGuest;
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const getZeroCheckin = async (guestData, decodeData = false, appendGuest = null) => {
    try {
        const decodedGuestData = decodeData ? JSON.parse(fromBase64(guestData)) : guestData;
        if (decodedGuestData?.[EV_ACCOUNT] && decodedGuestData?.[FACILITY]) {
            const checkinDateTimes = {
                [FORESEEN_STAY_UNTIL_FIELD]: decodedGuestData?.[content]?.[FORESEEN_STAY_UNTIL_FIELD],
                [STAY_FROM_FIELD]: decodedGuestData?.[content]?.[STAY_FROM_FIELD],
                [TIME_ESTIMATED_STAY_UNTIL_FIELD]: null,
                [TIME_STAY_FROM_FIELD]: null,
            };

            const checkinParams = {
                [NEW_CHECKIN_ID]: decodedGuestData?.[content]?.[ID],
                [FACILITY_FIELD]: decodedGuestData[FACILITY],
                evAccount: decodedGuestData[EV_ACCOUNT],
                numberOfGuests: 1,
                isNewCheckin: true,
                checkinTime: checkinDateTimes,
                [SINGLE_GUEST_CHECKIN]: true,
            };
            const checkin = await initCheckin(checkinParams);
            if (appendGuest) {
                checkin[guests] = [appendGuest];
            }
            return checkin;
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

const constructFakeCheckin = tourist => {
    const checkin = initEmptyCheckinStruct();
    checkin[id] = tourist?.[ID];
    checkin[STAY_FROM_FIELD] = getDateFromAspNetFormatToEvisitor(tourist?.[DATE_TIME_OF_ARRIVAL]);
    checkin[FORESEEN_STAY_UNTIL_FIELD] = getDateFromAspNetFormatToEvisitor(tourist?.[DATE_TIME_OF_DEPARTURE]);
    checkin[TIME_STAY_FROM_FIELD] = getTimeFromAspNetFormatToEvisitor(tourist?.[DATE_TIME_OF_DEPARTURE]);
    return checkin;
};

const extendGuestMetaData = (tourist, guest, evAccount, isNewGuest) => {
    const touristId = isNewGuest ? uuidv4() : tourist?.[ID];
    guest[id] = touristId;
    guest[content][ID] = touristId;
    if (isNewGuest) {
        if (!guest[content].hasOwnProperty(ADDITIONAL_FIELDS)) {
            guest[content][ADDITIONAL_FIELDS] = {};
        }
        guest[content][ADDITIONAL_FIELDS][CHECKIN_ID] = touristId;
    }
    if (!guest.hasOwnProperty(ADDITIONAL_FIELDS)) {
        guest[ADDITIONAL_FIELDS] = {};
    }
    const accomodation = getTouristFacility(tourist, evAccount);
    if (accomodation) {
        guest[ADDITIONAL_FIELDS][FACILITY_FIELD] = {
            ...accomodation,
        };
        guest[content][FACILITY] = accomodation?.[FACILITY_CODE];
    }
    if (evAccount) {
        guest[ADDITIONAL_FIELDS][EVISITOR] = {
            [NAME]: evAccount?.[NAME],
            [PIN_ID]: evAccount?.[PIN_ID],
        };
    }
    guest[ADDITIONAL_FIELDS][EV_IMPORTED] = true;
    guest[STATUS] = tourist?.[TOURIST_CHECKOUT_OUT] ? CHECKED_OUT_STATUS : CHECKED_IN_STATUS;
    guest[UPDATED_STATUS] = false;
    return guest;
};

const initEmptyCheckinStruct = () => {
    return {
        [ADDITIONAL_FIELDS]: {
            [FACILITY_FIELD]: {},
        },
    };
};

export const prepareGuestData = guestData => {
    try {
        const checkinId = guestData?.[ADDITIONAL_FIELDS]?.[CHECKIN_ID];
        const guestDataCopy = JSON.parse(JSON.stringify(guestData));
        const guestStatus = guestDataCopy[STATUS];
        const guestUpdatedStatus = guestDataCopy[UPDATED_STATUS];
        const guestId = guestDataCopy[ID];
        const guestNote = guestDataCopy?.[ADDITIONAL_FIELDS]?.[GUEST_NOTE_FIELD] ?? '';
        delete guestDataCopy[ADDITIONAL_FIELDS];
        delete guestDataCopy[POSITION];
        delete guestDataCopy[STATUS];
        delete guestDataCopy[UPDATED_STATUS];
        const guestContent = {
            ...guestDataCopy,
            [ADDITIONAL_FIELDS]: {
                [GUEST_NOTE_FIELD]: guestNote,
            },
        };

        return {
            [id]: guestId,
            [CHECKIN_ID]: checkinId,
            [UPDATED_STATUS]: guestUpdatedStatus,
            [STATUS]: guestStatus,
            [content]: guestContent,
        };
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const createGuestTemplate = async (facility, evAccount, day) => {
    const selectedDay = day ? day : getNowDayDate();
    try {
        const checkinTemplate = initEmptyCheckinStruct();
        checkinTemplate[id] = 0;
        checkinTemplate[ADDITIONAL_FIELDS][FACILITY_FIELD] = {
            ...facility,
        };
        checkinTemplate[FORESEEN_STAY_UNTIL_FIELD] = await getNightsStayUntilFromDay(selectedDay);
        checkinTemplate[STAY_FROM_FIELD] = getDateStringFromCalDateString(selectedDay);
        const createdGuest = await createGuest(null, checkinTemplate, null, true, true);
        return toBase64(
            JSON.stringify({
                [content]: createdGuest[content],
                [EV_ACCOUNT]: evAccount,
                [FACILITY]: facility,
            })
        );
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const prepareGuestCheckinData = (guest, facility, evAccount) => {
    return {
        [content]: {
            ...guest,
        },
        [FACILITY]: facility,
        [EV_ACCOUNT]: evAccount,
    };
};

export const prepareCalendarImportTourist = async (tourist, evAccount, eVisitorStaticData, facilityData, day) => {
    try {
        const importedTourist = await importTouristToGuest(tourist, evAccount, eVisitorStaticData, true);
        const decodedFacilityData = JSON.parse(fromBase64(facilityData));
        const selectedDay = day ? day : getNowDayDate();
        importedTourist[content][FORESEEN_STAY_UNTIL_FIELD] = await getNightsStayUntilFromDay(selectedDay);
        importedTourist[content][STAY_FROM_FIELD] = getDateStringFromCalDateString(selectedDay);
        importedTourist[content][FACILITY] = decodedFacilityData?.[FACILITY]?.[FACILITY_CODE];

        let extendedTourist = importedTourist?.[content];
        const paymentCategories = await getPaymentCategoriesFromEvAcount(decodedFacilityData[FACILITY], evAccount);
        if (paymentCategories) {
            extendedTourist = extendTouristPaymentCategory(extendedTourist, paymentCategories);
        }

        const guestAndFacilityData = {
            [content]: extendedTourist,
            [EV_ACCOUNT]: decodedFacilityData?.[EV_ACCOUNT],
            [FACILITY]: decodedFacilityData?.[FACILITY],
        };
        return toBase64(JSON.stringify(guestAndFacilityData));
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const prepareFacilityData = (evAccount, facility) => {
    try {
        const facilityData = {
            [EV_ACCOUNT]: evAccount,
            [FACILITY]: facility,
        };
        return toBase64(JSON.stringify(facilityData));
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const prepareGuestForEdit = (guestData, facility, evAccount) => {
    try {
        return toBase64(
            JSON.stringify({
                [content]: guestData,
                [EV_ACCOUNT]: evAccount,
                [FACILITY]: facility,
            })
        );
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const restoreGuest = (guestData, guestId, checkinId) => {
    try {
        const decodedGuestData = JSON.parse(fromBase64(guestData));
        const guestNote = decodedGuestData?.[content]?.[ADDITIONAL_FIELDS]?.[GUEST_NOTE_FIELD] ?? '';
        delete decodedGuestData[content][ADDITIONAL_FIELDS];
        decodedGuestData[content][ADDITIONAL_FIELDS] = {[GUEST_NOTE_FIELD]: guestNote};
        const newGuest = {
            [id]: guestId,
            [CHECKIN_ID]: checkinId,
            [STATUS]: decodedGuestData?.[content]?.[STATUS],
            [UPDATED_STATUS]: decodedGuestData?.[content]?.[UPDATED_STATUS],
            [content]: {...decodedGuestData?.[content]},
        };
        delete newGuest[content][STATUS];
        delete newGuest[content][POSITION];
        delete newGuest[content][UPDATED_STATUS];
        return newGuest;
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const getContentFromExistingGuest = (guest, existingGuest) => {
    if (guest && isGuestEmpty(guest?.[content])) {
        if (existingGuest && !isGuestEmpty(existingGuest?.[content])) {
            guest = {...existingGuest};
        }
    }
    return guest;
};

// Invoice utils
export const getGuestRange = data =>
    `${getFormattedDateFromDateString(data?.[STAY_FROM_FIELD])} - ${getFormattedDateFromDateString(
        data?.[FORESEEN_STAY_UNTIL_FIELD]
    )}`;

export const getInvoiceDayRange = data =>
    `${getFormattedDateFromDateString(data?.[INVOICE_FORM_STAY_FROM])} - ${getFormattedDateFromDateString(
        data?.[INVOICE_FORM_STAY_TO]
    )}`;

export const extendGuestPaymentCategory = (guest, paymentCategories) => {
    const guestBirthDate = guest[content]?.[DATE_OF_BIRTH_FIELD];
    if (!guest[content]?.[PAYMENT_CATEGORY] && guestBirthDate) {
        const suggestedPayCatCode = getPaymentCategory(guestBirthDate);
        const selectedCategory = paymentCategories?.find(p => p[CODE] === suggestedPayCatCode);
        if (selectedCategory) {
            guest[content][PAYMENT_CATEGORY] = selectedCategory[CODE];
        }
    }
    return guest;
};

export const extendTouristPaymentCategory = (tourist, paymentCategories) => {
    const touristBirthDate = tourist?.[DATE_OF_BIRTH_FIELD];
    if (!tourist?.[PAYMENT_CATEGORY] && touristBirthDate) {
        const suggestedPayCatCode = getPaymentCategory(touristBirthDate);
        const selectedCategory = paymentCategories?.find(p => p[CODE] === suggestedPayCatCode);
        if (selectedCategory) {
            tourist[PAYMENT_CATEGORY] = selectedCategory[CODE];
        }
    }
    return tourist;
};

export const getPaymentCategories = async (checkin, getEvisitorByPin) => {
    const pin = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN];
    const facilityId = checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[FACILITY_ID];
    const facilityCode = checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[FACILITY_CODE];
    if (pin) {
        const evAccount = await getEvisitorByPin(pin);
        if (evAccount) {
            let accUnit = null;
            if (facilityId) {
                accUnit = findById(evAccount?.[ACCOMODATION_UNIT], facilityId);
            } else if (facilityCode) {
                accUnit = findByFacilityCode(evAccount?.[ACCOMODATION_UNIT], facilityCode);
            }
            if (accUnit) {
                const paymentCategories = accUnit?.[ACCOMODATION_PAYMENT_CATEGORIES] ?? null;
                return paymentCategories;
            }
        }
    }
    return null;
};

export const getPaymentCategoriesFromEvAcount = async (facility, evAccount) => {
    const facilityId = facility?.[FACILITY_ID];
    const facilityCode = facility?.[FACILITY_CODE];
    if (evAccount) {
        let accUnit = null;
        if (facilityId) {
            accUnit = findById(evAccount?.[ACCOMODATION_UNIT], facilityId);
        } else if (facilityCode) {
            accUnit = findByFacilityCode(evAccount?.[ACCOMODATION_UNIT], facilityCode);
        }
        if (accUnit) {
            const paymentCategories = accUnit?.[ACCOMODATION_PAYMENT_CATEGORIES] ?? null;
            return paymentCategories;
        }
    }
    return null;
};

export const registerFormFields = (guest, register, setValue) => {
    Object.keys(guest[content]).map(key => {
        register(key);
        setValue(key, guest[content][key]);
        return true;
    });
};

export const setGuestMeta = (checkin, guest, register, setValue) => {
    register(CHECKIN);
    setValue(CHECKIN, checkin);
    register(CHECKIN_ID);
    setValue(CHECKIN_ID, guest[CHECKIN_ID]);
    register(id);
    setValue(id, guest[id]);
    register(STATUS);
    setValue(STATUS, guest[STATUS]);
    register(UPDATED_STATUS);
    setValue(UPDATED_STATUS, guest[UPDATED_STATUS]);
    register(GUEST_NOTE_FIELD);
    setValue(GUEST_NOTE_FIELD, guest[content]?.[ADDITIONAL_FIELDS]?.[GUEST_NOTE_FIELD] ?? '');
};

export const setCountryCity = (checkin, countries, setValue) => {
    const {preferredCountry, preferredCity} = getLastGuestCountryCity(checkin);
    if (preferredCountry) {
        const {countryResidenceCode, countryCitizenshipCode, countryBirthCode} = mapPreferredCountries(
            countries,
            preferredCountry
        );
        setValue(COUNTRY_OF_RESIDENCE, countryResidenceCode);
        setValue(CITIZENSHIP, countryCitizenshipCode);
        setValue(COUNTRY_OF_BIRTH, countryBirthCode);
    }
    if (preferredCity) {
        setValue(CITY_OF_RESIDENCE, preferredCity);
    }
};

export const mergeFetchedTourists = (tourists, fetchedTourists) => {
    return {[RECORDS]: [...tourists, ...fetchedTourists[RECORDS]]};
};

export const deleteContentAndPreserveNote = data => {
    const note = data[content]?.[ADDITIONAL_FIELDS]?.[GUEST_NOTE_FIELD];
    const stayUntil = data[content]?.[FORESEEN_STAY_UNTIL_FIELD];
    const timeStayUntil = data[content]?.[TIME_ESTIMATED_STAY_UNTIL_FIELD];
    const stayFrom = data[content]?.[STAY_FROM_FIELD];
    const timeStayFrom = data[content]?.[TIME_STAY_FROM_FIELD];
    data[content] = {};
    if (note) {
        data[content][ADDITIONAL_FIELDS] = {[GUEST_NOTE_FIELD]: note};
    }
    if (stayUntil) {
        data[content][FORESEEN_STAY_UNTIL_FIELD] = stayUntil;
    }
    if (timeStayUntil) {
        data[content][TIME_ESTIMATED_STAY_UNTIL_FIELD] = timeStayUntil;
    }
    if (stayFrom) {
        data[content][STAY_FROM_FIELD] = stayFrom;
    }
    if (timeStayFrom) {
        data[content][TIME_STAY_FROM_FIELD] = timeStayFrom;
    }
};

export const cleanGuestForCheckin = data => {
    const guest = deepCopy(data);
    delete guest[ADDITIONAL_FIELDS];
    return guest;
};

const ignoredRootFields = [
    id,
    CHECKIN_ID,
    INITIAL_GUEST_FIELD,
    GUEST_NOTE_FIELD,
    CHECKIN,
    status,
    UPDATED_STATUS,
    ADDITIONAL_FIELDS,
];

const ignoredMetaFields = [GUEST_NOTE_FIELD, CHECKIN_ID, SINGLE_GUEST_CHECKIN];

export const compareInitialAndEditedGuest = (initial, edited) => {
    if (initial && edited) {
        // check without ADDITIONAL_FIELDS
        const areEqual = isEqual(omit(initial, ignoredRootFields), omit(edited, ignoredRootFields));
        if (areEqual) {
            const areMetaEqual = isEqual(
                omit(initial?.[ADDITIONAL_FIELDS], ignoredMetaFields),
                omit(edited?.[ADDITIONAL_FIELDS], ignoredMetaFields)
            );
            return areMetaEqual;
        }
    }
    return false;
};

export const getSwipeButtonType = guest => {
    const status = {[SWIPE_VISIBLE]: false, [SWIPE_CHECKIN_ACTION]: false, [SWIPE_CHECKOUT_ACTION]: false};
    if (guest) {
        if (guest?.[STATUS] === CHECKED_IN_STATUS) {
            status[SWIPE_VISIBLE] = true;
            status[SWIPE_CHECKOUT_ACTION] = true;
            if (guest?.[UPDATED_STATUS]) {
                status[SWIPE_VISIBLE] = true;
                status[SWIPE_CHECKOUT_ACTION] = false;
                status[SWIPE_CHECKIN_ACTION] = true;
            }
        } else if (guest?.[STATUS] === NOT_CHECKED_IN_STATUS) {
            status[SWIPE_VISIBLE] = true;
            status[SWIPE_CHECKIN_ACTION] = true;
        } else if (guest?.[STATUS] === CHECKED_OUT_STATUS) {
            status[SWIPE_VISIBLE] = false;
        }
    }
    return status;
};

export const updateGuestCheckedInStatus = async (localGuest, remoteGuest, updateGuest) => {
    let isGuestCheckoutStatusUpdated = false;
    const localGuestStatus = localGuest[status];
    if ([CHECKED_IN_STATUS, CHECKED_OUT_STATUS].includes(localGuestStatus)) {
        const remoteGuestStatus = remoteGuest[CHECKOUT_OUT] ? CHECKED_OUT_STATUS : CHECKED_IN_STATUS;
        if (localGuestStatus !== remoteGuestStatus) {
            try {
                localGuest[status] = remoteGuestStatus;
                localGuest[content][STAY_FROM_FIELD] = getDateFromAspNetFormatToEvisitor(remoteGuest[STAY_FROM_FIELD]);
                localGuest[content][TIME_STAY_FROM_FIELD] = getTimeFromAspNetFormatToEvisitor(
                    remoteGuest[TIME_STAY_FROM_FIELD]
                );
                localGuest[content][FORESEEN_STAY_UNTIL_FIELD] = getDateFromAspNetFormatToEvisitor(
                    remoteGuest[DATE_TIME_OF_DEPARTURE]
                );
                localGuest[content][TIME_ESTIMATED_STAY_UNTIL_FIELD] = getTimeFromAspNetFormatToEvisitor(
                    remoteGuest[DATE_TIME_OF_DEPARTURE]
                );
                await updateGuest(localGuest, true);
                isGuestCheckoutStatusUpdated = true;
            } catch (e) {
                console.log(e);
            }
        }
    }
    return isGuestCheckoutStatusUpdated;
};

export const updateGuestCheckedOutStatus = async (localGuest, remoteGuest, updateGuestStatus) => {
    let isGuestCheckoutStatusUpdated = false;
    try {
        localGuest[status] = CHECKED_OUT_STATUS;
        localGuest[content][STAY_FROM_FIELD] = getDateFromAspNetFormatToEvisitor(remoteGuest[STAY_FROM_FIELD]);
        localGuest[content][TIME_STAY_FROM_FIELD] = getTimeFromAspNetFormatToEvisitor(
            remoteGuest[TIME_STAY_FROM_FIELD]
        );
        localGuest[content][FORESEEN_STAY_UNTIL_FIELD] = getDateFromAspNetFormatToEvisitor(
            remoteGuest[DATE_TIME_OF_DEPARTURE]
        );
        localGuest[content][TIME_ESTIMATED_STAY_UNTIL_FIELD] = getTimeFromAspNetFormatToEvisitor(
            remoteGuest[DATE_TIME_OF_DEPARTURE]
        );
        await updateGuestStatus(localGuest, CHECKED_OUT_STATUS, false);
        isGuestCheckoutStatusUpdated = true;
    } catch (e) {
        console.log(e);
    }
    return isGuestCheckoutStatusUpdated;
};

export const getListOfGuests = (checkin, countries, lang) => {
    const guestList = [];
    if (checkin) {
        const checkinGuests = checkin?.[guests];
        if (isNotEmpty(checkinGuests)) {
            const countryName = isCroatianLang(lang) ? NAME_NATIONAL_SHORT : COUNTRY_NAME;
            for (let checkinGuest of checkinGuests) {
                const guestClient = {};
                const guestName = checkinGuest?.[content]?.[TOURIST_NAME];
                const guestSurname = checkinGuest?.[content]?.[TOURIST_SURNAME];
                if (guestName && guestSurname) {
                    guestClient[NAME] = guestName + ' ' + guestSurname;
                }
                const guestCountry = checkinGuest?.[content]?.[COUNTRY_OF_RESIDENCE];
                const country = mapCountryCodeToCountry(guestCountry, countries);
                if (country) {
                    guestClient[COUNTRY_OF_RESIDENCE] = country[countryName];
                }
                const guestCity = checkinGuest?.[content]?.[CITY_OF_RESIDENCE];
                if (guestCity && guestCity !== NEPOZNATO) {
                    guestClient[CITY_OF_RESIDENCE] = guestCity;
                }
                guestClient[ADDRESS] = `${guestClient[CITY_OF_RESIDENCE] ? guestCity + ', ' : ''}${
                    guestClient[COUNTRY_OF_RESIDENCE]
                }`;

                guestList.push(guestClient);
            }
        }
    }
    return guestList;
};

export const syncTouristCheckoutStatus = async (
    eVisitorPinId,
    guest,
    updateGuestStatus,
    forceCheck = false,
    setOpenProgress = null
) => {
    let isGuestCheckoutStatusUpdated = false;
    if (getForCheckout(guest[status])) {
        const executeSyncCheck =
            forceCheck || isGuestDateInPast(guest[content]?.[FORESEEN_STAY_UNTIL_FIELD]) ? true : false;
        if (eVisitorPinId && executeSyncCheck) {
            try {
                setOpenProgress && setOpenProgress(true);
                const touristRes = await fetchTourist(eVisitorPinId, 1, 1, guest[id]);
                if (touristRes) {
                    const touristData = touristRes[RECORDS]?.[0];
                    if (touristData) {
                        const remoteGuestStatus = touristData[CHECKOUT_OUT] ? CHECKED_OUT_STATUS : CHECKED_IN_STATUS;
                        if (remoteGuestStatus === CHECKED_OUT_STATUS) {
                            isGuestCheckoutStatusUpdated = await updateGuestCheckedOutStatus(
                                guest,
                                touristData,
                                updateGuestStatus
                            );
                        }
                    }
                }
            } catch (e) {
                console.log(e);
            } finally {
                setOpenProgress && setOpenProgress(false);
            }
        }
        return isGuestCheckoutStatusUpdated;
    }
};

export const syncTouristsCheckoutStatus = async (eVisitorPinId, checkinGuests, updateGuestStatus, setOpenProgress) => {
    let isAnyGuestCheckoutStatusUpdated = false;
    setOpenProgress(true);
    for (let guest of checkinGuests) {
        const isCurrentGuestCheckoutStatusUpdated = await syncTouristCheckoutStatus(
            eVisitorPinId,
            guest,
            updateGuestStatus,
            false,
            null
        );
        if (isCurrentGuestCheckoutStatusUpdated) isAnyGuestCheckoutStatusUpdated = true;
    }
    setOpenProgress(false);
    return isAnyGuestCheckoutStatusUpdated;
};

export const checkIfGuestIsNotEmpty = guestData => (guestData?.[content]?.[TOURIST_NAME] ? true : false);
