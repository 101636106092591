import {DrawerItem, DrawerItemList, createDrawerNavigator} from '@react-navigation/drawer';
import {NavigationContainer, useNavigation} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    ImageBackground,
    Platform,
    Pressable,
    SafeAreaView,
    ScrollView,
    Text,
    View,
    useWindowDimensions,
} from 'react-native';
import {Button, Icon, useTheme} from 'react-native-elements';
import useSWR from 'swr';
import {
    ACCOUNT,
    APP_VERSION,
    BEST_BEFORE,
    BUY_CREDITS,
    CALENDAR,
    CHECKINS,
    CHECKIN_LABEL,
    CONTACT,
    CREDITS,
    EVACCOUNTS,
    FINANCES,
    FINANCE_PAYMENT,
    GUESTS,
    INVOICE,
    INVOICES,
    LOGOUT,
    PACKAGES,
    RESERVATIONS,
    RESERVATION_LABEL,
    SCANNER_LABEL,
    SETTINGS,
    TOOLBAR_GUEST_TITLE,
    UNLIMITED,
    data_credits,
    data_credits_best_before,
} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import AccountScreen from '../screens/AccountScreen';
import CalendarScreen from '../screens/CalendarScreen';
import CheckinScreen from '../screens/CheckinScreen';
import CheckinsScreen from '../screens/CheckinsScreen';
import ContactScreen from '../screens/ContactScreen';
import DocumentScreen from '../screens/DocumentScreen';
import EVAccountsScreen from '../screens/EVAccountsScreen';
import GuestScreen from '../screens/GuestScreen';
import InvoiceScreen from '../screens/InvoiceScreen';
import InvoicesScreen from '../screens/InvoicesScreen';
import LoginScreen from '../screens/LoginScreen';
import PackagesScreen from '../screens/PackagesScreen';
import ReservationsScreen from '../screens/ReservationsScreen.js';
import ScannerScreen from '../screens/ScannerScreen';
import SelfCheckinScreen from '../screens/SelfCheckinScreen';
import SettingsScreen from '../screens/SettingsScreen';
import TouristsScreen from '../screens/TouristsScreen';
import globalStyle from '../theme/globalStyle';
import {getFormattedDateFromSqlOrUnlimited} from '../utils/dateHelper';
import {getIsRecepcija, getUserEmail} from '../utils/userUtils';
import {logoSvg} from './drawer.logo.svg.js';
import SplashScreen from '../screens/SplashScreen.js';
import FinancesScreen from '../screens/FinancesScreen.js';
import FinancePaymentScreen from '../screens/FinancePaymentScreen.js';

const BG_IMAGE = require('../../assets/images/backgrounds/drawer_bg.png');

const trueIfAndroid = Platform.OS === 'android' ? true : false;
const falseIfAndroid = Platform.OS === 'android' ? false : true;
const trueIfWeb = Platform.OS === 'web' ? true : false;

const config = {
    screens: {
        Root: {
            screens: {
                Checkins: 'checkins',
                Calendar: 'calendar',
                Tourists: 'guests',
                EVAccounts: 'evaccounts',
                Finances: 'finances',
                FinancePayment: 'financepayment',
                Account: 'account',
                Settings: 'settings',
                Contact: 'contact',
                Invoices: 'invoices',
            },
        },
        Checkin: {
            path: null,
            screens: {
                CheckinScreen: 'checkin',
            },
        },
        Splash: 'splash',
        Login: 'login',
        Referral: 'referral',
        Guest: 'guest',
        Scanner: 'scan',
        SelfCheckin: 'self-checkin',
        DocumentScreen: 'document',
        TouristsChooser: 'tourists-chooser',
        Invoice: 'invoice',
        Packages: 'packages',
    },
};

const defaultLinking = {
    prefixes: [],
    config: {
        ...config,
    },
};

export default function Navigation() {
    const [isRecepcija, setIsRecepcija] = useState(false);
    const [links, setLinks] = useState(defaultLinking);
    const {loggedIn} = useContext(DataContext);

    useEffect(() => {
        if (loggedIn) {
            getIsRecepcija().then(flag => setIsRecepcija(flag));
        }
    }, [loggedIn]);

    useEffect(() => {
        if (isRecepcija) {
            const modifiedConfig = defaultLinking;
            delete modifiedConfig.config.screens.Root.screens.Checkins;
            modifiedConfig.config.screens.Root.screens.Reservations = 'reservations';
            setLinks({...modifiedConfig});
        } else {
            setLinks(defaultLinking);
        }
    }, [isRecepcija]);

    return (
        <NavigationContainer linking={links} isRecepcija={isRecepcija}>
            <RootNavigator />
            <LoginComponent />
        </NavigationContainer>
    );
}

const LoginComponent = () => {
    const navigation = useNavigation();
    const {loggedIn, startupCompleted} = useContext(DataContext);

    useEffect(() => {
        if (!loggedIn && startupCompleted) {
            const currentRoute = navigation.getCurrentRoute();
            if (currentRoute?.name === 'SelfCheckin') {
                // maybe some others routes should also not be redirected to Login
            } else {
                navigation.navigate('Login');
            }
        }
    }, [loggedIn, startupCompleted]);
};

const Stack = createNativeStackNavigator();

const CheckinNavigator = () => {
    const {theme} = useTheme();
    const {t} = useTranslation();
    const {loggedIn} = useContext(DataContext);
    const [isRecepcija, setIsRecepcija] = useState(false);

    useEffect(() => {
        if (loggedIn) {
            getIsRecepcija().then(flag => setIsRecepcija(flag));
        }
    }, [loggedIn]);

    return (
        <Stack.Navigator
            options={{
                headerShown: true,
                title: t(CHECKIN_LABEL),
            }}>
            <Stack.Screen
                name="CheckinScreen"
                component={CheckinScreen}
                options={{
                    headerShown: trueIfAndroid,
                    title: isRecepcija ? t(RESERVATION_LABEL) : t(CHECKIN_LABEL),
                    headerTitleStyle: {
                        fontFamily: theme.fontFamilyMedium,
                        fontWeight: '500',
                        fontSize: 16,
                    },
                }}
            />
        </Stack.Navigator>
    );
};

const RootNavigator = ({navigation}) => {
    const {theme} = useTheme();
    const width = useWindowDimensions().width;
    const isMobile = width < theme.tabletBP;
    const {t} = useTranslation();
    const {loggedIn} = useContext(DataContext);
    const [isRecepcija, setIsRecepcija] = useState(false);

    useEffect(() => {
        if (loggedIn) {
            getIsRecepcija().then(flag => setIsRecepcija(flag));
        }
    }, [loggedIn]);

    return (
        <Stack.Navigator
            screenOptions={{
                headerTintColor: theme.colors.textColor,
                headerStyle: {
                    backgroundColor: theme.colors.white,
                    borderBottomWidth: 0,
                    borderColor: 'transparent',
                    shadowColor: theme.colors.primaryDark,
                    shadowOpacity: 0.15,
                    shadowOffset: {width: 0, height: 0},
                    shadowRadius: 5,
                    elevation: 5,
                    //height: isMobile ? 50 : 64,
                },
                headerLeftContainerStyle: !isMobile && {
                    opacity: 0,
                },
                headerTitleContainerStyle: isMobile ? {marginLeft: 0} : {marginLeft: -35},
                headerTitleStyle: {
                    fontFamily: theme.fontFamilyMedium,
                    fontSize: 16,
                },
                headerTitleAlign: 'left',
            }}>
            {loggedIn && <Stack.Screen name="Root" component={DrawerNavigator} options={{headerShown: false}} />}
            <Stack.Screen
                name="Splash"
                screenOptions={{presentation: 'modal'}}
                options={{headerShown: false, gestureEnabled: false}}
                component={SplashScreen}
            />
            <Stack.Screen
                name="Login"
                screenOptions={{presentation: 'modal'}}
                options={{headerShown: false, gestureEnabled: false}}
                component={LoginScreen}
            />
            <Stack.Screen
                name="Checkin"
                options={{
                    headerShown: falseIfAndroid,
                    title: isRecepcija ? t(RESERVATION_LABEL) : t(CHECKIN_LABEL),
                    headerBackTitleVisible: false,
                }}
                component={CheckinNavigator}
            />
            <Stack.Screen
                name="Scanner"
                screenOptions={{presentation: 'modal'}}
                options={{headerShown: true, title: t(SCANNER_LABEL)}}
                component={ScannerScreen}
            />
            <Stack.Screen
                name="Referral"
                screenOptions={{presentation: 'modal'}}
                options={{headerShown: false}}
                component={LoginScreen}
            />
            <Stack.Screen
                name="TouristsChooser"
                screenOptions={{presentation: 'modal', headerTitleAlign: 'left'}}
                options={{
                    headerShown: true,
                    title: t(TOOLBAR_GUEST_TITLE),
                    headerBackTitleVisible: false,
                }}
                component={TouristsScreen}
            />
            <Stack.Screen
                name="Invoice"
                screenOptions={{presentation: 'modal'}}
                options={{headerShown: true, title: t(INVOICE), headerBackTitleVisible: false}}
                component={InvoiceScreen}
            />
            <Stack.Screen
                name="Calendar"
                screenOptions={{presentation: 'modal'}}
                options={{headerShown: true}}
                component={CalendarScreen}
            />
            <Stack.Screen
                name="Invoices"
                screenOptions={{presentation: 'modal'}}
                options={{headerShown: true, title: t(INVOICES)}}
                component={InvoicesScreen}
            />
            <Stack.Screen
                name="EVAccounts"
                screenOptions={{presentation: 'modal'}}
                options={{headerShown: true}}
                component={EVAccountsScreen}
            />
            <Stack.Screen
                name="Finances"
                screenOptions={{presentation: 'modal'}}
                options={{headerShown: true}}
                component={FinancesScreen}
            />
            <Stack.Screen
                name="FinancePayment"
                screenOptions={{presentation: 'modal'}}
                options={{headerShown: true, title: t(FINANCE_PAYMENT), headerBackTitleVisible: false}}
                component={FinancePaymentScreen}
            />
            <Stack.Screen
                name="Contact"
                screenOptions={{presentation: 'modal'}}
                options={{headerShown: true}}
                component={ContactScreen}
            />
            <Stack.Screen
                name="Settings"
                screenOptions={{presentation: 'modal'}}
                options={{headerShown: true}}
                component={SettingsScreen}
            />
            <Stack.Screen
                name="Guest"
                screenOptions={{presentation: 'modal'}}
                options={{
                    headerShown: true,
                    title: t(TOOLBAR_GUEST_TITLE),
                    headerBackTitleVisible: false,
                }}
                component={GuestScreen}
            />
            <Stack.Screen
                name="SelfCheckin"
                options={{headerShown: false, title: 'Self check-in'}}
                component={SelfCheckinScreen}
            />
            <Stack.Screen name="Document" options={{headerShown: true}} component={DocumentScreen} />
            <Stack.Screen name="Account" options={{headerShown: true}} component={AccountScreen} />
            <Stack.Screen
                name="Packages"
                options={{headerShown: true, title: t(PACKAGES), headerBackTitleVisible: false}}
                component={PackagesScreen}
            />
        </Stack.Navigator>
    );
};

const Drawer = createDrawerNavigator();
const DrawerNavigator = ({navigation}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const [isRecepcija, setIsRecepcija] = useState(false);
    const [isStartCompleted, setIsStartCompleted] = useState(false);
    const {loggedIn} = useContext(DataContext);
    const width = useWindowDimensions().width;
    const isMobile = width < theme.tabletBP;

    useEffect(() => {
        if (loggedIn) {
            getIsRecepcija()
                .then(flag => {
                    setIsRecepcija(flag);
                })
                .then(_ => {
                    setIsStartCompleted(true);
                });
        }
    }, [loggedIn]);

    if (!loggedIn || !isStartCompleted) {
        return null;
    }

    const initialRoute = isRecepcija ? 'Reservations' : 'Checkins';

    return (
        <Drawer.Navigator
            drawerContent={props => (
                <CustomDrawerContent theme={theme} global={global} t={t} isMobile={isMobile} width={width} {...props} />
            )}
            initialRouteName={initialRoute}
            /*useLegacyImplementation={trueIfWeb}*/
            screenOptions={{
                drawerType: isMobile ? 'front' : 'permanent',
                drawerStyle: {
                    shadowColor: 'rgb(95, 80, 178)',
                    shadowOpacity: 0.2,
                    shadowOffset: {width: 0, height: 0},
                    shadowRadius: 10,
                    elevation: 5,
                    borderRightWidth: 0,
                    width: 280,
                },
                drawerItemStyle: {
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 0,
                    marginBottom: 0,
                    borderRadius: 0,
                },
                drawerLabelStyle: {fontSize: 14, fontFamily: theme.fontFamilyMedium, marginLeft: -18},
                drawerActiveBackgroundColor: 'rgba(138, 134, 202, 0.08)',
                drawerInactiveBackgroundColor: 'transparent',
                drawerActiveTintColor: theme.colors.primary,
                drawerInactiveTintColor: theme.colors.textColor,
                headerTintColor: theme.colors.textColor,
                headerStyle: {
                    backgroundColor: theme.colors.white,
                    borderBottomWidth: 0,
                    borderColor: 'transparent',
                    shadowColor: theme.colors.primaryDark,
                    shadowOpacity: 0.15,
                    shadowOffset: {width: 0, height: 0},
                    shadowRadius: 10,
                    elevation: 5,
                    //height: isMobile ? 50 : 64,
                },
                headerLeftContainerStyle: !isMobile && {
                    opacity: 0,
                },
                headerTitleContainerStyle: isMobile ? {marginLeft: 0} : {marginLeft: -35},
                headerTitleStyle: {
                    fontFamily: theme.fontFamilyMedium,
                    fontWeight: '500',
                    fontSize: 16,
                },
                headerTitleAlign: 'left',
            }}>
            {!isRecepcija && (
                <Drawer.Screen
                    name="Checkins"
                    component={CheckinsScreen}
                    options={({navigation}) => ({
                        title: t(CHECKINS),
                        drawerIcon: ({color, focused}) => (
                            <Icon
                                name={focused ? 'home' : 'home-outline'}
                                type="ionicon"
                                size={18}
                                color={color}
                                style={{width: 20, marginLeft: 10}}
                            />
                        ),
                    })}
                />
            )}
            {isRecepcija && (
                <Drawer.Screen
                    name="Reservations"
                    component={ReservationsScreen}
                    options={({navigation}) => ({
                        title: t(RESERVATIONS),
                        drawerIcon: ({color, focused}) => (
                            <Icon
                                name={focused ? 'home' : 'home-outline'}
                                type="ionicon"
                                size={18}
                                color={color}
                                style={{width: 20, marginLeft: 10}}
                            />
                        ),
                    })}
                />
            )}
            {!isRecepcija && (
                <Drawer.Screen
                    name="Calendar"
                    component={CalendarScreen}
                    options={({navigation}) => ({
                        title: t(CALENDAR),
                        drawerIcon: ({color, focused}) => (
                            <Icon
                                name={focused ? 'calendar' : 'calendar-outline'}
                                type="ionicon"
                                size={18}
                                color={color}
                                style={{width: 20, marginLeft: 10}}
                            />
                        ),
                    })}
                />
            )}
            <Drawer.Screen
                name="Tourists"
                component={TouristsScreen}
                options={({navigation}) => ({
                    title: t(GUESTS),
                    drawerIcon: ({color, focused}) => (
                        <Icon
                            name={focused ? 'people' : 'people-outline'}
                            type="ionicon"
                            size={20}
                            color={color}
                            style={{width: 20, marginLeft: 10}}
                        />
                    ),
                })}
            />
            {!isRecepcija && (
                <Drawer.Screen
                    name="Invoices"
                    component={InvoicesScreen}
                    options={({navigation}) => ({
                        title: t(INVOICES),
                        drawerIcon: ({color, focused}) => (
                            <Icon
                                name={focused ? 'receipt' : 'receipt-outline'}
                                type="ionicon"
                                size={18}
                                color={color}
                                style={{width: 20, marginLeft: 10}}
                            />
                        ),
                    })}
                />
            )}
            <Drawer.Screen
                name="EVAccounts"
                component={EVAccountsScreen}
                options={({navigation}) => ({
                    title: t(EVACCOUNTS),
                    drawerIcon: ({color, focused}) => (
                        <Icon
                            name={focused ? 'swap-horizontal' : 'swap-horizontal-outline'}
                            type="ionicon"
                            size={20}
                            color={color}
                            style={{width: 20, marginLeft: 10}}
                        />
                    ),
                })}
            />
            <Drawer.Screen
                name="Finances"
                component={FinancesScreen}
                options={({navigation}) => ({
                    title: t(FINANCES),
                    drawerIcon: ({color, focused}) => (
                        <Icon
                            name={focused ? 'cash' : 'cash-outline'}
                            type="ionicon"
                            size={20}
                            color={color}
                            style={{width: 20, marginLeft: 10}}
                        />
                    ),
                })}
            />
            <Drawer.Screen
                name="Settings"
                component={SettingsScreen}
                options={({navigation}) => ({
                    title: t(SETTINGS),
                    drawerIcon: ({color, focused}) => (
                        <Icon
                            name={focused ? 'build' : 'build-outline'}
                            type="ionicon"
                            size={20}
                            color={color}
                            style={{width: 20, marginLeft: 10}}
                        />
                    ),
                })}
            />
            <Drawer.Screen
                name="Contact"
                component={ContactScreen}
                options={({navigation}) => ({
                    title: t(CONTACT),
                    drawerIcon: ({color, focused}) => (
                        <Icon
                            name={focused ? 'call' : 'call-outline'}
                            type="ionicon"
                            size={20}
                            color={color}
                            style={{width: 20, marginLeft: 10}}
                        />
                    ),
                })}
            />
            <Drawer.Screen
                name="Account"
                component={AccountScreen}
                options={({navigation}) => ({
                    title: t(ACCOUNT),
                    drawerIcon: ({color, focused}) => (
                        <Icon
                            name={focused ? 'person' : 'person-outline'}
                            type="ionicon"
                            size={18}
                            color={color}
                            style={{width: 20, marginLeft: 10}}
                        />
                    ),
                    drawerItemStyle: {
                        display: 'none',
                    },
                })}
            />
        </Drawer.Navigator>
    );
};

const CustomDrawerContent = props => {
    const {theme, global, t, isMobile, width} = props;
    const {deleteAllUserData, loggedIn} = useContext(DataContext);
    const [user, setUser] = useState(null);
    const {data, mutate} = useSWR('refreshUserCredits');

    const onLogout = useCallback(async _ => {
        await deleteAllUserData();
        props.navigation.navigate('Login');
    }, []);

    useEffect(() => {
        if (loggedIn) {
            if (!user) {
                getUserEmail().then(data => data && setUser(data));
                const isTablet = width >= 500 && width < theme.desktopBP;
                if (trueIfWeb || isTablet) mutate();
            }
        } else {
            if (user) {
                setUser(null);
            }
        }
    }, [loggedIn]);

    return (
        <ImageBackground
            source={BG_IMAGE}
            resizeMode="cover"
            style={{
                flex: 1,
            }}>
            <SafeAreaView style={{flex: 1}}>
                <View
                    style={{
                        height: isMobile ? 56 : 65,
                        paddingLeft: 17,
                        marginHorizontal: 0,
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        borderBottomColor: 'rgba(138, 134, 202, 0.2)',
                        borderBottomWidth: 1,
                    }}>
                    {logoSvg}
                </View>
                <ScrollView contentContainerStyle={{flexGrow: 1}}>
                    <Pressable
                        onPress={() => props.navigation.navigate('Account')}
                        style={{
                            paddingHorizontal: 15,
                            paddingVertical: 20,
                            backgroundColor: theme.colors.primaryExtraLight,
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderBottomColor: 'rgba(138, 134, 202, 0.2)',
                            borderBottomWidth: 1,
                            marginBottom: 2,
                        }}>
                        <Icon
                            name="edit"
                            size={18}
                            color={theme.colors.primary}
                            containerStyle={{
                                position: 'absolute',
                                top: 7,
                                right: 10,
                            }}
                        />
                        <Icon
                            name="person"
                            type="ionicon"
                            size={30}
                            color="#b4abd2"
                            containerStyle={{
                                width: 60,
                                height: 60,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 60,
                                backgroundColor: theme.colors.white,
                                borderWidth: 1,
                                borderColor: 'rgba(138, 134, 202, 0.3)',
                            }}
                        />
                        <Text style={[global.link, {marginVertical: 8, maxWidth: '100%', textAlign: 'center'}]}>
                            {user}
                        </Text>
                        <Text style={[global.textCaps, {fontSize: 10, color: theme.colors.greyMedium}]}>
                            {t(CREDITS)}
                            <Text style={[global.textCaps, {fontSize: 10, color: theme.colors.primary}]}>
                                {' '}
                                {data?.[data_credits] ?? ''}
                            </Text>
                        </Text>
                        <Text style={[global.textCaps, {fontSize: 10, color: theme.colors.greyMedium}]}>
                            {t(BEST_BEFORE)}
                            <Text style={[global.textCaps, {fontSize: 10, color: theme.colors.primary}]}>
                                {' '}
                                {getFormattedDateFromSqlOrUnlimited(data?.[data_credits_best_before], t(UNLIMITED))}
                            </Text>
                        </Text>
                    </Pressable>
                    <View style={{flexGrow: 1}}>
                        <DrawerItemList {...props} />
                    </View>
                    <View style={{alignItems: 'center', marginVertical: 30}}>
                        <Button
                            onPress={() => props.navigation.navigate('Packages')}
                            title={t(BUY_CREDITS)}
                            containerStyle={[global.Button.containerStyle]}
                            buttonStyle={[global.Button.buttonStyle, {width: 140, paddingHorizontal: 0}]}
                            titleStyle={global.Button.titleStyle}></Button>
                    </View>
                    <View style={global.flexCenter}>
                        <DrawerItem
                            label={t(LOGOUT)}
                            onPress={onLogout}
                            icon={() => (
                                <Icon name="power-outline" type="ionicon" color={theme.colors.primary} size={20} />
                            )}
                            labelStyle={{
                                fontSize: 14,
                                color: theme.colors.primary,
                                fontFamily: theme.fontFamilyMedium,
                                marginLeft: -18,
                            }}
                            style={{
                                flex: 1,
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: 0,
                                marginBottom: 10,
                                paddingLeft: 10,
                                paddingRight: 10,
                                borderRadius: 0,
                            }}
                        />
                        <Text
                            style={[
                                global.textMain,
                                {
                                    fontSize: 10,
                                    color: theme.colors.textColor,
                                    marginBottom: 5,
                                    marginRight: 20,
                                },
                            ]}>
                            v.{APP_VERSION}
                        </Text>
                    </View>
                </ScrollView>
            </SafeAreaView>
        </ImageBackground>
    );
};
