import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    qrcode: {
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'center',
    },

    note: {
        color: '#808080',
        fontSize: 10,
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 30,
        paddingHorizontal: 20,
    },
}));

export default useStyles;
