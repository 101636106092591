import React from 'react';
import {FlatList, View, Pressable} from 'react-native';
import {ListItem, Text, Icon, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {range} from '../../utils/arrayHelpers';
import useStyles from './NumberDialog.styles';
import Modal from 'react-native-modal';

const minNumber = 1;
const maxNumber = 30;
const listData = range(minNumber, maxNumber);

const NumberDialog = ({open, setOpen, title, onNumberSelected}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);

    const onClose = () => {
        setOpen(false);
    };

    const onItemClick = async item => {
        onClose();
        await onNumberSelected(String(item));
    };

    const renderItem = ({item}) => (
        <ListItem key={String(item)} bottomDivider onPress={_ => onItemClick(item)}>
            <ListItem.Content style={{alignItems: 'center'}}>
                <ListItem.Title>
                    <Text style={global.textMain}>{item}</Text>
                </ListItem.Title>
            </ListItem.Content>
        </ListItem>
    );

    return (
        <Modal
            isVisible={open}
            onBackdropPress={onClose}
            onBackButtonPress={onClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text id="form-dialog-title" style={global.modalTitleText}>
                        {title}
                    </Text>
                    <Pressable onPress={onClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <FlatList
                    data={listData}
                    renderItem={renderItem}
                    style={styles.centeredView}
                    keyExtractor={(item, index) => String(item)}
                />
            </View>
        </Modal>
    );
};

export default NumberDialog;
