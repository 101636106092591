import QRCode from 'qrcode.react';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, Pressable, ScrollView, Text, View} from 'react-native';
import {Button, Icon, LinearProgress, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import WebView from 'react-native-webview';
import {useReactToPrint} from 'react-to-print';
import {
    CLOSE,
    POWERED_BY,
    PRINT,
    QR_CODE_TITLE,
    QR_SELF_CHECKIN_DIALOG_NOTE,
    SELF_CHECKIN,
    SELF_CHECKIN_QR_CODE,
} from '../constants/stringsAndFields';
import globalStyle from '../theme/globalStyle';
import {parseAndGenerateHtmlSelfCheckin} from '../utils/htmlParser';
import {generatePdf} from '../utils/pdf';
import useStyles from './SelfCheckinQRDialog.styles';
import {logoQrSvg} from './SelfCheckinQRDialog.logo.svg.js';

const IS_MOBILE = Platform.OS !== 'web' ? true : false;
const FILE_NAME = 'eCheckinSelfCheckinQr';

const SelfCheckinQRDialog = ({useSharedDialogs}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const [htmlContent, setHtmlContent] = useState(null);
    const {t} = useTranslation();
    const componentRef = useRef();
    const {checkinLink, isOpenSelfCheckinQRDetails, closeSelfCheckinQRDetails} = useSharedDialogs();

    const shareTitle = t(SELF_CHECKIN);
    const shareText = t(SELF_CHECKIN);

    const close = () => {
        setHtmlContent(null);
        closeSelfCheckinQRDetails();
    };

    const onButtonClose = _ => {
        close();
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const onButtonPrint = _ => {
        handlePrint();
    };

    const handleMobilePrint = async _ => {
        await generatePdf(htmlContent, FILE_NAME, shareTitle, shareText);
    };

    const generateQRHtml = async checkinLink => {
        try {
            const parsedHtml = parseAndGenerateHtmlSelfCheckin(t, checkinLink, IS_MOBILE);
            setHtmlContent(parsedHtml);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (isOpenSelfCheckinQRDetails && checkinLink) {
            generateQRHtml(checkinLink).catch(console.error);
        }
    }, [isOpenSelfCheckinQRDetails, checkinLink]); // eslint-disable-line react-hooks/exhaustive-deps

    //web app
    const webView = !IS_MOBILE && (
        <View ref={componentRef} style={{height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            {checkinLink && (
                <View style={styles.contentWrapper}>
                    <Text style={styles.title}>{t(QR_CODE_TITLE)}</Text>
                    <View style={styles.qrcode}>
                        <QRCode level="M" value={checkinLink} />
                    </View>
                    <Text style={[global.textMain, styles.note]}>{t(QR_SELF_CHECKIN_DIALOG_NOTE)}</Text>
                    <Text style={[global.link, styles.note, {marginTop: 10}]}>{checkinLink}</Text>
                    <Text style={[global.textMain, styles.poweredNote]}>{t(POWERED_BY)}</Text>
                    {logoQrSvg}
                </View>
            )}
        </View>
    );

    //mobile apps
    const mobileWebView = (
        <WebView
            source={{
                html: htmlContent,
            }}
        />
    );

    return (
        <Modal
            isVisible={isOpenSelfCheckinQRDetails}
            onBackdropPress={close}
            onBackButtonPress={close}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={[global.modalDialog, {height: '95%', maxHeight: 520}]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(SELF_CHECKIN_QR_CODE)}</Text>
                    <Pressable onPress={close} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>
                {!htmlContent && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
                <ScrollView contentContainerStyle={{flexGrow: 1}}>
                    <View style={[global.modalContent, {flexGrow: 1}]}>
                        {htmlContent && (IS_MOBILE ? mobileWebView : webView)}
                    </View>
                </ScrollView>

                <View style={global.modalFooter}>
                    <Button
                        title={t(CLOSE)}
                        onPress={onButtonClose}
                        containerStyle={[global.ModalButton.Negative.containerStyle, {minWidth: 110}]}
                        buttonStyle={global.ModalButton.Negative.buttonStyle}
                        titleStyle={global.ModalButton.Negative.titleStyle}
                    />
                    <Button
                        title={t(PRINT)}
                        onPress={IS_MOBILE ? handleMobilePrint : onButtonPrint}
                        containerStyle={[global.ModalButton.Positive.containerStyle, {minWidth: 110}]}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default SelfCheckinQRDialog;
