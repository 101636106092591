import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import {Platform} from 'react-native';
import {IS_PRODUCTION_MODE} from '../config/development';
import {
    DEV_BACKEND,
    PRODUCTION_BACKEND,
    STORAGE_COMM_KEY,
    STORAGE_PASSWORD,
    STORAGE_RECEPCIJA_GROUP,
    STORAGE_REFRESH_TOKEN,
    STORAGE_USER_ACCESS_TOKEN,
    STORAGE_USER_DATA,
    STORAGE_USER_EMAIL,
    STORAGE_USER_ID,
    settings,
} from '../constants/stringsAndFields';
import {getDeviceId} from '../utils/device';
import {serializeData} from '../utils/helpers';
import {getXAppVersion} from '../utils/network';
import {
    checkAppToken,
    generateAndSaveAppToken,
    getAppAccessToken,
    getRefreshToken,
    getUserAccessToken,
    setUserOrdinal,
} from '../utils/userUtils';

const REACT_APP_BASE_URL =
    Platform.OS === 'web'
        ? ''
        : process.env.REACT_APP_BASE_URL ?? (IS_PRODUCTION_MODE ? PRODUCTION_BACKEND : DEV_BACKEND);

export const BASE_URL = REACT_APP_BASE_URL ?? (IS_PRODUCTION_MODE ? PRODUCTION_BACKEND : DEV_BACKEND);
export const API_VER_TOKENS = process.env.REACT_APP_API_VER_TOKENS ?? 'v1';
export const API_VER = process.env.REACT_APP_API_VER ?? 'v2';

const DEV_JTW_TOKEN =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfdG9rZW4iOiJpbkNoZWNrSW4tZGV2ZWxvcG1lbnQiLCJpYXQiOjE2NDAyNjM1MzcsIm5iZiI6MTY0MDI2MzUzNywiZXhwIjoxOTU1ODM5NTM3fQ.pCvdcbySG2ErZvcrHs_5JgUCLg1WMdatkTnfAwpXD5U';
const PRODUCTION_JWT_TOKEN =
    process.env.REACT_APP_JWT_APP_TOKEN ??
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjIxNTQ3NTQ5NTYsImlhdCI6MTUyMzYwMjk1NiwibmJmIjoxNTIzNjAyOTU2LCJhcHBfdG9rZW4iOiJpbkNoZWNrSW4tcHJvZHVjdGlvbiJ9.8wLxONLICqA99Xpx2TSAa6f8-vso6s8UmXnaSJWjq1s';
// {"app_token": "inCheckIn-development", "exp": 1955839537, "iat": 1640261878, "nbf": 1640261878}
export const JWT_APP_TOKEN = IS_PRODUCTION_MODE ? PRODUCTION_JWT_TOKEN : DEV_JTW_TOKEN;

const LOGIN_ROUTE = '/auth';

const EVISITOR_USER_ROUTE = '/users/me/evisitor';

const USER_ROUTE = '/users/me';
const USER_ROUTES = [
    EVISITOR_USER_ROUTE,
    '/users/me/password',
    '/checkin/all',
    '/users/me/checkins',
    '/guest/',
    '/guest/all',
    '/checkin/',
    '/AccommodationUnitFacilityType',
    '/FacilityTouristCheckInLookup',
    '/proxy',
    '/users/me/credits',
    '/users/me/evs-invoices',
    '/users/me/notifications',
    '/users/me/recepcija',
];
// users/me/self-checkin/guest

const APP_AUTH_ROUTES = ['/users', '/auth', '/all-settlements', '/TTPaymentCategoryLookup', '/guest'];
const REFRESH_ROUTE = '/auth/tokens/refresh';

const REST_TIMEOUT = 25000;

const restClient = axios.create({
    baseURL: BASE_URL,
    timeout: REST_TIMEOUT,
});

export const refreshAuthLogic = async _ => {
    const res = await restClient.post(`${BASE_URL}/${API_VER_TOKENS}/auth/tokens/refresh`);
    if (res) {
        await AsyncStorage.setItem(STORAGE_REFRESH_TOKEN, res?.refresh_token);
        await AsyncStorage.setItem(STORAGE_USER_ACCESS_TOKEN, res?.access_token);
    }
};

const isUserRoute = (reqUrl, reqMethod) => {
    // single checkin route
    if (reqUrl.includes('/checkin/') && reqMethod === 'get' && !reqUrl.endsWith('all')) return false;
    for (let url of USER_ROUTES) {
        if (reqUrl.includes(url)) return true;
    }
    if (reqUrl.endsWith(USER_ROUTE)) return true;
    return false;
};

const isAppRoute = (reqUrl, reqMethod) => {
    // single checkin route
    if (reqUrl.includes('/checkin/') && reqMethod === 'get') return true;
    for (let url of APP_AUTH_ROUTES) {
        if (reqUrl.endsWith(url)) return true;
        if (reqUrl.includes(url) && !reqUrl.endsWith('all') && ['get', 'put'].includes(reqMethod)) {
            return true;
        }
    }
    return false;
};

const isRefreshCall = reqUrl => reqUrl.includes(REFRESH_ROUTE);

const resolveToken = async (reqUrl, reqMethod) => {
    if (isRefreshCall(reqUrl)) return await getRefreshToken();
    if (isUserRoute(reqUrl, reqMethod)) return await getUserAccessToken();
    if (isAppRoute(reqUrl, reqMethod)) return await getAppAccessToken();
    return await getUserAccessToken();
};

const addHeaders = async request => {
    await checkAppToken();
    const token = await resolveToken(request.url, request.method);
    request.headers['Authorization'] = `JWT ${token}`;
    const userToken = await getUserAccessToken();
    if (userToken) {
        request.headers['X-Authorization'] = `JWT ${userToken}`;
    }
    request.headers['X-Device'] = await getDeviceId();
    request.headers['X-App-Version'] = getXAppVersion();
    return request;
};

restClient.interceptors.request.use(async request => {
    return addHeaders(request);
});

restClient.interceptors.response.use(
    async resp => {
        return resp.data;
    },

    async error => {
        /* refresh token and retry request once more on 401
           else log user out
        */
        const {config: originalReq, response} = error;
        console.log('interceptors.response error');
        console.log(error);

        if (response?.data?.description) {
            error.RESTErrors = {
                description: response?.data?.description,
                type: response?.data?.type,
                data: response?.data?.data,
            };
        }

        // handle login Invalid Application Token
        if (
            originalReq.url.endsWith(LOGIN_ROUTE) &&
            response?.data?.description === 'Invalid Application Token' &&
            !originalReq.isRetryAttempt
        ) {
            await generateAndSaveAppToken();
            try {
                console.log('Trying update of application token');
                originalReq.isRetryAttempt = true;
                const reqWithHeaders = await addHeaders(originalReq);
                originalReq.headers = {...originalReq.headers, ...reqWithHeaders.headers};
                console.log('Retrying request');
                console.log(originalReq);
                return await restClient.request(originalReq);
            } catch (e) {
                throw e;
            }
        }
        // skip refresh token request, retry attempts to avoid infinite loops
        else if (
            !originalReq.url.endsWith(LOGIN_ROUTE) &&
            !originalReq.url.includes(EVISITOR_USER_ROUTE) &&
            !originalReq.url.includes(`/${API_VER_TOKENS}/auth/tokens/refresh`) &&
            !originalReq.isRetryAttempt &&
            response &&
            response.status === 401 &&
            response?.data?.description !== 'Insufficient privileges'
        ) {
            try {
                console.log('Trying refresh token');
                await refreshToken();
                originalReq.isRetryAttempt = true;
                const reqWithHeaders = await addHeaders(originalReq);
                originalReq.headers = {...originalReq.headers, ...reqWithHeaders.headers};
                console.log('Retrying request');
                console.log(originalReq);
                return await restClient.request(originalReq);
            } catch (e) {
                // log user out if fail to refresh (due to expired or missing token) or persistent 401 errors from original requests
                if (e === 'user has not logged in' || (e.response && e.response.status === 401)) {
                    console.log('User has not logged in');
                }
                // suppress original error to throw the new one to get new information
                throw e;
            }
        } else {
            throw error;
        }
    }
);

async function refreshToken() {
    const refreshToken = await getUserAccessToken();
    if (!refreshToken) {
        throw new Error('user has not logged in');
    }

    // use private variable to keep 1 active JWT refresh request at any time.
    this.refreshPromise = this.refreshPromise || refreshAuthLogic;

    // get new access token
    try {
        await this.refreshPromise();
    } finally {
        this.refreshPromise = null;
    }
}

export const storeResponseData = async (res, user = null) => {
    if (res) {
        await AsyncStorage.setItem(STORAGE_REFRESH_TOKEN, res?.refresh_token);
        await AsyncStorage.setItem(STORAGE_USER_ACCESS_TOKEN, res?.access_token);
        await AsyncStorage.setItem(STORAGE_COMM_KEY, res?.comm_key);
        await AsyncStorage.setItem(STORAGE_USER_ID, res?.id);
        await AsyncStorage.setItem(STORAGE_RECEPCIJA_GROUP, res?.groups?.includes(7) ? '1' : '0');
        try {
            await setUserOrdinal(res?.ordinal);
            await AsyncStorage.setItem(STORAGE_USER_DATA, serializeData(res?.[settings]));
        } catch (e) {
            console.log(e);
        }
    }
    if (user) {
        await AsyncStorage.setItem(STORAGE_USER_EMAIL, user?.email);
        await AsyncStorage.setItem(STORAGE_PASSWORD, user?.password);
    }
};

export default restClient;
