import React from 'react';
import {FAB} from 'react-native-elements';
import {ADD_CHECKIN} from '../../constants/stringsAndFields';
import {useSharedDialogs} from './useDialogs';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const FabButton = ({t, global}) => {
    const {openFab} = useSharedDialogs();

    const insets = useSafeAreaInsets();

    return (
        <FAB
            title={t(ADD_CHECKIN)}
            icon={{name: 'add', color: '#fff', size: 22, containerStyle: {margin: 0}}}
            placement="right"
            onPress={openFab}
            containerStyle={[global.boxShadow, {marginBottom: insets.bottom}]}
            buttonStyle={global.FAB.buttonStyle}
            titleStyle={global.FAB.titleStyle}
        />
    );
};

export default FabButton;
