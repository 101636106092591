import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable, View} from 'react-native';
import {CheckBox, Icon, LinearProgress, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {getNotifications, saveNotifications} from '../../api/inCheckinRestService';
import {
    EDIT_NOTIFICATIONS,
    NOTIFICATIONS_EMAIL_SUBFIELD,
    NOTIFICATIONS_MAIL_FIELD,
    NOTIFICATIONS_MAIL_FIELD_TITLE,
    NOTIFICATIONS_NEWSLETTER_FIELD,
    NOTIFICATIONS_NEWSLETTER_FIELD_TITLE,
    NOTIFICATIONS_REMINDER_FIELD,
    NOTIFICATIONS_REMINDER_FIELD_TITLE,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import useStyles from './EditNotifications.styles';

const EditNotifications = ({setVisible, visible}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const styles = useStyles(theme);
    const [openProgress, setOpenProgress] = useState(true);
    const [notificationSettings, setNotificationSettings] = useState({
        [NOTIFICATIONS_MAIL_FIELD]: false,
        [NOTIFICATIONS_REMINDER_FIELD]: false,
        [NOTIFICATIONS_NEWSLETTER_FIELD]: false,
    });

    const toggleOverlay = () => {
        setVisible(!visible);
    };

    const handleChange = notificationKey => {
        const updatedValue = !notificationSettings[notificationKey];
        setOpenProgress(true);
        saveNotifications({
            [notificationKey]: {
                [NOTIFICATIONS_EMAIL_SUBFIELD]: updatedValue,
            },
        })
            .catch(console.error)
            .finally(_ => {
                setNotificationSettings({
                    ...notificationSettings,
                    [notificationKey]: updatedValue,
                });
                setOpenProgress(false);
            });
    };

    useEffect(() => {
        if (visible) {
            setOpenProgress(true);
            getNotifications()
                .then(res => {
                    if (res) {
                        const updateNotifications = {
                            ...notificationSettings,
                        };
                        for (const [key, value] of Object.entries(res)) {
                            updateNotifications[key] = value?.[NOTIFICATIONS_EMAIL_SUBFIELD];
                        }
                        setNotificationSettings(updateNotifications);
                    }
                })
                .catch(console.error)
                .finally(_ => {
                    setOpenProgress(false);
                });
        }
    }, [visible]);

    return (
        <Modal
            isVisible={visible}
            onBackdropPress={toggleOverlay}
            onBackButtonPress={toggleOverlay}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(EDIT_NOTIFICATIONS)}</Text>
                    <Pressable onPress={toggleOverlay} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>
                {openProgress && (
                    <LinearProgress
                        style={styles.linearProgress}
                        color={theme.colors.primary}
                        variant="indeterminate"
                    />
                )}
                <View style={global.modalContent}>
                    <CheckBox
                        title={t(NOTIFICATIONS_REMINDER_FIELD_TITLE)}
                        checked={notificationSettings[NOTIFICATIONS_REMINDER_FIELD]}
                        onPress={_ => handleChange(NOTIFICATIONS_REMINDER_FIELD)}
                        containerStyle={styles.modalConsentCheckbox}
                        fontFamily={'Montserrat-Medium'}
                        textStyle={styles.modalConsentCheckboxText}
                        size={21}
                    />
                    <CheckBox
                        title={t(NOTIFICATIONS_MAIL_FIELD_TITLE)}
                        checked={notificationSettings[NOTIFICATIONS_MAIL_FIELD]}
                        onPress={_ => handleChange(NOTIFICATIONS_MAIL_FIELD)}
                        containerStyle={styles.modalConsentCheckbox}
                        fontFamily={'Montserrat-Medium'}
                        textStyle={styles.modalConsentCheckboxText}
                        size={21}
                    />
                    <CheckBox
                        title={t(NOTIFICATIONS_NEWSLETTER_FIELD_TITLE)}
                        checked={notificationSettings[NOTIFICATIONS_NEWSLETTER_FIELD]}
                        onPress={_ => handleChange(NOTIFICATIONS_NEWSLETTER_FIELD)}
                        containerStyle={styles.modalConsentCheckbox}
                        fontFamily={'Montserrat-Medium'}
                        textStyle={styles.modalConsentCheckboxText}
                        size={21}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default EditNotifications;
