import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Animated, Modal, Platform, Text, TouchableOpacity, View} from 'react-native';
import {Icon, useTheme} from 'react-native-elements';
import {SORT} from '../../../constants/stringsAndFields';
import globalStyle from '../../../theme/globalStyle';
import {getDefaultIndex} from '../../../utils/helpers';
import useStyles from './Dropdown.styles';
import {getCurrentYear} from '../../../utils/dateHelper';

const IS_WEB = Platform.OS === 'web';

const Dropdown = ({position, currentSortOption, onSelected, dropdownOptions, defaultCurrentYear = false}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t} = useTranslation();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [show, setShow] = useState(false);

    const initialIndex = defaultCurrentYear ? getDefaultIndex(dropdownOptions, getCurrentYear()) : -1;
    const initialOption = defaultCurrentYear ? dropdownOptions[initialIndex]?.['text'] : null;
    const [selectedIndex, setSelectedIndex] = useState(initialIndex);
    const [selectedOption, setSelectedOption] = useState(initialOption);

    const [dropdownCoordinates, setDropdownCoordinates] = useState({top: 0, left: 0});

    const DropdownButtonRef = useRef();
    const rotateValue = useRef(new Animated.Value(0)).current;

    const toggleModal = async () => {
        if (isModalVisible) {
            setIsModalVisible(false);
            setShow(false);
        } else {
            await openDropdown();
        }
        Animated.timing(rotateValue, {
            toValue: isModalVisible ? 0 : 1,
            duration: 100,
            useNativeDriver: true,
        }).start();
    };

    const openDropdown = async () => {
        DropdownButtonRef.current.measure((_x, _y, w, h, px, py) => {
            const dropdownPosition = {};
            dropdownPosition.top = py + h;
            if (position === 'right') {
                dropdownPosition.left = px;
            } else {
                dropdownPosition.left = px + w - 160;
            }
            const selectedIndex = dropdownOptions.findIndex(
                el => el['text'] === currentSortOption || el['sortBy'] === currentSortOption
            );
            setSelectedIndex(selectedIndex);
            setDropdownCoordinates(dropdownPosition);
            setShow(true);
        });
    };

    const onOptionSelect = async (option, index) => {
        if (option?.['sortBy']) {
            // used by sorting dropdown
            setSelectedOption(option?.['text']);
            await onSelected(option);
        } else {
            // used by Year dropdown
            setSelectedOption(option?.['text']);
            await onSelected(option?.['text']);
        }
        setSelectedIndex(index);
        await toggleModal();
    };

    useEffect(() => {
        if (dropdownCoordinates && show) {
            setIsModalVisible(true);
        }
    }, [dropdownCoordinates, show]);

    useEffect(() => {
        if (currentSortOption && dropdownOptions) {
            const optionText = dropdownOptions[0]?.hasOwnProperty('sortBy')
                ? dropdownOptions.find(item => item['sortBy'] === currentSortOption)
                : dropdownOptions.find(item => item['text'] === currentSortOption);
            optionText && setSelectedOption(optionText?.['text']);
        }
    }, [currentSortOption]);

    return (
        <View>
            <TouchableOpacity
                ref={DropdownButtonRef}
                onPress={toggleModal}
                style={styles.dropdownButton}
                activeOpacity={1}>
                <Text style={styles.dropdownLabel}>{selectedOption || t(SORT)}</Text>
                <Animated.View
                    style={[
                        {
                            transform: [
                                {
                                    rotate: rotateValue.interpolate({
                                        inputRange: [0, 1],
                                        outputRange: ['0deg', '180deg'],
                                    }),
                                },
                            ],
                        },
                    ]}>
                    <Icon name={'keyboard-arrow-down'} size={16} />
                </Animated.View>
            </TouchableOpacity>
            <Modal visible={isModalVisible} transparent animationType="none">
                <TouchableOpacity
                    activeOpacity={1}
                    style={[styles.overlay, IS_WEB && {cursor: 'default'}]}
                    onPressOut={toggleModal}>
                    <View
                        style={[
                            global.card,
                            global.boxShadow,
                            styles.dropdown,
                            {overflow: 'visible', top: dropdownCoordinates.top, left: dropdownCoordinates.left},
                        ]}>
                        {dropdownOptions.map((option, index) => (
                            <TouchableOpacity
                                key={index}
                                style={[styles.optionItem, selectedIndex === index && styles.selectedOptionItem]}
                                onPress={() => onOptionSelect(option, index)}>
                                <Text style={[global.textMain, selectedIndex === index && styles.selectedOptionText]}>
                                    {option.text}
                                </Text>
                            </TouchableOpacity>
                        ))}
                    </View>
                </TouchableOpacity>
            </Modal>
        </View>
    );
};

export default Dropdown;
