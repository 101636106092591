import {makeStyles} from 'react-native-elements';
import {DEFAULT_BORDER_RRADIUS} from './constants';

const useStyles = makeStyles((theme, props) => ({
    container: {
        backgroundColor: theme.colors.primaryExtraLight,
        alignSelf: 'center',
        justifyContent: 'center',
        marginVertical: 10,
        borderColor: theme.colors.borderPrimaryLight,
        borderWidth: 1,
        borderRadius: DEFAULT_BORDER_RRADIUS,
    },
    containerInnerStyle: {
        padding: 5,
    },
    underlayContainer: {
        position: 'absolute',
        top: 10,
        left: 5,
        backgroundColor: theme.colors.borderPrimaryLight,
        borderRadius: DEFAULT_BORDER_RRADIUS,
    },
    iconContainer: {
        position: 'absolute',
        top: 10,
        left: 5,
        backgroundColor: theme.colors.primary,
        alignItems: 'center',
        justifyContent: 'center',
    },
    innerIconContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        color: theme.colors.primary,
        fontSize: 14,
        paddingLeft: DEFAULT_BORDER_RRADIUS,
        textAlign: 'center',
        fontFamily: theme.fontFamilyMedium,
    },
}));

export default useStyles;
