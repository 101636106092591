import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    landlordTitle: {
        marginBottom: 15,
    },

    landlordTitleText: {
        color: theme.colors.primary,
        lineHeight: 20,
    },

    landlordInfo: {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        borderColor: theme.colors.borderPrimaryLight,
        borderWidth: 1,
    },

    landlordName: {
        fontFamily: theme.fontFamilyMedium,
        marginBottom: 5,
    },
}));

export default useStyles;
