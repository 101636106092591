import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    invoicesModal: {
        minHeight: 400,
        maxHeight: '90%',
        maxWidth: 720,
    },
}));

export default useStyles;
