import React from 'react';
import {useTranslation} from 'react-i18next';
import {FlatList, Pressable, View} from 'react-native';
import {Icon, ListItem, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {
    CHOOSE_FACILITY_TITLE,
    FACILITY_ERROR_NO_ACTIVE_FACILITY,
    FACILITY_ID,
    NAME,
    NAME_CODE,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';

const SelectAccomodationDialog = ({isOpen, setOpen, onSelected, accomodations}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const onItemClick = async item => {
        onSelected(item);
        handleClose();
    };

    const render = ({item}) => (
        <ListItem
            key={`${item?.[FACILITY_ID]}_${item?.[NAME]}_${item?.[NAME_CODE]}`}
            bottomDivider
            onPress={_ => onItemClick(item)}>
            <ListItem.Content>
                <ListItem.Title style={global.textMain}>{item?.[NAME] + ' ' + item?.[NAME_CODE]}</ListItem.Title>
            </ListItem.Content>
        </ListItem>
    );

    const emptyList = <EmptyList t={t} global={global} />;

    return (
        <Modal
            isVisible={isOpen}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(CHOOSE_FACILITY_TITLE)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <FlatList
                    data={accomodations}
                    renderItem={render}
                    keyExtractor={(item, index) => `${item?.[FACILITY_ID]}_${item?.[NAME]}_${item?.[NAME_CODE]}`}
                    ListEmptyComponent={emptyList}
                />
            </View>
        </Modal>
    );
};

export default SelectAccomodationDialog;

const EmptyList = ({t, global}) => (
    <View style={global.listEmptyMsgWithBackground}>
        <Text style={global.listEmptyMsgText}>{t(FACILITY_ERROR_NO_ACTIVE_FACILITY)}</Text>
    </View>
);
