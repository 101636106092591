import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    modalConsentCheckbox: {
        backgroundColor: 'transparent',
        borderWidth: 0,
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 15,
        padding: 0,
    },

    modalConsentCheckboxText: {
        color: theme.colors.textColor,
        fontSize: 14,
        lineHeight: 20,
        marginRight: 0,
        fontWeight: 'normal',
    },
}));

export default useStyles;
