import React, {useCallback, memo} from 'react';
import {Text, View} from 'react-native';
import {Icon, ListItem, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {DATE_TIME_OF_DEPARTURE, id, NAME_FIELD, STAY_FROM_FIELD} from '../../constants/stringsAndFields';
import {useWindowDimensions} from 'react-native';
import {getTouristStatusLabel, getTouristStatusLabelColor, getTouristBorderLeftColor} from '../../utils/touristUtils';
import {getAccomodationData, prepareCalendarImportTourist} from '../../utils/guestUtils';
import {getDateFromAspNetFormatToShort} from '../../utils/dateHelper';
import isEqual from 'lodash/isEqual';

const TouristItem = ({
    tourist,
    evAccount,
    checkinId,
    navigation,
    calendarInput,
    facilityData,
    eVisitorStaticData,
    day,
    openQRDetails,
    updateGuestId,
    importTourist,
}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);

    const width = useWindowDimensions().width;
    const isDesktop = width >= theme.desktopBP;

    const {facilityName, accomodationUnit} = getAccomodationData(evAccount, tourist);

    const showDetails = detailsTourist => {
        updateGuestId(detailsTourist[id]);
        openQRDetails();
    };

    const openDetailsScreen = useCallback(
        async tourist => {
            if (checkinId) {
                await importTourist(tourist);
                navigation.goBack();
            } else if (calendarInput && facilityData) {
                const guestData = await prepareCalendarImportTourist(
                    tourist,
                    evAccount,
                    eVisitorStaticData,
                    facilityData,
                    day
                );
                if (guestData) {
                    navigation.replace('Guest', {
                        checkinId: 0,
                        isNewGuest: true,
                        guestData: guestData,
                        consent: true,
                        calendarInput: true,
                    });
                }
            } else {
                showDetails(tourist);
            }
        },
        [checkinId, calendarInput, facilityData, evAccount, eVisitorStaticData, facilityData, day]
    );

    return (
        <ListItem
            key={tourist[id]}
            onPress={_ => openDetailsScreen(tourist)}
            containerStyle={[global.listItem, {borderColor: getTouristBorderLeftColor(tourist)}]}
            style={[global.boxShadow, {marginBottom: 15, borderRadius: 4}]}
            underlayColor="white">
            <View style={[isDesktop && {flexDirection: 'row', alignItems: 'center'}, {flex: 1}]}>
                <View
                    style={[
                        global.flexCenter,
                        {justifyContent: 'space-between'},
                        !isDesktop && {marginBottom: 10},
                        isDesktop && {width: '35%'},
                    ]}>
                    <View style={[global.flexCenter, {flex: 1}]}>
                        <Icon
                            type="ionicon"
                            name="person"
                            size={16}
                            style={{marginRight: 8}}
                            color={getTouristBorderLeftColor(tourist)}
                        />
                        <Text style={[global.textMain, {fontFamily: theme.fontFamilySemibold, fontSize: 13, flex: 1}]}>
                            {tourist?.[NAME_FIELD]}
                        </Text>
                    </View>
                    <Text
                        style={[
                            global.checkinLabel,
                            isDesktop && {marginHorizontal: 20},
                            !isDesktop && {marginLeft: 10},
                            getTouristStatusLabelColor(tourist),
                        ]}>
                        {getTouristStatusLabel(tourist)}
                    </Text>
                </View>
                <View
                    style={[
                        global.flexCenter,
                        {justifyContent: 'space-between'},
                        !isDesktop && {alignItems: 'flex-end'},
                        isDesktop && {width: '65%', paddingLeft: 20},
                    ]}>
                    <View
                        style={[
                            {flex: 1},
                            !isDesktop && {alignItems: 'flex-start'},
                            isDesktop && {flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'},
                        ]}>
                        <View
                            style={[
                                global.flexCenter,
                                !isDesktop && {marginBottom: 10},
                                isDesktop && {maxWidth: '65%', paddingRight: 10},
                            ]}>
                            <Icon
                                type="ionicon"
                                name="home-outline"
                                size={16}
                                color={theme.colors.textColorMedium}
                                style={{marginRight: 8}}
                            />
                            <View style={{flex: 1}}>
                                <Text style={[global.textCaps, {fontSize: 11, lineHeight: 14}]}>{facilityName}</Text>
                                <Text
                                    style={[
                                        global.textCaps,
                                        {fontSize: 10, color: theme.colors.textColorLight, marginTop: 2},
                                    ]}>
                                    {accomodationUnit}
                                </Text>
                            </View>
                        </View>
                        <View style={global.dateLabel}>
                            <Icon
                                type="ionicon"
                                name="calendar-outline"
                                color={theme.colors.textColorMedium}
                                size={16}
                                style={{marginRight: 8}}
                            />
                            <Text style={[global.textMain]}>
                                {getDateFromAspNetFormatToShort(tourist?.[STAY_FROM_FIELD])}
                            </Text>
                            <Text
                                style={{
                                    color: theme.colors.greyLight,
                                    marginHorizontal: 6,
                                }}>
                                /
                            </Text>
                            <Text style={[global.textMain]}>
                                {getDateFromAspNetFormatToShort(tourist?.[DATE_TIME_OF_DEPARTURE])}
                            </Text>
                        </View>
                    </View>
                    <Icon
                        type="ionicon"
                        name={'qr-code-outline'}
                        size={20}
                        style={isDesktop && {marginLeft: 50, marginRight: 20}}
                    />
                </View>
            </View>
            {/* <View style={[isDesktop && {flexDirection: 'row', alignItems: 'center'}, {flex: 1}]}>
                <View
                    style={[
                        !isDesktop && {marginBottom: 10},
                        isTablet && {paddingRight: 180},
                        isDesktop && {width: '25%'},
                    ]}>
                    <View style={global.flexCenter}>
                        <Icon
                            type="ionicon"
                            name="person"
                            size={16}
                            style={{marginRight: 8}}
                            color={getTouristBorderLeftColor(tourist)}
                        />
                        <Text style={[global.textMain, {fontFamily: theme.fontFamilyMedium, fontSize: 13}]}>
                            {tourist?.[NAME_FIELD]}
                        </Text>
                    </View>
                </View>

                <View
                    style={[
                        !isDesktop && {marginBottom: 10, marginLeft: 3},
                        isTablet && {marginBottom: 0, paddingRight: 180},
                        isDesktop && {width: '25%'},
                    ]}>
                    <View style={global.flexCenter}>
                        <Icon
                            type="ionicon"
                            name="home-outline"
                            size={16}
                            color={theme.colors.textColorMedium}
                            style={{marginRight: 8}}
                        />
                        <View style={{flex: 1}}>
                            <Text style={[global.textCaps, {fontSize: 11, lineHeight: 14}]}>{facilityName}</Text>
                            <Text
                                style={[
                                    global.textCaps,
                                    {fontSize: 10, color: theme.colors.textColorLight, marginTop: 2},
                                ]}>
                                {accomodationUnit}
                            </Text>
                        </View>
                    </View>
                </View>

                <View
                    style={[
                        !isDesktop && {marginLeft: 3},
                        isTablet && {position: 'absolute', right: 10},
                        isDesktop && {
                            width: '25%',
                            alignItems: 'center',
                            paddingHorizontal: 20,
                        },
                        isMobileSmall && {marginBottom: 15},
                    ]}>
                    <View style={global.flexCenter}>
                        <Text style={[global.checkinLabel, getTouristStatusLabelColor(tourist)]}>
                            {getTouristStatusLabel(tourist)}
                        </Text>
                    </View>
                </View>

                <View
                    style={[
                        {flexDirection: 'row', justifyContent: 'space-between'},
                        !isDesktop && {marginLeft: 3},
                        isTablet && {position: 'absolute', right: 10, bottom: 0},
                        isDesktop && {width: '25%', alignItems: 'center', paddingHorizontal: 20},
                    ]}>
                    <View
                        style={[
                            global.flexCenter,
                            global.listItemIconTextWrapper,
                            {minWidth: 140, alignSelf: 'center'},
                            isMobileSmall && {alignSelf: 'flex-start'},
                        ]}>
                        <Icon
                            type="ionicon"
                            name="calendar-outline"
                            color={theme.colors.textColorMedium}
                            size={16}
                            style={{marginRight: 8}}
                        />
                        <Text style={[global.textMain]}>
                            {getDateFromAspNetFormatToShort(tourist?.[STAY_FROM_FIELD])}
                        </Text>
                        <Text
                            style={{
                                color: theme.colors.greyLight,
                                marginHorizontal: 6,
                            }}>
                            /
                        </Text>
                        <Text style={[global.textMain]}>
                            {getDateFromAspNetFormatToShort(tourist?.[FORESEEN_STAY_UNTIL_FIELD])}
                        </Text>
                    </View>

                    <Icon type="ionicon" name={'qr-code-outline'} size={20} style={{marginLeft: 10}} />
                </View>
            </View> */}
        </ListItem>
    );
};

function arePropsEqual(prevProps, nextProps) {
    return isEqual(prevProps.tourist, nextProps.tourist);
}

export default memo(TouristItem, arePropsEqual);
