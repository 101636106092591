import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Pressable} from 'react-native';
import {Button, CheckBox, Icon, ListItem, Text, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {
    CHOOSE_EVACCOUNT_TITLE,
    CLOSE,
    ID,
    NAME,
    NO_EVACCOUNT_MODAL,
    OK,
    SEARCH,
    SELECT_ALL,
    DESELECT_ALL,
} from '../../constants/stringsAndFields';
import Modal from 'react-native-modal';
import {FlatList} from 'react-native-gesture-handler';
import {filterByProperty, isNotEmpty, removeByValue} from '../../utils/arrayHelpers';
import UITextInput from '../ui-components/UITextInput';

const filterProperty = NAME;

const EVChooserDialog = props => {
    const {t} = useTranslation();
    const {
        evAccount,
        evaccounts,
        title = t(CHOOSE_EVACCOUNT_TITLE),
        useSharedDialogs,
        setEvAccount,
        multiChooserActive = false,
        multiChooserCallback = null,
    } = props;
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {isOpenEvisitorChooser, closeEvisitorDialog} = useSharedDialogs();
    const [search, setSearch] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);

    const isMultipleChooserAccounts = multiChooserActive && evaccounts?.length > 1;

    const updateSearch = text => {
        setSearch(text);
    };

    const clearSearch = () => {
        setSearch('');
        setSelectedList([]);
    };

    const handleClose = () => {
        setSearch('');
        closeEvisitorDialog();
        setSelectedList([]);
    };

    const onClickEvAccountItem = async item => {
        if (multiChooserActive) {
            multiChooserCallback([item[ID]]);
        } else {
            setEvAccount(item);
            handleClose();
        }
    };

    const updateSelectionList = item => {
        if (!selectedList.includes(item[ID])) {
            setSelectedList([...selectedList, item[ID]]);
        } else {
            const updatedList = removeByValue(selectedList, item[ID]);
            setSelectedList([...updatedList]);
        }
    };

    const selectAllAccounts = () => {
        if (selectedList.length === filteredList.length) {
            setSelectedList([]);
        } else {
            const updatedList = filteredList.map(item => item[ID]);
            setSelectedList(updatedList);
        }
    };

    const extendedCallback = () => {
        multiChooserCallback && multiChooserCallback(selectedList);
    };

    useEffect(() => {
        if (evaccounts && isNotEmpty(evaccounts)) {
            const filtered = filterByProperty(evaccounts, filterProperty, search);
            setFilteredList(filtered);
        } else {
            setFilteredList([]);
        }
    }, [evaccounts, search]);

    useEffect(() => {
        if (isOpenEvisitorChooser && isMultipleChooserAccounts && evAccount) {
            setSelectedList([evAccount[ID]]);
        }
    }, [isOpenEvisitorChooser]);

    const render = isMultipleChooserAccounts
        ? ({item}) => (
              <ListItem
                  key={item[ID]}
                  onPress={_ => updateSelectionList(item)}
                  containerStyle={{padding: 10}}
                  bottomDivider>
                  <View style={global.flexCenter}>
                      <CheckBox
                          onPress={_ => updateSelectionList(item)}
                          checked={selectedList.includes(item[ID])}
                          containerStyle={global.CheckBox}
                          textStyle={global.CheckBoxText}
                          size={21}
                      />
                      <ListItem.Title style={global.textMain}>{item[NAME]}</ListItem.Title>
                  </View>
              </ListItem>
          )
        : ({item}) => (
              <ListItem key={item[ID]} bottomDivider onPress={_ => onClickEvAccountItem(item)}>
                  <ListItem.Content>
                      <ListItem.Title style={global.textMain}>{item[NAME]}</ListItem.Title>
                  </ListItem.Content>
              </ListItem>
          );

    const listEmpty = (
        <View style={global.listEmptyMsg}>
            <Text style={global.listEmptyMsgText}>{t(NO_EVACCOUNT_MODAL)}</Text>
        </View>
    );

    return (
        <Modal
            isVisible={isOpenEvisitorChooser}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={[global.modalDialog, {backgroundColor: theme.colors.white}]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{title}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <UITextInput
                    value={search}
                    renderErrorMessage={false}
                    iconLeft="search"
                    iconRight={search ? 'close' : null}
                    onRightIconPress={clearSearch}
                    placeholder={t(SEARCH)}
                    onChangeText={updateSearch}
                    containerStyle={{
                        marginBottom: 0,
                        backgroundColor: theme.colors.lightBg,
                        borderBottomWidth: 1,
                        borderBottomColor: theme.colors.greyLight,
                        padding: 10,
                    }}
                />
                {isMultipleChooserAccounts && (
                    <Pressable
                        onPress={selectAllAccounts}
                        style={{
                            paddingVertical: 15,
                            paddingHorizontal: 20,
                            borderBottomWidth: 1,
                            borderBottomColor: theme.colors.greyLight,
                        }}>
                        <Text style={global.link}>
                            {selectedList.length === filteredList.length ? t('DESELECT_ALL') : t('SELECT_ALL')}
                        </Text>
                    </Pressable>
                )}
                <FlatList
                    data={filteredList}
                    renderItem={render}
                    keyExtractor={(item, index) => item[ID]}
                    ListEmptyComponent={listEmpty}
                />
                {multiChooserActive && (
                    <View style={global.modalFooter}>
                        <Button
                            title={t(CLOSE)}
                            onPress={handleClose}
                            containerStyle={[global.ModalButton.Negative.containerStyle, {minWidth: 110}]}
                            buttonStyle={global.ModalButton.Negative.buttonStyle}
                            titleStyle={global.ModalButton.Negative.titleStyle}
                        />
                        <Button
                            title={t(OK)}
                            onPress={extendedCallback}
                            containerStyle={[global.ModalButton.Positive.containerStyle, {minWidth: 110}]}
                            buttonStyle={global.ModalButton.Positive.buttonStyle}
                            titleStyle={global.ModalButton.Positive.titleStyle}
                        />
                    </View>
                )}
            </View>
        </Modal>
    );
};

export default EVChooserDialog;
