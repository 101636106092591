import React, { FC } from 'react';
import { View, Text, TextProps, StyleProp, TextStyle, ViewProps, ViewStyle, StyleSheet } from 'react-native';
import { DEFAULT_HEIGHT } from './constants';
import { SwipeButtonCommonProps } from './SwipeButton';
import { useTheme } from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import useStyles from './SwipeButtonStyles';

export interface SwipeButtonTextProps extends Omit<SwipeButtonCommonProps, 'borderRadius'> {
  /**
   * The text that will be displaied in the container
   */
  title: string;

  /**
   * Additional props for the title text
   */
    titleProps?: TextProps;

  /**
   * Additional styling for the title text
   */
  titleStyle?: StyleProp<TextStyle>;

  /**
   * Additional props for the title container
   */
    titleContainerProps?: ViewProps;

  /**
   * Additional styling for the title container
   */
  titleContainerStyle?: StyleProp<ViewStyle>;
}

const SwipeButtonText: FC<SwipeButtonTextProps> = ({
    title, titleStyle, titleContainerProps, titleContainerStyle, titleProps, height = DEFAULT_HEIGHT,
}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    return (
        <View

            testID="TitleContainer"
            style={[
                styles.titleContainer,
                { height },
                titleContainerStyle,
            ]}
            {...titleContainerProps}
        >
            <Text
                numberOfLines={2}
                allowFontScaling={false}
                style={[styles.title, titleStyle]}
                testID="Title"
                {...titleProps}
            >
                {title}
            </Text>
        </View>
    );
};

export default SwipeButtonText;