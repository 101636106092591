import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, SafeAreaView, ScrollView, useWindowDimensions, View} from 'react-native';
import {Button, Icon, LinearProgress, Text, useTheme} from 'react-native-elements';
import WebView from 'react-native-webview';
import {useReactToPrint} from 'react-to-print';
import {fetchPaymentSlip, useUpGeneratePdfTz} from '../api/inCheckinRestService';
import {
    AMOUNT_FIELD,
    BARCODE_ERROR,
    DEBT_ARRIVAL,
    DEBT_FOR_PAY,
    DEBT_OBJECT,
    EXECUTION_DATE_FIELD,
    FACILITY_NAME,
    FACILITY_PAYMENT_NOTE,
    FINAL_DEBT_AMOUNT,
    GENERATE_BARCODE,
    ID,
    Iban,
    MODEL_FIELD,
    NOTE,
    PIN_ID,
    PRINT,
    REFNO_FIELD,
    SHARE_BUTTON_TEXT,
    SHARE_DOCUMENT_TEXT,
} from '../constants/stringsAndFields';
import globalStyle from '../theme/globalStyle';
import {isNotEmpty} from '../utils/arrayHelpers';
import {drawBarcodeCanvas, drawBarcodeCanvasJavascript, generateBarcodeData} from '../utils/invoice/invoice';
import {fromBase64} from '../utils/jwt';
import {generatePdf} from '../utils/pdf';
import useStyles from './FinancePaymentScreen.styles';

const isNativeWebView = Platform.OS !== 'web' ? true : false;
const canvasWebViewHtml = `<html><head></head><body>${drawBarcodeCanvasJavascript}</body></html>`;
const fileName = 'eVisitorZaduzenjeUplatnica';

const FinancePaymentScreen = ({navigation, route}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const styles = useStyles(theme);
    const [payment, setPayment] = useState(null);
    const [progressOngoing, setProgressOngoing] = useState(false);
    const [htmlContent, setHtmlContent] = useState(null);
    const [barcodeCanvasData, setBarcodeCanvasData] = useState(null);
    const [canvasWebViewReady, setCanvasWebViewReady] = useState(false);
    const [showBarcode, setShowBarcode] = useState(false);
    const componentRef = useRef();
    const canvasWebViewRef = useRef(null);

    const paymentId = route?.params?.[ID];
    const evisitorPinId = route?.params?.[PIN_ID];
    const facilityName = fromBase64(route?.params?.[FACILITY_NAME] ?? '');

    const shareTitle = t(SHARE_DOCUMENT_TEXT);
    const shareText = t(SHARE_DOCUMENT_TEXT);

    const width = useWindowDimensions().width;
    const isMobile = width < theme.tabletBP;

    const fetchPayment = async (evisitorPinId, paymentId) => {
        setProgressOngoing(true);
        const paymentSlip = await fetchPaymentSlip(evisitorPinId, paymentId);
        if (isNotEmpty(paymentSlip)) {
            setPayment(paymentSlip[0]);
        } else {
            setPayment(null);
        }
        setProgressOngoing(false);
    };

    const prepareNativeWebViewCanvasImg = (webViewRef, data) => {
        try {
            const barcodeArray = generateBarcodeData(data);
            webViewRef.injectJavaScript(`drawBarcodeCanvas(${JSON.stringify(barcodeArray)}); true;`);
        } catch (e) {
            console.log(e);
            setBarcodeCanvasData(null);
        }
    };

    const prepareWebCanvasImg = data => {
        try {
            const barcodeArray = generateBarcodeData(data);
            const {dataUrl, width, height} = drawBarcodeCanvas(barcodeArray);
            setBarcodeCanvasData({width, height, dataUrl});
        } catch (e) {
            console.log(e);
            setBarcodeCanvasData(null);
        }
    };

    const receiveCanvasWebViewHtml = event => {
        try {
            const {width, height, dataUrl} = JSON.parse(event.nativeEvent.data);
            setBarcodeCanvasData({width, height, dataUrl});
        } catch (e) {
            console.log(e);
            setBarcodeCanvasData(null);
        }
    };

    const canvasWebViewReadyCallback = () => {
        setCanvasWebViewReady(true);
    };

    const onClickGenerateBarcode = async () => {
        try {
            await useUpGeneratePdfTz();
            setShowBarcode(true);
        } catch (e) {
            console.log(e);
            setShowBarcode(false);
            setHtmlContent(
                `<html style="width: 100%;">
                    <body style="width: 100%; text-align: center;" >
                         ${t(BARCODE_ERROR)}
                    </body>
                </html>`
            );
        }
    };

    const onButtonShare = async _ => {
        await generatePdf(htmlContent, fileName, shareTitle, shareText);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onButtonPrint = async _ => {
        if (isNativeWebView) {
            await generatePdf(htmlContent, fileName, shareTitle, shareText);
        } else {
            handlePrint();
        }
    };

    useEffect(() => {
        if (payment && showBarcode) {
            if (isNativeWebView && canvasWebViewReady) {
                prepareNativeWebViewCanvasImg(canvasWebViewRef.current, payment);
            } else {
                prepareWebCanvasImg(payment);
            }
        }
    }, [payment, canvasWebViewReady, showBarcode]);

    useEffect(() => {
        if (barcodeCanvasData) {
            setHtmlContent(
                `<html style="width: 100%;">
                    <head>
                        <style>      
                            @media print {                               
                                img {
                                    width: 280px !important;
                                    margin: 50px;
                                }
                            }
                        </style> 
                    </head>
                    <body style="width: 100%; text-align: center;" >
                        <img src="${barcodeCanvasData?.dataUrl}" style="width: ${isMobile ? '100%' : '280px'}"} />
                    </body>
                </html>`
            );
        }
    }, [barcodeCanvasData]);

    useEffect(() => {
        if (paymentId && evisitorPinId) {
            fetchPayment(evisitorPinId, paymentId).catch(console.error);
        }
    }, [paymentId]);

    return (
        <SafeAreaView style={global.containerBg}>
            <ScrollView contentContainerStyle={{flexGrow: 1}}>
                {progressOngoing && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
                <View style={global.containerInner}>
                    {payment && (
                        <View style={{marginVertical: 15}}>
                            <View
                                style={[
                                    global.card,
                                    global.boxShadow,
                                    {padding: 20, textAlign: 'center', overflow: 'visible'},
                                ]}>
                                <View style={styles.itemWrapper}>
                                    <Text style={[global.textMain, styles.itemTitle]}>{t(DEBT_OBJECT)}: </Text>
                                    <Text style={[global.textMain, styles.itemValue]}>{facilityName}</Text>
                                </View>
                                <View style={styles.itemWrapper}>
                                    <Text style={[global.textMain, styles.itemTitle]}>{t(FINAL_DEBT_AMOUNT)}: </Text>
                                    <Text style={[global.textMain, styles.itemValue]}>{payment?.[AMOUNT_FIELD]}</Text>
                                </View>
                                <View style={styles.itemWrapper}>
                                    <Text style={[global.textMain, styles.itemTitle]}>IBAN: </Text>
                                    <Text style={[global.textMain, styles.itemValue]}>{payment?.[Iban]}</Text>
                                </View>
                                <View style={styles.itemWrapper}>
                                    <Text style={[global.textMain, styles.itemTitle]}>PNB: </Text>
                                    <Text style={[global.textMain, styles.itemValue]}>
                                        {payment?.[MODEL_FIELD]} {payment?.[REFNO_FIELD]}
                                    </Text>
                                </View>
                                <View style={styles.itemWrapper}>
                                    <Text style={[global.textMain, styles.itemTitle]}>{t(DEBT_ARRIVAL)}: </Text>
                                    <Text style={[global.textMain, styles.itemValue]}>
                                        {payment?.[EXECUTION_DATE_FIELD]}
                                    </Text>
                                </View>
                                <View style={styles.itemWrapperAmount}>
                                    <Text style={[global.textMain, styles.itemTitle, {fontSize: 16}]}>
                                        {t(DEBT_FOR_PAY)}:{' '}
                                    </Text>
                                    <Text style={[global.textMain, styles.itemValue, {fontSize: 16, flex: 0}]}>
                                        {payment?.[AMOUNT_FIELD]}
                                    </Text>
                                </View>

                                {isNativeWebView ? (
                                    <View>
                                        {htmlContent && (
                                            <WebView
                                                source={{html: htmlContent}}
                                                style={[
                                                    isMobile && {aspectRatio: 280 / 154, width: '100%'},
                                                    !isMobile && {height: 160},
                                                ]}
                                            />
                                        )}

                                        <WebView
                                            ref={canvasWebViewRef}
                                            onMessage={receiveCanvasWebViewHtml}
                                            source={{html: canvasWebViewHtml}}
                                            onLoadEnd={canvasWebViewReadyCallback}
                                            containerStyle={{position: 'absolute', width: 0, height: 0}}
                                        />
                                    </View>
                                ) : (
                                    <View style={{maxWidth: 280, alignSelf: 'center'}}>
                                        {htmlContent && (
                                            <div ref={componentRef} dangerouslySetInnerHTML={{__html: htmlContent}} />
                                        )}
                                    </View>
                                )}
                                {!showBarcode && (
                                    <Button
                                        title={t(GENERATE_BARCODE)}
                                        onPress={onClickGenerateBarcode}
                                        containerStyle={[
                                            global.Button.containerStyle,
                                            {marginVertical: 20, alignSelf: 'center'},
                                        ]}
                                        buttonStyle={global.Button.buttonStyle}
                                        titleStyle={global.Button.titleStyle}
                                    />
                                )}

                                {showBarcode && (
                                    <View>
                                        <View style={{marginVertical: 10}}>
                                            <Text style={global.textCapsDark}>{t(NOTE)}:</Text>
                                            <Text style={global.textMain}>{t(FACILITY_PAYMENT_NOTE)}</Text>
                                        </View>
                                        <View
                                            style={{
                                                marginBottom: 10,
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                flexWrap: 'wrap',
                                            }}>
                                            <Button
                                                title={t(PRINT)}
                                                onPress={onButtonPrint}
                                                titleStyle={global.outlineButtonTitle}
                                                containerStyle={[
                                                    global.Button.containerStyle,
                                                    {width: 150, margin: 10},
                                                ]}
                                                buttonStyle={global.outlineButton}
                                                icon={
                                                    <Icon
                                                        name="print"
                                                        type="ionicon"
                                                        size={18}
                                                        color={theme.colors.primary}
                                                        containerStyle={{marginRight: 10}}
                                                    />
                                                }
                                            />
                                            <Button
                                                title={t(SHARE_BUTTON_TEXT)}
                                                onPress={onButtonShare}
                                                titleStyle={global.outlineButtonTitle}
                                                containerStyle={[
                                                    global.Button.containerStyle,
                                                    {width: 150, margin: 10},
                                                ]}
                                                buttonStyle={global.outlineButton}
                                                icon={
                                                    <Icon
                                                        name="share-social"
                                                        type="ionicon"
                                                        size={18}
                                                        color={theme.colors.primary}
                                                        containerStyle={{marginRight: 10}}
                                                    />
                                                }
                                            />
                                        </View>
                                    </View>
                                )}
                            </View>
                        </View>
                    )}
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export default FinancePaymentScreen;
