import {useIsFocused} from '@react-navigation/native';
import React, {useEffect, useRef, useState} from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import {Text, useTheme} from 'react-native-elements';
import {DOCUMENT_NUMBER, EV_ACCOUNT, FACILITY, PROCESSING_GALLERY, content} from '../constants/stringsAndFields';
import {isNotEmpty} from '../utils/arrayHelpers';
import {checkAndOpenCamera, closeCamera} from '../utils/camera';
import {getPhotoGalleryImages, recognizeImage} from '../utils/camera';
import {parseCamResults} from '../utils/cameraCommon';
import {DEFAULT_LANG} from '../utils/i18n';
import {fromBase64, toBase64} from '../utils/jwt';
import {SafeAreaView} from 'react-native-safe-area-context';
import globalStyle from '../theme/globalStyle';
import {useTranslation} from 'react-i18next';

const IS_WEB = Platform.OS === 'web';

const ScannerScreen = ({route, navigation}) => {
    const {
        checkinId,
        callbackScreen = 'Guest',
        lang = DEFAULT_LANG,
        facilityData = null,
        additionalParams = null,
        importFromGallery = 'false',
        savedGalleryTourist = null,
    } = route.params;
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const isFocused = useIsFocused();
    const [imageNumber, setImageNumber] = useState('');
    const [galleryImport, setGalleryImport] = useState(false);
    const [galleryTourists, setGalleryTourists] = useState([]);
    const [currentTourist, setCurrentTourist] = useState(null);
    const cameraRef = useRef(null);
    const importFromGalleryFlag = importFromGallery === 'true';

    const onScanResult = scanData => {
        try {
            const guestData = !IS_WEB ? parseCamResults(scanData, true) : scanData;
            // this is for calendar input scanner
            if (facilityData && additionalParams) {
                const decodedData = JSON.parse(fromBase64(facilityData));
                const guestAndFacilityData = {
                    [content]: guestData,
                    [EV_ACCOUNT]: decodedData?.[EV_ACCOUNT],
                    [FACILITY]: decodedData?.[FACILITY],
                };
                navigation.replace(callbackScreen, {
                    checkinId: checkinId,
                    isNewGuest: true,
                    guestData: toBase64(JSON.stringify(guestAndFacilityData)),
                    consent: true,
                    lang: lang,
                    ...additionalParams,
                    scanned: 'true',
                });
            } else {
                // this is for self-checkin and regular checkin screens
                navigation.replace(callbackScreen, {
                    checkinId: checkinId,
                    isNewGuest: true,
                    guestData: toBase64(JSON.stringify({[content]: guestData})),
                    consent: true,
                    lang: lang,
                    scanned: 'true',
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onScanError = error => {
        console.log('onScanError');
        console.log(error);
        if (IS_WEB) {
            navigation.goBack();
        } else {
            console.log('onScanError pop');
            navigation.pop();
        }
    };

    const openCamera = async importFromGallery => {
        if (importFromGallery) {
            await recognizeFromGallery();
        } else {
            await checkAndOpenCamera(onScanResult, onScanError, cameraRef, importFromGallery);
        }
    };

    const cleanup = () => {
        setGalleryImport(false);
        setCurrentTourist(null);
        navigation.pop();
    };

    const recognizeFromGallery = async () => {
        try {
            const images = await getPhotoGalleryImages();
            const imagesLength = images.length;
            const guestsData = [];
            let idx = 1;
            for (const image of images) {
                try {
                    const msg = `${idx}/${imagesLength}`;
                    setImageNumber(msg);
                    const imageRes = await recognizeImage(image);
                    const guestData = parseCamResults(imageRes, true);
                    guestsData.push(guestData);
                } catch (e) {
                    console.log('Failed to recognize MRZ from image');
                    console.log(e);
                }
                idx++;
            }
            if (isNotEmpty(guestsData)) {
                setGalleryImport(true);
                importGalleryGuest(guestsData, guestsData[0]);
            } else {
                // no pictures recognized
                cleanup();
            }
        } catch (e) {
            console.log('Failed to load images from gallery');
            console.log(e);
            cleanup();
        } finally {
            setImageNumber('');
        }
    };

    const importGalleryGuest = (guests, guestData) => {
        try {
            // removing current guest
            const updatedGuestList = guests.filter(item => item[DOCUMENT_NUMBER] !== guestData[DOCUMENT_NUMBER]);
            setGalleryTourists([...updatedGuestList]);
            setCurrentTourist(guestData[DOCUMENT_NUMBER]);

            // calendar guest
            if (facilityData && additionalParams) {
                const decodedData = JSON.parse(fromBase64(facilityData));
                const guestAndFacilityData = {
                    [content]: guestData,
                    [EV_ACCOUNT]: decodedData?.[EV_ACCOUNT],
                    [FACILITY]: decodedData?.[FACILITY],
                };
                navigation.navigate(callbackScreen, {
                    checkinId: checkinId,
                    isNewGuest: true,
                    guestData: toBase64(JSON.stringify(guestAndFacilityData)),
                    consent: true,
                    lang: lang,
                    ...additionalParams,
                    scanned: 'true',
                    isGalleryGuest: true,
                });
            } else {
                // regular guest
                navigation.navigate(callbackScreen, {
                    checkinId: checkinId,
                    isNewGuest: true,
                    guestData: toBase64(JSON.stringify({[content]: guestData})),
                    consent: true,
                    lang: lang,
                    scanned: 'true',
                    isGalleryGuest: true,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    // guest imported from gallery
    useEffect(() => {
        if (isFocused && galleryImport && currentTourist) {
            if (!savedGalleryTourist) {
                if (isNotEmpty(galleryTourists)) {
                    // guest not saved/cancelled, continue to next
                    importGalleryGuest(galleryTourists, galleryTourists.pop());
                } else {
                    // guest not saved/cancelled, it is last guest
                    cleanup();
                }
            }
            // guest saved, try next or finish
            else if (savedGalleryTourist) {
                const updatedGuestList = galleryTourists.filter(item => item[DOCUMENT_NUMBER] !== savedGalleryTourist);
                setGalleryTourists([...updatedGuestList]);
                if (isNotEmpty(updatedGuestList)) {
                    importGalleryGuest(updatedGuestList, updatedGuestList.pop());
                } else {
                    // last guest
                    cleanup();
                }
            }
        }
    }, [isFocused, galleryImport, savedGalleryTourist, galleryTourists]);

    // initial load of screen
    useEffect(() => {
        if (checkinId || facilityData) {
            openCamera(importFromGalleryFlag).catch(console.error);
        }
        return () => {
            IS_WEB && closeCamera();
        };
    }, []);

    return (
        <SafeAreaView style={global.containerBg}>
            <View style={styles.container} ref={cameraRef}>
                {imageNumber && (
                    <View>
                        <Text style={[global.textTitle, styles.text]}>{`${t(PROCESSING_GALLERY)}`}</Text>
                        <Text
                            style={[
                                global.textTitle,
                                styles.text,
                                {
                                    color: theme.colors.primary,
                                    fontSize: 30,
                                    fontFamily: theme.fontFamilyMedium,
                                    marginTop: 20,
                                },
                            ]}>{`${imageNumber}`}</Text>
                    </View>
                )}
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        textAlign: 'center',
    },
});

export default ScannerScreen;
