import React from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable, View} from 'react-native';
import {Button, Icon, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {INVOICES, NEW_INVOICE} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import useStyles from './InvoicesDialog.styles';
import InvoicesList from './InvoicesList';

const InvoicesDialog = ({useSharedDialogs, openNewInvoice, navigation}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t} = useTranslation();

    const {isOpenInvoices, closeInvoices, invoices, resetInvoices} = useSharedDialogs();

    const resetAllData = () => {
        closeInvoices();
        resetInvoices();
    };

    const onNewInvoices = () => {
        closeInvoices();
        openNewInvoice();
    };

    return (
        <Modal
            isVisible={isOpenInvoices}
            onBackdropPress={resetAllData}
            onBackButtonPress={resetAllData}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={[global.modalDialog, styles.invoicesModal]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(INVOICES)}</Text>
                    <Pressable onPress={resetAllData} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <View
                    style={{
                        backgroundColor: theme.colors.white,
                        flex: 1,
                    }}>
                    <InvoicesList invoices={invoices} closeInvoices={closeInvoices} navigation={navigation} />
                </View>
                <View style={global.modalFooter}>
                    <Button
                        onPress={onNewInvoices}
                        title={t(NEW_INVOICE)}
                        containerStyle={global.ModalButton.Positive.containerStyle}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                        disabledStyle={global.ModalButton.disabledStyle}
                        disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default InvoicesDialog;
