import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {ScrollView, View} from 'react-native';
import {useTheme} from 'react-native-elements';
import PdfViewer from '../components/PdfViewer';
import useStyles from './DocumentScreen.styles';

const DocumentScreen = ({navigation, route}) => {
    const {theme} = useTheme();
    const {t} = useTranslation();
    const styles = useStyles(theme);
    const {documentUrl} = route.params;
    return (
        <ScrollView contentContainerStyle={styles.container}>
            <View style={styles.container}>{documentUrl && <PdfViewer documentUrl={documentUrl} />}</View>
        </ScrollView>
    );
};

export default DocumentScreen;
