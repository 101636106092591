import React, {FC} from 'react';
import {GestureResponderHandlers, Animated} from 'react-native';
import {SwipeButtonCommonProps} from './SwipeButton';
import {useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import useStyles from './SwipeButtonStyles';

export interface SwipeButtonCircleProps extends SwipeButtonCommonProps {
    /**
     * GestureHandlers for when swiping the button
     */
    panHandlers: GestureResponderHandlers;

    /**
     * Opacity of the element
     */
    opacity?: number;

    /**
     * Element that should be displaied inside the button
     */
    Icon: JSX.Element;

    /**
     * Determinates the value of the button
     */
    translateX: Animated.Value;

    /**
     * Background color for the circle
     */
    circleBackgroundColor?: string;
}

export const SwipeButtonCircle: FC<SwipeButtonCircleProps> = ({
    Icon,
    opacity,
    panHandlers,
    translateX,
    height,
    borderRadius,
    circleBackgroundColor,
}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    return (
        <Animated.View
            testID="Button"
            {...panHandlers}
            style={[
                styles.iconContainer,
                {
                    opacity,
                    width: height,
                    height,
                    borderRadius,
                    transform: [{translateX}],
                },
            ]}>
            <Animated.View
                testID="IconContainer"
                style={[
                    styles.innerIconContainer,
                    {
                        backgroundColor: circleBackgroundColor,
                        width: height,
                        height,
                        borderRadius,
                    },
                    {opacity},
                ]}>
                {Icon}
            </Animated.View>
        </Animated.View>
    );
};
