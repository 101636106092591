import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    dropdownButton: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 5,
    },

    dropdownLabel: {
        fontFamily: theme.fontFamilyMedium,
        color: theme.colors.primary,
        fontSize: 13,
    },

    dropdown: {
        position: 'absolute',
        padding: 5,
        width: 160,
    },

    overlay: {
        width: '100%',
        height: '100%',
    },

    optionItem: {
        paddingVertical: 5,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 7,
    },

    selectedOptionItem: {
        backgroundColor: theme.colors.primaryExtraLight,
    },

    selectedOptionText: {
        fontFamily: theme.fontFamilyMedium,
        color: theme.colors.primary,
    },
}));

export default useStyles;
