import Clipboard from '@react-native-clipboard/clipboard';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable, ScrollView, View, useWindowDimensions} from 'react-native';
import {Icon, LinearProgress, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {
    EMAIL_BODY_REFERRAL,
    EMAIL_TITLE_REFERRAL,
    PRODUCTION_BACKEND,
    REFERRAL_DIALOG_TXT_1,
    REFERRAL_DIALOG_TXT_2,
    REFERRAL_DIALOG_TXT_3,
    SEND_REFERRAL,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import {generateShareUrl, isShareApiAvailable} from '../../utils/helpers';
import {share} from '../../utils/share';
import {getOrRefreshUserOrdinal} from '../../utils/userUtils';
import useStyles from './InviteFriendDialog.styles';

const InviteFriendDialog = ({open, setOpen}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t} = useTranslation();
    const width = useWindowDimensions().width;
    const notDesktop = width < theme.desktopBP;
    const [refUrl, setRefUrl] = useState(null);
    const [openProgress, setOpenProgress] = useState(true);

    const generateReferral = async _ => {
        setOpenProgress(true);
        const userOrdinal = await getOrRefreshUserOrdinal();
        if (userOrdinal) {
            const generatedReferral = `${generateShareUrl(PRODUCTION_BACKEND)}/referral?ref=${userOrdinal}`;
            setRefUrl(generatedReferral);
        }
        setOpenProgress(false);
    };

    useEffect(() => {
        open && generateReferral().catch(console.error);
    }, [open]);

    const onOpenLink = async _ => {
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onCopy = async _ => {
        try {
            refUrl && Clipboard.setString(refUrl);
        } catch (err) {
            console.log(err);
        } finally {
            handleClose();
        }
    };

    const onShare = async _ => {
        refUrl && (await share(EMAIL_TITLE_REFERRAL, EMAIL_BODY_REFERRAL, refUrl));
        handleClose();
    };

    return (
        <Modal
            isVisible={open}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}>
            <View style={[global.modalDialog, {maxHeight: '95%'}]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(SEND_REFERRAL)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>
                {openProgress && (
                    <LinearProgress
                        style={styles.linearProgress}
                        color={theme.colors.primary}
                        variant="indeterminate"
                    />
                )}
                <ScrollView contentContainerStyle={{flexGrow: 1}}>
                    <View style={[global.modalContent]}>
                        <Text
                            style={[
                                global.textIntro,
                                {
                                    textAlign: 'center',
                                    marginTop: 10,
                                    marginBottom: 20,
                                },
                            ]}>
                            {t(REFERRAL_DIALOG_TXT_1)}
                        </Text>

                        <Text style={[global.textMain, {textAlign: 'center', marginBottom: 20}]}>
                            {t(REFERRAL_DIALOG_TXT_2)}
                        </Text>

                        {/* Link */}
                        <View style={{marginBottom: 15}}>
                            <View style={global.flexCenter}>
                                <View style={[styles.linkWrapper, {flex: 1, paddingRight: 0}]}>
                                    <Pressable style={styles.linkTextWrapper} onPress={onOpenLink}>
                                        <Text numberOfLines={1} style={[global.link, styles.linkText]}>
                                            {refUrl}
                                        </Text>
                                    </Pressable>
                                    <Pressable style={styles.linkBtns} onPress={onCopy}>
                                        <Icon
                                            type="ionicon"
                                            name="copy-outline"
                                            size={20}
                                            color={theme.colors.primary}
                                        />
                                    </Pressable>
                                    {notDesktop && isShareApiAvailable && (
                                        <Pressable style={styles.linkBtns} onPress={onShare}>
                                            <Icon
                                                type="ionicon"
                                                name="share-social-outline"
                                                size={20}
                                                color={theme.colors.primary}
                                            />
                                        </Pressable>
                                    )}
                                </View>
                            </View>
                        </View>

                        <Text style={[global.textMain, {textAlign: 'center', marginBottom: 20}]}>
                            {t(REFERRAL_DIALOG_TXT_3)}
                        </Text>
                    </View>
                </ScrollView>
            </View>
        </Modal>
    );
};

export default InviteFriendDialog;
