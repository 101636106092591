import {yupResolver} from '@hookform/resolvers/yup';
import React, {useEffect, useState, useRef} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Pressable, View} from 'react-native';
import {Icon, Input, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {
    ARRIVAL_DATE_LABEL,
    ARRIVAL_TIME_LABEL,
    DEPARTURE_DATE_LABEL,
    DEPARTURE_TIME_LABEL,
    FORESEEN_STAY_UNTIL_FIELD,
    SELECT_ARRIVAL_DEPARTURE,
    LABEL_FROM,
    LABEL_TO,
    STAY_FROM_FIELD,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    TIME_STAY_FROM_FIELD,
} from '../../constants/stringsAndFields';
import {
    getDateFromDateString,
    getDateFromTime,
    getFormattedDateFromDateString,
    getMonthAgoJsDate,
    getNightsStayUntil,
    getNowDate,
    getStringDate,
    getStringTime,
    getTimeStayUntil,
} from '../../utils/dateHelper';
import PaperDateTimePicker from '../datetime/PaperDateTimePicker';
import schema from './CheckinDateTimePicker.schema';
import useStyles from './CheckinDateTimePicker.styles';
import {getCheckinTime} from '../../utils/helpers';
import DateRangePicker from './DateRangePicker';

const CheckinDateTimePicker = ({setCheckinDateTimes, disabledDatePickers = false, disabledTimePickers = false}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const [closeDateTimePicker, setCloseDateTimePicker] = useState(true);
    const [datePickerData, setDatePickerData] = useState(null);
    const [openDateRange, setOpenDateRange] = useState(false);
    const [dateRangePickerData, setDateRangePickerData] = useState(null);

    const {t} = useTranslation();

    const fromDayRef = useRef(null);
    const toDayRef = useRef(null);
    const fromTimeRef = useRef(null);
    const toTimeRef = useRef(null);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: {errors},
        getValues,
        trigger,
    } = useForm({
        resolver: yupResolver(schema(t)),
    });

    useEffect(() => {
        const prepareCheckin = async () => {
            const stayUntilDate = await getNightsStayUntil();
            const checkoutTime = await getTimeStayUntil();
            const checkinTime = await getCheckinTime();
            const checkin = {
                [FORESEEN_STAY_UNTIL_FIELD]: stayUntilDate,
                [STAY_FROM_FIELD]: getNowDate(),
                [TIME_ESTIMATED_STAY_UNTIL_FIELD]: checkoutTime,
                [TIME_STAY_FROM_FIELD]: checkinTime,
            };
            Object.keys(checkin).map(key => {
                register(key);
                setValue(key, checkin[key]);
                return true;
            });
            triggerAndUpdate();
        };
        prepareCheckin().catch(console.error);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeDate = (date, field) => {
        setValue(field, getStringDate(date));
        triggerAndUpdate();
    };

    const onChangeTime = (date, field) => {
        setValue(field, getStringTime(date));
        triggerAndUpdate();
    };

    const triggerAndUpdate = () => {
        trigger()
            .then(validation => {
                if (validation) {
                    const values = getValues();
                    setCheckinDateTimes({valid: true, ...values});
                } else {
                    setCheckinDateTimes({valid: false});
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const onSubmit = (data, e) => {
        console.log(data);
    };

    const onError = (errors, e) => {
        console.log(errors, e);
    };

    const openDateTimePicker = (mode, field, value, dateBoundary = null) => {
        setDatePickerData({
            datePickerMode: mode,
            datePickerDate: getDateFromTime(value),
            currentPickerField: field,
            dateBoundary: dateBoundary,
        });
        setCloseDateTimePicker(false);
    };

    const openDateRangePicker = (mode, field, value, dateBoundary = null) => {
        /*
        const stayFrom = getValues(STAY_FROM_FIELD);
        const stayFromDate = getDateFromDateString(stayFrom);
        const stayTo = getValues(FORESEEN_STAY_UNTIL_FIELD);
        const stayToDate = getDateFromDateString(stayTo);
        */
        setDateRangePickerData({
            stayFromDate: null,
            stayToDate: null,
        });
        setOpenDateRange(true);
    };

    const onConfirmDateRange = async ({startDate, endDate}) => {
        onChangeDate(startDate, STAY_FROM_FIELD);
        onChangeDate(endDate, FORESEEN_STAY_UNTIL_FIELD);
    };

    const onArrivalDatePress = field => {
        fromDayRef.current.blur();
        if (!disabledDatePickers) {
            openDateRangePicker('date', STAY_FROM_FIELD, field.value);
        }
    };

    const onStayFromTimePress = field => {
        fromTimeRef.current.blur();
        if (!disabledTimePickers) {
            openDateTimePicker('time', TIME_STAY_FROM_FIELD, field.value);
        }
    };

    const onStayUntilDatePress = field => {
        toDayRef.current.blur();
        if (!disabledDatePickers) {
            openDateRangePicker('date', FORESEEN_STAY_UNTIL_FIELD, field.value);
        }
    };

    const onStayToTimePress = field => {
        toTimeRef.current.blur();
        if (!disabledTimePickers) {
            openDateTimePicker('time', TIME_ESTIMATED_STAY_UNTIL_FIELD, field.value);
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.timeFields}>
                <Controller
                    name={STAY_FROM_FIELD}
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                        <Input
                            label={t(ARRIVAL_DATE_LABEL)}
                            showSoftInputOnFocus={false}
                            disabled={disabledDatePickers}
                            caretHidden={true}
                            leftIcon={
                                <Pressable
                                    onPress={_ => onArrivalDatePress(field)}
                                    style={({pressed}) => ({
                                        opacity: pressed ? 0.5 : 1,
                                    })}>
                                    <Icon
                                        type="ionicon"
                                        name="calendar"
                                        size={18}
                                        color={disabledDatePickers ? theme.colors.greyLight : theme.colors.iconColor}
                                    />
                                </Pressable>
                            }
                            leftIconContainerStyle={global.inputIconContainer}
                            ref={fromDayRef}
                            onFocus={_ => onArrivalDatePress(field)}
                            value={getFormattedDateFromDateString(field.value ?? '')}
                            errorMessage={errors[STAY_FROM_FIELD] ? errors[STAY_FROM_FIELD].message : null}
                            containerStyle={[styles.timeFieldInputContainer]}
                            style={[global.inputWithIcon, styles.timeFieldInput]}
                        />
                    )}
                />
                <Controller
                    name={TIME_STAY_FROM_FIELD}
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                        <Input
                            label={t(ARRIVAL_TIME_LABEL)}
                            showSoftInputOnFocus={false}
                            disabled={disabledTimePickers}
                            caretHidden={true}
                            leftIcon={
                                <Pressable
                                    onPress={_ => onStayFromTimePress(field)}
                                    style={({pressed}) => ({
                                        opacity: pressed ? 0.5 : 1,
                                    })}>
                                    <Icon
                                        type="ionicon"
                                        name="time-outline"
                                        size={18}
                                        color={disabledTimePickers ? theme.colors.greyLight : theme.colors.iconColor}
                                    />
                                </Pressable>
                            }
                            leftIconContainerStyle={global.inputIconContainer}
                            ref={fromTimeRef}
                            onFocus={_ => onStayFromTimePress(field)}
                            value={field.value ?? ''}
                            errorMessage={errors[TIME_STAY_FROM_FIELD] ? errors[TIME_STAY_FROM_FIELD].message : null}
                            containerStyle={[styles.timeFieldInputContainer]}
                            style={[global.inputWithIcon, styles.timeFieldInput]}
                        />
                    )}
                />
            </View>
            <View style={styles.timeFields}>
                <Controller
                    name={FORESEEN_STAY_UNTIL_FIELD}
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                        <Input
                            label={t(DEPARTURE_DATE_LABEL)}
                            showSoftInputOnFocus={false}
                            disabled={disabledDatePickers}
                            caretHidden={true}
                            leftIcon={
                                <Pressable
                                    onPress={_ => onStayUntilDatePress(field)}
                                    style={({pressed}) => ({
                                        opacity: pressed ? 0.5 : 1,
                                    })}>
                                    <Icon
                                        type="ionicon"
                                        name="calendar"
                                        size={18}
                                        color={disabledDatePickers ? theme.colors.greyLight : theme.colors.iconColor}
                                    />
                                </Pressable>
                            }
                            leftIconContainerStyle={global.inputIconContainer}
                            ref={toDayRef}
                            onFocus={_ => onStayUntilDatePress(field)}
                            value={getFormattedDateFromDateString(field.value ?? '')}
                            errorMessage={
                                errors[FORESEEN_STAY_UNTIL_FIELD] ? errors[FORESEEN_STAY_UNTIL_FIELD].message : null
                            }
                            containerStyle={[styles.timeFieldInputContainer]}
                            style={[global.inputWithIcon, styles.timeFieldInput]}
                        />
                    )}
                />
                <Controller
                    name={TIME_ESTIMATED_STAY_UNTIL_FIELD}
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                        <Input
                            label={t(DEPARTURE_TIME_LABEL)}
                            showSoftInputOnFocus={false}
                            disabled={disabledTimePickers}
                            caretHidden={true}
                            leftIcon={
                                <Pressable
                                    onPress={_ => onStayToTimePress(field)}
                                    style={({pressed}) => ({
                                        opacity: pressed ? 0.5 : 1,
                                    })}>
                                    <Icon
                                        type="ionicon"
                                        name="time-outline"
                                        size={18}
                                        color={disabledTimePickers ? theme.colors.greyLight : theme.colors.iconColor}
                                    />
                                </Pressable>
                            }
                            leftIconContainerStyle={global.inputIconContainer}
                            ref={toTimeRef}
                            onFocus={_ => onStayToTimePress(field)}
                            value={field.value ?? ''}
                            errorMessage={
                                errors[TIME_ESTIMATED_STAY_UNTIL_FIELD]
                                    ? errors[TIME_ESTIMATED_STAY_UNTIL_FIELD].message
                                    : null
                            }
                            containerStyle={[styles.timeFieldInputContainer]}
                            style={[global.inputWithIcon, styles.timeFieldInput]}
                        />
                    )}
                />
                <PaperDateTimePicker
                    closed={closeDateTimePicker}
                    setClosed={setCloseDateTimePicker}
                    onChangeDate={onChangeDate}
                    onChangeTime={onChangeTime}
                    datePickerData={datePickerData}
                />
                <DateRangePicker
                    open={openDateRange}
                    setOpen={setOpenDateRange}
                    pickerData={dateRangePickerData}
                    onConfirm={onConfirmDateRange}
                    label={t(SELECT_ARRIVAL_DEPARTURE)}
                    startLabel={t(LABEL_FROM)}
                    endLabel={t(LABEL_TO)}
                />
            </View>
        </View>
    );
};

export default CheckinDateTimePicker;
