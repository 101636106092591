import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {Text, Icon, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import useStyles from './LandlordInfo.styles';
import {
    ADDITIONAL_FIELDS,
    EVISITOR,
    NAME,
    FACILITY_FIELD,
    NAME_CODE,
    STAY_FROM_FIELD,
    FORESEEN_STAY_UNTIL_FIELD,
    SELF_CHECKIN_LANDLORD_TITLE,
} from '../../constants/stringsAndFields';
import {getFormattedDateFromDateString} from '../../utils/dateHelper';
import {isManualGuestDate, removeParanthesisText} from '../../utils/helpers';

const LandlordInfo = props => {
    const {checkin} = props;
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const styles = useStyles(theme);

    const facility = checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME];
    const facilityText = checkin ? removeParanthesisText(facility) : '';
    const dateRangeText = checkin
        ? `${getFormattedDateFromDateString(checkin?.[STAY_FROM_FIELD])} - ${getFormattedDateFromDateString(
              checkin?.[FORESEEN_STAY_UNTIL_FIELD]
          )}`
        : '';

    const isManualGuestInput = isManualGuestDate(checkin);

    return (
        <View style={styles.landlordInfo}>
            <View style={[global.flexCenter, styles.landlordTitle]}>
                <Icon
                    type="ionicon"
                    name="home-outline"
                    size={18}
                    style={{marginRight: 10}}
                    color={theme.colors.primary}
                />
                <Text style={[global.textTitle, styles.landlordTitleText]}>{t(SELF_CHECKIN_LANDLORD_TITLE)}</Text>
            </View>
            <View style={{marginBottom: 20}}>
                <Text style={[global.textMain, styles.landlordName]}>
                    {checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[NAME]}
                </Text>
                <Text style={[global.textMain, styles.landlordName]}>{facilityText}</Text>
            </View>

            <View style={[global.flexCenter]}>
                <Icon
                    type="ionicon"
                    name="bed-outline"
                    size={16}
                    color={theme.colors.primary}
                    style={{marginRight: 10}}
                />
                <Text style={[global.textMain, styles.landlordText]}>
                    {checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME_CODE]}
                </Text>
            </View>

            {!isManualGuestInput && (
                <View style={[global.flexCenter, {marginTop: 10}]}>
                    <Icon
                        type="ionicon"
                        name="calendar-outline"
                        size={16}
                        style={{marginRight: 10}}
                        color={theme.colors.primary}
                    />
                    <Text style={[global.textMain, styles.landlordText]}>{dateRangeText}</Text>
                </View>
            )}
        </View>
    );
};

export default LandlordInfo;
