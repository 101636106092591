import {useCallback, useState} from 'react';
import {useBetween} from 'use-between';
import {CHECKIN_ACTION, CHECKOUT_ACTION} from '../../constants/stringsAndFields';

const useCheckinActions = () => {
    const [isOpenShare, setIsOpenShare] = useState(false);
    const openShare = useCallback(() => setIsOpenShare(true), []);
    const closeShare = useCallback(() => setIsOpenShare(false), []);

    const [isShareVisible, setIsShareVisible] = useState(false);
    const showShare = useCallback(() => setIsShareVisible(true), []);
    const hideShare = useCallback(() => setIsShareVisible(false), []);

    const [isOpenActionsDialog, setIsOpenActionsDialog] = useState(null);
    const showActionsDialog = useCallback(action => setIsOpenActionsDialog(action), []);
    const hideActionsDialog = useCallback(() => setIsOpenActionsDialog(null), []);

    const [actionsCount, setActionsCount] = useState({
        [CHECKIN_ACTION]: 0,
        [CHECKOUT_ACTION]: 0,
    });
    const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);
    const showCheckout = useCallback(() => setIsCheckoutVisible(true), []);
    const hideCheckout = useCallback(() => setIsCheckoutVisible(false), []);
    const [isCheckinVisible, setIsCheckinVisible] = useState(false);
    const showCheckin = useCallback(() => setIsCheckinVisible(true), []);
    const hideCheckin = useCallback(() => setIsCheckinVisible(false), []);

    const [checkinAction, setCheckinAction] = useState(null);
    const triggerCheckinAction = useCallback(value => setCheckinAction(value), []);

    return {
        isCheckoutVisible,
        isCheckinVisible,
        actionsCount,
        isShareVisible,
        isOpenShare,
        showShare,
        hideShare,
        openShare,
        closeShare,
        isOpenActionsDialog,
        showActionsDialog,
        hideActionsDialog,
        showCheckout,
        hideCheckout,
        showCheckin,
        hideCheckin,
        setActionsCount,
        checkinAction,
        triggerCheckinAction,
    };
};

export const useSharedCheckinActions = () => useBetween(useCheckinActions);
