import {
    IS_CHECKED_OUT,
    CHECKED_OUT_STATUS_LABEL,
    CHECKED_IN_STATUS_LABEL,
    RECORDS,
    ID,
    TOURIST_SURNAME_NAME,
    DATE_TIME_OF_ARRIVAL,
    DATE_TIME_OF_DEPARTURE,
    CHECKOUT_OUT,
    FACILITY_NAME,
    FACILITY_ID,
    ACCOMODATION_OBJECT,
} from '../constants/stringsAndFields';

import {useTranslation} from 'react-i18next';
import {useTheme} from 'react-native-elements';
import globalStyle from '../theme/globalStyle';
import {getDateMillisFromAspNetFormat} from './dateHelper';

export const getTouristStatusLabel = tourist => {
    const {t} = useTranslation();
    if (tourist?.[IS_CHECKED_OUT]) return t(CHECKED_OUT_STATUS_LABEL);
    else return t(CHECKED_IN_STATUS_LABEL);
};

export const getTouristStatusLabelColor = tourist => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    if (tourist?.[IS_CHECKED_OUT]) return global.labelBlue;
    else return global.labelGreen;
};

export const getTouristBorderLeftColor = tourist => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    if (tourist?.[IS_CHECKED_OUT]) return theme.colors.default;
    else return theme.colors.success;
};

export const parseAndSortTourists = fetchedTourists => {
    const touristsRecords = fetchedTourists[RECORDS];
    const touristList = [];
    for (let tourist of touristsRecords) {
        const name = tourist[TOURIST_SURNAME_NAME];
        // TODO: Convert this dates to ISO string date and then convert them back
        const arrival = getDateMillisFromAspNetFormat(tourist[DATE_TIME_OF_ARRIVAL]);
        const departure = getDateMillisFromAspNetFormat(tourist[DATE_TIME_OF_DEPARTURE]);
        const isCheckedOut = tourist[CHECKOUT_OUT];
        const facility = tourist[FACILITY_NAME];
        const id = tourist[ID];
        touristList.push({id, name, arrival, departure, isCheckedOut, facility, ...tourist});
    }
    touristList.sort((a, b) => b.arrival - a.arrival);
    return touristList;
};

export const getTouristFacility = (tourist, evaccount) => {
    const facilityId = tourist?.[FACILITY_ID];
    if (facilityId) {
        const accomodationObject = evaccount?.[ACCOMODATION_OBJECT]?.find(o => o[FACILITY_ID] === facilityId);
        if (accomodationObject) {
            return accomodationObject;
        }
    }
    return null;
};
