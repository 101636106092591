import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, Pressable, View} from 'react-native';
import {CheckBox, Icon, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {
    AGREE,
    AND,
    PRIVACY_POLICY,
    PRIVOLA_2,
    PRIVOLA_3,
    PRIVOLA_4,
    PRIVOLA_5,
    PRIVOLA_6,
    PRIVOLA_7,
    PRIVOLA_8,
    PRIVOLA_9,
    PRIVOLA_CONTENT_TITLE,
    TERMS,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import useStyles from './ConsentDialog.styles';

import HtmlDialog, {PRIVACY_VIEW, TERMS_VIEW} from '../htmlviews/HtmlDialog';

const IS_WEB = Platform.OS === 'web';

const ConsentDialog = ({consentAccepted, disableButton, isGuest = false}) => {
    const {t} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const [open, setOpen] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [mainPrivola, setMainPrivola] = useState(false);
    const [state, setState] = useState({
        privolaTwo: false,
        privolaThree: false,
        privolaFour: false,
        privolaFive: false,
    });

    const {privolaTwo, privolaThree, privolaFour, privolaFive} = state;

    const handleChange = privola => {
        setState({...state, [privola]: !state[privola]});
    };

    const onRejectPrivola = () => {
        setState({
            privolaTwo: false,
            privolaThree: false,
            privolaFour: false,
            privolaFive: false,
        });
        setMainPrivola(false);
    };

    const closeAndReject = () => {
        setOpen(false);
        onRejectPrivola();
    };

    const closeAndAccept = () => {
        setOpen(false);
        setMainPrivola(true);
        consentAccepted(true);
    };

    const onPressTermsOfUseCheckBox = () => {
        setOpen(true);
    };

    const termsPreview = () => {
        setShowTerms(true);
    };

    const privacyPreview = () => {
        setShowPrivacy(true);
    };

    const termsOfUseComponent = (
        <Text style={[global.textMain, {fontSize: 12, lineHeight: 14, marginLeft: 10}]}>
            {t(AGREE)}
            <Text
                style={[global.link, {fontSize: 12, lineHeight: 14}, IS_WEB && {cursor: 'pointer'}]}
                onPress={termsPreview}>
                {' '}
                {t(TERMS)}{' '}
            </Text>
            {t(AND)}
            <Text
                style={[global.link, {fontSize: 12, lineHeight: 14}, IS_WEB && {cursor: 'pointer'}]}
                onPress={privacyPreview}>
                {' '}
                {t(PRIVACY_POLICY)}
            </Text>
        </Text>
    );

    useEffect(() => {
        const {privolaTwo, privolaThree, privolaFour, privolaFive} = state;
        const consentCurrentlyAccepted =
            [privolaTwo, privolaThree, privolaFour, privolaFive].filter(v => v).length === 4;
        if (consentCurrentlyAccepted) {
            closeAndAccept();
        } else {
            disableButton(true);
        }
    }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!mainPrivola) {
            disableButton(true);
        }
    }, [mainPrivola]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <CheckBox
                title={termsOfUseComponent}
                checked={mainPrivola}
                onPress={onPressTermsOfUseCheckBox}
                containerStyle={styles.consentCheckbox}
            />
            <Modal
                isVisible={open}
                onBackdropPress={closeAndReject}
                onBackButtonPress={closeAndReject}
                animationInTiming={1}
                animationOutTiming={1}
                backdropOpacity={0.6}>
                <View style={global.modalDialog}>
                    <View style={global.modalTitle}>
                        <Text style={global.modalTitleText}>{t(PRIVOLA_CONTENT_TITLE)}</Text>
                        <Pressable onPress={closeAndReject} style={global.modalCloseButton}>
                            <Icon
                                name="close"
                                containerStyle={global.modalCloseIcon}
                                color={theme.colors.white}
                                size={20}
                            />
                        </Pressable>
                    </View>

                    <View style={global.modalContent}>
                        <CheckBox
                            title={isGuest ? t(PRIVOLA_2) : t(PRIVOLA_6)}
                            checked={privolaTwo}
                            onPress={_ => handleChange('privolaTwo')}
                            containerStyle={styles.modalConsentCheckbox}
                            fontFamily={'Montserrat-Medium'}
                            textStyle={styles.modalConsentCheckboxText}
                            size={21}
                        />
                        <CheckBox
                            title={isGuest ? t(PRIVOLA_3) : t(PRIVOLA_7)}
                            checked={privolaThree}
                            onPress={_ => handleChange('privolaThree')}
                            containerStyle={styles.modalConsentCheckbox}
                            fontFamily={'Montserrat-Medium'}
                            textStyle={styles.modalConsentCheckboxText}
                            size={21}
                        />
                        <CheckBox
                            title={isGuest ? t(PRIVOLA_4) : t(PRIVOLA_8)}
                            checked={privolaFour}
                            onPress={_ => handleChange('privolaFour')}
                            containerStyle={styles.modalConsentCheckbox}
                            fontFamily={'Montserrat-Medium'}
                            textStyle={styles.modalConsentCheckboxText}
                            size={21}
                        />
                        <CheckBox
                            title={isGuest ? t(PRIVOLA_5) : t(PRIVOLA_9)}
                            checked={privolaFive}
                            onPress={_ => handleChange('privolaFive')}
                            containerStyle={[styles.modalConsentCheckbox, {marginBottom: 0}]}
                            fontFamily={'Montserrat-Medium'}
                            textStyle={styles.modalConsentCheckboxText}
                            size={21}
                        />
                    </View>
                </View>
            </Modal>

            {showTerms && <HtmlDialog isOpen={showTerms} setOpen={setShowTerms} view={TERMS_VIEW} />}
            {showPrivacy && <HtmlDialog isOpen={showPrivacy} setOpen={setShowPrivacy} view={PRIVACY_VIEW} />}
        </>
    );
};

export default ConsentDialog;
