export const head = `
<head>
    <meta charset="utf-8" />
    <title>eCheckin invoice</title>

    <style>
        body {
            margin: 0pt;
            padding: 0pt;
            font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
            font-size: 13px;
            line-height: 1.4;
            color: #444;
        }

        .page {
            aspect-ratio: 595/842;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            padding: 50px;
        }

        @media screen {
            .page.android,
            .page.ios {
                font-size: 18px;
                padding: 50px;
            }

            .page.android .header-info .date,
            .page.ios .header-info .date,
            .page.android .header-info .time,
            .page.ios .header-info .time,
            .page.android .in-name p,
            .page.ios .in-name p {
                font-size: 16px;
            }

            .page.android .header-title h1,
            .page.ios .header-title h1 {
                font-size: 30px;
            }

            .page.android #invoice-table th,
            .page.ios #invoice-table th {
                font-size: 15px;
            }

            .page.android .currency-rate,
            .page.ios .currency-rate {
                font-size: 12px;
            }  

            .page.android .operator,
            .page.ios .operator,
            .page.android .footer {
                font-size: 16px;
            }
        }

        @media print {
            html,
            body {
                margin: 0;
                padding: 0;
                height: 100%;
                -webkit-print-color-adjust: exact;
            }
            
            body {
                overflow: visible; 
            }

            .page {
                aspect-ratio: auto;
            }

            .page.web {
                min-height: 29.6cm;
                padding-top: 1.5cm;   
                padding-bottom: 1cm;
                padding-left: 1.5cm;   
                padding-right: 1.5cm;   
            }

            .page.ios {
                min-height: 100%;
                padding: 1.5cm;   
            }

            .page.android {
                min-height: 100%;
                padding: 0;
            }
        }

        *,
        *:focus {
            outline: none;
        }
        
        * {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }

        img {
            max-width: 100%;
        }

        p {
            margin: 0 0 5px 0;
        }

        main {
            flex: 1;
        }

        strong {
            color: #292929;
        }

        header {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #dfdfdf;
        }

        .header-info {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: space-between;
            -ms-flex-pack: space-between;
            justify-content: space-between;
        }
        
        .header-info .date,
        .header-info .time,
        .in-name p {
            text-align: right;
            margin-bottom: 3px;
            font-size: 12px;
        }

        .header-title h1 {
            font-size: 20px;
            margin-bottom: 0;
            margin-top: 20px;
            text-align: center;
        }
            
        header .logo img {
            display: block;
            height: 55px;
            width: auto;
            margin-bottom: 15px;
        }

        .owner-info {
            padding-right: 20px;
        }

        .in-name {
            padding-top: 10px;
        }

        .information {
            margin-bottom: 30px;
        }

        .guest-info {
            margin-bottom: 10px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            }
            
        th,
        td {
            padding: 0 0 10px 0;
        }

        #invoice-table th,
        #invoice-table td {
            width: 100px;
            min-width: 85px;
        }

        #invoice-table th {
            background-color: #eee;
            color: #292929;
            height: 35px;
            padding: 5px;
            text-align: center;
            text-transform: uppercase;
            font-size: 11px;
        }

        #invoice-table th:first-child,
        #invoice-table td:first-child {
            min-width: 170px;
            text-align: left;
        }

        #invoice-table th:first-child {
            padding-left: 10px;
        }

        #invoice-table td {
            height: 40px;
            padding: 5px;
            text-align: center;
        }

        .total {
            text-align: right;
            padding: 20px 20px 0 0;
            border-top: 1px solid #dfdfdf;
        }

        .total-price {
            margin-top: 30px;
        }

        .currency-rate {
            font-size: 10px;
        }   
        
        .operator {
            margin-top: 30px;
            margin-bottom: 30px;
            font-size: 12px;
        }

        .footer p {
            font-size: 12px;
            margin-bottom: 0;
        }

        .footer-operator p {
            text-align: left;
        }

        .footer-notes,
        .footer-user-text {
            text-align: center;
        }

        .footer-notes {
            padding-top: 10px;
        }

        .footer-user-text {
            border-top: 1px solid #dfdfdf;
            padding-top: 10px;
            margin-top: 10px;
        }

        @media only screen and (max-width: 768px) {
            .page.web {
                padding: 20px 10px;
            }

            #invoice-table {
                min-width: 580px;
            }

            .table-wrapper {
                overflow-x: auto;
            }
        }             
    </style>
</head>`;
