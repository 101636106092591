import React from 'react';
import {useTranslation} from 'react-i18next';
import {FlatList, Text, View} from 'react-native';
import {useTheme} from 'react-native-elements';
import {FACILITY_PAYMENT_INFO, ID, NOTE, NO_FACILITY} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import FacilityItem from './FacilityItem';

const FacilitiesList = ({facilities, evAccount}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();

    const render = ({item}) => <FacilityItem facility={item} evAccount={evAccount} />;
    const listEmpty = () => (
        <View style={global.listEmptyMsg}>
            <Text style={global.listEmptyMsgText}>{t(NO_FACILITY)}</Text>
        </View>
    );
    const listHeader = () => (
        <View
            style={[
                global.card,
                global.boxShadow,
                {padding: 15, backgroundColor: theme.colors.lightBg, marginBottom: 20, overflow: 'visible'},
            ]}>
            <Text style={[global.textCapsDark, {marginBottom: 5}]}>{t(NOTE) + ': '}</Text>
            <Text style={[global.textMain, {fontFamily: theme.fontFamilyMedium}]}>{t(FACILITY_PAYMENT_INFO)}</Text>
        </View>
    );

    return (
        <FlatList
            data={facilities}
            renderItem={render}
            keyExtractor={item => item[ID]}
            contentContainerStyle={[global.flatList, global.hasFAB, global.containerInner, {flexGrow: 1}]}
            ListEmptyComponent={listEmpty}
            ListHeaderComponent={listHeader}
        />
    );
};

export default FacilitiesList;
