import sanitizeHtml from 'sanitize-html';

export const sanitizeObject = data => {
    const sanitiziedOwner = {};
    if (data) {
        for (const key of Object.keys(data)) {
            sanitiziedOwner[key] = sanitizeHtml(data[key]);
        }
    }
    return sanitiziedOwner;
};
