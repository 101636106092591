import {ADDITIONAL_FIELDS, EVISITOR, id, PIN_ID} from '../../constants/stringsAndFields';
import {createGuestTemplate, prepareFacilityData} from '../../utils/guestUtils';

export const _openCameraAction = async (navigation, closeFab, checkin) => {
    closeFab();
    navigation.navigate('Scanner', {checkinId: checkin[id]});
};

export const openCameraAction = async (navigation, closeFab, evAccount, facility) => {
    closeFab();
    const facilityData = prepareFacilityData(evAccount, facility);
    if (facilityData) {
        navigation.navigate('Scanner', {
            callbackScreen: 'Guest',
            checkinId: 0,
            facilityData: facilityData,
            additionalParams: {calendarInput: true},
        });
    }
};

export const _openGalleryImportAction = async (navigation, closeFab, checkin) => {
    closeFab();
    navigation.navigate('Scanner', {checkinId: checkin[id], importFromGallery: 'true'});
};

export const openGalleryImportAction = async (navigation, closeFab, evAccount, facility) => {
    closeFab();
    const facilityData = prepareFacilityData(evAccount, facility);
    if (facilityData) {
        navigation.navigate('Scanner', {
            callbackScreen: 'Guest',
            checkinId: 0,
            facilityData: facilityData,
            additionalParams: {calendarInput: true},
            importFromGallery: 'true',
        });
    }
};

export const _onAddNewGuestClickAction = async (navigation, closeFab, checkin) => {
    closeFab();
    navigation.navigate('Guest', {checkinId: checkin[id], isNewGuest: true, manualInput: true});
};

export const onAddNewGuestClickAction = async (navigation, closeFab, evAccount, facility, dayPicked) => {
    closeFab();
    const guestTemplate = await createGuestTemplate(facility, evAccount, dayPicked);
    if (guestTemplate) {
        navigation.navigate('Guest', {
            checkinId: 0,
            isNewGuest: true,
            guestData: guestTemplate,
            calendarInput: true,
        });
    }
};

export const _onAddExistingGuestClickAction = async (navigation, closeFab, checkin) => {
    closeFab();
    const eVisitorPinId = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID];
    const cId = checkin?.[id] ?? checkinId;
    if (eVisitorPinId) {
        navigation.navigate('TouristsChooser', {
            checkinId: cId,
            eVisitorPinId: eVisitorPinId,
        });
    }
};

export const onAddExistingGuestClickAction = async (navigation, closeFab, evAccount, facility, dayPicked) => {
    closeFab();
    const eVisitorPinId = evAccount?.[PIN_ID];
    if (eVisitorPinId) {
        const facilityData = prepareFacilityData(evAccount, facility);
        if (facilityData) {
            navigation.navigate('TouristsChooser', {
                calendarInput: true,
                facilityData: facilityData,
                eVisitorPinId: eVisitorPinId,
                day: dayPicked,
            });
        }
    }
};
