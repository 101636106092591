import React, {useState, useCallback} from 'react';
import {useBetween} from 'use-between';
import {getDateFromDateString, getDateFromTime} from '../../utils/dateHelper';

const useDialog = () => {
    const [isClosedDateTimePicker, setIsClosedDateTimePicker] = useState(true);
    const closeDateTimePicker = useCallback(close => setIsClosedDateTimePicker(close), []);

    const [datePickerData, setDatePickerData] = useState(null);
    const updateDateTimePickerData = useCallback(data => setDatePickerData(data), []);
    const clearDateTimePickerData = useCallback(() => setDatePickerData(null), []);

    const openDateTimePicker = useCallback((mode, field, value) => {
        setDatePickerData({
            datePickerMode: mode,
            datePickerDate: mode === 'date' ? getDateFromDateString(value) : getDateFromTime(value),
            currentPickerField: field,
        });
        closeDateTimePicker(false);
    }, []);

    const [creditsDepletedOpen, setCreditsDepletedOpen] = useState(false);
    const openCreditsDepleted = useCallback(_ => setCreditsDepletedOpen(true), []);
    const closeCreditsDepleted = useCallback(_ => setCreditsDepletedOpen(false), []);

    const [useCreditsAfterExpireOpen, setUseCreditsAfterExpireOpen] = useState(false);
    const openCreditsAfterExpire = useCallback(_ => setUseCreditsAfterExpireOpen(true), []);
    const closeCreditsAfterExpire = useCallback(_ => setUseCreditsAfterExpireOpen(false), []);

    return {
        isClosedDateTimePicker,
        closeDateTimePicker,
        datePickerData,
        updateDateTimePickerData,
        clearDateTimePickerData,
        openDateTimePicker,
        creditsDepletedOpen,
        openCreditsDepleted,
        closeCreditsDepleted,
        useCreditsAfterExpireOpen,
        openCreditsAfterExpire,
        closeCreditsAfterExpire,
    };
};

export const useSharedDialogs = () => useBetween(useDialog);
