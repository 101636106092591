import AsyncStorage from '@react-native-async-storage/async-storage';
import {useIsFocused} from '@react-navigation/native';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SafeAreaView, View, useWindowDimensions} from 'react-native';
import {Button, Icon, Input, LinearProgress, useTheme} from 'react-native-elements';
import {REST_COMMUNICATION, fetchTourists, fetchTouristsAllPages} from '../api/inCheckinRestService';
import MessageDialog from '../components/MessageDialog';
import TouristDetailsDialog from '../components/TouristDetailsDialog';
import DateRangePicker from '../components/datetime/DateRangePicker';
import EVChooserDialog from '../components/tourists/EVChooserDialog';
import EVChooserHeader from '../components/tourists/EVChooserHeader';
import TouristsList from '../components/tourists/TouristsList';
import {useSharedDialogs} from '../components/tourists/useDialogs';
import {
    ACCOMODATION_OBJECT,
    COUNTRY_DATA,
    DOCUMENT_TYPE_DATA,
    DOWNLOAD_TOURISTS_LABEL,
    EV_ACCOUNTS_DATA,
    EV_ACCOUNT_ID,
    ID,
    PIN,
    PIN_ID,
    RECORDS,
    SEARCH_TOURISTS_LABEL,
    SELECT_PERIOD,
    SETTLEMENTS_DATA,
    STORAGE_CHOSEN_EV_ACCOUNT,
    TOURISTS_DATASET,
    content,
    id,
} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import globalStyle from '../theme/globalStyle';
import {findEvisitorById, findEvisitorByPin, findEvisitorByPinId, isEmpty, isNotEmpty} from '../utils/arrayHelpers';
import {checkBirthDayNotification, getDateTimeToIso, getDateTimeToIsoEndOfDay} from '../utils/dateHelper';
import {handleExportDataToXlsx} from '../utils/export';
import {
    createGuest,
    extendTouristPaymentCategory,
    getPaymentCategories,
    mergeFetchedTourists,
} from '../utils/guestUtils';
import {mapTouristToGuest, updateMaxGuestsCount} from '../utils/helpers';
import {DEFAULT_LANG} from '../utils/i18n';
import {debounceFunction} from '../utils/promiseHelpers';
import {parseAndSortTourists} from '../utils/touristUtils';
import {isEVAccountFetchedFacility, saveEVAccountFetchedFacilityFlag} from '../utils/userUtils';
import useStyles from './TouristsScreen.styles';
import UITextInput from '../components/ui-components/UITextInput';

export const TOURIST_PAGE_COUNT = 15;
const PAGE_ONE = 1;

const TouristsScreen = ({route, navigation}) => {
    const {
        clearErrors,
        apiErrors,
        apiMessages,
        evaccounts,
        setApiErrors,
        refetchEvisitor,
        eVisitorStaticData,
        getCheckin,
        getEvisitorByPin,
        onUpdateCheckin,
        onUpdateGuests,
    } = useContext(DataContext);
    const {
        checkinId = null,
        calendarInput = false,
        lang = DEFAULT_LANG,
        facilityData = null,
        day = null,
    } = route?.params ?? {};

    const isFocused = useIsFocused();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t} = useTranslation();
    const [tourists, setTourists] = useState([]);
    const [openMessage, setOpenMessage] = useState(false);

    const [evAccount, setEvAccount] = useState(null);
    const [progressOngoing, setProgressOngoing] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [focusSearch, setSearchFocus] = useState(false);
    const [search, setSearch] = useState('');
    const [evAccountSetupInProgress, setEvAccountSetupInProgress] = useState(false);
    const [openExportCalendarDialog, setOpenExportCalendarDialog] = useState(false);
    const [exportInProgress, setExportInProgress] = useState(false);
    const [selectedExportEvAccounts, setSelectedExportEvAccounts] = useState([]);
    const {openEvisitorDialog, closeEvisitorDialog, isOpenEvisitorChooser} = useSharedDialogs();

    const shouldStoreEVAccountChange = !checkinId && !calendarInput ? true : false;
    const shouldUseCheckinDates = checkinId || calendarInput ? true : false;
    const width = useWindowDimensions().width;
    const isMobile = width < theme.tabletBP;

    const countries = useMemo(() => eVisitorStaticData && eVisitorStaticData?.[COUNTRY_DATA], [eVisitorStaticData]);
    const citiesSettlements = useMemo(
        () => eVisitorStaticData && eVisitorStaticData?.[SETTLEMENTS_DATA],
        [eVisitorStaticData]
    );
    const documentTypes = useMemo(
        () => eVisitorStaticData && eVisitorStaticData?.[DOCUMENT_TYPE_DATA],
        [eVisitorStaticData]
    );

    const clearSearch = _ => {
        triggerSearch('', evAccount);
    };

    const triggerSearch = useCallback((text, evAccount) => {
        const delay = !text ? 100 : 2000;
        const delayedQuery = debounceFunction(() => {
            if (evAccount?.[PIN_ID]) {
                onChoosedEVAccountSearch(evAccount, text).catch(console.error);
            }
        }, delay);
        delayedQuery && delayedQuery();
        setSearch(text);
    }, []);

    const populateTourists = useCallback(fetchedTourists => {
        const sortedTourists = parseAndSortTourists(fetchedTourists);
        sortedTourists && setTourists(sortedTourists);
    }, []);

    const onChoosedEVAccountSearch = useCallback(async (evAccount, search) => {
        const pinId = evAccount?.[PIN_ID];
        if (pinId) {
            try {
                setProgressOngoing(true);
                const fetchedTourists = await fetchTourists(pinId, TOURIST_PAGE_COUNT, PAGE_ONE, search, null);
                if (fetchedTourists) {
                    populateTourists(fetchedTourists);
                } else {
                    setTourists([]);
                }
            } catch (e) {
                console.log(e);
                setApiErrors({signal: 'fetchTourists', message: e?.RESTErrors?.description ?? e?.message});
            } finally {
                setProgressOngoing(false);
            }
        }
    }, []);

    const fetchInit = async choosedEvAccount => {
        try {
            setProgressOngoing(true);
            const fetchedTourists = await fetchTourists(
                choosedEvAccount[PIN_ID],
                TOURIST_PAGE_COUNT,
                PAGE_ONE,
                null,
                null
            );
            fetchedTourists && populateTourists(fetchedTourists);
        } catch (e) {
            console.log(e);
            setApiErrors({signal: 'fetchTourists', message: e?.RESTErrors?.description ?? e?.message});
        } finally {
            setProgressOngoing(false);
            search !== '' && setSearch('');
        }
    };

    const getNextPageTourists = useCallback(
        async page => {
            const pinId = evAccount?.[PIN_ID];
            if (pinId) {
                try {
                    setProgressOngoing(true);
                    const fetchedTourists = await fetchTourists(pinId, TOURIST_PAGE_COUNT, page, search, null);
                    if (fetchedTourists) {
                        populateTourists(mergeFetchedTourists(tourists, fetchedTourists));
                        return fetchedTourists?.[RECORDS]?.length;
                    }
                } catch (e) {
                    console.log(e);
                    setApiErrors({signal: 'fetchTourists', message: e?.RESTErrors?.description ?? e?.message});
                } finally {
                    setProgressOngoing(false);
                }
            }
            return null;
        },
        [evAccount, tourists, search]
    );

    const onRefresh = async () => {
        if (evAccount) {
            setRefresh(true);
            await fetchInit(evAccount);
            setRefresh(false);
        }
    };

    const setupEvAccount = async () => {
        const eVisitorPinId = route.params?.eVisitorPinId;
        let eVisitorAcc = null;
        if (isNotEmpty(evaccounts)) {
            if (eVisitorPinId) {
                eVisitorAcc = eVisitorPinId ? findEvisitorByPinId(evaccounts, eVisitorPinId) : null;
            } else {
                const storedPin = await AsyncStorage.getItem(STORAGE_CHOSEN_EV_ACCOUNT);
                eVisitorAcc = storedPin ? findEvisitorByPin(evaccounts, storedPin) : null;
            }
            if (!eVisitorAcc) {
                eVisitorAcc = evaccounts[0];
            }
            if (eVisitorAcc) {
                setEvAccount(eVisitorAcc);
                shouldStoreEVAccountChange && (await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, eVisitorAcc[PIN]));
            }
        } else {
            setEvAccount(null);
        }
        return eVisitorAcc;
    };

    const exportExcelCheckins = _ => {
        setExportInProgress(true);
        openEvisitorDialog();
    };

    const onConfirmExport = useCallback(
        async (dateRange, optionalData) => {
            setOpenExportCalendarDialog(false);
            setProgressOngoing(true);
            try {
                const dateBoundary = {
                    startDate: getDateTimeToIso(dateRange.startDate),
                    endDate: getDateTimeToIsoEndOfDay(dateRange.endDate),
                };
                const fetchedTourists = {[RECORDS]: [], [EV_ACCOUNTS_DATA]: []};
                for (const evAccountId of selectedExportEvAccounts) {
                    const evAcc = findEvisitorById(evaccounts, evAccountId);
                    if (evAcc) {
                        try {
                            const fetchedTouristForAccount = await fetchTouristsAllPages(
                                evAcc?.[PIN_ID],
                                100,
                                1,
                                null,
                                null,
                                dateBoundary
                            );
                            if (fetchedTouristForAccount?.[RECORDS]) {
                                const expandedRecords = fetchedTouristForAccount[RECORDS].map(item => {
                                    item[EV_ACCOUNT_ID] = evAcc[ID];
                                    return item;
                                });
                                fetchedTourists[RECORDS] = [...fetchedTourists[RECORDS], ...expandedRecords];
                                fetchedTourists[EV_ACCOUNTS_DATA] = [...fetchedTourists[EV_ACCOUNTS_DATA], evAcc];
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }
                await handleExportDataToXlsx(
                    fetchedTourists[RECORDS],
                    fetchedTourists[EV_ACCOUNTS_DATA],
                    TOURISTS_DATASET,
                    dateRange,
                    countries,
                    t
                );
            } catch (e) {
                console.log(e);
            } finally {
                setProgressOngoing(false);
            }
        },
        [selectedExportEvAccounts, evaccounts]
    );

    const onChangeTextSearch = text => {
        triggerSearch(text, evAccount);
    };

    const onFocusSearch = _ => {
        setSearchFocus(true);
    };

    const onBlurSearch = _ => {
        setSearchFocus(false);
    };

    const checkAndFetchFacilities = async activeEvAccount => {
        const facilities = activeEvAccount ? activeEvAccount?.[ACCOMODATION_OBJECT] : [];
        if (isEmpty(facilities)) {
            const isAlreadyFetched = await isEVAccountFetchedFacility(activeEvAccount[PIN]);
            if (!isAlreadyFetched) {
                await refetchEvisitor(activeEvAccount);
                await saveEVAccountFetchedFacilityFlag(activeEvAccount[PIN]);
            }
        }
    };

    const initEvAccountData = async acc => {
        setEvAccountSetupInProgress(true);
        try {
            shouldStoreEVAccountChange && (await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, acc[PIN]));
            await checkAndFetchFacilities(acc);
            await fetchInit(acc);
        } catch (e) {
            console.log(e);
        } finally {
            setEvAccountSetupInProgress(false);
        }
    };

    const generateMessage = useCallback(() => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`;
        }
        if (apiMessages) {
            if (apiMessages?.signal === REST_COMMUNICATION) {
                return `${apiMessages?.message}`;
            }
            return `${apiMessages?.signal}: ${apiMessages?.message}`;
        }
        return null;
    }, [apiErrors, apiMessages]);

    const importTourist = async tourist => {
        const dbCheckin = await getCheckin(checkinId);
        if (dbCheckin && countries && citiesSettlements && documentTypes) {
            setProgressOngoing(true);
            try {
                let guest = mapTouristToGuest(tourist, countries, citiesSettlements, documentTypes);
                await updateMaxGuestsCount(dbCheckin[id], getCheckin, onUpdateCheckin);
                const paymentCategories = await getPaymentCategories(dbCheckin, getEvisitorByPin);
                if (paymentCategories) {
                    guest = extendTouristPaymentCategory(guest, paymentCategories);
                }
                const savedGuest = await createGuest(guest, dbCheckin, onUpdateGuests, true, shouldUseCheckinDates);
                await checkBirthDayNotification(savedGuest[content], dbCheckin, t);
            } catch (e) {
                console.log(e);
            } finally {
                setProgressOngoing(false);
            }
        }
    };

    useEffect(() => {
        if (isFocused) {
            clearErrors();
            setupEvAccount().catch(console.error);
        }
    }, [isFocused]);

    useEffect(() => {
        apiErrors && setOpenMessage(true);
        apiMessages && setOpenMessage(true);
    }, [apiMessages, apiErrors]);

    useEffect(() => {
        if (evaccounts && isFocused) {
            setupEvAccount().catch(console.error);
        }
    }, [evaccounts]);

    useEffect(() => {
        if (!evAccountSetupInProgress && isFocused && evaccounts && evAccount) {
            initEvAccountData(evAccount).catch(console.error);
        } else {
            setTourists([]);
        }
        return () => {
            setEvAccountSetupInProgress(false);
        };
    }, [evAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    // TODO: for mobile headerTitle should hold search and headerTitle set to null
    useEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <EVChooserHeader
                    evAccount={evAccount}
                    useSharedDialogs={useSharedDialogs}
                    global={global}
                    theme={theme}
                    isMobile={isMobile}
                    exportExcelCheckins={exportExcelCheckins}
                />
            ),
        });
    }, [evAccount]);

    useEffect(() => {
        if (!isOpenEvisitorChooser && exportInProgress) {
            setExportInProgress(false);
        }
    }, [isOpenEvisitorChooser]);

    const multiChooserExportCallback = async data => {
        closeEvisitorDialog();
        setSelectedExportEvAccounts(data);
        setOpenExportCalendarDialog(true);
    };

    return (
        <SafeAreaView style={[global.containerBg, isMobile && styles.containerMobile]}>
            {progressOngoing && (
                <LinearProgress style={styles.linearProgress} color={theme.colors.primary} variant="indeterminate" />
            )}
            <View style={global.filterNav}>
                <View
                    style={{
                        width: '100%',
                        maxWidth: 1080,
                        marginHorizontal: 'auto',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingVertical: 0,
                    }}>
                    <UITextInput
                        value={search}
                        renderErrorMessage={false}
                        iconLeft="search"
                        iconRight={search ? 'close' : null}
                        onRightIconPress={clearSearch}
                        placeholder={t(SEARCH_TOURISTS_LABEL)}
                        onChangeText={onChangeTextSearch}
                        style={styles.searchInput}
                        containerStyle={styles.searchContainer}
                    />
                    <Button
                        onPress={exportExcelCheckins}
                        title={t(DOWNLOAD_TOURISTS_LABEL)}
                        icon={{
                            type: 'ionicon',
                            name: 'download-outline',
                            size: 18,
                            color: theme.colors.white,
                            containerStyle: {marginRight: 5},
                        }}
                        containerStyle={[global.Button.containerStyle, {marginLeft: 10}]}
                        buttonStyle={[global.Button.buttonStyle, {paddingHorizontal: 10, paddingVertical: 5}]}
                        titleStyle={[global.Button.titleStyle, {textTransform: 'none'}]}
                    />
                </View>
            </View>
            {isFocused && (
                <MessageDialog
                    message={generateMessage()}
                    isError={apiErrors}
                    open={openMessage}
                    handleOpen={setOpenMessage}
                />
            )}
            {isFocused && (
                <EVChooserDialog
                    evAccount={evAccount}
                    evaccounts={evaccounts}
                    setEvAccount={setEvAccount}
                    useSharedDialogs={useSharedDialogs}
                    multiChooserActive={exportInProgress}
                    multiChooserCallback={multiChooserExportCallback}
                />
            )}
            <TouristsList
                tourists={tourists}
                evAccount={evAccount}
                checkinId={checkinId}
                navigation={navigation}
                refreshing={refresh}
                onRefresh={onRefresh}
                calendarInput={calendarInput}
                facilityData={facilityData}
                eVisitorStaticData={eVisitorStaticData}
                day={day}
                importTourist={importTourist}
                getNextPageTourists={getNextPageTourists}
            />
            <TouristDetailsDialog setApiErrors={setApiErrors} useSharedDialogs={useSharedDialogs} />
            <DateRangePicker
                open={openExportCalendarDialog}
                setOpen={setOpenExportCalendarDialog}
                onConfirm={onConfirmExport}
                optionalData={{tourists, evAccount}}
                label={t(SELECT_PERIOD)}
            />
        </SafeAreaView>
    );
};

export default TouristsScreen;
