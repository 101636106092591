import React from 'react';
import {useTranslation} from 'react-i18next';
import {FlatList, View, Pressable} from 'react-native';
import {ListItem, Text, Icon, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {INVOICE_FORM_OWNER, INVOICE_FORM_OWNER_ADDRESS, OWNER_LIST_TITLE} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';

const OwnerListSelect = ({isOpen, setOpen, onSelected, owners, callbackField = INVOICE_FORM_OWNER}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const onItemClick = async item => {
        onSelected(item, callbackField);
        handleClose();
    };

    const render = ({item}) => (
        <ListItem key={item} bottomDivider onPress={_ => onItemClick(item)}>
            <ListItem.Content>
                <ListItem.Title style={global.textMain}>
                    {item?.[INVOICE_FORM_OWNER]} : {item?.[INVOICE_FORM_OWNER_ADDRESS]}
                </ListItem.Title>
            </ListItem.Content>
        </ListItem>
    );

    return (
        <Modal
            isVisible={isOpen}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={[global.modalDialog, {maxWidth: 400}]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(OWNER_LIST_TITLE)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <FlatList data={owners} renderItem={render} keyExtractor={(item, index) => JSON.stringify(item)} />
            </View>
        </Modal>
    );
};

export default OwnerListSelect;
