import {
    fetchAccomodationObjectCharacetistics,
    fetchAccomodations,
    fetchFacilities,
    fetchPaymentCategory,
    REST_COMMUNICATION,
} from '../api/inCheckinRestService';
import {
    ACCOMODATION_OBJECT,
    ACCOMODATION_PAYMENT_CATEGORIES,
    ACCOMODATION_UNIT,
    AUTO_TOURIST_CHECKOUT,
    CHECKIN_CHECKOUT,
    CODE,
    EMAIL_FIELD,
    FACILITY_CODE,
    ID,
    NAME,
    PIN_ID,
    RECORDS,
} from '../constants/stringsAndFields';
import {concatAccomodationNames, concatFacilityNames, stringCleaner} from './helpers';

export const getEvisitorData = async (evisitorAccountData, pinId, eVisitorCounter, setApiMessages) => {
    const evAccountName = evisitorAccountData?.[NAME];
    const accomodationUnits = await fetchAccomodations(pinId);
    const evAccountFacility = concatFacilityNames(accomodationUnits[RECORDS]);
    const msg1 = `eVisitor ${eVisitorCounter} ${evAccountName}: ${evAccountFacility}`;
    setApiMessages({signal: REST_COMMUNICATION, message: msg1});

    const facilities = await fetchFacilities(pinId);
    const evAccountAccomodation = concatAccomodationNames(facilities[RECORDS]);
    const msg2 = `eVisitor ${eVisitorCounter} ${evAccountName}: ${evAccountAccomodation}`;
    setApiMessages({signal: REST_COMMUNICATION, message: msg2});

    const unitCodes = accomodationUnits?.[RECORDS]?.map(unit => unit[CODE]) ?? [];
    const accomodationObjects =
        facilities?.[RECORDS]?.filter(facility => unitCodes.includes(facility[FACILITY_CODE])) ?? [];

    let idx = 0;
    for (let accomodationUnit of accomodationUnits?.[RECORDS]) {
        try {
            const accomodationObjectCharacteristics = await fetchAccomodationObjectCharacetistics(
                pinId,
                accomodationUnit[ID]
            );
            accomodationUnits[RECORDS][idx][AUTO_TOURIST_CHECKOUT] =
                accomodationObjectCharacteristics[RECORDS][0][AUTO_TOURIST_CHECKOUT];
            accomodationUnits[RECORDS][idx][CHECKIN_CHECKOUT] =
                accomodationObjectCharacteristics[RECORDS][0][CHECKIN_CHECKOUT];
            accomodationUnits[RECORDS][idx][EMAIL_FIELD] = accomodationObjectCharacteristics[RECORDS][0][EMAIL_FIELD];
            accomodationUnits[RECORDS][idx][NAME] = stringCleaner(accomodationUnits[RECORDS][idx]?.[NAME]);

            const accomodationPaymentCategory = await fetchPaymentCategory(pinId, accomodationUnit[ID]);
            accomodationUnits[RECORDS][idx][ACCOMODATION_PAYMENT_CATEGORIES] = accomodationPaymentCategory[RECORDS];
            idx++;
        } catch (e) {
            console.log(e);
        }
    }
    setApiMessages(null);
    const dbEvisitor = evisitorAccountData;
    dbEvisitor[PIN_ID] = pinId;
    dbEvisitor[ACCOMODATION_OBJECT] = accomodationObjects;
    dbEvisitor[ACCOMODATION_UNIT] = accomodationUnits[RECORDS];
    return dbEvisitor;
};

export const getEvisitorDataLegacy = async (evisitorAccountData, pinId, eVisitorCounter, setApiMessages) => {
    const evAccountName = evisitorAccountData?.[NAME];
    const accomodationObjects = await fetchFacilities(pinId);
    // Logging 2
    const evAccountAccomodation = concatAccomodationNames(accomodationObjects[RECORDS]);
    const msg2 = `eVisitor ${eVisitorCounter} ${evAccountName}: ${evAccountAccomodation}`;
    setApiMessages({signal: REST_COMMUNICATION, message: msg2});

    const accomodationUnits = await fetchAccomodations(pinId);
    // Logging 3
    const evAccountFacility = concatFacilityNames(accomodationUnits[RECORDS]);
    const msg4 = `eVisitor ${eVisitorCounter} ${evAccountName}: ${evAccountFacility}`;
    setApiMessages({signal: REST_COMMUNICATION, message: msg4});

    let idx = 0;
    for (let accomodationUnit of accomodationUnits?.[RECORDS]) {
        try {
            const accomodationObjectCharacteristics = await fetchAccomodationObjectCharacetistics(
                pinId,
                accomodationUnit[ID]
            );
            accomodationUnits[RECORDS][idx][AUTO_TOURIST_CHECKOUT] =
                accomodationObjectCharacteristics[RECORDS][0][AUTO_TOURIST_CHECKOUT];
            accomodationUnits[RECORDS][idx][CHECKIN_CHECKOUT] =
                accomodationObjectCharacteristics[RECORDS][0][CHECKIN_CHECKOUT];
            accomodationUnits[RECORDS][idx][EMAIL_FIELD] = accomodationObjectCharacteristics[RECORDS][0][EMAIL_FIELD];
            accomodationUnits[RECORDS][idx][NAME] = stringCleaner(accomodationUnits[RECORDS][idx]?.[NAME]);

            const accomodationPaymentCategory = await fetchPaymentCategory(pinId, accomodationUnit[ID]);
            accomodationUnits[RECORDS][idx][ACCOMODATION_PAYMENT_CATEGORIES] = accomodationPaymentCategory[RECORDS];
            idx++;
        } catch (e) {
            console.log(e);
        }
    }
    setApiMessages(null);
    const dbEvisitor = evisitorAccountData;
    dbEvisitor[PIN_ID] = pinId;
    dbEvisitor[ACCOMODATION_OBJECT] = accomodationObjects[RECORDS];
    dbEvisitor[ACCOMODATION_UNIT] = accomodationUnits[RECORDS];
    console.log(dbEvisitor);
    return dbEvisitor;
};
