import React, {Suspense, useEffect, useState} from 'react';
import {ThemeProvider, useTheme} from 'react-native-elements';
import {initTheme, themes} from './theme/themes';
import globalStyle from './theme/globalStyle';
import {Platform, StatusBar, Text} from 'react-native';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {DataProvider} from './context/dataContext';
import {webRootCss} from './web/css';
import Navigation from './navigation';
import './utils/i18n';
import {initDb} from './database/sqlOperations';
import {FORCE_DB_CREATE} from './database/common';
import {registerCalendarLocale, registerPickerLang} from './utils/initWebPickerTranslations';
import {useTranslation} from 'react-i18next';
import NetInfo from '@react-native-community/netinfo';
import {ONLINE_CONNECTED_STATUS} from './constants/stringsAndFields';
import {GestureHandlerRootView} from 'react-native-gesture-handler';

const IS_WEB = Platform.OS === 'web';
const IS_ANDROID = Platform.OS === 'android';

const App = () => {
    const {t, i18n} = useTranslation();
    const [db, setDb] = useState();
    const [appTheme, setAppTheme] = useState(themes.echeckin);
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const [isConnected, setIsConnected] = useState(true);

    useEffect(() => {
        initTheme(setAppTheme).catch(console.error);
        initDb(db, FORCE_DB_CREATE).then(initializedDb => {
            setDb(initializedDb);
        });
        registerPickerLang(i18n);
    }, []);

    useEffect(() => {
        i18n.language && registerCalendarLocale(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(state => {
            setIsConnected(state.isConnected);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    const setAndroidStatusBar = () => {
        StatusBar.setBackgroundColor('#fff');
        StatusBar.setBarStyle('dark-content');
    };

    useEffect(() => {
        IS_ANDROID && setAndroidStatusBar;
    });

    return (
        <GestureHandlerRootView style={{flex: 1}}>
            <SafeAreaProvider>
                {IS_WEB && webRootCss}
                <ThemeProvider theme={appTheme}>
                    <Suspense fallback={null}>
                        {db && (
                            <DataProvider db={db}>
                                {!isConnected && (
                                    <Text style={global.connectionStatus}>{t(ONLINE_CONNECTED_STATUS)}</Text>
                                )}
                                <Navigation />
                            </DataProvider>
                        )}
                    </Suspense>
                </ThemeProvider>
            </SafeAreaProvider>
        </GestureHandlerRootView>
    );
};

export default App;
