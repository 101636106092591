import {
    ADDITIONAL_FIELDS,
    content,
    EVISITOR,
    EV_IMPORTED,
    FACILITY_FIELD,
    FORESEEN_STAY_UNTIL_FIELD,
    guests,
    guest,
    ID,
    PERIODS,
    POSITION,
    STATUS,
    STAY_FROM_FIELD,
    UPDATED_STATUS,
    CHECKIN_ID,
    id,
    GUEST_NOTE_FIELD,
} from '../constants/stringsAndFields';
import {CSS_COLOR_NAMES} from '../theme/colors';
import {getCalendarFormattedDateFromDateString, getDateFromDateString, getDatesBetween, isSameDay} from './dateHelper';
import {getGuestStatusBorderColor} from './guestUtils';
import {guestHasContentOrNote, isEmpty, isGuestEmpty} from './helpers';

export const getCalendarGuestDays = fullCheckins => {
    let guestKey = 0;
    const generateDot = (index = 0) => {
        guestKey++;
        return {key: guestKey, color: CSS_COLOR_NAMES[index].toLowerCase()};
    };

    const days = {};
    if (fullCheckins) {
        for (let checkin of fullCheckins) {
            const checkinGuests = checkin[guests];
            if (checkinGuests && checkinGuests.length > 0) {
                for (let checkinGuest of checkinGuests) {
                    const guestContent = checkinGuest[content];
                    if (!isGuestEmpty(guestContent)) {
                        const from = guestContent[STAY_FROM_FIELD];
                        const to = guestContent[FORESEEN_STAY_UNTIL_FIELD];

                        if (!guestContent.hasOwnProperty(ADDITIONAL_FIELDS)) {
                            guestContent[ADDITIONAL_FIELDS] = {};
                        }
                        guestContent[ADDITIONAL_FIELDS][EVISITOR] = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR];
                        guestContent[ADDITIONAL_FIELDS][FACILITY_FIELD] =
                            checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD];
                        guestContent[STATUS] = checkinGuest[STATUS];
                        try {
                            const fromDate = getDateFromDateString(from);
                            const toDate = getDateFromDateString(to);
                            const rangeDays = getDatesBetween(fromDate, toDate, true);
                            let rangeIdx = 0;
                            for (let rangeDay of rangeDays) {
                                const {formatted, date} = rangeDay;
                                if (!days[formatted]) {
                                    days[formatted] = {dots: [generateDot(0)], guests: [guestContent]};
                                } else {
                                    // if day is taken use next dot
                                    const dotsLen = days[formatted]['dots'].length + 1;
                                    days[formatted] = {
                                        dots: [...days[formatted]['dots'], generateDot(dotsLen)],
                                        guests: [...days[formatted]['guests'], guestContent],
                                    };
                                }
                                rangeIdx++;
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }
            }
        }
    }
    return days;
};

export const extractGuestsFromCheckins = checkins => {
    const checkinsGuests = [];
    for (let checkin of checkins) {
        const checkinGuests = checkin[guests];
        if (checkinGuests && checkinGuests.length > 0) {
            for (let checkinGuest of checkinGuests) {
                const guestContent = checkinGuest[content];
                if (guestHasContentOrNote(guestContent)) {
                    if (!checkinGuest.hasOwnProperty(ADDITIONAL_FIELDS)) {
                        checkinGuest[ADDITIONAL_FIELDS] = {};
                    }
                    checkinGuest[ADDITIONAL_FIELDS][EVISITOR] = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR];
                    checkinGuest[ADDITIONAL_FIELDS][FACILITY_FIELD] = checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD];
                    checkinGuest[ADDITIONAL_FIELDS][CHECKIN_ID] = checkin?.[id];
                    checkinGuest[ADDITIONAL_FIELDS][GUEST_NOTE_FIELD] =
                        guestContent?.[ADDITIONAL_FIELDS]?.[GUEST_NOTE_FIELD] ?? '';
                    checkinsGuests.push(checkinGuest);
                }
            }
        }
    }
    return checkinsGuests;
};

export const getCalendarGuestPeriods = fullCheckins => {
    let guestKey = 0;
    const generatePeriod = (index = 0, start, end) => {
        guestKey++;
        return {
            key: guestKey,
            startingDay: start,
            endingDay: end,
            color: CSS_COLOR_NAMES[index].toLowerCase(),
        };
    };

    const days = {};
    if (fullCheckins) {
        for (let checkin of fullCheckins) {
            const checkinGuests = checkin[guests];
            if (checkinGuests && checkinGuests.length > 0) {
                for (let checkinGuest of checkinGuests) {
                    const guestContent = checkinGuest[content];
                    if (!isGuestEmpty(guestContent)) {
                        const from = guestContent[STAY_FROM_FIELD];
                        const to = guestContent[FORESEEN_STAY_UNTIL_FIELD];

                        if (!guestContent.hasOwnProperty(ADDITIONAL_FIELDS)) {
                            guestContent[ADDITIONAL_FIELDS] = {};
                        }

                        guestContent[ADDITIONAL_FIELDS][EVISITOR] = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR];
                        guestContent[ADDITIONAL_FIELDS][FACILITY_FIELD] =
                            checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD];
                        guestContent[ADDITIONAL_FIELDS][EV_IMPORTED] = false;
                        guestContent[UPDATED_STATUS] = checkinGuest[UPDATED_STATUS];
                        guestContent[STATUS] = checkinGuest[STATUS];

                        try {
                            const fromDate = getDateFromDateString(from);
                            const toDate = getDateFromDateString(to);
                            const rangeDays = getDatesBetween(fromDate, toDate, true);
                            let rangeIdx = 0;

                            // TODO: have to henalde end and start dates
                            for (let rangeDay of rangeDays) {
                                const {formatted, dayDate} = rangeDay;
                                const isStart = isSameDay(fromDate, dayDate);
                                const isEnd = isSameDay(toDate, dayDate);

                                if (!days.hasOwnProperty[formatted]) {
                                    days[formatted] = {
                                        periods: [],
                                        guests: [],
                                    };
                                }
                                const periodLen = days[formatted][PERIODS].length + 1;
                                days[formatted] = {
                                    periods: [...days[formatted][PERIODS], generatePeriod(periodLen, isStart, isEnd)],
                                    guests: [...days[formatted][guests], guestContent],
                                };
                                rangeIdx++;
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }
            }
        }
    }
    return days;
};

export const getCalendarTouristsPeriods = (tourists, theme) => {
    const days = {};
    if (tourists) {
        tourists.sort(sortByStateFrom);

        for (let tourist of tourists) {
            const guestContent = tourist[content];
            const from = guestContent[STAY_FROM_FIELD];
            const to = guestContent[FORESEEN_STAY_UNTIL_FIELD];

            if (!guestContent.hasOwnProperty(ADDITIONAL_FIELDS)) {
                guestContent[ADDITIONAL_FIELDS] = {};
            }
            guestContent[ADDITIONAL_FIELDS][EVISITOR] = tourist?.[ADDITIONAL_FIELDS]?.[EVISITOR];
            guestContent[ADDITIONAL_FIELDS][FACILITY_FIELD] = tourist?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD];
            guestContent[ADDITIONAL_FIELDS][EV_IMPORTED] = tourist?.[ADDITIONAL_FIELDS]?.[EV_IMPORTED] ?? false;
            guestContent[ADDITIONAL_FIELDS][CHECKIN_ID] = tourist?.[CHECKIN_ID];
            guestContent[UPDATED_STATUS] = tourist?.[UPDATED_STATUS];
            guestContent[STATUS] = tourist?.[STATUS];

            try {
                const fromDate = getDateFromDateString(from);
                const toDate = getDateFromDateString(to);
                const rangeDays = getDatesBetween(fromDate, toDate, true);

                for (let rangeDay of rangeDays) {
                    const {formatted, dayDate} = rangeDay;
                    const isStart = isSameDay(fromDate, dayDate);
                    const isEnd = isSameDay(toDate, dayDate);

                    if (!days?.[formatted]) {
                        days[formatted] = {
                            periods: [],
                        };
                    }
                    const periodLen = days[formatted][PERIODS].length + 1;

                    const sortedPeriods = [
                        ...days[formatted][PERIODS],
                        generatePeriod(periodLen, isStart, isEnd, guestContent, theme),
                    ];

                    sortedPeriods.sort(sortPrioByStateFrom);
                    days[formatted][PERIODS] = sortedPeriods;
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    if (days && !isEmpty(days)) {
        const positions = storeInitialGuestPositions(days);
        appendPeriods(days, positions);
    }
    return days;
};

const generatePeriod = (index = 0, start, end, guest, theme) => {
    return {
        startingDay: start,
        endingDay: end,
        color: getGuestStatusBorderColor(guest, theme),
        guest: guest,
    };
};

const sortByStateFrom = (t1, t2) => {
    const fromDateT1 = getDateFromDateString(t1?.[content]?.[STAY_FROM_FIELD]);
    const fromDateT2 = getDateFromDateString(t2?.[content]?.[STAY_FROM_FIELD]);
    return Number(fromDateT1) - Number(fromDateT2);
};

const sortPrioByStateFrom = (t1, t2) => {
    const fromDateT1 = getDateFromDateString(t1?.[guest]?.[STAY_FROM_FIELD]);
    const fromDateT2 = getDateFromDateString(t2?.[guest]?.[STAY_FROM_FIELD]);
    return Number(fromDateT1) - Number(fromDateT2);
};

const storeInitialGuestPositions = days => {
    const guestPositions = {};
    for (const key of Object.keys(days)) {
        let idx = 0;
        // this is guest id and position for first occurence
        for (const period of days[key][PERIODS]) {
            const guestId = period[guest][ID];
            if (!guestPositions?.[guestId]) {
                guestPositions[guestId] = idx;
            }
            idx++;
        }
    }
    return guestPositions;
};

const appendPeriods = (days, guestPositions) => {
    for (const day of Object.keys(days)) {
        const periods = days[day][PERIODS];

        // append position to guests
        for (const period of periods) {
            const guestId = period[guest][ID];
            period[guest][POSITION] = guestPositions[guestId];
        }

        // find highest position
        let positions = periods.map(p => p[guest][POSITION]);
        let guestPositionMax = Math.max(...positions);

        for (const period of periods) {
            const from = period[guest][STAY_FROM_FIELD];
            const fromDate = getCalendarFormattedDateFromDateString(from);
            // this period starts from this day
            if (day === fromDate) {
                const myGuestId = period[guest][ID];
                const myPosition = period[guest][POSITION];
                // find guest with same position
                const overlapGuest = periods.find(p => p[guest][POSITION] === myPosition && p[guest][ID] !== myGuestId);
                // if overlap guest exist take next biggest position for current guest
                if (overlapGuest) {
                    period[guest][POSITION] = guestPositionMax + 1;
                    guestPositions[myGuestId] = guestPositionMax + 1;
                    guestPositionMax++;
                }
            }
        }

        const newPeriods = [];
        positions = periods.map(p => p[guest][POSITION]);
        guestPositionMax = Math.max(...positions);

        // fill gaps between positions
        let lastStoredPosition = -1;
        for (let i = 0; i <= guestPositionMax; i++) {
            // get periods with index positions
            const matchedPeriods = periods.filter(p => p[guest][POSITION] === i);

            // if multiple guests have same position and have been recalculated, skip iteration
            if (lastStoredPosition > -1 && lastStoredPosition === i) {
                //if (matchedPeriods && matchedPeriods.length > 0) {
                //console.log('Not continuing because I have more guests to add');
                //} else {
                continue;
                //}
            }

            let storeIndex = 0;
            // increment position for period
            for (const matchedPeriod of matchedPeriods) {
                const guestId = matchedPeriod[guest][ID];
                const initialPosition = guestPositions[guestId];
                const newPosition = guestPositions[guestId] + storeIndex;
                if (newPosition > initialPosition) {
                    // store recalculated position
                    guestPositions[guestId] = newPosition;
                }
                newPeriods.push(matchedPeriod);
                lastStoredPosition = newPosition;
                storeIndex++;
            }

            // gap filler
            if (matchedPeriods && matchedPeriods.length === 0) {
                newPeriods.push({color: 'transparent', empty: true});
            }
        }
        days[day][PERIODS] = newPeriods;
    }
};

/*
 markedDates={{
    '2017-10-25': {dots: [vacation, massage, workout], selected: true, selectedColor: 'red'},
    '2017-10-26': {dots: [massage, workout], disabled: true}
  }}
*/

/*
   if exist then add { starting, ending, color}
    '2017-12-14': {
      periods: [
        {startingDay: false, endingDay: true, color: '#5f9ea0'},
        {startingDay: false, endingDay: true, color: '#ffa500'},
        {startingDay: true, endingDay: false, color: '#f0e68c'}
      ]
    },
    '2017-12-15': {
      periods: [
        {startingDay: true, endingDay: false, color: '#ffa500'},
        {color: 'transparent'},
        {startingDay: false, endingDay: false, color: '#f0e68c'}
      ]
    }
*/
