import React, {memo} from 'react';
import {Icon, ListItem, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import useStyles from './CheckinItem.styles';
import {Text, View, useWindowDimensions, Pressable} from 'react-native';
import {
    ADDITIONAL_FIELDS,
    CHECKIN_NOTE_FIELD,
    FACILITY_FIELD,
    FORESEEN_STAY_UNTIL_FIELD,
    guests,
    id,
    INVOICE_EXISTS,
    NAME,
    NAME_CODE,
    SELF_CHECKIN_BUTTON_LABEL,
    STAY_FROM_FIELD,
} from '../../constants/stringsAndFields';
import {getShortFormattedDateFromDateString} from '../../utils/dateHelper';
import {getGuestsStatusesCounts} from '../../utils/guestUtils';
import {useNavigation} from '@react-navigation/native';
import isEqual from 'lodash/isEqual';
import {isManualGuestDate} from '../../utils/helpers';
import {useTranslation} from 'react-i18next';

const CheckinItem = ({checkin}) => {
    const navigation = useNavigation();
    const {theme} = useTheme();
    const {t} = useTranslation();
    const global = globalStyle(theme);
    const styles = useStyles(theme);

    const width = useWindowDimensions().width;
    const isDesktop = width >= theme.desktopBP;
    const isDesktopLarge = width >= 1300;

    const isManualGuestInput = isManualGuestDate(checkin);

    const showCheckinNote =
        checkin?.[ADDITIONAL_FIELDS]?.[CHECKIN_NOTE_FIELD] && checkin?.[ADDITIONAL_FIELDS]?.[CHECKIN_NOTE_FIELD] !== ''
            ? true
            : false;

    const {checkedInCount, checkoutedCount, guestsForCheckinCount, guestCount} = getGuestsStatusesCounts(
        checkin?.[guests]
    );

    const openDetailsScreen = checkin => {
        navigation.navigate('Checkin', {
            screen: 'CheckinScreen',
            params: {checkinId: checkin[id]},
        });
    };

    const onShareGuestClick = _ => {
        // TODO: maybe implement share dialog?
        openDetailsScreen(checkin);
    };

    return (
        <ListItem
            key={checkin[id]}
            onPress={() => openDetailsScreen(checkin)}
            containerStyle={[
                global.listItem,
                checkoutedCount !== 0 && {borderColor: theme.colors.default},
                checkedInCount !== 0 && {borderColor: theme.colors.success},
                guestsForCheckinCount !== 0 && {borderColor: theme.colors.error},
            ]}
            style={[global.boxShadow, {marginBottom: 15, borderRadius: 4}]}
            underlayColor="white">
            <View style={{flex: 1}}>
                <View style={[isDesktop && {flexDirection: 'row', alignItems: 'center'}, {flex: 1}]}>
                    <View
                        style={[
                            !isDesktop && {marginBottom: 5},
                            isDesktop && {width: '60%', paddingRight: 20},
                            isDesktopLarge && {width: '70%'},
                        ]}>
                        <View style={global.flexCenter}>
                            <Icon
                                type="ionicon"
                                name="home-outline"
                                size={16}
                                color={theme.colors.textColorMedium}
                                style={{marginRight: 10}}
                            />
                            <View style={{flex: 1}}>
                                <Text style={[global.textCaps, {lineHeight: 16}]}>
                                    {checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME]}
                                </Text>
                                <Text
                                    style={[
                                        global.textCaps,
                                        {fontSize: 10, color: theme.colors.textColorLight, marginTop: 2},
                                    ]}>
                                    {checkin?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME_CODE]}
                                </Text>
                            </View>
                            {checkin?.[INVOICE_EXISTS] && (
                                <View
                                    style={[
                                        global.flexCenter,
                                        {minWidth: 45, justifyContent: 'center'},
                                        isDesktop && {flex: 0.5},
                                    ]}>
                                    <Icon
                                        type="ionicon"
                                        name="receipt-outline"
                                        size={18}
                                        color={theme.colors.primary}
                                    />
                                </View>
                            )}
                        </View>
                    </View>

                    <View
                        style={[
                            {flexDirection: 'row', justifyContent: 'space-between'},
                            isDesktop && {width: '40%', paddingRight: 20},
                            !isDesktop && {marginTop: 5},
                            isDesktopLarge && {width: '30%'},
                        ]}>
                        {isManualGuestInput && (
                            <Pressable onPress={onShareGuestClick} style={styles.selfCheckinBtn}>
                                <Icon
                                    type="ionicon"
                                    name="share-social"
                                    size={16}
                                    color={theme.colors.primary}
                                    containerStyle={{marginRight: 8}}
                                />
                                <Text style={styles.selfCheckinBtnText}>{t(SELF_CHECKIN_BUTTON_LABEL)}</Text>
                            </Pressable>
                        )}
                        {!isManualGuestInput && (
                            <View style={[global.flexCenter, global.listItemIconTextWrapper, {minWidth: 140}]}>
                                <Icon
                                    type="ionicon"
                                    name="calendar-outline"
                                    size={16}
                                    color={theme.colors.textColorMedium}
                                    style={{marginRight: 8}}
                                />
                                <Text style={[global.textMain]}>
                                    {getShortFormattedDateFromDateString(checkin?.[STAY_FROM_FIELD])}
                                </Text>
                                <Text
                                    style={{
                                        color: theme.colors.greyLight,
                                        marginHorizontal: 6,
                                    }}>
                                    /
                                </Text>
                                <Text style={[global.textMain]}>
                                    {getShortFormattedDateFromDateString(checkin?.[FORESEEN_STAY_UNTIL_FIELD])}
                                </Text>
                            </View>
                        )}

                        <View style={global.flexCenter}>
                            {guestCount === 0 && (
                                <View
                                    style={[
                                        global.flexCenter,
                                        global.listItemIconTextWrapper,
                                        {marginLeft: 5, minWidth: 45},
                                    ]}>
                                    <Icon
                                        type="material-community"
                                        name="account"
                                        size={18}
                                        style={{marginRight: 5}}
                                        color={theme.colors.greyMedium}
                                    />
                                    <Text
                                        style={[
                                            global.textMain,
                                            {color: theme.colors.greyMedium, fontFamily: theme.fontFamilyMedium},
                                        ]}>
                                        {guestCount}
                                    </Text>
                                </View>
                            )}

                            {checkedInCount !== 0 && (
                                <View
                                    style={[
                                        global.flexCenter,
                                        global.listItemIconTextWrapper,
                                        {marginLeft: 5, minWidth: 45},
                                    ]}>
                                    <Icon
                                        type="material-community"
                                        name="account-check"
                                        size={18}
                                        style={{marginRight: 5}}
                                        color={theme.colors.success}
                                    />
                                    <Text
                                        style={[
                                            global.textMain,
                                            {color: theme.colors.success, fontFamily: theme.fontFamilyMedium},
                                        ]}>
                                        {checkedInCount}
                                    </Text>
                                </View>
                            )}

                            {checkoutedCount !== 0 && (
                                <View
                                    style={[
                                        global.flexCenter,
                                        global.listItemIconTextWrapper,
                                        {marginLeft: 5, minWidth: 45},
                                    ]}>
                                    <Icon
                                        type="material-community"
                                        name="account-arrow-up"
                                        size={18}
                                        style={{marginRight: 5}}
                                        color={theme.colors.default}
                                    />
                                    <Text
                                        style={[
                                            global.textMain,
                                            {color: theme.colors.default, fontFamily: theme.fontFamilyMedium},
                                        ]}>
                                        {checkoutedCount}
                                    </Text>
                                </View>
                            )}

                            {guestsForCheckinCount !== 0 && (
                                <View
                                    style={[
                                        global.flexCenter,
                                        global.listItemIconTextWrapper,
                                        {marginLeft: 5, minWidth: 45},
                                    ]}>
                                    <Icon
                                        type="material-community"
                                        name="account-alert"
                                        size={18}
                                        style={{marginRight: 5}}
                                        color={theme.colors.error}
                                    />
                                    <Text
                                        style={[
                                            global.textMain,
                                            {color: theme.colors.error, fontFamily: theme.fontFamilyMedium},
                                        ]}>
                                        {guestsForCheckinCount}
                                    </Text>
                                </View>
                            )}
                        </View>
                    </View>
                </View>
                {showCheckinNote && (
                    <View style={global.listNote}>
                        <Icon
                            name="edit"
                            size={12}
                            color={theme.colors.textColorLight}
                            containerStyle={{marginRight: 8, top: 1}}
                        />
                        <Text style={global.listNoteText}>{checkin?.[ADDITIONAL_FIELDS]?.[CHECKIN_NOTE_FIELD]}</Text>
                    </View>
                )}
            </View>
        </ListItem>
    );
};

//export default CheckinItem;
function arePropsEqual(prevProps, nextProps) {
    //return JSON.stringify(prevProps.checkin) === JSON.stringify(nextProps.checkin);
    return isEqual(prevProps.checkin, nextProps.checkin);
}

export default memo(CheckinItem, arePropsEqual);
