import React, {memo, useEffect, useMemo, useState} from 'react';
import {
    FlatList,
    RefreshControl,
    View,
    Text,
    useWindowDimensions,
    TouchableOpacity,
    ActivityIndicator,
} from 'react-native';
import {useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {useTranslation} from 'react-i18next';
import {LOAD_MORE, NO_INVOICES} from '../../constants/stringsAndFields';
import InvoiceItem from './InvoiceItem';
import {INVOICES_PAGE_COUNT, INVOICE_FIRST_PAGE} from '../../screens/InvoicesScreen';

const InvoicesList = ({
    invoices,
    refreshing,
    onRefresh,
    navigation,
    invoicesHeader,
    evAccount,
    getNextPageInvoices,
}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const width = useWindowDimensions().width;
    const [page, setPage] = useState(INVOICE_FIRST_PAGE);
    const [loading, setLoading] = useState(false);

    const getData = async _ => {
        setLoading(true);
        const incrementedPage = page + 1;
        const results = await getNextPageInvoices(incrementedPage);
        if (results === INVOICES_PAGE_COUNT) {
            setPage(incrementedPage);
        }
        setLoading(false);
    };

    const onRefreshPress = async _ => {
        await onRefresh(evAccount);
    };

    const render = ({item}) => <InvoiceItem invoice={item} navigation={navigation} />;

    const memoizedContainerStyle = useMemo(() => {
        const isDesktop = width > 1500;
        const global = globalStyle(theme);
        return [global.flatList, {width: '100%', maxWidth: 1100, alignSelf: 'center'}, isDesktop && {paddingTop: 25}];
    }, [width, theme]);

    const emptyList = <MemoizedEmptyList t={t} global={global} />;

    const renderFooter = () => {
        return invoices?.length === (page + 1) * INVOICES_PAGE_COUNT ? (
            <View style={styles.footer}>
                <TouchableOpacity
                    activeOpacity={0.9}
                    onPress={getData}
                    style={[global.outlineButton, styles.loadMoreBtn]}>
                    {loading ? (
                        <ActivityIndicator color={theme.colors.primary} style={[{marginLeft: 8}, {maxHeight: 8}]} />
                    ) : (
                        <Text style={[global.outlineButtonTitle, {textTransform: 'none', fontSize: 12}]}>
                            {t(LOAD_MORE)}
                        </Text>
                    )}
                </TouchableOpacity>
            </View>
        ) : null;
    };

    useEffect(() => {
        if (invoices?.length === INVOICES_PAGE_COUNT) {
            setPage(INVOICE_FIRST_PAGE);
        }
    }, [invoices?.length]);

    return (
        <FlatList
            data={invoices}
            renderItem={render}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefreshPress} />}
            ListHeaderComponent={invoicesHeader}
            contentContainerStyle={memoizedContainerStyle}
            ListEmptyComponent={emptyList}
            ListFooterComponent={renderFooter}
        />
    );
};

export default InvoicesList;

export function EmptyList({t, global}) {
    return (
        <View style={global.listEmptyMsg}>
            <Text style={global.listEmptyMsgText}>{t(NO_INVOICES)}</Text>
        </View>
    );
}
export const MemoizedEmptyList = memo(EmptyList);

const styles = {
    footer: {
        padding: 7,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    loadMoreBtn: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 160,
        minHeight: 38,
        paddingHorizontal: 0,
    },
};
