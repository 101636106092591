import React from 'react';
import {useTranslation} from 'react-i18next';
import {FlatList, Pressable, View} from 'react-native';
import {Icon, ListItem, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {CLIENT_LIST_TITLE, NAME} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';

const ClientListSelect = ({isOpen, setOpen, onSelected, clients}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const onItemClick = async item => {
        onSelected(item);
        handleClose();
    };

    const render = ({item}) => (
        <ListItem key={item} bottomDivider onPress={_ => onItemClick(item)}>
            <ListItem.Content>
                <ListItem.Title style={global.textMain}>{item?.[NAME] ?? ''}</ListItem.Title>
            </ListItem.Content>
        </ListItem>
    );

    return (
        <Modal
            isVisible={isOpen}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={[global.modalDialog, {maxWidth: 400}]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(CLIENT_LIST_TITLE)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <FlatList data={clients} renderItem={render} keyExtractor={(item, index) => item?.[NAME] + index} />
            </View>
        </Modal>
    );
};

export default ClientListSelect;
