import React, {useEffect, useState} from 'react';
import {Input} from 'react-native-elements';
import {View, Text} from 'react-native';
import {
    INVOICE_FORM_NUMBER,
    INVOICE_PROFORMA_NUMBER_LABEL,
    INVOICE_NR_FIELD,
    INVOICE_NUMBER_LABEL,
    INVOICE_PAY_DESK_FIELD,
    INVOICE_P_UNIT_FIELD,
} from '../../constants/stringsAndFields';
import {isInvoiceSelected} from '../../utils/helpers';
import useStyles from './InvoiceNumberEditor.styles';

// this comoponent could be potentialy improved by introduction of controller for input fields
const InvoiceNumberEditor = ({invoiceType, initialInvoiceFormNumber, setValue, t, theme, global}) => {
    const styles = useStyles(theme);

    // N - number
    const [n, setN] = useState(initialInvoiceFormNumber?.[0] ?? '1');
    const allowOnlyDigitsN = text => {
        setN(text.replace(/[^0-9]/g, ''));
    };

    // P - poslovna jedinica (string)
    const [p, setP] = useState(initialInvoiceFormNumber?.[1] ?? '1');
    const allowOnlyCharsP = text => {
        setP(text.replace(/[^a-z0-9]/gi, ''));
    };

    // B - blagajna (isključivo broj)
    const [b, setB] = useState(initialInvoiceFormNumber?.[2] ?? '1');
    const allowOnlyDigitsB = text => {
        setB(text.replace(/[^0-9]/g, ''));
    };

    useEffect(() => {
        setValue(INVOICE_FORM_NUMBER, `${n}-${p}-${b}`);
    }, [n, p, b]);

    const [invoiceNumberFocusN, setInvoiceNumberFocusN] = useState(false);
    const turnOnInvoiceNumberFocusN = () => {
        setInvoiceNumberFocusN(true);
    };
    const turnOffInvoiceNumberFocusN = () => {
        setInvoiceNumberFocusN(false);
    };

    const [invoiceNumberFocusP, setInvoiceNumberFocusP] = useState(false);
    const turnOnInvoiceNumberFocusP = () => {
        setInvoiceNumberFocusP(true);
    };
    const turnOffInvoiceNumberFocusP = () => {
        setInvoiceNumberFocusP(false);
    };

    const [invoiceNumberFocusB, setInvoiceNumberFocusB] = useState(false);
    const turnOnInvoiceNumberFocusB = () => {
        setInvoiceNumberFocusB(true);
    };
    const turnOffInvoiceNumberFocusB = () => {
        setInvoiceNumberFocusB(false);
    };

    return (
        <>
            <View style={[global.flexCenter, {justifyContent: 'center'}]}>
                <Text
                    style={{
                        color: '#656565',
                        textTransform: 'uppercase',
                        fontSize: 11,
                        fontFamily: theme.fontFamilySemibold,
                        marginRight: 5,
                    }}>
                    {isInvoiceSelected(invoiceType) ? t(INVOICE_NUMBER_LABEL) : t(INVOICE_PROFORMA_NUMBER_LABEL)}
                </Text>
                <Text style={{fontSize: 11, color: '#656565', textTransform: 'uppercase'}}>
                    ({t(INVOICE_NR_FIELD)} - {t(INVOICE_P_UNIT_FIELD)} - {t(INVOICE_PAY_DESK_FIELD)})
                </Text>
            </View>

            <View
                style={[
                    styles.invoiceNumberEditorWrapper,
                    (invoiceNumberFocusN || invoiceNumberFocusP || invoiceNumberFocusB) &&
                        styles.invoiceNumberEditorWrapperFocused,
                ]}>
                <Input
                    value={n}
                    keyboardType="numeric"
                    onChangeText={allowOnlyDigitsN}
                    inputContainerStyle={styles.inputContainerStyle}
                    containerStyle={styles.inputContainer}
                    inputStyle={styles.inputStyle}
                    onFocus={turnOnInvoiceNumberFocusN}
                    onBlur={turnOffInvoiceNumberFocusN}
                />
                <Text style={styles.inputDivider}>-</Text>
                <Input
                    value={p}
                    onChangeText={allowOnlyCharsP}
                    inputContainerStyle={styles.inputContainerStyle}
                    containerStyle={styles.inputContainer}
                    inputStyle={styles.inputStyle}
                    onFocus={turnOnInvoiceNumberFocusP}
                    onBlur={turnOffInvoiceNumberFocusP}
                />
                <Text style={styles.inputDivider}>-</Text>
                <Input
                    value={b}
                    keyboardType="numeric"
                    onChangeText={allowOnlyDigitsB}
                    inputContainerStyle={styles.inputContainerStyle}
                    containerStyle={styles.inputContainer}
                    inputStyle={styles.inputStyle}
                    onFocus={turnOnInvoiceNumberFocusB}
                    onBlur={turnOffInvoiceNumberFocusB}
                />
            </View>
        </>
    );
};

export default InvoiceNumberEditor;
