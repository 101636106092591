import React from 'react';
import {useTranslation} from 'react-i18next';
import {FlatList, View, Pressable} from 'react-native';
import {ListItem, Text, Icon, useTheme} from 'react-native-elements';
import globalStyle from '../theme/globalStyle';
import {code, LANGUAGE_TITLE, name} from '../constants/stringsAndFields';
import Modal from 'react-native-modal';

const LangSelect = ({isOpen, setOpenLangSelect, onLangSelected, langList}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();

    const handleClose = () => {
        setOpenLangSelect(false);
    };

    const onItemClick = async item => {
        onLangSelected(item);
        handleClose();
    };

    const render = ({item}) => (
        <ListItem key={item[code]} bottomDivider onPress={_ => onItemClick(item)}>
            <ListItem.Content style={{alignItems: 'center'}}>
                <ListItem.Title style={global.textMain}>{item[name]}</ListItem.Title>
            </ListItem.Content>
        </ListItem>
    );

    return (
        <Modal
            isVisible={isOpen}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={[global.modalDialog, {maxWidth: 300}]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(LANGUAGE_TITLE)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>
                <FlatList data={langList} renderItem={render} keyExtractor={(item, index) => item[code]} />
            </View>
        </Modal>
    );
};

export default LangSelect;
