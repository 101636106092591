import {saveAs} from 'file-saver';

export const writeXlsxToFile = async (workbook, fileName, t) => {
    try {
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        saveAs(blob, fileName);
    } catch (e) {
        console.log(e);
    }
};

export const writeBufferXlsxToFile = async (buffer, fileName, t) => {
    try {
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        saveAs(blob, fileName);
    } catch (e) {
        console.log(e);
    }
};
