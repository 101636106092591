import React, {memo} from 'react';
import {Text, View} from 'react-native';
import {Icon, ListItem, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {
    content,
    id,
    INVOICE_FORM_CURRENCY,
    INVOICE_FORM_DATE,
    INVOICE_FORM_OVERNIGHT_COUNT,
    INVOICE_FORM_NUMBER,
    INVOICE_FORM_PRICE_FINAL,
    OVERNIGHT,
} from '../../constants/stringsAndFields';
import {useWindowDimensions} from 'react-native';
import {getDateString} from '../../utils/dateHelper';
import {useTranslation} from 'react-i18next';
import isEqual from 'lodash/isEqual';

const InvoiceItem = ({invoice, closeInvoices, navigation}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const invoiceContent = invoice?.[content];

    const width = useWindowDimensions().width;
    const isDesktop = width >= theme.desktopBP;
    const isTablet = width >= 500 && width < theme.desktopBP;
    const isMobileSmall = width < 500;
    const {t} = useTranslation();

    const openDetailsScreen = async _ => {
        if (invoice) {
            closeInvoices();
            navigation.navigate('Invoice', {invoiceId: invoice[id]});
        }
    };

    return (
        <ListItem
            key={invoice[id]}
            onPress={openDetailsScreen}
            containerStyle={[global.listItem, {borderColor: theme.colors.primary}]}
            style={[global.boxShadow, {marginBottom: 15, borderRadius: 4}]}
            underlayColor="white">
            <View style={[isDesktop && {flexDirection: 'row', alignItems: 'center'}, {flex: 1}]}>
                <View
                    style={[
                        !isDesktop && {marginBottom: 20},
                        isTablet && {paddingRight: 180},
                        isDesktop && {width: '25%'},
                    ]}>
                    <View style={global.flexCenter}>
                        <Icon
                            type="ionicon"
                            name="receipt-outline"
                            size={18}
                            style={{marginRight: 8}}
                            color={theme.colors.primary}
                        />
                        <Text
                            style={[
                                global.textMain,
                                {fontFamily: theme.fontFamilyMedium, color: theme.colors.primary},
                            ]}>
                            {invoiceContent?.[INVOICE_FORM_NUMBER]}
                        </Text>
                    </View>
                </View>

                <View
                    style={[
                        !isDesktop && {marginBottom: 10, marginLeft: 3},
                        isTablet && {marginBottom: 0, paddingRight: 180},
                        isDesktop && {width: '25%'},
                    ]}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Icon type="ionicon" name="calendar-outline" size={16} style={{marginRight: 8}} />
                        <Text style={[global.textMain]}>{getDateString(invoiceContent?.[INVOICE_FORM_DATE])}</Text>
                    </View>
                </View>

                <View
                    style={[
                        !isDesktop && {marginLeft: 3},
                        isTablet && {position: 'absolute', right: 10},
                        isDesktop && {
                            width: '25%',
                            alignItems: 'center',
                            paddingHorizontal: 20,
                            justifyContent: 'center',
                        },
                        isMobileSmall && {marginBottom: 15},
                    ]}>
                    <View style={[global.flexCenter, isMobileSmall && {alignSelf: 'flex-start'}]}>
                        <Icon type="ionicon" name="bed-outline" size={16} style={{marginRight: 8}} />
                        <Text style={[global.textMain]}>{OVERNIGHT}: </Text>
                        <Text style={[global.textMain, {fontFamily: theme.fontFamilyMedium}]}>
                            {invoiceContent?.[INVOICE_FORM_OVERNIGHT_COUNT]}
                        </Text>
                    </View>
                </View>

                <View
                    style={[
                        {flexDirection: 'row', justifyContent: 'space-between'},
                        !isDesktop && {marginLeft: 3},
                        isTablet && {position: 'absolute', right: 10, bottom: 0},
                        isDesktop && {
                            width: '25%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingHorizontal: 20,
                        },
                    ]}>
                    <View style={global.flexCenter}>
                        <Text
                            style={[
                                global.checkinLabel,
                                {color: theme.colors.primary, fontSize: 12, backgroundColor: 'rgba(95, 80, 178, 0.1)'},
                            ]}>
                            {invoiceContent?.[INVOICE_FORM_PRICE_FINAL]} {invoiceContent?.[INVOICE_FORM_CURRENCY]}
                        </Text>
                    </View>
                </View>
            </View>

            <ListItem.Chevron containerStyle={global.listItemChevronContainer} />
        </ListItem>
    );
};

function arePropsEqual(prevProps, nextProps) {
    return isEqual(prevProps.invoice, nextProps.invoice);
}

export default memo(InvoiceItem, arePropsEqual);
