import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    textTitle: {
        fontSize: 24,
        marginBottom: 20,
    },

    textMain: {
        marginBottom: 10,
    },
}));

export default useStyles;
