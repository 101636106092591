import React from 'react';
import {useTranslation} from 'react-i18next';
import {View, Pressable} from 'react-native';
import {Button, Text, Icon, useTheme} from 'react-native-elements';
import globalStyle from '../theme/globalStyle';
import {ACCEPT, DECLINE, NO, YES} from '../constants/stringsAndFields';
import Modal from 'react-native-modal';

const ConfirmDialog = ({
    title,
    content,
    open = false,
    setOpen,
    onConfirm,
    isDelete = false,
    onReject = null,
    showButtons = true,
    data = null,
}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const close = () => {
        setOpen(false);
        onReject && onReject();
    };

    const confirm = async _ => {
        setOpen(false);
        if (data) {
            await onConfirm(data);
        } else {
            await onConfirm();
        }
    };

    return (
        <Modal
            isVisible={open}
            onBackdropPress={close}
            onBackButtonPress={close}
            backdropTransitionOutTiming={0}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                {title && (
                    <View style={global.modalTitle}>
                        <Text style={global.modalTitleText}>{title}</Text>
                        <Pressable onPress={close} style={global.modalCloseButton}>
                            <Icon
                                name="close"
                                containerStyle={global.modalCloseIcon}
                                color={theme.colors.white}
                                size={20}
                            />
                        </Pressable>
                    </View>
                )}

                {content && (
                    <View style={[global.modalContent, {paddingVertical: 30}]}>
                        <Text style={global.modalContentText}>{content}</Text>
                    </View>
                )}

                {showButtons && (
                    <View style={global.modalFooter}>
                        <Button
                            onPress={close}
                            title={isDelete ? t(NO) : t(DECLINE)}
                            containerStyle={global.ModalButton.Negative.containerStyle}
                            buttonStyle={global.ModalButton.Negative.buttonStyle}
                            titleStyle={global.ModalButton.Negative.titleStyle}
                            disabledStyle={global.ModalButton.disabledStyle}
                            disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                        />
                        <Button
                            onPress={confirm}
                            title={isDelete ? t(YES) : t(ACCEPT)}
                            containerStyle={global.ModalButton.Positive.containerStyle}
                            buttonStyle={global.ModalButton.Positive.buttonStyle}
                            titleStyle={global.ModalButton.Positive.titleStyle}
                            disabledStyle={global.ModalButton.disabledStyle}
                            disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                        />
                    </View>
                )}
            </View>
        </Modal>
    );
};

export default ConfirmDialog;
