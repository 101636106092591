import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    checkinModal: {
        height: 400,
        maxHeight: 400,
    },

    modalContent: {
        flex: 1,
        padding: 10,
    },

    modalListItem: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.greyLight,
    },
}));

export default useStyles;
