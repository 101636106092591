import NetInfo from '@react-native-community/netinfo';
import {Platform} from 'react-native';
import {APP_VERSION, HEADER_ANDROID, HEADER_IOS, HEADER_SELF_CHECKIN, HEADER_WEB} from '../constants/stringsAndFields';

export async function isOffline() {
    const state = await NetInfo.fetch();
    //console.log("NetInfo is connected?", state.isConnected);
    return !state.isConnected;
}

export async function isOnline() {
    const isConnected = await isOffline();
    return !isConnected;
}

export const getXDevice = () => {
    if (Platform.OS === 'android') return HEADER_ANDROID;
    if (Platform.OS === 'ios') return HEADER_IOS;
    if (Platform.OS === 'web') {
        try {
            const currentUrl = new URL(window.location.href);
            if (currentUrl?.pathname?.includes('self-checkin')) return HEADER_SELF_CHECKIN;
        } catch (e) {
            console.log(e);
        }
        return HEADER_WEB;
    }
};

export const getXAppVersion = () => {
    if (Platform.OS === 'android') return `e-an ${APP_VERSION}`;
    if (Platform.OS === 'ios') return `e-ios ${APP_VERSION}`;
    if (Platform.OS === 'web') return `e-web ${APP_VERSION}`;
    return `e-unknown ${APP_VERSION}`;
};
