import {useCallback, useState} from 'react';
import {useBetween} from 'use-between';
import {getSwipeButtonType as createSwipeButtonType} from '../../utils/guestUtils';

const useCheckinActions = () => {
    const [toggleCheckinSwipeState, setToggleCheckinSwipeState] = useState(false);
    const handleCheckinSwipeToggle = value => setToggleCheckinSwipeState(value);

    const [guestForCheckin, setGuestForCheckin] = useState(null);
    const prepareGuestForCheckin = useCallback(value => setGuestForCheckin(value), []);

    const [isSwipeReset, setIsSwipeReset] = useState(false);
    const resetSwipeButton = useCallback(value => setIsSwipeReset(value), []);

    const [swipeButtonType, setSwipeButtonType] = useState(null);
    const prepareSwipeButtonType = useCallback(value => setSwipeButtonType(createSwipeButtonType(value)), []);

    const [fastCheckinInProgress, setFastCheckinInProgress] = useState(false);
    const startFastCheckinInProgress = useCallback(() => setFastCheckinInProgress(true), []);
    const endFastCheckinInProgress = useCallback(() => setFastCheckinInProgress(false), []);

    const [isOpenActionsDialog, setIsOpenActionsDialog] = useState(null);
    const showActionsDialog = useCallback(action => setIsOpenActionsDialog(action), []);
    const hideActionsDialog = useCallback(() => setIsOpenActionsDialog(null), []);

    const [checkoutAction, setCheckoutAction] = useState(null);
    const triggerCheckoutAction = useCallback(value => setCheckoutAction(value), []);

    return {
        guestForCheckin,
        prepareGuestForCheckin,
        toggleCheckinSwipeState,
        handleCheckinSwipeToggle,
        isSwipeReset,
        resetSwipeButton,
        swipeButtonType,
        prepareSwipeButtonType,
        fastCheckinInProgress,
        startFastCheckinInProgress,
        endFastCheckinInProgress,
        isOpenActionsDialog,
        showActionsDialog,
        hideActionsDialog,
        checkoutAction,
        triggerCheckoutAction,
    };
};

export const useSharedCheckinActions = () => useBetween(useCheckinActions);
