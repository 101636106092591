import {Share} from 'react-native';

export const share = async (title, text, url) => {
    if (navigator.share) {
        // Web Share API is supported
        try {
            await Share.share({
                message: text + ' ' + url,
                url: url,
                title: title,
            });
        } catch (e) {
            console.log(e);
        }
    } else {
        console.log('Web Share API is not supported');
    }
};

export const sharePdfNative = async (title, text, filePath) => {
    try {
        await Share.share({
            title: title,
            message: text,
            url: filePath,
        });
    } catch (e) {
        console.log(e);
    }
};

export const sharePdfBase64Native = async (title, text, data) => {
    try {
        await Share.share({
            title: title,
            message: text,
            url: 'data:application/pdf;base64,' + data,
        });
    } catch (e) {
        console.log(e);
    }
};
