import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable, View} from 'react-native';
import {Icon, ListItem, Text, useTheme} from 'react-native-elements';
import {AMOUNT, FACILITY_NAME, GO_TO_PAYMENT, ID, PIN_ID, YOUR_DEBT} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import {toBase64} from '../../utils/jwt';

const FacilityItem = ({facility, evAccount}) => {
    const navigation = useNavigation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();

    const handleOpenPayment = async () => {
        navigation.navigate('FinancePayment', {
            [ID]: facility[ID],
            [PIN_ID]: evAccount[PIN_ID],
            [FACILITY_NAME]: toBase64(facility[FACILITY_NAME]),
        });
    };

    return (
        <ListItem
            key={facility[ID]}
            containerStyle={[global.card, {padding: 0}]}
            style={[global.boxShadow, {marginBottom: 15, borderRadius: 10}]}>
            <View style={{flex: 1}}>
                <View style={{padding: 15, borderBottomWidth: 1, borderBottomColor: theme.colors.borderLight}}>
                    <Text style={[global.cardHeaderText, {paddingBottom: 15, lineHeight: 16}]}>
                        {facility?.[FACILITY_NAME]}
                    </Text>
                    <View style={global.flexCenter}>
                        <Text style={[global.textMain, {fontFamily: theme.fontFamilyMedium, fontSize: 16}]}>
                            {t(YOUR_DEBT)}
                        </Text>
                        <Text
                            style={[
                                global.textMain,
                                {color: theme.colors.primary, fontFamily: theme.fontFamilySemibold, fontSize: 16},
                            ]}>
                            {facility?.[AMOUNT]}
                        </Text>
                    </View>
                </View>

                <Pressable
                    onPress={handleOpenPayment}
                    style={[
                        ({pressed}) => ({
                            opacity: pressed ? 0.5 : 1,
                        }),
                    ]}>
                    <View
                        style={[
                            global.cardFooter,
                            {borderTopWidth: 0, backgroundColor: theme.colors.white, justifyContent: 'flex-end'},
                        ]}>
                        <View style={global.flexCenter}>
                            <Text style={global.link}>{t(GO_TO_PAYMENT)}</Text>
                            <Icon type="ionicon" name="chevron-forward" size={18} color={theme.colors.primary} />
                        </View>
                    </View>
                </Pressable>
            </View>
        </ListItem>
    );
};

export default FacilityItem;
