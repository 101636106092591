import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    packagesWrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        maxWidth: 900,
        marginVertical: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        alignSelf: 'center',
    },

    noteWrapper: {
        paddingTop: 15,
        paddingHorizontal: 20,
        paddingBottom: 30,
    },

    note: {
        fontSize: 12,
        color: theme.colors.textColorMedium,
        textAlign: 'center',
    },
}));

export default useStyles;
