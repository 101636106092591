import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import bg from '../translations/bg.json';
import zh from '../translations/zh.json';
import cs from '../translations/cs.json';
import da from '../translations/da.json';
import de from '../translations/de.json';
import et from '../translations/et.json';
import en from '../translations/en.json';
import es from '../translations/es.json';
import fi from '../translations/fi.json';
import fr from '../translations/fr.json';
import hr from '../translations/hr.json';
import hu from '../translations/hu.json';
import hi from '../translations/hi.json';
import it from '../translations/it.json';
import ja from '../translations/ja.json';
import ko from '../translations/ko.json';
import lt from '../translations/lt.json';
import lv from '../translations/lv.json';
import nb from '../translations/nb.json';
import nl from '../translations/nl.json';
import pl from '../translations/pl.json';
import pt from '../translations/pt.json';
import ro from '../translations/ro.json';
import ru from '../translations/ru.json';
import si from '../translations/si.json';
import sk from '../translations/sk.json';
import sv from '../translations/sv.json';
import tr from '../translations/tr.json';
import ua from '../translations/ua.json';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {STORAGE_LANGUAGE} from '../constants/stringsAndFields';

export const DEFAULT_LANG = 'hr';
export const FALLBACK_LANG = 'en';

const languageDetector = {
    type: 'languageDetector',
    async: true,
    detect: async cb => {
        const storedLang = await AsyncStorage.getItem(STORAGE_LANGUAGE);
        if (storedLang) {
            cb(storedLang);
        } else {
            cb(DEFAULT_LANG);
        }
    },
    init: () => {},
    cacheUserLanguage: () => {},
};

i18n.use(languageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            bg: {
                translation: {
                    ...bg,
                },
            },
            zh: {
                translation: {
                    ...zh,
                },
            },
            cs: {
                translation: {
                    ...cs,
                },
            },
            da: {
                translation: {
                    ...da,
                },
            },
            de: {
                translation: {
                    ...de,
                },
            },
            et: {
                translation: {
                    ...et,
                },
            },
            en: {
                translation: {
                    ...en,
                },
            },
            es: {
                translation: {
                    ...es,
                },
            },
            fi: {
                translation: {
                    ...fi,
                },
            },
            fr: {
                translation: {
                    ...fr,
                },
            },
            hr: {
                translation: {
                    ...hr,
                },
            },
            hu: {
                translation: {
                    ...hu,
                },
            },
            hi: {
                translation: {
                    ...hi,
                },
            },
            it: {
                translation: {
                    ...it,
                },
            },
            ja: {
                translation: {
                    ...ja,
                },
            },
            ko: {
                translation: {
                    ...ko,
                },
            },
            lt: {
                translation: {
                    ...lt,
                },
            },
            lv: {
                translation: {
                    ...lv,
                },
            },
            nb: {
                translation: {
                    ...nb,
                },
            },
            nl: {
                translation: {
                    ...nl,
                },
            },
            pl: {
                translation: {
                    ...pl,
                },
            },
            pt: {
                translation: {
                    ...pt,
                },
            },
            ro: {
                translation: {
                    ...ro,
                },
            },
            ru: {
                translation: {
                    ...ru,
                },
            },
            si: {
                translation: {
                    ...si,
                },
            },
            sk: {
                translation: {
                    ...sk,
                },
            },
            sv: {
                translation: {
                    ...sv,
                },
            },
            tr: {
                translation: {
                    ...tr,
                },
            },
            ua: {
                translation: {
                    ...ua,
                },
            },
        },
        fallbackLng: FALLBACK_LANG,
        compatibilityJSON: 'v3',
        debug: false,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
