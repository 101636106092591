import React from 'react';
import {useTranslation} from 'react-i18next';
import {Icon, ListItem, Text, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {View, Pressable} from 'react-native';
import Modal from 'react-native-modal';
import {
    CALL_ICON,
    CONTACT_VIA,
    EMAIL,
    EMAIL_CONTACT,
    EMAIL_CONTACT_SUBJECT,
    EMAIL_ICON,
    WHATS_APP,
} from '../../constants/stringsAndFields';
import {sendEmail, sendMessage} from '../../utils/contact';

const ContactDialog = ({setVisible, visible}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();

    const toggleOverlay = () => {
        setVisible(!visible);
    };

    const onEmail = async _ => {
        try {
            await sendEmail(EMAIL_CONTACT, EMAIL_CONTACT_SUBJECT);
        } catch (e) {
            console.log(e);
        }
    };

    const onMessage = async _ => {
        try {
            await sendMessage();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Modal
            isVisible={visible}
            onBackdropPress={toggleOverlay}
            onBackButtonPress={toggleOverlay}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(CONTACT_VIA)}</Text>
                    <Pressable onPress={toggleOverlay} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <ListItem key={EMAIL} bottomDivider onPress={onEmail}>
                    <Icon name={EMAIL_ICON} color={theme.colors.greyLight} />
                    <ListItem.Content>
                        <ListItem.Title style={global.textMain}>{t(EMAIL)}</ListItem.Title>
                    </ListItem.Content>
                    <ListItem.Chevron />
                </ListItem>

                <ListItem key={WHATS_APP} bottomDivider onPress={onMessage}>
                    <Icon name={CALL_ICON} color={theme.colors.greyLight} />
                    <ListItem.Content>
                        <ListItem.Title style={global.textMain}>{WHATS_APP}</ListItem.Title>
                    </ListItem.Content>
                    <ListItem.Chevron />
                </ListItem>
            </View>
        </Modal>
    );
};

export default ContactDialog;
