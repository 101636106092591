import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Switch, Pressable} from 'react-native';
import {Icon, ListItem, Text, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';

import {updateAccomodationObjectCharacetistics} from '../../api/inCheckinRestService';
import {
    ACCOMODATION_OBJECT,
    ACCOMODATION_UNIT,
    AUTO_CHECKOUT,
    AUTO_TOURIST_CHECKOUT,
    CODE,
    FACILITY_CODE,
    ID,
    NAME,
    NAME_CODE,
    PIN,
} from '../../constants/stringsAndFields';
import {isString} from '../../utils/helpers';

const EVAccountItem = ({evaccount, onDelete, setOpenProgress, refetchEvisitor}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const [isAutoCheckout, setIsAutoCheckout] = useState(false);

    const handleDeleteClick = () => {
        onDelete(evaccount);
    };

    const handleRefreshClick = async () => {
        await refetchEvisitor(evaccount);
    };

    const handleAutoCheckoutChange = async val => {
        setOpenProgress(true);
        try {
            await updateAccomodationObjectCharacetistics(evaccount, val);
        } catch (e) {
            console.log(e);
        }
        setOpenProgress(false);
    };

    useEffect(() => {
        if (evaccount) {
            const accUnit = evaccount?.[ACCOMODATION_UNIT]?.[0];
            if (accUnit) {
                const autoCheckout = accUnit?.[AUTO_TOURIST_CHECKOUT];
                const autoCheckoutVal =
                    autoCheckout === true || (isString(autoCheckout) && autoCheckout.startsWith('DA')) ? true : false;
                setIsAutoCheckout(autoCheckoutVal);
            }
        }
    }, [evaccount, [...evaccount?.[ACCOMODATION_UNIT].map(u => u[AUTO_TOURIST_CHECKOUT])]]);

    const getAccomodationObjects = code => {
        const accObjects = evaccount?.[ACCOMODATION_OBJECT]?.filter(accObject => accObject[FACILITY_CODE] === code);
        return (
            <View>
                {accObjects &&
                    accObjects?.map((accObject, index) => (
                        <View key={String(index) + (accObject?.[NAME_CODE] ?? '')} style={[global.flexCenter]}>
                            <Icon
                                type="ionicon"
                                name="bed-outline"
                                size={16}
                                color={theme.colors.primary}
                                style={{marginRight: 10, position: 'relative', top: 1}}
                            />
                            <Text style={[global.textCaps, {fontSize: 11, color: theme.colors.textColorLight}]}>
                                {accObject?.[NAME_CODE]}
                            </Text>
                        </View>
                    ))}
            </View>
        );
    };

    const getAccomodationUnits = () =>
        evaccount?.[ACCOMODATION_UNIT]?.map(unit => {
            return (
                <React.Fragment key={unit?.[ID]}>
                    <View
                        style={{
                            paddingHorizontal: 15,
                            paddingVertical: 15,
                            borderBottomWidth: 1,
                            borderBottomColor: theme.colors.borderLight,
                        }}>
                        <View style={[global.flexCenter, {marginBottom: 8}]}>
                            <Icon
                                type="ionicon"
                                name="home-outline"
                                size={16}
                                color={theme.colors.primary}
                                style={{marginRight: 10}}
                            />
                            <Text style={[global.textCaps, {fontSize: 12, lineHeight: 18, flex: 1}]}>
                                {unit?.[NAME]}
                            </Text>
                        </View>

                        {getAccomodationObjects(unit?.[CODE])}
                    </View>
                </React.Fragment>
            );
        });

    return (
        <ListItem
            key={evaccount[PIN]}
            containerStyle={[global.card, {padding: 0}]}
            style={[global.boxShadow, {marginBottom: 15, borderRadius: 10}]}>
            <View style={{flex: 1}}>
                <View style={global.cardHeader}>
                    <Text style={[global.cardHeaderText]}>{evaccount?.[NAME]}</Text>
                    <Pressable
                        onPress={handleRefreshClick}
                        style={[
                            ({pressed}) => ({
                                opacity: pressed ? 0.5 : 1,
                            }),
                        ]}>
                        <Icon type="ionicon" name="refresh-outline" size={20} color={theme.colors.primary} />
                    </Pressable>
                </View>
                <View>{getAccomodationUnits()}</View>

                <View style={[global.cardFooter, {borderTopWidth: 0}]}>
                    <View style={[global.flexCenter]}>
                        <Text style={[global.textMain, {fontSize: 13, marginRight: 20}]}>{t(AUTO_CHECKOUT)}</Text>
                        <Switch
                            value={isAutoCheckout}
                            onValueChange={handleAutoCheckoutChange}
                            trackColor={{
                                false: theme.colors.greyLight,
                                true: '#aca7ca',
                            }}
                            ios_backgroundColor={theme.colors.greyLight}
                            thumbColor={isAutoCheckout ? theme.colors.primary : theme.colors.borderLight}
                            activeThumbColor={theme.colors.primary} // web only
                        />
                    </View>

                    <Pressable
                        onPress={handleDeleteClick}
                        style={[
                            ({pressed}) => ({
                                opacity: pressed ? 0.5 : 1,
                            }),
                        ]}>
                        <Icon type="ionicon" name="trash-outline" size={20} color={theme.colors.primary} />
                    </Pressable>
                </View>
            </View>
        </ListItem>
    );
};

export default EVAccountItem;
