import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    accountBox: {
        backgroundColor: theme.colors.white,
        borderRadius: 10,
        overflow: 'hidden',
    },

    accountCredits: {padding: 15},

    accountItem: {
        padding: 15,
        borderTopColor: theme.colors.greyExtraLight,
        borderTopWidth: 1,
    },

    itemTitle: {
        fontFamily: theme.fontFamilySemibold,
        fontSize: 14,
        lineHeight: 30,
    },

    itemValue: {
        fontFamily: theme.fontFamily,
        fontSize: 14,
        lineHeight: 30,
    },

    itemValuePrimary: {
        color: theme.colors.primary,
        fontFamily: theme.fontFamilySemibold,
        fontSize: 14,
        lineHeight: 30,
    },

    sectionTitle: {
        fontFamily: theme.fontFamilyMedium,
        fontSize: 14,
        lineHeight: 30,
    },

    accountCreditsDesktop: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    creditsBtnContainer: {
        alignSelf: 'center',
        marginHorizontal: 20,
    },

    note: {
        color: theme.colors.textColorMedium,
        textAlign: 'center',
    },

    creditsNote: {
        alignItems: 'center',
        marginTop: 10,
    },
}));

export default useStyles;
