import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    invoiceNumberEditorWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderColor: '#d6d6d6',
        marginBottom: 20,
        justifyContent: 'center',
    },

    invoiceNumberEditorWrapperFocused: {
        borderColor: theme.colors.primary,
    },

    inputContainerStyle: {
        marginBottom: 0,
    },

    inputContainer: {
        paddingLeft: 5,
        paddingRight: 5,
        width: 70,
    },

    inputStyle: {
        borderBottomWidth: 0,
        width: '100%',
        textAlign: 'center',
        color: '#292929',
        fontSize: 14,
        fontFamily: theme.fontFamilyMedium,
    },

    inputDivider: {
        color: '#a8a8a8',
        marginHorizontal: 3,
    },
}));

export default useStyles;
