import React from 'react';
import {DatePickerModal, TimePickerModal} from 'react-native-paper-dates';
import {DATE_OF_BIRTH_FIELD, FORESEEN_STAY_UNTIL_FIELD, STAY_FROM_FIELD} from '../../constants/stringsAndFields';
import {get120YearsAgo, get20YearsAgo, getDateFromDateString, getYesterday} from '../../utils/dateHelper';
import WithProviders from './PaperPickersTheme';
import {useTranslation} from 'react-i18next';
import {isBirthDayField} from '../../utils/helpers';

const FormDateTimePicker = ({closed, setClosed, onChangeDate, onChangeTime, datePickerData}) => {
    const {
        datePickerMode = 'date',
        datePickerDate,
        currentPickerField = FORESEEN_STAY_UNTIL_FIELD,
        checkinData,
        dateBoundary,
        rawDateValue = null,
    } = datePickerData ?? {};
    const {i18n} = useTranslation();
    const isVisible = !closed ? true : false;

    const hidePicker = () => {
        setClosed(true);
    };

    const onDatePickerDateChange = async params => {
        hidePicker();
        const {hours, minutes, date} = params;
        if (params) {
            if (datePickerMode === 'date') {
                await onChangeDate(date, currentPickerField);
            } else {
                // create date from hours and minutes
                const selectedDate = new Date();
                selectedDate.setMinutes(minutes);
                selectedDate.setSeconds(0);
                selectedDate.setHours(hours);
                await onChangeTime(selectedDate, currentPickerField);
            }
        }
    };

    const dateBoundaries = () => {
        if (checkinData && currentPickerField === FORESEEN_STAY_UNTIL_FIELD) {
            return {
                startDate: getDateFromDateString(checkinData[STAY_FROM_FIELD]),
                endDate: null,
                disabledDates: [],
            };
        } else if (currentPickerField === DATE_OF_BIRTH_FIELD) {
            return {
                startDate: get120YearsAgo(),
                endDate: getYesterday(),
                disabledDates: [],
            };
        } else if (dateBoundary) {
            return {
                startDate: dateBoundary?.['startDate'],
                endDate: dateBoundary?.['endDate'] ?? null,
                disabledDates: [],
            };
        }
        return {};
    };

    const currentDate = isBirthDayField(currentPickerField) && !rawDateValue ? get20YearsAgo() : datePickerDate;
    // single date pick
    const datePicker = (
        <DatePickerModal
            visible={isVisible}
            mode="single"
            onDismiss={hidePicker}
            date={currentDate}
            onConfirm={onDatePickerDateChange}
            validRange={dateBoundaries()}
            animationType="none"
            locale={i18n.language}
        />
    );

    // single time pick
    const timePicker = (
        <TimePickerModal
            visible={isVisible}
            onDismiss={hidePicker}
            onConfirm={onDatePickerDateChange}
            hours={datePickerDate ? datePickerDate?.getHours() : null} // default: current hours
            minutes={datePickerDate ? datePickerDate?.getMinutes() : null} // default: current minutes
            animationType="none"
            locale={i18n.language}
        />
    );

    const picker = datePickerMode === 'date' ? datePicker : timePicker;
    const pickerComponent = <>{isVisible && picker}</>;

    return <WithProviders component={pickerComponent} />;
};

export default FormDateTimePicker;
