import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    searchInput: {
        borderRadius: 40,
    },

    searchContainer: {
        flex: 1,
        maxWidth: 210,
        marginBottom: 0,
    },

    linearProgress: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 50,
        zIndex: 2,
    },
}));

export default useStyles;
