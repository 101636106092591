import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    container: {
        paddingVertical: 5,
    },

    timeFields: {
        flexDirection: 'row',
    },

    timeFieldInputContainer: {
        width: '50%',
    },

    timeFieldInput: {
        width: '100%',
    },
}));

export default useStyles;
