import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Pressable, ScrollView, Platform, Linking} from 'react-native';
import {Button, Icon, Text, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {
    ADD_EVISITOR_DIALOG_TITLE,
    EVISITOR_401_NEGATIVE,
    EVISITOR_401_POSITIVE,
    EVISITOR_401_TITLE,
    EVISITOR_401_TXT,
    EVISITOR_401_TXT_URL,
    EVISITOR_401_URL,
    EVISITOR_CONNECT,
    EVISITOR_NOTE,
    EVISITOR_PASS,
    EVISITOR_USERNAME,
} from '../../constants/stringsAndFields';
import Modal from 'react-native-modal';
import UITextInput from '../ui-components/UITextInput';

const IS_WEB = Platform.OS === 'web';

const AddEvisitorDialog = ({open, setOpen, onConnect, evAccountError, setEvAccountError}) => {
    const {t} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const [user, setUser] = useState();
    const [password, setPassword] = useState();
    const [hidePassword, setHidePassword] = useState(true);
    const [disableConfirm, setDisableConfirm] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setUser();
        setPassword();
        setEvAccountError(false);
    };

    const onConfirmClick = async () => {
        await onConnect({pin: user, pin_password: password});
    };

    const onRetryClick = async () => {
        setEvAccountError(false);
    };

    const openWeb = () => {
        Linking.openURL(t(EVISITOR_401_URL));
    };

    const setUserField = text => setUser(text);
    const setPasswordField = text => setPassword(text);

    useEffect(() => {
        if (user && user.length > 1 && password && password.length > 1) {
            setDisableConfirm(false);
        } else {
            setDisableConfirm(true);
        }
    }, [user, password]);

    return (
        <Modal
            isVisible={open}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}
            avoidKeyboard={true}>
            <View style={[global.modalDialog, {maxHeight: '100%'}]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>
                        {evAccountError ? t(EVISITOR_401_TITLE) : t(ADD_EVISITOR_DIALOG_TITLE)}
                    </Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>
                {evAccountError ? (
                    <>
                        <ScrollView contentContainerStyle={global.modalContent}>
                            <Text style={[global.textIntro, {marginBottom: 15}]}>{t(EVISITOR_401_TXT)}</Text>
                            <Text
                                style={[
                                    global.link,
                                    {textAlign: 'center'},
                                    {fontSize: 16},
                                    IS_WEB && {cursor: 'pointer'},
                                    {marginBottom: 15},
                                ]}
                                onPress={openWeb}>
                                {EVISITOR_401_TXT_URL}
                            </Text>
                        </ScrollView>
                        <View style={global.modalFooter}>
                            <Button
                                title={t(EVISITOR_401_POSITIVE)}
                                onPress={handleClose}
                                containerStyle={[global.ModalButton.Negative.containerStyle, {minWidth: 110}]}
                                buttonStyle={global.ModalButton.Negative.buttonStyle}
                                titleStyle={global.ModalButton.Negative.titleStyle}
                            />
                            <Button
                                title={t(EVISITOR_401_NEGATIVE)}
                                onPress={onRetryClick}
                                containerStyle={[global.ModalButton.Positive.containerStyle, {minWidth: 110}]}
                                buttonStyle={global.ModalButton.Positive.buttonStyle}
                                titleStyle={global.ModalButton.Positive.titleStyle}
                            />
                        </View>
                    </>
                ) : (
                    <>
                        <ScrollView contentContainerStyle={global.modalContent}>
                            <Text style={[global.textIntro, {marginBottom: 15}]}>{t(EVISITOR_NOTE)}</Text>
                            <UITextInput
                                label={t(EVISITOR_USERNAME)}
                                iconLeft="person-outline"
                                onChangeText={setUserField}
                            />
                            <UITextInput
                                label={t(EVISITOR_PASS)}
                                secureTextEntry={hidePassword}
                                iconLeft="lock-closed-outline"
                                iconRight={hidePassword ? 'eye-outline' : 'eye-off-outline'}
                                onRightIconPress={_ => setHidePassword(!hidePassword)}
                                onChangeText={setPasswordField}
                            />
                        </ScrollView>
                        <View style={global.modalFooter}>
                            <Button
                                onPress={onConfirmClick}
                                disabled={disableConfirm}
                                title={t(EVISITOR_CONNECT)}
                                containerStyle={global.ModalButton.Positive.containerStyle}
                                buttonStyle={global.ModalButton.Positive.buttonStyle}
                                titleStyle={global.ModalButton.Positive.titleStyle}
                                disabledStyle={global.ModalButton.disabledStyle}
                                disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                            />
                        </View>
                    </>
                )}
            </View>
        </Modal>
    );
};

export default AddEvisitorDialog;
