import {useIsFocused} from '@react-navigation/native';
import React, {useContext, useEffect} from 'react';
import {BackHandler, Platform, SafeAreaView, ScrollView} from 'react-native';
import {useTheme} from 'react-native-elements';
import LoginForm from '../components/login/LoginForm';
import DataContext from '../context/dataContext';
import globalStyle from '../theme/globalStyle';
import {sleep} from '../utils/promiseHelpers';

const IS_IOS = Platform.OS === 'ios';

const LoginScreen = ({navigation}) => {
    const {
        login,
        setLoggedIn,
        clearErrors,
        signUp,
        initStaticData,
        fetchInitData,
        apiErrors,
        apiMessages,
        loggedIn,
        startupCompleted,
    } = useContext(DataContext);
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const isFocused = useIsFocused();

    useEffect(() => {
        isFocused && clearErrors();
    }, [isFocused]);

    useEffect(() => {
        if (loggedIn && isFocused && startupCompleted) {
            sleep(200).then(() => {
                navigation.navigate('Root');
            });
        }
    }, [loggedIn, startupCompleted, isFocused]);

    useEffect(() => {
        const listener = navigation.addListener('beforeRemove', e => {
            e.preventDefault();
            if (IS_IOS) return;
            BackHandler.exitApp();
        });
        return listener;
    }, [navigation]);

    return (
        <SafeAreaView style={global.container}>
            <ScrollView contentContainerStyle={{flexGrow: 1}} keyboardShouldPersistTaps="handled">
                <LoginForm
                    navigation={navigation}
                    login={login}
                    setLoggedIn={setLoggedIn}
                    signUp={signUp}
                    initStaticData={initStaticData}
                    apiErrors={apiErrors}
                    apiMessages={apiMessages}
                    fetchInitData={fetchInitData}
                />
            </ScrollView>
        </SafeAreaView>
    );
};

export default LoginScreen;
