import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SafeAreaView, ScrollView, View} from 'react-native';
import {Button, Icon, LinearProgress, Text, useTheme} from 'react-native-elements';
import globalStyle from '../theme/globalStyle';
import {useIsFocused} from '@react-navigation/native';
import LangSelect from '../components/LangSelect';
import GuestForm from '../components/guest/GuestForm';
import ConsentDialog from '../components/self-checkin/ConsentDialog';
import LandlordInfo from '../components/self-checkin/LandlordInfo';
import LinkDialog from '../components/self-checkin/LinkDialog';
import '../components/self-checkin/PdfJs';
import SelfCheckinFooter from '../components/self-checkin/SelfCheckinFooter';
import {
    ADDITIONAL_FIELDS,
    APP_LANGS,
    CURRENT_GUESTS_COUNT,
    CURRENT_GUEST_COUNT,
    FORWARD_DATA,
    GUEST_SAVED,
    INVALID_FORM_DETAILS,
    MAX_GUEST_NUMBER,
    SELF_CHECKIN_NOTE,
    SELF_CHECKIN_SLOTS_FULL,
    SELF_CHECKIN_TEXT_2,
    SELF_CHECKIN_TEXT_INTRO_1,
    SELF_CHECKIN_TITLE,
    code,
} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import {isManualGuestDate} from '../utils/helpers';
import useStyles from './SelfCheckinScreen.styles';
import {useSharedCheckinActions} from '../components/self-checkin/useCheckinActions';

const SelfCheckinScreen = ({route, navigation}) => {
    const {eVisitorStaticData, getCheckin, refreshCheckin, createOrUpdateGuest, apiErrors, apiMessages} =
        useContext(DataContext);
    const {checkinId, lang, saved, guestData = null, consent = false} = route.params;
    const isFocused = useIsFocused();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t, i18n} = useTranslation();
    const styles = useStyles(theme);

    const [checkin, setCheckin] = useState(null);
    const [saveButton, setSaveButton] = useState(null);
    const [isOpenShare, setIsOpenShare] = useState(false);
    const [showConsentDialog, setShowConsentDialog] = useState(false);
    const [openLangSelect, setOpenLangSelect] = useState(false);
    const [isConsentAccepted, setIsConsentAccepted] = useState(Boolean(consent));
    const [showFormErrors, setShowFormErrors] = useState(false);

    const isManualGuestInput = isManualGuestDate(checkin);

    const refreshLocalCheckin = async checkinId => {
        await refreshCheckin(checkinId);
        const dbCheckin = await getCheckin(checkinId);
        if (dbCheckin) {
            setCheckin(dbCheckin);
        }
    };

    const consentAccepted = isAccepted => {
        setIsConsentAccepted(isAccepted);
        setShowConsentDialog(false);
    };

    const openLangSelectModal = _ => {
        setOpenLangSelect(true);
    };

    const onLangSelected = chosenLang => {
        const langCode = chosenLang?.[code];
        if (langCode && langCode !== lang) {
            navigation.replace('SelfCheckin', {
                checkinId: checkinId,
                saved: false,
                consent: isConsentAccepted,
                lang: langCode,
            });
        }
    };

    const openShare = _ => {
        setIsOpenShare(true);
    };

    const langChooser = (
        <Button
            title={lang}
            onPress={openLangSelectModal}
            buttonStyle={global.langSelectButton}
            titleStyle={global.langSelectButtonTitle}
            containerStyle={global.langSelectWrapper}
            icon={{
                name: 'keyboard-arrow-down',
                size: 16,
                color: theme.colors.primary,
            }}
            iconContainerStyle={{position: 'absolute', zIndex: 1, right: 5, marginHorizontal: 0}}
        />
    );

    useEffect(() => {
        if (lang) {
            lang && i18n.changeLanguage(lang);
        }
    }, [lang]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (checkinId) {
            refreshLocalCheckin(checkinId).catch(console.error);
        }
    }, []);

    const isNewGuest = true;
    // this is not guest for edit
    const guest = null;

    const currentGuestsCount = checkin?.[ADDITIONAL_FIELDS]?.[CURRENT_GUESTS_COUNT] ?? 0;
    const maxGuestsCount = checkin?.[ADDITIONAL_FIELDS]?.[MAX_GUEST_NUMBER] ?? 0;
    const areGuestSlotsFull = maxGuestsCount !== 0 && currentGuestsCount === maxGuestsCount;

    return (
        <SafeAreaView style={global.container}>
            <ScrollView contentContainerStyle={{flexGrow: 1}}>
                <View style={styles.bgContainer}>
                    {langChooser}

                    <View style={styles.contentWrapper}>
                        <View style={[global.containerInner, {maxWidth: 620}]}>
                            <Icon
                                type="material-community"
                                name="account-check"
                                size={30}
                                color={theme.colors.primary}
                                containerStyle={styles.headerIcon}
                            />

                            <View
                                style={[
                                    global.card,
                                    global.boxShadow,
                                    {
                                        borderWidth: 1,
                                        borderColor: theme.colors.borderLight,
                                        marginTop: 33,
                                    },
                                ]}>
                                {!checkin && (
                                    <LinearProgress
                                        color={theme.colors.primary}
                                        style={{position: 'absolute', top: 5, left: 0, right: 0}}
                                        variant="indeterminate"
                                    />
                                )}
                                <View style={styles.landlordWrapper}>
                                    <Text
                                        style={[
                                            global.textHeader,
                                            {
                                                textAlign: 'center',
                                                marginBottom: 30,
                                                fontFamily: theme.fontFamilySemibold,
                                                color: theme.colors.primary,
                                                textTransform: 'uppercase',
                                            },
                                        ]}>
                                        {t(SELF_CHECKIN_TITLE)}
                                    </Text>
                                    <Text style={[global.textIntro, {marginBottom: 20, color: theme.colors.textColor}]}>
                                        {t(SELF_CHECKIN_TEXT_INTRO_1)}
                                    </Text>

                                    <LandlordInfo checkin={checkin} />

                                    {!isManualGuestInput && (
                                        <View style={styles.savedGuestsTitle}>
                                            <Text style={[global.textTitle, {fontSize: 16}]}>
                                                {t(CURRENT_GUEST_COUNT)}{' '}
                                            </Text>

                                            <Text
                                                style={[global.textTitle, styles.savedGuestsCountText, {fontSize: 16}]}>
                                                {currentGuestsCount}/{maxGuestsCount}
                                            </Text>
                                        </View>
                                    )}

                                    {isManualGuestInput && areGuestSlotsFull && (
                                        <View>
                                            <Text style={[global.textTitle, styles.guestMsg, {fontSize: 16}]}>
                                                {t(SELF_CHECKIN_SLOTS_FULL)}
                                            </Text>
                                        </View>
                                    )}
                                    {saved && (
                                        <Text style={[global.textMain, styles.guestSavedMsg]}>{t(GUEST_SAVED)}</Text>
                                    )}
                                </View>

                                {maxGuestsCount !== 0 && !areGuestSlotsFull && (
                                    <View style={[styles.guestFormWrapper]}>
                                        <View>
                                            <Text
                                                style={[
                                                    global.textMain,
                                                    {
                                                        marginBottom: 30,
                                                        textAlign: 'center',
                                                        lineHeight: 22,
                                                        paddingHorizontal: 20,
                                                    },
                                                ]}>
                                                {t(SELF_CHECKIN_TEXT_2)}
                                            </Text>
                                        </View>

                                        <GuestForm
                                            eVisitorStaticData={eVisitorStaticData}
                                            createOrUpdateGuest={createOrUpdateGuest}
                                            apiErrors={apiErrors}
                                            apiMessages={apiMessages}
                                            existingGuest={guest}
                                            isNewGuest={isNewGuest}
                                            guestData={guestData}
                                            checkin={checkin}
                                            selfCheckinSaveButton={setSaveButton}
                                            isSelfCheckin={true}
                                            navigation={navigation}
                                            lang={lang}
                                            isConsentAccepted={isConsentAccepted}
                                            isFocused={isFocused}
                                            setShowFormErrors={setShowFormErrors}
                                            useSharedCheckinActions={useSharedCheckinActions}
                                        />

                                        {showFormErrors && (
                                            <View style={{paddingBottom: 8}}>
                                                <Text style={global.guestInvalidForm}>{t(INVALID_FORM_DETAILS)}</Text>
                                            </View>
                                        )}

                                        <View style={styles.saveBtnWrapper}>{saveButton}</View>
                                    </View>
                                )}
                            </View>

                            {!isManualGuestInput && maxGuestsCount !== 0 && !areGuestSlotsFull && (
                                <Button
                                    title={t(FORWARD_DATA)}
                                    onPress={openShare}
                                    icon={{name: 'share', color: theme.colors.primary}}
                                    containerStyle={[global.outlineButtonContainer, styles.shareButton]}
                                    buttonStyle={[
                                        global.outlineButton,
                                        {paddingLeft: 15, paddingRight: 20, backgroundColor: '#fff'},
                                    ]}
                                    titleStyle={[global.outlineButtonTitle, {textTransform: 'uppercase'}]}
                                />
                            )}

                            <Text style={[global.textMain, styles.note]}>{t(SELF_CHECKIN_NOTE)}</Text>
                        </View>
                    </View>

                    <SelfCheckinFooter />
                </View>
                {showConsentDialog && (
                    <ConsentDialog
                        open={showConsentDialog}
                        setOpen={setShowConsentDialog}
                        consentAccepted={consentAccepted}
                        isGuest={true}
                    />
                )}

                <LinkDialog isOpen={isOpenShare} setOpenShare={setIsOpenShare} checkin={checkin} />
                <LangSelect
                    isOpen={openLangSelect}
                    setOpenLangSelect={setOpenLangSelect}
                    onLangSelected={onLangSelected}
                    langList={APP_LANGS}
                />
            </ScrollView>
        </SafeAreaView>
    );
};

export default SelfCheckinScreen;
