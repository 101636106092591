import React from 'react';
import {View} from 'react-native';
import {Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import globalStyle from '../theme/globalStyle';
import useStyles from './ProgressDialog.styles';

const ProgressDialog = ({setVisible, visible, details, title}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);

    const toggleOverlay = () => {
        setVisible(!visible);
    };

    return (
        <Modal
            isVisible={visible}
            onBackdropPress={toggleOverlay}
            backdropTransitionOutTiming={0}
            backdropColor={'white'}
            backdropOpacity={0.95}
            style={{margin: 0, padding: 20}}>
            <View style={[global.modalDialog, styles.modalDialog]}>
                <View style={[global.modalContent, styles.modalContent]}>
                    <Text style={styles.progressTitle}>
                        {title}
                        {'\n'}
                    </Text>

                    <Text style={global.modalContentText}>{details}</Text>
                </View>
            </View>
        </Modal>
    );
};

export default ProgressDialog;
