import {useCallback, useState} from 'react';
import {useBetween} from 'use-between';

const useDialog = () => {
    const [isOpenEvisitorChooser, setIsOpenEvisitorChooser] = useState(false);
    const openEvisitorDialog = useCallback(() => setIsOpenEvisitorChooser(true), []);
    const closeEvisitorDialog = useCallback(() => setIsOpenEvisitorChooser(false), []);

    const [isOpenFab, setIsOpenFab] = useState(false);
    const openFab = useCallback(() => setIsOpenFab(true), []);
    const closeFab = useCallback(() => setIsOpenFab(false), []);

    const [isOpenGuestDialog, setIsOpenGuestDialog] = useState(false);
    const openGuestNumberDialog = useCallback(_ => setIsOpenGuestDialog(true), []);
    const closeGuestNumberDialog = useCallback(() => setIsOpenGuestDialog(false), []);

    const [isOpenQRDetails, setIsOpenQRDetails] = useState(false); // QR code details
    const openQRDetails = useCallback(_ => setIsOpenQRDetails(true), []);
    const closeQRDetails = useCallback(() => setIsOpenQRDetails(false), []);

    const [guestId, setGuestId] = useState(null); // used for QR dialog
    const updateGuestId = useCallback(id => setGuestId(id), []);

    const [checkinLink, setCheckinLink] = useState(null); // used for SelfCheckin QR dialog
    const updateCheckinLink = useCallback(checkinLinkData => setCheckinLink(checkinLinkData), []);

    const [isOpenSelfCheckinQRDetails, setIsOpenSelfCheckinQRDetails] = useState(false); // QR code details
    const openSelfCheckinQRDetails = useCallback(_ => setIsOpenSelfCheckinQRDetails(true), []);
    const closeSelfCheckinQRDetails = useCallback(() => setIsOpenSelfCheckinQRDetails(false), []);

    const [creditsDepletedOpen, setCreditsDepletedOpen] = useState(false);
    const openCreditsDepleted = useCallback(_ => setCreditsDepletedOpen(true), []);
    const closeCreditsDepleted = useCallback(_ => setCreditsDepletedOpen(false), []);

    const [useCreditsAfterExpireOpen, setUseCreditsAfterExpireOpen] = useState(false);
    const openCreditsAfterExpire = useCallback(_ => setUseCreditsAfterExpireOpen(true), []);
    const closeCreditsAfterExpire = useCallback(_ => setUseCreditsAfterExpireOpen(false), []);

    const [isOpenNoteEdit, setIsOpenNoteEdit] = useState(false);
    const openNoteEdit = useCallback(_ => setIsOpenNoteEdit(true), []);
    const closeNoteEdit = useCallback(_ => setIsOpenNoteEdit(false), []);

    const [isOpenInvoices, setIsOpenInvoices] = useState(false);
    const openInvoices = useCallback(_ => setIsOpenInvoices(true), []);
    const closeInvoices = useCallback(_ => setIsOpenInvoices(false), []);

    const [invoices, setInvoices] = useState(false);
    const prepareInvoices = useCallback(data => setInvoices(data), []);
    const resetInvoices = useCallback(_ => setInvoices([]), []);

    const [sortGuestsBy, setSortGuestsBy] = useState(null);
    const updateSortGuestsBy = useCallback(data => setSortGuestsBy(data), []);

    return {
        isOpenEvisitorChooser,
        openEvisitorDialog,
        closeEvisitorDialog,
        isOpenFab,
        openFab,
        closeFab,
        isOpenGuestDialog,
        openGuestNumberDialog,
        closeGuestNumberDialog,
        isOpenQRDetails,
        openQRDetails,
        closeQRDetails,
        guestId,
        updateGuestId,
        checkinLink,
        updateCheckinLink,
        isOpenSelfCheckinQRDetails,
        openSelfCheckinQRDetails,
        closeSelfCheckinQRDetails,
        creditsDepletedOpen,
        openCreditsDepleted,
        closeCreditsDepleted,
        useCreditsAfterExpireOpen,
        openCreditsAfterExpire,
        closeCreditsAfterExpire,
        isOpenNoteEdit,
        openNoteEdit,
        closeNoteEdit,
        isOpenInvoices,
        openInvoices,
        closeInvoices,
        invoices,
        prepareInvoices,
        resetInvoices,
        sortGuestsBy,
        updateSortGuestsBy,
    };
};

export const useSharedDialogs = () => useBetween(useDialog);
