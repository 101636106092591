import React from 'react';
import {View} from 'react-native';
import {Text, useTheme} from 'react-native-elements';
import globalStyle from '../theme/globalStyle';
import Modal from 'react-native-modal';

const AUTO_HIDE_DURATION = 2000;
const ERROR_AUTO_HIDE_DURATION = 4000;

const MessageDialog = ({message, isError = false, timeout = AUTO_HIDE_DURATION, open, handleOpen}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);

    const toggleOverlay = () => {
        handleOpen(false);
    };

    const isOpen = open && message ? true : false;
    return (
        <Modal
            isVisible={isOpen}
            onBackdropPress={toggleOverlay}
            onBackButtonPress={toggleOverlay}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={[global.modalContent, {minHeight: 80, justifyContent: 'center'}]}>
                    <Text style={global.modalContentText}>{message}</Text>
                </View>
            </View>
        </Modal>
    );
};

export default MessageDialog;
