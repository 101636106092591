import AsyncStorage from '@react-native-async-storage/async-storage';
import {useIsFocused} from '@react-navigation/native';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SafeAreaView, useWindowDimensions} from 'react-native';
import {LinearProgress, useTheme} from 'react-native-elements';
import {REST_COMMUNICATION} from '../api/inCheckinRestService';
import MessageDialog from '../components/MessageDialog';
import DateRangePicker from '../components/datetime/DateRangePicker';
import EVChooserHeader from '../components/invoices/EVChooserHeader';
import InvoicesHeader from '../components/invoices/InvoicesHeader';
import InvoicesList from '../components/invoices/InvoicesList';
import {useSharedDialogs} from '../components/invoices/useDialogs';
import EVChooserDialog from '../components/tourists/EVChooserDialog';
import {
    ACCOMODATION_OBJECT,
    INVOICES_DATASET,
    INVOICE_CUSTOM_OWNER_FIELD,
    PIN,
    SELECT_PERIOD,
    STORAGE_CHOSEN_EV_ACCOUNT,
    STORAGE_CHOSEN_INVOICE_OWNER,
} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import globalStyle from '../theme/globalStyle';
import {extendEvisitorAccounts, findEvisitorByPin, isEmpty, isFakeEvAccount, isNotEmpty} from '../utils/arrayHelpers';
import {handleExportDataToXlsx} from '../utils/export';
import {
    getInvoicesYear,
    getUserInfo,
    isEVAccountFetchedFacility,
    saveEVAccountFetchedFacilityFlag,
} from '../utils/userUtils';
import {getCurrentYear} from '../utils/dateHelper';

export const INVOICES_PAGE_COUNT = 25;
export const INVOICE_FIRST_PAGE = 0;
export const INVOICES_ALL_PAGES = -1;

const InvoicesScreen = ({route, navigation}) => {
    const {
        clearErrors,
        apiErrors,
        apiMessages,
        evaccounts,
        refetchEvisitor,
        invoices,
        getInvoicesByEvAccount,
        refreshInvoices,
        loggedIn,
    } = useContext(DataContext);
    const isFocused = useIsFocused();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const [openMessage, setOpenMessage] = useState(false);
    const [evAccount, setEvAccount] = useState(null);
    const [progressOngoing, setProgressOngoing] = useState(false);
    const [openExportDialog, setOpenExportDialog] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [evChooserAccounts, setEvChooserAccounts] = useState([]);
    const [yearFilter, setYearFilter] = useState(getCurrentYear());

    const width = useWindowDimensions().width;
    const isMobile = width < theme.tabletBP;

    const onRefresh = async evAccount => {
        try {
            if (evAccount) {
                setRefresh(true);
                await refreshInvoices(evAccount, evaccounts, INVOICE_FIRST_PAGE, true);
                await getInvoicesByEvAccount(evAccount);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setRefresh(false);
        }
    };

    const setupEvAccount = async (extendedEvaccounts, currentEvAccount) => {
        let selectedAccount = null;
        if (currentEvAccount && currentEvAccount[INVOICE_CUSTOM_OWNER_FIELD]) {
            return currentEvAccount;
        }
        if (isNotEmpty(extendedEvaccounts)) {
            let invoicesPin = await AsyncStorage.getItem(STORAGE_CHOSEN_INVOICE_OWNER);
            const storedGlobalEvAccPin = await AsyncStorage.getItem(STORAGE_CHOSEN_EV_ACCOUNT);
            if (storedGlobalEvAccPin && invoicesPin !== '-1') {
                invoicesPin = storedGlobalEvAccPin;
            }
            const matchedStoredAccount = invoicesPin ? findEvisitorByPin(extendedEvaccounts, invoicesPin) : null;
            if (matchedStoredAccount) {
                selectedAccount = matchedStoredAccount;
            } else {
                const customOwner = extendedEvaccounts.find(e => e[INVOICE_CUSTOM_OWNER_FIELD]);
                if (customOwner) {
                    selectedAccount = customOwner;
                }
                if (!selectedAccount) {
                    selectedAccount = extendedEvaccounts[0];
                }
            }
        }
        return selectedAccount;
    };

    const checkAndFetchFacilities = useCallback(async activeEvAccount => {
        if (!isFakeEvAccount(activeEvAccount)) {
            const facilities = activeEvAccount ? activeEvAccount?.[ACCOMODATION_OBJECT] : [];
            if (isEmpty(facilities)) {
                const isAlreadyFetched = await isEVAccountFetchedFacility(activeEvAccount[PIN]);
                if (!isAlreadyFetched) {
                    await refetchEvisitor(activeEvAccount);
                    await saveEVAccountFetchedFacilityFlag(activeEvAccount[PIN]);
                }
            }
        }
    }, []);

    const loadInvoicesForEvAccount = async (evAccount, forceRefresh, page = INVOICE_FIRST_PAGE) => {
        try {
            await refreshInvoices(evAccount, evaccounts, page, forceRefresh);
            await getInvoicesByEvAccount(evAccount);
            await AsyncStorage.setItem(STORAGE_CHOSEN_INVOICE_OWNER, evAccount[PIN]);
            if (!isFakeEvAccount(evAccount)) {
                await checkAndFetchFacilities(evAccount);
                await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, evAccount[PIN]);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const generateMessage = useCallback(() => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`;
        }
        if (apiMessages) {
            if (apiMessages?.signal === REST_COMMUNICATION) {
                return `${apiMessages?.message}`;
            }
            return `${apiMessages?.signal}: ${apiMessages?.message}`;
        }
        return null;
    }, [apiErrors, apiMessages]);

    const exportInvoices = _ => {
        setOpenExportDialog(true);
    };

    const onConfirmExport = async (dateRange, optionalData) => {
        setProgressOngoing(true);
        const evAccList = [{...optionalData?.evAccount}];
        setOpenExportDialog(false);
        await refreshInvoices(evAccount, evaccounts, INVOICES_ALL_PAGES, true);
        const freshInvoices = await getInvoicesByEvAccount(evAccount);
        try {
            await handleExportDataToXlsx(freshInvoices, evAccList, INVOICES_DATASET, dateRange, null, t);
        } catch (e) {
            console.log(e);
        } finally {
            setProgressOngoing(false);
        }
    };

    const initOwners = async evaccounts => {
        try {
            setRefresh(true);
            const storedUserInfo = await getUserInfo();
            const accounts = extendEvisitorAccounts(evaccounts, storedUserInfo, true, t);
            const selectedEvAccount = await setupEvAccount(accounts, evAccount);
            setEvChooserAccounts(accounts);
            setEvAccount(selectedEvAccount);
            await getInvoicesByEvAccount(selectedEvAccount);
        } catch (e) {
            console.log(e);
        } finally {
            setRefresh(false);
        }
    };

    const getNextPageInvoices = useCallback(
        async page => {
            try {
                setProgressOngoing(true);
                await loadInvoicesForEvAccount(evAccount, true, page);
            } catch (e) {
                console.log(e);
            } finally {
                setProgressOngoing(false);
            }
            return null;
        },
        [evAccount, evaccounts]
    );

    const header = (
        <InvoicesHeader
            evAccount={evAccount}
            loadInvoicesForEvAccount={loadInvoicesForEvAccount}
            exportInvoices={exportInvoices}
            useSharedDialogs={useSharedDialogs}
        />
    );

    useEffect(() => {
        if (loggedIn && isFocused && evaccounts) {
            clearErrors();
            initOwners(evaccounts).catch(console.error);
        }
    }, [isFocused, evaccounts, loggedIn]);

    useEffect(() => {
        if (evAccount) {
            loadInvoicesForEvAccount(evAccount, false, INVOICE_FIRST_PAGE).catch(console.error);
            getInvoicesYear().then(year => setYearFilter(year));
        }
    }, [evAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <EVChooserHeader evAccount={evAccount} onRefresh={onRefresh} global={global} theme={theme} />
            ),
        });
    }, [evAccount]);

    useEffect(() => {
        apiErrors && setOpenMessage(true);
        apiMessages && setOpenMessage(true);
    }, [apiMessages, apiErrors]);

    return (
        <SafeAreaView style={global.containerBg}>
            {progressOngoing && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}

            <InvoicesList
                invoices={invoices}
                invoicesHeader={header}
                refreshing={refresh}
                onRefresh={onRefresh}
                navigation={navigation}
                evAccount={evAccount}
                getNextPageInvoices={getNextPageInvoices}
            />
            <DateRangePicker
                open={openExportDialog}
                setOpen={setOpenExportDialog}
                onConfirm={onConfirmExport}
                optionalData={{invoices, evAccount}}
                label={t(SELECT_PERIOD)}
            />

            {isFocused && (
                <EVChooserDialog
                    evaccounts={evChooserAccounts}
                    setEvAccount={setEvAccount}
                    useSharedDialogs={useSharedDialogs}
                />
            )}
            {isFocused && (
                <MessageDialog
                    message={generateMessage()}
                    isError={apiErrors}
                    open={openMessage}
                    handleOpen={setOpenMessage}
                />
            )}
        </SafeAreaView>
    );
};

export default InvoicesScreen;
