import {useNavigation} from '@react-navigation/native';
import isEqual from 'lodash/isEqual';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {Text, View, useWindowDimensions} from 'react-native';
import {Icon, ListItem, useTheme} from 'react-native-elements';
import {
    ADDITIONAL_FIELDS,
    CURRENT_GUESTS_COUNT,
    FORESEEN_STAY_UNTIL_FIELD,
    MAX_GUEST_NUMBER,
    RESERVATION,
    RESERVATION_HOLDER,
    RESERVATION_ID,
    RESERVATION_STATUS,
    RESERVATION_UNIT_NAME,
    STAY_FROM_FIELD,
    guests,
    id,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import {getShortFormattedDateFromDateString} from '../../utils/dateHelper';
import {getGuestsStatusesCounts} from '../../utils/guestUtils';
import useStyles from './ReservationItem.styles';

/*
the first row containing {reservation_holder} + {reservation_num}
the second row containing {unit_name}
dates as shown on CheckinListItem
status color on the left is independent of guest check-in and depends on {reservation_status} -->> 0=red, 1=green.
ReservationDetails is similar to CheckinDetails, but without the red parts:

checkin[ADDITIONAL_FIELDS]:
    "reservation":  {
    "reservation_id": "3383576f-5f43-d464-7453-1d1f1e2a4d9b",
    "reservation_holder": "Aaron Kato",
    "unit_name": "Parcela A 62",
    "reservation_status": "0"
    }
*/

const ReservationItem = ({checkin}) => {
    const navigation = useNavigation();
    const {theme} = useTheme();
    const {t} = useTranslation();
    const global = globalStyle(theme);
    const styles = useStyles(theme);

    const width = useWindowDimensions().width;
    const isDesktop = width >= theme.desktopBP;
    const isDesktopLarge = width >= 1300;

    const {checkedInCount, checkoutedCount, guestsForCheckinCount, guestCount} = getGuestsStatusesCounts(
        checkin?.[guests]
    );

    const isReservationStatusTrue =
        checkin?.[ADDITIONAL_FIELDS]?.[RESERVATION]?.[RESERVATION_STATUS] === '0' ? false : true;

    const openDetailsScreen = checkin => {
        navigation.navigate('Checkin', {
            screen: 'CheckinScreen',
            params: {checkinId: checkin[id], isReservation: true},
        });
    };

    const reservationGuestCount = `${checkin?.[ADDITIONAL_FIELDS]?.[CURRENT_GUESTS_COUNT]} / ${checkin?.[ADDITIONAL_FIELDS]?.[MAX_GUEST_NUMBER] ?? 0}`;

    return (
        <ListItem
            key={checkin[id]}
            onPress={() => openDetailsScreen(checkin)}
            containerStyle={[
                global.listItem,
                isReservationStatusTrue && {borderColor: theme.colors.success},
                !isReservationStatusTrue && {borderColor: theme.colors.error},
            ]}
            style={[global.boxShadow, {marginBottom: 15, borderRadius: 4}]}
            underlayColor="white">
            <View style={{flex: 1}}>
                <View style={[isDesktop && {flexDirection: 'row', alignItems: 'center'}, {flex: 1}]}>
                    <View
                        style={[
                            !isDesktop && {marginBottom: 5},
                            isDesktop && {width: '60%', paddingRight: 20},
                            isDesktopLarge && {width: '70%'},
                        ]}>
                        <View style={global.flexCenter}>
                            <Icon
                                type="ionicon"
                                name="home-outline"
                                size={16}
                                color={theme.colors.textColorMedium}
                                style={{marginRight: 10}}
                            />
                            <View style={{flex: 1}}>
                                <Text style={[global.textCaps, {lineHeight: 16}]}>
                                    {checkin?.[ADDITIONAL_FIELDS]?.[RESERVATION]?.[RESERVATION_HOLDER] ?? ''}
                                    {checkin?.[ADDITIONAL_FIELDS]?.[RESERVATION]?.[RESERVATION_ID] ?? ''}
                                </Text>
                                <Text
                                    style={[
                                        global.textCaps,
                                        {fontSize: 10, color: theme.colors.textColorLight, marginTop: 2},
                                    ]}>
                                    {checkin?.[ADDITIONAL_FIELDS]?.[RESERVATION]?.[RESERVATION_UNIT_NAME] ?? ''}
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View
                        style={[
                            {flexDirection: 'row', justifyContent: 'space-between'},
                            isDesktop && {width: '40%', paddingRight: 20},
                            !isDesktop && {marginTop: 5},
                            isDesktopLarge && {width: '30%'},
                        ]}>
                        <View style={[global.flexCenter, global.listItemIconTextWrapper, {minWidth: 140}]}>
                            <Icon
                                type="ionicon"
                                name="calendar-outline"
                                size={16}
                                color={theme.colors.textColorMedium}
                                style={{marginRight: 8}}
                            />
                            <Text style={[global.textMain]}>
                                {getShortFormattedDateFromDateString(checkin?.[STAY_FROM_FIELD])}
                            </Text>
                            <Text
                                style={{
                                    color: theme.colors.greyLight,
                                    marginHorizontal: 6,
                                }}>
                                /
                            </Text>
                            <Text style={[global.textMain]}>
                                {getShortFormattedDateFromDateString(checkin?.[FORESEEN_STAY_UNTIL_FIELD])}
                            </Text>
                        </View>

                        <View style={global.flexCenter}>
                            <View
                                style={[
                                    global.flexCenter,
                                    global.listItemIconTextWrapper,
                                    {marginLeft: 5, minWidth: 45},
                                ]}>
                                <Icon
                                    type="material-community"
                                    name="account"
                                    size={18}
                                    style={{marginRight: 5}}
                                    color={theme.colors.greyMedium}
                                />
                                <Text
                                    style={[
                                        global.textMain,
                                        {color: theme.colors.greyMedium, fontFamily: theme.fontFamilyMedium},
                                    ]}>
                                    {reservationGuestCount}
                                </Text>
                            </View>

                            {/* 
                            {checkedInCount !== 0 && (
                                <View
                                    style={[
                                        global.flexCenter,
                                        global.listItemIconTextWrapper,
                                        {marginLeft: 5, minWidth: 45},
                                    ]}>
                                    <Icon
                                        type="material-community"
                                        name="account-check"
                                        size={18}
                                        style={{marginRight: 5}}
                                        color={theme.colors.success}
                                    />
                                    <Text
                                        style={[
                                            global.textMain,
                                            {color: theme.colors.success, fontFamily: theme.fontFamilyMedium},
                                        ]}>
                                        {checkedInCount}
                                    </Text>
                                </View>
                            )}

                            {checkoutedCount !== 0 && (
                                <View
                                    style={[
                                        global.flexCenter,
                                        global.listItemIconTextWrapper,
                                        {marginLeft: 5, minWidth: 45},
                                    ]}>
                                    <Icon
                                        type="material-community"
                                        name="account-arrow-up"
                                        size={18}
                                        style={{marginRight: 5}}
                                        color={theme.colors.default}
                                    />
                                    <Text
                                        style={[
                                            global.textMain,
                                            {color: theme.colors.default, fontFamily: theme.fontFamilyMedium},
                                        ]}>
                                        {checkoutedCount}
                                    </Text>
                                </View>
                            )}

                            {guestsForCheckinCount !== 0 && (
                                <View
                                    style={[
                                        global.flexCenter,
                                        global.listItemIconTextWrapper,
                                        {marginLeft: 5, minWidth: 45},
                                    ]}>
                                    <Icon
                                        type="material-community"
                                        name="account-alert"
                                        size={18}
                                        style={{marginRight: 5}}
                                        color={theme.colors.error}
                                    />
                                    <Text
                                        style={[
                                            global.textMain,
                                            {color: theme.colors.error, fontFamily: theme.fontFamilyMedium},
                                        ]}>
                                        {guestsForCheckinCount}
                                    </Text>
                                </View>
                            )}
                            */}
                        </View>
                    </View>
                </View>
            </View>
        </ListItem>
    );
};

function arePropsEqual(prevProps, nextProps) {
    return isEqual(prevProps.checkin, nextProps.checkin);
}

export default memo(ReservationItem, arePropsEqual);
