import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {FlatList, View, Pressable} from 'react-native';
import {Button, ListItem, Text, Icon, useTheme, CheckBox} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {
    ACCOMODATION_OBJECT,
    ACCOMODATION_UNIT,
    CHOOSE_EVACCOUNT_TITLE,
    CHOOSE_FACILITY_TITLE,
    CODE,
    FACILITY_CODE,
    FACILITY_ERROR_NO_ACTIVE_FACILITY,
    FACILITY_ID,
    GUEST_MANUAL_ENTER_DATES_TITLE,
    GUEST_MANUAL_ENTER_TIME_TITLE,
    MANUAL_GUEST_DATES,
    MANUAL_GUEST_TIME,
    NAME,
    NAME_CODE,
    OK,
    TITLE_GUEST_ARRIVAL,
} from '../../constants/stringsAndFields';
import useStyles from './MultiStepCheckinCreatorDialog.styles';
import Modal from 'react-native-modal';
import CheckinDateTimePicker from '../datetime/CheckinDateTimePicker';
import {useSharedDialogs} from './useDialogs';
import {isEmpty} from '../../utils/arrayHelpers';

const DEFAULT_NUMBER_OF_GUESTS = 5;

const MultiStepCheckinCreatorDialog = ({evAccount, navigation}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t} = useTranslation();
    const [step, setStep] = useState(1);
    const [chosenFacility, setChosenFacility] = useState(null);
    const [checkinDateTimes, setCheckinDateTimes] = useState({});
    const [filteredFacilities, setFilteredFacilities] = useState([]);
    const [guestManualDate, setGuestManualDate] = useState(false);
    const [guestManualTime, setGuestManualTime] = useState(false);
    const [disabledDatePickers, setDisabledDatePickers] = useState(false);
    const [disabledTimePickers, setDisabledTimePickers] = useState(false);

    const {isOpenFab, closeFab} = useSharedDialogs();
    const onPressGuestManualDate = _ => {
        setGuestManualDate(!guestManualDate);
        if (guestManualDate) {
            setDisabledDatePickers(false);
        } else {
            setDisabledDatePickers(true);
        }
    };
    const onPressGuestManualTime = _ => {
        setGuestManualTime(!guestManualTime);
        if (guestManualTime) {
            setDisabledTimePickers(false);
        } else {
            setDisabledTimePickers(true);
        }
    };

    useEffect(() => {
        if (evAccount && isOpenFab) {
            const facilities = evAccount ? evAccount?.[ACCOMODATION_OBJECT] : [];
            if (!isEmpty(facilities)) {
                const unitCodes = evAccount[ACCOMODATION_UNIT].map(unit => unit[CODE]);
                const activeFacilities = facilities.filter(facility => unitCodes.includes(facility[FACILITY_CODE]));
                for (let facility of activeFacilities) {
                    const parentObject = evAccount[ACCOMODATION_UNIT].find(
                        unit => facility[FACILITY_CODE] === unit[CODE]
                    );
                    if (parentObject) {
                        facility[NAME] = parentObject[NAME];
                    }
                }
                setFilteredFacilities([...activeFacilities]);
            } else {
                setFilteredFacilities([]);
            }
        }
    }, [evAccount, isOpenFab]);

    const onClickFacilityItem = facility => {
        if (facility) {
            setChosenFacility(facility);
            setStep(2);
        }
    };

    const resetAllData = () => {
        closeFab();
        setStep(1);
        setChosenFacility(null);
        setCheckinDateTimes({});
    };

    const onClickConfirm = () => {
        if (checkinDateTimes?.valid && evAccount && chosenFacility) {
            const facility = chosenFacility;
            const dateTimes = checkinDateTimes;
            resetAllData();
            // navigate to checkin screen with props for creation of new checkin
            navigation.navigate('Checkin', {
                screen: 'CheckinScreen',
                params: {
                    facility: facility,
                    evAccount: evAccount,
                    numberOfGuests: DEFAULT_NUMBER_OF_GUESTS,
                    isNewCheckin: true,
                    checkinTime: dateTimes,
                    [MANUAL_GUEST_DATES]: guestManualDate,
                    [MANUAL_GUEST_TIME]: guestManualTime,
                },
            });
        } else {
            resetAllData();
        }
    };

    const renderTitle = stepTitle => {
        switch (stepTitle) {
            case 1:
                return t(CHOOSE_FACILITY_TITLE);
            case 2:
                return t(TITLE_GUEST_ARRIVAL);
            default:
                return t(CHOOSE_EVACCOUNT_TITLE);
        }
    };

    const renderFacility = ({item}) => (
        <ListItem
            key={`${item?.[FACILITY_ID]}_${item?.[NAME]}_${item?.[NAME_CODE]}`}
            bottomDivider
            onPress={() => onClickFacilityItem(item)}>
            <ListItem.Content>
                <ListItem.Title>
                    <Text style={global.textMain}>{item?.[NAME] + ' ' + item?.[NAME_CODE]}</Text>
                </ListItem.Title>
            </ListItem.Content>
        </ListItem>
    );

    const emptyList = <EmptyList t={t} global={global} />;

    const renderFacilities = facilities => (
        <FlatList
            data={facilities}
            renderItem={renderFacility}
            keyExtractor={(item, index) => `${item?.[FACILITY_ID]}_${item?.[NAME]}_${item?.[NAME_CODE]}`}
            ListEmptyComponent={emptyList}
        />
    );

    const renderGuestArrival = () => {
        return (
            <>
                <View style={[global.modalContent, styles.modalContent]}>
                    <CheckinDateTimePicker
                        setCheckinDateTimes={setCheckinDateTimes}
                        disabledDatePickers={disabledDatePickers}
                        disabledTimePickers={disabledTimePickers}
                    />
                    <CheckBox
                        title={t(GUEST_MANUAL_ENTER_DATES_TITLE)}
                        checked={guestManualDate}
                        onPress={onPressGuestManualDate}
                        containerStyle={global.CheckBox}
                        textStyle={global.CheckBoxText}
                        size={21}
                    />
                    <CheckBox
                        title={t(GUEST_MANUAL_ENTER_TIME_TITLE)}
                        checked={guestManualTime}
                        onPress={onPressGuestManualTime}
                        containerStyle={global.CheckBox}
                        textStyle={global.CheckBoxText}
                        size={21}
                    />
                </View>
                <View style={global.modalFooter}>
                    <Button
                        onPress={onClickConfirm}
                        title={OK}
                        disabled={checkinDateTimes?.['valid'] === false}
                        containerStyle={global.ModalButton.Positive.containerStyle}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                        disabledStyle={global.ModalButton.disabledStyle}
                        disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                    />
                </View>
            </>
        );
    };

    return (
        <Modal
            isVisible={isOpenFab}
            onBackdropPress={resetAllData}
            onBackButtonPress={resetAllData}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={[global.modalDialog, styles.checkinModal]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{renderTitle(step)}</Text>
                    <Pressable onPress={resetAllData} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <View
                    style={{
                        backgroundColor: theme.colors.white,
                        flex: 1,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        overflow: 'hidden',
                    }}>
                    {step === 1 && renderFacilities(filteredFacilities)}
                    {step === 2 && renderGuestArrival()}
                </View>
            </View>
        </Modal>
    );
};

export default MultiStepCheckinCreatorDialog;

const EmptyList = ({t, global}) => (
    <View style={global.listEmptyMsgWithBackground}>
        <Text style={global.listEmptyMsgText}>{t(FACILITY_ERROR_NO_ACTIVE_FACILITY)}</Text>
    </View>
);
