import Clipboard from '@react-native-clipboard/clipboard';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable, ScrollView, View, useWindowDimensions} from 'react-native';
import {Button, Icon, LinearProgress, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {
    ADDITIONAL_FIELDS,
    CHECKIN_LINK,
    EMAIL_BODY_SELF_CHECKIN,
    LANGUAGE,
    MAX_GUEST_NUMBER,
    NOTE,
    PRODUCTION_BACKEND,
    QR_CODE,
    SELF_CHECKIN,
    SELF_CHECKIN_DIALOG_BOTTOM_NOTE_TXT,
    SELF_CHECKIN_DIALOG_TITLE,
    SELF_CHECKIN_QR_CODE_DIALOG_TXT,
    SHARE_SELF_CHECKIN_TITLE,
    SLUG,
    id,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import {
    createRemoteCheckinIfNotExist,
    generateShareUrl,
    getLanguageString,
    getLanguageStringTranslated,
    getTranslatedLanguageCode,
    isNotNull,
    isShareApiAvailable,
    openInNewTab,
} from '../../utils/helpers';
import {sleep} from '../../utils/promiseHelpers';
import {share} from '../../utils/share';
import {setupUserLang} from '../../utils/userUtils';
import LangSelect from './LangSelect';
import NumberOfGuestsIncrementor from './NumberOfGuestsIncrementor';
import useStyles from './ShareLinkDialog.styles';

const ShareLinkDialog = ({
    checkin,
    useSharedCheckinActions,
    useSharedDialogs,
    updateGuestNumber,
    updateLocalCheckin,
    refreshLocalCheckin,
}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t, i18n} = useTranslation();
    const width = useWindowDimensions().width;
    const notDesktop = width < theme.desktopBP;
    const [checkinUrl, setCheckinUrl] = useState(null);
    const [openLangSelect, setOpenLangSelect] = useState(false);
    const {isOpenShare, closeShare} = useSharedCheckinActions();
    const checkinMaxGuestNumber = checkin?.[ADDITIONAL_FIELDS]?.[MAX_GUEST_NUMBER];
    const [guestNumber, setGuestNumber] = useState(checkinMaxGuestNumber ?? 4);
    const {updateCheckinLink, openSelfCheckinQRDetails} = useSharedDialogs();
    const activeLang = i18n.language;
    const [lang, setLang] = useState('');
    const [progressOngoing, setProgressOngoing] = useState(false);
    const slug = checkin?.[SLUG];

    const onOpenLink = async _ => {
        await updateGuestNumberData();
        closeShare();
        openInNewTab(checkinUrl);
    };

    const handleClose = () => {
        closeShare();
        setGuestNumber(checkinMaxGuestNumber);
    };

    const updateGuestNumberData = async _ => {
        try {
            if (guestNumber !== checkinMaxGuestNumber) {
                await updateGuestNumber(checkin, Number(guestNumber));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onCopy = async _ => {
        await updateGuestNumberData();
        try {
            Clipboard.setString(checkinUrl);
        } catch (err) {
            console.log(err);
        } finally {
            handleClose();
        }
    };

    const onPrint = async _ => {
        handleClose();
        updateCheckinLink(checkinUrl);
        await updateGuestNumberData();
        await sleep(300);
        openSelfCheckinQRDetails();
    };

    const onShare = async _ => {
        await updateGuestNumberData();
        await share(SHARE_SELF_CHECKIN_TITLE, EMAIL_BODY_SELF_CHECKIN, checkinUrl);
        handleClose();
    };

    const onLangSelected = item => {
        const {code} = item;
        const translatedLang = getLanguageStringTranslated(code, activeLang);
        if (translatedLang) {
            setLang(translatedLang);
            let url = `${generateShareUrl(PRODUCTION_BACKEND)}/self-checkin?checkinId=${
                checkin?.[id]
            }&lang=${getTranslatedLanguageCode(translatedLang, activeLang)}`;
            if (isNotNull(slug)) {
                url = `${PRODUCTION_BACKEND}/sc/${slug}?lang=${getTranslatedLanguageCode(translatedLang, activeLang)}`;
            }
            setCheckinUrl(url);
        }
    };

    const openLangSelectModal = _ => {
        setOpenLangSelect(true);
    };

    useEffect(() => {
        if (checkin) {
            let url = `${generateShareUrl(PRODUCTION_BACKEND)}/self-checkin?checkinId=${
                checkin?.[id]
            }&lang=${getTranslatedLanguageCode(lang, activeLang)}`;
            if (isNotNull(slug)) {
                url = `${PRODUCTION_BACKEND}/sc/${slug}?lang=${getTranslatedLanguageCode(lang, activeLang)}`;
            }
            setCheckinUrl(url);
        }
    }, [checkin, lang]);

    useEffect(() => {
        setGuestNumber(checkinMaxGuestNumber);
        setupUserLang().then(storedSettingsLang => setLang(getLanguageString(activeLang, storedSettingsLang)));
    }, [checkinMaxGuestNumber, isOpenShare]);

    useEffect(() => {
        if (isOpenShare && checkin) {
            createRemoteCheckinIfNotExist(checkin, updateLocalCheckin, setProgressOngoing).then(isUploaded => {
                if (isUploaded) {
                    refreshLocalCheckin(checkin[id]).catch(console.error);
                }
            });
        }
    }, [isOpenShare]);

    return (
        <Modal
            isVisible={isOpenShare}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}>
            <View style={[global.modalDialog, {maxHeight: '95%'}]}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(SELF_CHECKIN)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>
                <ScrollView contentContainerStyle={{flexGrow: 1}}>
                    <View style={[global.modalContent]}>
                        {progressOngoing && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
                        <Text
                            style={[
                                global.textIntro,
                                {
                                    textAlign: 'center',
                                    marginTop: 10,
                                    marginBottom: 30,
                                    maxWidth: '85%',
                                    alignSelf: 'center',
                                },
                            ]}>
                            {t(SELF_CHECKIN_DIALOG_TITLE)}
                        </Text>

                        {/* Number of guests */}
                        <NumberOfGuestsIncrementor
                            isOpenShare={isOpenShare}
                            checkinMaxGuestNumber={checkinMaxGuestNumber}
                            guestNumber={guestNumber}
                            setGuestNumber={setGuestNumber}
                        />

                        {/* LangSelect */}
                        <View style={[global.flexCenter, {marginBottom: 23}]}>
                            <Text style={[global.textCaps, {marginRight: 10}]}>{t(LANGUAGE) + ':'}</Text>
                            <Pressable
                                onPress={_ => {
                                    openLangSelectModal();
                                }}>
                                <Text style={[global.link, styles.linkText]}>
                                    {getLanguageStringTranslated(
                                        getTranslatedLanguageCode(lang, activeLang),
                                        activeLang
                                    )}
                                </Text>
                            </Pressable>
                            <LangSelect
                                isOpen={openLangSelect}
                                setOpenLangSelect={setOpenLangSelect}
                                onLangSelected={onLangSelected}
                            />
                        </View>

                        {/* Link */}
                        <View style={{marginBottom: 15}}>
                            <Text style={[global.textCaps, {marginBottom: 15}]}>{t(CHECKIN_LINK) + ':'}</Text>
                            <View style={global.flexCenter}>
                                <View style={[styles.linkWrapper, {flex: 1, paddingRight: 0}]}>
                                    <Pressable style={styles.linkTextWrapper} onPress={onOpenLink}>
                                        <Text numberOfLines={1} style={[global.link, styles.linkText]}>
                                            {checkinUrl}
                                        </Text>
                                    </Pressable>
                                    <Pressable style={styles.linkBtns} onPress={onCopy}>
                                        <Icon
                                            type="ionicon"
                                            name="copy-outline"
                                            size={20}
                                            color={theme.colors.primary}
                                        />
                                    </Pressable>
                                    {notDesktop && isShareApiAvailable && (
                                        <Pressable style={styles.linkBtns} onPress={onShare}>
                                            <Icon
                                                type="ionicon"
                                                name="share-social-outline"
                                                size={20}
                                                color={theme.colors.primary}
                                            />
                                        </Pressable>
                                    )}
                                </View>
                            </View>
                        </View>

                        {/* QR code */}
                        <View style={{marginBottom: 20}}>
                            <Button
                                onPress={onPrint}
                                title={t(QR_CODE)}
                                icon={
                                    <Icon
                                        type="ionicon"
                                        name="qr-code-outline"
                                        size={20}
                                        color={theme.colors.primary}
                                        containerStyle={{marginRight: 10}}
                                    />
                                }
                                containerStyle={[
                                    global.ModalButton.Negative.containerStyle,
                                    {alignSelf: 'center', borderRadius: 10, marginBottom: 15},
                                ]}
                                buttonStyle={[
                                    global.ModalButton.Negative.buttonStyle,
                                    {
                                        borderRadius: 10,
                                        paddingVertical: 5,
                                        paddingHorizontal: 30,
                                        borderColor: 'rgba(93, 77, 157,  0.4)',
                                    },
                                ]}
                                titleStyle={[
                                    global.ModalButton.Negative.titleStyle,
                                    {textTransform: 'none', fontFamily: theme.fontFamilyMedium},
                                ]}
                            />

                            <Text style={[global.textMain, {textAlign: 'center', paddingHorizontal: 5}]}>
                                {t(SELF_CHECKIN_QR_CODE_DIALOG_TXT)}
                            </Text>
                        </View>

                        {/* Note */}
                        <View style={{paddingTop: 15, borderTopWidth: 1, borderTopColor: theme.colors.greyLight}}>
                            <Text style={[global.textNote, {textAlign: 'left'}]}>
                                <Text style={{fontFamily: theme.fontFamilyMedium}}>{t(NOTE) + ': '}</Text>
                                {t(SELF_CHECKIN_DIALOG_BOTTOM_NOTE_TXT)}
                            </Text>
                        </View>
                    </View>
                </ScrollView>
            </View>
        </Modal>
    );
};

export default ShareLinkDialog;
