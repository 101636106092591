import React, {useEffect, useState} from 'react';
import {useIsFocused} from '@react-navigation/native';
import {useTranslation} from 'react-i18next';
import {SafeAreaView, ScrollView, View, Platform, Linking} from 'react-native';
import {Text, Button, useTheme} from 'react-native-elements';
import globalStyle from '../theme/globalStyle';
import {
    ABOUT_ADDRESS,
    ABOUT_EMAIL_OFFICE,
    ABOUT_IBAN,
    ABOUT_OIB,
    ABOUT_OWNER,
    ABOUT_SWIFT,
    ABOUT_WEB,
    APP_VERSION,
    CONTACT,
} from '../constants/stringsAndFields';
import ContactDialog from '../components/contact/ContactDialog';
import useStyles from './ContactScreen.styles';
import {getDebugLog} from '../utils/userUtils';

const DEBUG_CLICK_NUMBER = 7;
const IS_IOS = Platform.OS === 'ios';
const IS_WEB = Platform.OS === 'web';

const ContactScreen = ({navigation}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const styles = useStyles(theme);
    const isFocused = useIsFocused();
    const [openContact, setOpenContact] = useState(false);
    const [debugMode, setDebugMode] = useState(false);
    const [debugModeClickCount, setDebugModeClickCount] = useState(0);
    const [debugMessage, setDebugMessage] = useState('');

    const onClickContact = async _ => {
        setOpenContact(true);
    };

    const openWeb = () => {
        Linking.openURL('https://echeckin.hr/');
    };

    const openEmail = () => {
        Linking.openURL('mailto:office@echeckin.hr');
    };

    useEffect(() => {
        isFocused && setDebugModeClickCount(0);
    }, [isFocused]);

    useEffect(() => {
        if (debugModeClickCount >= DEBUG_CLICK_NUMBER) {
            getDebugLog().then(msg => {
                msg && setDebugMessage(msg);
            });
            setDebugMode(true);
        } else {
            setDebugMode(false);
        }
    }, [debugModeClickCount]);

    const onClickOwner = async _ => {
        setDebugModeClickCount(debugModeClickCount + 1);
    };

    return (
        <SafeAreaView style={global.containerBg}>
            <ScrollView contentContainerStyle={{flexGrow: 1}}>
                <View style={global.containerInner}>
                    <View style={{marginVertical: 15}}>
                        <View
                            style={[
                                global.card,
                                global.boxShadow,
                                {padding: 20, textAlign: 'center', overflow: 'visible'},
                            ]}>
                            <Text style={[global.textTitle, styles.textTitle]} onPress={onClickOwner}>
                                {t(ABOUT_OWNER)}
                            </Text>
                            <Text style={[global.textMain, styles.textMain]}>{t(ABOUT_ADDRESS)}</Text>
                            <Text style={[global.textMain, styles.textMain]}>{t(ABOUT_OIB)}</Text>
                            <Text style={[global.textMain, styles.textMain]}>{t(ABOUT_SWIFT)}</Text>
                            <Text style={[global.textMain, styles.textMain]}>{t(ABOUT_IBAN)}</Text>
                            {!IS_IOS && (
                                <Text
                                    style={[global.link, styles.textMain, IS_WEB && {cursor: 'pointer'}]}
                                    onPress={openWeb}>
                                    {t(ABOUT_WEB)}
                                </Text>
                            )}
                            <Text
                                style={[global.link, styles.textMain, IS_WEB && {cursor: 'pointer'}]}
                                onPress={openEmail}>
                                {t(ABOUT_EMAIL_OFFICE)}
                            </Text>

                            <Text style={[global.textMain, styles.textMain]}>{APP_VERSION}</Text>
                            {debugMode && (
                                <>
                                    <Text style={[global.textMain, styles.textMain]}>DEBUG</Text>
                                    <Text style={[global.textMain, styles.textMain]}>{debugMessage}</Text>
                                </>
                            )}
                            <Button
                                title={t(CONTACT)}
                                onPress={onClickContact}
                                containerStyle={[
                                    global.Button.containerStyle,
                                    {marginVertical: 20, alignSelf: 'center'},
                                ]}
                                buttonStyle={global.Button.buttonStyle}
                                titleStyle={global.Button.titleStyle}
                            />
                        </View>
                    </View>

                    <ContactDialog visible={openContact} setVisible={setOpenContact} />
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export default ContactScreen;
