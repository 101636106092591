import React from 'react';
import {useTranslation} from 'react-i18next';
import {ListItem, Icon, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {FEMALE, GENDER_LABEL, MALE} from '../../constants/stringsAndFields';
import Modal from 'react-native-modal';
import {View, Text, Pressable} from 'react-native';

const GenderChooser = ({genderChooserVisible, setGenderChooserVisible, setGender}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();

    const toggleOverlay = () => {
        setGenderChooserVisible(false);
    };

    const onPressGender = gender => {
        setGender(gender);
        toggleOverlay();
    };

    return (
        <Modal
            isVisible={genderChooserVisible}
            onBackdropPress={toggleOverlay}
            backdropTransitionOutTiming={0}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text id="form-dialog-title" style={global.modalTitleText}>
                        {t(GENDER_LABEL)}
                    </Text>
                    <Pressable onPress={toggleOverlay} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <View style={global.modalContent}>
                    <ListItem key={MALE} onPress={e => onPressGender(MALE)}>
                        <ListItem.Content>
                            <ListItem.Title style={global.textMain}>{t(MALE)}</ListItem.Title>
                        </ListItem.Content>
                    </ListItem>
                    <ListItem key={FEMALE} onPress={e => onPressGender(FEMALE)}>
                        <ListItem.Content>
                            <ListItem.Title style={global.textMain}>{t(FEMALE)}</ListItem.Title>
                        </ListItem.Content>
                    </ListItem>
                </View>
            </View>
        </Modal>
    );
};

export default GenderChooser;
