import {
    COMMENT,
    CREATED_AT,
    EVISITOR_OIB,
    id,
    SELF_CHECKIN_FIELD,
    SLUG,
    UPDATED_AT,
    UPDATED_STATUS,
} from '../constants/stringsAndFields';
import {checkCheckinTable, CHECKINS_TABLE} from './checkin';
import {checkColumnExist, checkTableExists, insertData, selectData} from './common';
import {checkEvisitorTable, EVISITOR_TABLE} from './evisitor';
import {checkGuestTable, GUESTS_TABLE} from './guest';
import {checkInvoiceTable, createInvoiceTable, INVOICE_TABLE} from './invoice';
import {runSqlCmd} from './sqlOperations';

export const VERSIONS_TABLE = 'versions';

const keys = [id, COMMENT, CREATED_AT];

export const initVersionsTable = async tx => {
    const exist = await checkVersionsTable(tx);
    if (!exist) {
        await reCreateVersionsTable(tx);
        await migration1(tx);
    }
    try {
        const migrationTwoApplied = await isMigrationApplied(2, tx);
        if (!migrationTwoApplied) {
            await migration2(tx);
            await insertVersion(tx, {id: 2, [COMMENT]: 'evisitor table updated_at', [CREATED_AT]: null});
        }
    } catch (e) {
        console.log(e);
    }
    try {
        const migrationThreeApplied = await isMigrationApplied(3, tx);
        if (!migrationThreeApplied) {
            await migration3(tx);
            await insertVersion(tx, {id: 3, [COMMENT]: 'invoices table created', [CREATED_AT]: null});
        }
    } catch (e) {
        console.log(e);
    }
    try {
        const migrationFourApplied = await isMigrationApplied(4, tx);
        if (!migrationFourApplied) {
            await migration4(tx);
            await insertVersion(tx, {id: 4, [COMMENT]: 'guest table updated', [CREATED_AT]: null});
        }
    } catch (e) {
        console.log(e);
    }
    try {
        const migrationFiveApplied = await isMigrationApplied(5, tx);
        if (!migrationFiveApplied) {
            await migration5(tx);
            await insertVersion(tx, {id: 5, [COMMENT]: 'invoice table updated', [CREATED_AT]: null});
        }
    } catch (e) {
        console.log(e);
    }
    try {
        const migrationSixApplied = await isMigrationApplied(6, tx);
        if (!migrationSixApplied) {
            await migration6(tx);
            await insertVersion(tx, {id: 6, [COMMENT]: 'checkins table updated', [CREATED_AT]: null});
        }
    } catch (e) {
        console.log(e);
    }
};

export const reCreateVersionsTable = async tx => {
    await runSqlCmd(tx, `DROP TABLE IF EXISTS ${VERSIONS_TABLE};`);
    await runSqlCmd(
        tx,
        `CREATE TABLE IF NOT EXISTS ${VERSIONS_TABLE}(
      ${id} INTEGER PRIMARY KEY NOT NULL,
      ${COMMENT} TEXT,
      ${CREATED_AT} TEXT);`
    );
    const initVersion = {id: 1, [COMMENT]: 'init', [CREATED_AT]: null};
    await insertVersion(tx, initVersion);
};

export const insertVersion = async (tx, version) => {
    await insertData(tx, VERSIONS_TABLE, keys, version);
};

export const checkVersionsTable = async tx => {
    return await checkTableExists(tx, VERSIONS_TABLE);
};

const isMigrationApplied = async (versionId, tx) => {
    const version = await getVersion(versionId, tx);
    return version && version.length > 0 ? true : false;
};
const getVersion = async (versionId, tx) => {
    const versions = await selectData(tx, VERSIONS_TABLE, keys, id, versionId, id);
    return versions;
};

const migration1 = async tx => {
    const tableExist = await checkGuestTable(tx);
    if (tableExist) {
        let updatedColumnExists = await checkColumnExist(tx, GUESTS_TABLE, UPDATED_STATUS);
        if (!updatedColumnExists) {
            await runSqlCmd(tx, `ALTER TABLE ${GUESTS_TABLE} ADD COLUMN ${UPDATED_STATUS} INTEGER DEFAULT 0;`);
            updatedColumnExists = await checkColumnExist(tx, GUESTS_TABLE, UPDATED_STATUS);
            updatedColumnExists && console.log('Migration 1 applied successfully');
        }
    }
};

const migration2 = async tx => {
    const tableExist = await checkEvisitorTable(tx);
    if (tableExist) {
        let updatedColumnExists = await checkColumnExist(tx, EVISITOR_TABLE, UPDATED_AT);
        if (!updatedColumnExists) {
            await runSqlCmd(tx, `ALTER TABLE ${EVISITOR_TABLE} ADD COLUMN ${UPDATED_AT} TEXT;`);
            updatedColumnExists = await checkColumnExist(tx, EVISITOR_TABLE, UPDATED_AT);
            updatedColumnExists && console.log('Migration 2.1 applied successfully');
        }

        let createdColumnExists = await checkColumnExist(tx, EVISITOR_TABLE, CREATED_AT);
        if (!createdColumnExists) {
            await runSqlCmd(tx, `ALTER TABLE ${EVISITOR_TABLE} ADD COLUMN ${CREATED_AT} TEXT;`);
            createdColumnExists = await checkColumnExist(tx, EVISITOR_TABLE, CREATED_AT);
            createdColumnExists && console.log('Migration 2.2 applied successfully');
        }
    }
};

const migration3 = async tx => {
    const tableExist = await checkInvoiceTable(tx);
    if (!tableExist) {
        await createInvoiceTable(tx);
        const idColumnExists = await checkColumnExist(tx, INVOICE_TABLE, id);
        idColumnExists && console.log('Migration 3 applied successfully');
    }
};

const migration4 = async tx => {
    const tableExist = await checkGuestTable(tx);
    if (tableExist) {
        let updatedColumnExists = await checkColumnExist(tx, GUESTS_TABLE, SELF_CHECKIN_FIELD);
        if (!updatedColumnExists) {
            await runSqlCmd(tx, `ALTER TABLE ${GUESTS_TABLE} ADD COLUMN ${SELF_CHECKIN_FIELD} INTEGER DEFAULT 0;`);
            updatedColumnExists = await checkColumnExist(tx, GUESTS_TABLE, UPDATED_STATUS);
            updatedColumnExists && console.log('Migration 4 applied successfully');
        }
    }
};

const migration5 = async tx => {
    const tableExist = await checkInvoiceTable(tx);
    if (tableExist) {
        let updatedColumnExists = await checkColumnExist(tx, INVOICE_TABLE, EVISITOR_OIB);
        if (!updatedColumnExists) {
            await runSqlCmd(tx, `ALTER TABLE ${INVOICE_TABLE} ADD COLUMN ${EVISITOR_OIB} TEXT;`);
            updatedColumnExists = await checkColumnExist(tx, INVOICE_TABLE, EVISITOR_OIB);
            updatedColumnExists && console.log('Migration 5 applied successfully');
        }
    }
};

const migration6 = async tx => {
    const tableExist = await checkCheckinTable(tx);
    if (tableExist) {
        let updatedColumnExists = await checkColumnExist(tx, CHECKINS_TABLE, SLUG);
        if (!updatedColumnExists) {
            await runSqlCmd(tx, `ALTER TABLE ${CHECKINS_TABLE} ADD COLUMN ${SLUG} TEXT;`);
            updatedColumnExists = await checkColumnExist(tx, CHECKINS_TABLE, SLUG);
            updatedColumnExists && console.log('Migration 6 applied successfully');
        }
    }
};
