import {utils, write} from 'xlsx-js-style';
import {
    EV_ACCOUNTS_DATA,
    EXPORT_HEADER_ADDRESS,
    EXPORT_HEADER_AGE_REPORT_NAME,
    EXPORT_HEADER_CITIZENSHIP_REPORT_NAME,
    EXPORT_HEADER_GENDERS_REPORT_NAME,
    EXPORT_HEADER_INTRO,
    EXPORT_HEADER_NAME,
    EXPORT_HEADER_OIB,
    EXPORT_HEADER_PLACE,
    EXPORT_HEADER_REPORT_NAME,
    EXPORT_HEADER_REPORT_OBJECT,
    EXPORT_HEADER_REPORT_OBJECT_ALL,
    EXPORT_HEADER_REPORT_PERIOD,
    EXPORT_HEADER_REPORT_TIME,
    EXPORT_INVOICE_HEADER_REPORT_BOOK_NAME,
    EXPORT_TOURISTS_HEADER_REPORT_BOOK_NAME,
    FEMALE,
    MALE,
} from '../constants/stringsAndFields';
import {getNowDayDateReportFormated} from './dateHelper';
import {EXPORT_CHECKIN_SHEET, EXPORT_INVOICE_SHEET} from './export';
import {writeBufferXlsxToFile} from './exportData';
import {getExportUserData} from './helpers';
import capitalize from 'lodash/capitalize';
import dropRight from 'lodash/dropRight';

export const writeToXlsxWithHeader = async (data, headerData, fileName, sheetName, t) => {
    const wb = utils.book_new();
    const reportTitle =
        sheetName === EXPORT_CHECKIN_SHEET
            ? EXPORT_TOURISTS_HEADER_REPORT_BOOK_NAME
            : EXPORT_INVOICE_HEADER_REPORT_BOOK_NAME;

    const isTouristsExport = sheetName === EXPORT_CHECKIN_SHEET;

    // Report Header
    const headerReportInfo = [
        [
            {v: t(EXPORT_HEADER_REPORT_NAME), t: 's', s: {font: {bold: true}}},
            {v: t(reportTitle), t: 's'},
        ],
        [
            {v: t(EXPORT_HEADER_REPORT_TIME), t: 's', s: {font: {bold: true}}},
            {v: getNowDayDateReportFormated(), t: 's'},
        ],
        [
            {v: t(EXPORT_HEADER_REPORT_OBJECT), t: 's', s: {font: {bold: true}}},
            {v: t(EXPORT_HEADER_REPORT_OBJECT_ALL), t: 's'},
        ],
        [
            {v: t(EXPORT_HEADER_REPORT_PERIOD), t: 's', s: {font: {bold: true}}},
            {v: headerData?.['dateRange'], t: 's'},
        ],
        [{v: ''}],
    ];

    const evAccounts = headerData?.[EV_ACCOUNTS_DATA];
    const multiEvAccounts = evAccounts && evAccounts.length > 1;

    const headerUserData = getExportUserData(evAccounts.pop());
    const headerUserInfo = [
        [{v: t(EXPORT_HEADER_INTRO), t: 's', s: {font: {bold: true}}}],
        [
            {v: t(EXPORT_HEADER_NAME), t: 's', s: {font: {bold: true}}},
            {v: headerUserData[EXPORT_HEADER_NAME], t: 's'},
        ],
        [
            {v: t(EXPORT_HEADER_OIB), t: 's', s: {font: {bold: true}}},
            {v: headerUserData[EXPORT_HEADER_OIB], t: 's'},
        ],
        [
            {v: t(EXPORT_HEADER_PLACE), t: 's', s: {font: {bold: true}}},
            {v: headerUserData[EXPORT_HEADER_PLACE], t: 's'},
        ],
        [
            {v: t(EXPORT_HEADER_ADDRESS), t: 's', s: {font: {bold: true}}},
            {v: headerUserData[EXPORT_HEADER_ADDRESS], t: 's'},
        ],
        [{v: ''}],
    ];

    // Data Header
    // removing last three columns: countryShort, ageRange, genderMale
    const dataNameHeader = [
        dropRight(
            Object.keys(data[0]).map(name => {
                return {v: name, t: 's', s: {font: {bold: true}}};
            }),
            isTouristsExport ? 3 : 0
        ),
    ];

    // Data Items
    const dataItems = [];
    data.map(item => {
        const cellData = dropRight(Object.values(item), isTouristsExport ? 3 : 0); // removing last three columns: countryShort, ageRange, genderMale
        const out = [];
        cellData.forEach(cell => out.push({v: cell, t: 's'}));
        dataItems.push(out);
    });

    const ws = multiEvAccounts
        ? utils.aoa_to_sheet([...headerReportInfo, ...dataNameHeader, ...dataItems])
        : utils.aoa_to_sheet([...headerReportInfo, ...headerUserInfo, ...dataNameHeader, ...dataItems]);

    // settings column witdh
    // TODO: caclulate this column sizes dynamically by counting chars for each cell
    let wscols = [{wch: 27}, {wch: 20}, {wch: 10}, {wch: 20}, {wch: 10}];
    if (sheetName === EXPORT_CHECKIN_SHEET) {
        //       ownerOib	ownerName	facilityName	accomodationUnit	touristName	touristStayFrom	touristForseenStayUntil	touristStatus
        wscols = [{wch: 27}, {wch: 20}, {wch: 30}, {wch: 10}, {wch: 25}, {wch: 25}, {wch: 25}, {wch: 10}];
    } else if (sheetName === EXPORT_INVOICE_SHEET) {
        //      Redni broj	Broj računa	Nadnevak	Ime i prezime	Iznos računa Način plaćanja
        wscols = [{wch: 27}, {wch: 20}, {wch: 10}, {wch: 20}, {wch: 10}, {wch: 18}];
    }
    ws['!cols'] = wscols;
    utils.book_append_sheet(wb, ws, sheetName);

    if (isTouristsExport) {
        const countries = {};
        const genders = {[MALE]: 0, [FEMALE]: 0};
        const ageRanges = {
            ['0-12']: 0,
            ['12-18']: 0,
            ['18-30']: 0,
            ['30-40']: 0,
            ['40-50']: 0,
            ['50-60']: 0,
            ['60-75']: 0,
            ['75-120']: 0,
        };
        for (const tourist of data) {
            if (tourist.countryShort) {
                if (countries[tourist.countryShort]) {
                    countries[tourist.countryShort] = countries[tourist.countryShort] + 1;
                } else {
                    countries[tourist.countryShort] = 1;
                }
            }
            if (tourist.genderMale) {
                genders[MALE] = genders[MALE] + 1;
            } else {
                genders[FEMALE] = genders[FEMALE] + 1;
            }
            if (tourist.ageRange && ageRanges.hasOwnProperty(tourist.ageRange)) {
                ageRanges[tourist.ageRange] = ageRanges[tourist.ageRange] + 1;
            }
        }

        // CITIZENSHIP
        const headerCitizenshipReportInfo = [
            [
                {v: t(EXPORT_HEADER_REPORT_NAME), t: 's', s: {font: {bold: true}}},
                {v: t(EXPORT_HEADER_CITIZENSHIP_REPORT_NAME), t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_REPORT_TIME), t: 's', s: {font: {bold: true}}},
                {v: getNowDayDateReportFormated(), t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_REPORT_OBJECT), t: 's', s: {font: {bold: true}}},
                {v: t(EXPORT_HEADER_REPORT_OBJECT_ALL), t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_REPORT_PERIOD), t: 's', s: {font: {bold: true}}},
                {v: headerData?.['dateRange'], t: 's'},
            ],
            [{v: ''}],
        ];

        const citizenshipNameHeader = [
            [
                {v: 'Citizenship', t: 's', s: {font: {bold: true}}},
                {v: 'Count', t: 's', s: {font: {bold: true}}},
            ],
        ];

        const citizenshipDataItems = [];
        Object.keys(countries).map(countryName => {
            citizenshipDataItems.push([
                {v: countryName, t: 's'},
                {v: countries[countryName], t: 's'},
            ]);
        });

        const ws2 = utils.aoa_to_sheet([
            ...headerCitizenshipReportInfo,
            ...citizenshipNameHeader,
            ...citizenshipDataItems,
        ]);
        const wscols = [{wch: 27}, {wch: 20}];
        ws2['!cols'] = wscols;
        utils.book_append_sheet(wb, ws2, 'Citizenship');

        // GENDERS
        const headerGendersReportInfo = [
            [
                {v: t(EXPORT_HEADER_REPORT_NAME), t: 's', s: {font: {bold: true}}},
                {v: t(EXPORT_HEADER_GENDERS_REPORT_NAME), t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_REPORT_TIME), t: 's', s: {font: {bold: true}}},
                {v: getNowDayDateReportFormated(), t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_REPORT_OBJECT), t: 's', s: {font: {bold: true}}},
                {v: t(EXPORT_HEADER_REPORT_OBJECT_ALL), t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_REPORT_PERIOD), t: 's', s: {font: {bold: true}}},
                {v: headerData?.['dateRange'], t: 's'},
            ],
            [{v: ''}],
        ];

        const gendersNameHeader = [
            [
                {v: 'Gender', t: 's', s: {font: {bold: true}}},
                {v: 'Count', t: 's', s: {font: {bold: true}}},
            ],
        ];

        const gendersDataItems = [];
        Object.keys(genders).map(gender => {
            gendersDataItems.push([
                {v: capitalize(gender), t: 's'},
                {v: genders[gender], t: 's'},
            ]);
        });

        const ws3 = utils.aoa_to_sheet([...headerGendersReportInfo, ...gendersNameHeader, ...gendersDataItems]);
        ws3['!cols'] = wscols;
        utils.book_append_sheet(wb, ws3, 'Gender');

        // AGE
        const headerAgeReportInfo = [
            [
                {v: t(EXPORT_HEADER_REPORT_NAME), t: 's', s: {font: {bold: true}}},
                {v: t(EXPORT_HEADER_AGE_REPORT_NAME), t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_REPORT_TIME), t: 's', s: {font: {bold: true}}},
                {v: getNowDayDateReportFormated(), t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_REPORT_OBJECT), t: 's', s: {font: {bold: true}}},
                {v: t(EXPORT_HEADER_REPORT_OBJECT_ALL), t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_REPORT_PERIOD), t: 's', s: {font: {bold: true}}},
                {v: headerData?.['dateRange'], t: 's'},
            ],
            [{v: ''}],
        ];

        const gendersAgeHeader = [
            [
                {v: 'Age', t: 's', s: {font: {bold: true}}},
                {v: 'Count', t: 's', s: {font: {bold: true}}},
            ],
        ];

        const ageDataItems = [];
        Object.keys(ageRanges).map(ageRange => {
            ageDataItems.push([
                {v: ageRange, t: 's'},
                {v: ageRanges[ageRange], t: 's'},
            ]);
        });

        const ws4 = utils.aoa_to_sheet([...headerAgeReportInfo, ...gendersAgeHeader, ...ageDataItems]);
        ws4['!cols'] = wscols;
        utils.book_append_sheet(wb, ws4, 'Age');
    }

    const wbout = write(wb, {type: 'buffer', bookType: 'xlsx'});
    await writeBufferXlsxToFile(wbout, fileName, t);
};
