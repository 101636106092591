import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    modalDialog: {
        maxWidth: 400,
        height: 150,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 20,
        backgroundColor: 'transparent',
    },

    modalContent: {
        justifyContent: 'center',
    },

    progressTitle: {
        fontFamily: theme.fontFamilyMedium,
        fontSize: 18,
        lineHeight: 30,
        color: theme.colors.primary,
        textAlign: 'center',
        marginBottom: 10,
    },
}));

export default useStyles;
