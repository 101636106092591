import {Platform} from 'react-native';
import {
    CODE,
    DOCUMENT_TYPE_DATA,
    HR_LOCALE,
    HR_LOCALE_ALT,
    LANGUAGE_BG_CODE,
    LANGUAGE_ZH_CODE,
    LANGUAGE_CS_CODE,
    LANGUAGE_DA_CODE,
    LANGUAGE_DE_CODE,
    LANGUAGE_ET_CODE,
    LANGUAGE_EN_CODE,
    LANGUAGE_ES_CODE,
    LANGUAGE_FI_CODE,
    LANGUAGE_FR_CODE,
    LANGUAGE_HU_CODE,
    LANGUAGE_HI_CODE,
    LANGUAGE_IT_CODE,
    LANGUAGE_JA_CODE,
    LANGUAGE_KO_CODE,
    LANGUAGE_LT_CODE,
    LANGUAGE_LV_CODE,
    LANGUAGE_NB_CODE,
    LANGUAGE_NL_CODE,
    LANGUAGE_PL_CODE,
    LANGUAGE_PT_CODE,
    LANGUAGE_RO_CODE,
    LANGUAGE_RU_CODE,
    LANGUAGE_SI_CODE,
    LANGUAGE_SK_CODE,
    LANGUAGE_SV_CODE,
    LANGUAGE_TR_CODE,
    LANGUAGE_UA_CODE,
    NAME,
    code,
    name,
} from '../constants/stringsAndFields';

import * as bgDocTypeData from '../translations/documentTypes/DocumentTypeLookupBg.json';
import * as zhDocTypeData from '../translations/documentTypes/DocumentTypeLookupZh.json';
import * as csDocTypeData from '../translations/documentTypes/DocumentTypeLookupCs.json';
import * as deDocTypeData from '../translations/documentTypes/DocumentTypeLookupDe.json';
import * as etDocTypeData from '../translations/documentTypes/DocumentTypeLookupEt.json';
import * as enDocTypeData from '../translations/documentTypes/DocumentTypeLookupEn.json';
import * as esDocTypeData from '../translations/documentTypes/DocumentTypeLookupEs.json';
import * as frDocTypeData from '../translations/documentTypes/DocumentTypeLookupFr.json';
import * as huDocTypeData from '../translations/documentTypes/DocumentTypeLookupHu.json';
import * as hiDocTypeData from '../translations/documentTypes/DocumentTypeLookupHi.json';
import * as itDocTypeData from '../translations/documentTypes/DocumentTypeLookupIt.json';
import * as jaDocTypeData from '../translations/documentTypes/DocumentTypeLookupJa.json';
import * as koDocTypeData from '../translations/documentTypes/DocumentTypeLookupKo.json';
import * as ltDocTypeData from '../translations/documentTypes/DocumentTypeLookupLt.json';
import * as lvDocTypeData from '../translations/documentTypes/DocumentTypeLookupLv.json';
import * as plDocTypeData from '../translations/documentTypes/DocumentTypeLookupPl.json';
import * as roDocTypeData from '../translations/documentTypes/DocumentTypeLookupRo.json';
import * as ruDocTypeData from '../translations/documentTypes/DocumentTypeLookupRu.json';
import * as siDocTypeData from '../translations/documentTypes/DocumentTypeLookupSi.json';
import * as skDocTypeData from '../translations/documentTypes/DocumentTypeLookupSk.json';
import * as trDocTypeData from '../translations/documentTypes/DocumentTypeLookupTr.json';
import * as uaDocTypeData from '../translations/documentTypes/DocumentTypeLookupUa.json';
import * as daDocTypeData from '../translations/documentTypes/DocumentTypeLookupDa.json';
import * as fiDocTypeData from '../translations/documentTypes/DocumentTypeLookupFi.json';
import * as nbDocTypeData from '../translations/documentTypes/DocumentTypeLookupNb.json';
import * as nlDocTypeData from '../translations/documentTypes/DocumentTypeLookupNl.json';
import * as ptDocTypeData from '../translations/documentTypes/DocumentTypeLookupPt.json';
import * as svDocTypeData from '../translations/documentTypes/DocumentTypeLookupSv.json';

export const DOC_TYPE_LANGS = [
    {code: LANGUAGE_EN_CODE, name: enDocTypeData},
    {code: LANGUAGE_DE_CODE, name: deDocTypeData},
    {code: LANGUAGE_FR_CODE, name: frDocTypeData},
    {code: LANGUAGE_IT_CODE, name: itDocTypeData},
    {code: LANGUAGE_ES_CODE, name: esDocTypeData},
    {code: LANGUAGE_PL_CODE, name: plDocTypeData},
    {code: LANGUAGE_CS_CODE, name: csDocTypeData},
    {code: LANGUAGE_HU_CODE, name: huDocTypeData},
    {code: LANGUAGE_BG_CODE, name: bgDocTypeData},
    {code: LANGUAGE_ZH_CODE, name: zhDocTypeData},
    {code: LANGUAGE_ET_CODE, name: etDocTypeData},
    {code: LANGUAGE_JA_CODE, name: jaDocTypeData},
    {code: LANGUAGE_LT_CODE, name: ltDocTypeData},
    {code: LANGUAGE_LV_CODE, name: lvDocTypeData},
    {code: LANGUAGE_RO_CODE, name: roDocTypeData},
    {code: LANGUAGE_RU_CODE, name: ruDocTypeData},
    {code: LANGUAGE_SI_CODE, name: siDocTypeData},
    {code: LANGUAGE_SK_CODE, name: skDocTypeData},
    {code: LANGUAGE_TR_CODE, name: trDocTypeData},
    {code: LANGUAGE_UA_CODE, name: uaDocTypeData},
    {code: LANGUAGE_KO_CODE, name: koDocTypeData},
    {code: LANGUAGE_DA_CODE, name: daDocTypeData},
    {code: LANGUAGE_FI_CODE, name: fiDocTypeData},
    {code: LANGUAGE_HI_CODE, name: hiDocTypeData},
    {code: LANGUAGE_NB_CODE, name: nbDocTypeData},
    {code: LANGUAGE_NL_CODE, name: nlDocTypeData},
    {code: LANGUAGE_PT_CODE, name: ptDocTypeData},
    {code: LANGUAGE_SV_CODE, name: svDocTypeData},
];

export const translateDocTypes = (docTypes, lang = 'en') => {
    if (docTypes) {
        try {
            const clonedDocTypes = JSON.parse(JSON.stringify(docTypes));
            let records = [];
            // translate to eng every language except croatian
            if (![HR_LOCALE, HR_LOCALE_ALT].includes(lang)) {
                const docType = DOC_TYPE_LANGS.find(item => item[code] === lang)?.[name];
                if (docType) {
                    records = docType?.[DOCUMENT_TYPE_DATA];
                }
            }
            if (records) {
                for (const dataType of records) {
                    const matchedRecordIndex = clonedDocTypes.findIndex(item => item[CODE] === dataType[CODE]);
                    if (matchedRecordIndex !== -1) {
                        clonedDocTypes[matchedRecordIndex][NAME] = dataType[NAME];
                    }
                }
            }
            return clonedDocTypes;
        } catch (e) {
            console.log(e);
        }
        return docTypes;
    }
    return null;
};

export const generateDocLink = (t, docName) => {
    const envUrl = process.env.REACT_APP_BASE_URL;
    if (Platform.OS === 'web') {
        const port = window.location.port;
        const noPort = !port || port === '' ? true : false;
        if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
            return `${location.protocol}//${location.hostname}${noPort ? '' : ':' + port}/${t(docName)}`;
        }
    }
    if (envUrl) return `${envUrl}/${t(docName)}`;
};

export const resolveLabelTranslations = trans => {
    if (trans?.['nextMonth']) {
        return trans;
    } else {
        const localeText = trans?.['components']?.['MuiLocalizationProvider']?.['defaultProps']?.['localeText'];
        if (localeText?.['nextMonth']) {
            return localeText;
        }
    }
    return null;
};
