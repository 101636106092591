import {useNavigation} from '@react-navigation/native';
import isEqual from 'lodash/isEqual';
import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Text, View, useWindowDimensions} from 'react-native';
import {Icon, ListItem, useTheme} from 'react-native-elements';
import {
    ADDITIONAL_FIELDS,
    FORESEEN_STAY_UNTIL_FIELD,
    GUEST_NOTE_FIELD,
    STAY_FROM_FIELD,
    content,
    id,
    status,
} from '../../constants/stringsAndFields';
import globalStyle from '../../theme/globalStyle';
import {getShortFormattedDateFromDateString} from '../../utils/dateHelper';
import {
    getGuestStatusBorderColor,
    getGuestStatusLabel,
    getGuestStatusLabelColor,
    getGuestTitleWithAge,
    showQRCode,
} from '../../utils/guestUtils';

const GuestItem = ({guest, checkin, onDeleteCheckinGuest, openQRDetails, updateGuestId}) => {
    const navigation = useNavigation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();

    const width = useWindowDimensions().width;
    const isDesktop = width >= theme.desktopBP;

    const openDetailsScreen = useCallback(() => {
        navigation.navigate('Guest', {guestId: guest[id], checkinId: checkin[id]});
    }, [guest[id], checkin[id]]);

    const handleDeleteClick = useCallback(
        async e => {
            e.stopPropagation();
            await onDeleteCheckinGuest(guest);
        },
        [guest]
    );

    const handleQRClick = useCallback(() => {
        updateGuestId(guest[id]);
        openQRDetails();
    }, [guest[id]]);

    const showGuestNote = guest?.[content]?.[ADDITIONAL_FIELDS]?.[GUEST_NOTE_FIELD] ? true : false;

    return (
        <>
            <ListItem
                key={guest?.[id]}
                onPress={openDetailsScreen}
                containerStyle={[global.listItem, {borderColor: getGuestStatusBorderColor(guest, theme)}]}
                style={[
                    global.boxShadow,
                    {
                        marginBottom: 15,
                        borderRadius: 4,
                    },
                ]}
                underlayColor="white">
                <View style={{flex: 1}}>
                    <View style={[isDesktop && {flexDirection: 'row', alignItems: 'center'}, {flex: 1}]}>
                        <View
                            style={[!isDesktop && {marginBottom: 15, paddingRight: 160}, isDesktop && {width: '30%'}]}>
                            <View style={global.flexCenter}>
                                <Icon
                                    type="ionicon"
                                    name="person"
                                    size={16}
                                    color={getGuestStatusBorderColor(guest, theme)}
                                    style={{marginRight: 8}}
                                />
                                <Text style={[global.textMain, {fontFamily: theme.fontFamilySemibold, fontSize: 13}]}>
                                    {getGuestTitleWithAge(t, guest)}
                                </Text>
                            </View>
                        </View>

                        <View style={[global.flexCenter, isDesktop && {width: '50%'}]}>
                            <View
                                style={[
                                    !isDesktop && !isDesktop && {position: 'absolute', right: 0, top: -35},
                                    isDesktop && {width: '50%', alignItems: 'center', paddingHorizontal: 10},
                                ]}>
                                <View style={global.flexCenter}>
                                    <Text style={[global.checkinLabel, getGuestStatusLabelColor(guest, theme)]}>
                                        {getGuestStatusLabel(guest, t)}
                                    </Text>
                                </View>
                            </View>
                            <View style={[isDesktop && {width: '50%', alignItems: 'center', paddingHorizontal: 10}]}>
                                <View
                                    style={[
                                        global.flexCenter,
                                        global.listItemIconTextWrapper,
                                        {minWidth: 140, alignSelf: 'center'},
                                    ]}>
                                    <Icon
                                        type="ionicon"
                                        name="calendar-outline"
                                        size={16}
                                        color={theme.colors.textColorMedium}
                                        style={{marginRight: 8}}
                                    />
                                    <Text style={[global.textMain]}>
                                        {getShortFormattedDateFromDateString(guest?.[content]?.[STAY_FROM_FIELD])}
                                    </Text>
                                    <Text
                                        style={{
                                            color: theme.colors.greyLight,
                                            marginHorizontal: 6,
                                        }}>
                                        /
                                    </Text>
                                    <Text style={[global.textMain]}>
                                        {getShortFormattedDateFromDateString(
                                            guest?.[content]?.[FORESEEN_STAY_UNTIL_FIELD]
                                        )}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View
                            style={[
                                !isDesktop && {position: 'absolute', right: 50, bottom: 0},
                                isDesktop && {width: '10%', alignItems: 'center', paddingHorizontal: 10},
                            ]}>
                            {showQRCode(guest[status]) && (
                                <Icon type="ionicon" name={'qr-code-outline'} size={20} onPress={handleQRClick} />
                            )}
                        </View>

                        <View
                            style={[
                                !isDesktop && {position: 'absolute', right: 0, bottom: 0},
                                isDesktop && {width: '10%', alignItems: 'center', paddingHorizontal: 10},
                            ]}>
                            <Icon type="ionicon" name="trash-outline" size={20} onPress={handleDeleteClick} />
                        </View>
                    </View>
                    {showGuestNote && (
                        <View style={global.listNote}>
                            <Icon
                                name="edit"
                                size={12}
                                color={theme.colors.textColorLight}
                                containerStyle={{marginRight: 8, top: 1}}
                            />
                            <Text style={global.listNoteText}>
                                {guest?.[content]?.[ADDITIONAL_FIELDS]?.[GUEST_NOTE_FIELD] ?? ''}
                            </Text>
                        </View>
                    )}
                </View>
            </ListItem>
        </>
    );
};

function arePropsEqual(prevProps, nextProps) {
    return isEqual(prevProps.guest, nextProps.guest);
}

export default memo(GuestItem, arePropsEqual);
