import React from 'react';
import {Pressable, View} from 'react-native';
import {Icon} from 'react-native-elements';

const EVChooserHeader = props => {
    const {evAccount, onRefresh, global, theme} = props;

    const onRefreshPress = async _ => {
        await onRefresh(evAccount);
    };

    return (
        <View style={[global.flexCenter]}>
            <Pressable onPress={onRefreshPress} style={{marginRight: 15}}>
                <Icon type="ionicon" name="refresh-outline" size={22} color={theme.colors.primary} />
            </Pressable>
        </View>
    );
};

export default EVChooserHeader;
