import {LocalizationProvider, MobileDatePicker, MobileTimePicker} from '@mui/x-date-pickers';
import {useTranslation} from 'react-i18next';
import {FORESEEN_STAY_UNTIL_FIELD, STAY_FROM_FIELD} from '../../constants/stringsAndFields';
import {get20YearsAgo, getDateFromDateString, getYesterday} from '../../utils/dateHelper';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {
    bg,
    da,
    de,
    es,
    cs,
    et,
    fi,
    fr,
    hr,
    hu,
    hi,
    it,
    ja,
    ko,
    lt,
    lv,
    nb,
    nl,
    pl,
    pt,
    sv,
    ro,
    ru,
    sl,
    sk,
    tr,
    uk,
    zh,
} from 'date-fns/locale';
import {
    beBY,
    caES,
    csCZ,
    daDK,
    deDE,
    elGR,
    enUS,
    esES,
    faIR,
    fiFI,
    frFR,
    heIL,
    huHU,
    isIS,
    itIT,
    jaJP,
    koKR,
    kzKZ,
    nbNO,
    nlNL,
    plPL,
    ptBR,
    roRO,
    ruRU,
    skSK,
    svSE,
    trTR,
    ukUA,
    urPK,
    viVN,
    zhCN,
} from '@mui/x-date-pickers/locales';
// custom
import hrHR from '../../translations/muiDatePicker/hrHR';
import {resolveLabelTranslations} from '../../utils/translation';
import {isBirthDayField} from '../../utils/helpers';

// this langs are supported currently: https://mui.com/x/react-date-pickers/localization/
const MUI_DATE_PICKER_LABELS_TRANSLATION = {
    ['hr']: hrHR,
    ['en']: enUS,
    ['da']: daDK,
    ['de']: deDE,
    ['es']: esES,
    ['fr']: frFR,
    ['hu']: huHU,
    ['it']: itIT,
    ['ja']: jaJP,
    ['ko']: koKR,
    ['pl']: plPL,
    ['ro']: roRO,
    ['ru']: ruRU,
    ['sk']: skSK,
    ['tr']: trTR,
    ['ua']: ukUA,
    ['be']: beBY,
    ['ca']: caES,
    ['cs']: csCZ,
    ['el']: elGR,
    ['fa']: faIR,
    ['fi']: fiFI,
    ['he']: heIL,
    ['is']: isIS,
    ['kz']: kzKZ,
    ['nb']: nbNO,
    ['nl']: nlNL,
    ['pt']: ptBR,
    ['sv']: svSE,
    ['ur']: urPK,
    ['vi']: viVN,
    ['zh']: zhCN,
};

const MUI_DATE_PICKER_LOCALES = {
    ['bg']: bg,
    ['cs']: cs,
    ['da']: da,
    ['de']: de,
    ['et']: et,
    ['es']: es,
    ['fi']: fi,
    ['fr']: fr,
    ['hi']: hi,
    ['hr']: hr,
    ['hu']: hu,
    ['it']: it,
    ['ja']: ja,
    ['ko']: ko,
    ['lt']: lt,
    ['lv']: lv,
    ['nb']: nb,
    ['nl']: nl,
    ['pl']: pl,
    ['pt']: pt,
    ['ro']: ro,
    ['ru']: ru,
    ['si']: sl,
    ['sk']: sk,
    ['sv']: sv,
    ['tr']: tr,
    ['ua']: uk,
    ['zh']: zh,
};

// api: https://mui.com/x/api/date-pickers/mobile-date-picker/
// api: https://mui.com/x/api/date-pickers/mobile-time-picker/
const MuiDateTimePicker = ({closed, setClosed, onChangeDate, onChangeTime, datePickerData}) => {
    const {
        datePickerMode = 'date',
        datePickerDate,
        currentPickerField = FORESEEN_STAY_UNTIL_FIELD,
        checkinData,
        dateBoundary,
        openTo = 'day',
        rawDateValue = null,
    } = datePickerData ?? {};
    const {i18n} = useTranslation();
    const isVisible = !closed ? true : false;
    if (!isVisible) return null;

    const close = () => {
        setClosed(true);
    };

    const onDatePickerDateChange = async date => {
        if (date) {
            if (datePickerMode === 'date') {
                await onChangeDate(date, currentPickerField);
            } else {
                await onChangeTime(date, currentPickerField);
            }
        }
        close();
    };

    const dateBoundaries = () => {
        let startDate = null;
        let endDate = null;
        if (checkinData && currentPickerField === FORESEEN_STAY_UNTIL_FIELD) {
            startDate = getDateFromDateString(checkinData[STAY_FROM_FIELD]);
        } else if (isBirthDayField(currentPickerField)) {
            endDate = getYesterday();
        } else if (dateBoundary) {
            startDate = dateBoundary?.['startDate'];
            endDate = dateBoundary?.['endDate'];
        }

        return {startDate, endDate};
    };

    const {startDate, endDate} = dateBoundaries();

    if (datePickerMode === 'date') {
        const currentDate = isBirthDayField(currentPickerField) && !rawDateValue ? get20YearsAgo() : datePickerDate;
        return (
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={MUI_DATE_PICKER_LOCALES[i18n.language]}
                localeText={resolveLabelTranslations(MUI_DATE_PICKER_LABELS_TRANSLATION[i18n.language])}>
                <MobileDatePicker
                    open={isVisible}
                    onClose={close}
                    onAccept={onDatePickerDateChange}
                    value={currentDate}
                    openTo={openTo}
                    minDate={startDate}
                    maxDate={endDate}
                />
            </LocalizationProvider>
        );
    } else {
        return (
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={MUI_DATE_PICKER_LOCALES[i18n.language]}
                localeText={resolveLabelTranslations(MUI_DATE_PICKER_LABELS_TRANSLATION[i18n.language])}>
                <MobileTimePicker
                    open={isVisible}
                    onClose={close}
                    onAccept={onDatePickerDateChange}
                    value={datePickerDate}
                />
            </LocalizationProvider>
        );
    }
};

export default MuiDateTimePicker;
