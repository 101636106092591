import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Pressable, View} from 'react-native';
import {Button, Icon, Text, useTheme} from 'react-native-elements';
import Modal from 'react-native-modal';
import {fetchCredits} from '../api/inCheckinRestService';
import {
    BUY_CREDITS,
    DECLINE,
    BEST_BEFORE,
    CREDITS,
    UNLIMITED,
    data_credits,
    data_credits_best_before,
} from '../constants/stringsAndFields';
import {getFormattedDateFromSqlOrUnlimited} from '../utils/dateHelper';
import globalStyle from '../theme/globalStyle';
import useStyles from '../screens/AccountScreen.styles';

const CreditsDepletedDialog = ({title, content, onBuy, onReject = null, showButtons = true, useSharedDialogs}) => {
    const {theme} = useTheme();
    const styles = useStyles(theme);
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const {creditsDepletedOpen, closeCreditsDepleted} = useSharedDialogs();
    const [credits, setCredits] = useState(null);

    const close = async () => {
        closeCreditsDepleted();
        onReject && (await onReject());
    };

    const buy = async _ => {
        closeCreditsDepleted();
        await onBuy();
    };

    const refetchCredits = async () => {
        try {
            const fetchedCredits = await fetchCredits();
            fetchedCredits && setCredits(fetchedCredits);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (creditsDepletedOpen) {
            refetchCredits().catch(console.error);
        }
    }, [creditsDepletedOpen]);

    return (
        <Modal
            isVisible={creditsDepletedOpen}
            onBackdropPress={close}
            onBackButtonPress={close}
            backdropTransitionOutTiming={0}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                {title && (
                    <View style={global.modalTitle}>
                        <Text style={global.modalTitleText}>{title}</Text>
                        <Pressable onPress={close} style={global.modalCloseButton}>
                            <Icon
                                name="close"
                                containerStyle={global.modalCloseIcon}
                                color={theme.colors.white}
                                size={20}
                            />
                        </Pressable>
                    </View>
                )}

                {content && (
                    <View style={[global.modalContent, {paddingVertical: 20}]}>
                        {credits && (
                            <>
                                <Text style={[global.modalContentText]}>
                                    <Text style={global.modalContentText}>{t(CREDITS)}</Text>
                                    <Text style={styles.itemValuePrimary}>{credits?.[data_credits]}</Text>
                                </Text>
                                <Text style={[global.modalContentText]}>
                                    <Text style={global.modalContentText}>{t(BEST_BEFORE)}</Text>
                                    <Text style={styles.itemValuePrimary}>
                                        {getFormattedDateFromSqlOrUnlimited(
                                            credits?.[data_credits_best_before],
                                            t(UNLIMITED)
                                        )}
                                    </Text>
                                </Text>
                            </>
                        )}
                        <Text style={[global.modalContentText, {marginTop: 15}]}>{content}</Text>
                    </View>
                )}

                {showButtons && (
                    <View style={global.modalFooter}>
                        <Button
                            onPress={close}
                            title={t(DECLINE)}
                            containerStyle={global.ModalButton.Negative.containerStyle}
                            buttonStyle={global.ModalButton.Negative.buttonStyle}
                            titleStyle={global.ModalButton.Negative.titleStyle}
                            disabledStyle={global.ModalButton.disabledStyle}
                            disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                        />
                        <Button
                            onPress={buy}
                            title={t(BUY_CREDITS)}
                            containerStyle={global.ModalButton.Positive.containerStyle}
                            buttonStyle={global.ModalButton.Positive.buttonStyle}
                            titleStyle={global.ModalButton.Positive.titleStyle}
                            disabledStyle={global.ModalButton.disabledStyle}
                            disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                        />
                    </View>
                )}
            </View>
        </Modal>
    );
};

export default CreditsDepletedDialog;
