import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Pressable} from 'react-native';
import {Button, Icon, Text, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import Modal from 'react-native-modal';
import {CHANGE_PASS, CONFIRM, CONFIRM_PASSWORD, NEW_PASSWORD, OLD_PASSWORD} from '../../constants/stringsAndFields';
import {getPassword} from '../../utils/userUtils';
import UITextInput from '../ui-components/UITextInput';

const ChangePasswordDialog = ({open, setOpen, onConfirm}) => {
    const {t} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const [oldPass, setOldPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [unhideOldPass, setUnhideOldPass] = useState(true);
    const [unhideNewPass, setUnhideNewPass] = useState(true);
    const [unhideConfirmPass, setUnhideConfirmPass] = useState(true);
    const [disableConfirm, setDisableConfirm] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setNewPass('');
        setConfirmPass('');
    };

    const onConfirmClick = () => {
        onConfirm({password: newPass});
    };

    const setOldPassField = text => setOldPass(text);
    const setNewPassField = text => setNewPass(text);
    const setConfirmPassField = text => setConfirmPass(text);

    useEffect(() => {
        if (newPass && newPass.length > 1 && confirmPass === newPass) {
            setDisableConfirm(false);
        } else {
            setDisableConfirm(true);
        }
    }, [newPass, confirmPass]);

    useEffect(() => {
        getPassword().then(password => setOldPass(password));
        if (open === false) {
            setNewPass('');
            setConfirmPass('');
        }
    }, [open]);

    return (
        <Modal
            isVisible={open}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}
            avoidKeyboard={true}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(CHANGE_PASS)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>
                <View style={global.modalContent}>
                    <UITextInput
                        label={t(OLD_PASSWORD)}
                        value={oldPass}
                        onChangeText={setOldPassField}
                        secureTextEntry={unhideOldPass}
                        iconLeft="lock-closed-outline"
                        iconRight={unhideOldPass ? 'eye-outline' : 'eye-off-outline'}
                        onRightIconPress={_ => setUnhideOldPass(!unhideOldPass)}
                    />
                    <UITextInput
                        label={t(NEW_PASSWORD)}
                        value={newPass}
                        onChangeText={setNewPassField}
                        secureTextEntry={unhideNewPass}
                        iconLeft="lock-closed-outline"
                        iconRight={unhideNewPass ? 'eye-outline' : 'eye-off-outline'}
                        onRightIconPress={_ => setUnhideNewPass(!unhideNewPass)}
                    />
                    <UITextInput
                        label={t(CONFIRM_PASSWORD)}
                        value={confirmPass}
                        onChangeText={setConfirmPassField}
                        secureTextEntry={unhideConfirmPass}
                        iconLeft="lock-closed-outline"
                        iconRight={unhideConfirmPass ? 'eye-outline' : 'eye-off-outline'}
                        onRightIconPress={_ => setUnhideConfirmPass(!unhideConfirmPass)}
                    />
                </View>
                <View style={global.modalFooter}>
                    <Button
                        onPress={onConfirmClick}
                        disabled={disableConfirm}
                        title={t(CONFIRM)}
                        containerStyle={global.ModalButton.Positive.containerStyle}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                        disabledStyle={global.ModalButton.disabledStyle}
                        disabledTitleStyle={global.ModalButton.disabledTitleStyle}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default ChangePasswordDialog;
