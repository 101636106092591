import React from 'react';
import {Platform} from 'react-native';
import {SpeedDial} from 'react-native-elements';
import {
    ADD_GUEST,
    EXISTING_GUEST,
    IMPORT_DOCUMENT,
    MANUAL_GUEST,
    SCAN_DOCUMENT,
} from '../../constants/stringsAndFields';
import {
    onAddNewGuestClickAction,
    openCameraAction,
    openGalleryImportAction,
    onAddExistingGuestClickAction,
} from './FabButtonActions';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const IS_WEB = Platform.OS === 'web';

const FabButton = ({t, theme, global, isMobile, useSharedDialogs, navigation, checkin}) => {
    const {isOpenFab, openFab, closeFab} = useSharedDialogs();

    const openCamera = _ => openCameraAction(navigation, closeFab, checkin);
    const openGalleryImport = _ => openGalleryImportAction(navigation, closeFab, checkin);
    const onAddNewGuestClick = _ => onAddNewGuestClickAction(navigation, closeFab, checkin);
    const onAddExistingGuestClick = _ => onAddExistingGuestClickAction(navigation, closeFab, checkin);

    const insets = useSafeAreaInsets();

    return (
        <SpeedDial
            title={t(ADD_GUEST)}
            isOpen={isOpenFab}
            icon={{
                name: 'add',
                color: '#fff',
                size: 22,
                containerStyle: {margin: 0},
            }}
            openIcon={{name: 'close', color: '#fff', size: 22, containerStyle: {margin: 0}}}
            onOpen={openFab}
            onClose={closeFab}
            containerStyle={[
                global.boxShadow,
                {
                    bottom: 0,
                    top: 0,
                    zIndex: 1,
                    marginRight: isMobile ? -6 : 0,
                    marginBottom: insets.bottom - 6,
                },
            ]}
            buttonStyle={global.FAB.buttonStyle}
            titleStyle={global.FAB.titleStyle}>
            {!IS_WEB && (
                <SpeedDial.Action
                    icon={{iconPosition: 'right', name: 'camera-outline', type: 'ionicon', color: '#fff'}}
                    iconContainerStyle={{padding: 0, justifyContent: 'center'}}
                    title={t(SCAN_DOCUMENT)}
                    titleStyle={[global.textMain, {overflow: 'hidden'}]}
                    onPress={openCamera}
                    buttonStyle={{
                        backgroundColor: theme.colors.primary,
                        height: 40,
                        width: 40,
                        minWidth: 40,
                        paddingRight: 0,
                        paddingLeft: 0,
                        paddingVertical: 0,
                    }}
                />
            )}
            {!IS_WEB && (
                <SpeedDial.Action
                    icon={{iconPosition: 'right', name: 'image-outline', type: 'ionicon', color: '#fff'}}
                    iconContainerStyle={{padding: 0, justifyContent: 'center'}}
                    title={t(IMPORT_DOCUMENT)}
                    titleStyle={[global.textMain, {overflow: 'hidden'}]}
                    onPress={openGalleryImport}
                    buttonStyle={{
                        backgroundColor: theme.colors.primary,
                        height: 40,
                        width: 40,
                        minWidth: 40,
                        paddingRight: 0,
                        paddingLeft: 0,
                        paddingVertical: 0,
                    }}
                />
            )}
            <SpeedDial.Action
                icon={{name: 'search-outline', type: 'ionicon', color: '#fff'}}
                iconContainerStyle={{padding: 0, justifyContent: 'center'}}
                title={t(EXISTING_GUEST)}
                titleStyle={[global.textMain, {overflow: 'hidden'}]}
                onPress={onAddExistingGuestClick}
                buttonStyle={{
                    backgroundColor: theme.colors.primary,
                    height: 40,
                    width: 40,
                    minWidth: 40,
                    paddingRight: 0,
                    paddingLeft: 0,
                    paddingVertical: 0,
                }}
            />
            <SpeedDial.Action
                icon={{name: 'edit', color: '#fff'}}
                iconContainerStyle={{padding: 0, justifyContent: 'center'}}
                title={t(MANUAL_GUEST)}
                titleStyle={[global.textMain, {overflow: 'hidden'}]}
                onPress={onAddNewGuestClick}
                buttonStyle={{
                    backgroundColor: theme.colors.primary,
                    height: 40,
                    width: 40,
                    minWidth: 40,
                    paddingRight: 0,
                    paddingLeft: 0,
                    paddingVertical: 0,
                }}
            />
        </SpeedDial>
    );
};

export default FabButton;
