import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    accordionContent: {
        height: 0,
    },

    accordionHeader: {
        backgroundColor: theme.colors.primaryExtraLight,
        paddingHorizontal: 15,
        paddingVertical: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    accordionHeaderText: {
        color: theme.colors.primary,
        fontSize: 14,
        fontFamily: theme.fontFamilyMedium,
    },
}));
export default useStyles;
