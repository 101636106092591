import React from 'react';
import {useTheme} from 'react-native-elements';
import {Provider as PaperProvider, MD3LightTheme} from 'react-native-paper';

// https://callstack.github.io/react-native-paper/theming.html

const WithProviders = ({component}) => {
    const {theme} = useTheme();
    const mainTheme = {
        ...MD3LightTheme,
        colors: {
            ...MD3LightTheme.colors,
            primary: theme.colors.primary,
            text: theme.colors.textColor,
            surface: theme.colors.primaryExtraLight,
            onSurface: theme.colors.textColor,
            primaryContainer: 'rgb(233, 230, 249)',
            surfaceVariant: 'rgb(233, 230, 249)',
        },
    };

    return <PaperProvider theme={mainTheme}>{component}</PaperProvider>;
};

export default WithProviders;
