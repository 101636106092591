import React from 'react';
import {useTranslation} from 'react-i18next';
import {Text, Button, Icon, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {EXPORT_ACCOUNT_INFO, EXPORT_ACCOUNT_INFO_TEXT, OK} from '../../constants/stringsAndFields';
import Modal from 'react-native-modal';
import {View, Pressable} from 'react-native';

const ExportDialog = ({setVisible, visible}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();

    const toggleOverlay = () => {
        setVisible(!visible);
    };

    const doExport = () => {
        console.log('TODO: implement export of data');
    };

    return (
        <Modal
            isVisible={visible}
            onBackdropPress={toggleOverlay}
            onBackButtonPress={toggleOverlay}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(EXPORT_ACCOUNT_INFO)}</Text>
                    <Pressable onPress={toggleOverlay} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <View style={global.modalContent}>
                    <Text style={global.modalContentText}> {t(EXPORT_ACCOUNT_INFO_TEXT)} </Text>
                </View>
                <View style={global.modalFooter}>
                    <Button
                        title={t(OK)}
                        onPress={doExport}
                        containerStyle={global.ModalButton.Positive.containerStyle}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default ExportDialog;
