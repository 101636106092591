import React, {FC, useEffect, useState} from 'react';
import {
    View,
    Animated,
    PanResponder,
    GestureResponderEvent,
    PanResponderGestureState,
    StyleProp,
    ViewStyle,
} from 'react-native';
import {
    CIRCLE_HEIGHT,
    DEFAULT_BORDER_RRADIUS,
    DEFAULT_COMPLETE_THRESHOLD_PERCENTAGE,
    DEFAULT_GO_BACK_TO_START,
    DEFAULT_HEIGHT,
    DEFAULT_WIDTH,
} from './constants';
import {useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import useStyles from './SwipeButtonStyles';

import {SwipeButtonCircle, SwipeButtonCircleProps} from './SwipeButtonCircle';
import SwipeButtonText, {SwipeButtonTextProps} from './SwipeButtonText';

type SwipeButtonPropsExtends = SwipeButtonCommonProps &
    Omit<SwipeButtonCircleProps, 'opacity' | 'panHandlers' | 'translateX'> &
    SwipeButtonTextProps;

interface SwipeButtonProps extends SwipeButtonPropsExtends {
    /**
     * Callback that will be invoked when complete threshold has been reached
     */
    onComplete: () => void;

    /**
     * The with of the button
     *
     * @default 90% of the screen width
     */
    width?: number;

    /**
     * If disabled is set to true it will not be possible to interace with the button
     */
    disabled?: boolean;

    /**
     * Indicates when `onComplete` should be invoked.
     *
     * @default 70
     */
    completeThresholdPercentage?: number;

    /**
     * Callback that will be invoked when the suer starts swiping
     */
    onSwipeStart?: () => void;

    /**
     * Callback that will be invoked when the suer ends swiping
     */
    onSwipeEnd?: () => void;

    /**
     * The styling of the underlay container
     */
    underlayStyle?: StyleProp<ViewStyle>;

    /**
     * Styling for the outer container
     */
    containerStyle?: StyleProp<ViewStyle>;

    /**
     * It resets swipe to initial position
     */
    isSwipeReset?: boolean;

    /**
     * Callback that will be invoked when the swipe button state changes
     */
    onToggle?: (toggleValue: boolean) => void;

    /**
     * Callback to set state when button is restarted
     */
    resetSwipeButton?: (toggleValue: boolean) => void;

    /**
     * In future could be used to implement left/right swipe for checkin/checkout
     */
    isCheckin?: boolean;
}

export type SwipeButtonCommonProps = {
    /**
     * The height of the button
     * @default 70
     */
    height?: number;

    /**
     * The height of the circle
     * @default 40
     */
    circleHeight?: number;

    /**
     * The border radius of the container and the Icon
     *
     * @default (height / 2)
     */
    borderRadius?: number;

    /**
     * If true, the circle will scroll back to the start position after swipe is completed
     *
     * @default false
     */
    goBackToStart?: boolean;
};

export const SwipeButton: FC<SwipeButtonProps> = ({
    height = DEFAULT_HEIGHT,
    circleHeight = CIRCLE_HEIGHT,
    width = DEFAULT_WIDTH,
    borderRadius = DEFAULT_BORDER_RRADIUS,
    title,
    titleContainerProps,
    titleProps,
    titleContainerStyle,
    titleStyle,
    completeThresholdPercentage = DEFAULT_COMPLETE_THRESHOLD_PERCENTAGE,
    underlayStyle,
    disabled,
    Icon,
    containerStyle,
    circleBackgroundColor,
    goBackToStart = DEFAULT_GO_BACK_TO_START,
    onComplete = () => {},
    onSwipeEnd = () => {},
    onSwipeStart = () => {},
    isSwipeReset = false,
    onToggle = () => {},
    resetSwipeButton = () => {},
    isCheckin = true,
}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const [endReached, setEndReached] = useState<boolean>(false);
    const opacity = disabled ? 0.5 : 1;
    const opacityStyle = {opacity};
    const [translateX] = useState<Animated.Value & {_value?: number}>(new Animated.Value(0));
    const scrollDistance = width - completeThresholdPercentage / 100 - height;
    const completeThreshold = scrollDistance * (completeThresholdPercentage / 100);

    const animateToStart = () => {
        Animated.spring(translateX, {toValue: 0, tension: 10, friction: 5, useNativeDriver: false}).start();
        return setEndReached(false);
    };

    const animateToEnd = () => {
        //onComplete();
        Animated.spring(translateX, {
            toValue: scrollDistance,
            tension: 10,
            friction: 5,
            useNativeDriver: false,
        }).start();
        if (goBackToStart) {
            setEndReached(true);
            return animateToStart();
        }
        return setEndReached(true);
    };

    const onMove = (_: GestureResponderEvent, gestureState: PanResponderGestureState) => {
        if (disabled) {
            return false;
        }

        if (gestureState.dx < 0 || gestureState.dx > scrollDistance) {
            return Animated.event([{dx: translateX}], {useNativeDriver: false})({
                ...gestureState,
                dx: gestureState.dx < 0 ? 0 : scrollDistance,
            });
        }

        return Animated.event([{dx: translateX}], {useNativeDriver: false})(gestureState);
    };

    const onRelease = () => {
        if (disabled) {
            return;
        }

        if (endReached) {
            return animateToStart();
        }

        const isCompleted = translateX._value! >= completeThreshold;

        return isCompleted ? animateToEnd() : animateToStart();
    };

    const panResponser = () =>
        PanResponder.create({
            onPanResponderGrant: onSwipeStart,
            onPanResponderEnd: onSwipeEnd,
            onStartShouldSetPanResponder: () => false,
            onMoveShouldSetPanResponder: () => true,
            onMoveShouldSetPanResponderCapture: () => false,
            onPanResponderMove: onMove,
            onPanResponderRelease: onRelease,
        });

    useEffect(() => {
        if (isSwipeReset) {
            animateToStart();
            onToggle(false);
            resetSwipeButton(false);
        }
    }, [isSwipeReset]);

    useEffect(() => {
        onToggle(endReached);
    }, [endReached]);

    return (
        <View style={[styles.container, opacityStyle, containerStyle, {height, width, borderRadius}]}>
            <View style={[styles.containerInnerStyle]}>
                <SwipeButtonText
                    title={title}
                    titleStyle={titleStyle}
                    titleProps={titleProps}
                    height={height}
                    titleContainerProps={titleContainerProps}
                    titleContainerStyle={titleContainerStyle}
                />
                {!disabled && (
                    <Animated.View
                        testID="Underlay"
                        style={[
                            styles.underlayContainer,
                            underlayStyle,
                            {
                                width: translateX.interpolate({
                                    inputRange: [0, 100],
                                    outputRange: [circleHeight, 100 + circleHeight],
                                }),
                                height: circleHeight,
                            },
                        ]}
                    />
                )}
                <SwipeButtonCircle
                    circleBackgroundColor={circleBackgroundColor}
                    Icon={Icon}
                    opacity={opacity}
                    panHandlers={panResponser().panHandlers}
                    translateX={translateX}
                    borderRadius={borderRadius}
                    height={circleHeight}
                />
            </View>
        </View>
    );
};
