import AsyncStorage from '@react-native-async-storage/async-storage';
import {useIsFocused} from '@react-navigation/native';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {SafeAreaView} from 'react-native';
import {LinearProgress, useTheme} from 'react-native-elements';
import {REST_COMMUNICATION, fetchFacilitiesFlatRates} from '../api/inCheckinRestService';
import MessageDialog from '../components/MessageDialog';
import EVChooserHeader from '../components/checkins/EVChooserHeader';
import AddEvisitorDialog from '../components/evaccounts/AddEvisitorDialog';
import FacilitiesList from '../components/finances/FacilitiesList';
import {useSharedDialogs} from '../components/finances/useDialogs';
import EVChooserDialog from '../components/tourists/EVChooserDialog';
import {ACCOMODATION_OBJECT, PIN, PIN_ID, STORAGE_CHOSEN_EV_ACCOUNT} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import globalStyle from '../theme/globalStyle';
import {findEvisitorByPin, isEmpty, isNotEmpty} from '../utils/arrayHelpers';
import {isEVAccountFetchedFacility, saveEVAccountFetchedFacilityFlag} from '../utils/userUtils';

const FinancesScreen = ({route, navigation}) => {
    const {clearErrors, evaccounts, refetchEvisitor, associateEvisitor, apiMessages, apiErrors} =
        useContext(DataContext);
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const isFocused = useIsFocused();
    const [evAccount, setEvAccount] = useState(null);
    const [progressOngoing, setProgressOngoing] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [openAddEvisitor, setOpenAddEvisitor] = useState(false);
    const [paymentFacilities, setPaymentFacilities] = useState([]);
    const [evAccountError, setEvAccountError] = useState(false);

    const loadPaymentFacilities = async evAccount => {
        setProgressOngoing(true);
        const faclitiesFlatRates = await fetchFacilitiesFlatRates(evAccount[PIN_ID]);
        if (isNotEmpty(faclitiesFlatRates)) {
            setPaymentFacilities(faclitiesFlatRates);
        } else {
            setPaymentFacilities([]);
        }
        setProgressOngoing(false);
    };

    const onRefresh = useCallback(async () => {
        await loadPaymentFacilities(evAccount);
    }, [evAccount]);

    const onConnectEvisitorAccount = async eVisitorCredentials => {
        setProgressOngoing(true);
        setOpenAddEvisitor(false);
        try {
            const initializedEvAccount = await associateEvisitor(eVisitorCredentials);
            if (initializedEvAccount) {
                await setEvAccountCallback(initializedEvAccount);
            }
        } catch (e) {
            console.log(e);
            setEvAccountError(true);
            setOpenAddEvisitor(true);
        } finally {
            setProgressOngoing(false);
        }
    };

    const redrawHeaderComponents = useCallback(() => {
        navigation.setOptions({
            headerRight: () => (
                <EVChooserHeader
                    evAccount={evAccount}
                    useSharedDialogs={useSharedDialogs}
                    onRefresh={onRefresh}
                    global={global}
                    theme={theme}
                />
            ),
        });
    }, [evAccount, global, theme]);

    const setupEvAccount = async () => {
        const storedEvAccPin = await AsyncStorage.getItem(STORAGE_CHOSEN_EV_ACCOUNT);
        let evAccountData = null;
        if (isNotEmpty(evaccounts)) {
            const firstEvAccount = evaccounts[0];
            if (storedEvAccPin) {
                evAccountData = findEvisitorByPin(evaccounts, storedEvAccPin);
                if (evAccountData) {
                    await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, evAccountData[PIN]);
                    // setEvAccount only if necessary
                    if (!evAccount || evAccount[PIN] !== evAccountData[PIN]) {
                        setEvAccount(evAccountData);
                    }
                }
            } else {
                if (firstEvAccount) {
                    await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, firstEvAccount[PIN]);
                    setEvAccount(firstEvAccount);
                    evAccountData = firstEvAccount;
                }
            }
            if (evAccountData) {
                await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, evAccountData[PIN]);
            } else if (firstEvAccount) {
                await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, firstEvAccount[PIN]);
            }
        } else {
            setEvAccount(null);
            redrawHeaderComponents();
        }
        return evAccountData;
    };

    const checkAndFetchFacilities = async activeEvAccount => {
        const facilities = activeEvAccount ? activeEvAccount?.[ACCOMODATION_OBJECT] : [];
        if (isEmpty(facilities)) {
            const isAlreadyFetched = await isEVAccountFetchedFacility(activeEvAccount[PIN]);
            if (!isAlreadyFetched) {
                await refetchEvisitor(activeEvAccount);
                await saveEVAccountFetchedFacilityFlag(activeEvAccount[PIN]);
            }
        }
    };

    const updateHeader = async acc => {
        await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, acc[PIN]);
        redrawHeaderComponents();
    };

    const initEvAccountData = async acc => {
        try {
            const checkinsRefreshed = await checkAndFetchFacilities(acc);
            return checkinsRefreshed;
        } catch (e) {
            console.log(e);
        }
        return null;
    };

    const generateMessage = useCallback(() => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`;
        }
        if (apiMessages) {
            if (apiMessages?.signal === REST_COMMUNICATION) {
                return `${apiMessages?.message}`;
            }
            return `${apiMessages?.signal}: ${apiMessages?.message}`;
        }
        return null;
    }, [apiErrors, apiMessages]);

    useEffect(() => {
        redrawHeaderComponents();
    }, []);

    useEffect(() => {
        if (isFocused && evaccounts) {
            clearErrors();
            setupEvAccount()
                .catch(console.error)
                .then(evAccountData => {
                    loadPaymentFacilities(evAccountData).catch(console.error);
                });
        }
    }, [isFocused, evaccounts]);

    useEffect(() => {
        if (evaccounts && evAccount) {
            updateHeader(evAccount).catch(console.error);
        }
    }, [evAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    const setEvAccountCallback = async account => {
        if (account) {
            try {
                setEvAccount(account);
                await initEvAccountData(account);
                await loadPaymentFacilities(account);
            } catch (e) {
                console.log(e);
            }
        }
    };

    useEffect(() => {
        (apiErrors || apiMessages) && setOpenMessage(true);
    }, [apiErrors, apiMessages]);

    return (
        <SafeAreaView style={global.containerBg}>
            {progressOngoing && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
            <FacilitiesList facilities={paymentFacilities} evAccount={evAccount} />
            <AddEvisitorDialog
                open={openAddEvisitor}
                setOpen={setOpenAddEvisitor}
                onConnect={onConnectEvisitorAccount}
                evAccountError={evAccountError}
                setEvAccountError={setEvAccountError}
            />
            {isFocused && (
                <EVChooserDialog
                    evaccounts={evaccounts}
                    setEvAccount={setEvAccountCallback}
                    useSharedDialogs={useSharedDialogs}
                />
            )}
            {isFocused && (
                <MessageDialog
                    message={generateMessage()}
                    isError={apiErrors}
                    open={openMessage}
                    handleOpen={setOpenMessage}
                />
            )}
        </SafeAreaView>
    );
};

export default FinancesScreen;
