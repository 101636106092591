import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    footerActionButtonsWrapper: {
        backgroundColor: theme.colors.white,
        shadowColor: theme.colors.primaryDark,
        shadowOpacity: 0.05,
        shadowOffset: {width: 0, height: -2},
        shadowRadius: 3,
        elevation: 5,
        flexDirection: 'row',
        height: 65,
        paddingHorizontal: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },

    containerStyle: {
        maxWidth: 150,
        height: 45,
    },

    containerStyleMobile: {
        justifyContent: 'center',
        flex: 1,
        marginHorizontal: 5,
    },

    containerStyleDesktop: {
        marginHorizontal: 15,
        width: 150,
    },

    buttonStyle: {
        height: 45,
        minWidth: 80,
        paddingHorizontal: 0,
        paddingVertical: 0,
    },

    buttonStyleMobile: {
        flexDirection: 'column',
    },

    titleStyle: {
        textTransform: 'none',
    },

    titleStyleMobile: {
        fontSize: 11,
        marginTop: 2,
    },
}));

export default useStyles;
