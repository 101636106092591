import {
    ACTIVE,
    ADDITIONAL_FIELDS,
    CAM_IDENTIFIER,
    CAM_TEXT,
    CHECKIN_ID,
    CODE,
    CODE_MI,
    CODE_NAMES,
    COUNTRY_CODE,
    CREATED_AT,
    DATE_OF_ARRIVAL,
    DATE_OF_DEPARTURE,
    DOCUMENT_TYPE_DATA,
    EVISITOR,
    FACILITY_CODE,
    FACILITY_FIELD,
    FIRST_ADDED,
    FORESEEN_STAY_UNTIL_FIELD,
    GUEST_NOTE_FIELD,
    ID,
    INVOICE_CUSTOM_OWNER_FIELD,
    INVOICE_OTHER_OWER,
    LAST_ADDED,
    NAME,
    NAME_CODE,
    NAME_NATIONAL_SHORT,
    PAYMENT_CATEGORY_DATA,
    PIN,
    PIN_ID,
    RECORDS,
    SEQUENCE,
    STAY_FROM_FIELD,
    UPDATED_STATUS,
    USER_DATA_FORM_LEGAL_NAME,
    USER_DATA_FORM_OIB,
    USER_DATA_FORM_PERSONAL_NAME,
    content,
    guests,
    id,
} from '../constants/stringsAndFields';
import {getDateFromDateString} from './dateHelper';
import {checkIfGuestIsNotEmpty} from './guestUtils';
import {isStringNotEmpty} from './helpers';

export const isNotEmpty = list => list && list.length > 0;

export const isEmpty = list => list && list.length === 0;

export const findCheckin = (checkins, checkinId) =>
    checkins ? checkins.find(checkin => checkin[id] === checkinId) : null;

export const filterGuests = (guests, checkinId) =>
    guests.filter(guest => guest?.additional_info?.checkin_id === checkinId);

export const getNumberOfGuests = checkin => checkin?.[guests]?.length;

export const findGuest = (guests, id) => (guests ? guests.find(guest => guest[ID] === id) : false);

export const findEvisitorByPinId = (evisitors, pinId) =>
    evisitors ? evisitors.find(evisitor => evisitor[PIN_ID] === pinId) : false;

export const findEvisitorById = (evisitors, eVisitorId) =>
    evisitors ? evisitors.find(evisitor => evisitor[ID] === eVisitorId) : false;

export const findEvisitorByPin = (evisitors, pin) =>
    evisitors ? evisitors.find(evisitor => evisitor[PIN] === pin) : false;

export const filterByPin = (checkins, evAccount) =>
    evAccount ? checkins.filter(checkin => checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN] === evAccount?.[PIN]) : [];

export const findById = (data, dataId) => (data ? data.find(item => item[ID] === dataId) : false);

export const findByFacilityCode = (data, facilityCode) =>
    data ? data.find(item => item[CODE] === facilityCode) : false;

export const filterByProperty = (evaccounts, filterProperty, search) =>
    evaccounts.filter(item => item[filterProperty]?.toLowerCase().includes(search.toLowerCase())) ?? [];

export const filterByFacilityCode = (checkins, selectedFacility) =>
    selectedFacility
        ? checkins.filter(
              c => c?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[FACILITY_CODE] === selectedFacility?.[FACILITY_CODE]
          )
        : [];

export const filterByFacilityCodeAndNameCode = (checkins, selectedFacility) =>
    selectedFacility
        ? checkins.filter(
              c =>
                  c?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[FACILITY_CODE] === selectedFacility?.[FACILITY_CODE] &&
                  c?.[ADDITIONAL_FIELDS]?.[FACILITY_FIELD]?.[NAME_CODE] === selectedFacility?.[NAME_CODE]
          )
        : [];

export const findGuestInCheckin = (checkin, guestId) => {
    let guest = checkin?.[guests]?.find(guest => guest[id] === guestId);
    return guest ? guest : false;
};

export const mapCountryCodeToCountry = (code, countries) =>
    countries?.find(country => country[COUNTRY_CODE] === code) ?? {};

export const mapTypeCodeToObject = (code, docs) => docs?.find(doc => doc[CODE] === code) ?? {};

export const mapTypeCodeMIToObject = (code, docs) => docs?.find(doc => doc[CODE_MI] === code) ?? {};

export const mapNameToObject = (name, docs) => docs?.find(doc => doc[NAME] === name) ?? {};

export const parseStaticData = eVisitorStaticData => {
    const staticData = {};
    try {
        if (eVisitorStaticData) {
            for (const data of eVisitorStaticData) {
                const DATA_KEY = Object.keys(data)[0];
                if (DATA_KEY) {
                    staticData[DATA_KEY] = data[DATA_KEY];
                }
            }
        }
        staticData[DOCUMENT_TYPE_DATA] = staticData[DOCUMENT_TYPE_DATA].filter(
            documentType => documentType[ACTIVE] === true
        );
        staticData[DOCUMENT_TYPE_DATA].sort((a, b) => (a[SEQUENCE] > b[SEQUENCE] ? 1 : -1));
        staticData[PAYMENT_CATEGORY_DATA] = staticData[PAYMENT_CATEGORY_DATA].filter(
            documentType => documentType[ACTIVE] === true
        );
    } catch (e) {
        console.log(e);
    }
    return staticData;
};

export const parseFacilityRecords = data => data?.[RECORDS];

export const sortDateTimes = (a, b) => (a[CREATED_AT] < b[CREATED_AT] ? -1 : a[CREATED_AT] > b[CREATED_AT] ? 1 : 0);

export const sortByDateTimes = (a, b) => (a[CREATED_AT] < b[CREATED_AT] ? -1 : a[CREATED_AT] > b[CREATED_AT] ? 1 : 0);

export const sortByStayFromField = (d1, d2) => {
    const dateD1 = getDateFromDateString(d1?.[STAY_FROM_FIELD] ?? d1?.[content]?.[STAY_FROM_FIELD]);
    const dateD2 = getDateFromDateString(d2?.[STAY_FROM_FIELD] ?? d2?.[content]?.[STAY_FROM_FIELD]);
    return Number(dateD1) - Number(dateD2);
};

export const sortByStayUntilField = (d1, d2) => {
    const dateD1 = getDateFromDateString(d1?.[FORESEEN_STAY_UNTIL_FIELD] ?? d1?.[content]?.[FORESEEN_STAY_UNTIL_FIELD]);
    const dateD2 = getDateFromDateString(d2?.[FORESEEN_STAY_UNTIL_FIELD] ?? d2?.[content]?.[FORESEEN_STAY_UNTIL_FIELD]);
    return Number(dateD1) - Number(dateD2);
};

export const checkinsOrGuestsSorter = (checkinsOrGuests, sortBy) => {
    switch (sortBy) {
        case LAST_ADDED:
            return checkinsOrGuests?.sort(sortDateTimes)?.reverse();
        case FIRST_ADDED:
            return checkinsOrGuests?.sort(sortDateTimes);
        case DATE_OF_ARRIVAL:
            return checkinsOrGuests?.sort(sortByStayFromField)?.reverse();
        case DATE_OF_DEPARTURE:
            return checkinsOrGuests?.sort(sortByStayUntilField)?.reverse();
        default:
            return checkinsOrGuests;
    }
};

export const filterByPinAndSort = (checkins, evAccount, sortBy) =>
    checkinsOrGuestsSorter(filterByPin(checkins, evAccount), sortBy);

export const range = (start, end) =>
    Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);

export const splitIntoChunks = (array, chunkSize = 1000) => {
    const resultArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        resultArray.push(chunk);
    }
    return resultArray;
};

export const getMrzItemByIdentiferValue = (data, value, isNative) => {
    if (isNative) {
        const foundValue = data?.[value];
        return foundValue ? foundValue.trim() : null;
    } else {
        const item = data.find(item => item?.[CAM_IDENTIFIER] === value);
        return item ? item?.[CAM_TEXT] : null;
    }
};

const countryPrios = ['HRV', 'DEU', 'AUT', 'ITA', 'POL', 'CZE', 'SVN', 'SVK', 'HUN', 'NLD'];
const countryMap = new Map();
countryPrios.forEach((x, i) => countryMap.set(x, i));
const sortAlphabet = countries => {
    const first = countries.slice(0, countryPrios.length);
    const second = countries.slice(countryPrios.length);
    const sortedSecondAlph = second.sort((a, b) => {
        return a[NAME_NATIONAL_SHORT].localeCompare(b[NAME_NATIONAL_SHORT]);
    });
    return [...first, ...sortedSecondAlph];
};
export const sortCountries = countries => sortAlphabet(sortByPriorities(COUNTRY_CODE, countryMap, countries));

const citiesPrios = [
    'Zagreb',
    'Osijek',
    'Velika Gorica',
    'Slavonski Brod',
    'Karlovac',
    'Varaždin',
    'Split',
    'Rijeka',
    'Zadar',
];
const citiesMap = new Map();
citiesPrios.forEach((x, i) => citiesMap.set(x, i));
export const sortCities = cities => sortByPriorities(NAME, citiesMap, cities);

const docPrios = ['027', '051', '002', '003', '004', '005', '028', '052', '035', '033', '103', '032'];
const docMap = new Map();
docPrios.forEach((x, i) => docMap.set(x, i));
export const sortDocTypes = docTypes => sortByPriorities(CODE, docMap, docTypes);

const paymentCatPrios = ['14', '1', '2', '16'];
const paymentCatMap = new Map();
paymentCatPrios.forEach((x, i) => paymentCatMap.set(x, i));
export const sortPaymentCategories = paymentCategories => sortByPriorities(CODE, paymentCatMap, paymentCategories);

const sortByPriorities = (sortKey, prioMap, data) => {
    if (data) {
        data.sort((a, b) => {
            // > 0	    sort b before a
            // < 0	    sort a before b
            // === 0	keep original order of a and b
            const mapHasA = prioMap.has(a[sortKey]);
            const mapHasB = prioMap.has(b[sortKey]);

            // leave original order
            if (!mapHasA && !mapHasB) return 0;

            // b before a: 1
            if (!mapHasA && mapHasB) return 1;

            // a before b: -1
            if (mapHasA && !mapHasB) return -1;

            return prioMap.get(a[sortKey]) - prioMap.get(b[sortKey]);
        });
        return data;
    }
    return null;
};

export const mergeGuestData = (localData, remoteData) => {
    const mergedGuests = [];
    const updatedIds = new Map();
    for (let localGuest of localData) {
        const isGuestEdited = localGuest?.[UPDATED_STATUS];
        const guestNote = localGuest?.[content]?.[ADDITIONAL_FIELDS]?.[GUEST_NOTE_FIELD];
        const guestId = localGuest?.[id];
        const remoteGuest = remoteData.find(el => el?.[id] === guestId);
        if (remoteGuest) {
            remoteGuest[content][CHECKIN_ID] = localGuest[CHECKIN_ID];
            remoteGuest[CHECKIN_ID] = localGuest[CHECKIN_ID];
        }
        if (remoteGuest && guestNote) {
            remoteGuest[content][ADDITIONAL_FIELDS][GUEST_NOTE_FIELD] = guestNote;
        }

        if (!remoteGuest || isGuestEdited) {
            if (checkIfGuestIsNotEmpty(localGuest)) {
                mergedGuests.push(localGuest);
                if (isGuestEdited) {
                    updatedIds.set(localGuest[id], true);
                }
            }
        }
    }

    for (let remoteGuest of remoteData) {
        if (!updatedIds.has(remoteGuest[id])) {
            mergedGuests.push(remoteGuest);
        }
    }
    return [...mergedGuests];
};

export const extendPaymentCategories = paymentCategories => {
    return paymentCategories.map(item => {
        return {...item, [NAME]: item[CODE_NAMES]};
    });
};

export const extendEvisitorAccounts = (evisitors, customOwner, useUserDataForInvoice, t) => {
    const extendedEvisitors = [];
    if (evisitors) {
        extendedEvisitors.push(...evisitors);
        for (const extendedEvisitor of extendedEvisitors) {
            extendedEvisitor[INVOICE_CUSTOM_OWNER_FIELD] = false;
        }
        if (useUserDataForInvoice && customOwner) {
            const personalName = customOwner[USER_DATA_FORM_PERSONAL_NAME];
            const legalName = customOwner[USER_DATA_FORM_LEGAL_NAME];
            let name = isStringNotEmpty(personalName) ? personalName : null;
            name = !name && isStringNotEmpty(legalName) ? legalName : '';
            if (customOwner[USER_DATA_FORM_OIB]) {
                const customEVisitor = {
                    [PIN]: customOwner[USER_DATA_FORM_OIB],
                    [ID]: customOwner[USER_DATA_FORM_OIB],
                    [NAME]: name,
                    [INVOICE_CUSTOM_OWNER_FIELD]: true,
                };
                extendedEvisitors.push(customEVisitor);
            }
        }
        extendedEvisitors.push({
            [PIN]: '-1',
            [ID]: '-1',
            [NAME]: t(INVOICE_OTHER_OWER),
            [INVOICE_CUSTOM_OWNER_FIELD]: false,
        });
    }
    return extendedEvisitors;
};

export const isFakeEvAccount = account => {
    if (account?.[INVOICE_CUSTOM_OWNER_FIELD] === true) return true;
    if (account[PIN] === '-1') return true;
    return false;
};

export const removeByValue = (list, val) => {
    for (let i = 0; i < list.length; i++) {
        if (list[i] === val) {
            list.splice(i, 1);
            i--;
        }
    }
    return list;
};
