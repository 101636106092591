import AsyncStorage from '@react-native-async-storage/async-storage';
import {useIsFocused} from '@react-navigation/native';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, SafeAreaView, ScrollView, Text, View, Switch, Pressable} from 'react-native';
import {Icon, Input, ListItem, useTheme} from 'react-native-elements';
import LangSelect from '../components/LangSelect';
import DateTimePicker from '../components/datetime/DateTimePicker';
import HtmlDialog, {PRIVACY_VIEW, TERMS_VIEW} from '../components/htmlviews/HtmlDialog';
import NumberDialog from '../components/pickers/NumberDialog';
import {
    APP_LANGS_SETTINGS,
    BIRTHDAY_NOTIFICATION_TIME_FIELD,
    DEFAULT_CHECKIN_TIME,
    DEFAULT_CHECKOUT_TIME,
    DEFAULT_NUMBER_OF_NIGHTS,
    LANGUAGE_TITLE,
    LEGAL,
    PRIVACY_TITLE,
    SETTINGS_BIRTHDAY_NOTIFICATION_ACTIVE,
    SETTINGS_BIRTHDAY_NOTIFICATION_DESCRIPTION,
    SETTINGS_BIRTHDAY_NOTIFICATION_TIME,
    SETTINGS_BIRTHDAY_NOTIFICATION_TITLE,
    SETTINGS_TITLE_CURRENT_TIME,
    SETTINGS_TITLE_DEFAULT_CHECKIN_TIME,
    SETTINGS_TITLE_DEFAULT_CHECKOUT_TIME,
    SETTINGS_TITLE_DEFAULT_NUMBER_OF_NIGHTS,
    STORAGE_LANGUAGE,
    TERMS_TITLE,
    code,
} from '../constants/stringsAndFields';
import globalStyle from '../theme/globalStyle';
import {getDateFromTime, getDefaultTime, getStringTime} from '../utils/dateHelper';
import {getLanguageCode, getLanguageString, getLanguageStringForSettings} from '../utils/helpers';
import {
    DEFAULT_BIRTHDAY_NOTIF_TIME,
    getBirthdayNotificationTime,
    getCurrentCheckinTime,
    getIsCurrentCheckinTimeEnabled,
    getUserDefaultCheckoutTime,
    getUserDefaultNumberOfNights,
    isBirthdayNotificationsOn,
    setBirthdayNotificationTime,
    setCurrentCheckinTime,
    setIsBirthdayNotificationsOn,
    setIsCurrentCheckinTimeEnabled,
    setUserSettings,
    setupUserLang,
} from '../utils/userUtils';
import useStyles from './SettingsScreen.styles';
import {cancelAllNotifications, getActiveNotifications} from '../utils/notifications';

const IS_WEB = Platform.OS === 'web';

const SettingsScreen = ({navigation}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t, i18n} = useTranslation();
    const isFocused = useIsFocused();
    const [closeDateTimePicker, setCloseDateTimePicker] = useState(true);
    const [datePickerData, setDatePickerData] = useState(null);
    const [defaultCheckinTime, setDefaultCheckinTime] = useState(getDefaultTime());
    const [defaultCheckoutTime, setDefaultCheckoutTime] = useState(getDefaultTime());
    const [defaultNumberOfNights, setDefaultNumberOfNights] = useState('1');
    const [openNumberDialog, setOpenNumberDialog] = useState(false);
    const [openLangSelect, setOpenLangSelect] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [birthdayNotifTime, setBirthdayNotifTime] = useState(DEFAULT_BIRTHDAY_NOTIF_TIME);
    const [isBirthdayNotificationsEnabled, setIsBirthdayNotificationsEnabled] = useState(true);
    const [isCurrentCheckinTimeSwitchEnabled, setIsCurrentCheckinTimeSwitchEnabled] = useState(true);
    const [activeNotifications, setActiveNotifications] = useState('0');
    const activeLang = i18n.language;
    const [lang, setLang] = useState('');

    const langInputRef = useRef(null);
    const defaultCheckinRef = useRef(null);
    const defaultCheckoutRef = useRef(null);
    const defaultNumberOfNightsRef = useRef(null);
    const birthdayNotificationTimeRef = useRef(null);

    const openDateTimePicker = (mode, field, value) => {
        setDatePickerData({
            datePickerMode: mode,
            datePickerDate: getDateFromTime(value),
            currentPickerField: field,
        });
        setCloseDateTimePicker(false);
    };

    const onChangeDate = async (date, field) => {
        //do nothing
    };

    const onChangeTime = async (date, field) => {
        setCloseDateTimePicker(true);
        const time = getStringTime(date);
        if (field === BIRTHDAY_NOTIFICATION_TIME_FIELD) {
            setBirthdayNotifTime(time);
            await setBirthdayNotificationTime(time);
        } else if (field === DEFAULT_CHECKIN_TIME) {
            setDefaultCheckinTime(time);
            await setCurrentCheckinTime(time);
        } else if (field === DEFAULT_CHECKOUT_TIME) {
            setDefaultCheckoutTime(time);
            await setUserSettings(DEFAULT_CHECKOUT_TIME, time);
        }
    };

    const onNumberSelected = async nights => {
        nights = parseInt(nights);
        setDefaultNumberOfNights(String(nights));
        await setUserSettings(DEFAULT_NUMBER_OF_NIGHTS, nights);
    };

    const termsPreview = () => {
        setShowTerms(true);
    };

    const privacyPreview = () => {
        setShowPrivacy(true);
    };

    const openLangSelectModal = _ => {
        setOpenLangSelect(true);
    };

    const onLangSelected = async chosenLang => {
        if (chosenLang) {
            const langCode = chosenLang[code];
            const transKey = 'name_' + activeLang;
            if (transKey && langCode) {
                await AsyncStorage.setItem(STORAGE_LANGUAGE, langCode);
                setLang(getLanguageStringForSettings(langCode));
            }
        }
    };

    const handleBirthdayNotificationsChange = async _ => {
        const updatedOption = !isBirthdayNotificationsEnabled;
        await setIsBirthdayNotificationsOn(updatedOption);
        setIsBirthdayNotificationsEnabled(updatedOption);
        if (updatedOption === false) {
            await cancelAllNotifications();
            const activeNumber = await getActiveNotifications();
            setActiveNotifications(String(activeNumber));
        }
    };

    const handleCurrentCheckinTimeEnabledChange = async _ => {
        const updatedOption = !isCurrentCheckinTimeSwitchEnabled;
        await setIsCurrentCheckinTimeEnabled(updatedOption);
        setIsCurrentCheckinTimeSwitchEnabled(updatedOption);
    };

    useEffect(() => {
        lang && i18n.changeLanguage(getLanguageCode(lang));
    }, [lang]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isFocused) {
            getUserDefaultCheckoutTime().then(time => time && setDefaultCheckoutTime(time));
            getUserDefaultNumberOfNights().then(nights => nights && setDefaultNumberOfNights(String(nights)));
            setupUserLang().then(storedSettingsLang => setLang(getLanguageString(activeLang, storedSettingsLang)));
            isBirthdayNotificationsOn().then(isNotifEnabled => setIsBirthdayNotificationsEnabled(isNotifEnabled));
            getBirthdayNotificationTime().then(time => setBirthdayNotifTime(time));
            getActiveNotifications().then(activeNumber => setActiveNotifications(String(activeNumber)));
            getCurrentCheckinTime().then(settings => setDefaultCheckinTime(settings));
            getIsCurrentCheckinTimeEnabled().then(settings => setIsCurrentCheckinTimeSwitchEnabled(settings));
        }
    }, [isFocused]);

    return (
        <SafeAreaView style={global.containerBg}>
            <ScrollView contentContainerStyle={{flexGrow: 1}}>
                <View style={global.containerInner}>
                    <View style={[global.boxShadow, {marginVertical: 15, borderRadius: 10}]}>
                        <View style={global.card}>
                            <ListItem key={SETTINGS_TITLE_DEFAULT_CHECKIN_TIME} bottomDivider>
                                <View style={{flex: 1}}>
                                    <Text style={[global.textCaps, {marginBottom: 10}]}>
                                        {t(SETTINGS_TITLE_DEFAULT_CHECKIN_TIME)}
                                    </Text>
                                    <Input
                                        rightIcon={
                                            <Pressable
                                                onPress={_ => {
                                                    defaultCheckinRef.current.blur();
                                                    openDateTimePicker(
                                                        'time',
                                                        DEFAULT_CHECKIN_TIME,
                                                        defaultCheckinTime
                                                    );
                                                }}
                                                style={({pressed}) => ({
                                                    opacity: pressed ? 0.5 : 1,
                                                })}>
                                                <Icon name={'keyboard-arrow-down'} size={16} />
                                            </Pressable>
                                        }
                                        rightIconContainerStyle={{right: 20, marginVertical: 0}}
                                        showSoftInputOnFocus={false}
                                        caretHidden={true}
                                        disabled={isCurrentCheckinTimeSwitchEnabled}
                                        selectTextOnFocus={false}
                                        ref={defaultCheckinRef}
                                        value={defaultCheckinTime}
                                        onFocus={_ => {
                                            defaultCheckinRef.current.blur();
                                            openDateTimePicker('time', DEFAULT_CHECKIN_TIME, defaultCheckinTime);
                                        }}
                                        renderErrorMessage={false}
                                        containerStyle={{paddingHorizontal: 0, height: 40}}
                                        inputContainerStyle={{marginBottom: 0}}
                                        style={[
                                            global.link,
                                            IS_WEB && {cursor: 'pointer'},
                                            {width: 60, borderBottomWidth: 0, flex: 0},
                                        ]}
                                    />
                                    <View style={[global.flexCenter, {flex: 1, paddingBottom: 10}]}>
                                        <Text style={[global.textMain, {marginRight: 20, flex: 1}]}>
                                            {t(SETTINGS_TITLE_CURRENT_TIME)}
                                        </Text>
                                        <Switch
                                            value={isCurrentCheckinTimeSwitchEnabled}
                                            onValueChange={handleCurrentCheckinTimeEnabledChange}
                                            trackColor={{
                                                false: theme.colors.greyLight,
                                                true: '#aca7ca',
                                            }}
                                            ios_backgroundColor={theme.colors.greyLight}
                                            thumbColor={
                                                isCurrentCheckinTimeSwitchEnabled
                                                    ? theme.colors.primary
                                                    : theme.colors.borderLight
                                            }
                                            activeThumbColor={theme.colors.primary} // web only
                                        />
                                    </View>
                                </View>
                            </ListItem>

                            <ListItem key={SETTINGS_TITLE_DEFAULT_CHECKOUT_TIME} bottomDivider>
                                <View>
                                    <Text style={[global.textCaps, {marginBottom: 10}]}>
                                        {t(SETTINGS_TITLE_DEFAULT_CHECKOUT_TIME)}
                                    </Text>
                                    <Input
                                        rightIcon={
                                            <Pressable
                                                onPress={_ => {
                                                    defaultCheckoutRef.current.blur();
                                                    openDateTimePicker(
                                                        'time',
                                                        DEFAULT_CHECKOUT_TIME,
                                                        defaultCheckoutTime
                                                    );
                                                }}
                                                style={({pressed}) => ({
                                                    opacity: pressed ? 0.5 : 1,
                                                })}>
                                                <Icon name={'keyboard-arrow-down'} size={16} />
                                            </Pressable>
                                        }
                                        rightIconContainerStyle={{right: 20, marginVertical: 0}}
                                        showSoftInputOnFocus={false}
                                        caretHidden={true}
                                        selectTextOnFocus={false}
                                        ref={defaultCheckoutRef}
                                        value={defaultCheckoutTime}
                                        onFocus={_ => {
                                            defaultCheckoutRef.current.blur();
                                            openDateTimePicker('time', DEFAULT_CHECKOUT_TIME, defaultCheckoutTime);
                                        }}
                                        renderErrorMessage={false}
                                        containerStyle={{paddingHorizontal: 0, height: 40}}
                                        inputContainerStyle={{marginBottom: 0}}
                                        style={[
                                            global.link,
                                            IS_WEB && {cursor: 'pointer'},
                                            {width: 60, borderBottomWidth: 0, flex: 0},
                                        ]}
                                    />
                                </View>
                            </ListItem>

                            <ListItem key={SETTINGS_TITLE_DEFAULT_NUMBER_OF_NIGHTS} bottomDivider>
                                <View>
                                    <Text style={[global.textCaps, {marginBottom: 10}]}>
                                        {t(SETTINGS_TITLE_DEFAULT_NUMBER_OF_NIGHTS)}
                                    </Text>
                                    <View>
                                        <Input
                                            rightIcon={
                                                <Pressable
                                                    onPress={_ => {
                                                        defaultNumberOfNightsRef.current.blur();
                                                        setOpenNumberDialog(true);
                                                    }}
                                                    style={({pressed}) => ({
                                                        opacity: pressed ? 0.5 : 1,
                                                    })}>
                                                    <Icon name={'keyboard-arrow-down'} size={16} />
                                                </Pressable>
                                            }
                                            rightIconContainerStyle={{right: 20, marginVertical: 0}}
                                            showSoftInputOnFocus={false}
                                            caretHidden={true}
                                            selectTextOnFocus={false}
                                            ref={defaultNumberOfNightsRef}
                                            value={defaultNumberOfNights}
                                            onFocus={_ => {
                                                defaultNumberOfNightsRef.current.blur();
                                                setOpenNumberDialog(true);
                                            }}
                                            renderErrorMessage={false}
                                            containerStyle={{paddingHorizontal: 0, height: 40}}
                                            inputContainerStyle={{marginBottom: 0}}
                                            style={[
                                                global.link,
                                                IS_WEB && {cursor: 'pointer'},
                                                {width: 40, borderBottomWidth: 0, flex: 0},
                                            ]}
                                        />
                                    </View>
                                </View>
                            </ListItem>

                            <ListItem key={LANGUAGE_TITLE} bottomDivider>
                                <View>
                                    <Text style={[global.textCaps, {marginBottom: 10}]}>{t(LANGUAGE_TITLE)}</Text>
                                    <View>
                                        <Input
                                            rightIcon={
                                                <Pressable
                                                    onPress={_ => {
                                                        langInputRef.current.blur();
                                                        openLangSelectModal();
                                                    }}
                                                    style={({pressed}) => ({
                                                        opacity: pressed ? 0.5 : 1,
                                                    })}>
                                                    <Icon name={'keyboard-arrow-down'} size={16} />
                                                </Pressable>
                                            }
                                            rightIconContainerStyle={{right: 20, marginVertical: 0}}
                                            showSoftInputOnFocus={false}
                                            caretHidden={true}
                                            selectTextOnFocus={false}
                                            ref={langInputRef}
                                            value={lang}
                                            onFocus={_ => {
                                                langInputRef.current.blur();
                                                openLangSelectModal();
                                            }}
                                            renderErrorMessage={false}
                                            containerStyle={{paddingHorizontal: 0, height: 40}}
                                            style={[
                                                global.link,
                                                IS_WEB && {cursor: 'pointer'},
                                                {width: 80, borderBottomWidth: 0, flex: 0},
                                            ]}
                                        />
                                    </View>
                                </View>
                                <LangSelect
                                    isOpen={openLangSelect}
                                    setOpenLangSelect={setOpenLangSelect}
                                    onLangSelected={onLangSelected}
                                    langList={APP_LANGS_SETTINGS}
                                />
                            </ListItem>

                            {!IS_WEB && (
                                <ListItem key={'USE BIRTHDAY NOTIFICATIONS'} bottomDivider>
                                    <View style={{flex: 1}}>
                                        <View style={[global.flexCenter, {flex: 1, paddingBottom: 10}]}>
                                            <Text style={[global.textCaps, {marginRight: 20, flex: 1}]}>
                                                {t(SETTINGS_BIRTHDAY_NOTIFICATION_TITLE)}
                                            </Text>
                                            <Switch
                                                value={isBirthdayNotificationsEnabled}
                                                onValueChange={handleBirthdayNotificationsChange}
                                                trackColor={{
                                                    false: theme.colors.greyLight,
                                                    true: '#aca7ca',
                                                }}
                                                ios_backgroundColor={theme.colors.greyLight}
                                                thumbColor={
                                                    isBirthdayNotificationsEnabled
                                                        ? theme.colors.primary
                                                        : theme.colors.borderLight
                                                }
                                            />
                                        </View>

                                        <Text style={global.textMain}>
                                            {t(SETTINGS_BIRTHDAY_NOTIFICATION_DESCRIPTION)}
                                        </Text>

                                        <View style={{marginTop: 20}}>
                                            <Text style={[global.textCaps]}>
                                                {t(SETTINGS_BIRTHDAY_NOTIFICATION_TIME)}
                                            </Text>
                                            <Input
                                                rightIcon={
                                                    <Pressable
                                                        onPress={_ => {
                                                            birthdayNotificationTimeRef.current.blur();
                                                            openDateTimePicker(
                                                                'time',
                                                                BIRTHDAY_NOTIFICATION_TIME_FIELD,
                                                                birthdayNotifTime
                                                            );
                                                        }}
                                                        style={({pressed}) => ({
                                                            opacity: pressed ? 0.5 : 1,
                                                        })}>
                                                        <Icon name={'keyboard-arrow-down'} size={16} />
                                                    </Pressable>
                                                }
                                                rightIconContainerStyle={{right: 20, marginVertical: 0}}
                                                showSoftInputOnFocus={false}
                                                caretHidden={true}
                                                selectTextOnFocus={false}
                                                ref={birthdayNotificationTimeRef}
                                                value={birthdayNotifTime}
                                                onFocus={_ => {
                                                    birthdayNotificationTimeRef.current.blur();
                                                    openDateTimePicker(
                                                        'time',
                                                        BIRTHDAY_NOTIFICATION_TIME_FIELD,
                                                        birthdayNotifTime
                                                    );
                                                }}
                                                renderErrorMessage={false}
                                                containerStyle={{paddingHorizontal: 0, height: 40}}
                                                inputContainerStyle={{marginBottom: 0}}
                                                style={[
                                                    global.link,
                                                    IS_WEB && {cursor: 'pointer'},
                                                    {width: 60, borderBottomWidth: 0, flex: 0},
                                                ]}
                                            />
                                        </View>

                                        <Text style={[global.textMain, {marginTop: 5}]}>
                                            {t(SETTINGS_BIRTHDAY_NOTIFICATION_ACTIVE)}:
                                            <Text style={global.link}> {activeNotifications}</Text>
                                        </Text>
                                    </View>
                                </ListItem>
                            )}

                            <Text style={[global.textCaps, {paddingHorizontal: 16, paddingTop: 16}]}>{t(LEGAL)}</Text>

                            <ListItem>
                                <ListItem.Content>
                                    <Text
                                        style={[
                                            global.textMain,
                                            global.link,
                                            IS_WEB && {cursor: 'pointer'},
                                            {marginBottom: 10},
                                        ]}
                                        onPress={termsPreview}>
                                        {t(TERMS_TITLE)}
                                    </Text>
                                    <Text
                                        style={[
                                            global.textMain,
                                            global.link,
                                            IS_WEB && {cursor: 'pointer'},
                                            styles.footerText,
                                        ]}
                                        onPress={privacyPreview}>
                                        {t(PRIVACY_TITLE)}
                                    </Text>
                                </ListItem.Content>
                            </ListItem>
                        </View>
                    </View>
                    <DateTimePicker
                        closed={closeDateTimePicker}
                        setClosed={setCloseDateTimePicker}
                        onChangeDate={onChangeDate}
                        onChangeTime={onChangeTime}
                        datePickerData={datePickerData}
                    />
                    <NumberDialog
                        open={openNumberDialog}
                        setOpen={setOpenNumberDialog}
                        title={t(SETTINGS_TITLE_DEFAULT_NUMBER_OF_NIGHTS)}
                        onNumberSelected={onNumberSelected}
                    />
                    {showTerms && <HtmlDialog isOpen={showTerms} setOpen={setShowTerms} view={TERMS_VIEW} />}
                    {showPrivacy && <HtmlDialog isOpen={showPrivacy} setOpen={setShowPrivacy} view={PRIVACY_VIEW} />}
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export default SettingsScreen;
