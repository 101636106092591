import React from 'react';
import {View, Text, Pressable} from 'react-native';
import {EVISITOR_LABEL, NAME} from '../../constants/stringsAndFields';

const EVChooserHeader = props => {
    const {evAccount, useSharedDialogs, global, theme, isMobile} = props;
    const {openEvisitorDialog} = useSharedDialogs();

    return (
        <View style={[global.flexCenter]}>
            <Pressable onPress={openEvisitorDialog} style={{marginRight: 15}}>
                <Text numberOfLines={1} style={[global.evChooser, global.link, isMobile && {width: 140}]}>
                    {evAccount?.[NAME] ?? EVISITOR_LABEL}
                </Text>
            </Pressable>
        </View>
    );
};

export default EVChooserHeader;
