import {useIsFocused} from '@react-navigation/native';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, Pressable, TouchableOpacity, View, useWindowDimensions} from 'react-native';
import {Button, Icon, Image, Text, useTheme} from 'react-native-elements';
import {
    BANK,
    BANK_ADDRESS,
    BANK_INFO,
    IBAN,
    INVOICE_FORM_FOOTER_TEXT,
    INVOICE_FORM_OPERATOR_NAME,
    INVOICE_FORM_OPERATOR_REF,
    OIB,
    PROFILE_INVOICE_DATA_TITLE,
    PROFILE_INVOICE_FOOTER_TEXT,
    PROFILE_INVOICE_FOOTER_TITLE,
    PROFILE_INVOICE_OPERATOR_NAME,
    PROFILE_INVOICE_OPERATOR_REF,
    PROFILE_INVOICE_OPERATOR_TITLE,
    RESET_LOGO,
    STORAGE_USER_DATA,
    SWIFT,
    UPLOAD_LOGO,
    USER_DATA_ADDRESS,
    USER_DATA_CITY_POST,
    USER_DATA_CONTACT,
    USER_DATA_COUNTRY,
    USER_DATA_EMAIL,
    USER_DATA_FORM_ADDRESS,
    USER_DATA_FORM_BANK,
    USER_DATA_FORM_BANK_ADDRESS,
    USER_DATA_FORM_BANK_IBAN,
    USER_DATA_FORM_BANK_SWIFT,
    USER_DATA_FORM_CITY,
    USER_DATA_FORM_COUNTRY,
    USER_DATA_FORM_EMAIL,
    USER_DATA_FORM_ENTITY_FLAG,
    USER_DATA_FORM_LEGAL_NAME,
    USER_DATA_FORM_OIB,
    USER_DATA_FORM_PERSONAL_NAME,
    USER_DATA_FORM_TEL,
    USER_DATA_INVOICE,
    USER_DATA_INV_FOOTER_DATA,
    USER_DATA_INV_OPERATOR,
    USER_DATA_INV_OPERATOR_ID,
    USER_DATA_LEGAL_NAME,
    USER_DATA_NAME_AND_SURNAME,
    USER_INFO,
    INVOICES_DOWNLOAD_LABEL,
    PROFILE_INVOICE_TEMPLATE_TITLE,
    EVISITOR_LABEL,
    NAME,
} from '../../constants/stringsAndFields';
import DataContext from '../../context/dataContext';
import globalStyle from '../../theme/globalStyle';
import {importLogoGallery} from '../../utils/gallery';
import {updateImg, uploadImage} from '../../utils/images';
import {
    getInvoiceLogo,
    getUserData,
    removeInvoiceLogo,
    saveInvoiceLogo,
    setInvoicesYear,
    setUserSettings,
    updateUserInfoFields,
} from '../../utils/userUtils';
import EditFooterDataDialog from './EditFooterDataDialog';
import EditOperatorDataDialog from './EditOperatorDataDialog';
import EditUserDataDialog from './EditUserDataDialog';
import useStyles from './InvoicesHeader.styles';
import Dropdown from '../ui-components/Dropdown/Dropdown';
import Accordion from '../ui-components/Accordion/Accordion';
import {INVOICE_FIRST_PAGE} from '../../screens/InvoicesScreen';
import {getCurrentYear} from '../../utils/dateHelper';

const IS_WEB = Platform.OS === 'web';
const dropdownYearOptions = [{text: '2022'}, {text: '2023'}, {text: '2024'}];

const InvoicesHeader = ({evAccount, loadInvoicesForEvAccount, exportInvoices, useSharedDialogs}) => {
    const {clearErrors, setApiErrors} = useContext(DataContext);
    const isFocused = useIsFocused();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {t} = useTranslation();

    const [openProgress, setOpenProgress] = useState(false);
    const [userData, setUserData] = useState(null);
    const [openEditUserData, setOpenEditUserData] = useState(false);
    const [openEditOperatorData, setOpenEditOperatorData] = useState(false);
    const [openEditFooterData, setOpenEditFooterData] = useState(false);
    const [isLegalEntity, setIsLegalEntity] = useState(false);
    const [selectedEntityIndex, setSelectedEntityIndex] = useState(0);
    const [logoLoaded, setLogoLoaded] = useState(false);
    const [logoImageData, setLogoImageData] = useState(null);
    const [operatorRef, setOperatorRef] = useState('');
    const [operatorName, setOperatorName] = useState('');
    const [footerText, setFooterText] = useState('');
    const [yearFilter, setYearFilter] = useState(getCurrentYear());

    const {openEvisitorDialog} = useSharedDialogs();

    const inputPictureRef = useRef(null);
    const imgLogoRef = useRef(null);

    const width = useWindowDimensions().width;
    const isMobileSmall = width < 550;
    const isMobileEtraSmall = width < 400;
    const isDesktop = width >= theme.desktopBP;
    useEffect(() => {
        // Person
        if (selectedEntityIndex === 0) {
            setIsLegalEntity(false);
            // Company
        } else if (selectedEntityIndex === 1) {
            setIsLegalEntity(true);
        }
    }, [selectedEntityIndex]);

    useEffect(() => {
        if (userData) {
            setSelectedEntityIndex(parseInt(userData[USER_INFO]?.[USER_DATA_FORM_ENTITY_FLAG] || 0));
        }
    }, [userData]);

    useEffect(() => {
        if (isFocused) {
            clearErrors();
            getUserData().then(data => {
                if (data) {
                    const userInfo = data?.[STORAGE_USER_DATA]?.[USER_INFO];
                    setOperatorRef(userInfo?.[USER_DATA_INV_OPERATOR_ID] ?? '');
                    setOperatorName(userInfo?.[USER_DATA_INV_OPERATOR] ?? '');
                    setFooterText(userInfo?.[USER_DATA_INV_FOOTER_DATA] ?? '');
                    setUserData(data?.[STORAGE_USER_DATA]);
                }
            });
            getInvoiceLogo().then(logoData => {
                if (logoData) {
                    setLogoLoaded(true);
                    if (IS_WEB) {
                        updateImg(imgLogoRef, logoData);
                    } else {
                        setLogoImageData(logoData);
                    }
                } else {
                    setLogoLoaded(false);
                }
            });
        }
    }, [isFocused]);

    const refreshUserData = async () => {
        const data = await getUserData();
        data && setUserData(data?.[STORAGE_USER_DATA]);
    };

    const refreshOperator = async () => {
        getUserData().then(data => {
            const userInfo = data?.[STORAGE_USER_DATA]?.[USER_INFO];
            if (userInfo) {
                setOperatorRef(userInfo?.[USER_DATA_INV_OPERATOR_ID] ?? '');
                setOperatorName(userInfo?.[USER_DATA_INV_OPERATOR] ?? '');
            }
        });
        refreshUserData().catch(console.error);
    };

    const onEditUserDataConfirm = async userInfoData => {
        try {
            setOpenProgress(true);
            await setUserSettings(USER_INFO, userInfoData);
        } catch (e) {
            console.log(e);
            setApiErrors({signal: 'editUserData', message: e?.RESTErrors?.description ?? e?.message});
        } finally {
            setOpenEditUserData(false);
            setOpenEditFooterData(false);
            setOpenProgress(false);
            await refreshUserData();
        }
    };

    const onConfirmOperatorData = async data => {
        if (data) {
            await updateUserInfoFields(
                [USER_DATA_INV_OPERATOR, USER_DATA_INV_OPERATOR_ID],
                [data[INVOICE_FORM_OPERATOR_NAME], data[INVOICE_FORM_OPERATOR_REF]]
            );
        }
        await refreshOperator();
    };

    const refreshFooter = async () => {
        getUserData().then(data => {
            const userInfo = data?.[STORAGE_USER_DATA]?.[USER_INFO];
            if (userInfo) {
                setFooterText(userInfo?.[USER_DATA_INV_FOOTER_DATA] ?? '');
            }
        });
        refreshUserData().catch(console.error);
    };

    const onConfirmFooterData = async data => {
        if (data) {
            await updateUserInfoFields([USER_DATA_INV_FOOTER_DATA], [data[INVOICE_FORM_FOOTER_TEXT]]);
        }
        await refreshFooter();
    };

    const uploadImageHandler = _ => {
        if (IS_WEB) {
            inputPictureRef.current.click();
        }
    };

    const onLogoReset = async () => {
        await removeInvoiceLogo();
        setLogoLoaded(false);
        if (IS_WEB) {
            updateImg(imgLogoRef, null);
        } else {
            setLogoImageData(null);
        }
    };

    const openGallery = async _ => {
        const imgData = await importLogoGallery();
        if (imgData) {
            await saveInvoiceLogo(imgData);
            setLogoImageData(imgData);
            setLogoLoaded(true);
        }
    };

    const htmlContent = (
        <div>
            <img ref={imgLogoRef} hidden src="" width="auto" height="80px" display="block" />
        </div>
    );

    const resetLogo = logoLoaded && (
        <TouchableOpacity onPress={onLogoReset} style={[global.flexCenter, {marginTop: 10}]}>
            <Icon name={'clear'} color={theme.colors.primary} size={18} containerStyle={{marginRight: 3}} />
            <Text style={global.link}>{t(RESET_LOGO)}</Text>
        </TouchableOpacity>
    );

    const uploadComponent = IS_WEB ? (
        <>
            <input
                hidden
                ref={inputPictureRef}
                type="file"
                onChange={e => {
                    uploadImage(e, imgLogoRef);
                    setLogoLoaded(true);
                }}
                accept="image/*"
            />
            <div>{htmlContent}</div>

            {resetLogo}
        </>
    ) : (
        <>
            {logoImageData && (
                <Image
                    source={{
                        uri: `${logoImageData}`,
                    }}
                    style={{width: 'auto', height: 80}}
                    resizeMode="contain"
                />
            )}
            {resetLogo}
        </>
    );

    const openEditUserDataDialogFn = () => setOpenEditUserData(true);
    const openEditOperatorDataDialogFn = () => setOpenEditOperatorData(true);
    const openEditFooterDataDialogFn = () => setOpenEditFooterData(true);

    const onSelectedYear = async selectedYear => {
        await setInvoicesYear(selectedYear);
        await loadInvoicesForEvAccount(evAccount, false, INVOICE_FIRST_PAGE);
        setYearFilter(selectedYear);
    };

    const templateData = (
        <>
            <Pressable
                onPress={openEditUserDataDialogFn}
                style={[global.cardHeader, {borderTopWidth: 1, borderColor: 'rgba(93, 77, 157, 0.4)'}]}>
                <Text style={global.cardHeaderText}>{t(PROFILE_INVOICE_DATA_TITLE)}</Text>

                <Icon name={'edit'} color={theme.colors.primary} size={18} style={{marginLeft: 20}} />
            </Pressable>
            <View style={global.cardContent}>
                <View style={isDesktop && styles.invoiceDataWrapper}>
                    <View style={[isDesktop && {width: 250}]}>
                        <View style={[isMobileSmall ? styles.logoWrapperMobile : styles.logoWrapper]}>
                            {uploadComponent}
                            {!logoLoaded && (
                                <View style={[styles.logoPlaceholder, isMobileSmall && styles.logoPlaceholderMobile]}>
                                    <Icon name="image" type="ionicon" color={theme.colors.greyLight} size={50}></Icon>
                                </View>
                            )}
                            <Button
                                title={t(UPLOAD_LOGO)}
                                buttonStyle={[global.outlineButton, {width: 140, paddingHorizontal: 0}]}
                                titleStyle={global.outlineButtonTitle}
                                containerStyle={[global.Button.containerStyle, {marginTop: 20}]}
                                onPress={IS_WEB ? uploadImageHandler : openGallery}
                            />
                        </View>
                    </View>

                    <View
                        style={[
                            !isMobileSmall && {
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                            },
                        ]}>
                        <View
                            style={[
                                !isMobileSmall && {
                                    margin: 10,
                                    maxWidth: '50%',
                                },
                                isMobileSmall && {marginBottom: 20},
                            ]}>
                            <Text style={[global.textCaps, {marginBottom: 5}]}>{t(USER_DATA_INVOICE)}</Text>

                            {!isLegalEntity && (
                                <Text>
                                    <Text style={styles.itemTitle}>{t(USER_DATA_NAME_AND_SURNAME)}: </Text>
                                    <Text style={styles.itemValue}>
                                        {userData?.[USER_INFO]?.[USER_DATA_FORM_PERSONAL_NAME]}
                                    </Text>
                                </Text>
                            )}
                            {isLegalEntity && (
                                <>
                                    <Text>
                                        <Text style={styles.itemTitle}>{t(USER_DATA_LEGAL_NAME)}: </Text>
                                        <Text style={styles.itemValue}>
                                            {userData?.[USER_INFO]?.[USER_DATA_FORM_LEGAL_NAME]}
                                        </Text>
                                    </Text>
                                    <Text>
                                        <Text style={styles.itemTitle}>{t(OIB)}: </Text>
                                        <Text style={styles.itemValue}>
                                            {userData?.[USER_INFO]?.[USER_DATA_FORM_OIB]}
                                        </Text>
                                    </Text>
                                </>
                            )}
                            <Text>
                                <Text style={styles.itemTitle}>{t(USER_DATA_ADDRESS)}: </Text>
                                <Text style={styles.itemValue}> {userData?.[USER_INFO]?.[USER_DATA_FORM_ADDRESS]}</Text>
                            </Text>
                            <Text>
                                <Text style={styles.itemTitle}>{t(USER_DATA_CITY_POST)}: </Text>
                                <Text style={styles.itemValue}> {userData?.[USER_INFO]?.[USER_DATA_FORM_CITY]}</Text>
                            </Text>
                            <Text>
                                <Text style={styles.itemTitle}>{t(USER_DATA_COUNTRY)}: </Text>
                                <Text style={styles.itemValue}> {userData?.[USER_INFO]?.[USER_DATA_FORM_COUNTRY]}</Text>
                            </Text>
                            <Text>
                                <Text style={styles.itemTitle}>{t(USER_DATA_CONTACT)}: </Text>
                                <Text style={styles.itemValue}> {userData?.[USER_INFO]?.[USER_DATA_FORM_TEL]}</Text>
                            </Text>
                            <Text>
                                <Text style={styles.itemTitle}>{t(USER_DATA_EMAIL)}: </Text>
                                <Text style={styles.itemValue}> {userData?.[USER_INFO]?.[USER_DATA_FORM_EMAIL]}</Text>
                            </Text>
                        </View>

                        <View
                            style={[
                                !isMobileSmall && {
                                    margin: 10,
                                    maxWidth: '50%',
                                },
                            ]}>
                            <Text style={[global.textCaps, {marginBottom: 5}]}>{t(BANK_INFO)}</Text>

                            <Text>
                                <Text style={styles.itemTitle}>{t(IBAN)}: </Text>
                                <Text style={styles.itemValue}>
                                    {userData?.[USER_INFO]?.[USER_DATA_FORM_BANK_IBAN]}
                                </Text>
                            </Text>
                            <Text>
                                <Text style={styles.itemTitle}>{t(SWIFT)}: </Text>
                                <Text style={styles.itemValue}>
                                    {userData?.[USER_INFO]?.[USER_DATA_FORM_BANK_SWIFT]}
                                </Text>
                            </Text>
                            <Text>
                                <Text style={styles.itemTitle}>{t(BANK)}: </Text>
                                <Text style={styles.itemValue}> {userData?.[USER_INFO]?.[USER_DATA_FORM_BANK]}</Text>
                            </Text>
                            <Text>
                                <Text style={styles.itemTitle}>{t(BANK_ADDRESS)}: </Text>
                                <Text style={styles.itemValue}>
                                    {userData?.[USER_INFO]?.[USER_DATA_FORM_BANK_ADDRESS]}
                                </Text>
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
            <Pressable
                onPress={openEditOperatorDataDialogFn}
                style={[global.cardHeader, {borderTopWidth: 1, borderTopColor: theme.colors.borderLight}]}>
                <Text style={global.cardHeaderText}>{t(PROFILE_INVOICE_OPERATOR_TITLE)}</Text>

                <Icon name={'edit'} color={theme.colors.primary} size={18} style={{marginLeft: 20}} />
            </Pressable>
            <View style={global.cardContent}>
                <Text>
                    <Text style={styles.itemTitle}>{t(PROFILE_INVOICE_OPERATOR_REF)}: </Text>
                    <Text style={styles.itemValue}>{operatorRef}</Text>
                </Text>
                <Text>
                    <Text style={styles.itemTitle}>{t(PROFILE_INVOICE_OPERATOR_NAME)}: </Text>
                    <Text style={styles.itemValue}>{operatorName}</Text>
                </Text>
            </View>

            <Pressable
                onPress={openEditFooterDataDialogFn}
                style={[global.cardHeader, {borderTopWidth: 1, borderTopColor: theme.colors.borderLight}]}>
                <Text style={global.cardHeaderText}>{t(PROFILE_INVOICE_FOOTER_TITLE)}</Text>

                <Icon name={'edit'} color={theme.colors.primary} size={18} style={{marginLeft: 20}} />
            </Pressable>
            <View style={global.cardContent}>
                <View style={global.flexCenter}>
                    <Text style={styles.itemTitle}>{t(PROFILE_INVOICE_FOOTER_TEXT)}: </Text>
                    <Text style={[styles.itemValue, {flex: 1}]} numberOfLines={1}>
                        {footerText}
                    </Text>
                </View>
            </View>
        </>
    );

    const accordionData = [{id: 1, title: t(PROFILE_INVOICE_TEMPLATE_TITLE), content: templateData}];

    return (
        <>
            <View style={[global.boxShadow, {borderRadius: 10, borderWidth: 1, borderColor: 'rgba(93, 77, 157, 0.4)'}]}>
                <View style={global.card}>
                    <Accordion data={accordionData} />
                </View>
            </View>

            <View
                style={[
                    {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginVertical: 15,
                    },
                    isMobileEtraSmall && {flexDirection: 'column'},
                ]}>
                <View style={[global.flexCenter, {width: '100%', flex: 1}]}>
                    <Pressable
                        onPress={openEvisitorDialog}
                        style={[global.flexCenter, {marginRight: 10}, isMobileSmall && {flex: 1}]}>
                        <Text
                            style={[global.link, !isMobileSmall && {maxWidth: 200}, isDesktop && {maxWidth: 300}]}
                            numberOfLines={1}>
                            {evAccount?.[NAME] ?? EVISITOR_LABEL}
                        </Text>
                        <Icon name={'keyboard-arrow-down'} size={16} />
                    </Pressable>

                    <Dropdown
                        position="left"
                        currentSortOption={yearFilter}
                        dropdownOptions={dropdownYearOptions}
                        onSelected={onSelectedYear}
                        defaultCurrentYear={true}
                    />
                </View>

                <Button
                    onPress={exportInvoices}
                    title={t(INVOICES_DOWNLOAD_LABEL)}
                    icon={{
                        type: 'ionicon',
                        name: 'download-outline',
                        size: 18,
                        color: theme.colors.white,
                        containerStyle: {marginRight: 5},
                    }}
                    containerStyle={[
                        global.Button.containerStyle,
                        isMobileEtraSmall && {marginTop: 10, marginBottom: 5, alignSelf: 'flex-start'},
                        !isMobileEtraSmall && {marginLeft: 20},
                    ]}
                    buttonStyle={[global.Button.buttonStyle, {paddingHorizontal: 10, paddingVertical: 5}]}
                    titleStyle={[global.Button.titleStyle, {textTransform: 'none'}]}
                />
            </View>

            <EditUserDataDialog
                open={openEditUserData}
                setOpen={setOpenEditUserData}
                onConfirm={onEditUserDataConfirm}
                userInfo={userData?.[USER_INFO]}
            />
            <EditOperatorDataDialog
                open={openEditOperatorData}
                setOpen={setOpenEditOperatorData}
                onConfirm={onConfirmOperatorData}
            />
            <EditFooterDataDialog
                open={openEditFooterData}
                setOpen={setOpenEditFooterData}
                onConfirm={onConfirmFooterData}
            />
        </>
    );
};

export default InvoicesHeader;
