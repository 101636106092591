import {checkCheckinTable, reCreateCheckinTable} from './checkin';
import {createEvisitorTable} from './evisitor';
import {createGuestTable} from './guest';
import initSqlJs from 'sql.js';

// Required to let webpack 4 know it needs to copy the wasm file to our assets
import sqlWasm from '!!file-loader?name=sql-wasm-[contenthash].wasm!sql.js/dist/sql-wasm.wasm';
import {getFromIdxDb, IDX_DB_SQLITE_KEY, initIdxDb, putToIdxDb} from './indexedDb.web';
import {initVersionsTable} from './versions';
import {createInvoiceTable} from './invoice';

// without return value
export const runSqlCmd = async (tx, cmd) => {
    try {
        tx.run(cmd);
    } catch (e) {
        console.log(e);
    }
};

// with return
export const execSqlCmd = async (tx, cmd) => {
    try {
        return tx.exec(cmd);
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const execSqlCmdThrowable = async (tx, cmd) => {
    return tx.exec(cmd);
};

export const parseSqlResults = (sqlResults, parsedResults) => {
    if (sqlResults) {
        sqlResults.forEach(result => {
            const dataColumns = result['columns'];
            const dataSets = result['values'];
            dataSets.forEach(dataSet => {
                let counter = 0;
                const out = {};
                dataSet.forEach(data => {
                    out[dataColumns[counter]] = data;
                    counter++;
                });
                parsedResults.push(out);
            });
        });
    }
    return parsedResults;
};

export const parseSqlExistsResults = sqlResults => {
    if (!Array.isArray(sqlResults) || !sqlResults.length) {
        // array does not exist, is not an array, or is empty
        // ⇒ do not attempt to process array
        return false;
    }
    return true;
};

export const parseSqlCountResults = sqlResults => {
    if (sqlResults) {
        for (let result of sqlResults) {
            const res = result?.['values']?.[0] ?? 0;
            return Array.isArray(res) ? res[0] : res;
        }
    }
    return 0;
};

export const initDb = async (db, FORCE_DB_CREATE) => {
    try {
        console.log('Trying to init DB: ' + db);
        if (!db) {
            await initIdxDb();
            const SQL = await initSqlJs({locateFile: () => sqlWasm});
            const restoredDb = await importDatabase();
            db = restoredDb ? new SQL.Database(restoredDb) : new SQL.Database();
            console.log('Database OPEN');
            await initVersionsTable(db);
            const exist = await checkCheckinTable(db);
            if (!exist || FORCE_DB_CREATE) {
                await reCreateCheckinTable(db);
                await createGuestTable(db);
                await createEvisitorTable(db);
                await createInvoiceTable(db);
            }
            exportDatabase(db);
            return db;
        }
    } catch (e) {
        console.log('Database init error');
        console.log(e);
    }
    return db;
};

export const exportDatabase = async db => {
    //console.log('Trying to export database');
    await putToIdxDb(IDX_DB_SQLITE_KEY, db.export());
};

export const importDatabase = async () => {
    console.log('Trying to import database');
    const dbContent = await getFromIdxDb(IDX_DB_SQLITE_KEY);
    return dbContent;
};
