import {content, CREATED_AT, ID, PIN, UPDATED_AT, USER_ID, USER_ID_IDX} from '../constants/stringsAndFields';
import {findEvisitorById} from '../utils/arrayHelpers';
import {getUserId} from '../utils/userUtils';
import {checkTableExists, deleteData, insertData, selectData, SQL_TIME_NOW, updateData} from './common';
import {runSqlCmd} from './sqlOperations';

export const EVISITOR_TABLE = 'evisitor';
const keys = [ID, USER_ID, CREATED_AT, UPDATED_AT, content];

export const createEvisitorTable = async tx => {
    await runSqlCmd(tx, `DROP TABLE IF EXISTS ${EVISITOR_TABLE};`);
    await runSqlCmd(tx, `DROP INDEX IF EXISTS ${USER_ID_IDX}`);
    await runSqlCmd(
        tx,
        `CREATE TABLE IF NOT EXISTS ${EVISITOR_TABLE}(
      ${ID} TEXT PRIMARY KEY NOT NULL,
      ${USER_ID} TEXT,
      ${CREATED_AT} TEXT,
      ${UPDATED_AT} TEXT,
      ${content} TEXT);`
    );
    await runSqlCmd(tx, `CREATE INDEX ${USER_ID_IDX} ON ${EVISITOR_TABLE} (${USER_ID});`);
};

export const insertEvisitors = async (tx, evisitors) => {
    if (evisitors) {
        for (let evisitor of evisitors) {
            await insertEvisitor(tx, evisitor);
        }
    }
};

export const insertEvisitor = async (tx, evisitor) => {
    const userId = await getUserId();
    const dbEvisitor = {
        ID: evisitor[ID],
        [USER_ID]: userId,
        [CREATED_AT]: SQL_TIME_NOW,
        [UPDATED_AT]: SQL_TIME_NOW,
        content: JSON.stringify(evisitor),
    };
    await insertData(tx, EVISITOR_TABLE, keys, dbEvisitor);
};

export const deleteAllEvisitors = async tx => {
    await deleteData(tx, EVISITOR_TABLE);
};

export const deleteEvisitor = async (tx, evisitor) => {
    await deleteData(tx, EVISITOR_TABLE, evisitor[ID]);
};

export const updateEvisitor = async (tx, evisitor) => {
    const userId = await getUserId();
    const dbEvisitor = {
        ID: evisitor[ID],
        [USER_ID]: userId,
        [UPDATED_AT]: SQL_TIME_NOW,
        content: JSON.stringify(evisitor),
    };
    await updateData(tx, EVISITOR_TABLE, dbEvisitor, ID);
};

export const getEvisitors = async tx => {
    const userId = await getUserId();
    const evisitors = await selectData(tx, EVISITOR_TABLE, keys, USER_ID, userId);
    if (evisitors) {
        try {
            const deserializedEvisitors = evisitors.map(evisitor => {
                return JSON.parse(evisitor[content]);
            });
            return deserializedEvisitors;
        } catch (e) {
            console.log(e);
        }
    }
    return [];
};

export const getEvisitor = async (tx, eVisitorId) => {
    const userId = await getUserId();
    const evisitors = await selectData(tx, EVISITOR_TABLE, keys, USER_ID, userId);
    const evisitor = findEvisitorById(evisitors, eVisitorId);
    try {
        if (evisitor) {
            return JSON.parse(evisitor[content]);
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const getEvisitorByPinDb = async (tx, pin) => {
    const userId = await getUserId();
    const evisitors = await selectData(tx, EVISITOR_TABLE, keys, USER_ID, userId);
    for (let evisitor of evisitors) {
        try {
            const eVisitorData = JSON.parse(evisitor[content]);
            if (eVisitorData[PIN] === pin) return eVisitorData;
        } catch (e) {
            console.log(e);
        }
    }
    return null;
};

export const isEvisitorDataEmpty = async tx => {
    const evisitors = await getEvisitorsWithoutData(tx);
    return evisitors && evisitors.length > 0 ? false : true;
};

const getEvisitorsWithoutData = async tx => {
    const userId = await getUserId();
    const evisitors = await selectData(tx, EVISITOR_TABLE, keys, USER_ID, userId);
    return evisitors ? evisitors : [];
};

export const checkEvisitorTable = async tx => {
    return await checkTableExists(tx, EVISITOR_TABLE);
};
