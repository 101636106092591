import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    invoiceBoxWrapper: {
        padding: 10,
        width: '100%',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },

    invoiceBox: {
        width: '100%',
        maxWidth: 794,
        maxHeight: 1123,
        aspectRatio: '595/842',
        shadowColor: theme.colors.primaryDark,
        shadowOpacity: 0.15,
        shadowOffset: {width: 3, height: 3},
        shadowRadius: 10,
        elevation: 5,
    },

    accountName: {
        paddingHorizontal: 15,
        paddingVertical: 11,
    },

    accountCredits: {
        paddingHorizontal: 15,
        paddingVertical: 11,
    },

    accountCreditsText: {
        color: theme.colors.textColor,
        fontSize: 16,
        lineHeight: 30,
        fontFamily: theme.fontFamily,
    },

    accountCreditsValue: {
        color: theme.colors.primary,
        fontSize: 16,
        lineHeight: 30,
        fontFamily: theme.fontFamilyMedium,
    },
}));

export default useStyles;
