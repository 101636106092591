import React from 'react';
import {View, Text, Pressable, Platform} from 'react-native';
import {EVISITOR_LABEL, NAME} from '../../constants/stringsAndFields';

const IS_WEB = Platform.OS === 'web';

const EVChooserHeader = ({evAccount, useSharedDialogs, global}) => {
    const {openEvisitorDialog} = useSharedDialogs();
    return (
        <View style={[global.flexCenter, {maxWidth: '100%'}]}>
            <View style={[global.evChooserWrapper]}>
                <Pressable
                    onPress={openEvisitorDialog}
                    style={[!IS_WEB && {alignSelf: 'flex-end'}, {marginHorizontal: 15}]}>
                    <Text numberOfLines={1} style={[global.evChooser, global.link]}>
                        {evAccount?.[NAME] ?? EVISITOR_LABEL}
                    </Text>
                </Pressable>
            </View>
        </View>
    );
};

export default EVChooserHeader;
