import React from 'react';
import {FlatList, useWindowDimensions} from 'react-native';
import {useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import CalendarGuestItem from './CalendarGuestItem';
import {useMemo} from 'react';
import {useSharedDialogs} from './useDialogs';

const CalendarGuestsList = ({
    guests,
    guestActions,
    evAccount,
    facility,
    showErrors,
    setCheckinActionsDialog,
    calendarComponent,
    navigation,
}) => {
    const {theme} = useTheme();
    const width = useWindowDimensions().width;

    const {openQRDetails, updateGuestId} = useSharedDialogs();

    const memoizedContainerStyle = useMemo(() => {
        const isDesktop = width > 1500;
        const global = globalStyle(theme);
        return [
            global.flatList,
            global.hasFAB,
            {flexGrow: 1, width: '100%', maxWidth: 1100, alignSelf: 'center'},
            isDesktop && {paddingTop: 25},
        ];
    }, [width, theme]);

    const render = ({item}) => (
        <CalendarGuestItem
            guest={item}
            guestActions={guestActions}
            evAccount={evAccount}
            facility={facility}
            showErrors={showErrors}
            setCheckinActionsDialog={setCheckinActionsDialog}
            openQRDetails={openQRDetails}
            updateGuestId={updateGuestId}
            navigation={navigation}
        />
    );

    return (
        <FlatList
            data={guests}
            renderItem={render}
            ListHeaderComponent={calendarComponent}
            contentContainerStyle={memoizedContainerStyle}
        />
    );
};

export default CalendarGuestsList;
