import {
    content,
    CHECKIN_ID,
    id,
    STATUS,
    USER_ID,
    CREATED_AT,
    UPDATED_AT,
    UPDATED_STATUS,
    USER_ID_IDX,
    SELF_CHECKIN_FIELD,
} from '../constants/stringsAndFields';
import {isNotEmpty} from '../utils/arrayHelpers';
import {getUserId} from '../utils/userUtils';
import {CHECKINS_TABLE} from './checkin';
import {checkTableExists, deleteData, insertData, selectData, selectDataMultiFilter, updateData} from './common';
import {runSqlCmd} from './sqlOperations';

export const GUESTS_TABLE = 'guests';
const keys = [id, CHECKIN_ID, USER_ID, STATUS, content, UPDATED_STATUS, CREATED_AT, UPDATED_AT];

export const createGuestTable = async tx => {
    await runSqlCmd(tx, `DROP TABLE IF EXISTS ${GUESTS_TABLE};`);
    await runSqlCmd(tx, `DROP INDEX IF EXISTS ${USER_ID_IDX}`);
    await runSqlCmd(
        tx,
        `CREATE TABLE IF NOT EXISTS ${GUESTS_TABLE}(
      ${id} TEXT PRIMARY KEY NOT NULL,
      ${CHECKIN_ID} TEXT,
      ${USER_ID} TEXT,
      ${STATUS} TEXT,
      ${content} TEXT,
      ${SELF_CHECKIN_FIELD} INTEGER DEFAULT 0,
      ${UPDATED_STATUS} INTEGER,
      ${CREATED_AT} TEXT,
      ${UPDATED_AT} TEXT,
      FOREIGN KEY(${CHECKIN_ID}) REFERENCES ${CHECKINS_TABLE}(${id}));`
    );
    await runSqlCmd(tx, `CREATE INDEX ${USER_ID_IDX} ON ${GUESTS_TABLE} (${USER_ID});`);
};

export const insertGuest = async (tx, guest) => {
    const userId = await getUserId();
    await insertData(tx, GUESTS_TABLE, keys, {...guest, [USER_ID]: userId});
};

export const deleteAllGuests = async tx => {
    await deleteData(tx, GUESTS_TABLE);
};

export const deleteGuest = async (tx, guest) => {
    await deleteData(tx, GUESTS_TABLE, guest[id]);
};

export const updateGuest = async (tx, guest) => {
    await updateData(tx, GUESTS_TABLE, guest, id);
};

export const getGuests = async tx => {
    const userId = await getUserId();
    const guests = await selectData(tx, GUESTS_TABLE, keys, USER_ID, userId);
    if (guests) {
        const deserializedGuests = guests.map(guest => {
            guest[content] = JSON.parse(guest[content]);
            return guest;
        });
        return deserializedGuests;
    }
    return [];
};

export const getGuest = async (tx, guestId) => {
    const userId = await getUserId();
    const guest = await selectDataMultiFilter(tx, GUESTS_TABLE, keys, USER_ID, userId, id, guestId);
    if (guest && isNotEmpty(guest)) {
        return guest;
    }
    return null;
};

export const getGuestByCheckin = async (tx, checkin) => {
    const guests = await selectData(tx, GUESTS_TABLE, keys, CHECKIN_ID, checkin[id]);
    if (guests) {
        const deserializedGuests = guests.map(guest => {
            guest[content] = JSON.parse(guest[content]);
            return guest;
        });
        return deserializedGuests;
    }
    return [];
};

export const getGuestsByCheckinWithoutContent = async (tx, checkin) => {
    const guests = await selectData(tx, GUESTS_TABLE, keys, CHECKIN_ID, checkin[id]);
    if (guests) {
        const deserializedGuests = guests.map(guest => {
            delete guest[content];
            return guest;
        });
        return deserializedGuests;
    }
    return [];
};

export const checkGuestTable = async tx => {
    return await checkTableExists(tx, GUESTS_TABLE);
};

/*
id: uuid.UUID = db.Column(db.UUID, default=uuid.uuid4, primary_key=True)
# One checkin to one user tokens relationship
user_id: uuid.UUID = db.Column(db.UUID, db.ForeignKey('users.id'), nullable=False)
checkin_id: uuid.UUID = db.Column(db.UUID, db.ForeignKey('checkin.id'), nullable=False)
content: Dict = db.Column(JSONB, default={})
status: str = db.Column(db.String(255), nullable=False)
created_at: datetime.datetime = db.Column(db.DateTime, default=datetime.datetime.utcnow)
updated_at: datetime.datetime = db.Column(db.DateTime, onupdate=datetime.datetime.utcnow)

[CHECKIN_ID]: checkin[id],
[id]: guestId,
[STATUS]: EDITED_STATUS,
[content]:
*/
