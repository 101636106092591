import {
    CAM_DATE_BIRTH,
    CAM_DOCUMENT,
    CAM_FNAME,
    CAM_FORMAT_DATE_BIRTH,
    CAM_GNAME,
    CAM_LNAME,
    CAM_MRY_ISSUING_COUNTRY,
    CAM_MRZ_DOC_TYPE,
    CAM_MRZ_NATIONALITY,
    CAM_MRZ_SEX,
    CAM_NATIONALITY,
    CAM_RESULT,
    CAM_SEX,
    CAM_SNAME,
    CAM_TEXT,
    CITIZENSHIP,
    CITY_OF_RESIDENCE,
    COUNTRY_OF_BIRTH,
    COUNTRY_OF_RESIDENCE,
    DATE_OF_BIRTH_FIELD,
    DEFAULT_SERVICE_TYPE,
    DOCUMENT_NUMBER,
    DOCUMENT_TYPE,
    GENDER_FIELD,
    OFFERED_SERVICE_TYPE,
    TOURIST_NAME,
    TOURIST_SURNAME,
    CAM_IDENTIFIER,
    CAM_IDENTIFIER_JSON,
    CAM_FORMAT_DATEOBJECT_BIRTH,
} from '../constants/stringsAndFields';
import {getMrzItemByIdentiferValue} from './arrayHelpers';
import {getDateFromCamera, getDateFromMrzCamera, getDateFromNativeCamera} from './dateHelper';
import {checkIsoCountry, getDocumentTypeFromChar} from './guestUtils';
import {mapGender, mapGenderMrz} from './helpers';

const presets = ['universalid_id', 'universalid_mrz', 'universalid_dl'];
export const MRZ = 'universalid_mrz';
export const UNIVERAL_ID_FRONT = 'universalid_id';
// this variable is used for switching camera id modes
export const CAMERA_MODE = MRZ;

export const parseCamResults = (scanResults, isNative = false) => {
    if (isNative) {
        if (scanResults && CAMERA_MODE === MRZ) {
            try {
                // anyline native scan results
                // const data = JSON.parse(scanResults);
                // return mapMrzToGuestAnyline(data, isNative);
                return mapMrzToGuest(scanResults, isNative);
            } catch (e) {
                console.log(e);
                console.log('Could not parse cam results');
            }
        }
    } else {
        // anyline native
        const scanResult = scanResults?.result;
        if (CAMERA_MODE === UNIVERAL_ID_FRONT) {
            if (scanResult && scanResult?.length > 0) {
                let textRes = null;
                for (let dataRes of scanResult) {
                    if (dataRes?.[CAM_IDENTIFIER] === CAM_IDENTIFIER_JSON) {
                        textRes = dataRes?.[CAM_TEXT];
                    }
                }
                if (textRes) {
                    try {
                        const textData = JSON.parse(textRes);
                        return mapUniversalIdToGuest(textData);
                    } catch (e) {
                        console.log('Could not parse cam results');
                    }
                }
            }
        } else if (CAMERA_MODE === MRZ) {
            // anyline web
            if (scanResult && scanResult?.length > 0) {
                try {
                    return mapMrzToGuestAnyline(scanResult, isNative);
                } catch (e) {
                    console.log(e);
                    console.log('Could not parse cam results');
                }
            }
        }
    }

    return null;
};

// UNIVERSAL ID
export const mapUniversalIdToGuest = scanResult => {
    const guest = {
        [TOURIST_NAME]: scanResult?.[CAM_RESULT]?.[CAM_FNAME]?.[CAM_TEXT],
        [TOURIST_SURNAME]: scanResult?.[CAM_RESULT]?.[CAM_LNAME]?.[CAM_TEXT],
        [DATE_OF_BIRTH_FIELD]: getDateFromCamera(scanResult?.[CAM_RESULT]?.[CAM_DATE_BIRTH]?.[CAM_TEXT]),
        [DOCUMENT_NUMBER]: scanResult?.[CAM_RESULT]?.[CAM_DOCUMENT]?.[CAM_TEXT],
        [CITIZENSHIP]: scanResult?.[CAM_RESULT]?.[CAM_NATIONALITY]?.[CAM_TEXT],
        [GENDER_FIELD]: mapGender(scanResult?.[CAM_RESULT]?.[CAM_SEX]?.[CAM_TEXT]),
    };
    return guest;
};

// MRZ
export const mapMrzToGuest = (scanResult, isNative) => {
    const {birth, issuingCountry, nationality, name, surname, document, sex, documentType} = scanResult;
    const dateOfBirth = getDateFromMrzCamera(birth);
    const residenceCountry = checkIsoCountry(issuingCountry);
    const citizenship = checkIsoCountry(nationality);

    const guestIsHrv = residenceCountry === 'HRV';
    const guest = {
        [TOURIST_NAME]: name,
        [TOURIST_SURNAME]: surname,
        [DATE_OF_BIRTH_FIELD]: dateOfBirth,
        [DOCUMENT_NUMBER]: document,
        [GENDER_FIELD]: mapGenderMrz(sex),
        [CITIZENSHIP]: citizenship,
        [COUNTRY_OF_RESIDENCE]: residenceCountry,
        [COUNTRY_OF_BIRTH]: residenceCountry,
        [CITY_OF_RESIDENCE]: '',
        [OFFERED_SERVICE_TYPE]: DEFAULT_SERVICE_TYPE,
        [DOCUMENT_TYPE]: getDocumentTypeFromChar(documentType, guestIsHrv),
    };
    return guest;
};

export const mapMrzToGuestAnyline = (scanResult, isNative) => {
    const dateOfBirth = isNative
        ? getDateFromNativeCamera(getMrzItemByIdentiferValue(scanResult, CAM_FORMAT_DATEOBJECT_BIRTH, isNative))
        : getDateFromCamera(getMrzItemByIdentiferValue(scanResult, CAM_FORMAT_DATE_BIRTH, isNative));
    const residenceCountry = checkIsoCountry(getMrzItemByIdentiferValue(scanResult, CAM_MRY_ISSUING_COUNTRY, isNative));
    const citizenship = checkIsoCountry(getMrzItemByIdentiferValue(scanResult, CAM_MRZ_NATIONALITY, isNative));

    const guestIsHrv = residenceCountry === 'HRV';
    const guest = {
        [TOURIST_NAME]: getMrzItemByIdentiferValue(scanResult, CAM_GNAME, isNative),
        [TOURIST_SURNAME]: getMrzItemByIdentiferValue(scanResult, CAM_SNAME, isNative),
        [DATE_OF_BIRTH_FIELD]: dateOfBirth,
        [DOCUMENT_NUMBER]: getMrzItemByIdentiferValue(scanResult, CAM_DOCUMENT, isNative),
        [GENDER_FIELD]: mapGenderMrz(getMrzItemByIdentiferValue(scanResult, CAM_MRZ_SEX, isNative)),
        [CITIZENSHIP]: citizenship,
        [COUNTRY_OF_RESIDENCE]: residenceCountry,
        [COUNTRY_OF_BIRTH]: residenceCountry,
        [CITY_OF_RESIDENCE]: '',
        [OFFERED_SERVICE_TYPE]: DEFAULT_SERVICE_TYPE,
        [DOCUMENT_TYPE]: getDocumentTypeFromChar(
            getMrzItemByIdentiferValue(scanResult, CAM_MRZ_DOC_TYPE, isNative),
            guestIsHrv
        ),
    };
    return guest;
};
