import React from 'react';
import MaterialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import FontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import MaterialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import Ionicons from 'react-native-vector-icons/Fonts/Ionicons.ttf';

export const webRootCss = (
  <style type="text/css">{`
    @font-face {
      font-family: 'MaterialIcons';
      src: url('${MaterialIcons}') format('truetype');
    }

    @font-face {
      font-family: 'MaterialCommunityIcons';
      src: url('${MaterialCommunityIcons}') format('truetype');
    }

    @font-face {
      font-family: 'FontAwesome';
      src: url('${FontAwesome}') format('truetype');
    }

    @font-face {
      font-family: 'Ionicons';
      src: url('${Ionicons}') format('truetype');
    }
`}</style>
);
