import {useCallback, useState} from 'react';
import {useBetween} from 'use-between';

const useDialog = () => {
    const [isOpenEvisitorChooser, setIsOpenEvisitorChooser] = useState(false);
    const openEvisitorDialog = useCallback(() => setIsOpenEvisitorChooser(true), []);
    const closeEvisitorDialog = useCallback(() => setIsOpenEvisitorChooser(false), []);

    const [isOpenFab, setIsOpenFab] = useState(false);
    const openFab = useCallback(() => setIsOpenFab(true), []);
    const closeFab = useCallback(() => setIsOpenFab(false), []);

    const [dayPicked, setDayPicked] = useState(null);
    const updateDayPicked = useCallback(day => setDayPicked(day), []);

    const [isOpenQRDetails, setIsOpenQRDetails] = useState(false); // QR code details
    const openQRDetails = useCallback(_ => setIsOpenQRDetails(true), []);
    const closeQRDetails = useCallback(() => setIsOpenQRDetails(false), []);

    const [guestId, setGuestId] = useState(null); // used for QR dialog
    const updateGuestId = useCallback(id => setGuestId(id), []);

    const [creditsDepletedOpen, setCreditsDepletedOpen] = useState(false);
    const openCreditsDepleted = useCallback(_ => setCreditsDepletedOpen(true), []);
    const closeCreditsDepleted = useCallback(_ => setCreditsDepletedOpen(false), []);

    const [useCreditsAfterExpireOpen, setUseCreditsAfterExpireOpen] = useState(false);
    const openCreditsAfterExpire = useCallback(_ => setUseCreditsAfterExpireOpen(true), []);
    const closeCreditsAfterExpire = useCallback(_ => setUseCreditsAfterExpireOpen(false), []);

    return {
        isOpenEvisitorChooser,
        openEvisitorDialog,
        closeEvisitorDialog,
        isOpenFab,
        openFab,
        closeFab,
        dayPicked,
        updateDayPicked,
        isOpenQRDetails,
        openQRDetails,
        closeQRDetails,
        guestId,
        updateGuestId,
        creditsDepletedOpen,
        openCreditsDepleted,
        closeCreditsDepleted,
        useCreditsAfterExpireOpen,
        openCreditsAfterExpire,
        closeCreditsAfterExpire,
    };
};

export const useSharedDialogs = () => useBetween(useDialog);
