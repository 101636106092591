import AsyncStorage from '@react-native-async-storage/async-storage';
import LZString from 'lz-string';
//import jwt from 'jsonwebtoken';
import inRange from 'lodash/inRange';
import QRCodeGenerator from 'qrcode-svg';
import {Linking, Platform} from 'react-native';
import {fetchCheckinsFromRest, saveCheckinRest, saveGuestRest} from '../api/inCheckinRestService';
import {BASE_URL} from '../api/restClient';
import {
    ACCOMODATION_OBJECT,
    ACCOMODATION_UNIT,
    ADDITIONAL_FIELDS,
    ADDRESS,
    APP_LANGS,
    CHECKIN,
    CHECKIN_ID,
    CITIZENSHIP,
    CITY_OF_RESIDENCE,
    CODE,
    COUNTRY_CODE,
    COUNTRY_OF_BIRTH,
    COUNTRY_OF_RESIDENCE,
    CROATIA_COUNTRY_CODE,
    DATE_OF_BIRTH_FIELD,
    DATE_PLACE_OF_BIRTH,
    DATE_TIME_OF_DEPARTURE,
    DOCUMENT_NUMBER,
    DOCUMENT_TYPE,
    EV_STATIC_DATA,
    EXPORT_HEADER_ADDRESS,
    EXPORT_HEADER_NAME,
    EXPORT_HEADER_OIB,
    EXPORT_HEADER_PLACE,
    FACILITY_ID,
    FEMALE,
    FORESEEN_STAY_UNTIL_FIELD,
    GENDERS,
    GENDER_FIELD,
    GUEST_NOTE_FIELD,
    HR_LOCALE,
    HR_LOCALE_ALT,
    ID,
    INITIAL_GUEST_FIELD,
    INVOICE_CUSTOM_OWNER_FIELD,
    INVOICE_FORM_COMPANY_TYPE,
    INVOICE_FORM_CUSTOMER_TYPE,
    INVOICE_FORM_VER,
    INVOICE_TYPE,
    INVOICE_TYPE_PROFORMA,
    MALE,
    MANUAL_GUEST_DATES,
    MANUAL_GUEST_TIME,
    MAX_GUEST_NUMBER,
    NAME,
    NAME_CITIZENSHIP,
    NAME_CODE,
    NAME_NATIONAL,
    NAME_NATIONAL_SHORT,
    PIN,
    PIN_ID,
    REMOTE_CHECKIN_EXISTS,
    REST_ERRORS,
    SETTLEMENTS_DATA,
    SETTLEMENT_NAME,
    SINGLE_GUEST_CHECKIN,
    SLUG,
    STATUS,
    STAY_FROM_FIELD,
    STORAGE_CHOSEN_EV_ACCOUNT,
    STORAGE_USER_DATA,
    STREET_NUMBER,
    TIME_ESTIMATED_STAY_UNTIL_FIELD,
    TIME_STAY_FROM_FIELD,
    TOURIST_NAME,
    TOURIST_SURNAME,
    TOURIST_SURNAME_NAME,
    TRAVEL_DOCUMENT_TYPE,
    UPDATED_STATUS,
    USER_DATA_FORM_ADDRESS,
    USER_DATA_FORM_CITY,
    USER_DATA_FORM_IN_PDV_SYSTEM,
    USER_DATA_FORM_LEGAL_NAME,
    USER_DATA_FORM_PERSONAL_NAME,
    USER_DATA_SOJOURN_TAX,
    USER_INFO,
    code,
    content,
    guests,
    id,
    name,
} from '../constants/stringsAndFields';
import {findEvisitorByPin, mapCountryCodeToCountry, splitIntoChunks} from './arrayHelpers';
import {
    calculateAge,
    getDateFromAspNetFormatToEvisitor,
    getDateFromDateString,
    getNightsStayUntil,
    getNowDate,
    getNowTime,
    getTimeFromAspNetFormatToEvisitor,
    getTimeStayUntil,
} from './dateHelper';
import {DEFAULT_LANG} from './i18n';
import {getCity} from './invoice/invoice.functions';
import {isOnline} from './network';
import {getCurrentCheckinTime, getIsCurrentCheckinTimeEnabled, getUserOwnerOib} from './userUtils';

const IS_WEB = Platform.OS === 'web';

// TODO: THIS FILE SHOULD BE SEPARATED INTO MULTIPLE FILES: authHelpers, guestHelpers, shareHelpers....

// email share onClick link email:
export const emailLink = (url, {subject, body, separator}) =>
    'mailto:' + objectToGetParams({subject, body: body ? body + separator + url : url});

export const onClickEmail = link => {
    window.location.href = link;
};

export const objectToGetParams = object => {
    const params = Object.entries(object)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
    return params.length > 0 ? `?${params.join('&')}` : '';
};

export const validateEmail = testEmail => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(testEmail);
};

export const validatePasswordLength = password => {
    return password.length >= 6;
};

export const getPort = () =>
    window.location.port !== 443 && window.location.port !== '' ? ':' + window.location.port : '';

export const sendEmail = (subject, body) => {
    const link = 'mailto:?to=' + '&subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
    window.open(link);
};

export const openInNewTab = link => {
    if (IS_WEB) {
        window.open(link, '_blank');
    } else {
        Linking.openURL(link);
    }
};

export const removeParanthesisText = text => {
    const regExp = /\(([^)]+)\)/;
    const matches = regExp.exec(text);
    if (matches && matches[1]) {
        return text.replace(matches[1], '').replace('()', '');
    } else return text;
};

export const mapTouristToGuest = (tourist, countries, citiesSettlements, documents) => {
    const guest = {};
    guest[GENDER_FIELD] = tourist[GENDER_FIELD];
    const datePlaceBirth = tourist[DATE_PLACE_OF_BIRTH];
    const countryBirth = datePlaceBirth.substring(datePlaceBirth.indexOf(')') + 1).trim();

    let matchedBirthCountry = false;
    let matchedCitizenship = false;
    let matchedResidence = false;
    let matchedCity = false;
    for (let country of countries) {
        if (!matchedCitizenship && tourist[CITIZENSHIP] === country[NAME_CITIZENSHIP]) {
            // "Citizenship": "GUM",
            guest[CITIZENSHIP] = country[COUNTRY_CODE];
            matchedCitizenship = true;
        }

        // "Address": " Nepoznato Guam",
        if (!matchedResidence && tourist[ADDRESS].endsWith(country[NAME_NATIONAL])) {
            const city = tourist[ADDRESS].replace(country[NAME_NATIONAL], '').trim();
            guest[CITY_OF_RESIDENCE] = city;
            guest[COUNTRY_OF_RESIDENCE] = country[COUNTRY_CODE];
            matchedResidence = true;
        }

        if (!matchedCity && citiesSettlements && country[COUNTRY_CODE] === CROATIA_COUNTRY_CODE) {
            for (let citySettlement of citiesSettlements) {
                if (guest[CITY_OF_RESIDENCE] === citySettlement[SETTLEMENT_NAME]) {
                    guest[CITY_OF_RESIDENCE] = citySettlement[NAME];
                    matchedCity = true;
                    break;
                }
            }
        }

        // "DateOfBirth": "20030626",  "DatePlaceOfBirth": "26.06.2003 (18)  Guam",
        if (!matchedBirthCountry) {
            if (countryBirth === country[NAME_NATIONAL]) {
                guest[COUNTRY_OF_BIRTH] = country[COUNTRY_CODE];
                matchedBirthCountry = true;
            }
        }
    }

    // "DateOfBirth": "20030626",  "DatePlaceOfBirth": "26.06.2003 (18)  Guam",
    const dateOfBirth = datePlaceBirth.substring(0, datePlaceBirth.indexOf('(') + -1).trim();
    const dateOfBirthParts = dateOfBirth.split('.');
    const dateOfBirthFormatted = dateOfBirthParts[2] + dateOfBirthParts[1] + dateOfBirthParts[0];
    guest[DATE_OF_BIRTH_FIELD] = dateOfBirthFormatted;

    // "SurnameAndName": "Ivi\u0107 Ivan (18)", TouristName, TouristSurname
    const surnameAndName = tourist[TOURIST_SURNAME_NAME];
    const surnameAndNameClean = surnameAndName.substring(0, surnameAndName.indexOf('(')).trim();
    const surnameAndNameParts = surnameAndNameClean.split(' ');
    guest[TOURIST_SURNAME] = surnameAndNameParts[0];
    surnameAndNameParts.shift(); // remove first element (surname) from string array
    guest[TOURIST_NAME] = surnameAndNameParts.join(' ');

    // "TravelDocumentTypeNumber": "Ostalo 12345"
    // TODO: handle following use cases
    // tourist: "Putni list za strance 443444"
    // data: Putni list za strance - izdan od RH
    // data: Putni list za strance (strani)
    const travelDocument = tourist[TRAVEL_DOCUMENT_TYPE];
    const documentParts = travelDocument.split(' ');
    const documentNumber = documentParts.pop().trim();
    guest[DOCUMENT_NUMBER] = documentNumber;
    const documentPartsWithoutLastNumber = documentParts.join(' ').trim();

    // "DocumentType": "039"
    for (let document of documents) {
        if (document[NAME] === documentPartsWithoutLastNumber) {
            guest[DOCUMENT_TYPE] = document[CODE];
            break;
        }
    }

    guest[FORESEEN_STAY_UNTIL_FIELD] = getDateFromAspNetFormatToEvisitor(tourist[DATE_TIME_OF_DEPARTURE]);
    guest[TIME_ESTIMATED_STAY_UNTIL_FIELD] = getTimeFromAspNetFormatToEvisitor(tourist[DATE_TIME_OF_DEPARTURE]);
    guest[STAY_FROM_FIELD] = getDateFromAspNetFormatToEvisitor(tourist[STAY_FROM_FIELD]);
    guest[TIME_STAY_FROM_FIELD] = getTimeFromAspNetFormatToEvisitor(tourist[TIME_STAY_FROM_FIELD]);
    return guest;
};

export const getPaymentCategory = birth => {
    if (birth) {
        const dateBirth = getDateFromDateString(birth);
        const age = calculateAge(dateBirth);
        if (age <= 12) {
            return '1';
        } else if (age <= 18) {
            return '2';
        } else {
            return '14';
        }
    }
    return '14';
};

export const isEmpty = empty => Object.keys(empty).length === 0 && empty.constructor === Object;

export const isNotNull = data => data && data !== 'null';

export const isGuestEmpty = guestContent => {
    if (isEmpty(guestContent)) {
        return true;
    }
    // guest is empty but has additionalFields - guest note and date fields
    const numberOfKeys = Object.keys(guestContent).length;
    // possible keys: FORESEEN_STAY_UNTIL_FIELD, TIME_ESTIMATED_STAY_UNTIL_FIELD, STAY_FROM_FIELD, TIME_STAY_FROM_FIELD
    if (
        numberOfKeys <= 4 &&
        guestContent?.[FORESEEN_STAY_UNTIL_FIELD] &&
        guestContent?.[TIME_ESTIMATED_STAY_UNTIL_FIELD]
    ) {
        return true;
    }
    if (numberOfKeys <= 5 && guestContent?.[ADDITIONAL_FIELDS]) {
        return true;
    }
    return false;
};

export const guestHasContentOrNote = guestContent => {
    if (guestContent?.[ADDITIONAL_FIELDS]?.[GUEST_NOTE_FIELD]) return true;
    if (Object.keys(guestContent).length > 1) {
        return true;
    }
    return false;
};

export const isStringNotEmpty = string => string && string !== '';

export const isStringEmptyOrNull = string => !string || (string && string === '');

export const cleanFormData = (data, checkinData) => {
    const copyData = deepCopy(data);
    delete copyData[id];
    delete copyData[CHECKIN_ID];
    delete copyData[STATUS];
    delete copyData[CHECKIN];
    delete copyData[UPDATED_STATUS];
    delete copyData[UPDATED_STATUS];
    delete copyData[INITIAL_GUEST_FIELD];
    const note = copyData[GUEST_NOTE_FIELD];
    delete copyData[GUEST_NOTE_FIELD];
    if (checkinData) {
        copyData[ADDITIONAL_FIELDS] = copyData[ADDITIONAL_FIELDS] || {};
        copyData[ADDITIONAL_FIELDS][CHECKIN_ID] = checkinData[id];
        copyData[ADDITIONAL_FIELDS][SINGLE_GUEST_CHECKIN] =
            checkinData[ADDITIONAL_FIELDS]?.[SINGLE_GUEST_CHECKIN] ?? false;
    }
    if (note) {
        copyData[ADDITIONAL_FIELDS] = copyData[ADDITIONAL_FIELDS] || {};
        copyData[ADDITIONAL_FIELDS][GUEST_NOTE_FIELD] = note;
    }
    return copyData;
};

export const getUrlForQrByID = guestID => {
    let changedGuestID = guestID.replace(/-/g, 'I');
    changedGuestID = changedGuestID.replace(/a/g, 'J');
    changedGuestID = changedGuestID.replace(/1/g, 'L');
    changedGuestID = changedGuestID.replace(/f/g, 'K');
    return changedGuestID;
};

export const isJSON = str => {
    try {
        return JSON.parse(str) && !!str;
    } catch (e) {
        return false;
    }
};

export const getTouristObject = (pinId, facilityID, evaccounts) => {
    for (let evaccount of evaccounts) {
        let objectsDetails = '';
        if (evaccount[PIN_ID] === pinId) {
            objectsDetails = objectsDetails + evaccount[NAME] + ' - ';
            for (let accObj of evaccount[ACCOMODATION_OBJECT]) {
                if (accObj[FACILITY_ID] === facilityID) {
                    objectsDetails = objectsDetails + accObj[NAME_CODE] + ', ';
                    break;
                }
            }
            for (let accUnit of evaccount[ACCOMODATION_UNIT]) {
                if (accUnit[ID] === facilityID) {
                    objectsDetails = objectsDetails + removeParanthesisText(accUnit[NAME]);
                    break;
                }
            }
            return objectsDetails;
        }
    }
    return '';
};

export const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;

export const isString = data => typeof data === 'string' || data instanceof String;

export const mapGender = genderString => {
    return genderString?.includes('M/M') ? GENDERS[MALE] : GENDERS[FEMALE];
};

export const mapGenderMrz = genderString => {
    if (genderString?.includes('<')) return null;
    return genderString?.includes('M') ? GENDERS[MALE] : GENDERS[FEMALE];
};

export const checkStaticData = async () => {
    const storedStaticDataKeys = await AsyncStorage.getItem(EV_STATIC_DATA);
    try {
        JSON.parse(storedStaticDataKeys);
    } catch (e) {
        console.log('Invalid static data, removing entry');
        await AsyncStorage.removeItem(EV_STATIC_DATA);
        return false;
    }
    return storedStaticDataKeys ? true : false;
};

export const getStaticData = async () => {
    const storedStaticDataKeys = await AsyncStorage.getItem(EV_STATIC_DATA);
    let staticData = {};
    if (storedStaticDataKeys) {
        try {
            const dataKeys = JSON.parse(storedStaticDataKeys);
            for (const dataKey of dataKeys) {
                const storedStaticData = await AsyncStorage.getItem(`${EV_STATIC_DATA}_${dataKey}`);
                if (storedStaticData) {
                    staticData[dataKey] = JSON.parse(LZString.decompressFromUTF16(storedStaticData));
                }
            }
            const chunkedData = await getChunkedStorageData();
            if (chunkedData) {
                staticData[SETTLEMENTS_DATA] = chunkedData;
            }
        } catch (e) {
            console.log(e);
        }
    }
    return staticData;
};

const MAX_DATA_CHUNK_LENGTH = 1000;

const naturalSorter = (a, b) =>
    a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: 'base',
    });

// SETTLEMENTS_DATA
const getChunkedStorageData = async () => {
    const keys = await AsyncStorage.getAllKeys(); //
    const resultArray = [];
    const filteredKeys = keys.filter(key => key?.startsWith(`${EV_STATIC_DATA}_${SETTLEMENTS_DATA}`));
    filteredKeys.sort(naturalSorter);
    for (const key of filteredKeys) {
        const storedStaticData = await AsyncStorage.getItem(key);
        if (storedStaticData) {
            const arrayData = JSON.parse(LZString.decompressFromUTF16(storedStaticData));
            if (arrayData) {
                resultArray.push(...arrayData);
            }
        }
    }
    return resultArray;
};

const splitAndStoreLargeArray = async (storageKey, data) => {
    const splitData = splitIntoChunks(data, MAX_DATA_CHUNK_LENGTH);
    // [[1,2,3,4],[44,5,6,7,4]]
    for (const [i, value] of splitData.entries()) {
        await AsyncStorage.setItem(`${storageKey}_${i}`, LZString.compressToUTF16(JSON.stringify(value)));
    }
};

export const storeStaticData = async data => {
    const keys = Object.keys(data);
    if (keys) {
        await AsyncStorage.setItem(EV_STATIC_DATA, JSON.stringify(keys));
        for (const [key, value] of Object.entries(data)) {
            // SettlementLookup is over 6000 entries
            if (value.length > MAX_DATA_CHUNK_LENGTH) {
                await splitAndStoreLargeArray(`${EV_STATIC_DATA}_${key}`, value);
            } else {
                await AsyncStorage.setItem(`${EV_STATIC_DATA}_${key}`, LZString.compressToUTF16(JSON.stringify(value)));
            }
        }
    }
};

export const generateShareUrl = defaultUrl => {
    const envUrl = process.env.REACT_APP_BASE_URL;
    if (Platform.OS === 'web') {
        const port = window.location.port;
        const noPort = !port || port === '' ? true : false;
        if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
            return `${location.protocol}//${location.hostname}${noPort ? '' : ':' + port}`;
        }
    }
    if (envUrl) return envUrl;
    return defaultUrl;
};

const HIDE_UPDATE_CHECKIN_MESSAGES = true;
export const updateMaxGuestsCount = async (checkinId, getCheckin, onUpdateCheckin) => {
    const dbCheckin = await getCheckin(checkinId);
    if (dbCheckin) {
        const filteredGuests = dbCheckin?.[guests] ?? [];
        const currentGuestNumber = filteredGuests.length + 1; // +1 because this is new guest
        const maxGuestNumber = dbCheckin[ADDITIONAL_FIELDS][MAX_GUEST_NUMBER];
        if (maxGuestNumber < currentGuestNumber) {
            dbCheckin[ADDITIONAL_FIELDS][MAX_GUEST_NUMBER] = currentGuestNumber;
            await onUpdateCheckin(dbCheckin, HIDE_UPDATE_CHECKIN_MESSAGES);
        }
    }
};

export const getLastGuestCountryCity = checkin => {
    const guestsArray = checkin?.[guests];
    const guestsArrayLen = guestsArray?.length;
    let preferredCountry = null;
    let preferredCity = null;
    if (guestsArrayLen > 0) {
        const lastGuest = guestsArray[guestsArrayLen - 1];
        if (lastGuest) {
            preferredCountry = {
                countryResidence: lastGuest?.[content]?.[COUNTRY_OF_RESIDENCE],
                countryCitizenship: lastGuest?.[content]?.[CITIZENSHIP],
                countryBirth: lastGuest?.[content]?.[COUNTRY_OF_BIRTH],
            };
            preferredCity = lastGuest?.[content]?.[CITY_OF_RESIDENCE];
        }
    }
    return {preferredCountry, preferredCity};
};

export const isCroatiaCountry = country => country[COUNTRY_CODE] === CROATIA_COUNTRY_CODE;

export const mapPreferredCountries = (countries, preferredCountry) => {
    const {countryResidence, countryCitizenship, countryBirth} = preferredCountry;
    const countryResidenceCode = mapCountryCodeToCountry(countryResidence, countries)?.[COUNTRY_CODE];
    const countryCitizenshipCode = mapCountryCodeToCountry(countryCitizenship, countries)?.[COUNTRY_CODE];
    const countryBirthCode = mapCountryCodeToCountry(countryBirth, countries)?.[COUNTRY_CODE];
    return {countryResidenceCode, countryCitizenshipCode, countryBirthCode};
};

export const isLocalhost = () =>
    location.hostname === 'localhost' || location.hostname === '127.0.0.1' ? true : false;

export const removeDomain = url => {
    try {
        const urlObject = new URL(url);
        return urlObject.pathname;
    } catch (e) {
        console.log(e);
    }
    return url;
};

export const concatFacilityNames = list => {
    let out = '';
    if (list) {
        for (let item of list) {
            out = out + (item?.[NAME] ?? '') + ',';
        }
    }
    return out.slice(0, -1);
};

export const concatAccomodationNames = list => {
    let out = '';
    if (list) {
        for (let item of list) {
            out = out + (item?.[NAME_CODE] ?? '') + ',';
        }
    }
    return out.slice(0, -1);
};

export const generateExternalDocLink = (t, link) => {
    if (Platform.OS === 'web' && isLocalhost()) {
        return `${BASE_URL}/external-docs${removeDomain(t(link))}`;
    }
    return t(link);
};

export const deepCopy = data => {
    if (data) {
        try {
            return JSON.parse(JSON.stringify(data));
        } catch (e) {
            console.log(e);
        }
    }
    return null;
};

export const serializeData = data => {
    if (data) {
        try {
            return JSON.stringify(data);
        } catch (e) {
            console.log(e);
        }
    }
    return null;
};

export const deserializeData = data => {
    if (data) {
        try {
            return JSON.parse(data);
        } catch (e) {
            console.log(e);
        }
    }
    return null;
};

export const generateQRCodeSvg = text => {
    try {
        return new QRCodeGenerator({
            content: text,
            width: 128,
            height: 128,
            color: '#000000',
            background: '#ffffff',
            ecl: 'M',
        }).svg();
    } catch (e) {
        console.log(e);
    }
    return '';
};

export const isShareApiAvailable = Platform.OS !== 'web' || navigator?.share ? true : false;

export const getBoolean = condition => (condition === 'true' ? true : false);

export const getBooleanTrueDefault = condition => (condition === 'false' ? false : true);

export const isCustomOwnerDataSet = storedUserInfo =>
    isStringNotEmpty(storedUserInfo?.[USER_DATA_FORM_PERSONAL_NAME]) ||
    isStringNotEmpty(storedUserInfo?.[USER_DATA_FORM_LEGAL_NAME]);

// en, en => English
export const getLanguageString = (langCode, storedLang) => {
    const lookForLang = storedLang ? storedLang : DEFAULT_LANG;
    return APP_LANGS.find(appLang => appLang[code] === lookForLang)?.[name + '_' + langCode] ?? '';
};

export const getLanguageStringTranslated = (langCode, activeLang) =>
    APP_LANGS.find(appLang => appLang[code] === langCode)?.[name + '_' + activeLang] ?? '';

export const getLanguageStringForSettings = langCode =>
    APP_LANGS.find(appLang => appLang[code] === langCode)?.[name] ?? '';

export const getLanguageCode = langString =>
    APP_LANGS.find(appLang => appLang[name] === langString)?.[code] ?? DEFAULT_LANG;

export const getTranslatedLanguageCode = (langString, activeLang) =>
    APP_LANGS.find(appLang => appLang[name + '_' + activeLang] === langString)?.[code] ?? DEFAULT_LANG;

export const mergeDateTimesCheckin = async (checkinTime, checkin, callback) => {
    const stayUntilDate = await getNightsStayUntil();
    const checkoutTime = await getTimeStayUntil();
    const updatedCheckin = {
        ...checkin,
        [FORESEEN_STAY_UNTIL_FIELD]: checkinTime[FORESEEN_STAY_UNTIL_FIELD] ?? stayUntilDate,
        [STAY_FROM_FIELD]: checkinTime[STAY_FROM_FIELD] ?? getNowDate(),
        [TIME_ESTIMATED_STAY_UNTIL_FIELD]: checkinTime[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? checkoutTime,
        [TIME_STAY_FROM_FIELD]: checkinTime[TIME_STAY_FROM_FIELD] ?? getNowTime(),
    };
    callback(updatedCheckin);
};

export const isManualGuestDate = checkin => checkin?.[ADDITIONAL_FIELDS]?.[MANUAL_GUEST_DATES] ?? false;

export const isManualGuestTime = checkin => checkin?.[ADDITIONAL_FIELDS]?.[MANUAL_GUEST_TIME] ?? false;

export const stringCleaner = string => (string ? string.replace(/["']/g, '') : '');

export const isFacilityError = error => {
    if (error?.[REST_ERRORS]) {
        try {
            const {type, description} = error?.[REST_ERRORS];
            if (
                type === 'EvisitorError' &&
                description?.includes('Objekt') &&
                description?.includes('ne postoji u sustavu')
            ) {
                return true;
            }
        } catch (e) {
            console.log(e);
        }
    }
    return false;
};

export const localeResolver = locale => (locale === HR_LOCALE ? HR_LOCALE_ALT : locale);

export const getUserPdvAndTax = userDataStorage => {
    let userInPdvSystem = false;
    let userDataSojournTax = false;
    if (userDataStorage) {
        const userData = userDataStorage?.[STORAGE_USER_DATA];
        userInPdvSystem = getBoolean(userData[USER_INFO]?.[USER_DATA_FORM_IN_PDV_SYSTEM]);
        userDataSojournTax = getBoolean(userData[USER_INFO]?.[USER_DATA_SOJOURN_TAX] ?? true);
    }
    return {userInPdvSystem, userDataSojournTax};
};

export const isCroatianLang = lang => [HR_LOCALE, HR_LOCALE_ALT].includes(lang);

export const isBirthDayField = field => field === DATE_OF_BIRTH_FIELD;

export const isInvoiceSelected = selectedInvoiceTypeIndex => (selectedInvoiceTypeIndex === 0 ? true : false);
export const isInvoiceTypeProforma = invoice => (invoice?.[INVOICE_TYPE] === INVOICE_TYPE_PROFORMA ? true : false);

export const isGuestSelected = selectedCustomerTypeIndex => (selectedCustomerTypeIndex === 0 ? true : false);
export const isCompanySelected = selectedCustomerTypeIndex => (selectedCustomerTypeIndex === 1 ? true : false);
export const isCompanyType = invoice =>
    invoice?.[INVOICE_FORM_CUSTOMER_TYPE] === INVOICE_FORM_COMPANY_TYPE ? true : false;

export const isPricePerNightSelected = selectedPriceTypeIndex => (selectedPriceTypeIndex === 0 ? true : false);
export const isFinalPriceSelected = selectedPriceTypeIndex => (selectedPriceTypeIndex === 1 ? true : false);

export const getCheckinTime = async () => {
    const isPredefinedCurrentCheckinTime = await getIsCurrentCheckinTimeEnabled();
    if (!isPredefinedCurrentCheckinTime) {
        const time = await getCurrentCheckinTime();
        return time ? time : getNowTime();
    }
    return getNowTime();
};

export const isZeroOrBigger = number => {
    if (number) {
        if (number === '0') return true;
        try {
            const positiveNumber = parseFloat(number);
            return positiveNumber > 0 ? true : false;
        } catch (e) {
            console.log(e);
        }
    }
    return false;
};

export const simpleForm = () => {
    let values = {};

    const getValues = keys => {
        const arrayKeys = Array.isArray(keys) ? keys : [keys];
        const results = [];
        for (const key of arrayKeys) {
            const item = values[key];
            item && results.push(item);
        }
        if (results.length === 0) {
            return null;
        }
        if (results.length === 1) {
            return results[0];
        }
        return results;
    };

    const setValue = (key, value) => {
        values[key] = value;
    };

    const clear = () => {
        values = null;
    };

    return {values, getValues, setValue, clear};
};

export const shouldPerformMigration = (invoice, targetVersion) => {
    const invoiceVersion = invoice[INVOICE_FORM_VER];
    if (invoiceVersion) {
        try {
            const versionNumber = parseInt(invoiceVersion);
            return versionNumber <= targetVersion ? false : true;
        } catch (e) {
            console.log(e);
        }
    }
    return true;
};

export const isReferralRoute = (route, isWeb) => (route?.name === 'Referral' && isWeb ? true : false);

export const getInitialCategory = (route, isWeb) => {
    const initialCategory = isReferralRoute(route, isWeb) ? 2 : 0;
    return initialCategory;
};

export const addRefToUser = (user, isReferralPage) => {
    if (isReferralPage) {
        const refParam = new URLSearchParams(window.location.search)?.get('ref');
        if (refParam) {
            user['ref'] = refParam;
        }
    }
};

export const createRemoteCheckinIfNotExist = async (checkinData, updateLocalCheckin, setProgressOngoing) => {
    const slug = checkinData[SLUG];
    const additionalFields = checkinData[ADDITIONAL_FIELDS];
    const remoteCheckinExistsFlag = checkinData[ADDITIONAL_FIELDS]?.[REMOTE_CHECKIN_EXISTS];
    let isCheckinUpdated = false;
    if (!isNotNull(slug) && !remoteCheckinExistsFlag) {
        const online = await isOnline();
        if (online) {
            setProgressOngoing(true);
            let onlineCheckinExists = false;
            try {
                const remoteCheckin = await fetchCheckinsFromRest(null, {checkinId: checkinData[id]});
                if (remoteCheckin) onlineCheckinExists = true;
            } catch (e) {
                console.log(e);
            }
            if (!onlineCheckinExists) {
                try {
                    const createRes = await saveCheckinRest(checkinData);
                    const remoteSlug = createRes?.[SLUG];
                    if (remoteSlug) {
                        await updateLocalCheckin(
                            {
                                [id]: checkinData[id],
                                [SLUG]: remoteSlug,
                                [ADDITIONAL_FIELDS]: {
                                    ...additionalFields,
                                    [REMOTE_CHECKIN_EXISTS]: true,
                                },
                            },
                            true
                        );
                        isCheckinUpdated = true;
                    }

                    for (const guest of checkinData[guests]) {
                        try {
                            await saveGuestRest(guest);
                        } catch (e) {
                            console.log(e);
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            setProgressOngoing(false);
        }
    }
    return isCheckinUpdated;
};

export const getExportUserData = headerData => {
    // Other owner
    if (headerData[PIN] === '-1')
        return {
            [EXPORT_HEADER_ADDRESS]: '',
            [EXPORT_HEADER_PLACE]: '',
            [EXPORT_HEADER_NAME]: '',
            [EXPORT_HEADER_OIB]: '',
        };
    // Custom owner
    if (headerData[INVOICE_CUSTOM_OWNER_FIELD]) {
        return {
            [EXPORT_HEADER_ADDRESS]: headerData?.[USER_DATA_FORM_ADDRESS] ?? '',
            [EXPORT_HEADER_PLACE]: headerData?.[USER_DATA_FORM_CITY] ?? '',
            [EXPORT_HEADER_NAME]: headerData?.[NAME] ?? '',
            [EXPORT_HEADER_OIB]: headerData?.[PIN] ?? '',
        };
    }
    // Evisitor account owner
    return {
        [EXPORT_HEADER_ADDRESS]: headerData?.[STREET_NUMBER] ?? '',
        [EXPORT_HEADER_PLACE]: getCity(headerData?.[ADDRESS]) ?? '',
        [EXPORT_HEADER_NAME]: headerData?.[NAME] ?? '',
        [EXPORT_HEADER_OIB]: headerData?.[PIN] ?? '',
    };
};

export const checkExcludedOibs = async (evAccount, evaccounts) => {
    if (evAccount[PIN] === '-1') {
        const excludedOibs = evaccounts?.map(e => e[PIN]) ?? [];
        const excludedCustomOib = await getUserOwnerOib();
        if (excludedCustomOib) {
            excludedOibs.push(excludedCustomOib);
        }
        return excludedOibs;
    }
    return null;
};

export const getDefaultIndex = (listItems, match) => {
    let idx = 0;
    for (const item of listItems) {
        if (String(match) === item['text']) {
            return idx;
        }
        idx++;
    }
    return -1;
};

export const getStoredOrDefaultEvAccount = async evaccounts => {
    if (!isEmpty(evaccounts)) {
        const storedEvAccPin = await AsyncStorage.getItem(STORAGE_CHOSEN_EV_ACCOUNT);
        if (storedEvAccPin) {
            const evAccountData = findEvisitorByPin(evaccounts, storedEvAccPin);
            if (evAccountData) {
                return evAccountData;
            }
        }
        return evaccounts[0];
    }
    return null;
};

export const getCountryShortName = (countries, citizenship) => {
    const countryShort =
        countries.find(country => country[NAME_CITIZENSHIP] === citizenship)?.[NAME_NATIONAL_SHORT] ?? null;
    return countryShort;
};
// 0-12, 12-18, 18-30, 30-40, 50-60, 60-75, 75-120
export const getAgeRangeFromName = name => {
    try {
        const ageFirstPart = name.split('(')[1] ?? null;
        const age = ageFirstPart?.split(')')[0] ?? null;
        if (age) {
            const ageNumber = Number(age);
            if (inRange(ageNumber, 0, 12)) return '0-12';
            if (inRange(ageNumber, 12, 18)) return '12-18';
            if (inRange(ageNumber, 18, 30)) return '18-30';
            if (inRange(ageNumber, 30, 40)) return '30-40';
            if (inRange(ageNumber, 40, 50)) return '40-50';
            if (inRange(ageNumber, 50, 60)) return '50-60';
            if (inRange(ageNumber, 60, 75)) return '60-75';
            if (inRange(ageNumber, 75, 120)) return '75-120';
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};
