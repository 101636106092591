import React, {useState, useCallback} from 'react';
import {useBetween} from 'use-between';

const useDialog = () => {
    const [isOpenEvisitorChooser, setIsOpenEvisitorChooser] = useState(false);
    const openEvisitorDialog = useCallback(() => setIsOpenEvisitorChooser(true), []);
    const closeEvisitorDialog = useCallback(() => setIsOpenEvisitorChooser(false), []);
    return {
        isOpenEvisitorChooser,
        openEvisitorDialog,
        closeEvisitorDialog,
    };
};

export const useSharedDialogs = () => useBetween(useDialog);
