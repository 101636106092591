import React, {useState, useCallback} from 'react';
import {useBetween} from 'use-between';

const useErrors = () => {
    const [checkinErrors, setCheckinErrors] = useState(null);
    const clearCheckinErrors = useCallback(() => setCheckinErrors(null), []);

    const [isOpenCheckinErrorMessage, setIsOpenCheckinErrorMessage] = useState(false);
    const openCheckinErrorMsg = useCallback(() => setIsOpenCheckinErrorMessage(true), []);
    const closeCheckinErrorMsg = useCallback(() => setIsOpenCheckinErrorMessage(false), []);

    const [isOpenFacilityErrorMessage, setIsOpenFacilityErrorMessage] = useState(false);
    const openFacilityErrorMsg = useCallback(() => setIsOpenFacilityErrorMessage(true), []);
    const closeFacilityErrorMsg = useCallback(() => setIsOpenFacilityErrorMessage(false), []);

    const closeAllErrors = useCallback(() => {
        clearCheckinErrors();
        closeCheckinErrorMsg();
        closeFacilityErrorMsg();
    }, []);

    return {
        checkinErrors,
        clearCheckinErrors,
        isOpenCheckinErrorMessage,
        closeCheckinErrorMsg,
        closeAllErrors,
        setCheckinErrors,
        openCheckinErrorMsg,
        isOpenFacilityErrorMessage,
        openFacilityErrorMsg,
        closeFacilityErrorMsg,
    };
};

export const useSharedErrors = () => useBetween(useErrors);
