import 'react-native-gesture-handler';
import {enableLegacyWebImplementation} from 'react-native-gesture-handler';
enableLegacyWebImplementation(true);
//import './wdyr'; // <--- first import

import 'intl';
import 'intl/locale-data/jsonp/en';
import 'react-native-get-random-values';

import {createRoot} from 'react-dom/client';
import {createElement} from 'react';

import App from './src/App';
import './src/web/index.css';

const rootTag = createRoot(document.getElementById('root') ?? document.getElementById('main'));
rootTag.render(createElement(App));
