import React from 'react';
import {FlatList, View, Text, useWindowDimensions} from 'react-native';
import {useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {useTranslation} from 'react-i18next';
import GuestItem from './GuestItem';
import {memo, useMemo} from 'react';
import {useSharedDialogs} from './useDialogs';
import {NO_TOURISTS} from '../../constants/stringsAndFields';
import {checkinsOrGuestsSorter} from '../../utils/arrayHelpers';

const GuestList = ({checkin, onDeleteCheckinGuest, checkinDetailHeader}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const {t} = useTranslation();
    const width = useWindowDimensions().width;
    const {openQRDetails, updateGuestId, sortGuestsBy} = useSharedDialogs();

    const guests = checkinsOrGuestsSorter(checkin?.['guests'] ?? [], sortGuestsBy);

    const memoizedContainerStyle = useMemo(() => {
        const isDesktop = width > 1500;
        const global = globalStyle(theme);
        return [
            global.flatList,
            global.hasFAB,
            {flexGrow: 1, width: '100%', maxWidth: 1100, alignSelf: 'center'},
            isDesktop && {paddingTop: 25},
        ];
    }, [width, theme]);

    const render = ({item}) => (
        <GuestItem
            guest={item}
            checkin={checkin}
            onDeleteCheckinGuest={onDeleteCheckinGuest}
            openQRDetails={openQRDetails}
            updateGuestId={updateGuestId}
        />
    );

    const emptyList = <MemoizedEmptyList t={t} global={global} />;

    return (
        <FlatList
            data={guests}
            renderItem={render}
            ListHeaderComponent={checkinDetailHeader}
            contentContainerStyle={memoizedContainerStyle}
            ListEmptyComponent={emptyList}
        />
    );
};

export default GuestList;

const EmptyList = ({t, global}) => (
    <View style={global.listEmptyMsg}>
        <Text style={global.listEmptyMsgText}>{t(NO_TOURISTS)}</Text>
    </View>
);
export const MemoizedEmptyList = memo(EmptyList);
