import React, {useState, useCallback} from 'react';
import {useBetween} from 'use-between';

const useDialog = () => {
    const [isOpenEvisitorChooser, setIsOpenEvisitorChooser] = useState(false);
    const openEvisitorDialog = useCallback(() => setIsOpenEvisitorChooser(true), []);
    const closeEvisitorDialog = useCallback(() => setIsOpenEvisitorChooser(false), []);

    const [isOpenQRDetails, setIsOpenQRDetails] = useState(false); // QR code details
    const openQRDetails = useCallback(_ => setIsOpenQRDetails(true), []);
    const closeQRDetails = useCallback(() => setIsOpenQRDetails(false), []);

    const [guestId, setGuestId] = useState(null); // used for QR dialog
    const updateGuestId = useCallback(id => setGuestId(id), []);

    return {
        isOpenEvisitorChooser,
        openEvisitorDialog,
        closeEvisitorDialog,
        isOpenQRDetails,
        openQRDetails,
        closeQRDetails,
        guestId,
        updateGuestId,
    };
};

export const useSharedDialogs = () => useBetween(useDialog);
