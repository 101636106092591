import React, {useEffect} from 'react';
import {View} from 'react-native';
import {Button, Icon} from 'react-native-elements';
import {
    ADDITIONAL_FIELDS,
    CHECKIN_ACTION,
    CHECKIN_BUTTON,
    CHECKOUT_ACTION,
    CHECKOUT_BUTTON,
    MAX_GUEST_NUMBER,
    guests,
} from '../../constants/stringsAndFields';
import {setCheckinActionsCount} from '../../utils/guestUtils';
import useStyles from './FooterActionButtons.style';

const FooterActionButtons = ({t, theme, global, isMobile, isMobileSmall, checkin, useSharedCheckinActions}) => {
    const styles = useStyles(theme);
    const {
        isCheckoutVisible,
        isCheckinVisible,
        actionsCount,
        showShare,
        hideShare,
        showActionsDialog,
        showCheckout,
        hideCheckout,
        showCheckin,
        hideCheckin,
        setActionsCount,
    } = useSharedCheckinActions();

    const bottomActionButtonsList = [];
    const openCheckinActions = _ => showActionsDialog(CHECKIN_ACTION);
    const openCheckoutActions = _ => showActionsDialog(CHECKOUT_ACTION);

    useEffect(() => {
        if (checkin) setCheckinActionsCount(checkin, setActionsCount);
    }, [JSON.stringify(checkin?.[guests])]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let _ = actionsCount[CHECKIN_ACTION] > 0 ? hideCheckin() : showCheckin();
        // eslint-disable-next-line no-unused-vars
        _ = actionsCount[CHECKOUT_ACTION] > 0 ? hideCheckout() : showCheckout();
        // eslint-disable-next-line no-unused-vars
        if (checkin) {
            const guestNumber = checkin?.[guests]?.length;
            if (checkin?.[ADDITIONAL_FIELDS]?.[MAX_GUEST_NUMBER] === guestNumber) {
                showShare();
            } else {
                hideShare();
            }
        }
    }, [actionsCount, checkin]);

    useEffect(() => {
        if (checkin) {
            const guestNumber = checkin?.[guests]?.length;
            if (checkin?.[ADDITIONAL_FIELDS]?.[MAX_GUEST_NUMBER] === guestNumber) {
                showShare();
            } else {
                hideShare();
            }
        }
    }, [checkin?.[ADDITIONAL_FIELDS]?.[MAX_GUEST_NUMBER]]); // eslint-disable-line react-hooks/exhaustive-deps

    // this components can be written directyl without list
    if (!isCheckinVisible) {
        bottomActionButtonsList.push(
            <Button
                title={`${t(CHECKIN_BUTTON)} ${actionsCount?.[CHECKIN_ACTION]}`}
                key={CHECKIN_BUTTON}
                containerStyle={[
                    global.outlineButtonContainer,
                    styles.containerStyle,
                    isMobile ? styles.containerStyleMobile : styles.containerStyleDesktop,
                ]}
                buttonStyle={[
                    global.outlineButton,
                    styles.buttonStyle,
                    isMobileSmall && !isCheckoutVisible && styles.buttonStyleMobile,
                ]}
                titleStyle={[
                    global.outlineButtonTitle,
                    styles.titleStyle,
                    isMobileSmall && !isCheckoutVisible && styles.titleStyleMobile,
                ]}
                icon={
                    <Icon
                        type="ionicon"
                        name="person-add"
                        size={isMobileSmall && !isCheckoutVisible ? 16 : 18}
                        color={theme.colors.primary}
                        containerStyle={[
                            isMobileSmall && isCheckoutVisible && {marginRight: 10},
                            !isMobileSmall && {marginRight: 10},
                        ]}
                    />
                }
                onPress={openCheckinActions}
            />
        );
    }
    if (!isCheckoutVisible) {
        bottomActionButtonsList.push(
            <Button
                title={`${t(CHECKOUT_BUTTON)} ${actionsCount?.[CHECKOUT_ACTION]}`}
                key={CHECKOUT_BUTTON}
                containerStyle={[
                    global.outlineButtonContainer,
                    styles.containerStyle,
                    isMobile ? styles.containerStyleMobile : styles.containerStyleDesktop,
                ]}
                buttonStyle={[
                    global.outlineButton,
                    styles.buttonStyle,
                    {
                        backgroundColor: theme.colors.white,
                    },
                    isMobileSmall && !isCheckinVisible && styles.buttonStyleMobile,
                ]}
                titleStyle={[
                    global.outlineButtonTitle,
                    styles.titleStyle,
                    isMobileSmall && !isCheckinVisible && styles.titleStyleMobile,
                ]}
                icon={
                    <Icon
                        type="ionicon"
                        name="person-remove-outline"
                        size={isMobileSmall && !isCheckinVisible ? 16 : 18}
                        color={theme.colors.primary}
                        containerStyle={[
                            isMobileSmall && isCheckinVisible && {marginRight: 10},
                            !isMobileSmall && {marginRight: 10},
                        ]}
                    />
                }
                onPress={openCheckoutActions}
            />
        );
    }

    return (
        <View
            style={[
                styles.footerActionButtonsWrapper,
                isMobile && {paddingRight: 170},
                isMobile && {justifyContent: 'flex-start'},
            ]}>
            {bottomActionButtonsList}
        </View>
    );
};

export default FooterActionButtons;
