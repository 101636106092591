import {ADDITIONAL_FIELDS, EVISITOR, id, PIN_ID} from '../../constants/stringsAndFields';

export const openCameraAction = async (navigation, closeFab, checkin) => {
    closeFab();
    navigation.navigate('Scanner', {checkinId: checkin[id]});
};

export const openGalleryImportAction = async (navigation, closeFab, checkin) => {
    closeFab();
    navigation.navigate('Scanner', {checkinId: checkin[id], importFromGallery: 'true'});
};

export const onAddNewGuestClickAction = async (navigation, closeFab, checkin) => {
    closeFab();
    navigation.navigate('Guest', {checkinId: checkin[id], isNewGuest: true, manualInput: true});
};

export const onAddExistingGuestClickAction = async (navigation, closeFab, checkin) => {
    closeFab();
    const eVisitorPinId = checkin?.[ADDITIONAL_FIELDS]?.[EVISITOR]?.[PIN_ID];
    const cId = checkin?.[id] ?? checkinId;
    if (eVisitorPinId) {
        navigation.navigate('TouristsChooser', {
            checkinId: cId,
            eVisitorPinId: eVisitorPinId,
        });
    }
};
