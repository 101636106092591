import {LocaleConfig} from 'react-native-calendars';
import {
    registerTranslation,
    ar,
    de,
    en,
    enGB,
    fr,
    it,
    es,
    he,
    hi,
    ko,
    nl,
    pl,
    tr,
    cz,
    hu,
    pt,
    zh,
    zhTW,
} from 'react-native-paper-dates';
import {
    CLOSE,
    DATE_FORMAT_MUST_BE,
    DAY_IS_NOT_ALLOWED,
    MUST_BE_BETWEEN,
    MUST_BE_EARLIER_THEN,
    MUST_BE_LATER_THEN,
    NEXT,
    PICK_DATE_FROM_CALENDAR,
    PREVIOUS,
    SAVE_DATA,
    SELECT_DATE,
    SELECT_DATES,
    SELECT_ARRIVAL_DEPARTURE,
    TYPE_IN_DATE,
} from '../constants/stringsAndFields';

/* warning!
these do not exist in imports definition!
    ['cz', cz],
    ['hu', hu],
*/

const locales = [
    ['en', en],
    ['nl', nl],
    ['cz', cz],
    ['de', de],
    ['hu', hu],
    ['pl', pl],
    ['pt', pt],
    ['ar', ar],
    ['ko', ko],
    ['fr', fr],
    ['he', he],
    ['hi', hi],
    ['tr', tr],
    ['en-GB', enGB],
    ['zh', zh],
    ['zh-TW', zhTW],
];

export const registerPickerLang = i18n => {
    // Paper Date Picker
    locales.forEach(locale => {
        registerTranslation(locale[0], locale[1]);
    });

    const t = i18n.getFixedT('hr');
    registerTranslation('hr', {
        save: t(SAVE_DATA),
        selectSingle: t(SELECT_DATE),
        selectMultiple: t(SELECT_DATES),
        selectRange: t(SELECT_ARRIVAL_DEPARTURE),
        notAccordingToDateFormat: inputFormat => `${t(DATE_FORMAT_MUST_BE)} ${inputFormat}`,
        mustBeHigherThan: date => `${t(MUST_BE_LATER_THEN)} ${date}`,
        mustBeLowerThan: date => `${t(MUST_BE_EARLIER_THEN)} ${date}`,
        mustBeBetween: (startDate, endDate) => `${t(MUST_BE_BETWEEN)} ${startDate} - ${endDate}`,
        dateIsDisabled: t(DAY_IS_NOT_ALLOWED),
        previous: t(PREVIOUS),
        next: t(NEXT),
        typeInDate: t(TYPE_IN_DATE),
        pickDateFromCalendar: t(PICK_DATE_FROM_CALENDAR),
        close: t(CLOSE),
    });

    // Calendar (Checkins)
    LocaleConfig.locales['bg'] = {
        formatAccessibilityLabel: "dd 'от' MM 'от' yyyy",
        monthNames: [
            'януари',
            'февруари',
            'март',
            'април',
            'май',
            'юни',
            'юли',
            'август',
            'септември',
            'октомври',
            'ноември',
            'декември',
        ],
        monthNamesShort: ['яну', 'фев', 'март', 'апр', 'май', 'юни', 'юли', 'авг', 'сеп', 'окт', 'ноем', 'дек'],
        dayNames: ['неделя', 'понеделник', 'вторник', 'сряда', 'четвъртък', 'петък', 'събота'],
        dayNamesShort: ['Н', 'П', 'В', 'С', 'Ч', 'П', 'С'],
        today: 'Днес',
    };

    LocaleConfig.locales['cn'] = {
        formatAccessibilityLabel: "dd 'of' MM 'of' yyyy",
        monthNames: [
            '一月',
            '二月',
            '三月',
            '四月',
            '五月',
            '六月',
            '七月',
            '八月',
            '九月',
            '十月',
            '十一月',
            '十二月',
        ],
        monthNamesShort: [
            '一月',
            '二月',
            '三月',
            '四月',
            '五月',
            '六月',
            '七月',
            '八月',
            '九月',
            '十月',
            '十一月',
            '十二月',
        ],
        dayNames: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
        dayNamesShort: ['日', '一', '二', '三', '四', '五', '六'],
        today: '今天',
    };
    LocaleConfig.locales['cz'] = {
        formatAccessibilityLabel: "dd 'of' MM 'of' yyyy",
        monthNames: [
            'leden',
            'únor',
            'březen',
            'duben',
            'květen',
            'červen',
            'červenec',
            'srpen',
            'září',
            'říjen',
            'listopad',
            'prosinec',
        ],
        monthNamesShort: ['led', 'úno', 'bře', 'dub', 'kvě', 'čer', 'čvc', 'srp', 'zář', 'říj', 'lis', 'pro'],
        dayNames: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
        dayNamesShort: ['N', 'P', 'Ú', 'S', 'Č', 'P', 'S'],
        today: 'Dnes',
    };
    LocaleConfig.locales['de'] = {
        formatAccessibilityLabel: "dd 'von' MM 'von' yyyy",
        monthNames: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember',
        ],
        monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        today: 'Heute',
    };
    LocaleConfig.locales['ee'] = {
        formatAccessibilityLabel: "dd 'ja' MM 'ja' yyyy",
        monthNames: [
            'jaanuar',
            'veebruar',
            'märts',
            'aprill',
            'mai',
            'juuni',
            'juuli',
            'august',
            'september',
            'oktoober',
            'november',
            'detsember',
        ],
        monthNamesShort: ['jaan', 'veeb', 'märts', 'apr', 'mai', 'juuni', 'juuli', 'aug', 'sept', 'okt', 'nov', 'dets'],
        dayNames: ['pühapäev', 'esmaspäev', 'teisipäev', 'kolmapäev', 'neljapäev', 'reede', 'laupäev'],
        dayNamesShort: ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
        today: 'Täna',
    };
    LocaleConfig.locales['en'] = {
        formatAccessibilityLabel: "dd 'of' MM 'of' yyyy",
        monthNames: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        today: 'Today',
    };
    LocaleConfig.locales['es'] = {
        formatAccessibilityLabel: "dd 'de' MM 'de' yyyy",
        monthNames: [
            'enero',
            'febrero',
            'marzo',
            'abril',
            'mayo',
            'junio',
            'julio',
            'agosto',
            'septiembre',
            'octubre',
            'noviembre',
            'diciembre',
        ],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        today: 'Hoy',
    };
    LocaleConfig.locales['fr'] = {
        formatAccessibilityLabel: "dd 'de' MM 'de' yyyy",
        monthNames: [
            'janvier',
            'février',
            'mars',
            'avril',
            'mai',
            'juin',
            'juillet',
            'août',
            'septembre',
            'octobre',
            'novembre',
            'décembre',
        ],
        monthNamesShort: ['janv', 'févr', 'mars', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc'],
        dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        dayNamesShort: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        today: "Aujourd'hui",
    };
    LocaleConfig.locales['hr'] = {
        monthNames: [
            'Siječanj',
            'Veljača',
            'Ožujak',
            'Travanj',
            'Svibanj',
            'Lipanj',
            'Srpanj',
            'Kolovoz',
            'Rujan',
            'Listopad',
            'Studeni',
            'Prosinac',
        ],
        monthNamesShort: ['Sij.', 'Velj.', 'Ožu', 'Tra', 'Svi', 'Lip', 'Srp.', 'Kol', 'Ruj.', 'Lis.', 'Stu.', 'Pro.'],
        dayNames: ['Nedjelja', 'Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota'],
        dayNamesShort: ['Ned.', 'Pon.', 'Ut.', 'Sri.', 'Čet.', 'Pet.', 'Sub.'],
        today: 'Danas',
    };
    LocaleConfig.locales['hu'] = {
        formatAccessibilityLabel: "dd.'év' MM. 'hó' yyyy.",
        monthNames: [
            'január',
            'február',
            'március',
            'április',
            'május',
            'június',
            'július',
            'augusztus',
            'szeptember',
            'október',
            'november',
            'december',
        ],
        monthNamesShort: ['jan', 'feb', 'már', 'ápr', 'máj', 'jún', 'júl', 'aug', 'szept', 'okt', 'nov', 'dec'],
        dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
        dayNamesShort: ['V', 'H', 'K', 'Sze', 'Cs', 'P', 'Szo'],
        today: 'Ma',
    };
    LocaleConfig.locales['ia'] = {
        formatAccessibilityLabel: "dd 'का' MM 'का' yyyy",
        monthNames: [
            'जनवरी',
            'फरवरी',
            'मार्च',
            'अप्रैल',
            'मई',
            'जून',
            'जुलाई',
            'अगस्त',
            'सितम्बर',
            'अक्टूबर',
            'नवम्बर',
            'दिसम्बर',
        ],
        monthNamesShort: ['जन', 'फ़र', 'मार्च', 'अप्रै', 'मई', 'जून', 'जुल', 'अग', 'सित', 'अक्ट', 'नव', 'दिस'],
        dayNames: ['रविवार', 'सोमवार', 'मंगलवार', 'बुधवार', 'गुरुवार', 'शुक्रवार', 'शनिवार'],
        dayNamesShort: ['र', 'सो', 'मं', 'बु', 'गु', 'शु', 'श'],
        today: 'आज',
    };
    LocaleConfig.locales['it'] = {
        formatAccessibilityLabel: "dd 'di' MM 'di' yyyy",
        monthNames: [
            'gennaio',
            'febbraio',
            'marzo',
            'aprile',
            'maggio',
            'giugno',
            'luglio',
            'agosto',
            'settembre',
            'ottobre',
            'novembre',
            'dicembre',
        ],
        monthNamesShort: ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'],
        dayNames: ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'],
        dayNamesShort: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],
        today: 'Oggi',
    };
    LocaleConfig.locales['jp'] = {
        formatAccessibilityLabel: "dd 'of' MM 'of' yyyy",
        monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        dayNames: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
        dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
        today: '今日',
    };
    LocaleConfig.locales['kr'] = {
        formatAccessibilityLabel: "dd 'of' MM 'of' yyyy",
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        dayNames: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        today: '오늘',
    };
    LocaleConfig.locales['lt'] = {
        formatAccessibilityLabel: "dd 'of' MM 'of' yyyy",
        monthNames: [
            'sausis',
            'vasaris',
            'kovas',
            'balandis',
            'gegužė',
            'birželis',
            'liepa',
            'rugpjūtis',
            'rugsėjis',
            'spalis',
            'lapkritis',
            'gruodis',
        ],
        monthNamesShort: ['sau', 'vas', 'kov', 'bal', 'geg', 'bir', 'lie', 'rgp', 'rgs', 'spl', 'lap', 'grd'],
        dayNames: [
            'sekmadienis',
            'pirmadienis',
            'antradienis',
            'trečiadienis',
            'ketvirtadienis',
            'penktadienis',
            'šeštadienis',
        ],
        dayNamesShort: ['S', 'P', 'A', 'T', 'K', 'Pn', 'Š'],
        today: 'Šiandien',
    };
    LocaleConfig.locales['lv'] = {
        formatAccessibilityLabel: "dd 'of' MM 'of' yyyy",
        monthNames: [
            'janvāris',
            'februāris',
            'marts',
            'aprīlis',
            'maijs',
            'jūnijs',
            'jūlijs',
            'augusts',
            'septembris',
            'oktobris',
            'novembris',
            'decembris',
        ],
        monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'mai', 'jūn', 'jūl', 'aug', 'sep', 'okt', 'nov', 'dec'],
        dayNames: ['svētdiena', 'pirmdiena', 'otrdiena', 'trešdiena', 'ceturtdiena', 'piektdiena', 'sestdiena'],
        dayNamesShort: ['Sv', 'P', 'O', 'T', 'C', 'Pk', 'S'],
        today: 'Šodien',
    };
    LocaleConfig.locales['pl'] = {
        formatAccessibilityLabel: "dd 'of' MM 'of' yyyy",
        monthNames: [
            'styczeń',
            'luty',
            'marzec',
            'kwiecień',
            'maj',
            'czerwiec',
            'lipiec',
            'sierpień',
            'wrzesień',
            'październik',
            'listopad',
            'grudzień',
        ],
        monthNamesShort: ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paź', 'lis', 'gru'],
        dayNames: ['niedziela', 'poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota'],
        dayNamesShort: ['N', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'Sob'],
        today: 'Dzisiaj',
    };
    LocaleConfig.locales['ro'] = {
        formatAccessibilityLabel: "dd 'din' MM 'din' yyyy",
        monthNames: [
            'Ianuarie',
            'Februarie',
            'Martie',
            'Aprilie',
            'Mai',
            'Iunie',
            'Iulie',
            'August',
            'Septembrie',
            'Octombrie',
            'Noiembrie',
            'Decembrie',
        ],
        monthNamesShort: ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        dayNames: ['Duminică', 'Luni', 'Marți', 'Miercuri', 'Joi', 'Vineri', 'Sâmbătă'],
        dayNamesShort: ['D', 'L', 'Ma', 'Mi', 'J', 'V', 'S'],
        today: 'Astăzi',
    };
    LocaleConfig.locales['ru'] = {
        formatAccessibilityLabel: "dd 'от' MM 'от' yyyy",
        monthNames: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
        ],
        monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        today: 'Сегодня',
    };
    LocaleConfig.locales['si'] = {
        formatAccessibilityLabel: "dd 'od' MM 'od' yyyy",
        monthNames: [
            'Januar',
            'Februar',
            'Marec',
            'April',
            'Maj',
            'Junij',
            'Julij',
            'Avgust',
            'September',
            'Oktober',
            'November',
            'December',
        ],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'],
        dayNames: ['Nedelja', 'Ponedeljek', 'Torek', 'Sreda', 'Četrtek', 'Petek', 'Sobota'],
        dayNamesShort: ['N', 'P', 'T', 'S', 'Č', 'P', 'S'],
        today: 'Danes',
    };
    LocaleConfig.locales['sk'] = {
        formatAccessibilityLabel: "dd 'z' MM 'z' yyyy",
        monthNames: [
            'Január',
            'Február',
            'Marec',
            'Apríl',
            'Máj',
            'Jún',
            'Júl',
            'August',
            'September',
            'Október',
            'November',
            'December',
        ],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        dayNames: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
        dayNamesShort: ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pi', 'So'],
        today: 'Dnes',
    };
    LocaleConfig.locales['tr'] = {
        formatAccessibilityLabel: "dd 'nın' MM 'nın' yyyy",
        monthNames: [
            'Ocak',
            'Şubat',
            'Mart',
            'Nisan',
            'Mayıs',
            'Haziran',
            'Temmuz',
            'Ağustos',
            'Eylül',
            'Ekim',
            'Kasım',
            'Aralık',
        ],
        monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
        dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
        dayNamesShort: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
        today: 'Bugün',
    };
    LocaleConfig.locales['ua'] = {
        formatAccessibilityLabel: "dd 'з' MM 'року' yyyy",
        monthNames: [
            'Січень',
            'Лютий',
            'Березень',
            'Квітень',
            'Травень',
            'Червень',
            'Липень',
            'Серпень',
            'Вересень',
            'Жовтень',
            'Листопад',
            'Грудень',
        ],
        monthNamesShort: ['Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру'],
        dayNames: ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П’ятниця', 'Субота'],
        dayNamesShort: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        today: 'Сьогодні',
    };
};

export const registerCalendarLocale = currentLang => {
    LocaleConfig.defaultLocale = [
        'bg',
        'cn',
        'cz',
        'de',
        'ee',
        'en',
        'es',
        'fr',
        'hr',
        'hu',
        'ia',
        'it',
        'jp',
        'kr',
        'lt',
        'lv',
        'ro',
        'ru',
        'si',
        'sk',
        'tr',
        'ua',
    ].includes(currentLang)
        ? currentLang
        : 'hr';
};
