import React, {useState, useRef} from 'react';
import {Slider, Text, Button, Icon, useTheme} from 'react-native-elements';
import globalStyle from '../theme/globalStyle';
import {View, ScrollView} from 'react-native';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {Document, Page} from 'react-pdf';
import {useWindowDimensions} from '../utils/withWindowDimensions';
import {useTranslation} from 'react-i18next';
import {useReactToPrint} from 'react-to-print';
import {PRINT, ZOOM} from '../constants/stringsAndFields';

const PdfViewer = ({documentUrl}) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const {t} = useTranslation();
    const [pageNumber, setPageNumber] = useState(1);
    const [zoom, setZoom] = useState(1);
    const {theme} = useTheme();
    const global = globalStyle(theme);

    const {windowWidth, windowHeight} = useWindowDimensions();

    const interpolate = (start, end) => {
        let k = (zoom - 0) / 10; // 0 =>min  && 10 => MAX
        return Math.ceil((1 - k) * start + k * end) % 256;
    };

    const color = () => {
        let r = interpolate(255, 0);
        let g = interpolate(0, 255);
        let b = interpolate(0, 0);
        return `rgb(${r},${g},${b})`;
    };

    const onPrint = () => {
        handlePrint();
    };

    const onZoomChange = val => {
        setZoom(val);
    };

    const PrintPdf = () => (
        <div hidden>
            <Document inputRef={componentRef} file={documentUrl}>
                <Page pageNumber={pageNumber} />
            </Document>
        </div>
    );

    return (
        <View>
            <ScrollView horizontal={true}>
                <Document file={documentUrl}>
                    <Page pageNumber={pageNumber} scale={zoom} width={windowWidth - 20} />
                </Document>
            </ScrollView>

            <View style={{paddingTop: 10, paddingBottom: 30, paddingHorizontal: 20}}>
                <Text style={[global.textMain, {marginBottom: 10}]}>
                    {t(ZOOM)}: {zoom}x
                </Text>
                <View style={{marginHorizontal: 14}}>
                    <Slider
                        value={zoom}
                        onValueChange={onZoomChange}
                        maximumValue={4}
                        minimumValue={1}
                        step={0.5}
                        allowTouchTrack
                        trackStyle={{height: 5, backgroundColor: 'transparent'}}
                        thumbStyle={{height: 20, width: 20, backgroundColor: 'transparent'}}
                        thumbProps={{
                            children: (
                                <Icon
                                    name={'zoom-in'}
                                    size={20}
                                    reverse
                                    containerStyle={{bottom: 20, right: 20}}
                                    color={color()}
                                />
                            ),
                        }}
                    />
                </View>

                <Button
                    onPress={onPrint}
                    icon={{
                        name: 'print',
                        size: 15,
                        color: 'white',
                        containerStyle: {marginLeft: 0, marginRight: 5},
                    }}
                    title={t(PRINT)}
                    style={{alignSelf: 'center', marginTop: 10}}
                    containerStyle={global.Button.containerStyle}
                    buttonStyle={global.Button.buttonStyle}
                    titleStyle={global.Button.titleStyle}
                />
            </View>
            <PrintPdf />
        </View>

        /*<View>
            <ScrollView style={{height: windowHeight - 300, width: windowWidth - 20}}>
                <ScrollView horizontal={true}>
                    <View style={styles.pdfDocView}>
                        <Document file={documentUrl}>
                            <Page pageNumber={pageNumber} scale={zoom} width={windowWidth - 24} />
                        </Document>
                    </View>
                </ScrollView>
            </ScrollView>

            <View style={styles.contentView}>
                <Text>{t(ZOOM)}</Text>
                <Slider
                    value={zoom}
                    onValueChange={onZoomChange}
                    maximumValue={4}
                    minimumValue={1}
                    step={0.5}
                    allowTouchTrack
                    trackStyle={{height: 5, backgroundColor: 'transparent'}}
                    thumbStyle={{height: 20, width: 20, backgroundColor: 'transparent'}}
                    thumbProps={{
                        children: (
                            <Icon
                                name={'zoom-in'}
                                size={20}
                                reverse
                                containerStyle={{bottom: 20, right: 20}}
                                color={color()}
                            />
                        ),
                    }}
                />
                <Text style={{paddingTop: 20}}>Value: {zoom}</Text>
            </View>
            <Button
                onPress={onPrint}
                icon={{
                    name: 'print',
                    size: 15,
                    color: 'white',
                }}
                title={t(PRINT)}
            />
            <PrintPdf />
        </View>*/
    );
};

export default PdfViewer;
