import {getUniqueId} from 'react-native-device-info';
import {getXDevice} from './network';

// Platform.OS
// iOS: "FCDBD8EF-62FC-4ECB-B2F5-92C9E79AC7F9"
// Android: "dd96dec43fb81c97"
// Windows: "{2cf7cb3c-da7a-d508-0d7f-696bb51185b4}"
export const getDeviceId = async () => {
    let uniqueId = '';
    try {
        uniqueId = await getUniqueId();
    } catch (e) {
        console.log(e);
    }
    return uniqueId === 'unknown' ? getXDevice() : uniqueId;
};
