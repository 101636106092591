import Clipboard from '@react-native-clipboard/clipboard';
import React, {useState, useRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Pressable, useWindowDimensions} from 'react-native';
import {Button, Input, Text, Icon, useTheme} from 'react-native-elements';
import globalStyle from '../../theme/globalStyle';
import {
    APP_LANGS,
    CHECKIN_LINK,
    EMAIL_BODY_SELF_CHECKIN,
    id,
    LANGUAGE,
    LANGUAGE_EN_CODE,
    PRODUCTION_BACKEND,
    SHARE_BUTTON_COPY_TEXT,
    SHARE_BUTTON_TEXT,
    SHARE_SELF_CHECKIN_DIALOG_TITLE,
    SHARE_TEST_LINK,
    SHARE_SELF_CHECKIN_TITLE,
    SLUG,
} from '../../constants/stringsAndFields';
import {generateShareUrl, isNotNull, isShareApiAvailable} from '../../utils/helpers';
import LangSelect from '../LangSelect';
import Modal from 'react-native-modal';
import {share} from '../../utils/share';

const LinkDialog = ({isOpen, setOpenShare, checkin, onOpenLink = null}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const [lang, setLang] = useState(LANGUAGE_EN_CODE);
    const {t} = useTranslation();
    const width = useWindowDimensions().width;
    const notDesktop = width < theme.desktopBP;
    const [checkinUrl, setCheckinUrl] = useState(null);
    const [openLangSelect, setOpenLangSelect] = useState(false);
    const langInputRef = useRef(null);
    const slug = checkin?.[SLUG];

    const handleClose = () => {
        setOpenShare(false);
    };

    const onCopy = () => {
        try {
            Clipboard.setString(checkinUrl);
        } catch (err) {
            console.log(err);
        } finally {
            handleClose();
        }
    };

    const onShare = async () => {
        await share(SHARE_SELF_CHECKIN_TITLE, EMAIL_BODY_SELF_CHECKIN, checkinUrl);
        handleClose();
    };

    const onLangSelected = ({name, code}) => {
        setLang(name);
        let url = `${generateShareUrl(PRODUCTION_BACKEND)}/self-checkin?checkinId=${checkin?.[id]}&lang=${code}`;
        if (isNotNull(slug)) {
            url = `${PRODUCTION_BACKEND}/sc/${slug}?lang=${code}`;
        }
        setCheckinUrl(url);
    };

    const openLangSelectModal = _ => {
        setOpenLangSelect(true);
    };

    useEffect(() => {
        if (checkin) {
            let url = `${generateShareUrl(PRODUCTION_BACKEND)}/self-checkin?checkinId=${checkin?.[id]}&lang=${lang}`;
            if (isNotNull(slug)) {
                url = `${PRODUCTION_BACKEND}/sc/${slug}?lang=${lang}`;
            }
            setCheckinUrl(url);
        }
    }, [checkin]);

    return (
        <Modal
            isVisible={isOpen}
            onBackdropPress={handleClose}
            onBackButtonPress={handleClose}
            animationInTiming={1}
            animationOutTiming={1}
            backdropOpacity={0.6}>
            <View style={global.modalDialog}>
                <View style={global.modalTitle}>
                    <Text style={global.modalTitleText}>{t(SHARE_SELF_CHECKIN_DIALOG_TITLE)}</Text>
                    <Pressable onPress={handleClose} style={global.modalCloseButton}>
                        <Icon
                            name="close"
                            containerStyle={global.modalCloseIcon}
                            color={theme.colors.white}
                            size={20}
                        />
                    </Pressable>
                </View>

                <View style={global.modalContent}>
                    <Input
                        showSoftInputOnFocus={false}
                        ref={langInputRef}
                        caretHidden={true}
                        label={t(LANGUAGE)}
                        value={lang}
                        onFocus={_ => {
                            langInputRef.current.blur();
                            openLangSelectModal();
                        }}
                    />
                    <LangSelect
                        isOpen={openLangSelect}
                        setOpenLangSelect={setOpenLangSelect}
                        onLangSelected={onLangSelected}
                        langList={APP_LANGS}
                    />
                    <Input
                        label={t(CHECKIN_LINK)}
                        value={checkinUrl}
                        inputContainerStyle={{marginBottom: 0}}
                        disabled
                    />
                </View>
                <View style={[global.modalFooter, {flexDirection: 'column'}]}>
                    {notDesktop && isShareApiAvailable && (
                        <Button
                            onPress={onShare}
                            title={t(SHARE_BUTTON_TEXT)}
                            containerStyle={[global.ModalButton.Positive.containerStyle, {marginVertical: 5}]}
                            buttonStyle={global.ModalButton.Positive.buttonStyle}
                            titleStyle={global.ModalButton.Positive.titleStyle}
                        />
                    )}
                    <Button
                        onPress={onCopy}
                        title={t(SHARE_BUTTON_COPY_TEXT)}
                        containerStyle={[global.ModalButton.Positive.containerStyle, {marginVertical: 5}]}
                        buttonStyle={global.ModalButton.Positive.buttonStyle}
                        titleStyle={global.ModalButton.Positive.titleStyle}
                    />

                    {onOpenLink && (
                        <Button
                            onPress={onOpenLink}
                            title={t(SHARE_TEST_LINK)}
                            containerStyle={[global.ModalButton.Positive.containerStyle, {marginVertical: 5}]}
                            buttonStyle={global.ModalButton.Positive.buttonStyle}
                            titleStyle={global.ModalButton.Positive.titleStyle}
                        />
                    )}
                </View>
            </View>
        </Modal>
    );
};

export default LinkDialog;
