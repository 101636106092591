//const localeText={{ clearButtonLabel: 'Empty', todayButtonLabel: 'Now' }}

const hrHR = {
    // Calendar navigation
    previousMonth: 'Prethodni mjesec',
    nextMonth: 'Sljedeći mjesec',
    // View navigation
    openPreviousView: 'open previous view',
    openNextView: 'open next view',
    calendarViewSwitchingButtonAriaLabel: view =>
        view === 'year' ? 'year view is open, switch to calendar view' : 'calendar view is open, switch to year view',
    // DateRange placeholders
    start: 'Početak',
    end: 'Kraj',
    // Action bar
    cancelButtonLabel: 'Otkaži',
    clearButtonLabel: 'Obriši',
    okButtonLabel: 'OK',
    todayButtonLabel: 'Danas',
    // Toolbar titles
    datePickerToolbarTitle: 'Odaberi datum',
    dateTimePickerToolbarTitle: 'Odaberi datum & vrijeme',
    timePickerToolbarTitle: 'Odaberi vrijeme',
    dateRangePickerToolbarTitle: 'Odaberi raspon datuma',
    // Clock labels
    clockLabelText: (view, time, adapter) =>
        `Select ${view}. ${
            time === null ? 'No time selected' : `Selected time is ${adapter.format(time, 'fullTime')}`
        }`,
    hoursClockNumberText: hours => `${hours} sati`,
    minutesClockNumberText: minutes => `${minutes} minuta`,
    secondsClockNumberText: seconds => `${seconds} sekundi`,
    // Digital clock labels
    selectViewText: view => `Select ${view}`,
    // Calendar labels
    calendarWeekNumberHeaderLabel: 'Week number',
    calendarWeekNumberHeaderText: '#',
    calendarWeekNumberAriaLabelText: weekNumber => `Week ${weekNumber}`,
    calendarWeekNumberText: weekNumber => `${weekNumber}`,
    // Open picker labels
    openDatePickerDialogue: (value, utils) =>
        value !== null && utils.isValid(value)
            ? `Choose date, selected date is ${utils.format(value, 'fullDate')}`
            : 'Choose date',
    openTimePickerDialogue: (value, utils) =>
        value !== null && utils.isValid(value)
            ? `Choose time, selected time is ${utils.format(value, 'fullTime')}`
            : 'Choose time',
    // Table labels
    timeTableLabel: 'pick time',
    dateTableLabel: 'pick date',
    // Field section placeholders
    fieldYearPlaceholder: params => 'Y'.repeat(params.digitAmount),
    fieldMonthPlaceholder: params => (params.contentType === 'letter' ? 'MMMM' : 'MM'),
    fieldDayPlaceholder: () => 'DD',
    fieldWeekDayPlaceholder: params => (params.contentType === 'letter' ? 'EEEE' : 'EE'),
    fieldHoursPlaceholder: () => 'hh',
    fieldMinutesPlaceholder: () => 'mm',
    fieldSecondsPlaceholder: () => 'ss',
    fieldMeridiemPlaceholder: () => 'aa',
};

export default hrHR;
