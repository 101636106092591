import {Svg, Path} from 'react-native-svg';

export const logoSvg = (
    <Svg width="168" height="30" viewBox="0 0 168 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
            d="M43.9641 25.7037C42.3111 25.7037 40.8624 25.381 39.618 24.7357C38.3922 24.0719 37.4357 23.1684 36.7485 22.0253C36.0799 20.8821 35.7456 19.5822 35.7456 18.1256C35.7456 16.6505 36.0706 15.3506 36.7207 14.2259C37.3893 13.0828 38.2994 12.1885 39.4509 11.5432C40.621 10.8978 41.9489 10.5752 43.4347 10.5752C44.8834 10.5752 46.1742 10.8886 47.3072 11.5155C48.4401 12.1424 49.3316 13.0274 49.9817 14.1706C50.6317 15.3138 50.9567 16.6598 50.9567 18.2086C50.9567 18.3561 50.9475 18.522 50.9289 18.7064C50.9289 18.8908 50.9196 19.0659 50.901 19.2319H38.5037V16.9363H49.0623L47.6972 17.6554C47.7158 16.8073 47.5393 16.0605 47.1679 15.4152C46.7964 14.7698 46.2857 14.2628 45.6356 13.894C45.0042 13.5253 44.2705 13.3409 43.4347 13.3409C42.5804 13.3409 41.8282 13.5253 41.1782 13.894C40.5467 14.2628 40.0452 14.7791 39.6738 15.4428C39.3209 16.0882 39.1444 16.8534 39.1444 17.7384V18.2915C39.1444 19.1766 39.3487 19.9602 39.7573 20.6424C40.1659 21.3246 40.7417 21.8501 41.4846 22.2189C42.2275 22.5876 43.0819 22.772 44.0477 22.772C44.8834 22.772 45.6356 22.6429 46.3043 22.3848C46.9729 22.1267 47.5672 21.721 48.0872 21.1679L49.9538 23.2975C49.2852 24.0719 48.4401 24.6711 47.4186 25.0952C46.4157 25.5008 45.2642 25.7037 43.9641 25.7037Z"
            fill="white"
        />
        <Path
            d="M63.6625 25.7866C62.1767 25.7866 60.793 25.5469 59.5115 25.0675C58.2485 24.5697 57.1434 23.8783 56.1962 22.9933C55.2676 22.0898 54.5433 21.0296 54.0232 19.8127C53.5032 18.5958 53.2432 17.2682 53.2432 15.83C53.2432 14.3919 53.5032 13.0643 54.0232 11.8474C54.5433 10.6305 55.2769 9.57951 56.2241 8.69447C57.1713 7.79101 58.2764 7.09958 59.5393 6.62018C60.8023 6.12235 62.186 5.87344 63.6904 5.87344C65.2876 5.87344 66.7456 6.15001 68.0643 6.70316C69.3829 7.23786 70.4973 8.03992 71.4074 9.10933L69.0672 11.2943C68.3614 10.5383 67.5721 9.97593 66.6992 9.60716C65.8262 9.21996 64.879 9.02636 63.8575 9.02636C62.836 9.02636 61.8981 9.1923 61.0437 9.52419C60.208 9.85608 59.4743 10.3263 58.8429 10.9347C58.23 11.5432 57.7471 12.2623 57.3942 13.092C57.0599 13.9217 56.8927 14.8344 56.8927 15.83C56.8927 16.8257 57.0599 17.7384 57.3942 18.5681C57.7471 19.3978 58.23 20.1169 58.8429 20.7254C59.4743 21.3338 60.208 21.804 61.0437 22.1359C61.8981 22.4678 62.836 22.6337 63.8575 22.6337C64.879 22.6337 65.8262 22.4493 66.6992 22.0806C67.5721 21.6934 68.3614 21.1126 69.0672 20.3382L71.4074 22.5507C70.4973 23.6017 69.3829 24.4038 68.0643 24.9569C66.7456 25.5101 65.2783 25.7866 63.6625 25.7866Z"
            fill="white"
        />
        <Path
            d="M82.9846 10.5752C84.1733 10.5752 85.2319 10.8056 86.1606 11.2666C87.1078 11.7275 87.8507 12.4374 88.3893 13.3962C88.9279 14.3365 89.1972 15.5535 89.1972 17.047V25.5101H85.7148V17.4895C85.7148 16.1804 85.3991 15.2031 84.7676 14.5578C84.1547 13.9125 83.2911 13.5898 82.1767 13.5898C81.3595 13.5898 80.6352 13.7557 80.0037 14.0876C79.3722 14.4195 78.88 14.9173 78.5271 15.5811C78.1928 16.2265 78.0257 17.047 78.0257 18.0426V25.5101H74.5433V4.98841H78.0257V14.7237L77.2735 13.5068C77.7935 12.5665 78.5457 11.8474 79.5301 11.3496C80.533 10.8333 81.6845 10.5752 82.9846 10.5752Z"
            fill="white"
        />
        <Path
            d="M100.635 25.7037C98.9818 25.7037 97.5331 25.381 96.2887 24.7357C95.0629 24.0719 94.1064 23.1684 93.4192 22.0253C92.7506 20.8821 92.4163 19.5822 92.4163 18.1256C92.4163 16.6505 92.7413 15.3506 93.3913 14.2259C94.06 13.0828 94.97 12.1885 96.1215 11.5432C97.2916 10.8978 98.6196 10.5752 100.105 10.5752C101.554 10.5752 102.845 10.8886 103.978 11.5155C105.111 12.1424 106.002 13.0274 106.652 14.1706C107.302 15.3138 107.627 16.6598 107.627 18.2086C107.627 18.3561 107.618 18.522 107.6 18.7064C107.6 18.8908 107.59 19.0659 107.572 19.2319H95.1743V16.9363H105.733L104.368 17.6554C104.386 16.8073 104.21 16.0605 103.839 15.4152C103.467 14.7698 102.956 14.2628 102.306 13.894C101.675 13.5253 100.941 13.3409 100.105 13.3409C99.2511 13.3409 98.4989 13.5253 97.8488 13.894C97.2173 14.2628 96.7159 14.7791 96.3444 15.4428C95.9915 16.0882 95.8151 16.8534 95.8151 17.7384V18.2915C95.8151 19.1766 96.0194 19.9602 96.428 20.6424C96.8366 21.3246 97.4124 21.8501 98.1553 22.2189C98.8982 22.5876 99.7525 22.772 100.718 22.772C101.554 22.772 102.306 22.6429 102.975 22.3848C103.644 22.1267 104.238 21.721 104.758 21.1679L106.624 23.2975C105.956 24.0719 105.111 24.6711 104.089 25.0952C103.086 25.5008 101.935 25.7037 100.635 25.7037Z"
            fill="white"
        />
        <Path
            d="M117.798 25.7037C116.238 25.7037 114.845 25.381 113.619 24.7357C112.412 24.0719 111.465 23.1684 110.777 22.0253C110.09 20.8821 109.747 19.5822 109.747 18.1256C109.747 16.6505 110.09 15.3506 110.777 14.2259C111.465 13.0828 112.412 12.1885 113.619 11.5432C114.845 10.8978 116.238 10.5752 117.798 10.5752C119.247 10.5752 120.519 10.8702 121.615 11.4602C122.729 12.0318 123.574 12.8799 124.15 14.0047L121.475 15.5535C121.03 14.8528 120.482 14.3365 119.832 14.0047C119.2 13.6728 118.513 13.5068 117.77 13.5068C116.916 13.5068 116.145 13.6912 115.458 14.06C114.771 14.4287 114.232 14.9634 113.842 15.6641C113.452 16.3463 113.257 17.1668 113.257 18.1256C113.257 19.0844 113.452 19.9141 113.842 20.6147C114.232 21.2969 114.771 21.8224 115.458 22.1912C116.145 22.56 116.916 22.7443 117.77 22.7443C118.513 22.7443 119.2 22.5784 119.832 22.2465C120.482 21.9146 121.03 21.3984 121.475 20.6977L124.15 22.2465C123.574 23.3528 122.729 24.2102 121.615 24.8186C120.519 25.4087 119.247 25.7037 117.798 25.7037Z"
            fill="white"
        />
        <Path
            d="M129.866 22.2189L129.949 17.8767L137.722 10.7411H141.901L135.298 17.3235L133.46 18.8447L129.866 22.2189ZM127.024 25.5101V4.98841H130.507V25.5101H127.024ZM138.251 25.5101L132.819 18.8447L135.02 16.0789L142.486 25.5101H138.251Z"
            fill="white"
        />
        <Path
            d="M144.735 25.5101V10.7411H148.218V25.5101H144.735ZM146.491 8.30727C145.841 8.30727 145.302 8.10445 144.875 7.69881C144.466 7.29318 144.262 6.80456 144.262 6.23298C144.262 5.64296 144.466 5.15435 144.875 4.76715C145.302 4.36151 145.841 4.15869 146.491 4.15869C147.141 4.15869 147.67 4.35229 148.079 4.73949C148.506 5.10826 148.719 5.57843 148.719 6.15001C148.719 6.75847 148.515 7.27474 148.106 7.69881C147.698 8.10445 147.159 8.30727 146.491 8.30727Z"
            fill="white"
        />
        <Path
            d="M161.23 10.5752C162.419 10.5752 163.477 10.8056 164.406 11.2666C165.353 11.7275 166.096 12.4374 166.635 13.3962C167.173 14.3365 167.442 15.5535 167.442 17.047V25.5101H163.96V17.4895C163.96 16.1804 163.644 15.2031 163.013 14.5578C162.4 13.9125 161.536 13.5898 160.422 13.5898C159.605 13.5898 158.88 13.7557 158.249 14.0876C157.617 14.4195 157.125 14.9173 156.772 15.5811C156.438 16.2265 156.271 17.047 156.271 18.0426V25.5101H152.788V10.7411H156.104V14.7237L155.519 13.5068C156.039 12.5665 156.791 11.8474 157.775 11.3496C158.778 10.8333 159.93 10.5752 161.23 10.5752Z"
            fill="white"
        />
        <Path
            d="M26.4924 15.705C26.5024 15.5927 26.5092 15.5155 26.5117 15.3488V15L26.8605 14.999H30.0013C30.0008 15.3775 29.986 15.7584 29.9563 16.1411C29.8227 17.9154 29.3805 19.6036 28.6831 21.152C27.7884 23.1447 26.4796 24.8857 24.8809 26.284C23.1499 27.8009 21.0668 28.9259 18.7702 29.5204C17.2277 29.9216 15.6005 30.0808 13.9391 29.9611C12.1076 29.833 10.3669 29.3761 8.77536 28.6491C6.80582 27.7521 5.08482 26.4495 3.70091 24.8623C2.21637 23.1629 1.10898 21.1255 0.509252 18.8807C0.0782548 17.2751 -0.0900591 15.576 0.0461988 13.8406C0.177814 12.1174 0.600599 10.4757 1.26545 8.96448C1.66732 8.0481 2.1564 7.18406 2.72079 6.38106L2.72216 6.38292C5.43581 2.52265 9.92364 0 15.0006 0C17.1139 0 19.1251 0.437094 20.9488 1.22578C22.0188 1.68705 23.0184 2.26691 23.9337 2.94662L21.4317 5.4489C20.0706 4.53092 18.4907 3.89103 16.7608 3.62334L16.7611 3.62192C16.1871 3.53384 15.5992 3.48815 15.0006 3.48815C10.2834 3.48815 6.22887 6.32594 4.45105 10.3876C4.06245 11.2782 3.78112 12.2331 3.62554 13.2386C3.58053 13.5295 3.54683 13.8195 3.52412 14.1082C3.50161 14.4022 3.49016 14.6993 3.49016 14.999C3.49016 16.0257 3.62456 17.021 3.87673 17.9682C4.75464 21.2502 7.06245 24.0242 10.1933 25.461C11.1394 25.8952 12.1607 26.2073 13.2404 26.3743C13.5635 26.4243 13.8854 26.4604 14.2057 26.4829C14.4684 26.5008 14.7334 26.5099 15.0006 26.5099C15.9869 26.5099 16.9442 26.3859 17.8578 26.1525C21.2149 25.2908 24.0539 22.9361 25.4966 19.7312C25.913 18.8062 26.2131 17.8103 26.3757 16.7591C26.4215 16.4634 26.4556 16.1687 26.4783 15.8753C26.4835 15.806 26.4882 15.7524 26.4924 15.705Z"
            fill="white"
        />
        <Path
            d="M28.5497 3.27009L31.0164 5.73674L14.9831 21.7701L8.19983 14.9868L10.6665 12.5201L14.9831 16.8367L28.5497 3.27009Z"
            fill="white"
        />
    </Svg>
);
