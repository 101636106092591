import {Svg, Path} from 'react-native-svg';

export const logoSvg = (
    <Svg
    width="112"
    height="20"
    viewBox="0 0 112 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <Path
        d="M29.3094 17.1358C28.2074 17.1358 27.2417 16.9207 26.4121 16.4904C25.5949 16.0479 24.9572 15.4456 24.4991 14.6835C24.0533 13.9214 23.8304 13.0548 23.8304 12.0837C23.8304 11.1004 24.0471 10.2338 24.4805 9.48395C24.9262 8.72184 25.533 8.12567 26.3006 7.69545C27.0807 7.26522 27.966 7.05011 28.9565 7.05011C29.9223 7.05011 30.7829 7.25908 31.5382 7.67701C32.2935 8.09494 32.8878 8.68496 33.3212 9.44707C33.7545 10.2092 33.9712 11.1065 33.9712 12.139C33.9712 12.2374 33.965 12.348 33.9526 12.4709C33.9526 12.5938 33.9464 12.7106 33.9341 12.8212H25.6692V11.2909H32.7083L31.7982 11.7703C31.8106 11.2048 31.6929 10.707 31.4453 10.2768C31.1977 9.84656 30.8572 9.50853 30.4238 9.26269C30.0028 9.01684 29.5137 8.89392 28.9565 8.89392C28.387 8.89392 27.8855 9.01684 27.4521 9.26269C27.0312 9.50853 26.6968 9.85271 26.4492 10.2952C26.214 10.7254 26.0963 11.2356 26.0963 11.8256V12.1944C26.0963 12.7844 26.2325 13.3068 26.5049 13.7616C26.7773 14.2164 27.1612 14.5667 27.6564 14.8126C28.1517 15.0584 28.7213 15.1813 29.3651 15.1813C29.9223 15.1813 30.4238 15.0953 30.8695 14.9232C31.3153 14.7511 31.7115 14.4807 32.0582 14.1119L33.3026 15.5317C32.8568 16.0479 32.2935 16.4474 31.6125 16.7301C30.9438 17.0006 30.1762 17.1358 29.3094 17.1358Z"
        fill="#414141"
    />
    <Path
        d="M42.4417 17.1911C41.4512 17.1911 40.5287 17.0313 39.6744 16.7117C38.8324 16.3798 38.0957 15.9189 37.4642 15.3288C36.8451 14.7265 36.3622 14.0197 36.0155 13.2084C35.6688 12.3972 35.4955 11.5121 35.4955 10.5534C35.4955 9.59457 35.6688 8.70954 36.0155 7.89826C36.3622 7.08699 36.8513 6.38634 37.4828 5.79632C38.1142 5.194 38.851 4.73305 39.6929 4.41346C40.5349 4.08157 41.4574 3.91563 42.4603 3.91563C43.5251 3.91563 44.4971 4.10001 45.3762 4.46877C46.2553 4.82524 46.9982 5.35995 47.605 6.07289L46.0448 7.5295C45.5743 7.02553 45.0481 6.65062 44.4661 6.40477C43.8842 6.14664 43.2527 6.01757 42.5717 6.01757C41.8907 6.01757 41.2654 6.1282 40.6959 6.34946C40.1387 6.57072 39.6496 6.88417 39.2286 7.28981C38.82 7.69545 38.4981 8.17484 38.2628 8.72798C38.04 9.28112 37.9285 9.88958 37.9285 10.5534C37.9285 11.2171 38.04 11.8256 38.2628 12.3787C38.4981 12.9319 38.82 13.4113 39.2286 13.8169C39.6496 14.2225 40.1387 14.536 40.6959 14.7573C41.2654 14.9785 41.8907 15.0891 42.5717 15.0891C43.2527 15.0891 43.8842 14.9662 44.4661 14.7204C45.0481 14.4622 45.5743 14.075 46.0448 13.5588L47.605 15.0338C46.9982 15.7345 46.2553 16.2692 45.3762 16.6379C44.4971 17.0067 43.5189 17.1911 42.4417 17.1911Z"
        fill="#414141"
    />
    <Path
        d="M55.3231 7.05011C56.1156 7.05011 56.8213 7.20376 57.4404 7.51106C58.0719 7.81837 58.5672 8.29161 58.9262 8.9308C59.2853 9.5577 59.4649 10.369 59.4649 11.3646V17.0067H57.1433V11.6596C57.1433 10.7869 56.9328 10.1354 56.5118 9.7052C56.1032 9.27498 55.5274 9.05987 54.7845 9.05987C54.2397 9.05987 53.7568 9.1705 53.3358 9.39175C52.9148 9.61301 52.5867 9.9449 52.3515 10.3874C52.1286 10.8176 52.0172 11.3646 52.0172 12.0284V17.0067H49.6956V3.3256H52.0172V9.81583L51.5157 9.00455C51.8624 8.37766 52.3639 7.89826 53.0201 7.56638C53.6887 7.2222 54.4564 7.05011 55.3231 7.05011Z"
        fill="#414141"
    />
    <Path
        d="M67.0899 17.1358C65.9879 17.1358 65.0221 16.9207 64.1925 16.4904C63.3753 16.0479 62.7376 15.4456 62.2795 14.6835C61.8338 13.9214 61.6109 13.0548 61.6109 12.0837C61.6109 11.1004 61.8276 10.2338 62.2609 9.48395C62.7067 8.72184 63.3134 8.12567 64.0811 7.69545C64.8611 7.26522 65.7464 7.05011 66.737 7.05011C67.7028 7.05011 68.5633 7.25908 69.3186 7.67701C70.0739 8.09494 70.6682 8.68496 71.1016 9.44707C71.535 10.2092 71.7516 11.1065 71.7516 12.139C71.7516 12.2374 71.7455 12.348 71.7331 12.4709C71.7331 12.5938 71.7269 12.7106 71.7145 12.8212H63.4496V11.2909H70.4887L69.5786 11.7703C69.591 11.2048 69.4734 10.707 69.2257 10.2768C68.9781 9.84656 68.6376 9.50853 68.2042 9.26269C67.7833 9.01684 67.2942 8.89392 66.737 8.89392C66.1674 8.89392 65.666 9.01684 65.2326 9.26269C64.8116 9.50853 64.4773 9.85271 64.2297 10.2952C63.9944 10.7254 63.8768 11.2356 63.8768 11.8256V12.1944C63.8768 12.7844 64.013 13.3068 64.2854 13.7616C64.5578 14.2164 64.9416 14.5667 65.4369 14.8126C65.9322 15.0584 66.5017 15.1813 67.1456 15.1813C67.7028 15.1813 68.2042 15.0953 68.65 14.9232C69.0957 14.7511 69.492 14.4807 69.8386 14.1119L71.083 15.5317C70.6373 16.0479 70.0739 16.4474 69.3929 16.7301C68.7243 17.0006 67.9566 17.1358 67.0899 17.1358Z"
        fill="#414141"
    />
    <Path
        d="M78.532 17.1358C77.4919 17.1358 76.5633 16.9207 75.7461 16.4904C74.9413 16.0479 74.3098 15.4456 73.8517 14.6835C73.3935 13.9214 73.1645 13.0548 73.1645 12.0837C73.1645 11.1004 73.3935 10.2338 73.8517 9.48395C74.3098 8.72184 74.9413 8.12567 75.7461 7.69545C76.5633 7.26522 77.4919 7.05011 78.532 7.05011C79.4978 7.05011 80.346 7.24678 81.0765 7.64013C81.8194 8.02119 82.3828 8.58662 82.7666 9.33644L80.9836 10.369C80.6865 9.90188 80.3212 9.5577 79.8878 9.33644C79.4669 9.11518 79.0087 9.00455 78.5135 9.00455C77.9439 9.00455 77.43 9.12747 76.9719 9.37331C76.5138 9.61916 76.1547 9.97563 75.8947 10.4427C75.6347 10.8975 75.5047 11.4445 75.5047 12.0837C75.5047 12.7229 75.6347 13.2761 75.8947 13.7432C76.1547 14.198 76.5138 14.5483 76.9719 14.7941C77.43 15.04 77.9439 15.1629 78.5135 15.1629C79.0087 15.1629 79.4669 15.0523 79.8878 14.831C80.3212 14.6097 80.6865 14.2656 80.9836 13.7985L82.7666 14.831C82.3828 15.5685 81.8194 16.1401 81.0765 16.5458C80.346 16.9391 79.4978 17.1358 78.532 17.1358Z"
        fill="#414141"
    />
    <Path
        d="M86.5773 14.8126L86.633 11.9178L91.8148 7.16074H94.6007L90.199 11.549L88.9731 12.5631L86.5773 14.8126ZM84.6828 17.0067V3.3256H87.0044V17.0067H84.6828ZM92.1677 17.0067L88.546 12.5631L90.0132 10.7193L94.9907 17.0067H92.1677Z"
        fill="#414141"
    />
    <Path
        d="M96.4903 17.0067V7.16074H98.8119V17.0067H96.4903ZM97.6604 5.53818C97.2271 5.53818 96.868 5.40297 96.5832 5.13254C96.3108 4.86212 96.1746 4.53638 96.1746 4.15532C96.1746 3.76197 96.3108 3.43623 96.5832 3.1781C96.868 2.90767 97.2271 2.77246 97.6604 2.77246C98.0938 2.77246 98.4467 2.90153 98.7191 3.15966C99.0039 3.4055 99.1463 3.71895 99.1463 4.10001C99.1463 4.50565 99.0101 4.84982 98.7377 5.13254C98.4653 5.40297 98.1062 5.53818 97.6604 5.53818Z"
        fill="#414141"
    />
    <Path
        d="M107.487 7.05011C108.279 7.05011 108.985 7.20376 109.604 7.51106C110.235 7.81837 110.731 8.29161 111.09 8.9308C111.449 9.5577 111.628 10.369 111.628 11.3646V17.0067H109.307V11.6596C109.307 10.7869 109.096 10.1354 108.675 9.7052C108.267 9.27498 107.691 9.05987 106.948 9.05987C106.403 9.05987 105.92 9.1705 105.499 9.39175C105.078 9.61301 104.75 9.9449 104.515 10.3874C104.292 10.8176 104.181 11.3646 104.181 12.0284V17.0067H101.859V7.16074H104.069V9.81583L103.679 9.00455C104.026 8.37766 104.527 7.89826 105.184 7.56638C105.852 7.2222 106.62 7.05011 107.487 7.05011Z"
        fill="#414141"
    />
    <Path
        d="M17.6616 10.47C17.6683 10.3951 17.6728 10.3437 17.6744 10.2326V10L17.907 9.99934H20.0008C20.0005 10.2517 19.9906 10.5056 19.9709 10.7607C19.8818 11.9436 19.587 13.069 19.1221 14.1014C18.5256 15.4298 17.6531 16.5905 16.5873 17.5227C15.4332 18.5339 14.0445 19.2839 12.5135 19.6803C11.4851 19.9478 10.4004 20.0539 9.29272 19.974C8.07176 19.8887 6.91124 19.5841 5.85024 19.0994C4.53721 18.5014 3.38988 17.633 2.46727 16.5749C1.47758 15.4419 0.739318 14.0837 0.339501 12.5872C0.0521699 11.5168 -0.0600394 10.384 0.0307992 9.22708C0.118543 8.07828 0.4004 6.98378 0.843635 5.97632C1.11155 5.3654 1.4376 4.78937 1.81386 4.25404L1.81477 4.25528C3.62388 1.68176 6.61576 0 10.0004 0C11.4093 0 12.7501 0.291396 13.9659 0.817186C14.6792 1.1247 15.3456 1.51127 15.9558 1.96442L14.2878 3.6326C13.3804 3.02061 12.3271 2.59402 11.1739 2.41556L11.174 2.41462C10.7914 2.35589 10.3995 2.32543 10.0004 2.32543C6.85558 2.32543 4.15258 4.21729 2.96737 6.92504C2.7083 7.51881 2.52075 8.15538 2.41702 8.82572C2.38702 9.01964 2.36455 9.21299 2.34941 9.40547C2.33441 9.60146 2.32677 9.79951 2.32677 9.99935C2.32677 10.6838 2.41637 11.3473 2.58449 11.9788C3.16976 14.1668 4.7083 16.0161 6.79552 16.974C7.42628 17.2635 8.10715 17.4715 8.82696 17.5829C9.04231 17.6162 9.25695 17.6402 9.47047 17.6553C9.64558 17.6672 9.82229 17.6733 10.0004 17.6733C10.6579 17.6733 11.2961 17.5906 11.9052 17.435C14.1432 16.8605 16.0359 15.2907 16.9977 13.1541C17.2753 12.5374 17.4754 11.8735 17.5838 11.1727C17.6143 10.9756 17.637 10.7791 17.6522 10.5835C17.6557 10.5373 17.6588 10.5016 17.6616 10.47Z"
        fill="#5D4D9D"
    />
    <Path
        d="M19.0332 2.18006L20.6776 3.8245L9.98875 14.5134L5.46656 9.99118L7.11099 8.34674L9.98873 11.2245L19.0332 2.18006Z"
        fill="#5D4D9D"
    />
</Svg>
);
