import {useIsFocused} from '@react-navigation/native';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, Pressable, SafeAreaView} from 'react-native';
import {FAB, Icon, LinearProgress, useTheme} from 'react-native-elements';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {REST_COMMUNICATION} from '../api/inCheckinRestService';
import ConfirmDialog from '../components/ConfirmDialog';
import MessageDialog from '../components/MessageDialog';
import AddEvisitorDialog from '../components/evaccounts/AddEvisitorDialog';
import EVAccountsList from '../components/evaccounts/EVAccountsList';
import {ADD_EVISITOR, CONFIRM_DELETE_EV_DIALOG, CONFIRM_DELETE_EV_TITLE} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import globalStyle from '../theme/globalStyle';

const IS_IOS = Platform.OS === 'ios';

const EVAccountsScreen = ({route, navigation}) => {
    const {
        evaccounts,
        clearErrors,
        associateEvisitor,
        deleteEVisitor,
        initEvAccounts,
        apiErrors,
        refetchEvisitor,
        apiMessages,
    } = useContext(DataContext);
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = globalStyle(theme);
    const isFocused = useIsFocused();
    const [openFab, setOpenFab] = useState(false);
    const {t} = useTranslation();
    const [openMessage, setOpenMessage] = useState(false);
    const [progressOngoing, setProgressOngoing] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [forDeleteAccount, setForDeleteAccount] = useState();
    const [evAccountError, setEvAccountError] = useState(false);

    const generateMessage = () => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`;
        } else if (apiMessages) {
            if (apiMessages?.signal === REST_COMMUNICATION) {
                return `${apiMessages?.message}`;
            }
            return `${apiMessages?.signal}: ${apiMessages?.message}`;
        }
        return null;
    };

    const onRefresh = async () => {
        setRefresh(true);
        await initEvAccounts(false, true);
        setRefresh(false);
    };

    const onConnect = async eVisitorCredentials => {
        setProgressOngoing(true);
        setOpenFab(false);
        try {
            await associateEvisitor(eVisitorCredentials);
        } catch (e) {
            console.log(e);
            setEvAccountError(true);
            setOpenFab(true);
        } finally {
            setProgressOngoing(false);
        }
    };

    const openDeleteDialog = eVisitorAccount => {
        setForDeleteAccount(eVisitorAccount);
        setConfirmDeleteOpen(true);
    };

    const onDelete = async () => {
        if (forDeleteAccount) {
            setProgressOngoing(true);
            try {
                await deleteEVisitor(forDeleteAccount);
            } catch (e) {
                console.log(e);
            } finally {
                setProgressOngoing(false);
            }
        }
    };

    const handleOpenFab = () => {
        setOpenFab(!openFab);
    };

    useEffect(() => {
        apiErrors && setOpenMessage(true);
        apiMessages && setOpenMessage(true);
    }, [apiMessages, apiErrors]);

    useEffect(() => {
        isFocused && clearErrors();
    }, [isFocused]);

    useEffect(() => {
        const refreshButton = (
            <Pressable
                onPress={onRefresh}
                style={({pressed}) => ({
                    opacity: pressed ? 0.5 : 1,
                })}>
                <Icon
                    type="ionicon"
                    name="refresh-outline"
                    size={22}
                    color={theme.colors.primary}
                    style={{marginRight: 15}}
                />
            </Pressable>
        );

        navigation.setOptions({
            headerRight: () => refreshButton,
        });
    }, []);

    const insets = useSafeAreaInsets();

    return (
        <SafeAreaView style={global.containerBg}>
            {progressOngoing && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
            {isFocused && (
                <MessageDialog
                    message={generateMessage()}
                    isError={apiErrors}
                    open={openMessage}
                    handleOpen={setOpenMessage}
                />
            )}
            <EVAccountsList
                evaccounts={evaccounts}
                associateEvisitor={associateEvisitor}
                onDelete={openDeleteDialog}
                setOpenProgress={setProgressOngoing}
                refreshing={refresh}
                onRefresh={onRefresh}
                refetchEvisitor={refetchEvisitor}
            />
            <AddEvisitorDialog
                open={openFab}
                setOpen={setOpenFab}
                onConnect={onConnect}
                evAccountError={evAccountError}
                setEvAccountError={setEvAccountError}
            />
            <ConfirmDialog
                title={t(CONFIRM_DELETE_EV_TITLE)}
                open={confirmDeleteOpen}
                setOpen={setConfirmDeleteOpen}
                onConfirm={onDelete}
                content={t(CONFIRM_DELETE_EV_DIALOG)}></ConfirmDialog>
            <FAB
                title={t(ADD_EVISITOR)}
                icon={{name: 'add', color: '#fff', size: 22, containerStyle: {margin: 0}}}
                placement="right"
                onPress={handleOpenFab}
                containerStyle={[global.boxShadow, IS_IOS && {marginBottom: insets.bottom}]}
                buttonStyle={global.FAB.buttonStyle}
                titleStyle={global.FAB.titleStyle}
            />
        </SafeAreaView>
    );
};

export default EVAccountsScreen;
