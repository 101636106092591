import {makeStyles} from 'react-native-elements';

const useStyles = makeStyles((theme, props) => ({
    section: {
        marginBottom: 15,
    },

    title: {
        fontFamily: theme.fontFamilyMedium,
        fontSize: 14,
        lineHeight: 22,
        marginBottom: 10,
    },

    content: {
        fontFamily: theme.fontFamily,
        fontSize: 14,
        marginBottom: 5,
        lineHeight: 22,
    },
}));

export default useStyles;
