import qs from 'qs';
import {Linking} from 'react-native';
import {PHONE_CONTACT_NUMBER} from '../constants/stringsAndFields';

export async function sendEmail(to, subject, options = {}) {
    const {cc, bcc} = options;

    let url = `mailto:${to}`;

    // Create email link query
    const query = qs.stringify({
        subject: subject,
        cc: cc,
        bcc: bcc,
    });

    if (query.length) {
        url += `?${query}`;
    }

    // check if we can use this link
    const canOpen = await Linking.canOpenURL(url);

    if (!canOpen) {
        //throw new Error('Provided URL can not be handled');
    }

    return Linking.openURL(url);
}

export async function sendMessage() {
    try {
        const url = `whatsapp://send?phone=${PHONE_CONTACT_NUMBER}`;
        const canOpen = await Linking.canOpenURL(url);
        if (!canOpen) {
            //throw new Error('Provided URL can not be handled');
        }
        await Linking.openURL(url);
    } catch (e) {
        console.log(e);
    }
}

export function getEmailFormData(emailAddress) {
    const form = {email: emailAddress};
    const formData = new FormData();
    Object.keys(form).forEach(key => {
        formData.append(key, form[key]);
    });
    return formData;
}
