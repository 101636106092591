import {useIsFocused} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, Pressable, SafeAreaView, ScrollView, View} from 'react-native';
import {Icon, LinearProgress, useTheme} from 'react-native-elements';
import {getProducts, initConnection, requestPurchase} from 'react-native-iap';
import {fetchIapsAppleProducts, postIapsAppleOrder} from '../api/inCheckinRestService';
import IAPInvoiceDataDialog from '../components/iap/IAPInvoiceDataDialog';
import Package from '../components/packages/Package';
import {IAP_ALERT_PURCHASE_SUCCESSFUL} from '../constants/stringsAndFields';
import globalStyle from '../theme/globalStyle';
import {getUserInfo} from '../utils/userUtils';
import useStyles from './PackagesScreen.styles';

const IS_WEB = Platform.OS === 'web';
const IS_IOS = Platform.OS === 'ios';

const PackagesScreen = ({route, navigation}) => {
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const styles = useStyles(theme);
    const {i18n, t} = useTranslation();
    const isFocused = useIsFocused();

    const goBack = _ => {
        navigation.replace('Root');
    };

    const headerGoBackButton = (
        <Pressable onPress={goBack}>
            <Icon name="arrow-back" size={22} color={theme.colors.primary} containerStyle={{marginLeft: 11}} />
        </Pressable>
    );

    // web
    if (IS_WEB) {
        useEffect(() => {
            const parent = navigation.getParent();
            if (parent) {
                parent.setOptions({
                    headerLeft: () => headerGoBackButton,
                });
            }
            fetchProducts().catch(console.error);
        }, [navigation.getParent()]);
    } else {
        useEffect(() => {
            fetchProducts().catch(console.error);
            getUserInfo().then(userInfoData => userInfoData && setUserInfo(userInfoData));
        }, [isFocused]);
    }

    // --- populate products from incheckin backend and App store
    const [isInProgress, setIsInProgress] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [openEditUserData, setOpenEditUserData] = useState(false);

    const [selectedProduct, selectProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const fetchProducts = async () => {
        setIsInProgress(true);
        // 1) get products from ICI (has title and description localization). 2) get products from Apple. 3) append localized prices to productsICI 4) show in List
        // example of apple response:
        // HR store [{"countryCode": "HR", "currency": "HRK", "description": "15 credits and prolonged validity for 60 days", "discounts": [], "introductoryPrice": "", "introductoryPriceAsAmountIOS": "", "introductoryPriceNumberOfPeriodsIOS": "", "introductoryPricePaymentModeIOS": "", "introductoryPriceSubscriptionPeriodIOS": "", "localizedPrice": "34,99 HRK", "price": "34.99", "productId": "app.echeckin.hr.iSOS", "subscriptionPeriodNumberIOS": "0", "subscriptionPeriodUnitIOS": "DAY", "title": "iSOS - 15 credits", "type": "iap"}]
        // EN store [{"countryCode": "US", "currency": "USD", "description": "15 credits and prolonged validity for 60 days", "discounts": [], "introductoryPrice": "", "introductoryPriceAsAmountIOS": "", "introductoryPriceNumberOfPeriodsIOS": "", "introductoryPricePaymentModeIOS": "", "introductoryPriceSubscriptionPeriodIOS": "", "localizedPrice": "$3.99", "price": "3.99", "productId": "app.echeckin.hr.iSOS", "subscriptionPeriodNumberIOS": "0", "subscriptionPeriodUnitIOS": "DAY", "title": "iSOS - 15 credits", "type": "iap"}]
        try {
            const platform = IS_IOS ? 'ios' : '';
            let productsICI = await fetchIapsAppleProducts(i18n.language, platform);
            const productIds = productsICI.map(p => p.productId);
            if (IS_IOS) {
                initConnection()
                    .catch(error => {
                        console.error('IAP init connection', error.message);
                    })
                    .then(() => {
                        getProducts({skus: productIds})
                            .then(productsApple => {
                                const pp = Object.assign(
                                    {},
                                    ...productsApple.map(x => ({[x.productId]: x.localizedPrice}))
                                );
                                productsICI.forEach(object => {
                                    object.localizedPrice = pp[object.productId];
                                });
                                setProducts(productsICI);
                            })
                            .catch(error => {
                                console.error('IAP get products from Apple', error.message);
                            })
                            .finally(_ => {
                                setIsInProgress(false);
                            });
                    });
            } else {
                setProducts(productsICI);
                setIsInProgress(false);
            }
        } catch (error) {
            console.error('IAP get products from eCheckin', error.message);
        } finally {
            setIsInProgress(false);
        }
    };

    // --- handle iOS purchase ---
    const onItemClick = async product => {
        if (!isInProgress) {
            selectProduct(product);
            setOpenEditUserData(true);
        }
    };

    const onEditUserDataConfirm = async invData => {
        let orderData = {
            selectedProduct,
        };
        if (invData.USER_DATA_FORM_ENTITY_FLAG === 1) {
            orderData['invoice_type'] = 'r1';
            orderData['r1_company_name'] = invData.USER_DATA_FORM_LEGAL_NAME;
            orderData['r1_oib'] = invData.USER_DATA_FORM_OIB;
            orderData['r1_address'] = invData.USER_DATA_FORM_ADDRESS;
            orderData['r1_pobox'] = invData.USER_DATA_FORM_CITY;
            orderData['r1_country'] = invData.USER_DATA_FORM_COUNTRY;
            orderData['r1_phone'] = invData.USER_DATA_FORM_TEL;
        } else {
            orderData['invoice_type'] = 'standard';
            orderData['standard_name'] = invData.USER_DATA_FORM_PERSONAL_NAME;
            orderData['standard_address'] = invData.USER_DATA_FORM_ADDRESS;
            orderData['standard_pobox'] = invData.USER_DATA_FORM_CITY;
            orderData['standard_country'] = invData.USER_DATA_FORM_COUNTRY;
            orderData['standard_phone'] = invData.USER_DATA_FORM_TEL;
        }

        setOpenEditUserData(false);
        setIsInProgress(true);

        requestPurchase({
            sku: selectedProduct['productId'],
            andDangerouslyFinishTransactionAutomaticallyIOS: false,
        })
            .catch(error => {
                console.error('IAP requestPurchase', error.message);
            })
            .then(async purchase => {
                if (purchase) {
                    try {
                        orderData['purchase'] = purchase;
                        // await new Promise(r => setTimeout(r, 10000));  // simulate slow response
                        console.log('IAP start order');
                        const order = await postIapsAppleOrder(orderData);
                        console.log('IAP order created successful', order);
                        alert(t(IAP_ALERT_PURCHASE_SUCCESSFUL));
                    } catch (err) {
                        console.log('IAP top-up error', err);
                    }
                }
                setIsInProgress(false);
            });
    };

    return (
        <SafeAreaView style={global.containerBg}>
            {isInProgress && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
            <ScrollView contentContainerStyle={{flexGrow: 1}}>
                <View style={global.containerInner}>
                    <View style={styles.packagesWrapper}>
                        {products.map((product, index) => {
                            return <Package key={product.productId} product={product} iapHandler={onItemClick} />;
                        })}
                    </View>
                </View>
                <IAPInvoiceDataDialog
                    open={openEditUserData}
                    setOpen={setOpenEditUserData}
                    onConfirm={onEditUserDataConfirm}
                    userInfo={userInfo}
                />
            </ScrollView>
        </SafeAreaView>
    );
};

export default PackagesScreen;
