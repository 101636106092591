import {
    CREDITS_EXPIRATION_OVERRIDE,
    INSUFFICIENT_CREDITS,
    NETWORK_OFFLINE_ERROR_MSG,
    REST_ERRORS,
    STORAGE_USER_DATA,
    data_credits,
    data_credits_best_before,
    error_data,
    error_message,
    error_type,
} from '../constants/stringsAndFields';
import {isBestBeforeExpired} from './dateHelper';
import {getUserData, setUserSettings} from './userUtils';

export const shouldShowCreditsLowDialog = (error, callback) => {
    if (checkIsCreditsProblemError(error)) {
        if (error?.[REST_ERRORS]?.[error_data]?.[data_credits] <= -4) {
            callback(true);
            return true;
        }
    }
    return false;
};

export const shouldShowCreditsExpiredDialog = async (error, callback) => {
    if (checkIsCreditsProblemError(error)) {
        const {data} = error?.[REST_ERRORS] ?? {};
        if (data?.[data_credits] > 0) {
            if (isBestBeforeExpired(data?.[data_credits_best_before])) {
                const overrideCreditExpiration = await shouldOverrideCreditsExpiration();
                if (!overrideCreditExpiration) {
                    callback(true);
                    return true;
                }
            }
        }
    }
    return false;
};

export const useCreditsAfterExpireConfirm = async () => {
    await setUserSettings(CREDITS_EXPIRATION_OVERRIDE, true);
};

const shouldOverrideCreditsExpiration = async () => {
    const userData = await getUserData();
    const overrideCreditExpiration = userData?.[STORAGE_USER_DATA]?.[CREDITS_EXPIRATION_OVERRIDE];
    return overrideCreditExpiration ? true : false;
};

export const checkIsCreditsProblemError = e => (e?.[REST_ERRORS]?.[error_type] === INSUFFICIENT_CREDITS ? true : false);

export const checkIsNetworkError = e => (e?.[error_message] === NETWORK_OFFLINE_ERROR_MSG ? true : false);

export const showCreditsDialog = async (e, openCreditsDepleted, openCreditsAfterExpire) => {
    const creditsLow = shouldShowCreditsLowDialog(e, openCreditsDepleted);
    if (creditsLow) return true;
    const overrideCreditsExpiration = await shouldShowCreditsExpiredDialog(e, openCreditsAfterExpire);
    if (overrideCreditsExpiration) return true;
    return false;
};
